import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getAllBrandAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getAllBrandSaga(action) {

 
  try {
   
    let response = yield call(NetworkService.getAllBrand, action);
   
    yield put(actions.getAllBrandSuccess(response));
  } catch (err) {
   
    yield put(actions.getAllBrandFailure(err));
  }
}

export function* watchGetAllBrandSaga() {
 
  yield takeLatest(types.GET_ALL_BRAND_REQUEST, getAllBrandSaga);
}
