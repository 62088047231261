import React, { useEffect ,useState} from "react";

import { corporateGetAssignProgramRequest } from "../../../redux/Actions/Corporate/getAssignProgramAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";

function ViewProgramAssigned() {
  const { id } = useParams();
  const [totalLimit,setTotalLimit]=useState();
  const [subLimit,setSubLimit]=useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(corporateGetAssignProgramRequest(id));
  }, []);
  const corporateGetAssignProgram = useSelector(
    (state) => state.corporateGetAssignProgram
  );

  useEffect(()=>{
    corporateGetAssignProgram?.data?.data.data.data.services.map((item)=>{
        if(item.serviceUuid==="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
            item.attributeCountDtoList.map((ele)=>{
                setTotalLimit(`₹ ${ele.topLimit} /-`);
                setSubLimit(`₹ ${ele.subLimit} /-`);
            })
        }
    })
},[corporateGetAssignProgram])
  return (
    <div>
      <div>
        <div className="mainHeadingCorporate">View Program</div>
        <div className="boxTopCorporate">
          <div className="boxTopNameCorporate">
            {corporateGetAssignProgram.data?.data.data.data.name}
          </div>
          <div className="boxTopBalanceBoxCorporate">
            Balance - {corporateGetAssignProgram.data?.data.data.data.balance}
          </div>
          </div>

        <div className="boxBottomCorporate">
          <div className="boxBottomNameCorporate">Breif Description</div>
          <div className="boxBottomDescCorporate">
            <p>{corporateGetAssignProgram.data?.data.data.data.description}</p>
          </div>
          <div className="mainTemplateCardCorporate">
            {corporateGetAssignProgram.data?.data.data.data.services.map(
              (item) =>{ if(item.serviceUuid!=="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
                return (
                  <div className="ViewProgramtemplateCardCorporate">
                  <div className="AssignedviewProgramDisplay">{item.serviceName}</div>
                  <div className="AssignedviewProgramCorporate">
                   <div className="AssignedviewProgramCorporateField"> 
                    <div className="AssignedviewProgramCorporateFieldChild1">Assigned</div>
                    <div className="AssignedviewProgramCorporateFieldChild1">Remaining</div>
</div>
                  {item.attributeCountDtoList.map((x) => (

                    <div className="AssignedviewProgramCorporateChild0">
                      <div className="AssignedviewProgramCorporateChild1">
                        {x.attributeName}
                      </div>
                      <div className="AssignedviewProgramCorporateChild2">
                        <div className="AssignedviewProgramCorporateChild1Part1">
                          {/* Assigned */}
                          <span>{x.attributeCount}</span>
                        </div>
                        <div className="AssignedviewProgramCorporateChild1Part1">
                          {/* Remaining */}
                         <span>{x.attributeCount}</span>
                        </div>
                      </div>
                    </div>

                  ))}
                  </div>
                </div>
                )
              }
              else{
                return (
                  <div className="pharmacyServiceProgramDetail">
                  <div className="pharmacyService1Program">
                   Pharmacy
                  </div>
                  <div className="pharmacyService2">
                   <div className="pharmacyService2Child1">
                    <p>Total Limit</p>
                    <input type="text" value={totalLimit} disabled={true} />
                   </div>
                   <div className="pharmacyService2Child1">
                   <p>Sub Limit</p>
                    <input type="text" value={subLimit} disabled={true} />
                   </div>
                  </div>
                  <div className="pharmacyService3">
                  </div>
                </div>
                )
              }}
            )}
          </div>
        </div>

        <br />
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default ViewProgramAssigned;
