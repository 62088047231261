import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/getAllServiceAction";
import NetworkService from "../../../network/NetworkService";

export function* getServiceSaga(action) {
  try {
    let response = yield call(NetworkService.getService, action);
    yield put(actions.getAllServiceSuccess(response));
  } catch (err) {
    yield put(actions.getAllServiceFailure(err));
  }
}

export function* watchGetServiceSaga() {
  yield takeLatest(types.GET_ALL_SERVICE_REQUEST, getServiceSaga);
}