import * as types from "../../Config/actionType"

export const loginDoctorRequest = (data) => {
   
    return {
        type: types.LOGIN_DOCTOR_REQUEST,
        data:data
    }
}

export const loginDoctorSuccess = (data) => {
   
    return {
        type: types.LOGIN_DOCTOR_SUCCESS,
        data:data,
    }
}

export const loginDoctorFailure = (data) => {
   
    return {
        type: types.LOGIN_DOCTOR_FAILURE,
        data:data,
    }
}