import * as types from "../../../Config/actionType"

export const viewAllServicesAttributesRequest = (serviceUuid) => {
    //
    return {
        type: types.VIEW_ALL_SERVICES_ATTRIBUTES_REQUEST,
        serviceUuid
    }
}

export const viewAllServicesAttributesSuccess = (action) => {
    //
    return {
        type: types.VIEW_ALL_SERVICES_ATTRIBUTES_SUCCESS,
        action
    }
}

export const viewAllServicesAttributesFailure = (action) => {
             
    return {
        type: types.VIEW_ALL_SERVICES_ATTRIBUTES_FAILURE,
        action
    }
}