import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/getUpdateHospitalAction";
import NetworkService from "../../../network/NetworkService";

export function* getUpdateHospitalSaga(action) {
 
  try {
    let response = yield call(NetworkService.getUpdateHospital, action);
   
    yield put(actions.getUpdateHospitalSuccess(response));
  } catch (err) {
    yield put(actions.getUpdateHospitalFailure(err));
  }
}

export function* watchGetUpdateHospitalSaga() {
 
  yield takeLatest(types.GET_UPDATE_HOSPITAL_REQUEST, getUpdateHospitalSaga);
}
