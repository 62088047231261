import { Close } from "@mui/icons-material";
import { Autocomplete, Stack, TextField } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import "./DiagnosticService.css";
import CancelIcon from "@mui/icons-material/Cancel";

function DiagnosticServiceTemplate(prop) {
  const testsAttributeResponseDtoListArray = [];
  const packsAttributeResponseDtoListArray = [];
  const packageNameArray = [];
  const {
    templateServices,
    handleServicesAttributeData,
    packageName,
    testName,
    handleTemplateAttributeDiagnostic
  } = prop;
  const [testObjectArrayTemplate,setTestObjectArrayTemplate]=useState([]);

  const [testObjectArray, setTestObjectArray] = useState([]);
  const [ff, setFf] = useState([]);
  const [packageFinal, setPackageFinal] = useState([]);
  const [testFinal, setTestFinal] = useState([]);
  const [
    settestsAttributeResponseDtoListArray1,
  ] = useState([]);

  const viewPackageNames = useSelector(
    (state) => state.viewAllPackageNamesReducer
  );
  viewPackageNames?.data?.data?.data?.map((item) => {
    packsAttributeResponseDtoListArray.push(item);
  });
  packsAttributeResponseDtoListArray.map((item) => {
    packageNameArray.push(item.packageName);
  });


  useEffect(() => {
    setPackageFinal(packageName);
    setTestFinal(testName);
    setTestObjectArray(templateServices.attributeCountDtoList);
  }, []);
  const handleClose1 = () => {
    settestsAttributeResponseDtoListArray1([
      ...testsAttributeResponseDtoListArray,
    ]);
  };

  // Package handleChange

  let handleChangeTest = (e, attributeUuid, name, variable) => {
    if (variable === "test") {
      let testValues = {
        [e.target.name]: parseInt(e.target.value),
        ["attributeName"]: name,
        ["attributeUuid"]: attributeUuid,
        ["attributeType"]: "INDIVIDUAL_TEST",
        ["exclusive"]: true,
      };
      if (testObjectArray.length === 0) {
        testObjectArray.push(testValues);
      } else {
        let boolT = testObjectArray.some((item) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            return true;
          }
        });
        if (boolT === true) {
          testObjectArray.map((item, index) => {
            if (item.attributeUuid === testValues.attributeUuid) {
              testObjectArray.splice(index, 1);
              testObjectArray.push(testValues);
              setTestObjectArray([...testObjectArray]);
            }
          });
        } else {
          testObjectArray.push(testValues);
          setTestObjectArray([...testObjectArray]);
        }
      }
    } else {
      let testValues = {
        [e.target.name]: parseInt(e.target.value),
        ["attributeName"]: name,
        ["attributeUuid"]: attributeUuid,
        ["attributeType"]: "PACKAGE",
        ["exclusive"]: true,
      };
      if (testObjectArray.length === 0) {
        testObjectArray.push(testValues);
      } else {
        let boolT = testObjectArray.some((item) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            return true;
          }
        });
        if (boolT === true) {
          testObjectArray.map((item, index) => {
            if (item.attributeUuid === testValues.attributeUuid) {
              testObjectArray.splice(index, 1);
              testObjectArray.push(testValues);
              setTestObjectArray([...testObjectArray]);
            }
          });
        } else {
          testObjectArray.push(testValues);
          setTestObjectArray([...testObjectArray]);
        }
      }
    }

    let ffval = {
      ["serviceName"]: templateServices.serviceName,
      ["serviceUuid"]: templateServices.serviceUuid,
      ["attributeCountDtoList"]: testObjectArray,
    };
   
    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceUuid === ffval.serviceUuid) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }

    handleServicesAttributeData(ff);
  };

  


  function handleCloseTemplate(uuid) {
    prop.handleCloseTemplate(uuid)

  }


  function closePackages(id) {
    let z = packageFinal.filter((ele) => ele.attributeUuid !== id);
    setPackageFinal([...z])
    let z2 = testObjectArray.filter((ele) => ele.attributeUuid !== id);
    setTestObjectArray([...z2]);
  }
  
  const prevTestObjectArray = useRef([]);
  useEffect(()=>{
   
if(testObjectArray.length!==0){

  let ffval = {
    ["serviceName"]: templateServices.serviceName,
    ["serviceUuid"]: templateServices.serviceUuid,
    ["attributeCountDtoList"]: testObjectArray,
  };
  if (ff.length === 0) {
    ff.push(ffval);
  } else {
    let boolT = ff.some((item) => {
      if (item.serviceUuid === ffval.serviceUuid) {
        return true;
      }
    });
    if (boolT === true) {
      ff.map((item, index) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          ff.splice(index, 1);

          ff.push(ffval);
          setFf([...ff]);
        }
      });
    } else {
      ff.push(ffval);
      setFf([...ff]);
    }
  }
  handleServicesAttributeData(ffval);

}
else if (prevTestObjectArray.current.length > 0 && testObjectArray.length === 0) {
  let ffval = {
    ["serviceName"]: templateServices.serviceName,
    ["serviceUuid"]: templateServices.serviceUuid,
    ["attributeCountDtoList"]: testObjectArray,
  };
  if (ff.length === 0) {
    ff.push(ffval);
  } else {
    let boolT = ff.some((item) => {
      if (item.serviceUuid === ffval.serviceUuid) {
        return true;
      }
    });
    if (boolT === true) {
      ff.map((item, index) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          ff.splice(index, 1);

          ff.push(ffval);
          setFf([...ff]);
        }
      });
    } else {
      ff.push(ffval);
      setFf([...ff]);
    }
  }
  handleServicesAttributeData(ffval);
}
prevTestObjectArray.current = testObjectArray;
  },[testObjectArray,templateServices])

  return (
    <div className="diagnostic">
      <div className="diagnostic_top">
        <div className="Diagnostic-heading">{templateServices.serviceName}</div>
        <div className="inputlabel">
          <Stack spacing={2}>
            <Autocomplete
              options={packageNameArray}
              style={{ width: 150 }}
              renderInput={(params) => (
                <TextField {...params} label="Package" />
              )}
              onChange={(event, value) => {
                packsAttributeResponseDtoListArray.map((item) => {
                  let duplicacy = false;
                  packageFinal.map((item) => {
                    if (item.attributeName === value) {
                      duplicacy = true;
                    }
                  });
                  if (!duplicacy) {
                    if (item.packageName === value) {
                      let packageAddition = {
                        ["attributeName"]: value,
                        ["attributeUuid"]: item.packageUuid,
                      };
                      packageFinal.push(packageAddition);
                    }
                  }
                });

                setPackageFinal([...packageFinal]);
              }}
            />
          </Stack>
        </div>
        <div className="section3">

          <button
            className="templateClose"
            onClick={() => {
              handleCloseTemplate(templateServices.serviceUuid
              );
            }}
          >
            <Close />
          </button>
        </div>
      </div>
      <div className="diagnostic_bottom">
        <div className="bottom_left">
          {packageFinal.map((item, index) => {

            if (true) {
              return (
                <div className="package_attribute" key={item.attributeUuid}>
                  {/* <div className="leftside"> */}
                  {" "}
                  <span key={item.attributeUuid}>{item.attributeName}</span>
                  {/* </div> */}
                  <div className="rightside" key={item.attributeUuid}>
                    <input
                    key={item.attributeUuid}
                      name="attributeCount"
                      type="number"
                      // disabled
                      defaultValue={item.attributeCount}
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      min="0"
                      onBlur={(e) =>
                        handleChangeTest(
                          e,
                          item.attributeUuid || item.packageUuid,
                          item.attributeName || item.packageName,
                          "package"
                        )
                      }
                    />
                    <div className="center">
                      <CancelIcon
                        onClick={() => closePackages(item.attributeUuid)}
                      />
                    </div>
                  </div>
                </div>
              );
            } else {
              return <div></div>;
            }
          })}
        </div>
      </div>
    </div>
  );
}
export default DiagnosticServiceTemplate;
