import { Route, Routes } from "react-router-dom";
import Services from "../../component/Pages/Services/Services";
import ViewServices from "../../component/Pages/Services/ViewServices";
import Corporate from "../../component/Pages/Corporate/Corporate";
import Programs from "../../component/Pages/Programs/Programs";
import AddProgram from "../../component/Pages/Programs/AddProgram";
import ViewProgram from "../../component/Pages/Programs/ViewProgram";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import ViewCorporate from "../../component/Pages/Corporate/ViewCorporate";
import AddProgramTemplate from "../../component/Pages/Program Template/AddProgramNew"
import UnderConstuction from "../../component/Pages/UnderConstruction/UnderConstruction";
import Career from "../../component/Organisms/Footer/Career/Career";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions"
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy"
import AssignProgramTemplate from "../../component/Pages/Program Template/AssignProgramNew"
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound"
import MyProgram from "../../component/Pages/Programs/MyPrograms";
import AssignProgramToInsurer from "../../component/Pages/Insurer/AssignProgramToInsurer";
import ViewProgramAssignedInsurer from "../../component/Pages/Agents/ViewProgramAssignedInsurer";
import SingleLogin from "../../component/Pages/Login/SingleLogin";



export default function InsuranceAgentPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/insurance-Agent-Dashboard" element={<DashBoard />} />
      <Route path="/services" element={<Services />} />
      <Route path="/viewService/:uuid" element={<ViewServices />} />
      <Route path="/insurer/corporate" element={<Corporate />} />
      <Route path="/insurer/MyProgram" element={<MyProgram />} />
      <Route path="/insurerViewCorporateDetails/:id" element={<ViewCorporate />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/viewProgram/:name" element={<ViewProgram />} />
      <Route path="/addProgram" element={<AddProgram />} />
      <Route path="/viewProgram" element={<ViewProgram />} />
      <Route path="/programAssignTemplate" element={<AssignProgramTemplate />} />
      <Route path="/programTemplate" element={<AddProgramTemplate />} />
      <Route path="/underConstruction" element={<UnderConstuction />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
      <Route path="/insurer/AssignProgramToInsurer" element={<AssignProgramToInsurer />} />
      <Route path="/viewProgramAssignedInsurer/:id" element={<ViewProgramAssignedInsurer/>}/>

    </Routes>
  );
}