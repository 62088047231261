import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Lab/updateLabAction";
import NetworkService from "../../../network/NetworkService";

export function* updateLabSaga(action) {
  try {
    let response = yield call(NetworkService.updateLab, action);
    yield put(actions.updateLabSuccess(response));
  } catch (err) {
    yield put(actions.updateLabFailure(err));
  }
}

export function* watchUpdateLabSaga() {
  yield takeLatest(types.UPDATE_LAB_REQUEST, updateLabSaga);
}