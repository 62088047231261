import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/CreateTalkToDoctor/CreateTalkToDoctorAction"
import NetworkService from "../../../network/NetworkService";

export function* CreateTalkToDoctorSaga(action) {
  try {
     
    let response = yield call(NetworkService.CreateTalkToDoctor, action);
    yield put(actions.CreateTalkToDoctorSuccess(response));
  } catch (err) {
    yield put(actions.CreateTalkToDoctorFailure(err));
  }
}

export function* watchCreateTalkToDoctorSaga() {
  yield takeLatest(types.CREATE_TALK_TO_DOCTOR_REQUEST, CreateTalkToDoctorSaga);
}