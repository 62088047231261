import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Pharmacy/PharmacyActionType";
import * as actions from "../../Actions/Pharmacy/deletePharmacyAction";
import NetworkService from "../../../network/Pharmacy/PharmacyNetworkServices";

export function* deletePharmacy(action) {
  
  try {
    let response = yield call(NetworkService.deletePharmacy, action);
    yield put(actions.deletePharmacySuccess(response));
  } catch (err) {
    yield put(actions.deletePharmacyFailure(err));
  }
}

export function* watchDeletePharmacy() {
  yield takeLatest(types.DELETE_PHARMACY_REQUEST, deletePharmacy);
}
