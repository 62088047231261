import * as types from "../../Config/actionType"

export const updateServiceTitleRequest = (title,detail) => {
    return {
        type: types.UPDATE_SERVICE_TITLE_REQUEST,
        title,
        detail,
    }
}

export const updateServiceTitleSuccess = (action) => {
    return {
        type: types.UPDATE_SERVICE_TITLE_SUCCESS,
        action
    }
}

export const updateServiceTitleFailure = (action) => {
    return {
        type: types.UPDATE_SERVICE_TITLE_FAILURE,
        action
    }
}