import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/getProgramTemplateAction";
import NetworkService from "../../../../network/NetworkService";

export function* getProgramTemplateSaga(action) {
  try {
   
    let response = yield call(NetworkService.getProgramTemplate, action);
   
    yield put(actions.getProgramTemplateSuccess(response));
  } catch (err) {
    yield put(actions.getProgramTemplateFailure(err));
  }
}

export function* watchGetProgramTemplateSaga() {
  yield takeLatest(types.GET_PROGRAM_TEMPLATE_REQUEST, getProgramTemplateSaga);
}
