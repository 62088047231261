import * as types from "../../Config/actionType"

export const AddInitiateRefundRequest = (data) => {
    return {
        type: types.ADD_INITIATE_REFUND_REQUEST,
        data
        
    }
}

export const AddInitiateRefundSuccess = (data) => {
    return {
        type: types.ADD_INITIATE_REFUND_SUCCESS,
        data:data,
    }
}
export const AddInitiateRefundFailure = (data) => {
    return {
        type: types.ADD_INITIATE_REFUND_FAILURE,
        data:data,
    }
}