import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/updatePlanAction";
import NetworkService from "../../../network/NetworkService";

export function* updatePlanSaga(action) {
  try {
    let response = yield call(NetworkService.updatePlan, action);
    yield put(actions.updatePlanSuccess(response));
  } catch (err) {
    yield put(actions.updatePlanFailure(err));
  }
}

export function* watchUpdatePlanSaga() {
  yield takeLatest(types.UPDATE_PLAN_REQUEST, updatePlanSaga);
}
