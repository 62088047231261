import React, { useEffect, useState, useRef } from "react";
// import "./ModalAddPackage.css";
import "./Modal.css";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import { viewAllTestsNameRequest } from "../../../redux/Actions/Booking/viewAllTestsNameAction";
import { makePackageRequest } from "../../../redux/Actions/Booking/makePackageAction";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { modalHeaderAddPackage } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const loginSchema = Yup.object().shape({
  //   packageName: Yup.string().required("Required Field")
  //     .matches(
  //       /^(?![.,&-])[A-Za-z0-9 ,&.-]+$/,
  //       "Insert only normal character"
  //     ),
  //   packageDescription: Yup.string().required("Required Field"),
  //   cost: Yup.string().required("Required Field"),
  //   // interpretation: Yup.string().required("Required Field"),
  //   prerequisites: Yup.string().required("Required Field"),
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalAddPackage(props) {
  const finalTestsNames = [];
  const TestsObjects = [];
  const serviceId = [];
  // const serviceIdFinal=[];
  // const [serviceIdFinal,setServiceIdFinal] = useState([]);
  const [serviceIdSubmit, setServiceIdSubmit] = useState([]);
  const [prerequisites, setPrerequisites] = useState("");
  const [radiology, setRadiology] = useState(false);
  const [feature, setFeature] = useState(false);
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAllTestsNameRequest());
  }, []);

  const viewAllTestsNames = useSelector((state) => state.viewAllTestsName);
  viewAllTestsNames?.data?.data.data.data.map((name) => {
    finalTestsNames.push(name.name);
    TestsObjects.push({ name: name.name, id: name.id });
  });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setServiceIdSubmit(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  serviceIdSubmit.map((item) => {
    TestsObjects.map((ele) => {
      if (ele.name === item) {
        serviceId.push(parseInt(ele.id));
      }
    });
  });
  const updateRes = useSelector((state) => state.makePackage)

  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    const finalDispatch = {
      values,
      serviceId,
      feature,
      prerequisites,
      radiology,
    };

    dispatch(makePackageRequest(finalDispatch));
  };

  const makePackagereducer = useSelector((state) => state.makePackage);

  useEffect(() => {
    if (
      makePackagereducer?.data?.data.status === 201 &&
      makePackagereducer?.data?.data.data.message !== ""
    ) {
      swal(
        "Success",
        `${makePackagereducer?.data?.data.data.message}`,
        "success"
      ).then(() => {
        handleClose();
      });
    }
    if (
      (makePackagereducer.data?.data.status !== 201 &&
        makePackagereducer.data === undefined) ||
      makePackagereducer.error !== null
    ) {
      swal({
        title:
          "" +
          Object.values(makePackagereducer?.error?.data?.response?.data)[0],
        icon: "error",
      }).then(() => {
        props.open(true);
      });
    }
  }, [makePackagereducer]);

  const handleChecked = (e) => {
    if (e.target.value === true) {
      setFeature(!feature);
    } else {
      setFeature(!feature);
    }
  };

  function handleInstruction(e) {
    setPrerequisites(e.target.value);
  }

  function handleRadiology(e) {
    if (e.target.value === "true") {
      setRadiology(true);
    } else {
      setRadiology(false);
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeaderAddPackage}
            >
              Add New Package
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              packageName: "",
              packageDescription: "",
              cost: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {/* {({ isSubmitting, setFieldValue, values }) =>  */}
            {
              // return(
              <Form>
                <div className="inputFieldsContainer seperateTestList">
                  <label>
                    <Field
                      type="text"
                      name="packageName"
                      placeholder="Package Name"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="packageName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="packageDescription"
                      placeholder="Package Description"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="packageDescription"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="cost"
                      placeholder="Cost"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="cost"
                      component="div"
                    />
                  </label>
                  <label>
                    <select className="inputField" onChange={handleInstruction}>
                      <option value="" disabled selected>
                        Select Prerequisites
                      </option>
                      <option value="Fasting Required">Fasting Required</option>
                      <option value="Fasting Not Required">
                        Fasting Not Required
                      </option>
                    </select>
                  </label>

                  <label>
                    <select className="inputField" onChange={handleRadiology}>
                      <option value="" disabled selected>
                        Radiology
                      </option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                  <label>
                    <FormControl
                      sx={{
                        // m:1,
                        width: "40%",
                        marginBottom: "2.9rem",
                        // border: "1px solid #52608E",
                        borderRadius: "12px",
                        border: "hidden",
                        color: "#ffffff",
                        // backgroundColor:"red"
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label ">
                        Select Tests
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={serviceIdSubmit}
                        onChange={handleChange}
                        input={<OutlinedInput label=" Select Tests" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {TestsObjects.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            <Checkbox
                              checked={serviceIdSubmit.indexOf(item.name) > -1}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </label>

                  <label className="featuringCheck">
                    <Field
                      type="checkbox"
                      name="feature"
                      className="featuringCheckField"
                      onChange={handleChecked}
                      checked={feature}
                    />
                    <span>Marked as Featured Plan</span>
                  </label>
                  {serviceIdSubmit.length !== 0 ? (
                    <div className="testListPackage">
                      <div className="testListPackageHeader">Tests</div>
                      <div className="testListPackageList">
                        <ul>
                          {serviceIdSubmit.map((item) => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <button type="submit"    disabled={!flag}className="modalButtonPackage">
                    {flag ? "         Add Package" : <CircularProgress />}
                  </button>
                </div>
              </Form>
              // );
            }
            {/* } */}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddPackage;
