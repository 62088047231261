import * as types from "../../Config/actionType"

export const viewAllProgramOfInsurerRequest = (id) => {
    return {
        type: types.VIEW_ALL_PROGRAM_OF_INSURER_REQUEST,
        id
    }
}

export const viewAllProgramOfInsurerSuccess = (action) => {
    return {
        type: types.VIEW_ALL_PROGRAM_OF_INSURER_SUCCESS,
        action
    }
}

export const viewAllProgramOfInsurerFailure = (action) => {
    return {
        type: types.VIEW_ALL_PROGRAM_OF_INSURER_FAILURE,
        action
    }
}