import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Clinic/ApproveClinicAction";
import NetworkService from "../../../../network/NetworkService";

export function* approveClinic(action) {
  try {
    let response = yield call(NetworkService.approveClinic, action);
    yield put(actions.approveClinicSuccess(response));
  } catch (err) {
    yield put(actions.approveClinicFailure(err));
  }
}

export function* watchApproveClinic() {
  yield takeLatest(types.APPROVE_CLINIC_REQUEST, approveClinic);
}

