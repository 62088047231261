import * as types from "../../Config/actionType"

export const viewBookingRequest = (id) => {
    return {
        type: types.VIEW_BOOKING_REQUEST,
        id
    }
}

export const viewBookingSuccess = (action) => {
    return {
        type: types.VIEW_BOOKING_SUCCESS,
        action
    }
}

export const viewBookingFailure = (action) => {
    return {
        type: types.VIEW_BOOKING_FAILURE,
        action
    }
}