import * as types from "../../Config/Pharmacy/PharmacyActionType"

export const deletePharmacyRequest = (id,data) => {
    return {
        type: types.DELETE_PHARMACY_REQUEST,
        id:id,
        data:data,
        
    }
}

export const deletePharmacySuccess = (action) => {
    return {
        type: types.DELETE_PHARMACY_SUCCESS,
        action
    }
}

export const deletePharmacyFailure = (action) => {
    return {
        type: types.DELETE_PHARMACY_FAILURE,
        action
    }
}