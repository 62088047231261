import React, { useEffect, useState } from 'react'
import "./Banner.css"
import Switch from "@mui/material/Switch";
import upArrow from "../../../assets/upArrow.png"
import Footer from "../../Organisms/Footer/FooterUpper"
import downArrow from "../../../assets/downArrowBlack.png"
import { useDispatch, useSelector } from 'react-redux';
import { getScreenTypesRequest } from "../../../redux/Actions/Banner/getScreenTypesAction"
import { getBannerByScreenTypesRequest } from "../../../redux/Actions/Banner/getBannerByScreenTypesAction"
import eye from "../../../assets/eye.png"
import ModalAddBanner from '../../Organisms/Modal/ModalAddBanner';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { deleteBannerRequest } from "../../../redux/Actions/Banner/deleteBannerAction"
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { deletePositionBannerRequest } from "../../../redux/Actions/Banner/deletePositionBannerAction"
import { useNavigate } from 'react-router-dom';
import InfoIcon from "@mui/icons-material/Info";

function Banner() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [fromWeb, setFromWeb] = useState();
  const [fromWebDemo, setFromWebDemo] = useState();
  const [screenType, setScreenType] = useState();
  const [activeKey, setActiveKey] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [bannerName, setBannerName] = useState();
  const [bannerEnumName, setBannerEnumName] = useState();
  const [showButton, setShowButton] = useState(false);


  const getScreenTypes = useSelector((state) => state.getScreenTypes);
  const getBannerByScreenTypes = useSelector((state) => state.getBannerByScreenTypes);
  const deleteBanner = useSelector((state) => state.deleteBanner);
  const deletePositionBanner = useSelector((state) => state.deletePositionBanner);



  useEffect(() => {
    const fwe = localStorage.getItem("fromWeb") === "true" ? true : false;
    setFromWeb(fwe)
  }, [fromWebDemo])

  function handleScreenTypeList(e) {
    localStorage.setItem("fromWeb", e.target.value)
    setFromWebDemo(e.target.value)
  }

  const handleDivClick = (key, id) => {
    setScreenType(id)
    setActiveKey(key === activeKey ? null : key);

  };

  useEffect(() => {
    if (fromWeb !== undefined) {
      dispatch(getScreenTypesRequest(fromWeb));
      setActiveKey(null);
    }

  }, [fromWeb])
  useEffect(() => {
    if (screenType !== undefined) {
      dispatch(getBannerByScreenTypesRequest(screenType));
    }
  }, [screenType])




  function handleBanner(name, bannerEnum) {
    setOpenModal(!openModal)
    setBannerName(name)
    setBannerEnumName(bannerEnum)
  }

  function handleClose(bool) {
    setOpenModal(bool);
  }

  function handleDisable(screenType) {
    Swal.fire({
      title: 'Do you want to change visibility ?',
      showDenyButton: true,
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          screenType: screenType,
          bool: false
        }
        dispatch(deleteBannerRequest(data));
      } else if (result.isDenied) {
        window.location.reload();
      }
    })

  }

  function handleDelete(screenType) {
    Swal.fire({
      title: 'Do you want to delete ?',
      showDenyButton: true,
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          screenType: screenType,
          bool: true
        }
        dispatch(deleteBannerRequest(data));
      } else if (result.isDenied) {
        window.location.reload();
      }
    })

  }

  function handlePositionDisable(screenType, position) {
    Swal.fire({
      title: 'Do you want to change visibility ?',
      showDenyButton: true,
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          screenType: screenType,
          bool: false,
          position: position,

        }
        dispatch(deletePositionBannerRequest(data));
      } else if (result.isDenied) {
        window.location.reload();
      }
    })

  }

  function handlePositionDelete(screenType, position) {
    Swal.fire({
      title: 'Do you want to delete ?',
      showDenyButton: true,
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          screenType: screenType,
          bool: true,
          position: position,
        }
        dispatch(deletePositionBannerRequest(data));
      } else if (result.isDenied) {
      }
    })

  }




  if (deleteBanner.data?.data.status === 200 && deleteBanner.data?.data.data.message !== "") {
    swal({
      title: `${deleteBanner.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    })
  }
  if (deletePositionBanner.data?.data.status === 200 && deletePositionBanner.data?.data.data.message !== "") {
    swal({
      title: `${deletePositionBanner.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    })
  }

  function handleView(bannerId, bannerName, bannerType, bannerPosition, screenType, bannerTypeEnum, screenTypeEnum) {
    navigate("/bannerList", { state: { bannerId: bannerId, bannerName: bannerName, bannerType: bannerType, bannerPosition: bannerPosition, screenType: screenType, bannerTypeEnum: bannerTypeEnum, screenTypeEnum: screenTypeEnum } })
  }



  return (
    <div className='BannerListing'>
      <ModalAddBanner open={openModal} handleClose={handleClose} bannerName={bannerName} bannerEnumName={bannerEnumName} />
      <div className='BannerListingSection1'>
        <p>Banners</p>
      </div>
      <div className='BannerListingSection2'>
        <div className='BannerListingSection2_1'>
          {fromWeb === true ? <select name="cars" id="cars" onChange={handleScreenTypeList}>

            <option value="true" selected>Web</option>
            <option value="false">App</option>
          </select> : <select name="cars" id="cars" onChange={handleScreenTypeList}>

            <option value="true">Web</option>
            <option value="false" selected>App</option>
          </select>}
          <div className="BannerHint">
            <InfoIcon className="eyeicon"></InfoIcon>{" "}
            <span class="BannerHintDescriptionText">
              App :  Aspect Ratio<br />
              TypeABanner :- 3.25 : 1<br />
              TypeBBanner :- 3.25 : 1<br />
              TypeABanner(position 3) :- 3.25:1<br /><br />
              Web :  Aspect Ratio<br />
              TypeABanner :- 2.45 : 1<br />
              TypeABanner(Position 2) :- 4.6 : 1<br />
            </span>
          </div>
        </div>

        <div className='BannerListingSection2_2'>
          {getScreenTypes.data?.data.data.data.map((item, index) => (
            <>
              <div className='BannerListingSection2_2Children'>
                <div className='BannerListingSection2_2ChildrenLeft' ><p>{item.name}</p></div>
                <div className='BannerListingSection2_2ChildrenRight'>
                  {activeKey === index ?
                    <button onClick={() => handleBanner(item.name, item.id)} style={{ cursor: "pointer", marginRight: "1em", borderRadius: "6px" }}>+ Add Banner</button>
                    : ""}
                  {item.visible === null ? "" : item.visible === false ?
                    <Switch sx={{ color: "#52608E" }}
                      onClick={() => handleDisable(item.id)}
                    />

                    :


                    <Switch
                      defaultChecked={true}
                      sx={{ color: "#52608E" }}
                      onClick={() => handleDisable(item.id)}
                    />
                  }
                  {item.visible === null ? "" : <DeleteForeverRoundedIcon onClick={() => handleDelete(item.id)} style={{ cursor: "pointer", marginRight: "1em", marginLeft: "1em" }} />}
                  {activeKey === index ? (
                    <img
                      alt="error"
                      src={upArrow}
                      onClick={() => handleDivClick(index, item.id)}
                      className="upArrowImage"
                    />
                  ) : (
                    <img
                      alt="error"
                      src={downArrow}
                      onClick={() => handleDivClick(index, item.id)}
                      className="upArrowImage"
                    />
                  )}
                </div>
              </div>
              <div className='dropdownBanner'
                style={{ display: activeKey === index ? "flex" : "none", }}
              >
                <div className='dropdownHeader'>
                  <div className='dropdownHeaderChild'>Banner Type</div>
                  <div className='dropdownHeaderChildName'>Banner Name</div>
                  <div className='dropdownHeaderChild'>Position</div>
                  <div className='dropdownHeaderChildModify'>Modify</div>
                </div>
                {getBannerByScreenTypes.data?.data.data.data.length === 0 ? <div className='dropdownNoBanner'>No banners</div> : getBannerByScreenTypes.data?.data.data.data.map((ele) => (
                  <div className='dropdownHeaderValue'>
                    <div className='dropdownValueChild'>{ele.bannerType}</div>
                    <div className='dropdownValueChildName'>{ele.bannerName}</div>
                    <div className='dropdownValueChild'>{ele.position}</div>
                    <div className='dropdownValueChildModify'>

                      {ele.visible === null ? "" : ele.visible === false ?
                        <Switch sx={{ color: "#52608E", marginRight: "1.5em" }}
                          onClick={() => handlePositionDisable(item.id, ele.position)}
                        /> : <Switch
                          defaultChecked
                          sx={{ color: "#52608E", marginRight: "1.5em" }}
                          onClick={() => handlePositionDisable(item.id, ele.position)}
                        />}
                      <img src={eye} style={{ marginRight: "1.5em", cursor: "pointer", width: "1em", height: ".7em" }}
                        onClick={() =>
                          handleView(ele.bannerId, ele.bannerName, ele.bannerType, ele.position, item.name, ele.bannerTypeEnum, item.id)} />
                      <DeleteForeverRoundedIcon style={{ cursor: "pointer" }} onClick={() => handlePositionDelete(item.id, ele.position)} />
                    </div>
                  </div>
                ))}

              </div>
            </>
          ))}



        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Banner