import * as types from "../../../Config/actionType"

export const AllHospitalNameDropDownRequest = (data) => {
    return {
        type: types.ALL_HOSPITAL_NAME_DROPDOWN_REQUEST,
        data:data,
    }
}

export const AllHospitalNameDropDownSuccess = (data) => {
    return {
        type: types.ALL_HOSPITAL_NAME_DROPDOWN_SUCCESS,
        data:data,
    }
}

export const AllHospitalNameDropDownFailure = (data) => {
    return {
        type: types.ALL_HOSPITAL_NAME_DROPDOWN_FAILURE,
        data:data,
    }
}
