import { Route, Routes } from "react-router-dom";
import Career from "../../component/Organisms/Footer/Career/Career";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions";
import Appointment from "../../component/Pages/Appointments/Appointment";
import CreatePrescription from "../../component/Pages/Appointments/CreatePrescription";
import ViewAppointment from "../../component/Pages/Appointments/ViewAppointment";
import AppointmentStatus from "../../component/Pages/Dashboard/HospitalDashboard/AppointmentStatus";
import DoctorViewDetails from "../../component/Pages/Dashboard/HospitalDashboard/DoctorViewDetails";
import HospitalDashboard from "../../component/Pages/Dashboard/HospitalDashboard/HospitalDashboard";
import HospitalDoctorList from "../../component/Pages/Dashboard/HospitalDashboard/HospitalDoctorList";
import ViewAppointmentStatus from "../../component/Pages/Dashboard/HospitalDashboard/ViewAppointmentStatus";
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";
import UnderConstuction from "../../component/Pages/UnderConstruction/UnderConstruction";

export default function DoctorPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/hospital-Dashboard" element={<HospitalDashboard />} />
      <Route path="/hospitalAppointment" element={<Appointment />} />
      <Route
        path="/hospitalViewAppointment/:id"
        element={<ViewAppointment />}
      />
      <Route path="/underConstruction" element={<UnderConstuction />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      {/* <Route path="/HospitalDashboard" element={<HospitalDashboard />} /> */}
      <Route path="/HospitalDoctorList" element={<HospitalDoctorList />} />
      <Route path="/HospitalViewDoctor/:id" element={<DoctorViewDetails />} />
      <Route path="/AppointmentStatus" element={<AppointmentStatus />} />
      <Route path="/DashboardAppointmentstatus" element={<AppointmentStatus />} />

      <Route path="/ViewAppointmentStatus/:id" element={<ViewAppointmentStatus />} />
      <Route path="/CreatePrescription/:id" element={<CreatePrescription />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
    </Routes>
  );
}
