import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../Organisms/Footer/FooterUpper";
import Swal from "sweetalert2";
import "./HospitalDoctorList.css";
import AutoComplete from "../../../Molecules/Autocomplete/AutoComplete";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import storage from "../../../../Utils/storage";
import DisableDoctorModal from "../../../Organisms/Modal/DisableDoctorModal";
import { useNavigate } from "react-router-dom";
import { AllHospitalNameDropDownRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/AllHospitalNameDropdown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewAllPaymentRequest } from "../../../../redux/Actions/Payment/viewAllPaymentAction";
import { useLocation } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "../../../../assets/loader.gif";

function AppointmentStatus() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [formattedStartDate, setFormattedStartDate] = useState("");

  const [endDate, setEndDate] = useState();
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDate = (e) => {
    var date = new Date(e.target.value).getTime();

    setStartDate(date);
    setFormattedStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    var date = new Date(e.target.value).getTime();
    setEndDate(date);
    setFormattedEndDate(e.target.value);
  };

  const [hospitalDropdown, setHospitaldropdown] = useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const [delOpen, setdelOpen] = React.useState(false);
  const [delId, setDelId] = useState();
  const [searchPage, setSearchPage] = useState(1);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState("");
  const [statusFilter, setStatusFilter] = React.useState();

  const AllHospitalNameDropdown = useSelector(
    (state) => state.AllHospitalNameDropdown
  );

  useEffect(() => {
    setStatusFilter(state?.value);
  }, []);

  const viewAllPayment = useSelector((state) => state.viewAllPayment);

  useEffect(() => {
    if (hospitalDropdown === null) {
      dispatch(
        viewAllPaymentRequest(
          1,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          null,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
    } else {
      dispatch(
        viewAllPaymentRequest(
          1,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
      setPage(1);
      setSearchPage(page);
    }
  }, [hospitalDropdown]);

  useEffect(() => {
    if (startDate != undefined && endDate === undefined) {
      Swal.fire({
        title: "Please Add EndDate",
        icon: "error",
      });
    } else if (startDate > endDate && endDate !== null) {
      Swal.fire({
        title: "EndDate should be bigger than StartDate",
        icon: "error",
      });
    } else {
      dispatch(
        viewAllPaymentRequest(
          1,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
      setPage(1);
    }
  }, [startDate, endDate]);

  // useEffect(() => {
  //   setIsLoading(viewAllPayment.isLoading);
  // }, [ startDate, endDate]);

  useEffect(() => {
    dispatch(AllHospitalNameDropDownRequest());
  }, []);

  let hospitalNames = [{ label: "All Hospital", id: "" }];

  AllHospitalNameDropdown.data?.data?.data?.data.map((itm) => {
    hospitalNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleSearch = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllPaymentRequest(
          page,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
      setPage(1);
    } else {
      dispatch(
        viewAllPaymentRequest(
          searchPage,
          pageSize,
          startDate,
          endDate,
          e.target.value,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
    }
    setSearchText(e.target.value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllPaymentRequest(
          value,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
      setPage(value);
    } else {
      dispatch(
        viewAllPaymentRequest(
          value,
          pageSize,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllPaymentRequest(
          page,
          event.target.value,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
    } else {
      dispatch(
        viewAllPaymentRequest(
          searchPage,
          event.target.value,
          startDate,
          endDate,
          searchText,
          window.location.pathname == "/AppointmentStatus"
            ? ["COMPLETED", "FINISHED", "CONFIRMED"]
            : state?.appointmentStatus,
          hospitalDropdown,
          window.location.pathname == "/AppointmentStatus"
            ? null
            : state?.paymentStatus
        )
      );
    }
    setPage(1);
    setPageSize(event.target.value);
  };

  const handleHospitalNames = (e, val) => {
    storage.setStorage("cityName", val.label);

    setHospitaldropdown(val.id);
  };

  const handleViewDetails = (id) => {
    navigate(`/ViewAppointmentStatus/${id}`);
  };

  const handleAppointmentStatus = (e) => {
    setStatusFilter("Total Number of Appointment");
  };
  useEffect(() => {
    dispatch(
      viewAllPaymentRequest(
        page,
        pageSize,
        startDate,
        endDate,
        searchText,
        statusFilter === "Total Number of Appointment"
          ? ["COMPLETED", "FINISHED", "CONFIRMED"]
          : state?.appointmentStatus,
        hospitalDropdown,
        window.location.pathname == "/AppointmentStatus"
          ? null
          : state?.paymentStatus
      )
    );
  }, [statusFilter]);

  const columns = [
    {
      field: "customId",
      headerClassName: "super-app-theme--header",
      headerName: " Appointment Id",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "doctorName",
      headerClassName: "super-app-theme--header",
      headerName: "Doctor",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Paid Status",
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { paymentStatus } = params.row;
        return (
          <>
            {params.row?.appointmentStatus === "CONFIRMED" ? (
              <Typography variant="body1"> UPCOMING</Typography>
            ) : (
              <Typography variant="body1">{paymentStatus}</Typography>
            )}
          </>
        );
      },
    },
    {
      field: "timeSlot",
      headerClassName: "super-app-theme--header",
      headerName: "Appointment Date",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["timeSlot"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "patientName",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Name",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "amount",
      headerClassName: "super-app-theme--header",
      headerName: "Amount",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      field: "Modify",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 }}
              onClick={() => handleViewDetails(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div className="grid">
          <h4 className="h4">Appointments</h4>
          <div className="mainBox">
            {delOpen && (
              <DisableDoctorModal delOpen={setdelOpen} delId={delId} />
            )}

            <div className="head_doctor_hospitaldash">
              <div className="leftHead">
                <h5 className="list">List of Appointments</h5>
                <div className="doctorcount">
                  {viewAllPayment.data?.data.data.data.totalAppointments}{" "}
                  Appointments
                </div>
              </div>
              <div className="Doctor_datestyle_hospdash">
                <div className="paymentStartDate_hospdash">
                  <input
                    type="date"
                    className="HospitalDashboardDateInput"
                    onChange={(e) => handleStartDate(e)}
                    value={formattedStartDate}
                    style={{
                      border:
                        endDate !== 0 && startDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>

                <div className="paymentEndDate_hospdash">
                  <input
                    type="date"
                    className="HospitalDashboardDateInput"
                    onChange={(e) => handleEndDate(e)}
                    value={formattedEndDate}
                    style={{
                      border:
                        startDate !== 0 && endDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <hr />
            <div className="row2">
              <div className="requestCity">
                <AutoComplete
                  onChange={(event, newValue) => {
                    handleHospitalNames(event, newValue);
                  }}
                  heading="All Hospitals"
                  cityList={hospitalNames}
                />
                {state === null ? (
                  ""
                ) : (
                  <div className="AppointmentStatusvalue">
                    {statusFilter}
                    <div className="AppointmentStatusvalueCross"></div>
                  </div>
                )}
              </div>
              <div className="searchSection">
                <div className="searchBar">
                  <TextField
                    type="text"
                    name="search"
                    onChange={handleSearch}
                    variant="outlined"
                    sx={{
                      border: "1px solid #52608E",
                      borderRadius: "6px",
                      width: "350px",
                    }}
                    placeholder="Search "
                    size="small"
                    hiddenLabel="true"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "#52608E" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
              {viewAllPayment.data?.data.data.data
                .appointmentLotResponseDtos && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    "&:hover:not(.Mui-disabled)": {
                      cursor: "pointer",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  rows={
                    viewAllPayment?.data?.data.data.data
                      .appointmentLotResponseDtos
                  }
                  columns={columns}
                  rowCount={
                    viewAllPayment?.data?.data?.data?.data.totalAppointments
                  }
                  pageSize={pageSize}
                  disableColumnMenu
                  rowsPerPageOptions={[10, 50, 100]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  paginationMode="server"
                  components={{
                    Footer: "none",
                  }}
                />
              )}
            </Box>
            <div className="MainTablePagination">
              <p>Rows Per Page:</p>
              <select
                className="dropDown"
                defaultValue={10}
                onChange={handleDropChange}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <Pagination
                shape="rounded"
                count={viewAllPayment?.data?.data?.data?.data.totalPages}
                color="primary"
                onChange={handlePageChange}
                page={
                  searchText === "" || searchText.length <= 1
                    ? page
                    : searchPage
                }
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default AppointmentStatus;
