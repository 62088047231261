import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Services/viewAllServicesNameAndUuidAction";
import NetworkService from "../../../../network/NetworkService";

export function* viewAllServicesNameAndUuidSaga() {
  try {
    //
    let response = yield call(NetworkService.viewAllServicesNameAndUuid);
    //
    yield put(actions.viewAllServicesNameAndUuidSuccess(response));
  } catch (err) {
    yield put(actions.viewAllServicesNameAndUuidFailure(err));
  }
}



export function* watchViewAllServicesNameAndUuidSaga() {
  yield takeLatest(types.VIEW_ALL_SERVICES_NAMES_AND_UUID_REQUEST, viewAllServicesNameAndUuidSaga);
}

