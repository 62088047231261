import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { assignProgramToEmployeeRequest } from "../../../redux/Actions/Corporate/assignProgramToEmployeeAction";
import { getAllServiceRequest } from "../..//../redux/Actions/Service/getAllServiceAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";

const loginSchema = Yup.object().shape({
  employeeId: Yup.string().required("Required Field"),
  name: Yup.string()
    .required("Required Field")
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    ),
  contactNumber: Yup.string()
    .min(10, "Must be exactly 10 digits")
    .max(10, "Must be exactly 10 digits")
    .required("Required Field"),
  email: Yup.string()
    .matches(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
      "Enter a Valid E-mail Address"
    )
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function ModalAssignEmployeeProgram(props) {
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAllCityRequest());
    dispatch(getAllServiceRequest());
  }, []);

  const [recvEmail, setRecvEmail] = useState("");

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const emailDetails = useMemo(
    () => emailDetail?.data?.data.data,
    [emailDetail]
  );

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      dispatch(assignProgramToEmployeeRequest(values));
      setSubmitting(false);
    }, 400);
  };

  const res = useSelector((state) => state.assignProgramToEmployee);
  if (res.data?.data.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Program Assigned Successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
      // setSwal(false);
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Assign Program To Employee
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              employeeId: "",
              name: "",
              contactNumber: "",
              email: recvEmail,
              password: "",
              confirmPassword: "",
              uuid: props.uuid,
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form autoComplete="off">
                <div className="inputFieldsContainer">
                  {/* <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Hospital Email"
                      className="inputField"
                      onBlur={(e) => {
                        handleCheckEmail(e);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                  </label>
                  {emailDetail?.data?.data?.data?.message === null && (
                    <>
                      <div className="emailExist">Email exist</div>
                    </>
                  )} */}

                  <label>
                    <Field
                      type="text"
                      name="employeeId"
                      placeholder="Employee Id"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="employeeId"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Employee Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Employee Email"
                      className="inputField"
                      //   onBlur={(e) => {
                      //     handleCheckEmail(e);
                      //   }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Contact Number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>

                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Generate Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="password"
                        component="div"
                      />
                    </label>
                  )}

                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Retype Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="confirmPassword"
                        component="div"
                      />
                    </label>
                  )}

                  <button type="submit" className="modalButton">
                    Assign Program
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAssignEmployeeProgram;
