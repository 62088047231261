import * as types from "../../Config/actionType";

export const validateOtpRequest = (otp, id) => {
  return {
    type: types.VALIDATE_OTP_REQUEST,
    otp,
    id,
  };
};

export const validateOtpSuccess = (data) => {
  return {
    type: types.VALIDATE_OTP_SUCCESS,
    data: data,
  };
};

export const validateOtpFailure = (data) => {
  return {
    type: types.VALIDATE_OTP_FAILURE,
    data: data,
  };
};
