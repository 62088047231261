import * as types from "../../Config/actionType"

export const viewAllTestsNameRequest = () => {
    return {
        type: types.VIEW_ALL_TESTS_NAME_REQUEST,
    }
}

export const viewAllTestsNameSuccess = (data) => {
    return {
        type: types.VIEW_ALL_TESTS_NAME_SUCCESS,
        data:data,
    }
}

export const viewAllTestsNameFailure = (data) => {
    return {
        type: types.VIEW_ALL_TESTS_NAME_FAILURE,
        data:data,
    }
}