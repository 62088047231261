import * as types from "../../../Config/actionType";

export const networkViewHospitalRequest = (id) => {
  return {
    type: types.NETWORK_VIEW_HOSPITAL_REQUEST,
    id
  };
};

export const networkViewHospitalSuccess = (data) => {
  return {
    type: types.NETWORK_VIEW_HOSPITAL_SUCCESS,
    data: data,
  };
};

export const networkViewHospitalFailure = (data) => {
  return {
    type: types.NETWORK_VIEW_HOSPITAL_FAILURE,
    data: data,
  };
};