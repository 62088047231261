import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/ProfessionalService/ProfessionalSevicesViewRequest"
import NetworkService from "../../../../network/NetworkService";

export function* networkViewProfessionalServiceSaga(action) {
  try {
    let response = yield call(NetworkService.networkViewProfessionalService, action);
    yield put(actions.networkViewProfessionalServiceSuccess(response));
  } catch (err) {
    yield put(actions.networkViewProfessionalServiceFailure(err));
  }
}

export function* watchNetworkViewProfessionalServiceSaga() {
  yield takeLatest(types.NETWORK_VIEW_PROFESSIONAL_SERVICES_REQUEST, networkViewProfessionalServiceSaga);
}