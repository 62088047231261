import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/updateServiceDescriptionAction";
import NetworkService from "../../../network/NetworkService";

export function* updateServiceDescriptionSaga(action) {
  try {
    let response = yield call(NetworkService.updateServiceDescription, action);
    yield put(actions.updateServiceDescriptionSuccess(response));
  } catch (err) {
    yield put(actions.updateServiceDescriptionFailure(err));
  }
}

export function* watchUpdateServiceDescriptionSaga() {
  yield takeLatest(types.UPDATE_SERVICE_DESCRIPTION_REQUEST, updateServiceDescriptionSaga);
}