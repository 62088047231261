import * as types from "../../Config/actionType"

export const viewAllDoctorsRequest = (id,page,pageSize,name,city,status) => {
    return {
        type: types.VIEW_ALL_DOCTORS_REQUEST,
        id,
        page,
        pageSize,
        name,
        city,
        status
    }
}

export const viewAllDoctorsSuccess = (action) => {
    return {
        type: types.VIEW_ALL_DOCTORS_SUCCESS,
        action
    }
}

export const viewAllDoctorsFailure = (action) => {
    return {
        type: types.VIEW_ALL_DOCTORS_FAILURE,
        action
    }
}