import React, { useEffect, useState } from "react";
import "./AddLifeStyle.css";
import Chip from "@mui/material/Chip";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { viewLifeStyleRequest } from "../../../redux/Actions/LifeStyle/viewLifeStyleAction";
import { useNavigate, useParams } from "react-router-dom";
import { viewAllTestsNameRequest } from "../../../redux/Actions/Booking/viewAllTestsNameAction";
import { getPackageDropdownRequest } from "../../../redux/Actions/Packages/getPackageDropdownAction";
import { UpdateLifeStyleRequest } from "../../../redux/Actions/LifeStyle/UpdateLifeStyleAction";
import swal from "sweetalert";
import { AddLifeStyleRequest } from "../../../redux/Actions/LifeStyle/AddLifeStyleAction";
import CircularProgress from "@mui/material/CircularProgress";

function UpdateLifeStyle() {
  const { id } = useParams();

  const pathname = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(viewLifeStyleRequest(id));
  }, [id]);

  const viewLifeStyleParticular = useSelector(
    (state) => state.lifeStyle.viewLifeStyle
  );

  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedTestsUUIDS, setSelectedTestsUUIDS] = useState([]);
  const [selectedPackagesUUIDS, setSelectedPackagesUUIDS] = useState([]);
  const [availableTestOptions, setAvailableTestOptions] = useState([]);
  const [lifestyleName, setLifestyleName] = useState(pathname?.state?.name);

  useEffect(() => {
    dispatch(viewLifeStyleRequest(id));
  }, [id]);

  useEffect(() => {
    setAvailableTestOptions(viewLifeStyleParticular.data?.data.data.testData);
  }, [viewLifeStyleParticular]);

  useEffect(() => {
    dispatch(viewAllTestsNameRequest());
    dispatch(getPackageDropdownRequest());
  }, []);
  const viewAllTestsNames = useSelector((state) => state.viewAllTestsName);
  const viewPackagesDropdown = useSelector((state) => state.packageDropdown);

  const handleTestSelect = (e) => {
    viewAllTestsNames.data?.data.data.data.map((item) => {
      if (item.id === e.target.value) {
        if (selectedTests.some((ele) => ele === item.name) === false) {
          setSelectedTests([...selectedTests, item.name]);
        }
      }
    });
  };

  const handlePackageSelect = (e) => {
    viewPackagesDropdown.data?.action.data.data.map((item) => {
      if (item.id === e.target.value) {
        if (selectedPackages.some((ele) => ele === item.name) === false) {
          setSelectedPackages([...selectedPackages, item.name]);
        }
      }
    });
  };

  useEffect(() => {
    const updatedUUIDS = selectedTests.map((ele) => {
      const matchingItem = viewAllTestsNames.data?.data.data.data.find(
        (item) => item.name === ele
      );
      return matchingItem ? matchingItem.id : null;
    });

    setSelectedTestsUUIDS(updatedUUIDS.filter((id) => id !== null));
  }, [selectedTests, viewAllTestsNames.data?.data.data.data]);

  useEffect(() => {
    const updatedUUIDS = selectedPackages.map((ele) => {
      const matchingItem = viewPackagesDropdown.data?.action.data.data.find(
        (item) => item.name === ele
      );
      return matchingItem ? matchingItem.id : null;
    });

    setSelectedPackagesUUIDS(updatedUUIDS.filter((id) => id !== null));
  }, [selectedPackages, viewPackagesDropdown.data?.action.data.data]);

  const handleDeleteTest = (index) => {
    const updatedTests = [...selectedTests];
    updatedTests.splice(index, 1);
    setSelectedTests(updatedTests);

    const updatedUUIDS = [...selectedTestsUUIDS];
    updatedUUIDS.splice(index, 1);
    setSelectedTestsUUIDS(updatedUUIDS);
  };
  const handleDeletePackage = (index) => {
    const updatedTests = [...selectedPackages];
    updatedTests.splice(index, 1);
    setSelectedPackages(updatedTests);

    const updatedUUIDS = [...selectedPackagesUUIDS];
    updatedUUIDS.splice(index, 1);
    setSelectedPackagesUUIDS(updatedUUIDS);
  };

  useEffect(() => {
    let comingTest = [];
    let comingPackages = [];
    if (viewLifeStyleParticular.data?.status === 200) {
      viewLifeStyleParticular.data?.data.data.testData.map((item) => {
        comingTest.push(item.name);
      });
      setSelectedTests([...comingTest]);
    }

    if (viewLifeStyleParticular.data?.status === 200) {
      viewLifeStyleParticular.data?.data.data.packageData.map((item) => {
        comingPackages.push(item.name);
      });
      setSelectedPackages([...comingPackages]);
    }
    // setLifestyleName(viewLifeStyleParticular.data?.data.data.lifeStylePackageName)
  }, [viewLifeStyleParticular]);

  function lifeStyleName(e) {
    setLifestyleName(e.target.value);
  }
  const updateRes = useSelector((state) => state.lifeStyle.UpdateLifeStyle)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const updateLifestyle = () => {
    if (
      viewLifeStyleParticular.error?.action.response.status === 404 &&
      viewLifeStyleParticular.error?.action.response.data.errorMessage !== ""
    ) {
      let data = {
        lifestyleName: lifestyleName,
        lifeStylePackageEnum: id,
        selectedTestsUUIDS: selectedTestsUUIDS,
        selectedPackagesUUIDS: selectedPackagesUUIDS,
      };
      dispatch(AddLifeStyleRequest(data));
    } else {
      if (
        selectedTestsUUIDS.length == 0 &&
        selectedPackagesUUIDS.length === 0
      ) {
        swal({
          title: "Please Select Tests/Packages",
          icon: "info",
        });
      } else {
    setFlag(false)

        let data = {
          lifestyleName: lifestyleName,
          id: viewLifeStyleParticular.data?.data.data.lifeStylePackageEnum,
          selectedTestsUUIDS: selectedTestsUUIDS,
          selectedPackagesUUIDS: selectedPackagesUUIDS,
          uuid: viewLifeStyleParticular.data?.data.data.lifeStylePackageUuid,
        };
        dispatch(UpdateLifeStyleRequest(data));
      }
    }
  };

  const UpdateLifeStyle = useSelector(
    (state) => state.lifeStyle.UpdateLifeStyle
  );

  if (
    UpdateLifeStyle.data?.data.status === 200 &&
    UpdateLifeStyle.data?.data.data.message !== ""
  ) {
    swal({
      title: `${UpdateLifeStyle.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      UpdateLifeStyle.data.data.status = null;
      navigate("/lifeStyle");
    });
  }

  if (
    (UpdateLifeStyle.error?.data.response.status === 400 ||
      UpdateLifeStyle.error?.data.response.status === 403 ||
      UpdateLifeStyle.error?.data.response.status === 404 ||
      UpdateLifeStyle.error?.data.response.status === 401) &&
    UpdateLifeStyle.error?.data.response.data.lifeStylePackageName !== ""
  ) {
    swal({
      title: `${UpdateLifeStyle.error?.data.response.data.lifeStylePackageName}`,
      icon: "info",
    }).then(() => {
      window.location.reload();
    });
  }

  const AddLifeStyle = useSelector((state) => state.lifeStyle.AddLifeStyle);

  if (
    AddLifeStyle.data?.data.status === 201 &&
    AddLifeStyle.data?.data.data.message !== ""
  ) {
    swal({
      title: `${AddLifeStyle.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      AddLifeStyle.data.data.status = null;
      navigate("/lifeStyle");
    });
  }

  if (
    (AddLifeStyle.error?.data.response.status === 400 ||
      AddLifeStyle.error?.data.response.status === 403 ||
      AddLifeStyle.error?.data.response.status === 401 ||
      AddLifeStyle.error?.data.response.status === 404) &&
    AddLifeStyle.error?.data.response.data.lifeStylePackageName !== ""
  ) {
    swal({
      title: `${AddLifeStyle.error?.data.response.data.lifeStylePackageName}`,
      icon: "info",
    }).then(() => {
      AddLifeStyle.error.data.response.status = null;
    });
  }

  return (
    <div className="mainDivLifeStyle">
      <div className="nameAndOtherText">
        <div className="nameAndSelect">
          <div className="nameLifeStyle">Name</div>
          <div className="nameLifeStyleValue">
            <input
              name="lifeStyleName"
              placeholder="Life Style Package Name"
              id="parameterName"
              // disabled
              disabled={pathname?.state?.fixed ? true : false}
              value={lifestyleName}
              onChange={lifeStyleName}
            />
          </div>
        </div>
        <div>
          <div className="nameAndSelect">
            <div className="nameLifeStyle">Test</div>
            <div className="nameLifeStyleValue">
              <select
                className="selectNmaeLifeStyle"
                onChange={handleTestSelect}
                value=""
              >
                <option value="" disabled>
                  Select Test
                </option>
                {viewAllTestsNames.data?.data.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="nameAndSelect">
            <div className="nameLifeStyle">Packages</div>
            <div className="nameLifeStyleValue">
              <select
                className="selectNmaeLifeStyle"
                onChange={handlePackageSelect}
                value=""
              >
                <option value="" disabled>
                  Select Package
                </option>
                {viewPackagesDropdown.data?.action.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="chipsetTagAndName">
            <div className="nameLifeStylePackage">
              {selectedTests.length !== 0 ? "Selected Tests" : ""}
            </div>
            <div className="chipsetTags">
              {selectedTests?.map((test, index) => (
                <>
                  <div key={index} className="chipDIvs">
                    <Chip
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#67E4CE",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        height: "100%",
                        color: "#52608E",
                        fontWeight: "500",
                        borderRadius: "10px",
                      }}
                      label={test}
                      onDelete={() => handleDeleteTest(index)}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="chipsetTagAndName">
            <div className="nameLifeStylePackage">
              {selectedPackages.length !== 0 ? "Selected Packages" : ""}
            </div>
            <div className="chipsetTags">
              {selectedPackages?.map((pkg, index) => (
                <>
                  <div key={index} className="chipDIvs">
                    <Chip
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#67E4CE",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        height: "100%",
                        width: "100%",
                        color: "#52608E",
                        fontWeight: "500",
                        borderRadius: "10px",
                      }}
                      label={pkg}
                      onDelete={() => handleDeletePackage(index)}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        <button className="buttonOfLifeStyle" disabled={!flag} onClick={updateLifestyle}>
          {flag ? "   Save" : <CircularProgress />}
        </button>
      </div>
    </div>
  );
}

export default UpdateLifeStyle;
