import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/relationship"
import NetworkService from "../../../network/NetworkService";

export function* relationshipSaga(action) {
 

  try {
    let response = yield call(NetworkService.relationship, action);
    yield put(actions.relationshipSuccess(response));
  } catch (err) {
    yield put(actions.relationshipFailure(err));
  }
}

export function* watchrelationshipSaga() {
  yield takeLatest(types.RELATIONSHIP_REQUEST, relationshipSaga);
}