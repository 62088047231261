import * as types from "../../Config/Product/ProductActionType";

export const updateBrandRequest = (data,id) => {
  return {
    type: types.UPDATE_BRAND_REQUEST,
    data,
    id,
  };
};

export const updateBrandSuccess = (data) => {
  return {
    type: types.UPDATE_BRAND_SUCCESS,
    data: data,
  };
};

export const updateBrandFailure = (data) => {
  return {
    type: types.UPDATE_BRAND_FAILURE,
    data: data,
  };
};
