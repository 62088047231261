import * as types from "../../Config/actionType"

export const getAllServiceRequest = () => {
    return {
        type: types.GET_ALL_SERVICE_REQUEST,
    }
}

export const getAllServiceSuccess = (action) => {
    return {
        type: types.GET_ALL_SERVICE_SUCCESS,
        action
    }
}

export const getAllServiceFailure = (action) => {
    return {
        type: types.GET_ALL_SERVICE_FAILURE,
        action
    }
}