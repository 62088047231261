import * as types from "../../Config/Product/ProductActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const deleteCategoryReducer = (state = initialState, action = {}) => {

  switch (action.type) {
    case types.DELETE_CATEGORY_SUCCESS:
     
      return {
        ...initialState,
        data: action,
      };
    case types.DELETE_CATEGORY_FAILURE:
     
      return {
        ...initialState,
        error: action,
      };
    case types.DELETE_CATEGORY_REQUEST:
     
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
