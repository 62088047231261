import * as types from "../../../Config/actionType"

export const assignProgramInsurerRequest = (data) => {
    return {
        type: types.ASSIGN_PROGRAM_INSURER_REQUEST,
        data
    }
}

export const assignProgramInsurerSuccess = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_INSURER_SUCCESS,
        action
    }
}

export const assignProgramInsurerFailure = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_INSURER_FAILURE,
        action
    }
}