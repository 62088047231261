import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/validateOtpAction"
import NetworkService from "../../../network/NetworkService";

export function* validateOtpSaga(action) {
  try {
    let response = yield call(NetworkService.validateOtp, action);
    yield put(actions.validateOtpSuccess(response));
  } catch (err) {
    yield put(actions.validateOtpFailure(err));
  }
}

export function* watchValidateOtpSaga() {
  yield takeLatest(types.VALIDATE_OTP_REQUEST, validateOtpSaga);
}