import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/viewServiceAction";
import NetworkService from "../../../network/NetworkService";

export function* viewServiceSaga(action) {
  try {
    let response = yield call(NetworkService.viewService, action);
    yield put(actions.viewServiceSuccess(response));
  } catch (err) {
    yield put(actions.viewServiceFailure(err));
  }
}

export function* watchViewServiceSaga() {
  yield takeLatest(types.VIEW_SERVICE_REQUEST, viewServiceSaga);
}