import * as types from "../../Config/actionType"

export const loginRequest = (data) => {
   
    return {
        type: types.LOGIN_REQUEST,
        data:data
    }
}

export const loginSuccess = (data) => {
   
    return {
        type: types.LOGIN_SUCCESS,
        data:data,
    }
}

export const loginFailure = (data) => {
   
    return {
        type: types.LOGIN_FAILURE,
        data:data,
    }
}