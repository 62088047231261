import * as types from "../../../Config/actionType"

export const deleteProgramTemplateRequest = (data) => {
    return {
        type: types.DELETE_PROGRAM_TEMPLATE_REQUEST,
        data
    }
}

export const deleteProgramTemplateSuccess = (action) => {
    return {
        type: types.DELETE_PROGRAM_TEMPLATE_SUCCESS,
        action
    }
}

export const deleteProgramTemplateFailure = (action) => {
    return {
        type: types.DELETE_PROGRAM_TEMPLATE_FAILURE,
        action
    }
}