import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import "./DiagnosticService.css";
import Close from "@mui/icons-material/Close";
import { Stack, Autocomplete, TextField } from "@mui/material";
import { viewAllServicesNameAndUuidRequest } from "../../../redux/Actions/Program Template/Services/viewAllServicesNameAndUuidAction";
import { useDispatch } from "react-redux";

function HRAService(prop) {
  const dispatch = useDispatch();

  const testsAttributeResponseDtoListArray = [];
  const ServiceAttributeResponseDtoListArray = [];
  const ServiceNameArray = [];
  const [pack, setPack] = useState([]);
  const {
    handleClose,
    handleCloseAttribute,
    servicesInfo,
    handleServicesAttributeData,
  } = prop;
  const [testObjectArray, setTestObjectArray] = useState([]);
  const [packageObjectArray, setPackageObjectArray] = useState([]);
  const [ff, setFf] = useState([]);

  const [
    testsAttributeResponseDtoListArray1,
    settestsAttributeResponseDtoListArray1,
  ] = useState([]);

  const [
    ServiceAttributeResponseDtoListArray1,
    setServiceAttributeResponseDtoListArray1,
  ] = useState([]);
  const ServiceApiDataArray = [];

  useEffect(() => {
    dispatch(viewAllServicesNameAndUuidRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const viewServiceNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewServiceNames?.data?.data?.data?.map((item) =>
    ServiceApiDataArray.push(item)
  );
  useEffect(() => {
    setServiceAttributeResponseDtoListArray1([
      ...ServiceAttributeResponseDtoListArray,
    ]);
    settestsAttributeResponseDtoListArray1([
      ...testsAttributeResponseDtoListArray,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Package attribute work

  const viewPackageNames = useSelector(
    (state) => state.viewAllPackageNamesReducer
  );

  viewServiceNames?.data?.data?.data?.map((item) => {
    if (item.serviceUuid === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
      item.attributeResponseDtoList.map((ele) => {
        ServiceAttributeResponseDtoListArray.push(ele);
      });
    }
  });
  //
  ServiceAttributeResponseDtoListArray.map((item) =>
    ServiceNameArray.push(item.attributeName)
  );

 

  let handleChangeTest = (e, attributeUuid, name, variable, desc) => {
    let testValues = {
      [e.target.name]: parseInt(e.target.value),
      ["attributeName"]: name,
      ["attributeUuid"]: attributeUuid,
      ["attributeType"]: "INDIVIDUAL_TEST",
      ["exclusive"]: true,
      ["attributeDescription"]: desc,
    };

    if (testObjectArray.length === 0) {
      testObjectArray.push(testValues);
    } else {
      let boolT = testObjectArray.some((item) => {
        if (item.attributeUuid === testValues.attributeUuid) {
          return true;
        }
      });
      if (boolT === true) {
        testObjectArray.map((item, index) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            testObjectArray.splice(index, 1);
            testObjectArray.push(testValues);
            setTestObjectArray([...testObjectArray]);
          }
        });
      } else {
        testObjectArray.push(testValues);
        setTestObjectArray([...testObjectArray]);
      }
    }

    let ffval = {
      ["serviceName"]: servicesInfo.name,
      ["serviceUuid"]: servicesInfo.serviceUUID,
      ["attributeCountDtoList"]: testObjectArray,
    };

    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUUID === ffval.serviceUUID) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceUUID === ffval.serviceUUID) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }
    let fullyFinal = {};
    ff.map((item) => {
      fullyFinal = item;
    });
    handleServicesAttributeData(fullyFinal);
  };

  return (
    <div className="parentDiagnostic">
      <div className="diagnostic">
        <div className="diagnostic_top">
          <div className="Diagnostic-heading">{servicesInfo.name}</div>
          <div className="inputlabel">
            <Stack spacing={2}>
              <Autocomplete
                options={ServiceNameArray}
                style={{ width: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="Services" />
                )}
                onChange={(event, value) => {
                  setPack([...pack, value]);
                }}
              />
            </Stack>
          </div>
         
          <button
            className="diagnosticClose"
            onClick={() => handleClose(servicesInfo.serviceUUID)}
          >
            <Close />
          </button>
        </div>
        <div className="diagnostic_bottom">
          <div className="bottom_left">
            
            {ServiceAttributeResponseDtoListArray1.map((item, index) => {
              let desc = item.description === null ? "" : item.description;
              if (pack.includes(item.attributeName)) {
                return (
                  <div className="package_attribute" key={item.attributeId}>
                    <span key={item.attributeId}>{item.attributeName}</span>
                    <div className="rightside" key={item.attributeId}>
                      <input
                        name="attributeCount"
                        key={item.attributeId}
                        type="number"
                        min="0"
                        placeholder="count"
                        onWheel={(event) => event.currentTarget.blur()}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlur={(e) =>
                          handleChangeTest(
                            e,
                            item.attributeId,
                            item.attributeName,
                            "INDIVIDUAL_TEST",
                            item.attributeDescription
                          )
                        }
                      />
                      <div className="center">
                        <CancelIcon
                          onClick={() => {
                            handleCloseAttribute(servicesInfo.serviceUUID);
                            let fil = pack.filter(
                              (ele) => ele !== item.attributeName
                            );
                            let fil1 = testObjectArray.filter(
                              (ele) => ele.attributeUuid !== item.attributeId
                            );

                            setPack([...fil]);
                            setPackageObjectArray([...fil1]);

                            let ffvalPackage = {
                              ["serviceName"]: servicesInfo.name,
                              ["serviceUUID"]: servicesInfo.serviceUUID,
                              ["attributeCountDtoList"]: fil1,
                            };

                          

                            handleServicesAttributeData(ffvalPackage);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <div></div>;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default HRAService;
