import { Route, Routes } from "react-router-dom";
import Career from "../../component/Organisms/Footer/Career/Career";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions";
import PurchasedPrograms from "../../component/Pages/Corporate/PurchasedPrograms";
import Servicess from "../../component/Pages/Corporate/ServiceCorporate";
import ViewCorporateProgram from "../../component/Pages/Corporate/ViewCorporateProgram";
import ViewServiceCorporate from "../../component/Pages/Corporate/ViewServiceCorporate";
import DashBoardStructure from "../../component/Pages/DashboardCharts/DashBoardStructure";
import Employee from "../../component/Pages/Employee/Employee";
import ViewEmployee from "../../component/Pages/Employee/ViewEmployee";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";
import AddProgram from "../../component/Pages/Programs/AddProgram";
import MyService from "../../component/Pages/Services/MyService";
import UnderConstuction from "../../component/Pages/UnderConstruction/UnderConstruction";
import SingleLogin from "../../component/Pages/Login/SingleLogin";

export default function CorporateHrPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/corporate-Hr-Dashboard" element={<DashBoardStructure />} />
      <Route path="/myService" element={<MyService />} />
      <Route path="/allServices" element={<Servicess />} />
      <Route
        path="/ViewServiceCorporate/:uuid"
        element={<ViewServiceCorporate />}
      />
      <Route path="/employees" element={<Employee />} />

      <Route path="/viewEmployeeDetails/:id" element={<ViewEmployee />} />
      <Route path="/programs" element={<PurchasedPrograms />} />
      <Route path="/addProgram" element={<AddProgram />} />
      <Route path="/viewProgram/:uuid" element={<ViewCorporateProgram />} />
      <Route path="/underConstruction" element={<UnderConstuction />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
      {/* <Route path="/charts" element={<DashBoardStructure/>}/> */}
    </Routes>
  );
}
