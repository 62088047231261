import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TextField, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { cancelAppointmentRequest } from "../../../redux/Actions/Appointments/cancelAppointmentAction";
import { downloadPrescriptionRequest } from "../../../redux/Actions/Appointments/downloadPrescriptionAction";
import { finishAppointmentRequest } from "../../../redux/Actions/Appointments/finishAppointmentAction";
import { rescheduleAppointmentRequest } from "../../../redux/Actions/Appointments/rescheduleAppointmentAction";
import { uploadReportAppointmentsRequest } from "../../../redux/Actions/Appointments/uploadReportAppointmentsAction";
import { validateOtpRequest } from "../../../redux/Actions/Appointments/validateOtpAction";
import { viewAppointmentsRequest } from "../../../redux/Actions/Appointments/viewAppointmentAction";
import { deleteAttachmentRequest } from "../../../redux/Actions/Booking/deleteAttachmentAction";
import { downloadPrescriptionPdfRequest } from "../../../redux/Actions/InHouseDoctor/downloadPrescriptionPdfAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import UpdateNumber from "../../Organisms/Modal/UpdateNumber";
import "./ViewAppointment.css";
import { UserContext } from "../../../App";
import CircularProgress from "@mui/material/CircularProgress";

function ViewAppointment() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [edit, setEdit] = useState(false);
  const [download, setDownload] = useState(false);
  const [errorRateList, setErrorRateList] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [activeOption, setActiveOption] = useState(null);
  const [showUploadPrescription, setShowUploadPrescription] = useState(true);

  const [uploaded, setUploaded] = useState(0);

  const role = useContext(UserContext);

  useEffect(() => {
    dispatch(viewAppointmentsRequest(id));
  }, [id]);
  const [delOpen, setdelOpen] = React.useState(false);

  const res = useSelector((state) => state.viewAppointment);
  const deleteAttachment = useSelector((state) => state.deleteAttachment);

  const handleFileChange = (e) => {
    if (e.target.files[0].size / 1000000 > 3) {
      setError(true);
    } else {
      setError(false);
    }
    if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setFiles(e.target.files[0]);
      setErrorRateList(false);
    } else {
      setErrorRateList(true);
    }
  };

  var someDate = new Date(res.data?.data.data.timeSlot);
  someDate = someDate.toLocaleString();
  const [value, setValue] = useState();

  const handleConfirm = () => {
    setdelOpen(true);
  };

  const handleReschedule = () => {
    if (value === "Invalid Date" || value === undefined) {
      setEdit(true);
    } else {
      var date = new Date(value);
      date = date.getTime();
      Swal.fire({
        title: "Do you want to reschedule the appointment?",
        showDenyButton: true,
        confirmButtonText: "Reschedule",
        denyButtonText: `Close`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(rescheduleAppointmentRequest(id, date));
          Swal.fire({ icon: "success", title: "Reschedule confirmed" }).then(
            (result) => {
              window.location.reload();
            }
          );
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const handleDelete = () => {
    setFlag1(false);
    dispatch(
      deleteAttachmentRequest(res.data?.data.data.attachmentDtoList[0].id)
    );
    setDisabled(false);
  };

  if (deleteAttachment.data?.data.data.status === true) {
    swal({
      title: "Deleted successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  const cancelAppointment = useSelector((state) => state.cancelAppointment);

  const handleCancel = () => {
    Swal.fire({
      title: "Do you want to cancel the appointment?",
      showDenyButton: true,
      confirmButtonText: "Cancel Appointment",
      denyButtonText: `Close`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Cancelled", "", "success").then(() => {
          dispatch(cancelAppointmentRequest(id));
          window.location.reload();
        });
      }
      if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const handleClick = () => {
    setEdit(true);
  };

  const handleSubmit = () => {
    dispatch(validateOtpRequest(code, id));
  };

  const [delId, setDelId] = useState();

  const confirm = useSelector((state) => state.confirmAppointment);
  const reschedule = useSelector((state) => state.rescheduleAppointment);
  const cancel = useSelector((state) => state.cancelAppointment);
  const otp = useSelector((state) => state.validateOtp);

  if (
    otp?.data?.data.status === 200 &&
    otp?.data?.data.data.message === "Appointment valid!"
  ) {
    Swal.fire({
      title: "Appointment Started Successfully",
      icon: "success",
    }).then(() => {
      window.location.href = "/hospitalAppointment";
    });
  }

  if (otp?.error?.data.response.data.errorMessage === "Invalid OTP !") {
    swal({
      title: "Wrong OTP",
      text: "Enter the correct OTP",
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (confirm.data?.data?.data.message === "Status updated successfully" ||
      reschedule.data?.data?.data.message === "Status updated successfully") &&
    window.location.pathname === `/hospitalViewAppointment/${id}`
  ) {
    navigate("/hospitalAppointment");
    window.location.reload();
  }

  if (
    (confirm.data?.data?.data.message === "Status updated successfully" ||
      reschedule.data?.data?.data.message === "Status updated successfully" ||
      cancel.data?.data?.data.message === "Status updated successfully") &&
    window.location.pathname === `/viewAppointmentDetails/${id}`
  ) {
    navigate("/appointments");
    window.location.reload();
  }

  const uploadPdfReducer = useSelector(
    (state) => state.uploadReportAppointments
  );

  const finalFinish = useSelector((state) => state.finishAppointment);

  const handleUpload = () => {
    setFlagUpload(false);
    if (error === false) {
      if (files.length === 0) {
        swal({
          icon: "error",
          title: "Please select a file !",
        });
      } else if (errorRateList) {
        swal({
          icon: "error",
          title: "Please select a valid file !",
        });
      } else {
        const formData = new FormData();
        formData.append("file", files);
        dispatch(
          uploadReportAppointmentsRequest(formData, res.data?.data.data.id)
        );
      }
    }
  };
  if (uploadPdfReducer?.data?.data.status === 200) {
    swal({
      title: "Uploaded successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
      setDisabled(false);
    });
  }
  useEffect(() => {
    if (res.data?.data.data.attachmentDtoList.length !== 0) {
      setUploaded(1);
    } else {
      setUploaded(0);
    }
  }, [res.data?.data.data.attachmentDtoList]);

  const handleFinish = () => {
    setFinishFlag(false);
    if (uploaded === 0) {
      swal({
        icon: "error",
        title: "Please select a file !",
      });
    } else {
      dispatch(finishAppointmentRequest(res.data?.data.data.id));
    }
  };

  if (finalFinish?.data?.data.data.message === "Status updated successfully") {
    swal({
      title: "Status updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  const dP = useSelector((state) => state.downloadPrescription);

  const [count, setCount] = useState(0);
  const [code, setCode] = useState("");

  const handleView = () => {
    if (res.data?.data.data.attachmentDtoList.length !== 0) {
      const link = document.createElement("a");
      link.href = res.data?.data.data.attachmentDtoList[0].filePath;
      link.target = "_blank";
      link.setAttribute(
        "download",
        res.data?.data.data.attachmentDtoList[0].fileName
      );
      document.body.appendChild(link);
      link.click();
    }
  };
  const updateRes = useSelector((state) => state.downloadPrescription);
  const deletePres = useSelector((state) => state.deleteAttachment);
  const uploadRes = useSelector((state) => state.deleteAttachment);
  const finish = useSelector((state) => state.finishAppointment);

  const [flag, setFlag] = useState(true);
  const [flag1, setFlag1] = useState(true);
  const [flagUpload, setFlagUpload] = useState(true);
  const [finishFlag, setFinishFlag] = useState(true);

  useEffect(() => {
    if (updateRes?.data || updateRes?.error) {
      setFlag(true);
    }
    if (uploadRes?.data || uploadRes?.error) {
      setFlagUpload(true);
    }
    if (deletePres?.data || deletePres?.error) {
      setFlag1(true);
    }
    if (finish?.data || finish?.error) {
      setFinishFlag(true);
    }
  }, [updateRes, finish, uploadRes, deletePres]);

  const handleDownload = () => {
    setFlag(false);

    dispatch(
      downloadPrescriptionRequest(res.data?.data.data.attachmentDtoList[0].id)
    );
  };

  if (dP.data?.data.status === 200) {
    swal({
      title: "Download File",
      icon: "info",
    }).then(() => {
      const url_1 = window.URL.createObjectURL(
        new Blob([dP.data?.data.data], {
          type: "application/octet-stream",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      link.setAttribute(
        "download",
        res.data?.data.data.attachmentDtoList[0].fileName
      );
      document.body.appendChild(link);
      link.click();
      window.location.reload();
    });
  }

  const handleChange = (code) => {
    setCode(code);
  };

  const handlePrescription = () => {
    navigate(`/CreatePrescription/${id}`);
  };

  const handleThisDisable = () => {
    setDisabled(false);
  };

  const submittedPrescription = useSelector(
    (state) => state.submitPrescription
  );

  const downloadPrescriptionPdf = useSelector(
    (state) => state.downloadPrescriptionPdf
  );

  function handlePrint() {
    const data = {
      id: res?.data?.data?.data?.id,
      identifier: res?.data?.data?.data?.identifier,
    };

    setDownload(true);

    dispatch(downloadPrescriptionPdfRequest(data));
  }

  useEffect(() => {
    if (downloadPrescriptionPdf?.data?.data?.status === 200 && download) {
      swal({
        title: "Download File",
        icon: "info",
      }).then(() => {
        const url_1 = window.URL.createObjectURL(
          new Blob([downloadPrescriptionPdf?.data?.data?.data], {
            type: "application/octet-stream",
          })
        );
        const link = document.createElement("a");
        link.href = url_1;

        link.setAttribute("download", "Report.pdf");
        document.body.appendChild(link);
        link.click();
      });
    }
  }, [downloadPrescriptionPdf]);

  return (
    <div>
      {delOpen && <UpdateNumber delOpen={delOpen} id={id} />}
      <div className="appointmentmain">
        <h3 className="appId">Appointment Id - {res.data?.data.data.id}</h3>
        <div className="appointment-box">
          <div className="appointmentbox-head">
            <div className="name">{res.data?.data.data.patientName}</div>
            <div className="statuses">
              <>
                {res.data?.data.data.appointmentStatus === "CONFIRMED" && (
                  <Typography>
                    <Chip label="CONFIRMED" color="info" />
                  </Typography>
                )}
                {res.data?.data.data.appointmentStatus === "CANCELLED" && (
                  <Typography>
                    <Chip label="CANCELLED" color="error" />
                  </Typography>
                )}
                {res.data?.data.data.appointmentStatus === "COMPLETED" && (
                  <Typography>
                    <Chip label="COMPLETED" color="success" />
                  </Typography>
                )}
                {res.data?.data.data.appointmentStatus === "INITIATED" && (
                  <Typography>
                    <Chip label="INITIATED" color="secondary" size="medium" />
                  </Typography>
                )}
                {res.data?.data.data.appointmentStatus === "RESCHEDULED" && (
                  <Typography>
                    <Chip label="RESCHEDULED" color="warning" />
                  </Typography>
                )}
              </>
            </div>
          </div>
          <hr />
          <div className="appointment-box-body">
            <h5 className="app-desc">Breif Description</h5>
            <div className="desc">{res.data?.data.data.description}</div>
            <div className="appointment-details">
              <div className="appoi">
                <div className="appointment-keys">
                  Doctor
                  <br />
                  <br />
                  Speciality
                  <br />
                  <br />
                  {res?.data?.data?.data.memberName ? "Customer Name" : ""}
                  {res?.data?.data?.data.memberName ? (
                    <>
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  Customer Name
                  <br />
                  <br />
                  Customer Contact Number
                  <br />
                  <br />
                  Customer Email
                  <br />
                  <br />
                  Patient Relationship
                  <br />
                  <br />
                  Patient Name
                  <br />
                  <br />
                  Patient Number
                  <br />
                  <br />
                  Hospital Name
                  <br />
                  <br />
                  Hospital Contact Person
                  <br />
                  <br />
                  Hospital Contact Number
                  <br />
                  <br />
                  <br />
                  Time Slot
                </div>
                <div className="appointment-value">
                  {res.data?.data.data.doctorName}
                  <br />
                  <br />
                  {res.data?.data.data.speciality}
                  <br />
                  <br />
                  {res.data?.data.data.customerName}
                  <br />
                  <br />
                  {res.data?.data.data.customerNumber}
                  <br />
                  <br />
                  {res.data?.data.data.customerEmail}
                  <br />
                  <br />
                  {res.data?.data.data.relation}
                  <br />
                  <br />
                  {res.data?.data.data.patientName}
                  <br />
                  <br />
                  {res.data?.data.data.patientNumber}
                  <br />
                  <br />
                  {res.data?.data.data.hospitalName}
                  <br />
                  <br />
                  {res.data?.data.data.hospitalContactPersonName}
                  <br />
                  <br />
                  {res.data?.data.data.hospitalNumber}
                  <br />
                  <br />
                  <br />
                  <div className="timeSlot">
                    <div className="AppointmentTimeSlot">{someDate}</div>
                    {res.data?.data.data.appointmentStatus === "INITIATED" ||
                    res.data?.data.data.appointmentStatus === "RESCHEDULED" ? (
                      <div>
                        <EditIcon
                          className="edit"
                          sx={{ color: "#1D2334" }}
                          onClick={handleClick}
                        />
                      </div>
                    ) : null}
                  </div>
                  {edit && (
                    <div className="dateTime">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Time Slot"
                          minDate={new Date()}
                        value={value}

                          // inputFormat="dd/MM/yyyy hh:mm"
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                  )}
                </div>
                {window.location.pathname === `/viewAppointmentDetails/${id}`
                  ? ""
                  : res.data?.data.data.appointmentStatus === "CONFIRMED" && (
                      <>
                        <div className="Confirmed_otpForm">
                          <div className="Confirmed_otphead">
                            Enter appointment code from Patient.
                          </div>
                          <OtpInput
                            value={code}
                            onChange={handleChange}
                            containerStyle={{
                              width: "100%",
                            }}
                            numInputs={4}
                            separator={<span style={{ width: "1px" }}></span>}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle={{
                              background: "#F3F0F0",

                              border: "1px solid transparent",
                              borderRadius: "8px",
                              width: "60%",
                              height: "54px",
                              fontSize: "12px",
                              color: "#000",
                              fontWeight: "400",
                              caretColor: "blue",
                            }}
                            focusStyle={{
                              border: "1px solid #CFD3DB",
                              outline: "none",
                            }}
                          />
                          <small className="Confirmed_otpfoot">
                            This appointment will begin once you click start
                            appointment.
                          </small>
                          <div>
                            <button
                              type="submit"
                              className="Confirmed_startBtn"
                              onClick={handleSubmit}
                            >
                              Start Appointment
                            </button>
                          </div>
                        </div>
                      </>
                    )}
              </div>
            </div>

            {res.data?.data.data.appointmentStatus === "INITIATED" ||
            res.data?.data.data.appointmentStatus === "RESCHEDULED" ? (
              <div className="appointment-buttons">
                <button
                  className="appointment-btnAssign"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
                <button
                  className="appointment-btnCancel"
                  onClick={handleReschedule}
                >
                  Reschedule
                </button>
                <button
                  className="appointment-btnDelete"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            ) : null}
            {res.data?.data.data.appointmentStatus === "CONFIRMED" && (
              <div className="appointment-buttons">
                <button
                  className="appointment-btnCancel"
                  onClick={handleReschedule}
                >
                  Reschedule
                </button>
                <button
                  className="appointment-btnDelete"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            )}
            <div>
              <div
                className="finalScreenDetailsUploadReport"
                onClick={handleThisDisable}
              >
                {res.data?.data.data.appointmentStatus === "COMPLETED" && (
                  <div
                    className="appointment-details"
                    onClick={handleThisDisable}
                  >
                    <div className="appointment-keys">
                      <p>Upload Prescription</p>
                    </div>
                    {res.data?.data.data.attachmentDtoList.length === 0 ? (
                      <div
                        className="finalScreenDetailsUploadReportChild2"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div>
                          <input
                            type="file"
                            className="fileInput presupload"
                            onChange={(e) => handleFileChange(e)}
                            accept="application/pdf,image/png, image/jpeg ,image/jpg"
                          />
                          {error && (
                            <p className="errorSize">
                              File size should be less than 3MB
                            </p>
                          )}

                          {errorRateList && (
                            <p className="errorSize">
                              Please Upload only .pdf, .jpeg, .jpg or .png file
                            </p>
                          )}
                        </div>
                        <button disabled={!flagUpload} onClick={handleUpload}>
                          {flagUpload ? "     Upload" : <CircularProgress />}
                        </button>
                      </div>
                    ) : (
                      <div className="appointment-fileName">
                        {res.data?.data.data.attachmentDtoList[0].fileName}
                        <div className="finalScreenDetailsUploadReportChildIcon">
                          {flag ? (
                            <DownloadIcon
                              onClick={handleDownload}
                              style={{
                                marginRight: 10,
                                cursor: "pointer",
                                color: "#52608E",
                              }}
                            />
                          ) : (
                            <CircularProgress />
                          )}

                          {flag1 ? (
                            <DeleteIcon
                              onClick={() => handleDelete()}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {role === "HOSPITAL_ADMIN" &&
                res?.data?.data?.data?.attachmentDtoList.length === 0 && (
                  <>
                    {res.data?.data.data.appointmentStatus === "INITIATED" ||
                    res.data?.data.data.appointmentStatus === "RESCHEDULED" ||
                    res.data?.data.data.appointmentStatus === "CONFIRMED" ||
                    res.data?.data.data.appointmentStatus === "CANCELLED"
                      ? ""
                      : res.data?.data.data.appointmentStatus === "FINISHED"
                      ? ""
                      : res.data?.data.data.createPrescription && (
                          <>
                            <div className="orfield">or</div>
                            <div className="prescriptionAppointment">
                              <div className="appointment-keysUp">
                                Upload Prescription
                              </div>

                              <div className="">
                                <button
                                  className="NewCreateButtonAppointment"
                                  onClick={handlePrescription}
                                >
                                  Create Prescription
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                  </>
                )}
            </div>

            {res.data?.data.data.appointmentStatus === "COMPLETED" && (
              <div className="finalScreenDetailsFinalSubmit">
                <button disabled={!finishFlag} onClick={handleFinish}>
                  {finishFlag ? "      Mark as Finish" : <CircularProgress />}
                </button>
              </div>
            )}
            {res.data?.data.data.appointmentStatus === "FINISHED" && (
              <div className="finalScreenDetailsFinalSubmit">
                <button
                  onClick={
                    res.data?.data.data.appointmentStatus === "FINISHED" &&
                    res?.data?.data?.data?.attachmentDtoList.length !== 0
                      ? handleView
                      : handlePrint
                  }
                >
                  View Prescription
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewAppointment;
