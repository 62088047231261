import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/updateCorporateAction";
import NetworkService from "../../../network/NetworkService";

export function* updateCorporateSaga(action) {
  try {
     
    let response = yield call(NetworkService.updateCorporate, action);
    yield put(actions.updateCorporateSuccess(response));
  } catch (err) {
    yield put(actions.updateCorporateFailure(err));
  }
}

export function* watchUpdateCorporateSaga() {
  yield takeLatest(types.UPDATE_CORPORATE_REQUEST, updateCorporateSaga);
}