import * as types from "../../../Config/actionType"

export const viewAllServicesNameAndUuidRequest = () => {
    //
    return {
        type: types.VIEW_ALL_SERVICES_NAMES_AND_UUID_REQUEST,
    }
}

export const viewAllServicesNameAndUuidSuccess = (action) => {
    //
    return {
        type: types.VIEW_ALL_SERVICES_NAMES_AND_UUID_SUCCESS,
        action
    }
}

export const viewAllServicesNameAndUuidFailure = (action) => {
             
    return {
        type: types.VIEW_ALL_SERVICES_NAMES_AND_UUID_FAILURE,
        action
    }
}