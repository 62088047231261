import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/addCorporateAction";
import NetworkService from "../../../network/NetworkService";

export function* addCorporateSaga(action) {
  try {
     
    let response = yield call(NetworkService.addCorporate, action);
    yield put(actions.addCorporateSuccess(response));
  } catch (err) {
    yield put(actions.addCorporateFailure(err));
  }
}

export function* watchAddCorporateSaga() {
  yield takeLatest(types.ADD_CORPORATE_REQUEST, addCorporateSaga);
}