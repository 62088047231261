import React, { useContext } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import storage from "../../../Utils/storage";
import { UserContext } from "../../../App";

function CorporateProgramAssignView(props) {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const corporate = useSelector((state) => state.viewCorporateDetail);
  const handleClick = () => {
    if (window.location.pathname === `/insurerViewCorporateDetails/${id}`) {
      navigate("/insurer/AssignProgramToInsurer", {
        state: {
          id: id,
          name: corporate.data?.data.data.name,
          employeeCount: corporate.data?.data.data.noOfEmployee,
        },
      });
    } else {
      navigate("/programAssign", {
        state: {
          id: id,
          name: corporate.data?.data.data.name,
          employeeCount: corporate.data?.data.data.noOfEmployee,
        },
      });
    }
  };
  const handleviewprogram = (programUuid) => {
    navigate(`/viewProgramAssigned/${programUuid}`);
  };

 
 
  const role = useContext(UserContext);
  

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Program Name",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      minWidth: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      minWidth: 200,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "programStatus",
      headerClassName: "super-app-theme--header",
      headerName: " Status",
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row["programStatus"] === "Inactive" && (
              <Typography>
                <Chip label="Inactive" color="error" />
              </Typography>
            )}
             {params.row["programStatus"] === "Active" && (
              <Typography>
                <Chip label="Active" color='primary' />
              </Typography>
            )}
            
         
            {params.row["programStatus"] === "Upcoming" && (
              <Typography>
                <Chip label="Upcoming" color="warning" />
              </Typography>
            )}
          </>
        );
      },
    },

    {
      field: "View",
      minWidth: 170,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {role!=="INSURER" && <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleviewprogram(cellValues.row.programUuid)}
            />}
            
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <div className="ProgramAssignedMainConatiner">
        <p className="ProgramAssignedMainHeading">Program Assigned</p>
        <button className="ProgramAssignedMainButton" onClick={handleClick}>
          {" "}
          + Add Program
        </button>
      </div>
      <Box sx={{ height: 400, width: "100%", marginTop: "2%" }}>
        {props.corporateViewAssignProgramData?.data && (
          <DataGrid
            sx={{
              color: "#1D2334",
              "& .super-app-theme--header": {
                backgroundColor: "#52608E",
                color: "#F2EFEA",
                "& > .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              },
              "&:hover:not(.Mui-disabled)": {
                cursor: "pointer",
              },
              ".MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: "5%",
              },
            }}
            getRowId={(row) => row.programUuid}
            pagination
            disableColumnMenu
            rows={props.corporateViewAssignProgramData?.data}
            columns={columns}
            components={{
              Footer: "none",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default CorporateProgramAssignView;
