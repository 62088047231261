import React, { useContext } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import storage from "../../../Utils/storage";
import { UserContext } from "../../../App";

function InsurerProgramAssignView(props) {
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const corporate = useSelector((state) => state.viewCorporateDetail);
  
  const handleviewprogram = (programUuid) => {
    navigate(`/viewProgramAssignedInsurer/${programUuid}`);
  };
  
  const role = useContext(UserContext);

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Program Name",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
        field: "programCount",
        headerClassName: "super-app-theme--header",
        headerName: " Program Count",
        minWidth: 150,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "remainingCount",
        headerClassName: "super-app-theme--header",
        headerName: " Remaining Count",
        minWidth: 150,
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
      },
    {
      field: "View",
      minWidth: 170,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleviewprogram(cellValues.row.programUuid)}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        );
      },
    },
  ];
  return (
    <>
    {props.insurerViewAssignProgramData?.data && (
    <div>
      
      <div className="ProgramAssignedMainConatiner">
        <p className="ProgramAssignedMainHeading">Program Assigned</p>
        {/* <button className="ProgramAssignedMainButton" onClick={handleClick}>
          {" "}
          + Add Program
        </button> */}
      </div>
      <Box sx={{ height: 400, width: "100%", marginTop: "2%" }}>
        
          <DataGrid
            sx={{
              color: "#1D2334",
              "& .super-app-theme--header": {
                backgroundColor: "#52608E",
                color: "#F2EFEA",
                "& > .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              },
              "&:hover:not(.Mui-disabled)": {
                cursor: "pointer",
              },
              ".MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: "5%",
              },
            }}
            getRowId={(row) => row.programUuid}
            pagination
            disableColumnMenu
            rows={props.insurerViewAssignProgramData?.data}
            columns={columns}
            components={{
              Footer: "none",
            }}
          />
       
      </Box>
       
    </div>
    )}
    </>
  );
}

export default InsurerProgramAssignView;
