import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/CorporateDashboard/CorporateDashboardAction";
import NetworkService from "../../../network/NetworkService";

export function* CorporateDashboardSaga(action) {
  try {
     
    let response = yield call(NetworkService.corporateDashboard, action);
    yield put(actions.CorporateDashboardSuccess(response));
  } catch (err) {
    yield put(actions.CorporateDashboardFailure(err));
  }
}

export function* watchCorporateDashboardSaga() {
  yield takeLatest(types.DASHBOARD_DATA_REQUEST, CorporateDashboardSaga);
}