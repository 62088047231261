import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/completeBookingAction"
import NetworkService from "../../../network/NetworkService";

export function* completeBookingSaga(action) {
  try {
    let response = yield call(NetworkService.completeBooking, action);
    yield put(actions.completeBookingSuccess(response));
  } catch (err) {
    yield put(actions.completeBookingFailure(err));
  }
}

export function* watchCompleteBookingSaga() {
  yield takeLatest(types.COMPLETE_BOOKING_REQUEST, completeBookingSaga);
}