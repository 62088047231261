import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/CorporateDropdownAction";
import NetworkService from "../../../network/NetworkService";

export function* corporateDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.corporateDropdown, action);
    yield put(actions.corporateDropdownSuccess(response));
  } catch (err) {
    yield put(actions.corporateDropdownFailure(err));
  }
}

export function* watchcorporateDropdownSaga() {
  yield takeLatest(types.CORPORATE_DROPDOWN_REQUEST, corporateDropdownSaga);
}