import * as types from "../../Config/actionType"

export const getUpdateCorporateRequest = (data) => {
    return {
        type: types.GET_UPDATE_CORPORATE_REQUEST,
        data:data
    }
}

export const getUpdateCorporateSuccess = (data) => {
    return {
        type: types.GET_UPDATE_CORPORATE_SUCCESS,
        data:data,
    }
}

export const getUpdateCorporateFailure = (data) => {
    return {
        type: types.GET_UPDATE_CORPORATE_FAILURE,
        data:data,
    }
}