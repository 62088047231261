import * as types from "../../Config/actionType"

export const deleteServiceRequest = (id) => {
   
    return {
        type: types.DELETE_SERVICE_REQUEST,
        id
    }
}

export const deleteServiceSuccess = (action) => {
    return {
        type: types.DELETE_SERVICE_SUCCESS,
        action
    }
}

export const deleteServiceFailure = (action) => {
    return {
        type: types.DELETE_SERVICE_FAILURE,
        action
    }
}