import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/FileTemplateAction";
import NetworkService from "../../../network/NetworkService";

export function* fileTemplateFileSaga(action) {
  try {
    let response = yield call(NetworkService.fileTemplate, action);
    yield put(actions.fileTemplateSuccess(response));
  } catch (err) {
    yield put(actions.fileTemplateFailure(err));
  }
}

export function* watchFileTemplateFileSaga() {
  yield takeLatest(types.FILE_TEMPLATE_REQUEST, fileTemplateFileSaga);
}

