import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Packages/viewPackagesAction";
import NetworkService from "../../../network/NetworkService";

export function* viewPackagesSaga(action) {
  try {
    let response = yield call(NetworkService.viewPackages, action);
   
    yield put(actions.viewPackagesSuccess(response));
   
  } catch (err) {
   
    yield put(actions.viewPackagesFailure(err));
  }
}

export function* watchViewPackagesSaga() {
  yield takeLatest(types.VIEW_PACKAGES_REQUEST, viewPackagesSaga);
}