import * as types from "../../Config/Product/ProductActionType";

export const getAllProductRequest = (data) => {
  return {
    type: types.GET_ALL_PRODUCT_REQUEST,
    data:data
  };
};

export const getAllProductSuccess = (data,filter) => {
  return {
    type: types.GET_ALL_PRODUCT_SUCCESS,
    data: data,
    filter
  };
};

export const getAllProductFailure = (data) => {
  return {
    type: types.GET_ALL_PRODUCT_FAILURE,
    data: data,
  };
};
