import * as types from "../../Config/actionType"

export const mapToElasticRequest = (data) => {
   
    return {
        type: types.MAP_TO_ELASTIC_REQUEST,
        data:data
    }
}

export const mapToElasticSuccess = (data) => {
   

    return {
        type: types.MAP_TO_ELASTIC_SUCCESS,
        data:data,
    }
}

export const mapToElasticFailure = (data) => {
   

    return {
        type: types.MAP_TO_ELASTIC_FAILURE,
        data:data,
    }
}