import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Users/UserPlanDetailsAction";
import NetworkService from "../../../network/NetworkService";

export function* GetUserPlanSaga(action) {
  try {
    let response = yield call(NetworkService.getUserPlan, action);
    yield put(actions.GetUserPlanSuccess(response));
  } catch (err) {
    yield put(actions.GetUserPlanFailure(err));
  }
}

export function* watchGetUserPlanSaga() {
  yield takeLatest(types.GET_USER_PLAN_REQUEST, GetUserPlanSaga);
}
