import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Accounts/ViewDropdownAction"
import NetworkService from "../../../network/NetworkService";

export function* viewDropdownAccount() {
  try {
    let response = yield call(NetworkService.viewDropdownAccount);

    yield put(actions.viewDropdownAccountSuccess(response))
  } catch (err) {
    yield put(actions.viewDropdownAccountFailure(err));
  }
}

export function* watchViewDropdownAccount() {
  yield takeLatest(types.VIEW_DROPDOWN_ACCOUNT_REQUEST, viewDropdownAccount);
}