import * as types from "../../Config/actionType"

export const validateOtpLoginRequest = (otp,email) => {
    return {
        type: types.VALIDATE_OTP_LOGIN_REQUEST,
        otp,
        email
    }
}

export const validateOtpLoginSuccess = (data) => {
    return {
        type: types.VALIDATE_OTP_LOGIN_SUCCESS,
        data:data,
    }
}

export const validateOtpLoginFailure = (data) => {
    return {
        type: types.VALIDATE_OTP_LOGIN_FAILURE,
        data:data,
    }
}

export const validateOtpLoginClearRequest = () => {
    return {
        type: types.VALIDATE_OTP_LOGIN_CLEAR_REQUEST,
    }
}