import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/getRelationAction";
import NetworkService from "../../../network/NetworkService";

export function* getRelation(action) {
  try {
    let response = yield call(NetworkService.getRelation, action);
    yield put(actions.getRelationSuccess(response));
  } catch (err) {
    yield put(actions.getRelationFailure(err));
  }
}

export function* watchGetRelationSaga() {
  yield takeLatest(types.GET_RELATION_REQUEST, getRelation);
}