import * as types from "../../Config/actionType"

export const cancelAppointmentRequest = (data) => {
    return {
        type: types.CANCEL_APPOINTMENT_REQUEST,
        data:data
    }
}

export const cancelAppointmentSuccess = (data) => {
    return {
        type: types.CANCEL_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const cancelAppointmentFailure = (data) => {
    return {
        type: types.CANCEL_APPOINTMENT_FAILURE,
        data:data,
    }
}