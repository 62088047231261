import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Footer from "../../Organisms/Footer/FooterUpper";
import DisableDoctorModal from "../../Organisms/Modal/DisableDoctorModal";
import { useNavigate } from "react-router-dom";
import { viewAllPlanRequest } from "../../../redux/Actions/Plan/viewAllPlanAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { deletePlanRequest } from "../../../redux/Actions/Plan/deletePlanAction";
import "./ListOfPlan.css";
import storage from "../../../Utils/storage";
import { roles } from "../../../helpers/MainConstants";
import { UserContext } from "../../../App";
import EditIcon from '@mui/icons-material/Edit';

function ListOfPlan() {
  const role = useContext(UserContext);
  window.scrollTo({ top: 0 });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [delOpen, setdelOpen] = React.useState(false);
  const [delId, setDelId] = useState();
  const [name, setName] = React.useState("");

  useEffect(() => {
    let data = {
      page: 1,
      pageSize: 100,
      search: name,
    };
    dispatch(viewAllPlanRequest(data));
  }, [name]);
  const viewAllPlan = useSelector((state) => state.viewAllPlan);

  function debounceSearch(call, t) {
  let timer;
  return function(e) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      call(e);
    }, t);
  };
}


  function handleSearch(e) {

    setName(e.target.value);

  }

  function handleClick(uuid) {
    navigate(`/planDetails/${uuid}`);
  }
  const handlePlan = () => {
    navigate("/addPlan");
  };
  const handleDelete = (uuid) => {
    Swal.fire({
      title: "Do you want to Disable The Plan?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletePlanRequest(uuid));
      } else if (result.isDenied) {
        window.location.reload();
      }
    });
  };

  const deletePlan = useSelector((state) => state.deletePlan);

  if (
    deletePlan?.data?.data.status === 200 &&
    deletePlan?.data?.data?.data.message !== ""
  ) {
    Swal.fire(
      "Success",
      "Plan Has Been Disabled Successfully !",
      "success"
    ).then(() => {
      window.location.reload();
    });
  }

  function handleEditPlan(id) {
    navigate(`/editplan/${id}`)
  }

  return (
    <div>
      <div className="grid">
        <h4 className="h4" style={{ marginLeft: "3.4em" }}>
          {" "}
          PLANS
        </h4>
        <div className="mainBox">
          {delOpen && <DisableDoctorModal delOpen={setdelOpen} delId={delId} />}
          <div className="head">
            <div className="leftHeadLOP">
              <p className="leftHeadLOPHeading">List of Plan</p>
              <div className="leftHeadLOPCount">
                {viewAllPlan?.data?.data.data.data.totalRecords} Plans
              </div>
            </div>
            <div className="rightheadbtn">
              <div
                type="button"
                className="btn addModalbtn"
                onClick={() => handlePlan()}
              >
                + Add Plan
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity"></div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={(e) => debounceSearch(handleSearch, 1000)(e)}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search "
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="LOP">
            <div className="LOPHeader">
              <div className="LOPHeaderChild1">Name</div>
              <div className="LOPHeaderChild2">Services</div>
              <div className="LOPHeaderChild3">Type Of Hospitals</div>
              <div className="LOPHeaderChild4">Plan Price</div>
              <div className="LOPHeaderChild5">Discount</div>
              <div className="LOPHeaderChild6">Version</div>
              <div className="LOPHeaderChild7">Sales Count</div>
              <div className="LOPHeaderChild8">View</div>
            </div>
            <div className="LOPDataParent">
              {viewAllPlan?.data?.data.data.data.planDetailsResponseDto ? (
                viewAllPlan?.data?.data.data.data.planDetailsResponseDto.map(
                  (item, index) => (
                    <div
                      className={index % 2 == 0 ? "LOPDataEven" : "LOPDataOdd"}
                    >
                      <div className="LOPDataChild1">{item.name}</div>
                      <div className="LOPDataChild2">
                        {/* { servicesArray = Object.entries(item.services)} */}

                        {Object.entries(item.services).map((ele) => (
                          <div className="LOPDataChild2Child">
                            <div className="LOPDataChild2_1">{ele[0]}</div>
                            <div className="LOPDataChild2_2">{ele[1]}</div>
                          </div>
                        ))}
                      </div>
                      <div className="LOPDataChild3">
                        {item.hospitalType.map((ele) => (
                          <div className="typeHospital">{ele}</div>
                        ))}
                      </div>
                      <div className="LOPDataChild4">
                        {Object.entries(item.planPrice).map((ele) => (
                          <div className="LOPDataChild4Child">
                            <div className="LOPDataChild4_1">₹{ele[0]}</div>
                            <div
                              className={
                                index % 2 == 0
                                  ? "LOPDataChild4_2Even"
                                  : "LOPDataChild4_2Odd"
                              }
                            >
                              {ele[1]}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="LOPDataChild5">
                        {item.discount === null ? "-" : item.discount}
                      </div>
                      <div className="LOPDataChild6">v {item.version}.0</div>
                      <div className="LOPDataChild7">{item.salesCount}</div>
                      <div className="LOPDataChild8">
                        {item.status === true ? <EditIcon sx={{ color: "#52608E" }} onClick={() => handleEditPlan(item.planUuid)} /> : ""}
                        {item.status === true ? (
                          <VisibilityIcon
                            sx={{ color: "#52608E" }}
                            onClick={() => handleClick(item.planUuid)}
                          />
                        ) : (
                          ""
                        )}
                        {role === roles.CUSTOMER_SERVICE_AGENT ? (
                          <></>
                        ) : (
                          <>
                            {item.status === false ? (
                              <Switch
                                disabled
                                style={{
                                  color: "#A53F2B",
                                }}
                              />
                            ) : (
                              <Switch
                                defaultChecked
                                style={{ color: "#319B4B" }}
                                onClick={() => handleDelete(item.planUuid)}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
                )
              ) : (
                <p className="No_Plans_Found">No Plans Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ListOfPlan;
