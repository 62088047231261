import React, { useEffect } from "react";
import Footer from "../../Organisms/Footer/FooterUpper";
import "../Corporate/ViewCorporateDetail.css";
import "../Appointments/ViewAppointment.css";
import "./viewFile.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewTestRequest } from "../../../redux/Actions/Tests/viewTestAction";

function ViewTest() {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewTestRequest(id));
  }, [id]);

  const viewTest = useSelector((state) => state.viewTest);
 

  return (
    <div>
      <div className="appointmentmain">
        <h3 className="appId">{viewTest.data?.data.data.name}</h3>
        <div className="appointment-box">
          <div>
            <div className="displayBoxHeader">About This Test</div>
            <div className="displayBoxPara">
              {viewTest.data?.data.data.description}
            </div>
          </div>
          <hr />
          <div className="corporate-box-body">
            <div className="corporate-details">
              <div className="corporate-keys">
                Amount
                <br />
                <br />
                Instructions
                {/* <br />
                <br />
                Interpretation */}
                <br />
                <br />
                {viewTest.data?.data.data.parameters.length !== 0 && viewTest.data?.data.data.parameters[0].parameterName !==
                  "" && <>Parameters</>}
                <br />
                <br />
              </div>
              <div className="corporate-value">
                Rs {viewTest.data?.data?.data?.cost}/-
                <br />
                <br />
                {viewTest.data?.data.data.instruction}
                {/* <br />
                <br />
                {viewTest.data?.data.data.interpretation} */}
                <br />
                <br />
                {viewTest.data?.data.data.parameters.length !== 0 &&
                viewTest.data?.data.data.parameters[0]?.parameterName !== "" ? (
                  <div className="ViewTestParameters">
                    {viewTest.data?.data.data.parameters.map((x, index) => (
                      <>
                        <div className="ParametersIndividual">
                          {x.parameterName}
                          {index <
                            viewTest.data?.data.data.parameters.length - 1 && (
                            <hr className="ParametersIndividualSeperator" />
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                ) : null}
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
export default ViewTest;
