import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/CreateTalkToDoctor/checkEmailOrNumberAction";
import NetworkService from "../../../network/NetworkService";

export function* checkEmailOrNumberSaga(action) {
  try {
    let response = yield call(NetworkService.checkEmailOrNumber, action);
    yield put(actions.checkEmailOrNumberSuccess(response));
  } catch (err) {
    yield put(actions.checkEmailOrNumberFailure(err));
  }
}

export function* watchCheckEmailOrNumberSaga() {
  yield takeLatest(types.CHECK_EMAIL_OR_NUMBER_REQUEST, checkEmailOrNumberSaga);
}