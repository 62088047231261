import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./ModalAddEmployeeFile.css";
import { toast } from "react-toastify";
import { addEmployeeFileRequest } from "../../../redux/Actions/Employee/addEmployeeFileAction";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { fileTemplateRequest } from "../../../redux/Actions/Employee/FileTemplateAction";
import { AddEmployeeStyle } from "./ModalConstants";



export default function ModalAddEmployeeFile(props) {
  const [files, setFiles] = React.useState([]);
  const handleClose = () => props.open(false);
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.addEmployeeFile);
  const fileTemplate = useSelector((state) => state.fileTemplate);
  const onChangeFile = (e) => {
    setFiles(e.target.files);
  };

  const fileTemplateDownload = () => {
    dispatch(fileTemplateRequest());
  };
// upload.action.response.status
  const onUploadFile = () => {
    if (files === undefined) {
      toast.warning("Please choose a file first!", {
        position: "top-center",
        hideProgressBar: true,
      });
    } else {
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(addEmployeeFileRequest(formData, uuid));
    }
  };

  // Program
  if (upload.data?.request.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Uploaded the file successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  

  if (fileTemplate.data?.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Please use downloaded template",
      icon: "info",
    }).then(() => {
      const url_1 = window.URL.createObjectURL(
        new Blob([fileTemplate.data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      link.setAttribute("download", `EmployeeUploadTemplate.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
      window.location.reload();
    });
  }

  if (upload.data?.status === 200) {
    props.open(false);
    Swal.fire({
      title: "There are some corrupted data! Please try again",
      icon: "info",
    }).then(() => {
      const url_1 = window.URL.createObjectURL(
        new Blob([upload.data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      link.setAttribute("download", `corrupt_data.xlsx`); // or any other extension
      document.body.appendChild(link);
      link.click();
      window.location.reload();
    });
  }

  if (upload.error !== null) {
    props.open(false);
    Swal.fire({
      title: "" + upload.error?.action.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={AddEmployeeStyle}>
          <div className="header">
            <div className="headingFile">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Upload File
              </Typography>
            </div>
            <button className="fileFormatButton" onClick={fileTemplateDownload}>
              <u>Download Format</u>
            </button>
            <button className="closeButton" onClick={() => handleClose()}>
              <Close />
            </button>
          </div>
          <input
            type="file"
            className="fileInput"
            onChange={(e) => onChangeFile(e)}
            accept=".xlsx"
          />

          <button className="uploadButton" onClick={onUploadFile}>
            Upload
          </button>

        </Box>
      </Modal>
    </div>
  );
}
