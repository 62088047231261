import * as types from "../../Config/actionType";

export const createLotRequest = (appointmentId,prescriptionId) => {
  return {
    type: types.CREATE_LOT_REQUEST,
    appointmentId,
    prescriptionId
  };
};

export const createLotSuccess = (data) => {
  return {
    type: types.CREATE_LOT_SUCCESS,
    data: data,
  };
};

export const createLotFailure = (data) => {
  return {
    type: types.CREATE_LOT_FAILURE,
    data: data,
  };
};
