import * as types from "../../Config/actionType"

export const viewAllCorporateRequest = (page,pageSize,search,city,status) => {
    return {
        type: types.VIEW_ALL_CORPORATE_REQUEST,
        page,
        pageSize,
        search,
        city,
        status
    }
}

export const viewAllCorporateSuccess = (action) => {
    return {
        type: types.VIEW_ALL_CORPORATE_SUCCESS,
        action
    }
}

export const viewAllCorporateFailure = (action) => {
    return {
        type: types.VIEW_ALL_CORPORATE_FAILURE,
        action
    }
}