import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Insurer/AssignProgramToInsurerAction";
import NetworkService from "../../../network/NetworkService";

export function* AssignProgramToInsurerSaga(action) {
  try {
    let response = yield call(NetworkService.AssignProgramToInsurer, action);
    yield put(actions.AssignProgramToInsurerSuccess(response));
  } catch (err) {
    yield put(actions.AssignProgramToInsurerFailure(err));
  }
}

export function* watchAssignProgramToInsurerSaga() {
  yield takeLatest(types.ASSIGN_PROGRAM_TO_INSURER_REQUEST, AssignProgramToInsurerSaga);
}
