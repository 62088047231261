import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/viewAppointmentAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAppointments(action) {
  try {
    let response = yield call(NetworkService.viewAppointments, action);
    yield put(actions.viewAppointmentsSuccess(response));
  } catch (err) {
    yield put(actions.viewAppointmentsFailure(err));
  }
}

export function* watchViewAppointments() {
  yield takeLatest(types.VIEW_APPOINTMENTS_REQUEST, viewAppointments);
}

