import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/UpdateCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* updateCategorySaga(action) {
 
  try {
   
    let response = yield call(NetworkService.updateCategory, action);
   
    yield put(actions.updateCategorySuccess(response));
  } catch (err) {
   
    yield put(actions.updateCategoryFailure(err));
  }
}

export function* watchUpdateCategorySaga() {
 
  yield takeLatest(types.UPDATE_CATEGORY_REQUEST, updateCategorySaga);
}
