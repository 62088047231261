import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Packages/viewInPackagesAction";
import NetworkService from "../../../network/NetworkService";

export function* viewInPackagesSaga(action) {
 
  try {
    let response = yield call(NetworkService.viewInPackages, action);
    yield put(actions.viewInPackagesSuccess(response));
   
  } catch (err) {
    yield put(actions.viewInPackagesFailure(err));
    
  }
}

export function* watchViewInPackagesSaga() {
  yield takeLatest(types.VIEW_IN_PACKAGES_REQUEST, viewInPackagesSaga);
}