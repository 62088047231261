import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/downloadAttachmentAction"
import NetworkService from "../../../network/NetworkService";

export function* downloadAttachmentSaga(action) {
  try {
   
    let response = yield call(NetworkService.downloadAttachment, action);
    yield put(actions.downloadAttachmentSuccess(response));
  } catch (err) {
    yield put(actions.downloadAttachmentFailure(err));
  }
}

export function* watchDownloadAttachmentSaga() {
  yield takeLatest(types.DOWNLOAD_ATTACHMENT_REQUEST, downloadAttachmentSaga);
}