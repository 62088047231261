import * as types from "../../Config/actionType"

export const GetCouponDetailsRequest = (id) => {
   
    return {
        type: types.GET_COUPON_DETAILS_REQUEST,
        id
            
    }
}

export const GetCouponDetailsSuccess = (action) => {
   
    return {
        type: types.GET_COUPON_DETAILS_SUCCESS,
        action
    }
}

export const GetCouponDetailsFailure = (action) => {
   
    return {
        type: types.GET_COUPON_DETAILS_FAILURE,
        action
    }
}