import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./users.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { viewAllUsersRequest } from "../../../redux/Actions/Users/UsersAction";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { viewAllUsersDropdownRequest } from "../../../redux/Actions/Users/UsersDropdownAction";
import ModalAddUser from "../../Organisms/Modal/ModalAddUser";

function Users() {
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [searchPage, setSearchPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [add, setAdd] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (city === null) {
      dispatch(viewAllUsersRequest(1, pageSize, searchText, "", selectedValue));
    } else {
      dispatch(
        viewAllUsersRequest(1, pageSize, searchText, city, selectedValue)
      );
    }
    dispatch(viewAllCityRequest());
    dispatch(viewAllUsersDropdownRequest());
    setPage(1);
  }, [city]);

  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        viewAllUsersRequest(page, pageSize, newSearchText, city, selectedValue)
      );
      setPage(1);
    } else {
      dispatch(
        viewAllUsersRequest(
          searchPage,
          pageSize,
          newSearchText,
          city,
          selectedValue
        )
      );
    }

    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleViewClick = (id) => {
    navigate(`/ViewUser/${id}`);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllUsersRequest(value, pageSize, searchText, city, selectedValue)
      );
      setPage(value);
    } else {
      setSearchPage(value);
      dispatch(
        viewAllUsersRequest(value, pageSize, searchText, city, selectedValue)
      );
    }
  };
  const viewCity = useSelector((state) => state.viewAllCity);

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllUsersRequest(
          1,
          event.target.value,
          searchText,
          city,
          selectedValue
        )
      );
    } else {
      dispatch(
        viewAllUsersRequest(
          searchPage,
          event.target.value,
          searchText,
          city,
          selectedValue
        )
      );
    }
    setPage(1);
    setPageSize(event.target.value);
  };

  const viewInUsers = useSelector((state) => state.ViewAllUsers);
  const viewAllUser = useSelector((state) => state.ViewAllUsersDropdown);

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      minWidth: 60,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.8,
    },
    {
      field: "corporateName",
      headerClassName: "super-app-theme--header",
      headerName: "Corporate Name",
      minWidth: 60,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      valueFormatter: (params) => {
        return params.value !== null ? params.value : "-";
      },
    },
    {
      field: "number",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: "Address",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      valueFormatter: (params) => {
        return params.value !== null ? params.value : "-";
      },
    },
    {
      field: "programCount",
      headerClassName: "super-app-theme--header",
      headerName: "Program",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "planCount",
      headerClassName: "super-app-theme--header",
      headerName: "Plan",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "lifetimeValue",
      headerClassName: "super-app-theme--header",
      headerName: "LifeTime Value",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      valueFormatter: (params) => {
        return params.value !== null ? params.value : "-";
      },
    },

    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleViewClick(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  let Dropdown = [{ name: "", id: "" }];

  viewAllUser.data?.data?.data?.data?.map((itm, ind) => {
    Dropdown.push({
      name: itm.name,
      id: itm.id,
    });
  });

  const handleSelection = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);
    dispatch(
      viewAllUsersRequest(1, pageSize, searchText, city, selectedOption)
    );
    setPage(1);
  };

  const handleAdd = (event) => {
    setAdd(true);
  };

  return (
    <>
      <div className="grid">
        <h4 className="h4">Users</h4>
        <div className="mainBox">
          <div className="head">
            <div className="appleftHead">
              <h5 className="list">List Of Users</h5>
              <div className="count">
                {viewInUsers.data?.data?.data?.totalUser} Users
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className="requestCitings">
              <select
                className="requestCiting"
                value={selectedValue}
                onChange={handleSelection}
              >
                <option value="">All</option>
                {viewAllUser.data?.data?.data?.data?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities"
                cityList={cityNames}
              />
            </div>

            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="head">
            <div className="rightheadbtn">
              <div type="button" className="btn" onClick={() => handleAdd()}>
                + Add User
              </div>
            </div>
          </div>
          {add && <ModalAddUser open={setAdd} />}

          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewInUsers?.data?.data?.data?.userDetailResponseDto && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                getRowId={(row) => row.id}
                pagination
                disableColumnMenu
                rowCount={viewInUsers?.data?.data.data.totalUser}
                rows={viewInUsers?.data?.data?.data?.userDetailResponseDto}
                columns={columns}
                // pageSize=pageSize
                rowsPerPageOptions={[10, 50, 100]}
                // onSelectionModelChange={(itm) => handleClick(itm)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
              count={viewInUsers?.data?.data?.data?.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Users;
