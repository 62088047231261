import * as types from "../../Config/actionType"

export const updateAppointmentsRequest = (data) => {
    return {
        type: types.UPDATE_APPOINTMENTS_REQUEST,
        data:data
    }
}

export const updateAppointmentsSuccess = (data) => {
    return {
        type: types.UPDATE_APPOINTMENTS_SUCCESS,
        data:data,
    }
}

export const updateAppointmentsFailure = (data) => {
    return {
        type: types.UPDATE_APPOINTMENTS_FAILURE,
        data:data,
    }
}