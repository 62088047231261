import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/updateDoctorAction";
import NetworkService from "../../../network/NetworkService";

export function* updateDoctorSaga(action) {
  try {
    let response = yield call(NetworkService.updateDoctor, action);
    yield put(actions.updateDoctorSuccess(response));
  } catch (err) {
    yield put(actions.updateDoctorFailure(err));
  }
}

export function* watchUpdateDoctorSaga() {
  yield takeLatest(types.UPDATE_DOCTOR_REQUEST, updateDoctorSaga);
}