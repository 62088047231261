import * as types from "../../Config/actionType";

export const updatePlanRequest = (data) => {
  return {
    type: types.UPDATE_PLAN_REQUEST,
    data: data,
  };
};

export const updatePlanSuccess = (data) => {
  return {
    type: types.UPDATE_PLAN_SUCCESS,
    data: data,
  };
};

export const updatePlanFailure = (data) => {
  return {
    type: types.UPDATE_PLAN_FAILURE,
    data: data,
  };
};
