import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/deleteByIdAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* deleteById(action) {
  try {
    let response = yield call(NetworkService.deleteById, action);
    yield put(actions.deleteByIdSuccess(response));
  } catch (err) {
    yield put(actions.deleteByIdFailure(err));
  }
}

export function* watchDeleteById() {
  yield takeEvery(types.DELETE_BY_ID_REQUEST, deleteById);
}