import { Route, Routes } from "react-router-dom";
import Career from "../../component/Organisms/Footer/Career/Career";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions";
import ModalAddBooking from "../../component/Organisms/Modal/ModalAddBooking";
import ViewOrderDetails from "../../component/Pages/Accounts/ViewOrderDetails";
import Agents from "../../component/Pages/Agents/Agents";
import EditAgent from "../../component/Pages/Agents/EditAgent";
import ViewAgent from "../../component/Pages/Agents/ViewAgent";
import ViewProgramAssignedInsurer from "../../component/Pages/Agents/ViewProgramAssignedInsurer";
import ViewAllAppointments from "../../component/Pages/Appointments/Appointment";
import CreatePrescription from "../../component/Pages/Appointments/CreatePrescription";
import ViewAppointment from "../../component/Pages/Appointments/ViewAppointment";
import AddBanner from "../../component/Pages/Banner/AddBanner";
import BannerListing from "../../component/Pages/Banner/BannerListing";
import Booking from "../../component/Pages/Booking/Booking";
import ViewBooking from "../../component/Pages/Booking/ViewBooking";
import Corporate from "../../component/Pages/Corporate/Corporate";
import ViewCorporate from "../../component/Pages/Corporate/ViewCorporate";
import ViewProgramAssigned from "../../component/Pages/Corporate/ViewProgramAssigned";
import Coupon from "../../component/Pages/Coupon/Coupon";
import ViewCoupon from "../../component/Pages/Coupon/ViewCoupon";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import AddDiscount from "../../component/Pages/Discount/AddDiscount";
import Discount from "../../component/Pages/Discount/Discount";
import EditDiscount from "../../component/Pages/Discount/EditDiscount";
import ViewDiscount from "../../component/Pages/Discount/ViewDiscount";
import Doctors from "../../component/Pages/Doctors/Doctors";
import ViewEmployee from "../../component/Pages/Employee/ViewEmployee";
import Hospitals from "../../component/Pages/Hospitals/Hospitals";
import ViewHospital from "../../component/Pages/Hospitals/ViewHospital";
import Lab from "../../component/Pages/Lab/Lab";
import Test from "../../component/Pages/Lab/Test";
import ViewLab from "../../component/Pages/Lab/viewLab";
import ViewTest from "../../component/Pages/Lab/viewTest";
import ViewTests from "../../component/Pages/Labs/ViewTests";
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import Packages from "../../component/Pages/Packages/Packages";
import ViewPackages from "../../component/Pages/Packages/ViewPackages";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";
import AddPlan from "../../component/Pages/Plan/AddPlan";
import ListOfPlan from "../../component/Pages/Plan/ListOfPlan";
import ViewPlanDetail from "../../component/Pages/Plan/ViewPlanDetail";
import AddProgramTemplate from "../../component/Pages/Program Template/AddProgramNew";
import AssignProgramInsurer from "../../component/Pages/Program Template/AssignProgramInsurer";
import AssignProgramTemplate from "../../component/Pages/Program Template/AssignProgramNew";
import Programs from "../../component/Pages/Programs/Programs";
import ViewAssignedProgram from "../../component/Pages/Programs/ViewAssignedProgram";
import ViewProgram from "../../component/Pages/Programs/ViewProgram";
import ClinicRequest from "../../component/Pages/Request/Clinic/ClinicRequest";
import ViewClinicRequest from "../../component/Pages/Request/Clinic/ViewClinicRequest";
import DiagnosticRequest from "../../component/Pages/Request/Diagnostic/DiagnosticRequest";
import ViewDiagnosticRequest from "../../component/Pages/Request/Diagnostic/ViewDiagnosticRequest";
import HospitalRequest from "../../component/Pages/Request/Hospital/HospitalRequest";
import ViewHospitalRequest from "../../component/Pages/Request/Hospital/ViewHospitalRequest";
import ProfessionalServicesRequest from "../../component/Pages/Request/ProfessionalService/ProfessionalServiceRequest";
import ViewProfessionalRequest from "../../component/Pages/Request/ProfessionalService/ViewProfessionalRequest";
import Services from "../../component/Pages/Services/Services";
import ViewServices from "../../component/Pages/Services/ViewServices";
import UnderConstuction from "../../component/Pages/UnderConstruction/UnderConstruction";
import ViewUser from "../../component/Pages/Users/ViewUser";
import Users from "../../component/Pages/Users/users";
import Banner from "../../component/Pages/Banner/Banner";
import LifeStyle from "../../component/Pages/LifeStyle/LifeStyle";
import AddLifeStyle from "../../component/Pages/LifeStyle/AddLifeStyle";
import UpdateLifeStyle from "../../component/Pages/LifeStyle/UpdateLifeStyle";
import CardNumberForm from "../../component/Organisms/Card/CardNumberForm";
import AssignCardSubscription from "../../component/Pages/Users/AssignCardSubscription";
import UpdatePlanPage from "../../component/Pages/Plan/UpdatePlanPage";
import EditCategory from "../../component/Pages/Products/CreateCategory";
import AddProducts from "../../component/Pages/Products/AddProducts";

import CreateCategory from "../../component/Pages/Products/CreateCategory";
import ProductListing from "../../component/Pages/Products/ProductListing";
import EditProduct from "../../component/Pages/Products/EditProduct";
import ProductDetails from "../../component/Pages/Products/ProductDetails";

import ViewCategoryDetails from "../../component/Pages/Products/ViewCategoryDetails";
import CategoryListing from "../../component/Pages/Products/CategoryListing";
import BrandListing from "../../component/Pages/Products/BrandListing";
import AddBrand from "../../component/Pages/Products/AddBrand";
import ViewBrandDetails from "../../component/Pages/Products/ViewBrandDetails";
import ViewSubCategory from "../../component/Pages/Products/ViewSubCategory";
import EditDiscountProduct from "../../component/Pages/Discount/EditDiscountProduct";
import ViewDiscountProduct from "../../component/Pages/Discount/ViewDiscountProduct";


export default function CustomerAgentPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/super-Admin-Dashboard" element={<DashBoard />} />
      <Route path="/agents" element={<Agents />} />
      <Route path="/corporate" element={<Corporate />} />
      <Route path="/doctor" element={<Doctors />} />
      <Route path="/hospital" element={<Hospitals />} />
      <Route path="/viewHospitalDetails/:id" element={<ViewHospital />} />
      <Route path="/viewAgentDetails/:id" element={<ViewAgent />} />
      <Route path="/editAgent" element={<EditAgent />} />
      <Route path="/viewCorporateDetails/:id" element={<ViewCorporate />} />
      <Route path="/appointments" element={<ViewAllAppointments />} />
      <Route path="/viewAppointmentDetails/:id" element={<ViewAppointment />} />
      <Route path="/program" element={<Programs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/viewService/:uuid" element={<ViewServices />} />
      <Route path="/programTemplate" element={<AddProgramTemplate />} />
      <Route path="/programAssign" element={<AssignProgramTemplate />} />
      <Route path="/viewAssignedProgram" element={<ViewAssignedProgram />} />
      <Route path="/underConstruction" element={<UnderConstuction />} />
      <Route path="/viewProgram/:name" element={<ViewProgram />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/Booking" element={<Booking />} />
      <Route path="/products" element={<EditCategory />} />
      <Route path="/AddProducts" element={<AddProducts />} />

      <Route path="/ViewTests" element={<ViewTests />} />
      <Route path="/Packages" element={<Packages />} />
      <Route path="/ViewBookingDetails/:id" element={<ViewBooking />} />
      <Route path="/ViewPackages/:uuid" element={<ViewPackages />} />
      <Route path="/Lab" element={<Lab />} />
      <Route path="/Test" element={<Test />} />
      <Route path="/viewLab/:id" element={<ViewLab />} />
      <Route path="/viewTest/:id" element={<ViewTest />} />
      <Route path="/viewEmployeeDetails/:id" element={<ViewEmployee />} />
      <Route path="/addPlan" element={<AddPlan />} />
      <Route
        path="/viewHospitalRequest/:id"
        element={<ViewHospitalRequest />}
      />
      <Route path="/hospitalRequest" element={<HospitalRequest />} />
      <Route path="/viewClinicRequest/:id" element={<ViewClinicRequest />} />
      <Route path="/clinicRequest" element={<ClinicRequest />} />
      <Route path="/programAssignInsurer" element={<AssignProgramInsurer />} />
      <Route
        path="/viewDiagnosticRequest/:id"
        element={<ViewDiagnosticRequest />}
      />
      <Route path="/diagnosticRequest" element={<DiagnosticRequest />} />
      <Route
        path="/ProfessionalServicesRequest"
        element={<ProfessionalServicesRequest />}
      />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
      <Route
        path="/viewProgramAssigned/:id"
        element={<ViewProgramAssigned />}
      />
      <Route path="/AddBooking" element={<ModalAddBooking />} />
      <Route
        path="/viewProgramAssignedInsurer/:id"
        element={<ViewProgramAssignedInsurer />}
      />
      <Route path="/coupon" element={<Coupon />} />
      <Route path="/viewCoupon/:id" element={<ViewCoupon />} />
      <Route path="/Discount" element={<Discount />} />
      <Route path="/ViewDiscount/:enumType/:id" element={<ViewDiscount />} />
      <Route path="/AddDiscount" element={<AddDiscount />} />
      <Route path="/EditDiscount" element={<EditDiscount />} />
      <Route
        path="/ViewProfessionalServiceRequest/:id"
        element={<ViewProfessionalRequest />}
      />
      <Route path="/ViewOrderDetails/:id" element={<ViewOrderDetails />} />
      <Route path="/Users" element={<Users />} />
      <Route path="/ListOfPlan" element={<ListOfPlan />} />
      <Route path="/planDetails/:uuid" element={<ViewPlanDetail />} />
      <Route path="/ViewUser/:id" element={<ViewUser />} />
      <Route path="/CreatePrescription/:id" element={<CreatePrescription />} />
      <Route path="/addBanner" element={<AddBanner />} />
      <Route path="/banner" element={<Banner />} />
      <Route path="/bannerList" element={<BannerListing />} />
      <Route path="/lifeStyle" element={<LifeStyle />} />
      <Route path="/addLifeStyle" element={<AddLifeStyle />} />
      <Route path="/updateLifeStyle/:id" element={<UpdateLifeStyle />} />
      <Route path="/CardForm" element={<CardNumberForm />} />
      <Route path="/AssignCard/:id" element={<AssignCardSubscription />} />
<Route path="/editplan/:uuid" element={<UpdatePlanPage />}/>
<Route path="/ProductListing" element={<ProductListing />} />
<Route path="/editProduct/:id" element={<EditProduct />} />
<Route path="/viewProduct/:id" element={<ProductDetails />} />
      <Route path="/editplan/:uuid" element={<UpdatePlanPage />} />

      <Route
        path="/viewCategoryDetails/:id"
        element={<ViewCategoryDetails />}
      />
            <Route
        path="/viewBrandDetails/:id"
        element={<ViewBrandDetails />}
      />
           <Route
        path="/viewSubCategoryDetails/:id"
        element={<ViewSubCategory />}
      />

      <Route path="/addCategory" element={<CreateCategory />} />
      <Route path="/addBrand" element={<AddBrand />} />
      <Route path="/addProduct" element={<AddProducts />} />
      <Route path="/viewCategory" element={<CategoryListing />} />
      <Route path="/viewBrand" element={<BrandListing />} />

      
      <Route path="/EditDiscountProduct/:id" element={<EditDiscountProduct />} />
      <Route path="/ViewDiscountProduct/:enumType/:id" element={<ViewDiscountProduct />} />
      
      
    </Routes>
  );
}
