import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/assignProgramToEmployeeAction";
import NetworkService from "../../../network/NetworkService";

export function* assignProgramToEmployeeSaga(action) {
  try {
    let response = yield call(NetworkService.assignProgramToEmployee, action);
    yield put(actions.assignProgramToEmployeeSuccess(response));
  } catch (err) {
    yield put(actions.assignProgramToEmployeeFailure(err));
  }
}

export function* watchAssignProgramToEmployeeSaga() {
  yield takeLatest(types.ASSIGN_PROGRAM_TO_EMPLOYEE_REQUEST, assignProgramToEmployeeSaga);
}