import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/makePackageAction";
import NetworkService from "../../../network/NetworkService";

export function* makePackage(action) {
  try {
    let response = yield call(NetworkService.makePackage, action);

    yield put(actions.makePackageSuccess(response))
  } catch (err) {
    yield put(actions.makePackageFailure(err));
  }
}

export function* watchMakePackage() {
  yield takeLatest(types.MAKE_PACKAGE_REQUEST, makePackage);
}