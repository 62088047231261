import React, { useEffect, useState } from 'react'
import Modal from "@mui/material/Modal";
import "./ModalAddBanner.css"
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerTypesRequest } from "../../../redux/Actions/Banner/getBannerTypesAction"
import swal from 'sweetalert';
import { addBannerRequest } from "../../../redux/Actions/Banner/addBannerAction"
import CircularProgress from "@mui/material/CircularProgress";

function ModalAddBanner(props) {
    const dispatch = useDispatch();

    const [bannerName, setBannerName] = useState();
    const [bannerPosition, setBannerPosition] = useState();
    const [bannerType, setBannerType] = useState("null");
    const screenType = props.bannerName;
    const screenTypeEnum = props.bannerEnumName;

    useEffect(() => {
        dispatch(getBannerTypesRequest());
    }, [])

    const getBannerTypes = useSelector((state) => state.getBannerTypes);

    function handleCloseModal() {
        props.handleClose(false);
    }

    function handleBannerPosition(e) {
        setBannerPosition(e.target.value);
    }

    function handleBannerName(e) {
        setBannerName(e.target.value);
    }

    function handleBannerType(e) {
        setBannerType(e.target.value);
    }

    const updateRes = useSelector((state) => state.addBanner)
    const [flag, setFlag] = useState(true);
    useEffect(()=>{
      if(updateRes?.data || updateRes?.error)
      {
  
        setFlag(true);
      }
  
    },[updateRes])
  
  

    function handleSubmit() {
        if (bannerName === "" || bannerPosition === "" || bannerType === "null" || screenType === "") {
            swal({
                title: "Please fill all the Details carefully",
                icon: "error",
            })
        }
        else {
    setFlag(false)

            let data = {
                bannerName: bannerName,
                bannerPosition: parseInt(bannerPosition),
                bannerType: bannerType,
                screenType: screenTypeEnum,
            }
            dispatch(addBannerRequest(data));
        }

    }
    const addBanner = useSelector((state) => state.addBanner);

    if (addBanner.data?.data.status === 201 && addBanner.data?.data.data.message !== "") {
        swal({
            title: `${addBanner.data?.data.data.message}`,
            icon: "success",
        }).then(() => {
            window.location.reload();
        })
    }

    if (addBanner.error?.data.response.status === 409 && addBanner.error?.data.response.data.errorMessage !== "") {
        swal({
            title: `${addBanner.error?.data.response.data.errorMessage}`,
            icon: "error",
        }).then(() => {
            addBanner.error.data.response.status = null;
        })
    }
    return (
        <Modal
            open={props.open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="mainModal"
        >
            <div className='addBannerModal'>
                <div className='addBannerModalCross'><CloseIcon onClick={handleCloseModal} style={{cursor:"pointer"}}/></div>
                <div className='addBannerModalChild'>
                    <div className='addBannerModalChildLeft'>Banner Screen </div>
                    <div className='addBannerModalChildRight'>{screenType}</div>
                </div>
                <div className='addBannerModalChild'>
                    <div className='addBannerModalChildLeft'>Select Banner Type </div>
                    <div className='addBannerModalChildRight'>
                        <select onChange={handleBannerType}>
                            <option value="null" selected disabled={true}>Select Banner Type</option>
                            {getBannerTypes.data?.data.data.data.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='addBannerModalChild'>
                    <div className='addBannerModalChildLeft'>Banner Name </div>
                    <div className='addBannerModalChildRight'><input type="text" onChange={handleBannerName} placeholder="Banner Name"/></div>
                </div>
                <div className='addBannerModalChild'>
                    <div className='addBannerModalChildLeft'>Position </div>
                    <div className='addBannerModalChildRight'><input type="number" onChange={handleBannerPosition}
                    placeholder="Position"
                        onKeyDown={(e) =>
                            (e.key === "0" || e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") &&
                            e.preventDefault()
                        }
                        onWheel={(event) => event.currentTarget.blur()}
                    /></div>
                </div>
                <button className='addBannerButton' disabled={!flag} onClick={handleSubmit}>
                {flag ? "    Add Banner":
                    
                    <CircularProgress/>
                    }
                </button>
            </div>
        </Modal>
    )
}

export default ModalAddBanner