import * as types from "../../Config/actionType"

export const getProgramInsurerRequest = (data) => {
    return {
        type: types.GET_PROGRAM_INSURER_REQUEST,
        data:data
    }
}

export const getProgramInsurerSuccess = (data) => {
    return {
        type: types.GET_PROGRAM_INSURER_SUCCESS,
        data:data,
    }
}

export const getProgramInsurerFailure = (data) => {
    return {
        type: types.GET_PROGRAM_INSURER_FAILURE,
        data:data,
    }
}