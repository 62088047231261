import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Lab/ViewAllLabAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllLabSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllLab, action);
    yield put(actions.viewAllLabSuccess(response));
  } catch (err) {
    yield put(actions.viewAllLabFailure(err));
  }
}

export function* watchViewAllLabSaga() {
  yield takeLatest(types.VIEW_ALL_LAB_REQUEST, viewAllLabSaga);
}