import * as types from "../../Config/actionType"

export const updateCorporateRequest = (data) => {
    return {
        type: types.UPDATE_CORPORATE_REQUEST,
        data:data
    }
}

export const updateCorporateSuccess = (data) => {
    return {
        type: types.UPDATE_CORPORATE_SUCCESS,
        data:data,
    }
}

export const updateCorporateFailure = (data) => {
    return {
        type: types.UPDATE_CORPORATE_FAILURE,
        data:data,
    }
}