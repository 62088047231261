import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Tests/updateTestAction";
import NetworkService from "../../../network/NetworkService";

export function* updateTestSaga(action) {
   
  try {
    let response = yield call(NetworkService.updateTest, action);
    yield put(actions.updateTestSuccess(response));
  } catch (err) {
    yield put(actions.updateTestFailure(err));
  }
}

export function* watchUpdateTestSaga() {
  yield takeLatest(types.UPDATE_TEST_REQUEST, updateTestSaga);
}
