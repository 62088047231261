import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/getInsurerAssignedProgramsAction";
import NetworkService from "../../../network/NetworkService";

export function* getInsurerAssignedProgramsSaga(action) {
  try {
    let response = yield call(NetworkService.getInsurerAssignedPrograms, action);
    yield put(actions.getInsurerAssignedProgramsSuccess(response));
  } catch (err) {
    yield put(actions.getInsurerAssignedProgramsFailure(err));
  }
}

export function* watchGetInsurerAssignedProgramsSaga() {
  yield takeLatest(types.GET_INSURER_ASSIGNED_PROGRAMS_REQUEST, getInsurerAssignedProgramsSaga);
}