import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/viewAllAgentAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllAgents(action) {
  try {
    let response = yield call(NetworkService.viewAllAgents, action);
    yield put(actions.viewAllAgentsSuccess(response));
  } catch (err) {
    yield put(actions.viewAllAgentsFailure(err));
  }
}

export function* watchViewAllAgents() {
  yield takeLatest(types.VIEW_ALL_AGENTS_REQUEST, viewAllAgents);
}

