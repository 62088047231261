import * as types from "../../Config/actionType"

export const userDetailRequest = (data) => {
   
    return {
        type: types.USER_DETAIL_REQUEST,
        data
    }
}

export const userDetailSuccess = (action) => {
    return {
        type: types.USER_DETAIL_SUCCESS,
        action
    }
}

export const userDetailFailure = (action) => {
    return {
        type: types.USER_DETAIL_FAILURE,
        action
    }
}