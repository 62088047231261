import * as types from "../../Config/Product/ProductActionType";

export const deleteCategoryRequest = (data) => {
  return {
    type: types.DELETE_CATEGORY_REQUEST,
    data,
  
  };
};

export const deleteCategorySuccess = (data) => {
  return {
    type: types.DELETE_CATEGORY_SUCCESS,
    data: data,
  };
};

export const deleteCategoryFailure = (data) => {
  return {
    type: types.DELETE_CATEGORY_FAILURE,
    data: data,
  };
};
