import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../Organisms/Footer/FooterUpper";
import Swal from "sweetalert2";
import "./HospitalDoctorList.css";
import AutoComplete from "../../../Molecules/Autocomplete/AutoComplete";
import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import storage from "../../../../Utils/storage";
import Switch from "@mui/material/Switch";
import DisableDoctorModal from "../../../Organisms/Modal/DisableDoctorModal";
import { DoctorListDetailRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/DoctorListDetail";
import { useNavigate } from "react-router-dom";
import { AllHospitalNameDropDownRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/AllHospitalNameDropdown";
import VisibilityIcon from "@mui/icons-material/Visibility";

function HospitalDoctorList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [formattedStartDate, setFormattedStartDate] = useState("");

  const [endDate, setEndDate] = useState();
  const [formattedEndDate, setFormattedEndDate] = useState("");

  const handleStartDate = (e) => {
    var date = new Date(e.target.value).getTime();

    setStartDate(date);
    setFormattedStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    var date = new Date(e.target.value).getTime();
    setEndDate(date);
    setFormattedEndDate(e.target.value);
  };

  const [hospitalDropdown, setHospitaldropdown] = useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const [delOpen, setdelOpen] = React.useState(false);
  const [delId, setDelId] = useState();
  const [searchPage, setSearchPage] = useState(1);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState(true);

  const DoctorListDetail = useSelector((state) => state.DoctorListDetail);

  const AllHospitalNameDropdown = useSelector(
    (state) => state.AllHospitalNameDropdown
  );

  useEffect(() => {
    if (hospitalDropdown === null) {
      dispatch(
        DoctorListDetailRequest(
          1,
          pageSize,
          searchText,
          "",
          status,
          endDate,
          startDate
        )
      );
    } else {
      dispatch(
        DoctorListDetailRequest(
          1,
          pageSize,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
    }
    setPage(1);
  }, [hospitalDropdown]);

  useEffect(() => {
    if (startDate !== undefined && endDate === undefined) {
      Swal.fire({
        title: "Please Add EndDate",
        icon: "error",
      });
    } else if (startDate > endDate && endDate !== undefined) {
      Swal.fire({
        title: "EndDate should be bigger than StartDate",
        icon: "error",
      });
    } else {
      dispatch(
        DoctorListDetailRequest(
          1,
          pageSize,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
    }
    setPage(1);
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(AllHospitalNameDropDownRequest());
  }, []);

  let hospitalNames = [{ label: "All Hospital", id: "" }];

  AllHospitalNameDropdown.data?.data?.data?.data.map((itm) => {
    hospitalNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleSearch = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        DoctorListDetailRequest(
          "",
          page,
          pageSize,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
      setPage(1);
    } else {
      dispatch(
        DoctorListDetailRequest(
          searchPage,
          pageSize,
          e.target.value,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
    }
    setSearchText(e.target.value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        DoctorListDetailRequest(
          value,
          pageSize,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
      setPage(value);
    } else {
      dispatch(
        DoctorListDetailRequest(
          value,
          pageSize,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        DoctorListDetailRequest(
          page,
          event.target.value,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
      setPage(1);
    } else {
      dispatch(
        DoctorListDetailRequest(
          searchPage,
          event.target.value,
          searchText,
          hospitalDropdown,
          status,
          endDate,
          startDate
        )
      );
    }
    setPageSize(event.target.value);
  };

  const handleDelete = (id) => {
    setDelId(id);
    setdelOpen(true);
  };

  const handleHospitalNames = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setHospitaldropdown(val.id);
  };

  const handleViewDetails = (id) => {
    navigate(`/HospitalViewDoctor/${id}`);
  };
  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        DoctorListDetailRequest(
          1,
          pageSize,
          searchText,
          hospitalDropdown,
          e.target.value,
          endDate,
          startDate
        )
      );
    } else {
      dispatch(
        DoctorListDetailRequest(
          1,
          pageSize,
          searchText,
          hospitalDropdown,
          e.target.value,
          endDate,
          startDate
        )
      );
    }
    setPage(1);
    setStatus(e.target.value);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "totalAppointment",
      headerClassName: "super-app-theme--header",
      headerName: "Total Number of Appointment",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "paidAppointment",
      headerClassName: "super-app-theme--header",
      headerName: "Number of Appointment Paid",
      minWidth: 160,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "amountPaid",
      headerClassName: "super-app-theme--header",
      headerName: "Amount Paid",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      field: "Modify",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 }}
              onClick={() => handleViewDetails(cellValues.row.id)}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {DoctorListDetail?.data?.data.data.data
              .hospitalDoctorDashboardResponseDtoList[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <div>
                <Switch disabled sx={{ color: "#52608E" }} />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div className="grid">
          <h4 className="h4">Doctors</h4>
          <div className="mainBox">
            {delOpen && (
              <DisableDoctorModal delOpen={setdelOpen} delId={delId} />
            )}

            <div className="head_doctor_hospitaldash">
              <div className="leftHead">
                <h5 className="list">List of Doctors</h5>
                <div className="doctorcount">
                  {DoctorListDetail.data?.data.data.data.totalDoctors} Doctors
                </div>
              </div>
              <div className="Doctor_datestyle_hospdash">
                <div className="paymentStartDate_hospdash">
                  <input
                    type="date"
                    className="HospitalDashboardDateInput"
                    onChange={(e) => handleStartDate(e)}
                    value={formattedStartDate}
                    style={{
                      border:
                        endDate !== 0 && startDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>

                <div className="paymentEndDate_hospdash">
                  <input
                    type="date"
                    className="HospitalDashboardDateInput"
                    onChange={(e) => handleEndDate(e)}
                    value={formattedEndDate}
                    style={{
                      border:
                        startDate !== 0 && endDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <hr />
            <div className="row2">
              <div className="requestCity">
                <AutoComplete
                  onChange={(event, newValue) => {
                    handleHospitalNames(event, newValue);
                  }}
                  heading="All Hospitals"
                  cityList={hospitalNames}
                />
                <select
                  className="requestDropdown"
                  onChange={(e) => handleStatus(e)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="All">All</option>

                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
              <div className="searchSection">
                <div className="searchBar">
                  <TextField
                    type="text"
                    name="search"
                    onChange={handleSearch}
                    variant="outlined"
                    sx={{
                      border: "1px solid #52608E",
                      borderRadius: "6px",
                      width: "350px",
                    }}
                    placeholder="Search "
                    size="small"
                    hiddenLabel="true"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "#52608E" }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
              {DoctorListDetail.data?.data.data.data
                .hospitalDoctorDashboardResponseDtoList && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    "&:hover:not(.Mui-disabled)": {
                      cursor: "pointer",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  rows={
                    DoctorListDetail?.data?.data.data.data
                      .hospitalDoctorDashboardResponseDtoList
                  }
                  columns={columns}
                  rowCount={
                    DoctorListDetail?.data?.data?.data?.data.totalDoctors
                  }
                  pageSize={pageSize}
                  disableColumnMenu
                  rowsPerPageOptions={[10, 50, 100]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  paginationMode="server"
                  components={{
                    Footer: "none",
                  }}
                />
              )}
            </Box>
            <div className="MainTablePagination">
              <p>Rows Per Page:</p>
              <select
                className="dropDown"
                defaultValue={10}
                onChange={handleDropChange}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <Pagination
                shape="rounded"
                count={DoctorListDetail?.data?.data?.data?.data.totalPages}
                color="primary"
                onChange={handlePageChange}
                page={
                  searchText === "" || searchText.length <= 1
                    ? page
                    : searchPage
                }
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
}

export default HospitalDoctorList;
