import * as types from "../../Config/actionType";

export const updateTestRequest = (data) => {
  return {
    type: types.UPDATE_TEST_REQUEST,
    data: data,
  };
};

export const updateTestSuccess = (data) => {
  return {
    type: types.UPDATE_TEST_SUCCESS,
    data: data,
  };
};

export const updateTestFailure = (data) => {
  return {
    type: types.UPDATE_TEST_FAILURE,
    data: data,
  };
};
