import * as types from "../../Config/actionType"

export const addServiceAttributeRequest = (data) => {
   
    return {
        type: types.ADD_SERVICE_ATTRIBUTE_REQUEST,
        data
    }
}

export const addServiceAttributeSuccess = (action) => {
    return {
        type: types.ADD_SERVICE_ATTRIBUTE_SUCCESS,
        action
    }
}

export const addServiceAttributeFailure = (action) => {
    return {
        type: types.ADD_SERVICE_ATTRIBUTE_FAILURE,
        action
    }
}
