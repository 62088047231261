import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAllBannerByTypeRequest } from '../../../../redux/Actions/Banner/getAllBannerByTypeAction'
import "../../../Pages/Banner/Banner.css"
import DownloadIcon from '@mui/icons-material/Download';

function TypeCBanner(props) {

 
  const dispatch = useDispatch();
  var contentType = "";
  var title = "";
  var description = "";
  var innerBannerName = "";
  var image = ""

  useEffect(() => {
    if(props.bannerPosition){
      dispatch(getAllBannerByTypeRequest("TYPE_C", props.bannerPosition, props.screenTypeEnum))

    }
  }, [])

  
  function downloadImage(imageSrc) {
    const url_1 = window.URL.createObjectURL(
      new Blob([imageSrc], {
        type: "application/octet-stream",
      })
    );
    const link = document.createElement("a");
    link.href = url_1;
    link.setAttribute("download", "image.png");
    document.body.appendChild(link);
    link.click();
  }
  


  const getAllBannerByType = useSelector((state) => state.banner.getAllBannerByType)

  if (getAllBannerByType.data?.data.innerBannerResponseDto.length !== 0) {
    contentType = getAllBannerByType.data?.data.innerBannerResponseDto[0].contentType;
    title = getAllBannerByType.data?.data.innerBannerResponseDto[0].title;
    description = getAllBannerByType.data?.data.innerBannerResponseDto[0].description;
    innerBannerName = getAllBannerByType.data?.data.innerBannerResponseDto[0].innerBannerName;
    image = getAllBannerByType.data?.data.innerBannerResponseDto[0].image;
  }

  const handleClick = () => {
    if(image!==""){
      window.open(image, '_blank');

    }
  }

  return (
    <div className="">
      <div className="infoDiv">
        <div className="infoDivField">Banner Id </div>
        <div className="infoDivValue">
          {props.bannerId}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Banner Screen </div>
        <div className="infoDivValue">
          {props.screenType}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Banner Name </div>
        <div className="infoDivValue">
          {props.bannerName}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Position </div>
        <div className="infoDivValue">
          {props.position}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Banner Type</div>
        <div className="infoDivValue">
          {props.bannerTypeEnum}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Screen Type</div>
        <div className="infoDivValue">
          {props.screenTypeEnum}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Inner Banner Name</div>
        <div className="infoDivValue">
          {innerBannerName === "" ? "---" : innerBannerName}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Content Type</div>
        <div className="infoDivValue">
          {contentType === "" ? "---" : contentType}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Title</div>
        <div className="infoDivValue">
          {title === "" ? "---" : title}
        </div>
      </div>
      <div className="infoDiv">
        <div className="infoDivField">Description</div>
        <div className="infoDivValue">
          {description === "" ? "---" : description}
        </div>
      </div>
      <div className="infoDiv" style={{marginBottom:"1em"}}>
        <div className="infoDivField">Image</div>
        <div className="infoDivValue" style={{display:"flex",alignItems:"center"}}>
          {image === "" ? "---" : <img src={image} onClick={() => handleClick()} style={{height: "50px", width: "100px", cursor: "pointer"}} />}
          {/* <DownloadIcon style={{marginLeft:"1em",width:"1em",height:"1em",cursor:"pointer"}} onClick={()=>downloadImage(image,"image.jpg")} /> */}
        </div>
      </div>
    </div>
  )
}

export default TypeCBanner