import * as types from "../../Config/actionType"

export const cancelBookingRequest = (data) => {
    return {
        type: types.CANCEL_BOOKING_REQUEST,
        data
    }
}

export const cancelBookingSuccess = (data) => {
    return {
        type: types.CANCEL_BOOKING_SUCCESS,
        data
    }
}

export const cancelBookingFailure = (data) => {
    return {
        type: types.CANCEL_BOOKING_FAILURE,
        data
    }
}