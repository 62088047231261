import * as types from "../../Config/actionType";

export const addTestRequest = (data) => {
  return {
    type: types.ADD_TEST_REQUEST,
    data: data,
  };
};

export const addTestSuccess = (data) => {
  return {
    type: types.ADD_TEST_SUCCESS,
    data: data,
  };
};

export const addTestFailure = (data) => {
  return {
    type: types.ADD_TEST_FAILURE,
    data: data,
  };
};
