import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { chatStatusRequest } from "../../../redux/Actions/InHouseDoctor/ChatStatusAction";
import { consultationRequestsRequest } from "../../../redux/Actions/InHouseDoctor/ConsultationRequestsAction";
import YuvaLoader from '../../Organisms/Loader/YuvaLoader';
import "./ChatStatus.css";
import "./ConsultationRequests.css";

function ChatStatus() {
  const { id } = useParams();
const navigate = useNavigate();
  const dispatch=useDispatch();
 const [reason,setReason]=useState(false);
 const [reasonText,setReasonText]=useState("");
  useEffect(()=>{
    dispatch(consultationRequestsRequest(id))
  },[])
  const consultationRequests=useSelector((state)=>state.consultationRequests);

  const handleComplete = (id) => {
    setReason(false);
    const requestData={
      id:id,
      bool:true,
      reasonText:""
    }
   dispatch(chatStatusRequest(requestData));
   window.location.reload();
  };
 function handleInComplete(){
    setReason(true);
  };
 function handleReason(e){
   setReasonText(e.target.value);
    
  }
   
  function handleClick(id){
    const requestData={
      id:id,
      bool:false,
      reasonText:reasonText
    }
    if(requestData.reasonText===""){
      Swal.fire({
        title: "Please write Something!!",
        icon: "info",
      })
    }
else{
  dispatch(chatStatusRequest(requestData));
  window.location.reload();

}
   
  }


 useEffect(()=>{
 
  if(consultationRequests?.data?.data.data.chatStatus==="COMPLETED"){
  
    navigate(`/prescriptionForm/${id}`)

}

if(consultationRequests?.data?.data.data.chatStatus==="INCOMPLETE"){
  navigate("/consultationRequests")

}
},[consultationRequests])

const chatStatus =useSelector((state)=>state.chatStatus)



  if(chatStatus.isLoading){
    return <YuvaLoader />;
  }


  return (
    <div>
      <div className="ConsultationRequestHeader">Set Chat Status</div>
      
        <div className="ConsultationRequestCard">
          <div className="ConsultationRequestInfoWrapper">
            <h1 className="ConsultationRequestInfo">
            What is the Chat Status with the user ?
            </h1>
          </div>
          <div className="ConsultationRequestButtonWrapper">
            <button
              className="ConsultationRequestAcceptButton"
              onClick={() => {
                handleComplete(id);
              }}
            >
              Completed
            </button>
            <button
              className="ConsultationRequestRejectButton"
              onClick={() => {
                handleInComplete();
              }}
            >
              Incomplete
            </button>
          </div>
          
        </div>
        {reason?
         <div className='reasonArea'>
         <textarea className='reasonTextArea' onChange={handleReason} placeholder='Please Specify Reason...'/>
         <button  className="ConsultationRequestAcceptButton"  onClick={()=>handleClick(id)}>Submit</button>
         </div>:""}
       
        
    </div>
  );
}

export default ChatStatus;
