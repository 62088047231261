import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/getBannerByScreenTypesAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* getBannerByScreenTypes(action) {
  try {
    let response = yield call(NetworkService.getBannerByScreenTypes, action);
    yield put(actions.getBannerByScreenTypesSuccess(response));
  } catch (err) {
    yield put(actions.getBannerByScreenTypesFailure(err));
  }
}

export function* watchGetBannerByScreenTypesSaga() {
  yield takeEvery(types.GET_BANNER_BY_SCREEN_TYPES_REQUEST, getBannerByScreenTypes);
}