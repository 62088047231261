import RequestService from "../RequestService";
import { api } from "../../helpers/Pharmacy/PharmacyConstant"

class PharmacyNetworkServices {


addPharmacy(action) {
    const endPoint = `${api.addPharmacy}`;
    return RequestService.postRequest(endPoint, {
      address: action.data.address,
      cityId: parseInt(action.data.city),
      contactEmail:action.data.contactEmail,
      contactName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactPersonNumber,
      id: "",
      licenseNumber:action.data.licenseNumber,
      mapUrl:action.data.mapUrl,
      name: action.data.name.trim(),
      password: action.data.password,
      pharmacyValidity:parseInt(action.data.pharmacyValidity),
      pinCode: action.data.pinCode,
    });
  }

  deletePharmacy(action) {
    
    const endPoint = `${api.deletePharmacy}/${action.id}/?disableReason=${action.data}`;
    return RequestService.putRequest(endPoint);
  }

  viewAllPharmacy(action) {
    const endPoint = `${api.viewAllPharmacy}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityIdList: action.city === null ? null : [`${action.city}`],
      searchKey: action.name,
      active: action.status === "All" ? null : action.status,
    });
  }

  getPharmacyDetail(action) {
    const endPoint = `${api.getPharmacyDetail}/${parseInt(action.data)}`;
    return RequestService.getRequest(endPoint);
    }
}
export default new PharmacyNetworkServices();