import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css"
// import { BarChart } from '@mui/icons-material';

function AlcoholRisk_DonutChart({alcoholResponseDto}) {
 

  return (

   
    <React.Fragment>
      <div className="container-fluid mb-3">
        <Chart
          type="donut"
          //   width={549}
          //   height={560}
          series={[
            alcoholResponseDto?.heavyDrinker,
            alcoholResponseDto?.moderate,
            alcoholResponseDto?.nonDrinker,
            alcoholResponseDto?.occasional,
          ]}
          options={{
            labels: ["Heavy Drinker", "Moderate ", "Non Drinker", "Occasional"],
            colors: ["#C82404", "#38B9AE", "#E68D36", "#E6BF36"],



            chart: {
              type: "donut",
            },
            fill: {
                colors: ["#C82404", "#38B9AE", "#E68D36", "#E6BF36"],

            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,

                    // Zindex:""
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],

            dataLabels: {
              enabled: false,
              colors: ["#C82404", "#38B9AE", "#E68D36", "#E6BF36"],

            },
            plotOPtions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                  },
                },
              },
            },
          }}
        />
        <div className="BMI_Donut_OuterContainer">
          <div >
            <div className="BMI_ObeseHeading">Number of Heavy Drinker</div>
            <div className="BMI_ObeseSubHeading">{ alcoholResponseDto?.heavyDrinker}</div>
          </div>
          <div >
            <div className="BMI_ObeseHeading">Number of Moderate Drinker</div>
            <div className="BMI_ObeseSubHeading">{alcoholResponseDto?.moderate}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlcoholRisk_DonutChart;
