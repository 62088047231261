import * as types from "../../Config/actionType"

export const addReportRequest = (data,id) => {
    return {
        type: types.ADD_REPORT_REQUEST,
        data,
        id
    }
}

export const addReportSuccess = (action) => {
    return {
        type: types.ADD_REPORT_SUCCESS,
        action
    }
}

export const addReportFailure = (action) => {
    return {
        type: types.ADD_REPORT_FAILURE,
        action
    }
}