import * as types from "../../Config/actionType"

export const viewAllHospitalNamesRequest = () => {
    return {
        type: types.VIEW_ALL_HOSPITAL_NAMES_REQUEST,
    }
}

export const viewAllHospitalNamesSuccess = (action) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_NAMES_SUCCESS,
        action
    }
}

export const viewAllHospitalNamesFailure = (action) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_NAMES_FAILURE,
        action
    }
}