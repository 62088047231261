import * as types from "../../Config/actionType"

export const viewAllCityRequest = () => {
    return {
        type: types.VIEW_ALL_CITY_REQUEST,
    }
}

export const viewAllCitySuccess = (data) => {
    return {
        type: types.VIEW_ALL_CITY_SUCCESS,
        data:data,
    }
}

export const viewAllCityFailure = (data) => {
    return {
        type: types.VIEW_ALL_CITY_FAILURE,
        data:data,
    }
}