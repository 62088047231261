import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { InputAdornment } from "@mui/material";
import storage from "../../../Utils/storage";

export default function ComboBox(props) {
  const { heading, cityList } = props;

  const defaultCity = { label: heading, id: "" };
  const cityName = storage.getStorage("cityName")
  return (
    <Autocomplete
      {...props}
      disablePortal
      id="combo-box-demo"
      options={props.cityList}
      defaultValue= {defaultCity}
      // value={props.value}
      isOptionEqualToValue={(option, value) => option === value}
      sx={{
        marginLeft: "1.525em",
        width: "250px",
        color: "#52608E",
        borderRadius: "6px",
        border: "1px solid #52608E",
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            // name="City"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            // label="All Hospitals"
          />
        );
      }}
    />
  );
}