import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/AcceptOrRejectRequestAction"
import NetworkService from "../../../network/NetworkService";

export function* AcceptOrRejectRequestSaga(action) {
  try {
     
    let response = yield call(NetworkService.AcceptOrRejectRequest, action);
    yield put(actions.AcceptOrRejectSuccess(response));
  } catch (err) {
    yield put(actions.AcceptOrRejectFailure(err));
  }
}

export function* watchAcceptOrRejectRequestSaga() {
  yield takeLatest(types.ACCEPT_OR_REJECT_REQUEST_REQUEST, AcceptOrRejectRequestSaga);
}