import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import Slider from "@mui/material/Slider";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDebouncedCallback } from "use-debounce";
import { GetAllDiscountRequest } from "../../../redux/Actions/Discount/GetAllDiscountAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./Discount.css";

function Discount() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [maxDiscount, setMaxDiscount] = useState(100);
  const [minDiscount, setMinDiscount] = useState(0);
  const [debounceMaxDiscount, setDebounceMaxDiscount] = useState(100);
  const [debounceMinDiscount, setDebounceMinDiscount] = useState(0);
  const [search, setSearch] = useState("");
  const [enumType, setEnumType] = useState(sessionStorage.getItem("enumType") === null ? "PACKAGE" : sessionStorage.getItem("enumType"));
  const [value1, setValue1] = React.useState([0, 100]);
  const [count, setCount] = useState(0);
  const [selectedUUID, setSelectedUUID] = useState([]);
  const [selectedObject, setSelectedObject] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Package Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="ColumnColourRed">₹ {(params.row["totalAmount"])}</div>
          </>
        );
      },
    },

    {
      field: "discountAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Discounted Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="ColumnColorGreen">
              ₹ {params.row["discountAmount"]}
            </div>
          </>
        );
      },
    },

    {
      field: "discountPercentage",
      headerClassName: "super-app-theme--header",
      headerName: "Discount %",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] === null ? (
              <div className="">-</div>
            ) : (
              <div className="ColumnColorGreen">
                {params.row["discountPercentage"]} %
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      flex: 1.3,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["startDate"] !== null ? (
              <div>{new Date(params.row["startDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <>
            {params.row["endDate"] !== null ? (
              <div>{new Date(params.row["endDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      headerName: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] !== null ? (
              <div className="modify">
                <VisibilityIcon
                  sx={{ color: "#52608E", cursor: "pointer" }}
                  onClick={() => handleClick(params.row.uuid)}
                />
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
  ];
  const columnsTest = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Test Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="ColumnColourRed">₹ {(params.row["totalAmount"])}</div>
          </>
        );
      },
    },

    {
      field: "discountAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Discounted Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="ColumnColorGreen">
              ₹ {params.row["discountAmount"]}
            </div>
          </>
        );
      },
    },

    {
      field: "discountPercentage",
      headerClassName: "super-app-theme--header",
      headerName: "Discount %",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] === null ? (
              <div className="">-</div>
            ) : (
              <div className="ColumnColorGreen">
                {params.row["discountPercentage"]} %
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      flex: 1.3,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["startDate"] !== null ? (
              <div>{new Date(params.row["startDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <>
            {params.row["endDate"] !== null ? (
              <div>{new Date(params.row["endDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      headerName: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] !== null ? (
              <div className="modify">
                <VisibilityIcon
                  sx={{ color: "#52608E", cursor: "pointer" }}
                  onClick={() => handleClick(params.row.uuid)}
                />
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
  ];

  const columnsPlan = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Plan Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },

    {
      field: "discountPercentage",
      headerClassName: "super-app-theme--header",
      headerName: "Discount %",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] === null ? (
              <div className="">-</div>
            ) : (
              <div className="">{params.row["discountPercentage"]} %</div>
            )}
          </>
        );
      },
    },
    {
      field: "quarterly",
      headerClassName: "super-app-theme--header",
      headerName: "Quarterly",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["quarterly"] === null ? (
              <div className="">-</div>
            ) : (
              <div className="ColumnColourRed">₹ {Math.floor(params.row["quarterlyDiscount"])}</div>
            )}
          </>
        );
      },
    },
    {
      field: "halfYearly",
      headerClassName: "super-app-theme--header",
      headerName: "HalfYearly",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["halfYearly"] === null ? (
              <div className="">-</div>
            ) : (
              <div className="ColumnColourRed">
                ₹ {Math.floor(params.row["halfYearlyDiscount"])}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "yearly",
      headerClassName: "super-app-theme--header",
      headerName: "Yearly",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["yearly"] === null ? (
              <div className="">-</div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="ColumnColourRed" style={{ color: "green", fontSize: "1.1em" }}>₹{Math.floor(params.row["yearlyDiscount"])}</div>
                <div className="ColumnColourRed" style={{ fontSize: ".89em" }}>₹{Math.floor(params.row["yearly"])}</div>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["startDate"] !== null ? (
              <div>{new Date(params.row["startDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["endDate"] !== null ? (
              <div>{new Date(params.row["endDate"]).toLocaleString()}</div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
    {
      headerName: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
              <div className="modify">
                <VisibilityIcon
                  sx={{ color: "#52608E", marginRight: 2 }}
                  onClick={() => handleClick(params.row.uuid)}
                />
              </div>
          </>
        );
      },
    },
  ];

  const columnsProduct = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Test Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "discountResponseDtoForProductPriceWiseList",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      cellClassName: "dynamic-row-height-column",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" ,alignItems:"center"}}>
          <div >
            {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

              <div  >{item.unit} {item.unitEnum}</div>
            )))}
          </div>
          
        </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column",alignItems:"center" }}>
            {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

              <div className="ColumnColourRed" >₹ {item.totalAmount}</div>
            )))}
          </div>
        );
      },
    },

    {
      field: "discountAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Discounted Amount",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" ,color:"green" ,alignItems:"center"}}>
            {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

              <div className="ColumnColourRed" style={{ color:"green"}}> {item.discountAmount!==0?`₹${item.discountAmount}`:"---"}</div>
            )))}
          </div>
        );
      },
    },

    {
      field: "discountPercentage",
      headerClassName: "super-app-theme--header",
      headerName: "Discount %",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
             
            <div style={{ display: "flex", flexDirection: "column"  ,alignItems:"center"}}>
              {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

                <div  style={{ color:"green"}}> {item.discountPercentage===null?"---":` ${item.discountPercentage} %`}</div>
              )))}
            </div>
            
          </>
        );
      },
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      align: "center",
      flex: 1.3,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" ,alignItems:"center" }}>
            {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

              <div > {item.startDate!==null?new Date(item.startDate).toLocaleString():"---"}</div>
            )))}
          </div>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" ,alignItems:"center"}}>
            {(params.row["discountResponseDtoForProductPriceWiseList"].map((item) => (

              <div  > {item.endDate!==null?new Date(item.endDate).toLocaleString():"---"}</div>
            )))}
          </div>
        );
      },
    },
    {
      headerName: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["discountPercentage"] !== null ? (
              <div className="modify">
                <VisibilityIcon
                  sx={{ color: "#52608E", cursor: "pointer" }}
                  onClick={() => handleClick(params.row.uuid)}
                />
               
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        );
      },
    },
  ];

  const discountPercentageSlider = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "[Discount%]",
    },

    {
      value: 100,
      label: "100%",
    },
  ];
  const minDistance = 0;

  const debouncedOnChanged = useDebouncedCallback((value1, value2) => {
    setDebounceMinDiscount(value1);
    setDebounceMaxDiscount(value2);
  }, 500);

  const handleSlideFilter = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }

    setMinDiscount(newValue[0]);
    setMaxDiscount(newValue[1]);
    debouncedOnChanged(newValue[0], newValue[1]);
  };

  useEffect(() => {
    dispatch(
      GetAllDiscountRequest(
        page,
        pageSize,
        maxDiscount,
        minDiscount,
        search,
        enumType
      )
    );
  }, [
    page,
    pageSize,
    debounceMaxDiscount,
    debounceMinDiscount,
    search,
    enumType,
  ]);


  const getAllDiscount = useSelector((state) => state.getAllDiscount);

  const handleClick = (id) => {
    if(enumType==="PRODUCT"){
      navigate(`/ViewDiscountProduct/${enumType}/${id}`)
    }
    else{

      navigate(`/ViewDiscount/${enumType}/${id}`);
    }
  };

  const handleAddDiscount = () => {
    if (selectedUUID.length === 0) {
      Swal.fire({
        title: "Please Select before adding",
        icon: "error",
      });
    } else {
      navigate(`/AddDiscount`, {
        state: {
          selectedUUID: selectedUUID,
          enumType: enumType,
          details: selectedObject,
          allSelected: allSelected,
          maxDiscount: maxDiscount,
          minDiscount: minDiscount,
          search: search,
        },
      });
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSelect = (itm) => {
    setSelectedUUID(itm);
    const selectedRowsData = itm.map((id) =>
      getAllDiscount.data?.data.data?.data.discountResponseDtos.find(
        (row) => row.id === id
      )
    );
    if (itm.length === 1) {
      const amt = itm.map((id) =>
        getAllDiscount.data?.data.data?.data.discountResponseDtos.find(
          (row) => row.uuid === id
        )
      );
      setSelectedObject(amt);
    }
    if (itm.length === pageSize) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCount(selectedRowsData.length);
  };

  const handleDropdown = (e) => {
    sessionStorage.setItem("enumType", e.target.value);
    setEnumType(e.target.value)
  }
  return (
    <div>
      <div className="grid">
        <h4 className="h4">Discount</h4>
        <div className="mainBox">
          <div className="paymentHead">
            <div className="appleftHead">
              <h5 className="paymentLotlist">Discount Lists</h5>
            </div>
            <div className="appRHead">
              <div
                type="button"
                className="btn addModalbtn"
                onClick={() => handleAddDiscount()}
                style={{height:"2.5em"}}
              >
                + Add Discount
              </div>
            </div>
          </div>
          <hr />
          <div className="paymentRow2New">
            <div>
              <select
                className="requestDropdownDiscount"
                onChange={(e) => handleDropdown(e)}
                value={enumType}
              >
                <option value="PACKAGE">Health Packages</option>
                <option value="TEST">Diagnostic Tests</option>
                <option value="PLAN">Health Plans</option>
                <option value="PRODUCT">Product</option>
              </select>
            </div>
            <div className="" style={{display:"flex"}}>
              <div>
                {" "}
                <Box sx={{ width: 200, marginRight: "2em" }}>
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={value1}
                    onChange={handleSlideFilter}
                    valueLabelDisplay="auto"
                    disableSwap
                    marks={discountPercentageSlider}
                  />
                </Box>
              </div>

              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  variant="outlined"
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #52608E",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <Box sx={{ height: 600, width: "100%", marginTop: "0%" }}>
            {getAllDiscount.data?.data.data?.data.discountResponseDtos && (
              <DataGrid
                checkboxSelection
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                getRowId={(row) => row.uuid}
               rowHeight
                onSelectionModelChange={(itm) => handleSelect(itm)}
                pagination
                disableColumnMenu
                rowCount={getAllDiscount.data?.data.data?.data.totalRecords}
                rows={getAllDiscount.data?.data.data?.data.discountResponseDtos}
                columns={enumType === "PACKAGE" ? columns : (enumType === "PLAN" ? columnsPlan : (enumType === "PRODUCT" ? columnsProduct : columnsTest))}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={getAllDiscount.data?.data.data?.data.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Discount;
