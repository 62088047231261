import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/LifeStyle/lifeStyleActionType"
import * as actions from "../../Actions/LifeStyle/viewLifeStyleAction"
// import NetworkService from "../../../network/NetworkService";
import NetworkService from "../../../network/LifeStyle/viewAllLifeStyleService"

export function* viewLifeStyleDetail(action) {
  try {
    let response = yield call(NetworkService.viewLifeStyleDetail, action);
    yield put(actions.viewLifeStyleSuccess(response));
  } catch (err) {
    yield put(actions.viewLifeStyleFailure(err));
  }
}

export function* watchViewLifeStyleDetail() {
  yield takeLatest(types.VIEW_LIFE_STYLE_REQUEST, viewLifeStyleDetail);
}

