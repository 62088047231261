import React, { useEffect, useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateLabRequest } from "../../../redux/Actions/Lab/updateLabAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const loginSchema = Yup.object().shape({
  contactNumber: Yup.string().test(
    "len",
    "Must be exactly 10 digits",
    (val) => val.length === 10
  ),
  email: Yup.string().required("Required Field"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function ModalUpdateLab(props) {
  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, []);
  const viewCity = useSelector((state) => state.viewAllCity);
  const dispatch = useDispatch();
  const handleClose = () => props.open(false);
  const updateRes = useSelector((state) => state.updateLab)

  const [recvEmail, setRecvEmail] = useState(props.value?.data.data.contactEmail);
  const [recvMobileNumber, setRecvMobileNumber] = useState(props.value?.data.data.contactPersonNumber);

  
  const [isPasswordFirstClick, setIsPasswordFirstClick] = useState(false);

  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)
    setTimeout(() => {
      dispatch(updateLabRequest({values,recvEmail,recvMobileNumber}));
      setSubmitting(false);
    }, 400);
  };
  const res = useSelector((state) => state.updateLab);

  if (res.data?.data.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Lab details updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 200 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleCheckEmail = (event) => {
    if (event.target.value !== props.value?.contactEmail) {
      setRecvEmail(event.target.value);
    }
  };

  const handleCheckMobileNumber =(e)=>{
    if (e.target.value !== props.value?.data.data.contactPersonNumber) {
      setRecvMobileNumber(e.target.value);
    }
  }
  

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);

  return (
    <div>
      {/* <Button onClick={handleOpen}>+Add New Doctor</Button> */}
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Update Lab
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              name: props.value.data.data.contactName,
              LabName: props.value.data.data.name,
              LabAdddress: props.value.data.data.address,
              city: props.value.data.data.city,
              pinCode: props.value.data.data.pinCode,
              email: props.value.data.data.contactEmail,
              contactNumber: props.value.data.data.contactPersonNumber,
              contactName: props.value.data.data.contactName,
              id: props.value.data.data.id,
              password: props.value?.data.data.password?.slice(0, 6),
              confirmPassword: props.value?.data.data.password?.slice(0, 6),
              mapUrl: props.value.data.data.mapUrl,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <div className="modalInfo">
                    <label className="modalInfoLabel">Lab Name</label>
                    <label>: {props.value.data.data.contactName}</label>
                  </div>
                  <div className="modalInfo">
                    <label className="modalInfoLabel">Lab Address</label>
                    <label>: {props.value.data.data.address}</label>
                  </div>
                  <div className="modalInfo">
                    <label className="modalInfoLabel">Pin Code</label>
                    <label>: {props.value.data.data.pinCode}</label>
                  </div>
                  <div className="modalInfo">
                    <label className="modalInfoLabel">Contact Name</label>
                    <label>: {props.value.data.data.contactName}</label>
                  </div>
                  <div className="modalInfo">
                    <a
                      href={props.value.data.data.mapUrl}
                      className="modalInfoLabelsA"
                    >
                      View on Map
                    </a>
                  </div>

                  <label>
                    <Field
                      type="number"
                      name="pinCode"
                      placeholder="Pin Code"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Lab Email"
                      className="inputField"
                      value={recvEmail }
                      onChange={(e) => {
                        setRecvEmail(e.target.value)
                        if (
                          e.target.value !== props.value?.data.data.contactEmail
                        ) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        } else {
                          handleCheckEmail(e);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {(props.value?.data.data.contactEmail!==recvEmail &&  emailDetail.error?.data.response?.status === 302) && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Lab Contact Number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      value={recvMobileNumber}
                      onChange={(e) => {
                        setRecvMobileNumber(e.target.value)
                        if (
                          e.target.value !== props.value?.data.data.contactPersonNumber
                        ) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        } else {
                          handleCheckMobileNumber(e);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Generate Password"
                      className="inputField"
                      onClick={() => {
                        if (!isPasswordFirstClick) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="password"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Retype Password"
                      defaultValue="DefaultPassword"
                      className="inputField"
                      disabled={!isPasswordFirstClick}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="confirmPassword"
                      component="div"
                    />
                  </label>

                  <button type="submit"   disabled={!flag} className="modalButton">
                    {flag ? "       Update Lab" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdateLab;
