import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/confirmBookingAction"
import NetworkService from "../../../network/NetworkService";

export function* confirmBookingSaga(action) {
  try {
    let response = yield call(NetworkService.confirmBooking, action);
    yield put(actions.confirmBookingSuccess(response));
  } catch (err) {
    yield put(actions.confirmBookingFailure(err));
  }
}

export function* watchConfirmBookingSaga() {
  yield takeLatest(types.CONFIRM_BOOKING_REQUEST, confirmBookingSaga);
}