import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/finishAppointmentAction"
import NetworkService from "../../../network/NetworkService";

export function* finishAppointmentSaga(action) {
  try {
    let response = yield call(NetworkService.finishAppointment, action);
    yield put(actions.finishAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.finishAppointmentFailure(err));
  }
}

export function* watchFinishAppointmentSaga() {
  yield takeLatest(types.FINISH_APPOINTMENT_REQUEST, finishAppointmentSaga);
}