import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/addSubCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* addSubCategorySaga(action) {
 

  try {
    let response = yield call(NetworkService.addSubCategory, action);

    yield put(actions.addSubCategorySuccess(response));
  } catch (err) {
    yield put(actions.addSubCategoryFailure(err));
  }
}

export function* watchAddSubCategorySaga() {
  yield takeLatest(types.ADD_SUB_CATEGORY_REQUEST, addSubCategorySaga);
}
