import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getAllCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getAllCategorySaga(action) {

 
  try {
   
    let response = yield call(NetworkService.getAllCategory, action);
   
    yield put(actions.getAllCategorySuccess(response));
  } catch (err) {
   
    yield put(actions.getAllCategoryFailure(err));
  }
}

export function* watchGetAllCategorySaga() {
 
  yield takeLatest(types.GET_ALL_CATEGORY_REQUEST, getAllCategorySaga);
}
