import * as types from "../../Config/Pharmacy/PharmacyActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const AccountviewAllPaymentListingReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_SUCCESS:
      return {
        ...initialState,
        data: action,
      };
    case types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
