import * as types from "../../Config/actionType"

export const getDirectionRequest = (data) => {
    return {
        type: types.GET_DIRECTION_REQUEST,
        data:data
    }
}

export const getDirectionSuccess = (data) => {
    return {
        type: types.GET_DIRECTION_SUCCESS,
        data:data,
    }
}

export const getDirectionFailure = (data) => {
    return {
        type: types.GET_DIRECTION_FAILURE,
        data:data,
    }
}