import * as types from "../../Config/actionType"

export const addEmployeeFileRequest = (data,uuid) => {
    return {
        type: types.ADD_EMPLOYEE_FILE_REQUEST,
        data,
        uuid
    }
}

export const addEmployeeFileSuccess = (action) => {
    return {
        type: types.ADD_EMPLOYEE_FILE_SUCCESS,
        action
    }
}

export const addEmployeeFileFailure = (action) => {
    return {
        type: types.ADD_EMPLOYEE_FILE_FAILURE,
        action
    }
}