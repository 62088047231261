import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Hospital/NetworkHospitalAction"
import NetworkService from "../../../../network/NetworkService";

export function* networkHospitalSaga(action) {
  try {
    let response = yield call(NetworkService.networkHospital,action);
    yield put(actions.networkHospitalSuccess(response));
  } catch (err) {
    yield put(actions.networkHospitalFailure(err));
  }
}

export function* watchNetworkHospitalSaga() {
  yield takeLatest(types.NETWORK_HOSPITAL_REQUEST, networkHospitalSaga);
}