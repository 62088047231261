import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as Yup from "yup";
import { deletePharmacyRequest } from "../../../redux/Actions/Pharmacy/deletePharmacyAction";
import "./DisableModal.css";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const loginSchema = Yup.object().shape({
  disableReason: Yup.string().required("Required Field"),
});

function DisablePharmacyModal(props) {

  const dispatch = useDispatch();

  const handleClose = () => {
    window.location.reload();
    props.delOpen(false);
  };

  const deletePharmacy = useSelector((state) => state.pharmacy.deletePharmacy);
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(deletePharmacy?.data || deletePharmacy?.error)
    {

      setFlag(true);
    }

  },[deletePharmacy])


  const handleSubmit = (values, { setSubmitting }) => {
   
    setFlag(false)
   
    setTimeout(() => {
      dispatch(
        deletePharmacyRequest(props.delId, values.disableReason)
      );

      setSubmitting(false);
    }, 400);
  };
  
  if (deletePharmacy?.data?.data?.status === true) {
    swal({
      title: deletePharmacy?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  } 
  
  return (
    <div>
      <Modal
        open={props.delOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="DisableModalHeading"
            >
              Disable Pharmacy
            </Typography>
            <div className="DisableModalSubHeading">Disable Reason</div>
          </div>

          <Formik
            initialValues={{
              disableReason: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Form>
                <div className="Responsiveyu">
                  <label>
                    <Field
                      as="Textarea"
                      type="text"
                      name="disableReason"
                      placeholder="Reason To Disable"
                      className="disableReasontextarea"
                    />
                  </label>
                  <ErrorMessage
                    className="errorMessageModalValidationhosp"
                    name="disableReason"
                    component="div"
                  />
                 
                  <div className="disableModalSubmitButton">
                    <button type="submit"   disabled={!flag} className="disableModalButton">
                
                      {flag ? "   Submit":
                    
                    <CircularProgress/>
                    }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default DisablePharmacyModal;
