import React, { useEffect, useRef, useState } from "react";
import "./CreateCategory.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import { addBrandRequest } from "../../../redux/Actions/Product/AddBrandAction";

function AddBrand() {
  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
  });
  

 
    const [editorContent, setEditorContent] = useState("");
  
  


  
  const res = useSelector((state) => state.addBrand);
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [sendImage, setSendImage] = useState(null);

  const fileInputRef = useRef(null);

  const handleClearImage = () => {
    setSelectedImage(null);
  };
  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      setSendImage(file);

      reader.readAsDataURL(file);
    }
  };

  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(false);


  useEffect(()=>{
    if(res?.data || res?.error)
    {

      setFlag(true);
    }

  },[res])

  useEffect(()=>
  {
    if(selectedImage){
      setImageFlag(false);
    }


  },[selectedImage,editorContent])
  const handleSubmit = (values, { setSubmitting }) => {

    const categoryData = {
      name: values.name,
    
    };



      if (selectedImage) {
        setFlag(false);
  
  
        const newFormData = new FormData();
        newFormData.append("brandRequest", JSON.stringify(categoryData));
        newFormData.append("brandImage", sendImage);
        dispatch(addBrandRequest(newFormData));
      }
      else{
        setImageFlag(true);
  
      }
  

  };


  if (res?.data?.data.status === 201) {
 
    Swal.fire({
      title: "Brand created successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (res?.error) {
   
    Swal.fire({
      title: res?.error.data.response.data.errorMessage,
      icon: "error",
    }).then(() => {
    
    });
  }



  return (
    <>
      <div className="editProductMainBody">
        <div className="editCategoryHeader">Add Brand</div>
        <div className="editCategoryBody">
          <Formik
            initialValues={{ name: "", Parent: "", Status: "" }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form className="editCategoryFormikBody">
                <div className="editCategoryName">Brand Name</div>

                <label style={{ width: "90%" }}>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Brand  Name"
                    className="editCategoryField"
                  />
                  <ErrorMessage
                    className="errorMessage"
                    name="name"
                    component="div"
                  />
                </label>
               



             

                <div className="editCategoryDescription">Image </div>

                {imageFlag&&<div className="errorMessage">Please select a image</div>}
                
                <div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {selectedImage && (
                    <div>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </div>
                

                <div style={{ marginTop: "2em" }}>
                  {!selectedImage && (
                    <button
                      className="editButtonProduct"
                      onClick={handleEditClick}
                    >
                      Add Image
                    </button>
                  )}
                  <button
                    onClick={handleClearImage}
                    className="clearButtonProduct"
                  >
                    Clear{" "}
                  </button>
                </div>

                <div className="buttonCreate">
                  <button 
                      disabled={!flag}

                  className="editSubmitButton1" type="submit">
                  {flag ? (
                        " Create"
                      ) : (
                        <CircularProgress />
                      )}
                    
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </div>
      </div>
      <Footer />

     
    </>
  );
}

export default AddBrand;
