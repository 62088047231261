export const viewDiscount = {
  ViewDiscountHeader: "View Discount",
  ViewDiscountInfo1: "Package Name",
  ViewDiscountInfo2: "Total Amount",
  ViewDiscountInfo3: "Discount %",
  ViewDiscountInfo4: "Start Date",
  ViewDiscountInfo5: "End Date",
  ViewDiscountInfo6: "Description",

  AddDiscountHeader: "Add Discount",
  EditDiscountHeader: "Edit Discount",
  AddDiscountInfo1: "Discount Amount",
  AddDiscountInfo2: "Number of ",
  AddDiscountButton: "Add Discount",
  EditDiscountButton: "Edit Discount",


  ViewDiscountInfo7: "Quaterly Amount",
  ViewDiscountInfo8: "HalfYearly Amount",
  ViewDiscountInfo9: "Yearly Amount",
  ViewDiscountInfo1Plan:"Plan Name",
  ViewDiscountInfo1Test:"Test Name",
};
