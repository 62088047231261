import * as types from "../../Config/actionType"

export const corporateViewAssignProgramRequest = (data) => {
   
    return {
        type: types.CORPORATE_VIEW_ASSIGN_PROGRAM_REQUEST,
        data:data
    }
}

export const corporateViewAssignProgramSuccess = (data) => {
    return {
        type: types.CORPORATE_VIEW_ASSIGN_PROGRAM_SUCCESS,
        data:data,
    }
}

export const corporateViewAssignProgramFailure = (data) => {
    return {
        type: types.CORPORATE_VIEW_ASSIGN_PROGRAM_FAILURE,
        data:data,
    }
}