import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MyQuill= ({ sendContentToParent , desc}) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    sendContentToParent(value);
  }, [value, sendContentToParent]);

  useEffect(()=>{
    if(desc){
    setValue(desc);
    sendContentToParent(desc);
    }
  },[desc])

  const handleEditorChange = (content) => {
    setValue(content);
    sendContentToParent(content);
  };
  const customColors = [
    '#E60000', // Red
    '#FF9900', // Orange
    '#FFFF00', // Yellow
    '#33CC33', // Green
    '#3399FF', // Blue
    '#CC66FF', // Purple
    '#FFFFFF', // White
    '#000000', // Black
  ];
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      ['link', 'image', 'video'],
      [{ 'color': customColors }], // Add the color option to the toolbar
      ['clean'],
    ],
  };
  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike',
    'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background' // Add the color format
  ];
  return (
    <div>
      <ReactQuill theme="snow" value={value} onChange={handleEditorChange} modules={modules} formats={formats} style={{ height: '300px' }} />
    </div>
  )
}
export default MyQuill;