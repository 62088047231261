import * as types from "../../../Config/actionType"

export const assignProgramRequest = (data) => {
    //
    return {
        type: types.ASSIGN_PROGRAM_REQUEST,
        data
    }
}

export const assignProgramSuccess = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_SUCCESS,
        action
    }
}

export const assignProgramFailure = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_FAILURE,
        action
    }
}