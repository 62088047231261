import * as types from "../../Config/actionType";

export const addDiscountGroupRequest = (data) => {
  return {
    type: types.ADD_DISCOUNT_GROUP_REQUEST,
    data: data,
  };
};

export const addDiscountGroupSuccess = (data) => {
  return {
    type: types.ADD_DISCOUNT_GROUP_SUCCESS,
    data: data,
  };
};

export const addDiscountGroupFailure = (data) => {
  return {
    type: types.ADD_DISCOUNT_GROUP_FAILURE,
    data: data,
  };
};
