import React, { useEffect, useState } from "react";
import "./AssignProgram.css";
import "./AssignProgramNew.css";
import DiagnosticService from "./DiagnosticService.js";
import DiagnosticServiceTemplate from "./DiagnosticServiceTemplate.js";
import { Stack, Autocomplete, TextField } from "@mui/material";
import Services from "./ServicesNew.js";
import ServicesTemplate from "./servicesTemplateNew";
import Title_Description from "./Title_Description";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { viewAllTemplateDataRequest } from "../../../redux/Actions/Program Template/Program/viewAllTemplateDataAction";
import { viewAllProgramTemplateNamesRequest } from "../../../redux/Actions/Program Template/Program/viewAllProgramTemplateNamesAction";
import { viewAllPackageNamesRequest } from "../../../redux/Actions/Program Template/Package/viewAllPackageNamesAction";
import { viewAllServicesNameAndUuidRequest } from "../../../redux/Actions/Program Template/Services/viewAllServicesNameAndUuidAction";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { assignProgramInsurerRequest } from "../../../redux/Actions/Program Template/Program/AssignProgramInsurerAction";
import HRAService from "./HRAService.js"
import HRAServiceTemplate from "./HRAServiceTemplate"
import PharmacyService from "./PharmacyService";
import PharmacyServiceTemplate from "./PharmacyServiceTemplate";
import "./ServicesNew.css"
import DiscountService from "./DiscountService";

const AssignProgramInsurer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [servicesComponent, setServicesComponent] = useState([]);
  const [updatedServicesAttributeData, setUpdatedServicesAttributeData] =
    useState([]);
  const [servicesAttributeData, setServicesAttributeData] = useState([]);
  let title = "";
  let description = "";
  const [insurerId, setCorporateId] = useState(location.state.id);
  const [programCount, setProgramCount] = useState(0);
  const [insurerName, setCorporateName] = useState([]);
  const templateNames = [];
  const templateServices = [];
  const pharmacyService = [];

  const packageName = [];
  const serviceName = [];
  const testName = [];
  const defaultData = [];
  let templateDescription = "";
  let templateName = "";
  const [check, setCheck] = useState([]);
  const ServiceApiDataArray = [];
  const [startDate, setstartDate] = React.useState("");

  const [endDate, setendDate] = React.useState("");
  const [onClear, setOnClear] = useState("false");
  const [templateServicesFinal, setTemplateServicesFinal] = useState([]);
  const [error, setError] = useState(false);
  const [FormValidation, setFormValidation] = useState({});
  const [programTitle, setProgramTitle] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [children, setChildren] = useState(0);
  const HraService = [];
  const [zeroError, setZeroError] = useState();
  const [empty, setEmpty] = useState();
  const [emptyPharmacy, setEmptyPharmacy] = useState();

  var startDateConverted = new Date(startDate);
  startDateConverted = startDateConverted.getTime();

  var endDateConverted = new Date(endDate);
  endDateConverted = endDateConverted.getTime();

  useEffect(() => {
    dispatch(viewAllServicesNameAndUuidRequest());
    dispatch(viewAllProgramTemplateNamesRequest());
    dispatch(viewAllPackageNamesRequest());
  }, []);

  const [errorPharmacy, setErrorPharmacy] = useState();

  function TopSubLimiterror(bool) {
    setErrorPharmacy(bool);
  }

  function ZeroTopSubLimiterror(bool) {
    setZeroError(bool);
  }
  function EmptyTopSubLimiterror(bool) {
    setEmpty(bool);
  }
  function EmptyPharmacyTopSubLimiterror(bool) {
    setEmptyPharmacy(bool);
  }

  const loginSchema = Yup.object().shape({
    CorporateName: Yup.string().required("Required Field"),
    CorporateID: Yup.string().required("Required Field"),
  });

  const viewAllProgramTemplateNames = useSelector(
    (state) => state.viewAllProgramTemplateNamesReducer
  );

  viewAllProgramTemplateNames?.data?.action?.data?.data?.map((item) =>
    templateNames.push(item.name)
  );

  const viewAllTemplateData = useSelector(
    (state) => state.viewAllTemplateDataReducer
  );
  //For Getting program template name
  templateName = viewAllTemplateData?.data?.data?.data.name;

  //For Getting program template description
  templateDescription = viewAllTemplateData?.data?.data?.data.description;

  //for default data
  viewAllTemplateData?.data?.data?.data?.services?.map((item) =>
    defaultData.push(item)
  );
  //For Getting program template services
  viewAllTemplateData?.data?.data?.data?.services?.map((item) => {
    templateServices.push(item);
    if (item.serviceUuid === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9") {
      item.attributeCountDtoList.map((attributeType) => {
        if (attributeType.attributeType === "PACKAGE") {
          packageName.push(attributeType);
        }
        if (attributeType.attributeType === "INDIVIDUAL_TEST") {
          testName.push(attributeType);
        }
      });
    } else if (item.serviceUuid === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
      item.attributeCountDtoList.map((x) => {
        HraService.push(x);
      });
    }
    else if (item.serviceUuid === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
      item.attributeCountDtoList.map((x) => {
        pharmacyService.push(x);
      });
    } else {
      item.attributeCountDtoList.map((x) => {
        serviceName.push(x);
      });
    }
  });
  const assignProgramResponse = useSelector((state) => state.assignProgramInsurer);



  useEffect(() => {
    if (updatedServicesAttributeData.length === 0) {
      setUpdatedServicesAttributeData(templateServices);
    }
  }, [viewAllTemplateData]);

  const viewServiceNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewServiceNames?.data?.data?.data?.map((item) =>
    ServiceApiDataArray.push(item)
  );

  const handleCloseTemplateData = (componentsServiceUid) => {
    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);

    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);

    let newTemplateServicesFinal = templateServicesFinal.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setTemplateServicesFinal(newTemplateServicesFinal);
  };

  const handleClose = (componentsServiceUid) => {
    setEmptyPharmacy(false);

    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);

    let newServicesComponent = servicesComponent.filter(
      (item) => item.serviceUUID !== componentsServiceUid
    );
    setServicesComponent(newServicesComponent);

    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
  };

  const handleCloseAttribute = (componentsServiceUid) => {
    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
  };

  const getData_Title_Description = (titleInput, descriptionInput) => {
    title = titleInput;
    description = descriptionInput;
  };

  const handleServicesAttributeData = (servicesAttributeDataInput) => {
    if (servicesAttributeData.length === 0) {
      servicesAttributeData.push(servicesAttributeDataInput);
    }
    else {
      let boolT = servicesAttributeData.some((item) => {
        if (item.serviceUuid === servicesAttributeDataInput.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        servicesAttributeData.map((item, index) => {
          if (servicesAttributeDataInput.serviceUuid === item.serviceUuid) {
            servicesAttributeData.splice(index, 1);
            servicesAttributeData.push(servicesAttributeDataInput);
          }
        })

      }
      else {
        servicesAttributeData.push(servicesAttributeDataInput);
      }
    }
    servicesAttributeData.map((item) => {
      if (updatedServicesAttributeData.length === 0) {
        updatedServicesAttributeData.push(item);
      } else {
        let boolT = updatedServicesAttributeData.some((element) => {
          if (element.serviceUuid === item.serviceUuid) {
            return true;
          } else {
            return false;
          }
        });
        if (boolT === true) {
          updatedServicesAttributeData.map((individual, index) => {
            if (individual.serviceName === item.serviceName) {
              updatedServicesAttributeData.splice(index, 1);
              updatedServicesAttributeData.push(item);
              setUpdatedServicesAttributeData([
                ...updatedServicesAttributeData,
              ]);
            }
          });
        } else {
          updatedServicesAttributeData.push(item);
          setUpdatedServicesAttributeData([...updatedServicesAttributeData]);
        }
      }
    });
  };
  const [relations, setRelation] = React.useState([]);
  var oldRelation = [];


  oldRelation = viewAllTemplateData?.data?.data?.data?.relationsAllowed.split(",");

  useEffect(() => {
    if (oldRelation) {
      oldRelation?.map((item) => {
        if (relations.length === 0) {
          relations.push(item);
        }
        else {
          if (relations.includes(item) === false) {
            relations.push(item);

          }
        }
      })
    }
  }, [viewAllTemplateData])


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setRelation(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const dropdown = [
    "FATHER",
    "MOTHER",
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "MOTHER_IN_LAW",
    "FATHER_IN_LAW",
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };




  if (
    assignProgramResponse?.data?.action?.status === 201 &&
    assignProgramResponse?.data?.action?.data.message !== ""
  ) {
    Swal.fire({
      title: assignProgramResponse?.data?.action?.data.message,
      icon: "success",
    }).then(() => {
      navigate(`/agents`);
      window.location.reload();

    });
  }

  if (
    assignProgramResponse?.error?.action?.response?.status === 409 &&
    assignProgramResponse?.error?.action?.response?.data.errorMessage !== ""
  ) {
    Swal.fire({
      title: assignProgramResponse?.error?.action?.response?.data?.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }
  const FormValidationfun = (
    programCount,
    programTitle,
    programDescription,
    startDate,
    endDate
  ) => {
    const errors = {};

    if (!programCount) {
      errors.programCount = "*Program Count is Required!";
    }
    if (!programTitle) {
      errors.programTitle = "*Program Title is Required!";
    }
    if (!programDescription) {
      errors.programDescription = "*Program Description is Required!";
    }
    if (!startDate) {
      errors.startDate = "*Start Date is Required!";
    }
    if (!endDate) {
      errors.endDate = "*End Date is Required!";
    }
    return errors;
  };
  function checkduplicacy(name) {
    const z = templateServicesFinal.some((item) => {
      if (item.serviceName === name) {
        return true;
      }
    });

    if (z === false) {
      setCheck([...check, name]);
    }
  }

  useEffect(() => {
    setChildren(viewAllTemplateData?.data?.data?.data.childrenCount)
  }, [viewAllTemplateData])


  const handleSubmit = () => {
    const additionalServices = servicesComponent
      .filter((item) => item.attributeResponseDtoList.length === 0)
      .map((item) => item.serviceUUID);

    const filteredServicesAttributeData = [];

    updatedServicesAttributeData.forEach((item) => {
      if (item.serviceUuid !== "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter((ele) => {
          return !isNaN(ele.attributeCount);
        });

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      } else {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter((ele) => {
          return !isNaN(ele.subLimit) && !isNaN(ele.topLimit);
        });

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      }
    });


    let submitdata = {
      insurerId,
      insurerName,
      programTitle,
      description,
      filteredServicesAttributeData,
      programCount,
      children,
      relations,
      additionalServices
    };


    if (programCount === "0" || programCount.length === undefined) {
      Swal.fire({
        title: "Please Add programCount",
        icon: "error",
      });
    } else if (programCount.includes(".")) {
      Swal.fire({
        title: "Please remove decimal from programCount",
        icon: "error",
      });
    } else if (!programTitle) {
      Swal.fire({
        title: "Please Add Program Title",
        icon: "error",
      });
    } else if (!description) {
      Swal.fire({
        title: "Please Add Program Description",
        icon: "error",
      });
    } else if (errorPharmacy === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    } else if (zeroError === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    }
    else if ((relations.includes("SON") || relations.includes("DAUGHTER")) && (submitdata.children === undefined || submitdata.children === "")) {
      Swal.fire({
        title: "Please fill Number of Children",
        icon: "error",
      });
    }
    else {
      dispatch(assignProgramInsurerRequest(submitdata));
    }

  };

  return (
    <div className="template1">
      <div className="templateTop1">
        <span>Assign New Program To Insurer</span>
      </div>
      <div className="templateBottom1">
        <div className="templateBottomLeft1">
          <div style={{ display: "flex", marginTop: "3vh" }}>
            <div
              style={{ marginLeft: "5vw", fontWeight: "700", fontSize: "20px" }}
            >
              Insurer Name:
            </div>
            <div
              style={{ marginLeft: "1vw", fontWeight: "700", fontSize: "20px" }}
            >
              {location.state.name}
            </div>
          </div>
          <div className="main-Newcontainer1">


            {
              <div className="insurerNameBox">

                <div className="insurerlower">
                  <input
                    className="programCountInsurer"
                    placeholder="Program count"
                    type="number"
                    min={1}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) => setProgramCount(e.target.value)}
                  />
                  <p className="mandatory-field-pro">
                    {FormValidation.programCount}
                  </p>
                </div>
              </div>
            }
          </div>
          <Title_Description
            getData_Title_Description={getData_Title_Description}
            templateName={templateName}
            templateDescription={templateDescription}
            onClear={onClear}
            programTitle={programTitle}
            programDescription={programDescription}
            setProgramTitle={(val) => setProgramTitle(val)}
            setProgramDescription={(val) => setProgramDescription(val)}
            FormValidation={FormValidation}
          />
          <div className="formControlProgram">
            <FormControl sx={{ mL: 1, width: "80%" }}>
              {relations.length === 0 ?
                <InputLabel id="demo-multiple-checkbox-label " sx={{ backgroundColor: "white" }}>
                  Select Relation
                </InputLabel> : ""}

              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={relations}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {dropdown.map((dropdown) => (
                  <MenuItem key={dropdown} value={dropdown}>
                    <Checkbox checked={relations.indexOf(dropdown) > -1} />
                    <ListItemText primary={dropdown} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          &nbsp;
          {(relations.includes("SON") || relations.includes("DAUGHTER")) && (
            <div>
              <input
                as="Textarea"
                rows="1"
                cols="60"
                name="NumberChildren"
                type="number"
                value={children}
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                placeholder="Total number of children"
                className="textarea1"
                onBlur={(e) => setChildren(e.target.value)}
                disabled={viewAllTemplateData?.data?.data?.data.childrenCount ? true : false}

              />
            </div>
          )}
          {servicesComponent.map((item) => {
            if (
              item.serviceUUID === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" &&
              check.includes(item.name) === true
            ) {
              return (
                <DiagnosticService
                  servicesInfo={item}
                  key={item.serviceUUID}
                  handleClose={handleClose}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                  templateServices={templateServices}
                />
              );
            } else if (
              item.serviceUUID === "1dbcc55e-3dec-4e07-8c2a-e222631afebb" &&
              check.includes(item.name) === true
            ) {
              return (
                <HRAService
                  servicesInfo={item}
                  handleClose={handleClose}
                  key={item.serviceUUID}

                  handleCloseAttribute={handleCloseAttribute}
                  handleServicesAttributeData={handleServicesAttributeData}
                  templateServices={templateServices}
                />
              );
            }
            else if (item.serviceUUID === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb" &&
              check.includes(item.name) === true
            ) {
              return (
                <>
                  <PharmacyService
                    servicesInfo={item}
                    handleClose={handleClose}
                  key={item.serviceUUID}

                    handleServicesAttributeData={handleServicesAttributeData}
                    handleCloseAttribute={handleCloseAttribute}
                    templateServices={templateServices}
                    TopSubLimiterror={TopSubLimiterror}
                    ZeroTopSubLimiterror={ZeroTopSubLimiterror}
                    EmptyTopSubLimiterror={EmptyTopSubLimiterror}
                    EmptyPharmacyTopSubLimiterror={EmptyPharmacyTopSubLimiterror}

                  />
                  {zeroError ? <p style={{ color: "red", marginLeft: "3.81em", marginTop: "-2em" }}>Total Limit/Sub Limit cannot be negative/zero</p> : ""}
                  {/* {empty?<p style={{color:"red",marginLeft:"3.81em",marginTop:"-2em",paddingTop:"1em"}}>Total Limit/Sub Limit cannot be null/Empty</p>:""} */}
                  {errorPharmacy ? <p style={{ color: "red", marginLeft: "3.81em", marginTop: "-2em", paddingTop: "1em" }}>Total Limit has to greater than Sub Limit</p> : ""}
                </>

              );
            }
            // else if (
            //   item.serviceUUID === "3089855d-85a1-4c2f-9538-c8276cd76768" &&
            //   check.includes(item.name) === true
            // ) {
            //   return (
            //     <DiscountService
            //       servicesInfo={item}
            //       handleClose={handleClose}
            //       handleCloseAttribute={handleCloseAttribute}
            //       handleServicesAttributeData={handleServicesAttributeData}
            //       templateServices={templateServices}
            //     />
            //   );
            // }
            else if (
              item.serviceUUID !== "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" &&
              item.serviceUUID !== "1dbcc55e-3dec-4e07-8c2a-e222631afebb" &&
              item.serviceUUID !== "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb" &&
              // item.serviceUUID !== "3089855d-85a1-4c2f-9538-c8276cd76768" &&
              check.includes(item.name) === true
            ) {
              return (
                <Services
                  servicesInfo={item}
                  handleClose={handleClose}
                  key={item.serviceUUID}
                  handleCloseAttribute={handleCloseAttribute}
                  handleServicesAttributeData={handleServicesAttributeData}
                  templateServices={templateServices}
                />
              );
            }
          })}
          {templateServices.map((item) => {
            if (item.serviceUuid === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9") {
              servicesComponent.map((ele, index) => {
                if (ele.name === item.serviceName) {
                  servicesComponent.splice(index, 1);
                  setServicesComponent([...servicesComponent]);
                }
              });
              return (
                <DiagnosticServiceTemplate
                  servicesInfo={item}
                  handleClose={handleClose}
                  key={item.serviceUuid}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                  packageName={packageName}
                  testName={testName}
                  templateServices={item}
                  defaultData={defaultData}
                  handleCloseTemplateData={handleCloseTemplateData}
                />
              );
            }
            else if (item.serviceUuid === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
              servicesComponent.map((ele, index) => {
                if (ele.name === item.serviceName) {
                  servicesComponent.splice(index, 1);
                  setServicesComponent([...servicesComponent]);
                }
              });
              return (
                <HRAServiceTemplate
                  servicesInfo={item}
                  key={item.serviceUuid}

                  handleClose={handleClose}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                  packageName={HraService}
                  testName={testName}
                  templateServices={item}
                  defaultData={defaultData}
                  handleCloseTemplateData={handleCloseTemplateData}
                />
              );
            }
            else if (item.serviceUuid === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
              servicesComponent.map((ele, index) => {
                if (ele.name === item.serviceName) {
                  servicesComponent.splice(index, 1);
                  setServicesComponent([...servicesComponent]);
                }
              });
              return (
                <PharmacyServiceTemplate
                  servicesInfo={item}
                  key={item.serviceUuid}

                  pharmacyService={pharmacyService}
                  handleClose={handleClose}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                  templateServices={item}


                />
              );
            }
            else {
              let dropDown = [];
              let listf = [];

              ServiceApiDataArray.map((x) => {
                if (item.serviceName === x.name) {
                  x.attributeResponseDtoList.map((y) => {
                    dropDown.push(y);
                  });
                }
              });
              templateServices.map((x) => {
                if (item.serviceName === x.serviceName) {
                  x.attributeCountDtoList.map((y) => {
                    listf.push(y);
                  });
                }
              });

              servicesComponent.map((ele, index) => {
                if (ele.name === item.serviceName) {
                  servicesComponent.splice(index, 1);
                  setServicesComponent([...servicesComponent]);
                }
              });
              return (
                <ServicesTemplate
                  servicesInfo={item}
                  key={item.serviceUuid}

                  handleClose={handleClose}
                  handleServicesAttributeData={handleServicesAttributeData}
                  templateServices={item}
                  dropDown={dropDown}
                  list={listf}
                  serviceName={serviceName}
                  ServiceApiDataArray={ServiceApiDataArray}
                  handleCloseTemplateData={handleCloseTemplateData}
                />
              );
            }
          })}
        </div>
        <div className="templateBottomRight1">
          <div className="loadTemplate1">
            <Stack spacing={2}>
              <Autocomplete
                options={templateNames}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Load from Template" />
                )}
                onChange={(event, value) => {
                  dispatch(viewAllTemplateDataRequest(value));
                  setUpdatedServicesAttributeData([]);
                  setTemplateServicesFinal([]);

                  if (value === null) {
                    {

                    }
                    setOnClear(value);
                  } else {
                    setOnClear("false");
                  }

                  viewAllProgramTemplateNames?.data?.action?.data?.data?.map(
                    (item) => {
                      if (item.name === value) {
                        item.services.map((x) => templateServices.push(x));
                        setTemplateServicesFinal(templateServicesFinal);
                      } else {
                      }
                    }
                  );
                }}
              />
            </Stack>
          </div>
          <div className="addServicesHeading1">
            <span>Add Services</span>
          </div>
          <div className="allServices1">
            {ServiceApiDataArray.map((item) => {
              return (
                <div className="servicesList1">
                  <button
                    className="serviceListButton"
                    onClick={() => {
                      checkduplicacy(item.name);
                      if (
                        servicesComponent.length === 0 ||
                        servicesComponent.length >= 0
                      ) {
                        let boo = servicesComponent.some((ele) => {
                          if (ele.serviceUUID === item.serviceUuid) {
                            return true;
                          } else {
                            return false;
                          }
                        });
                        if (!boo) {
                          setServicesComponent([
                            ...servicesComponent,
                            {
                              name: item.name,
                              serviceUUID: item.serviceUuid,
                              attributeResponseDtoList:
                                item.attributeResponseDtoList,
                            },
                          ]);
                        }
                      }
                    }}
                  >
                    +
                  </button>
                  <span>{item.name}</span>
                </div>
              );
            })}
          </div>
          <div onClick={handleSubmit} className="addTemplateSubmit1">
            <span>Assign Program</span>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};
export default AssignProgramInsurer;