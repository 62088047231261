import * as types from "../../Config/actionType"

export const listOfConsultationsRequest = (page,pageSize,search) => {
    return {
        type: types.LIST_OF_CONSULTATIONS_REQUEST,
        page,
        pageSize,
        search
    }
}

export const listOfConsultationsSuccess = (action) => {
    return {
        type: types.LIST_OF_CONSULTATIONS_SUCCESS,
        action
    }
}

export const listOfConsultationsFailure = (action) => {
    return {
        type: types.LIST_OF_CONSULTATIONS_FAILURE,
        action
    }
}