
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Dashboard/HospitalDashboard/HospitalViewDoctorDetails"
import NetworkService from "../../../../network/NetworkService";

export function* HospitalViewDoctorSaga(action) {
  try {
    let response = yield call(NetworkService.HospitalViewDoctorDetails, action);
    yield put(actions.HospitalViewDoctorSuccess(response));
  } catch (err) {
    yield put(actions.HospitaLViewDoctorFailure(err));
  }
}

export function* watchHospitalViewDoctorSaga() {
  yield takeLatest(types.HOSPITAL_VIEW_DOCTOR_REQUEST, HospitalViewDoctorSaga);
}

