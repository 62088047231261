import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getUnitsAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getUnitsSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getUnits, action);
   
    yield put(actions.getUnitsSuccess(response));
  } catch (err) {
   
    yield put(actions.getUnitsFailure(err));
  }
}

export function* watchGetUnitsSaga() {
 
  yield takeLatest(types.GET_UNITS_REQUEST, getUnitsSaga);
}
