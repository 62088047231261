import * as types from "../../Config/Product/ProductActionType";

export const getSubCategoryDetailsRequest = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DETAILS_REQUEST,
    data
  };
};

export const getSubCategoryDetailsSuccess = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DETAILS_SUCCESS,
    data: data,
  };
};

export const getSubCategoryDetailsFailure = (data) => {
  return {
    type: types.GET_SUB_CATEGORY_DETAILS_FAILURE,
    data: data,
  };
};
