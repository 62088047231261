import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteProgramTemplateRequest } from "../../../redux/Actions/Program Template/Program/deleteProgramTemplateAction";
import { viewAllProgramTemplateNamesRequest } from "../../../redux/Actions/Program Template/Program/viewAllProgramTemplateNamesAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./Program.css";

function Programs() {
  const navigate = useNavigate();

  const handleClick = (name) => {
    navigate(`/viewProgram/${name}`);
  };


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAllProgramTemplateNamesRequest());
  }, []);

  const program = useSelector(
    (state) => state.viewAllProgramTemplateNamesReducer
  );

  const handleDelete = (name) => {
    dispatch(deleteProgramTemplateRequest(name));
  };
  const deleteProgramTemplate = useSelector(
    (state) => state.deleteProgramTemplate
  );

  // useEffect(()=>{
  if (
    deleteProgramTemplate?.data?.status === 200 &&
    deleteProgramTemplate?.data?.data.message !== ""
  ) {
    Swal.fire({
      title: `${deleteProgramTemplate?.data?.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  // },[deleteProgramTemplate])
  return (
    <div>
      <div className="grid">
        <h4 className="h4">Program Templates</h4>
        <div className="mainBox">
          <div className="head">
            <h5 className="programlist">List of Programs Templates</h5>

            {/* <div className="porgramcount">{program.data?.data.data.data.count} Services</div> */}
            {/* <div className="btnCreate">
              <button className="button">+ Create Program Template</button>
            </div> */}
            {/* <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  className="searchField"
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                    fontFamily: "Nunito",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="searchButton">
                <form
                  onSubmit={(e) => {
                    handleSearch(e);
                  }}
                >
                  <button
                    type="submit"
                    value="Submit"
                    className="hospitalsearchBtn"
                    style={{
                      backgroundColor: "#52608E",
                      width: "150px",
                      align: "end",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Search
                  </button>
                </form>
              </div>
            </div> */}
          </div>
          <hr />

          <div className="card-box">
            <Box>
              <CardContent>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                  direction="row"
                >
                  {program.data?.action.data.data.map((data) => (
                    <div className="cardse1" style={{ cursor: "pointer" }}>
                      <div className="card-head1">
                        <div className="card-head1Child1"> {data.name}</div>
                        <div className="card-head1Child2">
                          <VisibilityIcon
                            sx={{ color: "#52608E", marginRight: 2 }}
                            onClick={() => handleClick(data.name)}
                          />
                          <DeleteIcon
                            onClick={() => handleDelete(data.name)}
                            style={{ cursor: "pointer", color: "#52608E" }}
                          />
                        </div>
                      </div>
                      <div className="carding-bottom1New">
                        <div
                          className="carding-bottom1Child1New"
                          onClick={() => handleClick(data.name)}
                        >
                          <p>{data.description}</p>
                        </div>
                      </div>
                      <div className="card-body"></div>
                    </div>
                  ))}
                </Grid>
              </CardContent>
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Programs;
