import * as types from "../../Config/actionType"

export const getEmployeeProgramRequest = (id,page_no,page_size) => {
   
    return {
        type: types.GET_EMPLOYEE_PROGRAM_REQUEST,
        id,
        page_no,
        page_size
            
    }
}

export const getEmployeeProgramSuccess = (action) => {
   
    return {
        type: types.GET_EMPLOYEE_PROGRAM_SUCCESS,
        action
    }
}

export const getEmployeeProgramFailure = (action) => {
   
    return {
        type: types.GET_EMPLOYEE_PROGRAM_FAILURE,
        action
    }
}