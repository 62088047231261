import * as types from "../../Config/actionType"

export const AddCouponRequest = (data) => {
    return {
        type: types.ADD_COUPON_REQUEST,
        data:data,
    }
}

export const AddCouponSuccess = (data) => {
    return {
        type: types.ADD_COUPON_SUCCESS,
        data:data,
    }
}

export const AddCouponFailure = (data) => {
    return {
        type: types.ADD_COUPON_FAILURE,
        data:data,
    }
}