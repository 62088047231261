import * as types from "../../Config/actionType"

export const updateDoctorRequest = (data) => {
    return {
        type: types.UPDATE_DOCTOR_REQUEST,
        data:data
    }
}

export const updateDoctorSuccess = (data) => {
    return {
        type: types.UPDATE_DOCTOR_SUCCESS,
        data:data,
    }
}

export const updateDoctorFailure = (data) => {
    return {
        type: types.UPDATE_DOCTOR_FAILURE,
        data:data,
    }
}