import * as types from "../../Config/actionType"

export const corporateDropdownRequest = (data) => {
    return {
        type: types.CORPORATE_DROPDOWN_REQUEST,
        data:data
    }
}

export const corporateDropdownSuccess = (data) => {
    return {
        type: types.CORPORATE_DROPDOWN_SUCCESS,
        data:data,
    }
}

export const corporateDropdownFailure = (data) => {
    return {
        type: types.CORPORATE_DROPDOWN_FAILURE,
        data:data,
    }
}