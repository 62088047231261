import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/AssignProgramInsurerAction";
import NetworkService from "../../../../network/NetworkService";

export function* assignProgramInsurerSaga(action) {
  try {
    let response = yield call(NetworkService.assignProgramInsurer, action);
    yield put(actions.assignProgramInsurerSuccess(response));
  } catch (err) {
    yield put(actions.assignProgramInsurerFailure(err));
  }
}

export function* watchAssignProgramInsurerSaga() {
  yield takeLatest(types.ASSIGN_PROGRAM_INSURER_REQUEST, assignProgramInsurerSaga);
}