import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Users/UsersAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllUsers(action) {
  try {
    let response = yield call(NetworkService.viewAllUsers, action);
    yield put(actions.viewAllUsersSuccess(response));
  } catch (err) {
    yield put(actions.viewAllUsersFailure(err));
  }
}

export function* watchViewAllUsersSaga() {
  yield takeLatest(types.VIEW_ALL_USERS_REQUEST, viewAllUsers);
}
