import { combineReducers } from "redux";
import { addPharmacyReducer } from "./addPharmacyReducer";
import { deletePharmacyReducer } from "./deletePharmacyReducer";
import { viewAllPharmacyReducer } from "./viewAllPharmacyReducer";
import {getPharmacyDetailReducer} from "./viewPharmacyDetailReducer"

export default combineReducers({
  viewAllPharmacy: viewAllPharmacyReducer,
  addPharmacy: addPharmacyReducer,
  deletePharmacy: deletePharmacyReducer,
  getPharmacyDetail:getPharmacyDetailReducer,
});
