import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/system";

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewAllDoctorsRequest } from "../../../redux/Actions/Doctors/viewAllDoctorsActions";
import { viewHospitalDetailRequest } from "../../../redux/Actions/Hospitals/viewHospitalDetailAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import DisableDoctorModal from "../../Organisms/Modal/DisableDoctorModal";
import ModalAddDoctorHospital from "../../Organisms/Modal/ModalAddDoctorHospital";
import ModalUpdateDoctor from "../../Organisms/Modal/ModalUpdateDoctor";
import "./ViewHospital.css";
import IconButton from "@mui/material/IconButton";
import ModalAddDoctor from "../../Organisms/Modal/ModalAddDoctor";

function ViewHospital() {
  const [add, setAdd] = React.useState(false);
  const [values, setValues] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [doctorEdit, setDoctorEdit] = React.useState(false);
  const [delId, setDelId] = useState();
  const [delOpen, setdelOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleAdd = () => {
    setAdd(true);
  };

  const handleDoctorEdit = (value) => {
    setValues(value);
    setDoctorEdit(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    dispatch(viewHospitalDetailRequest(id));
  }, []);

  const details = useSelector((state) => state.viewHospitalDetail);
  const grid = useSelector((state) => state.doctor.viewAllDoctors);
  const addr = details.data?.data?.data.address;

  useEffect(() => {
    dispatch(viewAllDoctorsRequest(id, page, pageSize, ""));
  }, [id, page, pageSize]);

  var cit = addr?.substring(addr.indexOf(", "));

  const handleDelete = (id) => {
    setDelId(id);
    //
    setdelOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      sortable: false,
      flex: 1,
      fontFamily: "Nunito",
      disableColumnMenu: true,
    },
    {
      field: "speciality",
      headerClassName: "super-app-theme--header",
      headerName: "Speciality",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      fontFamily: "Nunito",
    },
    {
      field: "experience",
      headerClassName: "super-app-theme--header",
      headerName: "Years Of Experience",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      fontFamily: "Nunito",
    },
    {
      field: "hospitalName",
      headerClassName: "super-app-theme--header",
      headerName: "Hospital",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "cost",
      headerClassName: "super-app-theme--header",
      headerName: "Consultation Fees",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      fontFamily: "Nunito",
    },
    {
      field: "Modify",
      sortable: false,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      fontFamily: "Nunito",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {grid.data?.data.data.doctorResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <IconButton sx={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleDoctorEdit(cellValues.row)}
                />
              </IconButton>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleDoctorEdit(cellValues.row)}
                />
              </IconButton>
            )}

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {grid.data?.data?.data?.doctorResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <div>
                <Switch disabled sx={{ color: "#52608E" }} />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };
  function isUrl(url) {
    const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[^]*)?$/;
    return pattern.test(url);
  }

  return (
    <div>
      {doctorEdit && values && (
        <ModalUpdateDoctor value={values} open={setDoctorEdit} />
      )}
      <div className="hospital-main">
        <h3 className="headerViewName">{details.data?.data.data.name}</h3>
        <div className="hospital-box">
          <div className="counts">
            <div className="divCount">
              {details.data?.data.data.active === false ? (
                <div className="disableChip">Disabled</div>
              ) : (
                <div className="docCount">
                  {details.data?.data.data.noOfDoctors} Doctors
                </div>
              )}
            </div>

          </div>
          <hr />

          <div className="body">
            <div className="hospitalDetails">
              <div className="keys">
                Contact Number
                <br />
                <br />
                Official Email
                <br />
                <br />
                City
                <br />
                <br />
                Locality
                <br />
                <br />
                Point of Contact
                <br />
                <br />
                Select Type
                <br />
                <br />
                Map URL
              </div>
              <div className="valuess">
                {details.data?.data.data.contactPersonNumber}
                <br />
                <br />
                {details.data?.data.data.contactEmail}
                <br />
                <br />
                {details.data?.data.data.city}
                <br />
                <br />
                {details.data?.data.data.address}
                <br />
                <br />
                {details.data?.data.data.contactName}
                <br />
                <br />
                {details.data?.data.data.typeData}
                <br />
                <br />

                {details.data?.data.data.mapUrl &&
                  isUrl(details.data?.data.data.mapUrl) ? (
                  <a
                    className="tagcolor"
                    target="_blank"
                    href={details.data?.data.data.mapUrl}
                  >
                    View On Map
                  </a>
                ) : (
                  "----"
                )}
              </div>
            </div>

            {details?.data?.data?.data?.active === true ? (
              <div className="grid">
                <div className="grid-head">
                  {delOpen && (
                    <DisableDoctorModal delOpen={setdelOpen} delId={delId} />
                  )}

                  <div className="hospitalDoctor">List of Doctors</div>
                  <div className="rightheadbtn">
                    <button className="btn addModalbtn" onClick={() => handleAdd()}>
                      + Add Doctors
                    </button>
                  </div>
                  {add && (
                    <ModalAddDoctor
                      open={setAdd}
                      id={details.data?.data.data.id}
                      name={details.data?.data.data.name}
                    />
                  )}
                </div>
                <div>
                  <Box sx={{ height: 400, width: "100%", marginTop: "3%" }}>
                    {grid.data?.data.data.doctorResponseDtos && (
                      <DataGrid
                        sx={{
                          color: "#1D2334",
                          "& .super-app-theme--header": {
                            backgroundColor: "#52608E",
                            color: "#F2EFEA",
                            height: "100%",
                            "& > .MuiDataGrid-columnSeparator": {
                              visibility: "hidden",
                            },
                          },
                          ".MuiTablePagination-toolbar": {
                            alignItems: "baseline",
                            marginTop: "5%",
                          },
                          ".MuiTablePagination-toolbar": {
                            alignItems: "baseline",
                            marginTop: "5%",
                          },
                        }}
                        rows={grid.data?.data.data.doctorResponseDtos}
                        columns={columns}
                        pageSize={pageSize}
                        disableSelectionOnClick
                      />
                    )}
                  </Box>

                  <div className="hospital-pagination">
                    <p>Rows Per Page:</p>
                    <select
                      className="hospital-dropDown"
                      defaultValue={10}
                      onChange={handleDropChange}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <Pagination
                      count={grid.data?.data.data.totalPages}
                      color="primary"
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="grid-head">
                  <div className="hospitalDoctor">Disable Reason</div>
                </div>
                <div className="disableReason">
                  {details.data?.data.data.disableReason}
                </div>
                <div className="disableReasonFile">
                  {details.data?.data.data.fileName}
                  <VisibilityIcon
                    onClick={() => handleView(details.data?.data.data.filePath)}
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      color: "#52608E",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewHospital;