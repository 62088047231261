export const modalHeader = {
  fontSize: "35px",
  fontFamily: "Nunito",
  marginBottom: "30px",
  marginTop: "15px",
};

export const modalHeaderAddPackage = {
  fontFamily: "Nunito",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "2em",
  lineHeight: "150%",
  color: " #1D2334",
};

export const modalHeaderSub = {
    fontSize: "20px",
    fontFamily: "Nunito",
    marginBottom: "42px",
  };

export const AddEmployeeStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export const UpdateNumberStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };