import * as types from "../../Config/actionType"

export const assignProgramToEmployeeRequest = (data) => {
    return {
        type: types.ASSIGN_PROGRAM_TO_EMPLOYEE_REQUEST,
        data:data
    }
}

export const assignProgramToEmployeeSuccess = (data) => {
    return {
        type: types.ASSIGN_PROGRAM_TO_EMPLOYEE_SUCCESS,
        data:data,
    }
}

export const assignProgramToEmployeeFailure = (data) => {
    return {
        type: types.ASSIGN_PROGRAM_TO_EMPLOYEE_FAILURE,
        data:data,
    }
}