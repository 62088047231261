import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { addDiscountIndividualRequest } from "../../../redux/Actions/Discount/AddDiscountIndividualAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import { viewDiscount } from "./DiscountConstants";
import "./ViewDiscount.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

function AddDiscount() {
  const dispatch = useDispatch();

  const [totalAmount, SetTotalAmount] = useState(true);
  const [totalAmountValue, SetTotalAmountValue] = useState(0);
  const [DiscountPercentageValue, SetDiscountPercentageValue] = useState();
  const [DiscountAmountValue, SetDiscountAmountValue] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedUUID } = location.state;
  const { enumType } = location.state;
  const { details } = location.state;
  const { allSelected } = location.state;
  const { maxDiscount } = location.state;
  const { minDiscount } = location.state;
  const { search } = location.state;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const addSchema = Yup.object().shape({
    DiscountPercentage: Yup.number()
      .min(0, "Discount Percentage must be greater than or equal to 0")
      .max(100, "Discount Percentage must be less than or equal to 100")
      .required("Required Field"),
    Description: Yup.string().required("Required Field"),
  });

  

  const HandleDiscountPercentage = (e) => {
    SetDiscountPercentageValue(e.target.value);
    SetDiscountAmountValue(e.target.value * 0.01 * totalAmountValue);
  };

  const HandleDiscountAmount = (e) => {
    SetDiscountAmountValue(e.target.value);
    SetDiscountPercentageValue((e.target.value / totalAmountValue) * 100);
  };
  const updateRes = useSelector((state) => state.addDiscountIndividual)

  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])
  const handleSubmit = (values) => {
    setFlag(false);
    if (startDate === undefined) {
      Swal.fire({
        title: "Please Add Start Date",
        icon: "error",
      });
    } else if (endDate === undefined) {
      Swal.fire({
        title: "Please Add End Date",
        icon: "error",
      });
    } else {
      var sdate = new Date(startDate);
      var edate = new Date(endDate);
      sdate = sdate.getTime();
      edate = edate.getTime();
      let submitData = {
        values,
        selectedUUID,
        enumType,
        sdate,
        edate,
        allSelected,
        maxDiscount,
        search,
        minDiscount,
      };
      dispatch(addDiscountIndividualRequest(submitData));
    }
  };

  const addDiscountIndividual = useSelector(
    (state) => state.addDiscountIndividual
  );
  useEffect(() => {
    if (
      addDiscountIndividual?.data?.data?.status === 201 &&
      addDiscountIndividual?.data?.data?.data?.message !== ""
    ) {
      Swal.fire({
        title: `${addDiscountIndividual?.data?.data?.data?.message}`,
        icon: "success",
      }).then(() => {
        navigate(`/Discount`);
        window.location.reload();
      });
    }
  }, [addDiscountIndividual]);

  useEffect(() => {
    if (
      addDiscountIndividual?.error?.data?.response?.status === 400 &&
      addDiscountIndividual?.error?.data?.response?.data.errorMessage !== ""
    ) {
      Swal.fire({
        title: `${addDiscountIndividual?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      });
    }
  }, [addDiscountIndividual?.error?.data?.response?.status]);

  return (
    <>
      <div className="ViewDiscountWrapper">
        <h1 className="ViewDiscountHeader">{viewDiscount.AddDiscountHeader}</h1>
        <Formik
          initialValues={{
            TotalAmount: "",
            DiscountPercentage: "",
            DiscountAmount: "",
            Description: "",
          }}
          validationSchema={addSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="ViewDiscountInfoSection">
                {selectedUUID.length === 1 ? (
                  <div className="ViewDiscountInfoIndividual">
                    <h1 className="ViewDiscountInfo">
                      {viewDiscount.ViewDiscountInfo1}
                    </h1>
                    <h1 className="ViewDiscountData">{details[0].name}</h1>
                  </div>
                ) : (
                  <div className="ViewDiscountInfoIndividual">
                    <h1 className="ViewDiscountInfo">
                      {viewDiscount.AddDiscountInfo2} {enumType}
                    </h1>
                    {allSelected === true ? (
                      <h1 className="ViewDiscountData">All</h1>
                    ) : (
                      <h1 className="ViewDiscountData">
                        {selectedUUID.length}
                      </h1>
                    )}
                  </div>
                )}

                {selectedUUID.length === 1 && enumType !== "PLAN" && enumType !== "PRODUCT" && (
                  <div className="ViewDiscountInfoIndividual">
                    <h1 className="ViewDiscountInfo">
                      {viewDiscount.ViewDiscountInfo2}
                    </h1>
                    <h1 className="ViewDiscountData">
                      ₹ {details[0].totalAmount}
                    </h1>
                  </div>
                )}

                {selectedUUID.length === 1 && enumType === "PLAN" && (
                  <>
                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo7}
                      </h1>
                      <h1 className="ViewDiscountData">
                        ₹ {details[0].quarterly}
                      </h1>
                    </div>

                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo8}
                      </h1>
                      <h1 className="ViewDiscountData">
                        ₹ {details[0].halfYearly}
                      </h1>
                    </div>

                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo9}
                      </h1>
                      <h1 className="ViewDiscountData">
                        ₹ {details[0].yearly}
                      </h1>
                    </div>
                  </>
                )}

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo3}
                  </h1>
                  <label>
                    <Field
                      type="number"
                      name="DiscountPercentage"
                      placeholder="Discount Percentage"
                      value={DiscountPercentageValue}
                      className="AddDiscountInputField"
                      onChange={(e) => {
                        HandleDiscountPercentage(e);
                        setFieldValue("DiscountPercentage", e.target.value);
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="DiscountPercentage"
                      component="div"
                    />
                  </label>
                </div>

               

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo4}
                  </h1>

                  <label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start Date"
                        minDateTime={new Date()}
                        disablePast
                        value={startDate}
                        inputFormat="dd/MM/yyyy hh:mm a"
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        inputProps={{ readOnly: true }} 
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="StartDate"
                      component="div"
                    />
                  </label>
                </div>

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo5}
                  </h1>
                  <label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="End Date"
                        minDateTime={startDate}
                        inputFormat="dd/MM/yyyy hh:mm a"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        inputProps={{ readOnly: true }} 

                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="EndDate"
                      component="div"
                    />
                  </label>
                </div>

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo6}
                  </h1>
                  <label>
                    <Field
                      as="textarea"
                      placeholder="Description"
                      className="AddDiscountInputFieldDescription"
                      name="Description"
                      rows="4"
                      wrap="soft"
                    />
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="Description"
                      component="div"
                    />
                  </label>
                </div>
                <div className="SubmitButtonWrapper">
                  {" "}
                  <button type="submit"  disabled={!flag} className="AddDiscountSubmitButton">
                 

                    {flag ? viewDiscount.AddDiscountButton:
                    
                    <CircularProgress/>
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </>
  );
}

export default AddDiscount;
