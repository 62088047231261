
import { Avatar } from "@mui/material";
import React, {  useState } from "react";

import "./CustomerServiceAgentNavbar.css";
import logo from '../../../assets/logo_primary_light_variant.png'
import storage from "../../../Utils/storage";
import SwitchUserModal from "../../Organisms/Modal/SwitchUserModal"

function Navbar() {
  const getBackground = (curr) => {
    if (window.location.pathname === curr) {
      return "#E68D36";
    }
  };
  const [flag, setFlag] = useState(false);
  const handleSwitchUser = () => {
    setFlag(!flag);
  };
  
  const name = storage.getStorage("name");

  const handleLogout = () => {
    storage.clearStorage();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
     {flag && <SwitchUserModal  closeModal={handleSwitchUser}/>}
      
      <a className="navbar-brand" href="/hospital-Dashboard">
      <img src={logo} alt="error" className="navbarLogo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav w-100">
          <li className="nav-item">
            <a
              className="nav-link"
              href="/hospital-Dashboard"
              // href="/dashboard"

              style={{
                color: getBackground("/dashboard"),
              }}
            >
              DASHBOARD
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/HospitalDoctorList"
              style={{
                color: getBackground("/HospitalDoctorList"),
              }}
            >
              DOCTOR
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/hospitalAppointment"
              style={{
                color: getBackground("/hospitalAppointment"),
              }}
            >
              APPOINTMENTS
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="/AppointmentStatus"
              style={{
                color: getBackground("/AppointmentStatus"),
              }}
            >
              APPOINTMENTS STATUS
            </a>
          </li>


          <div className="profile">
            <li class="nav-item dropdown">
              <Avatar
                sx={{ height: "32px", width: "32px" }}
                className="pic"
                alt={name}
                src="/static/images/avatar/3.jpg"
              />
              <a
                className="nav-link dropdown-toggle "
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div
                  className="dropdown-item1"
                  onClick={handleSwitchUser}
                >
                  Switch user
                </div>
                <a
                  className="dropdown-item"
                  href="/"
                  onClick={handleLogout}
                >
                  Logout
                </a>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
