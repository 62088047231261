import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/getUpdateInsurerAction";
import NetworkService from "../../../network/NetworkService";

export function* getUpdateInsurerSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getUpdateInsurer, action);
   
    yield put(actions.getUpdateInsurerSuccess(response));
  } catch (err) {
   
    yield put(actions.getUpdateInsurerFailure(err));
  }
}

export function* watchGetUpdateInsurerSaga() {
 
  yield takeLatest(types.GET_UPDATE_INSURER_REQUEST, getUpdateInsurerSaga);
}
