import { Box, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { viewAllProgramOfInsurerRequest } from "../../../redux/Actions/Agents/viewAllProgramOfInsurer";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./Program.css";

function MyProgram() {
  const navigate = useNavigate();

  const handleClick = (programuuid) => {
    navigate(`/viewProgramAssignedInsurer/${programuuid}`);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(viewAllProgramTemplateNamesRequest());
    dispatch(viewAllProgramOfInsurerRequest(1));
  }, []);

  // const program = useSelector(
  //   (state) => state.viewAllProgramTemplateNamesReducer
  // );

  const service = useSelector((state) => state.viewAllProgramOfInsurer);

  const insurerViewAssignProgramData = service?.data?.action?.data;

  // const handleDelete = (name) => {
  //   dispatch(deleteProgramTemplateRequest(name));
  // };
  const deleteProgramTemplate = useSelector(
    (state) => state.deleteProgramTemplate
  );

  // useEffect(()=>{
  if (
    deleteProgramTemplate?.data?.status === 200 &&
    deleteProgramTemplate?.data?.data.message !== ""
  ) {
    Swal.fire({
      title: `${deleteProgramTemplate?.data?.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  // },[deleteProgramTemplate])
  return (
    <div>
      <div className="grid">
        <h4 className="h4">Program</h4>
        <div className="mainBox">
          <div className="head">
            <h5 className="programlist">List of Programs</h5>
          </div>
          <hr />

          <div className="card-box">
            <Box>
              <CardContent>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                  direction="row"
                >
                  {insurerViewAssignProgramData?.data?.map((data) => (
                    <div
                      className="cardse1"
                      onClick={() => handleClick(data.programUuid)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="card-head1">
                        <div className="card-head1Child1"> {data.name}</div>
                        <div className="card-head1Child2">
                          <VisibilityIcon
                            sx={{ color: "#52608E", marginRight: 2 }}
                            onClick={() => handleClick(data.Uuid)}
                          />
                        </div>
                      </div>
                      <div className="carding-bottom1New">
                        <div className="carding-bottom1Child1New">
                          <p>Balance - {data.remainingCount}</p>
                          <p>
                            Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-{" "}
                            {data.programCount}
                          </p>
                        </div>
                      </div>
                      <div className="card-body"></div>
                    </div>
                  ))}
                </Grid>
              </CardContent>
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default MyProgram;
