import * as types from "../../Config/actionType"

export const GetAllCouponRequest = (data) => {
   
    return {
        type: types.GET_ALL_COUPON_REQUEST,
        data:data
            
    }
}

export const GetAllCouponSuccess = (action) => {
   
    return {
        type: types.GET_ALL_COUPON_SUCCESS,
        action
    }
}

export const GetAllCouponFailure = (action) => {
   
    return {
        type: types.GET_ALL_COUPON_FAILURE,
        action
    }
}