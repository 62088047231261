import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HospitalViewDoctorRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/HospitalViewDoctorDetails";
import Footer from "../../../Organisms/Footer/FooterUpper";
function DoctorViewDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HospitalViewDoctorRequest(id));
  }, []);

  const HospitalViewDoctor = useSelector((state) => state.HospitalViewDoctor);

  return (
    <div>
      <div className="hospitalviewdoctormain">
        <h3 className="appId">
          {HospitalViewDoctor?.data?.data?.data?.data.name}{" "}
        </h3>
        <div className="appointment-box">
          <div className="appointment-box-body">
            <div className="appointment-details1">
              <div className="GridVieWDoctorArrange">
                <div className="appointment-keys">
                  Total Number of Appointment
                  <br />
                  <br />
                  <br />
                  Total Number of Appointment Paid
                  <br />
                  <br />
                  <br />
                  Total Number of Appointment Pending <br /> For Payment
                  <br />
                  <br />
                  <br />
                  Speciality
                  <br />
                  <br />
                  <br />
                </div>
                <div className="appointment-value">
                  {HospitalViewDoctor?.data?.data?.data?.data?.totalAppointment}
                  <br />
                  <br />
                  <br />
                  {HospitalViewDoctor?.data?.data?.data?.data?.paidAppointment}
                  <br />
                  <br />
                  <br />
                  {
                    HospitalViewDoctor?.data?.data?.data?.data
                      ?.pendingAppointment
                  }
                  <br />
                  <br />
                  <br />
                  <br />
                  {HospitalViewDoctor?.data?.data?.data?.data?.speciality.map(
                    (itm) => `${itm}    `
                  )}

                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="GridVieWDoctorArrange">
                <div className="appointment-keys">
                  Total Amount
                  <br />
                  <br />
                  <br />
                  Amount Paid
                  <br />
                  <br />
                  <br />
                  Pending For Payment
                  <br />
                  <br />
                  <br />
                </div>
                <div className="appointment-value">
                  ₹ {HospitalViewDoctor?.data?.data?.data?.data?.totalAmount}
                  <br />
                  <br />
                  <br />₹{" "}
                  {HospitalViewDoctor?.data?.data?.data?.data?.amountPaid}
                  <br />
                  <br />
                  <br />₹{" "}
                  {HospitalViewDoctor?.data?.data?.data?.data?.pendingAmount}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
            {/* <div className="SpecialityContainer">
              <div className="SpecialityHeadingViewdoctor">Speciality</div>
              <div className="SpecialityContainerOneBock">
                {" "}
                {HospitalViewDoctor?.data?.data?.data?.data?.speciality.map(
                  (itm) => `${itm}    `
                )}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DoctorViewDetails;
