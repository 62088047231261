import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/deleteSubCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* deleteSubCategorySaga(action) {
 

  try {
    let response = yield call(NetworkService.deleteSubCategory, action);

    yield put(actions.deleteSubCategorySuccess(response));
  } catch (err) {
    yield put(actions.deleteSubCategoryFailure(err));
  }
}

export function* watchDeleteSubCategorySaga() {
  yield takeLatest(types.DELETE_SUB_CATEGORY_REQUEST, deleteSubCategorySaga);
}
