import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { AddInitiateRefundRequest } from "../../../redux/Actions/Accounts/AddInitiateRefundAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { getAllServiceRequest } from "../..//../redux/Actions/Service/getAllServiceAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";





function ModalInitiateRefund(props) {
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  useEffect(() => {
    dispatch(getAllServiceRequest());
    dispatch(viewAllCityRequest());
  }, []);
  const [files, setFiles] = useState(false);
  const [select, setSelect] = useState(false);


  const dispatch = useDispatch();

  const [exception, setException] = useState(false);
  const [matchApiRes, setMatchApiRes] = useState(null);
  const res = useSelector((state) => state.addInsuranceAgent);
  

  const handleException = (e) => {
    if (e.target.checked === true) {
      setException(true);
    } else {
      setException(false);
    }
  };

  const FormResponse = useSelector((state) => state.addInitiateRefund);

  if (FormResponse?.data?.data?.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Refund Initiated Successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (FormResponse?.data?.data?.response?.status === 400) {
    swal({
      title: FormResponse?.data?.data?.response?.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  

  const handleSubmit = (values) => {
    if (values.refundAmount === "" || values.refundAmount===undefined) {
      swal({
        title: "Please Fill Refund Amount",
        icon: "info"
      })
    }
    else {
    const value = {
      orderId: props.id,
      refundAmount: values.refundAmount,
      orderItemIdList:props.orderSelected,
      exception:exception
    };
    const formData1 = new FormData();
    
    formData1.append("approvalFile", files[0]);
    formData1.append("paymentGatewaySc", select[0]);
    formData1.append("refundRequestDto", JSON.stringify(value));

      dispatch(AddInitiateRefundRequest(formData1));
  }
    };


  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
  }, [res]);


  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }



  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleFileChange2 = (e) => {
    setSelect(e.target.files);
  };


  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Initiate Refund
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              approvalFile: null,
              refundAmount: "",
              exception:false
            }}
            onSubmit={handleSubmit}
          >
            {({ resetForm }) => (
              <Form>
                <div className="inputFieldsContainer">

                  <label>
                    <Field
                      type="number"
                      name="refundAmount"
                      placeholder="Amount to be refunded"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="refundAmount"
                      component="div"
                    />
                  </label>
                  <label className="inputFieldButtonFormLabel">
                    Upload Approval( in pdf)
                  </label>
                  <input
                    name="approvalFile"
                    required
                    className="inputFieldButtonForm"
                    type="file"
                    accept=".pdf"
                    onChange={(e) => handleFileChange(e)}
                  />

                  <label className="inputFieldButtonFormLabel">
                    Payment Screenshot (PNG or JPG)
                  </label>
                  <input
                    name="paymentGatewaySc"
                    id="paymentGatewaySc"
                    className="inputFieldButtonForm"
                    required
                    type="file"
                    accept=".png,.jpg"
                    onChange={(e) => handleFileChange2(e)}
                  />

                  <label className="featuringCheck">
                    <Field
                      type="checkbox"
                      name="exception"
                      className="featuringCheckField"
                      onChange={handleException}
                      checked={exception}
                    />
                    <span>Exception</span>
                  </label>

                 
                  <button type="submit" className="modalButtonRefund">
                   {FormResponse.isLoading===false ? "Initiate Refund" :<CircularProgress style={{minHeight:".6em"}}/>}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalInitiateRefund;
