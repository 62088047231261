import * as types from "../../Config/actionType"

export const AcceptOrRejectRequest = (data) => {
    return {
        type: types.ACCEPT_OR_REJECT_REQUEST_REQUEST,
        data:data
    }
}

export const AcceptOrRejectSuccess = (data) => {
    return {
        type: types.ACCEPT_OR_REJECT_REQUEST_SUCCESS,
        data:data,
    }
}

export const AcceptOrRejectFailure = (data) => {
    return {
        type: types.ACCEPT_OR_REJECT_REQUEST_FAILURE,
        data:data,
    }
}