import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Banner/BannerActionType";
import * as actions from "../../Actions/Banner/deleteBannerAction";
import NetworkService from "../../../network/Banner/BannerNetworkServices";

export function* deleteBanner(action) {
  try {
    let response = yield call(NetworkService.deleteBanner, action);
    yield put(actions.deleteBannerSuccess(response));
  } catch (err) {
    yield put(actions.deleteBannerFailure(err));
  }
}

export function* watchDeleteBannerSaga() {
  yield takeLatest(types.DELETE_BANNER_REQUEST, deleteBanner);
}
