import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/getPlanDetailAction";
import NetworkService from "../../../network/NetworkService";

export function* getPlanDetailSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanDetail, action);
    yield put(actions.getPlanDetailSuccess(response));
  } catch (err) {
    yield put(actions.getPlanDetailFailure(err));
  }
}

export function* watchGetPlanDetailSaga() {
  yield takeLatest(types.GET_PLAN_DETAIL_REQUEST, getPlanDetailSaga);
}
