import React, { useEffect, useState } from "react";
import { Box, Pagination, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";
import "./ProductListing.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductRequest } from "../../../redux/Actions/Product/getAllProductAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalProductFilter from "../../Organisms/Modal/ModalProductFilter";
import Swal from "sweetalert2";
import { disableProductRequest } from "../../../redux/Actions/Product/disableProductAction";
import { mapToElasticRequest } from "../../../redux/Actions/Doctors/mapToElastic";
import Loader from "../../../assets/Loader/loader.gif";

function ProductListing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAllProduct = useSelector((state) => state.getAllProduct);

  const [productName, setProductName] = useState();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [brandId, setSelectBrandId] = useState(
    getAllProduct.data?.filter.data.brandId
  );
  const [subCategoryId, setSelectSubCategoryId] = useState(
    getAllProduct.data?.filter.data.subCategoryId
  );
  const [selectCategoryId, setSelectCategoryId] = useState(
    getAllProduct.data?.filter.data.selectCategoryId
  );

  useEffect(() => {
    let data = {
      name: productName,
      pageSize: pageSize,
      page: page,
      brandId: brandId,
      subCategoryId: subCategoryId,
      selectCategoryId: selectCategoryId,
    };
    dispatch(getAllProductRequest(data));
  }, [productName, pageSize, page, brandId, subCategoryId, selectCategoryId]);

  const disableProduct = useSelector((state) => state.disableProduct);

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
    {
      field: "brandName",
      headerClassName: "super-app-theme--header",
      headerName: "Brand Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "categoryName",
      headerClassName: "super-app-theme--header",
      headerName: "Category Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },

    {
      field: "subCategoryName",
      headerClassName: "super-app-theme--header",
      headerName: "SubCategory Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },

    {
      headerClassName: "super-app-theme--header",
      headerName: "Modify",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleViewDetails(cellValues.row.id)}
            />
            <Box>
              {getAllProduct?.data?.data.data.data.responseDto[
                cellValues.api.getRowIndex(cellValues.row.id)
              ]?.status === false ? (
                <div>
                  <Switch
                    sx={{ color: "#52608E", marginRight: 2 }}
                    disabled={true}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <Switch
                    defaultChecked
                    sx={{ color: "#52608E", marginRight: 2 }}
                    onClick={() => handleDisable(cellValues.row.id)}
                  />{" "}
                </div>
              )}
            </Box>
            {cellValues.row.status !== false ? (
              <EditIcon
                className="edit"
                sx={{ color: "#52608E" }}
                onClick={() => handleEditproduct(cellValues.row.id)}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  function handleDisable(id) {
    Swal.fire({
      title: "Do You Want to Disable The Product ?",
      showDenyButton: true,
      confirmButtonText: "Confirm ",
      denyButtonText: `Close`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(disableProductRequest(id));
      } else if (result.isDenied) {
        Swal.fire("Changes not saved", "", "info").then(() => {
          window.location.reload();
        });
      }
    });
  }

  function handleViewDetails(id) {
    navigate(`/viewProduct/${id}`);
  }

  function handleEditproduct(id) {
    navigate(`/editProduct/${id}`);
  }

  function handleSearch(e) {
    setProductName(e.target.value);
  }

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleFilter() {
    setOpenModal(!openModal);
  }

  function handleClose(bool) {
    setOpenModal(bool);
  }

  function filterProducts(data) {
    setSelectBrandId(data.brand);
    setSelectCategoryId(data.category);
    setSelectSubCategoryId(data.subCategory);
  }

  if (
    disableProduct.data?.data.status === 200 &&
    disableProduct.data?.data.data.message !== ""
  ) {
    swal({
      title: "Product Disabled Successfully",
      icon: "success",
    }).then(() => {
      disableProduct.data.data.status = null;
      window.location.reload();
    });
  }

  function handleSync() {
    dispatch(mapToElasticRequest());
  }

  const mapToElastic = useSelector((state) => state.mapToElastic);
  return (
    <div>
      <div className="grid">
        <ModalProductFilter
          open={openModal}
          handleClose={handleClose}
          filter={filterProducts}
        />
        <p className="ProductListingHeading">Product</p>

        <div className="mainBox">
          <div
            className="leftHead"
            style={{
              width: "100%",
              margin: "0 auto",
              background: "#fff",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "1em",
              paddingLeft: "1em",
              paddingBottom: "1em",
              borderBottom: "1px solid #EDE8E8",
            }}
          >
            <h5
              className="list"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              List of Products
            </h5>
            <div
              className="hospitalcount"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                marginLeft: "1em",
              }}
            >
              {getAllProduct?.data?.data.data.data.responseDto.length} Products
            </div>
          </div>
          <div className="ProductListingSearch">
            <input
              type="text"
              placeholder="Search"
              className="ProductListingSearchInput"
              onChange={handleSearch}
            />
            <button
              className="ProductListingSearchButton"
              onClick={handleFilter}
            >
              Filter
            </button>
            <div className="AddProductButton">
              {mapToElastic.isLoading ? (
                <button
                  style={{
                    width: "4em",
                    height: "2.3em",
                    paddingRight: "1em",
                    paddingLeft: "1em",
                    marginRight: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={Loader} style={{ width: "1.8em", height: "2em" }} />
                </button>
              ) : (
                <button
                  style={{
                    paddingRight: "1em",
                    paddingLeft: "1em",
                    marginRight: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={handleSync}
                >
                  Sync
                </button>
              )}

              <button onClick={() => navigate("/AddProducts")}>
                Add Product
              </button>
            </div>
          </div>

          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {getAllProduct?.data?.data.data.data.responseDto && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rows={getAllProduct?.data?.data.data.data.responseDto}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={getAllProduct.data?.data.data?.data.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProductListing;
