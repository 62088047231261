import React, { useContext, useEffect } from "react";
import "./ViewCorporate.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Employee from "../Employee/Employee";
import { viewCorporateDetailRequest } from "../../../redux/Actions/Corporate/viewCorporateDetailAction";
import { corporateViewAssignProgramRequest } from "../../../redux/Actions/Corporate/viewAssignProgram";
import CorporateProgramAssignView from "./CorporateProgramAssignView";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { roles } from "../../../helpers/MainConstants";
import storage from "../../../Utils/storage";
import { UserContext } from "../../../App";

function ViewCorporate() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = useContext(UserContext);

  const corporateViewAssignProgram = useSelector(
    (state) => state.corporateViewAssignProgram
  );
  const corporateViewAssignProgramData =
    corporateViewAssignProgram?.data?.data?.data;
   

  useEffect(() => {
    dispatch(viewCorporateDetailRequest(id));
    dispatch(corporateViewAssignProgramRequest(id));
  }, []);
  const assignProgramResponse = useSelector((state) => state.assignProgram);
  //

  const corporate = useSelector((state) => state.viewCorporateDetail);
  //
  const corporateId = corporate?.data?.data?.data?.id;
  const handleClick = () => {
    if (window.location.pathname === `/insurerViewCorporateDetails/${id}`) {
      navigate("/insurer/AssignProgramToInsurer", {
        state: {
          id: id,
          corporateName: corporate.data?.data.data.name,
          employeeCount: corporate.data?.data.data.noOfEmployee,
        },
      });
    } else {
      navigate("/programAssign", {
        state: {
          id: id,
          name: corporate.data?.data.data.name,
          employeeCount: corporate.data?.data.data.noOfEmployee,
        },
      });
    }
  };
 

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  //

  return (
    <div>
      <div className="corporatemain">
        <h3 className="corporate">Corporate</h3>
        <div className="corporate-box">
          <div className="corporate-head">
            <div className="corporate-name">
              {corporate.data?.data.data.name}
            </div>
            {corporate.data?.data.data.active === false && (
              <div className="disableChip">DISABLED</div>
            )}
          </div>
          <hr />
          <div className="corporate-box-body">
            <div className="corporate-details">
              <div className="corporate-keys">
                Contact Number
                <br />
                <br />
                Email
                <br />
                <br />
                City
                <br />
                <br />
                Pincode
                <br />
                <br />
                Contact Person Name
                <br />
                <br />
                Employee Count
                <br />
                <br />
                Address
              </div>
              <div className="corporate-value">
                {corporate.data?.data.data.contactPersonNumber}
                <br />
                <br />
                {corporate.data?.data.data.contactPersonEmail}
                <br />
                <br />
                {corporate.data?.data.data.city}
                <br />
                <br />
                {corporate.data?.data.data.pinCode}
                <br />
                <br />
                {corporate.data?.data.data.contactPersonName}
                <br />
                <br />
                {corporate.data?.data.data.noOfEmployee}
                <br />
                <br />
                {corporate.data?.data.data.address}
              </div>
            </div>
            {corporate.data?.data.data.active ? (
              <>
                <div className="corporate-buttons">
                  <button
                    className="corporate-btnAssigned"
                    onClick={handleClick}
                  >
                    Assign new Program
                  </button>
                </div>
              </>
            ) : (
              <div style={{ marginTop: "5em" }}>
                <div className="grid-head">
                  <div className="hospitalDoctor">Disable Reason</div>
                </div>
                <div className="disableReason">
                  {corporate.data?.data.data.disableReason}
                </div>
                <div className="disableReasonFile">
                  {corporate.data?.data.data.fileName}
                  <VisibilityIcon
                    onClick={() =>
                      handleView(corporate.data?.data.data.filePath)
                    }
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      color: "#52608E",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {/* {assignProgramResponse?.data?.action?.status == 201 ?<CorporateProgramAssignView  corporateId={corporateId}/>:""} */}
          <br />
          <br />
          <br />

          {corporate.data?.data.data.active ? (
            <CorporateProgramAssignView
              corporateId={id}
              corporateViewAssignProgramData={corporateViewAssignProgramData}
            />
          ) : (
            <></>
          )}
          <br /> 
           {role === roles.SUPER_ADMIN && corporate.data?.data.data.active ? (
        <Employee id={id} />
      ) : (
        <></>
      )}
        </div>
      </div>
      <br />
     

      <Footer />
    </div>
  );
}

export default ViewCorporate;
