import * as types from "../../Config/actionType"

export const addLabRequest = (data) => {
    return {
        type: types.ADD_LAB_REQUEST,
        data:data
    }
}

export const addLabSuccess = (data) => {
    return {
        type: types.ADD_LAB_SUCCESS,
        data:data,
    }
}

export const addLabFailure = (data) => {
    return {
        type: types.ADD_LAB_FAILURE,
        data:data,
    }
}