import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/DeleteDiscountAction";
import NetworkService from "../../../network/NetworkService";

export function* DeleteDiscountSaga(action) {
  try {
    let response = yield call(NetworkService.deleteDiscount, action);
    yield put(actions.deleteDiscountSuccess(response));
  } catch (err) {
    yield put(actions.deleteDiscountFailure(err));
  }
}

export function* watchDeleteDiscountSaga() {
  yield takeLatest(types.DELETE_DISCOUNT_REQUEST, DeleteDiscountSaga);
}
