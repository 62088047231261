import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/downloadPrescriptionPdfAction"
import NetworkService from "../../../network/NetworkService";

export function* downloadPrescriptionPdfSaga(action) {
  try {
      //
    let response = yield call(NetworkService.downloadPrescriptionPdf, action);
    yield put(actions.downloadPrescriptionPdfSuccess(response));
  } catch (err) {
    yield put(actions.downloadPrescriptionPdfFailure(err));
  }
}

export function* watchDownloadPrescriptionPdfSaga() {
  yield takeLatest(types.DOWNLOAD_PRESCRIPTION_PDF_REQUEST, downloadPrescriptionPdfSaga);
}