import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/getAllBannerByTypeAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* getAllBannerByType(action) {
  try {
    let response = yield call(NetworkService.getAllBannerByType, action);
    yield put(actions.getAllBannerByTypeSuccess(response.data));
  } catch (err) {
    yield put(actions.getAllBannerByTypeFailure(err));
  }
}

export function* watchGetAllBannerByType() {
  yield takeEvery(types.GET_ALL_BANNER_BY_TYPE_REQUEST, getAllBannerByType);
}