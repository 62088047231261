import {
  Box,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import Footer from "../../Organisms/Footer/Footer";
import Footer from "../../Organisms/Footer/FooterUpper";

import ViewProgram from "../Programs/Programs";
import EditIcon from "@mui/icons-material/Edit";
import "./PurchasedPrograms.css";
import { useDispatch, useSelector } from "react-redux";
import { viewAllProgramRequest } from "../../../redux/Actions/Program/viewAllProgramRequest";
import { useNavigate } from "react-router-dom";

function PurchasedPrograms() {
  const navigate = useNavigate();

  const handleChange = () => {};

  const handleClick = (uuid,status) => {
    if(status==="Active"){

      navigate(`/viewProgram/${uuid}`);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAllProgramRequest());
  }, []);

  const program = useSelector((state) => state.viewAllProgram);

  return (
    <div>
      <div className="grid">
        <h4 className="h4">Programs</h4>
        <div className="mainBox">
          <div className="head">
            <h5 className="programlist">Assigned Programs</h5>
          </div>
          <hr />

          <div className="card-box">
            <Box>
              <CardContent>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                  direction="row"
                >
                  {program.data?.data.data.data.map((data) => {
                  
                      return (
                        <div
                          className={data.paymentStatus === "Active" ? "cardsPP" :"cardsPPNew" }
                          onClick={() => handleClick(data.programUuid,data.paymentStatus)}
                        >
                          <div className="card-headPP">{data.name}</div>
                          <div className="card-bodyPP">
                            Balance - {data.balance}
                          </div>
                          <div className="card-body"></div>
                        </div>
                      );
                    
                  })}
                </Grid>
              </CardContent>
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default PurchasedPrograms;
