import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import { viewEmployeeDetailRequest } from "../../../redux/Actions/Employee/viewEmployeeDetailsAction";
import { getEmployeeProgramRequest } from "../../../redux/Actions/Employee/getEmployeeProgramsAction";
import "./ViewEmployee.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import storage from "../../../Utils/storage";
import { UserContext } from "../../../App";

function ViewEmployee() {
  const { id } = useParams();
 
  const [asSuperAdmin, setAsSuperAdmin] = React.useState(true);

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const Admin =  useContext(UserContext);
  useEffect(() => {
    Admin !== "SUPER_ADMIN" ? setAsSuperAdmin(false) : setAsSuperAdmin(true);
  }, []);

  useEffect(() => {
    dispatch(viewEmployeeDetailRequest(id, asSuperAdmin));
    dispatch(getEmployeeProgramRequest(id));
  }, [asSuperAdmin]);

  const viewEmployee = useSelector((state) => state.viewEmployeeDetail);
  const getEmployeeProgram = useSelector((state) => state.getEmployeeProgram);
 
 

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Program Name",
      minWidth: 110,
      flex: 1,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: "mode",
      headerClassName: "super-app-theme--header",
      headerName: "Mode",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false
    },
    // {
    //   field: "participants",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "No of Participants",
    //   minWidth: 200,
    //   flex: 1,
    // },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: false,
      headerName: "End Date",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Payment Status",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["programStatus"] === "Active" && (
              <Typography>
                <Chip
                  label="ACTIVE"
                  color="success"
                  // sx={{ backgroundColor: "#E0FAE8" }}
                  // variant="outlined"
                />
              </Typography>
            )}
            {params.row["programStatus"] === "Inactive" && (
              <Typography>
                <Chip label="INACTIVE" color="error" />
              </Typography>
            )}
            {params.row["programStatus"] === "Upcoming" && (
              <Typography>
                <Chip
                  label="UPCOMING"
                  color="primary"
                  // sx={{ backgroundColor: "#E0FAE8" }}
                  // variant="outlined"
                />
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="employee_view">
      <div className="employeeDet">
        <p>Employee Detail</p>
      </div>
      <div className="main_box">
        <div className="box-head">{viewEmployee.data?.data.data.name}</div>
        <div className="box-bodyy">
          <div className="box_detail">
            <div className="question">
              <p>Contact Number</p>
            </div>
            <div className="answer">
              <p>
                {viewEmployee.data?.data.data.number === null ? (
                  <div className="answer">
                    <p>---</p>
                  </div>
                ) : (
                  <div className="answer">
                    <p>{viewEmployee.data?.data.data.number}</p>
                  </div>
                )}
              </p>
            </div>
          </div>
          <div className="box_detail">
            <div className="question">
              <p>official Email</p>
            </div>
            {viewEmployee.data?.data.data.email === null ? (
              <div className="answer">
                <p>---</p>
              </div>
            ) : (
              <div className="answer">
                <p>{viewEmployee.data?.data.data.email}</p>
              </div>
            )}
          </div>
          <div className="box_detail">
            <div className="question">
              <p>City</p>
            </div>
            {viewEmployee.data?.data.data.cityName === null ? (
              <div className="answer">
                <p>---</p>
              </div>
            ) : (
              <div className="answer">
                <p>{viewEmployee.data?.data.data.cityName}</p>
              </div>
            )}
          </div>
          <div className="box_detail">
            <div className="question">
              <p>Locality</p>
            </div>
            {viewEmployee.data?.data.data.address === null ? (
              <div className="answer">
                <p>---</p>
              </div>
            ) : (
              <div className="answer">
                <p>{viewEmployee.data?.data.data.address}</p>
              </div>
            )}
          </div>
          {/* <div className="buttons">
            <button className="btnAssign">Assign new Program</button>
            <button className="btnCancel">Add to new Service</button>
          </div> */}
        </div>

        <div className="program_Assigned">
          <p>Program Assigned</p>
        </div>
        <div className="employeeTable">
          {getEmployeeProgram?.data?.data.data && (
            <Box sx={{ height: "100%", width: "100%" }}>
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  // ".MuiTablePagination-toolbar" :{
                  //   alignItems: 'baseline',
                  //   // marginTop:"5%",
                  //   // backgroundColor:"red",
                  // }
                }}
            getRowId={(row) => row.programUuid
            }
            disableColumnMenu

                rows={getEmployeeProgram?.data?.data.data}
                columns={columns}
                components={{
                  Footer: "none",
                }}
              />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewEmployee;
