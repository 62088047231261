import * as types from "../../Config/actionType"

export const getPackageDropdownRequest = () => {
    return {
        type: types.GET_PACKAGE_DROPDOWN_REQUEST,
    }
}

export const getPackageDropdownSuccess = (action) => {
    return {
        type: types.GET_PACKAGE_DROPDOWN_SUCCESS,
        action
    }
}

export const getPackageDropdownFailure = (action) => {
    return {
        type: types.GET_PACKAGE_DROPDOWN_FAILURE,
        action
    }
}