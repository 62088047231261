import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Organisms/Footer/FooterUpper";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Pagination, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { deleteAppointmentRequest } from "../../../redux/Actions/Appointments/deleteAppointment";
import { viewAllAppointmentsRequest } from "../../../redux/Actions/Appointments/viewAllAppointmentsActions";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import storage from "../../../Utils/storage";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import ModalAddAppointment from "../../Organisms/Modal/ModalAddAppointment";
import "./Appointment.css";
import { UserContext } from "../../../App";

function ViewAllAppointments() {
  const role = useContext(UserContext);

  const [city, setCity] = useState(storage.getStorage("cityId"));

  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [name, setName] = React.useState("");
  const [add, setAdd] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [idd, setIdd] = useState(storage.getStorage("id"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    if (window.location.pathname === "/hospitalAppointment") {
      setOpen(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteAppointmentRequest(id));
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          navigate("/hospitalAppointment");
          window.location.reload();
        } else {
          navigate("/hospitalAppointment");
        }
      });
    }
    if (window.location.pathname === "/appointments") {
      setOpen(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteAppointmentRequest(id));
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          navigate("/appointments");
          window.location.reload();
        } else {
          navigate("/appointments");
        }
      });
    }
  };

  const handleClick = (id) => {
    if (window.location.pathname === "/hospitalAppointment") {
      navigate(`/hospitalViewAppointment/${id}`);
    } else {
      navigate(`/viewAppointmentDetails/${id}`);
    }
  };

  if (idd === null) {
    setIdd("");
  }

  if (city === null) {
    setCity("");
  }

  useEffect(() => {
    const fetchData = () => {
      dispatch(viewAllCityRequest());
      dispatch(viewAllAppointmentsRequest(page, pageSize, "", idd, city));
    };
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [city]);

  const handlePageChange = (event, value) => {
    dispatch(viewAllAppointmentsRequest(value, pageSize, "", idd, city));
    setPage(value);
  };

  const handleDropChange = (e) => {
    dispatch(viewAllAppointmentsRequest(page, e.target.value, "", idd, city));
    setPageSize(e.target.value);
  };

  const viewAppointments = useSelector((state) => state.viewAllAppointments);

  const viewCity = useSelector((state) => state.viewAllCity);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleAdd = () => {
    setAdd(true);
  };

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Appointment Id",
      minWidth: 140,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "patientName",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Name",
      minWidth: 180,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "hospitalName",
      headerClassName: "super-app-theme--header",
      headerName: "Hospital Name",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "doctorName",
      headerClassName: "super-app-theme--header",
      headerName: "Requested Doctor",
      minWidth: 170,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "patientNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Number",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "hospitalNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Hospital Number",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },

    {
      field: "timeSlot",
      headerClassName: "super-app-theme--header",
      headerName: "Time Slot",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["timeSlot"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },


    {
      field: "appointmentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Status",

      disableColumnMenu: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["appointmentStatus"] === "CANCELLED" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "FINISHED" && (
              <Typography>
                <Chip label="FINISHED" color="primary" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "COMPLETED" && (
              <Typography>
                <Chip label="COMPLETED" color="success" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "CONFIRMED" && (
              <Typography>
                <Chip label="CONFIRMED" color="info" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "INITIATED" && (
              <Typography>
                <Chip label="INITIATED" color="secondary" size="medium" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "RESCHEDULED" && (
              <Typography>
                <Chip label="RESCHEDULED" color="warning" />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleClick(cellValues.row.id)}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <DeleteIcon
              sx={{ color: "#52608E", cursor: "pointer" }}
              onClick={() => handleDelete(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  return (
    <>
      <div className="grid">
        <h4 className="h4">Appointments</h4>
        <div className="mainBox">
          <div className="head">
            <div className="appleftHead">
              <h5 className="list">List of Appointments</h5>
              <div className="count">
                {viewAppointments.data?.data.data.totalAppointments}{" "}
                Appointments
              </div>
            </div>

            {add && <ModalAddAppointment open={setAdd} />}
          </div>
          <hr />
          <div className="row2">
            <div className="city">
              {role !== "HOSPITAL_ADMIN" && (
                <AutoComplete
                  onChange={(event, newValue) => {
                    handleCity(event, newValue);
                  }}
                  heading="All Cities"

                  cityList={cityNames}
                />
              )}
            </div>
            <div className="searchSection">
              <div className="rightheadbtn">
                {role !== "HOSPITAL_ADMIN" && (
                  <button
                    type="button"
                    className="btn addModalbtn"
                    onClick={() => handleAdd()}
                    style={{paddingBottom:"1.1em"}}
                  >
                    + Add Appointment
                  </button>
                )}
              </div>
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewAppointments.data?.data.data.appointmentResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rowCount={viewAppointments.data?.data.data.totalAppointments}
                rows={viewAppointments.data?.data.data.appointmentResponseDtos}
                columns={columns}

                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewAppointments.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default ViewAllAppointments;
