import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import download from "../../../assets/download.png";
import filter from "../../../assets/filter.png";
import Loader from "../../../assets/loader.gif";
import "./DashboardStructure.css";
import HRA_Overview from "./HRA_Overview";
import blankDashboard from "../../../assets/blankDashboard.png";
import { CorporateDashboardRequest } from "../../../redux/Actions/CorporateDashboard/CorporateDashboardAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import AlcoholRisk_DonutChart from "./AlcoholRisk_DonutChart";
import BMI_DonutChart from "./BMI_DonutChart";
import Illness_BarChart from "./Illness_BarChart";
import MentalHealthRisk_BarChart from "./MentalHealthRisk_BarChart";
import NutritionalRisk_BarChart from "./NutritionalRisk_barChart";
import SafetyHabit_BarChart from "./SafetyHabits_BarChart";
import SmokingTobacco_DonutChart from "./Smoking&Tobacco_DonutChart";
import WaistCircumferance_DonutChart from "./WaistCircumferance_DonutChart";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ErrorMessage, Field, Form, Formik } from "formik";

function DashBoardStructure() {
  const pdfExportComponent = React.useRef(null);
  const [startAge, setStartAge] = React.useState();
  const [endAge, setendAge] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CorporateDashboardRequest());
  }, []);

  const corporateDashboards = useSelector((state) => state.CorporateDashboard);
  const corporateDashboard = corporateDashboards?.data?.data?.data;

  const handleChangeGAD = (event) => {
    if (event.target.value === "PHQ") {
      // setSelectchart(true);
    } else {
      // setSelectchart(false);
    }
  };
  const handleSubmit = (values, { setSubmitting }) => {
    setStartAge(values.startAge);
    setendAge(values.endAge);
    if (values.startAge && values.endAge) {
      if (values.startAge > values.endAge) {
        Swal.fire({
          title: "Start age should be smaller than end age",
          icon: "error",
        }).then(() => {
        });
      } else {
        dispatch(CorporateDashboardRequest(values.startAge, values.endAge));
      }
    } else {
      Swal.fire({
        title: "Start Age or End Age cannot be Empty!",
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  let total = corporateDashboard?.data?.totalEmployeeTakenHra;

  return (
    <>
      {corporateDashboards.isLoading ? (
        <>
          {" "}
          <h3 className="headingLoading">Please Wait...</h3>
          <div className="corporateDashboardLoading">
            <img src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
      <>
        {total >= 5 ? (
          <>
            <PDFExport forcePageBreak=".page-break" ref={pdfExportComponent}>
              <div className="Dashboard_OuterContainer">
                  <div className="Dashboard_InnerContainer">
                    <Formik
                      initialValues={{
                        name: "",
                        endAge: "",
                        startAge: "",
                      }}
                    >
                      {
                        <Form>
                          <Field
                            as="select"
                            name="hospitalId"
                            placeholder="Select Services"
                            className="DashboardInputFields"
                            onChange={handleChangeGAD}
                          >
                            <option value="HRA">HRA </option>
                            <option value="COMINGSOON">COMINGSOON</option>
                          </Field>
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="hospitalId"
                            component="div"
                          />
                        </Form>
                      }
                    </Formik>
                    <div class="DashBoard_grid-container">
                      <div class="Dashboard_grid-item">
                        <div className="Dashboard_FirstText">
                          {" "}
                          Total Number of Employee
                        </div>
                        <div></div>

                        <div className="Dashboard_SecondText">
                          {corporateDashboard?.data?.totalEmployeeInCorporate}
                        </div>
                      </div>
                      <div class="Dashboard_grid-item">
                        <div className="Dashboard_FirstText">
                          {" "}
                          HRA Completed by Employee
                        </div>

                        <div className="Dashboard_SecondText">
                          {corporateDashboard?.data?.totalEmployeeTakenHra}
                        </div>
                      </div>

                      <div class="Dashboard_grid-item">
                        <div className="Dashboard_FirstText">
                          {" "}
                          HRA Not Attempted
                        </div>

                        <div className="Dashboard_SecondText">
                          {corporateDashboard?.data?.totalEmployeeInCorporate -
                            corporateDashboard?.data?.totalEmployeeTakenHra}
                        </div>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        name: "",
                        endAge: endAge,
                        startAge: startAge,
                      }}
                      // validationSchema={loginSchema}
                      // validationSchema={addSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div class="DashBoard_grid-container1">
                            <label>
                              <Field
                                type="number"
                                id="inputID"
                                name="startAge"
                                placeholder="Start Age"
                                className="dashboardStartDate"
                              ></Field>
                              <ErrorMessage
                                className="dashboardStartDateMsg"
                                name="startAge"
                                component="div"
                              />
                            </label>

                            <label>
                              <Field
                                type="number"
                                name="endAge"
                                id="inputID"
                                placeholder="End Age"
                                className="dashboardEndDate"
                              ></Field>
                              <ErrorMessage
                                className="dashboardEndDateMsg"
                                name="endAge"
                                component="div"
                              />
                            </label>
                            <div className="dashboardFilter">
                              {/* <div className="filterDashboard">
                          {" "}
                          <FilterAltOutlinedIcon />{" "}
                        </div>
                        <p className="filterText">Filter</p> */}
                              <button
                                type="submit"
                                className="dashboardSearchFilter"
                              >
                                <img src={filter} />
                                <h>Filter</h>
                              </button>
                              <div
                                className="dashboardSearchFilter1"
                                onClick={() => {
                                  if (pdfExportComponent.current) {
                                    pdfExportComponent.current.save();
                                  }
                                }}
                              >
                                <img src={download} />
                                {/* <DownloadIcon /> */}
                                <h> Download PDF</h>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>

                    <div className="HealthRiskOverview">
                      <div className="DashBoard_HRA_grid-container">
                        <div className="Dashboard_BMI">
                          Health Risk Assessment Overview
                        </div>
                        <HRA_Overview hraOverview={corporateDashboard?.data} />
                      </div>
                    </div>

                    <div className="Dashboard_SmallGraph">
                      <div class="Dashboard_SmallGraph_container">
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI">BMI</div>

                          <BMI_DonutChart
                            bmiResponseDto={
                              corporateDashboard?.data?.bmiResponseDto
                            }
                          />
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI">
                            {" "}
                            Waist circumference
                          </div>
                          <WaistCircumferance_DonutChart
                            waistCircumferenceResponseDto={
                              corporateDashboard?.data
                            }
                          />
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI">
                            {" "}
                            Nutritional Risk
                            <NutritionalRisk_BarChart
                              nutritionalResponseDto={
                                corporateDashboard?.data?.nutritionalResponseDto
                              }
                            />
                          </div>
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI">
                            {" "}
                            Mental Health Risk
                            <MentalHealthRisk_BarChart
                              phqResponseDto={corporateDashboard?.data}
                            />
                          </div>
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI"> Alcohol Risk</div>
                          <AlcoholRisk_DonutChart
                            alcoholResponseDto={
                              corporateDashboard?.data?.alcoholResponseDto
                            }
                          />
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item">
                          <div className="Dashboard_BMI">
                            {" "}
                            Smoking & Tobacco
                          </div>
                          <SmokingTobacco_DonutChart
                            smokingTobaccoResponseDto={
                              corporateDashboard?.data
                                ?.smokingTobaccoResponseDto
                            }
                          />
                        </div>
                      </div>

                      <div className="Dashboard_SmallGraph_container1">
                        <div class="Dashboard_SmallGraph_grid-item11">
                          <div className="Dashboard_BMI">
                            {" "}
                            Safety Habits
                            <SafetyHabit_BarChart
                              safetyResponseDto={
                                corporateDashboard?.data?.safetyResponseDto
                              }
                            />
                          </div>
                        </div>
                        <div class="Dashboard_SmallGraph_grid-item1">
                          <div className="Dashboard_BMI">
                            Illness
                            <Illness_BarChart
                              illnessResponseDto={
                                corporateDashboard?.data?.illnessResponseDto
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PDFExport>
            </>
          ) : (
            // <ComingSoon />
            <div className="DashboardSoonMain">
              <div className="DashboardSoonTextcontainer">
                <div className="DashboardSoonTextcontainerdiv">
                  This <span className="DashboardSoonTextcolor">Dashboard</span>{" "}
                  is used to generate a comprehensive{" "}
                  <span className="DashboardSoonTextcolor">
                    Health Risk Assessment (HRA)
                  </span>{" "}
                  report. To view it, at least five employees must provide all
                  the necessary data. This data is then analysed to generate
                  actionable insights and recommendations which can be used to
                  drive better HR performance.
                </div>
              </div>

              <img className="DashboardSoonIMage" src={blankDashboard}></img>
            </div>
          )}
        </>
      )}

      <Footer />
    </>
  );
}

export default DashBoardStructure;
