import * as types from "../../Config/actionType"

export const addEmployeeRequest = (data) => {
   
    return {
        type: types.ADD_EMPLOYEE_REQUEST,
        data
    }
}

export const addEmployeeSuccess = (action) => {
    return {
        type: types.ADD_EMPLOYEE_SUCCESS,
        action
    }
}

export const addEmployeeFailure = (action) => {
    return {
        type: types.ADD_EMPLOYEE_FAILURE,
        action
    }
}