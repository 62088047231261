import RequestService from "../RequestService";
import { api } from "../../helpers/LifeStyle/LifeStyleConstant"



class LifeStyleNetworkServices {


  viewAllLifeStyle(action) {
    const endPoint = `${api.viewAllLifeStyle}?fixed=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  viewLifeStyleDetail(action) {
    const endPoint = `${api.viewLifeStyleDetail}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  AddLifeStyle(action) {
    const endPoint = `${api.AddLifeStyle}`;
    return RequestService.postRequest(endPoint, {
      lifeStylePackageEnum: action.data.lifeStylePackageEnum,
      lifeStylePackageName: action.data.lifestyleName,
      packageUuids:action.data.selectedPackagesUUIDS,
      testIds: action.data.selectedTestsUUIDS,
    });
  }

  DeleteLifeStyle(action) {
    
    const endPoint = `${api.DeleteLifeStyle}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }

  UpdateLifeStyle(action) {
    const endPoint = `${api.UpdateLifeStyle}/${action.data.uuid}`;
    return RequestService.putRequest(endPoint, {
      lifeStylePackageEnum: action.data.id,
      lifeStylePackageName: action.data.lifestyleName,
      packageUuids:action.data.selectedPackagesUUIDS,
      testIds: action.data.selectedTestsUUIDS,
    });
  }

  
}

export default new LifeStyleNetworkServices();