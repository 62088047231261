import axiosRequest from "../Utils/instance";
import axiosRequest1 from "../Utils/instance1";

class RequestService {
  postRequest(endPoints, object) {
    return axiosRequest.post(endPoints, object)
  }

  putRequest(endPoints, object) {
    return axiosRequest.put(endPoints, object);
  }

  deleteRequest(endPoints) {
    return axiosRequest.delete(endPoints);
  }

  getRequest(endPoints) {
    return axiosRequest.get(endPoints);
  }

  postFileRequest(endPoints, object) {
    return axiosRequest1.post(endPoints, object, {
      responseType: "arraybuffer",
    });
  }

  putFileRequest(endPoints, object, object1,object2) {
    return axiosRequest.put(endPoints, {object, object1, object2});
  }

  getFileRequest(endPoints) {
    return axiosRequest1.get(endPoints, {
      responseType: "arraybuffer",
    });
  }
}

export default new RequestService();
