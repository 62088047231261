import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/addServiceAttributeAction";
import NetworkService from "../../../network/NetworkService";

export function* addServiceAttributeSaga(action) {
  try {
   
    let response = yield call(NetworkService.addServiceAttribute, action);
   
    yield put(actions.addServiceAttributeSuccess(response));
  } catch (err) {
    yield put(actions.addServiceAttributeFailure(err));
  }
}

export function* watchAddServiceAttributeSaga() {
  yield takeLatest(types.ADD_SERVICE_ATTRIBUTE_REQUEST, addServiceAttributeSaga);
}
