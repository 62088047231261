import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { addInsuranceAgentRequest } from "../..//../redux/Actions/Agents/addInsuranceAgentAction";
import { getAllServiceRequest } from "../..//../redux/Actions/Service/getAllServiceAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

const loginSchema = Yup.object().shape({
  agentName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . & , ]+$/, "Insert only normal character"),
  contactNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")

    .required("Required Field"),

  email: Yup.string().required("Required Field").email("Invalid Email"),
  insuranceCompany: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . & , ]+$/, "Insert only normal character"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function ModalAddInsuranceAgent(props) {
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  useEffect(() => {
    // dispatch(checkEmailAgentRequest("jigmet"))
    dispatch(getAllServiceRequest());
    dispatch(viewAllCityRequest());
  }, []);
  const [recvEmail, setRecvEmail] = useState();
  const [fieldDisable, setFieldDisable] = useState(false);
  const [reinitialize, setReinitialize] = useState(true);
  const [service, setService] = React.useState([]);

  const viewServices = useSelector((state) => state.getService);
  const viewCity = useSelector((state) => state.viewAllCity);

  const dispatch = useDispatch();
  // const jig = true;

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const emailDetails = emailDetail?.data?.data.data;
  const [matchApiCall, setMatchApiCall] = useState(false);

  const [matchApiRes, setMatchApiRes] = useState(null);
  const res = useSelector((state) => state.addInsuranceAgent);
  const handleCheckEmail = (event) => {
    setRecvEmail(event.target.value);
  };

  useEffect(() => {
    emailDetails?.data ? setFieldDisable(true) : setFieldDisable(false);
  }, [emailDetails]);

  const updateRes = useSelector((state) => state.addInsuranceAgent)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    // let serviceUuidArray = [];
    // {
    //   service.map((x) => {
    //     {
    //       viewServices?.data?.action?.data?.data?.map((item) => {
    //         if (item.name === x) {
    //           serviceUuidArray.push(item.serviceUuid);
    //         }
    //       });
    //     }
    //   });
    // }

    setMatchApiRes(null);
    setMatchApiCall(true);

    const value = {
      ...values,
      // ,selectServices: serviceUuidArray
    };
    setTimeout(() => {
      dispatch(addInsuranceAgentRequest(value));
      setSubmitting(false);
    }, 400);
  };
  useEffect(() => {
    if (matchApiCall) {
      if (matchApiRes !== null && matchApiRes) {
        handleEmailMobileValidation();
      }
    }
  }, [matchApiCall, matchApiRes]);
  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
  }, [res]);

  const handleEmailMobileValidation = () => {
    if (matchApiRes?.data?.data?.status === 201) {
      props.open(false);
      Swal.fire({
        title: "Insurer Added Successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
        // setSwal(false);
        setMatchApiCall(false);
        setMatchApiRes(null);
      });
    } else if (
      matchApiRes?.error !== null &&
      matchApiRes?.error?.data?.response !== 201 &&
      matchApiRes?.error?.data?.response?.data !== null
    ) {
      swal({
        title: "" + Object.values(matchApiRes?.error?.data?.response?.data),
        icon: "error",
      }).then(() => {
        setMatchApiCall(false);
        setMatchApiRes(null);

        props.open(true);
      });
    }
  };

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setService(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>+Add New Hospital</Button> */}
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Insurer
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={
              emailDetails?.data === null
                ? {
                    agentName: "",
                    contactNumber: "",
                    email: recvEmail,
                    // address: "",
                    agentId: "",
                    insuranceCompany: "",
                    // selectServices: "",
                    password: "",
                    confirmPassword: "",
                    // pinCode: "",
                    // city: "",
                  }
                : {
                    agentName: emailDetails?.data.name,
                    contactNumber: emailDetails?.data.number,
                    email: emailDetails?.data.email,
                    // address: emailDetails?.data.address,
                    agentId: "",
                    insuranceCompany: "",
                    // selectServices: "",
                    password: emailDetails?.data.password,
                    confirmPassword: emailDetails?.data.password,
                    // pinCode: emailDetails?.data.pinCode,
                    // city: "",
                  }
            }
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, resetForm }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="email"
                      name="email"
                      // value={recvEmail}
                      placeholder="Email"
                      className="inputField"
                      onBlur={(e) => {
                        handleCheckEmail(e);
                        // setFieldValue("email", e.target.value);
                      }}
                      autoComplete={false}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="agentName"
                      // disabled={fieldDisable}
                      placeholder="Name"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="agentName"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      name="contactNumber"
                      placeholder="Contact Number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>

                  {/* <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Address"
                      disabled={fieldDisable}
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    />
                  </label>  */}
                  {/* <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select City
                      </option>
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="city"
                      component="div"
                    />
                  </label> */}
                  {/* <label>
                    <Field
                      type="number"
                      name="pinCode"
                      placeholder="Pin Code"
                      disabled={fieldDisable}
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label>  */}
                  <label>
                    <Field
                      type="text"
                      name="insuranceCompany"
                      placeholder="Insurer"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="insuranceCompany"
                      component="div"
                    />
                  </label>

                  {!emailDetails?.data && (
                    <label>
                      <Field
                        name="password"
                        type="password"
                        placeholder="Generate Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="password"
                        component="div"
                      />
                    </label>
                  )}
                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Retype Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="confirmPassword"
                        component="div"
                      />
                    </label>
                  )}

                  {/* <label>
                    <Field
                      as="select"
                      name="selectServices"
                      placeholder="Select Services"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select Service
                      </option>
                      {viewServices?.data?.action?.data?.data?.map(
                        (item, index) => (
                          <option key={index} value={item.serviceUuid}>
                            {item.name}
                          </option>
                        )
                      )}
                    </Field>
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="selectServices"
                      component="div"
                    />
                  </label> */}

                  {/* <div className="formControl">
                    <FormControl sx={{ mL: 1, width: "100%" }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Services
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        // sx={{width:400}}
                        value={service}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Services" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {viewServices?.data?.action?.data?.data?.map(
                          (item, index) => (
                            <MenuItem key={item.serviceUuid} value={item.name}>
                              <Checkbox
                                checked={service?.indexOf(item.name) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div> */}

                  <button type="submit"  disabled={!flag} className="modalButton">
                  

                    {flag ? "    Add Insurer":
                    
                    <CircularProgress/>
                    }
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddInsuranceAgent;
