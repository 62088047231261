import * as types from "../../Config/actionType"

export const uploadReportRequest = (data,id) => {
    return {
        type: types.UPLOAD_REPORT_REQUEST,
        data,
        id
    }
}

export const uploadReportSuccess = (data) => {
    return {
        type: types.UPLOAD_REPORT_SUCCESS,
        data:data,
    }
}

export const uploadReportFailure = (data) => {
    return {
        type: types.UPLOAD_REPORT_FAILURE,
        data:data,
    }
}