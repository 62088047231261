import * as types from "../../Config/actionType"

export const viewAccountRequest = (page,pageSize,searchText,select) => {
   
    return {
        type: types.VIEW_ACCOUNT_REQUEST,
        page,
        pageSize,
        searchText,
        select,
        
            
    }
}

export const viewAccountSuccess = (data) => {
    return {
        type: types.VIEW_ACCOUNT_SUCCESS,
        data:data
            
    }
}

export const viewAccountFailure = (data) => {
    return {
        type: types.VIEW_ACCOUNT_FAILURE,
        data
    }
}