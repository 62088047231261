import * as types from "../../Config/Banner/BannerActionType";

export const deletePositionBannerRequest = (data) => {
  return {
    type: types.DELETE_POSITION_BANNER_REQUEST,
    data: data,
  };
};

export const deletePositionBannerSuccess = (data) => {
  return {
    type: types.DELETE_POSITION_BANNER_SUCCESS,
    data: data,
  };
};

export const deletePositionBannerFailure = (data) => {
  return {
    type: types.DELETE_POSITION_BANNER_FAILURE,
    data: data,
  };
};
