import * as types from "../../Config/actionType"

export const viewServiceRequest1 = (name) => {
    return {
        type: types.VIEW_SERVICE_REQUEST1,
        name
    }
}

export const viewServiceSuccess1 = (action) => {
    return {
        type: types.VIEW_SERVICE_SUCCESS1,
        action
    }
}

export const viewServiceFailure1 = (action) => {
    return {
        type: types.VIEW_SERVICE_FAILURE1,
        action
    }
}