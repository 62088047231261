import React from "react";
import storage from '../../../Utils/storage';

function ActiveDropdown(props) {
  const status = storage.getStorage("status")

  return (
    <div>
      <select className="requestDropdown" {...props} value={status}>
        <option value={null}>All</option>
        <option value={true}>Active</option>
        <option value={false}>Inactive</option>
      </select>
    </div>
  );
}

export default ActiveDropdown;
