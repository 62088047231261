import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { ChangeOrderStatusRequest } from "../../../redux/Actions/Accounts/ChangeOrderStatusAction";
import { ViewOrderRequest } from "../../../redux/Actions/Accounts/ViewOrderAction";
import { ViewOrderDropdownRequest } from "../../../redux/Actions/Accounts/ViewOrderDropdownAction";
import CircularProgress from "@mui/material/CircularProgress";
import "./ViewOrderDetails.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { Close } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function ViewOrderDetailsStatus() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const viewOrder = useSelector((state) => state.viewOrder);
  const ViewOrderDropdown = useSelector((state) => state.ViewOrderDropdown);
  const ChangeOrderStatus = useSelector((state) => state.ChangeOrderStatus);
  const [selectedOption, setSelectedOption] = useState([""]);
  const [description, setDescription] = useState("");
  const [orderSelected, setOrderSelected] = useState([]);
  const [orderObjects, setOrderObjects] = useState([]);
  const [remarksModal, setRemarksModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [statusAll, setStatusAll] = useState(false);

  useEffect(() => {
    dispatch(ViewOrderRequest(id));
    dispatch(ViewOrderDropdownRequest(id));
  }, []);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    if (ChangeOrderStatus?.data || ChangeOrderStatus?.error) {
      setFlag(true);
    }
  }, [ChangeOrderStatus]);

  const handleRemarkModalAll = () => {
    setRemarksModal(true);
    setStatusAll(true);
  };

  const handleRemarkModal = () => {
    if (orderSelected.length === 0) {
      swal({
        title: "Please Select Any Order",
        icon: "info",
      });
    } else {
      setRemarksModal(true);
    }
  };

  const handleChangeStatus = () => {
    if (description === "") {
      swal({
        title: "Please enter Remarks",
        icon: "error",
      });
    } else {
      const orderSelect = statusAll ? [] : orderSelected;
      let submitData = {
        selectedOption,
        description,
        id,
        orderSelected: orderSelect,
      };
      setFlag(false);
      dispatch(ChangeOrderStatusRequest(submitData));
    }
  };
  if (ChangeOrderStatus.data?.data.status === 200) {
    swal({
      title: ChangeOrderStatus?.data?.data.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (ChangeOrderStatus?.error?.data?.response?.status) {
    swal({
      title: ChangeOrderStatus?.error?.data?.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      ChangeOrderStatus.error.data.response.status = null;
    });
  }

  window.addEventListener("popstate", function (event) {
    window.location.href = "/accounts";
  });

  useEffect(() => {
    if (viewOrder.data?.data.data.data.orderItemDetailResponseDtoList) {
      viewOrder.data?.data.data.data.orderItemDetailResponseDtoList.map(
        (item) => {
          setOrderObjects((prevValue) => [...prevValue, item]);
        }
      );
    }
  }, [viewOrder.data?.data.data.data.orderItemDetailResponseDtoList]);
  function handleDivSelect(orderId, status) {
    if (status === "FAILED") {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 1500);
    }
    if (status !== "FAILED") {
    if (orderSelected.length === 0) {
      setOrderSelected((prevValue) => [...prevValue, orderId]);
    } else {
      orderSelected.map((ele, index) => {
        if (ele === orderId) {
          orderSelected.splice(index, 1);
          setOrderSelected([...orderSelected]);
        } else if (index === orderSelected.length - 1) {
          setOrderSelected((prevValue) => [...prevValue, orderId]);
        }
      });
    }
  }
  }

  return (
    <>
      {alert? (
        <div className="statusAlert">
        <Stack
          
          sx={{
            width: "50%",
            position: "fixed",
            zIndex: 1,
          }}
          spacing={2}
        >
          <Alert severity="error" variant="filled">
            Status  cannot be changed for failed
          </Alert>
        </Stack>
        </div>
      ) : (
        <></>
      )}
      {remarksModal === true ? (
        <div className="ModalOrderRemarks">
          <div className="ViewAccountsStatusChangeCloseDiv">
            <Close
              className="ModalOrderRemarksCloseButton"
              onClick={() => {
                setRemarksModal(false);
                setDescription("");
                setSelectedOption([""]);
                setStatusAll(false);
              }}
            />
          </div>
          <select
            id="status"
            value={selectedOption}
            onChange={handleOptionChange}
            className="initiateRefundIndividualSelect"
          >
            <option value="" disabled>
              Select Status
            </option>
            {ViewOrderDropdown?.data?.data?.data?.data.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
          <textarea
            id="description"
            value={description}
            placeholder="Remarks"
            className="ViewAccountsStatusChangeDescription"
            onChange={handleDescriptionChange}
          />

          <button onClick={handleChangeStatus} className="statusChangeButton">
            {ChangeOrderStatus.isLoading === false ? (
              "Change Status"
            ) : (
              <CircularProgress style={{ width: "1.5em", height: "80%" }} />
            )}
          </button>
        </div>
      ) : (
        <></>
      )}
      <div
        className="requestPage"
        style={
          remarksModal === true ? { background: "grey", opacity: "40%" } : {}
        }
        onClick={
          remarksModal === true
            ? () => {
                setRemarksModal(false);
                setDescription("");
                setSelectedOption([""]);
              }
            : ""
        }
      >
        <h3 className="requestHead">
          Order Id - {viewOrder.data?.data.data.data.orderNumber}
        </h3>

        <div className="requestBox">
          <div className="requestTextHospital2">
            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">No Of Units :</h1>
              <h1 className="requestPageData">
                <div className="infoViewOrderDetails">
                  {viewOrder.data?.data.data.data.itemCount}
                </div>
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Amount Paid :</h1>
              <h1 className="requestPageData">
                ₹ {viewOrder.data?.data.data.data.amount} /-
              </h1>
            </div>
            {viewOrder.data?.data.data.data.refundAmount !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Amount Refunded :</h1>
                <h1 className="requestPageData">
                  ₹{viewOrder.data?.data.data.data.refundAmount}/-
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Coupon Applied :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.couponUsed ? "Yes" : "No"}
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Purchase Date :</h1>
              <h1 className="requestPageData">
                {new Date(
                  viewOrder?.data?.data?.data?.data?.date
                ).toLocaleString()}
              </h1>
            </div>
            {viewOrder.data?.data.data.data.productType !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Products :</h1>
                <h1 className="requestPageData">
                  {viewOrder.data?.data.data.data.productType}
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Mode of Payment :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.paymentMode === null
                  ? "---"
                  : viewOrder.data?.data.data.data.paymentMode}
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Booked By :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.customerName}
              </h1>
            </div>
            {viewOrder.data?.data.data.data.patientName !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Booked for :</h1>
                <h1 className="requestPageData">
                  {viewOrder.data?.data.data.data.patientName}
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Payment Status :</h1>
              <h1 className="ViewAccountsStatus_Unsuccessful">
                {" "}
                {viewOrder.data?.data.data.data.orderStatus}
              </h1>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="orderHistoryTilesArea">
            <p className="orderHistoryTilesAreaHeading">Order Items :</p>
            <button
              disabled={!flag}
              className="changeStatusButton"
              onClick={() => {
                handleRemarkModal();
              }}
            >
              Change Status{" "}
              {orderSelected.length !== 0
                ? `(selected : ${orderSelected.length})`
                : ""}
            </button>

            <div className="orderHistoryTilesAreaChild">
              {orderObjects.map((item) => (
                <div
                  className={`orderHistoryTiles ${
                    orderSelected.includes(item.orderItemId) &&
                    item.paymentStatus !== "FAILED"
                      ? "selected"
                      : ""
                  }`}
                  id="orderHistoryTiles"
                  key={item.orderItemId}
                  onClick={() =>
                    handleDivSelect(item.orderItemId, item.paymentStatus)
                  }
                >
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Order Item Id</div>
                    <div className="orderHistoryTilesRight">
                      {item.orderItemId}
                    </div>
                  </div>
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Name</div>
                    <div className="orderHistoryTilesRight">
                      {item.itemName}
                    </div>
                  </div>
                  {item.deliveryStatus !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div
                        className="orderHistoryTilesLeft"
                        style={{ display: "flex", alignItems: "centre" }}
                      >
                        <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                          Delivery Status
                        </p>
                      </div>
                      <div
                        className="orderHistoryTilesRight "
                        style={{ width: "auto" }}
                      >
                        <p
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          className="orderHistoryTilesRightDeliveryStatus"
                        >
                          {item.deliveryStatus}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.paymentStatus !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div
                        className="orderHistoryTilesLeft"
                        style={{ display: "flex", alignItems: "centre" }}
                      >
                        <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                          Payment Status
                        </p>
                      </div>
                      <div
                        className="orderHistoryTilesRight "
                        style={{ width: "auto" }}
                      >
                        <p
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          className="orderHistoryTilesRightDeliveryStatus"
                        >
                          {item.paymentStatus}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Amount</div>
                    <div className="orderHistoryTilesRight">{item.amount}</div>
                  </div>
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Number of units</div>
                    <div className="orderHistoryTilesRight">
                      {item.itemCount}
                    </div>
                  </div>
                  {item.refundAmount !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div className="orderHistoryTilesLeft">Refund Amount</div>
                      <div className="orderHistoryTilesRight">
                        {item.refundAmount}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.remarks !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div className="orderHistoryTilesLeft">Remarks</div>
                      <div className="orderHistoryTilesRight">
                        {item.remarks}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="ViewAccountsStatusChangeButtonForChangeParent">
            <button
              disabled={!flag}
              className="ViewAccountsStatusChangeButtonForChange"
              onClick={() => {
                handleRemarkModalAll();
              }}
            >
              Change Status For All
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewOrderDetailsStatus;
