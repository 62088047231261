import * as types from "../../Config/actionType";

export const deletePlanRequest = (data) => {
  return {
    type: types.DELETE_PLAN_REQUEST,
    data: data,
  };
};

export const deletePlanSuccess = (data) => {
  return {
    type: types.DELETE_PLAN_SUCCESS,
    data: data,
  };
};

export const deletePlanFailure = (data) => {
  return {
    type: types.DELETE_PLAN_FAILURE,
    data: data,
  };
};
