import * as types from "../../../Config/actionType";

export const networkViewDiagnosticRequest = (id) => {
 
  return {
    type: types.NETWORK_VIEW_DIAGNOSTIC_REQUEST,
    id
  };
};

export const networkViewDiagnosticSuccess = (data) => {
  return {
    type: types.NETWORK_VIEW_DIAGNOSTIC_SUCCESS,
    data: data,
  };
};

export const networkViewDiagnosticFailure = (data) => {
  return {
    type: types.NETWORK_VIEW_DIAGNOSTIC_FAILURE,
    data: data,
  };
};