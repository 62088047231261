import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AssignProgramToInsurer.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { viewAllProgramOfInsurerRequest } from "../../../redux/Actions/Agents/viewAllProgramOfInsurer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import storage from "../../../Utils/storage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack, Autocomplete, TextField } from "@mui/material";
import { AssignProgramToInsurerRequest } from "../../../redux/Actions/Insurer/AssignProgramToInsurerAction";
import { getInsurerAssignedProgramsRequest } from "../../../redux/Actions/Agents/getInsurerAssignedProgramsAction";
import Swal from "sweetalert2";
import ViewDetailsProgramsInsurer from "../Programs/ViewProgram";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

function AssignProgramToInsurer() {
  const location = useLocation();
  const { corporateName } = location.state;
  const [startDate, setstartDate] = React.useState("");
  const [endDate, setendDate] = React.useState("");
  const [activeIndex, setActiveIndex] = React.useState(null);
  const [count, setProgramCount] = React.useState(null);
  const [programUuid, setProgramUuid] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let id = storage.getStorage("id");

    dispatch(viewAllProgramOfInsurerRequest(id));
  }, []);

  useEffect(() => {
    dispatch(getInsurerAssignedProgramsRequest(programUuid));
  }, [programUuid]);

  const programs = useSelector((state) => state.viewAllProgramOfInsurer);

  const getInsurerAssignedPrograms = useSelector(
    (state) => state.getInsurerAssignedPrograms
  );

 

  // function () {
  const handleView = (name) => {
    navigate(`/viewProgram/${name}`);
  };
  // }

  var startDateConverted = new Date(startDate);
  startDateConverted = startDateConverted.getTime();

  var endDateConverted = new Date(endDate);
  endDateConverted = endDateConverted.getTime();
  const divClicked = (index) => {
    setActiveIndex(index);
    setProgramUuid(programs?.data?.action?.data?.data[index].programUuid);
  };
 
  const corporateid = storage.getStorage("corporateId");
  const handleSubmit = () => {
    let final = {
      count,
      startDateConverted,
      endDateConverted,
      programUuid,
      corporateid,
    };

    if (programUuid === "") {
      Swal.fire({
        title: "Please Select Program",
        icon: "error",
      });
    } else if (count === "") {
      Swal.fire({
        title: "Please Add End Date",
        icon: "error",
      });
    } else {
      dispatch(AssignProgramToInsurerRequest(final));
    }
  };
  const res = useSelector((state) => state.AssignProgramToInsurer);

  if (
    res?.data?.action.status === 201 &&
    res?.data?.action?.data.message !== ""
  ) {
    Swal.fire({
      title: `${res?.data?.action?.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.replace(`/insurerViewCorporateDetails/${corporateid}`);
    });
  }
  if (
    res?.error?.action?.response.status === 400 &&
    res?.error?.action?.response.data.errorMessage !== ""
  ) {
    Swal.fire({
      title: `${res?.error?.action?.response.data.errorMessage}`,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    res?.error?.action?.response.status === 409 &&
    res?.error?.action?.response.data.errorMessage !== ""
  ) {
    Swal.fire({
      title: `${res?.error?.action?.response.data.errorMessage}`,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }
  const handleProgramCount = (e) => {
    setProgramCount(e.target.value);
  };

  const name = getInsurerAssignedPrograms.data?.action.data.data.name;

 
  return (
    <>
      <div className="AssignProgramToInsurerMain">
        <div className="AssignProgramToInsurerUpper">
          <p>Assigning Program</p>
          <p>{corporateName}</p>
        </div>
        <div className="AssignProgramToInsurerLower">
          <div className="AssignProgramToInsurerLowerChild1">
            {programs?.data?.action?.data?.data.map((data, index) => (
              <div
                className="AssignProgramToInsurerCards"
                onClick={() => divClicked(index)}
                key={index}
              >
                <div
                  className="AssignProgramToInsurerCardsChild1"
                  style={{
                    backgroundColor:
                      activeIndex === index ? "#73D38B" : "#52608E",
                  }}
                ></div>
                <div className="AssignProgramToInsurerCardsChild2">
                  <div className="AssignProgramToInsurerCardsChild2Upper">
                    <div className="AssignProgramToInsurerCardsChild2UpperHeading">
                      {data.name}
                    </div>
                    <div className="AssignProgramToInsurerCardsChild2UpperView">
                      <VisibilityIcon
                        onClick={() => handleView(data.name)}
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          color: "#52608E",
                        }}
                      />
                    </div>
                  </div>
                  <div className="AssignProgramToInsurerCardsChild2Lower">
                    <p style={{ color: "#E68D36" }}>
                      Balance - {data.remainingCount}
                    </p>
                    <p style={{ color: "#E68D36" }}>
                      Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-{" "}
                      {data.programCount}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="AssignProgramToInsurerLowerChild2">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date()}
                inputFormat="dd/MM/yyyy"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    style={{
                      width: "20%",
                    }}
                  />
                )}
                label="start date"
                value={startDate}
                onChange={(newValue) => {
                  setstartDate(newValue);
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date(startDate).getTime() + 24 * 60 * 60 * 1000}
                maxDate={
                  new Date(startDate).getTime() + 365 * 24 * 60 * 60 * 1000
                }
                inputFormat="dd/MM/yyyy"
                renderInput={(props) => (
                  <TextField
                    {...props}
                    style={{
                      width: "20%",
                    }}
                  />
                )}
                label="end date"
                value={endDate}
                //   sx={{height:"100%",backgroundColor:"green"}}
                onChange={(newValue) => {
                  setendDate(newValue);
                }}
              />
            </LocalizationProvider>
            <input
              type="number"
              placeholder="Program Count"
              className="programCountToInsurer"
              onChange={handleProgramCount}
            />
            <button
              className="AssignProgramToInsurerLowerChild2Button"
              onClick={handleSubmit}
            >
              Assign new Program
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AssignProgramToInsurer;
