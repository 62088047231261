import RequestService from "../RequestService";
import { api } from "../../helpers/Product/ProductConstant";

class ProductNetworkServices {
  getBrandDropdown(action) {
    const endPoint = `${api.getBrandDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  getRelatedProduct(action) {
    const endPoint = `${api.getRelatedProduct}`;
    return RequestService.getRequest(endPoint);
  }

  getUnits(action) {
    const endPoint = `${api.getUnits}`;
    return RequestService.getRequest(endPoint);
  }

  getCategoryDropdown(action) {
    const endPoint = `${api.getCategoryDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  getSubCategoryDropdown(action) {
    const endPoint = `${api.getSubCategoryDropdown}`;
    return RequestService.postRequest(endPoint, {
      categoryIdList: [action.data],
    });
  }

  addProduct(action) {
    const endPoint = `${api.addProduct}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  getAllProduct(action) {
    const endPoint = `${api.getAllProduct}?pageNo=${action.data.page}&pageSize=${action.data.pageSize}`;
    return RequestService.postRequest(endPoint, {
      brandIdList: action.data.brandId,
      categoryIdList: action.data.selectCategoryId,
      productName: action.data.name,
      subCategoryIdList: action.data.subCategoryId,
    });
  }

  getAllCategory(action) {
    const endPoint = `${api.getAllCategory}?pageNo=${action.pageNo}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      search: action.search,
    });
  }

  getCategoryDetails(action) {
    const endPoint = `${api.getCategoryDetails}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getAllSubCategory(action) {
    const endPoint = `${api.getAllSubCategory}?pageNo=${action.pageNo}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      categoryId: action.id,
      search: action.search,
    });
  }
  addSubCategory(action) {
    const endPoint = `${api.addSubCategory}/${action.id}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  addBrand(action) {
    const endPoint = `${api.addBrand}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  getAllBrand(action) {
    const endPoint = `${api.getAllBrand}?pageNo=${action.pageNo}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      search: action.search,
    });
  }

  getBrandDetails(action) {
    const endPoint = `${api.getBrandDetails}/${action?.data}`;
    return RequestService.getRequest(endPoint);
  }

  updateCategory(action) {
    const endPoint = `${api.updateCategory}/${action?.id}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  updateSubCategory(action) {
    const endPoint = `${api.updateSubCategory}/${action?.id}`;
    return RequestService.putRequest(endPoint, action.data);
  }

  getSubCategoryDetails(action) {
    const endPoint = `${api.getSubCategoryDetails}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  updateBrand(action) {
    const endPoint = `${api.updateBrand}/${action?.id}`;
    return RequestService.putRequest(endPoint, action.data);
  }

  deleteCategory(action) {
    const endPoint = `${api.deleteCategory}/${action.data}`;
    return RequestService.deleteRequest(endPoint, action.data);
  }

  deleteSubCategory(action) {
    const endPoint = `${api.deleteSubCategory}/${action.data}`;
    return RequestService.deleteRequest(endPoint, action.data);
  }

  deleteBrand(action) {
    const endPoint = `${api.deleteBrand}/${action.data}`;
    return RequestService.deleteRequest(endPoint, action.data);
  }

  getProduct(action) {
    const endPoint = `${api.getProduct}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  editProduct(action) {
    const endPoint = `${api.editProduct}/${action.data.id}`;
    return RequestService.putRequest(endPoint, action.data.newFormData);
  }

  disableProduct(action) {
    const endPoint = `${api.disableProduct}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }
}

export default new ProductNetworkServices();
