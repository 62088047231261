import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/viewAllPaymentAction"
import NetworkService from "../../../network/NetworkService";

export function* viewAllPaymentSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllPayment, action);
   
    yield put(actions.viewAllPaymentSuccess(response));
  } catch (err) {
    yield put(actions.viewAllPaymentFailure(err));
  }
}

export function* watchViewAllPaymentSaga() {
  yield takeLatest(types.VIEW_ALL_PAYMENT_REQUEST, viewAllPaymentSaga);
}