import { Close } from "@mui/icons-material";
import { Autocomplete, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DiagnosticService.css";
function HRAServiceTemplate(prop) {
  const servicesAttributeResponseDtoListArray = [];
  const testsAttributeResponseDtoListArray = [];
  const packsAttributeResponseDtoListArray = [];
  const testsNameArray = [];
  const packageNameArray = [];
  const {
    templateServices,
    handleServicesAttributeData,
    packageName,
    testName,
  } = prop;

  const [testObjectArray, setTestObjectArray] = useState([]);
  const [ff, setFf] = useState([]);
  const [packageFinal, setPackageFinal] = useState([]);
  const [testFinal, setTestFinal] = useState([]);

  const [
    settestsAttributeResponseDtoListArray1,
  ] = useState([]);


  const viewServiceNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewServiceNames?.data?.data?.data?.map((item) => {
    if (item.serviceUuid === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
      item.attributeResponseDtoList.map((ele) => {
        packsAttributeResponseDtoListArray.push(ele);
      });
    }
  });

  packsAttributeResponseDtoListArray.map((item) => {
    packageNameArray.push(item.attributeName);
  });
  const viewTestNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewTestNames?.data?.data?.data?.map((item) => {
    servicesAttributeResponseDtoListArray.push(item.attributeResponseDtoList);
  });
  servicesAttributeResponseDtoListArray[1]?.map((item) => {
    testsAttributeResponseDtoListArray.push(item);
    testsNameArray.push(item.attributeName);
  });

  useEffect(() => {
    setPackageFinal(packageName);
    setTestFinal(testName);
    setTestObjectArray(templateServices.attributeCountDtoList);
  }, []);
  const handleClose1 = () => {
    settestsAttributeResponseDtoListArray1([
      ...testsAttributeResponseDtoListArray,
    ]);
  };

  // Package handleChange
  let handleChangeTest = (e, attributeUuid, name, variable) => {
    if (variable === "test") {
      let testValues = {
        [e.target.name]: parseInt(e.target.value),
        ["attributeName"]: name,
        ["attributeUuid"]: attributeUuid,
        ["attributeType"]: "INDIVIDUAL_TEST",
        ["exclusive"]: true,
      };
      if (testObjectArray.length === 0) {
        testObjectArray.push(testValues);
      } else {
        let boolT = testObjectArray.some((item) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            return true;
          }
        });
        if (boolT === true) {
          testObjectArray.map((item, index) => {
            if (item.attributeUuid === testValues.attributeUuid) {
              testObjectArray.splice(index, 1);
              testObjectArray.push(testValues);
              setTestObjectArray([...testObjectArray]);
            }
          });
        } else {
          testObjectArray.push(testValues);
          setTestObjectArray([...testObjectArray]);
        }
      }
    } else {
      let testValues = {
        [e.target.name]: parseInt(e.target.value),
        ["attributeName"]: name,
        ["attributeUuid"]: attributeUuid,
        ["attributeType"]: "PACKAGE",
        ["exclusive"]: true,
      };
      if (testObjectArray.length === 0) {
        testObjectArray.push(testValues);
      } else {
        let boolT = testObjectArray.some((item) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            return true;
          }
        });
        if (boolT === true) {
          testObjectArray.map((item, index) => {
            if (item.attributeUuid === testValues.attributeUuid) {
              testObjectArray.splice(index, 1);
              testObjectArray.push(testValues);
              setTestObjectArray([...testObjectArray]);
            }
          });
        } else {
          testObjectArray.push(testValues);
          setTestObjectArray([...testObjectArray]);
        }
      }
    }

    let ffval = {
      ["serviceName"]: templateServices.serviceName,
      ["serviceUuid"]: templateServices.serviceUuid,
      ["attributeCountDtoList"]: testObjectArray,
    };

    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceUuid === ffval.serviceUuid) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }

    handleServicesAttributeData(ff);
  };


  function handleCloseTemplate(uuid){
prop.handleCloseTemplate(uuid)

  }

  return (
    <div className="diagnostic">
      <div className="diagnostic_top">
        <div className="Diagnostic-heading">{templateServices.serviceName}</div>
        <div className="inputlabel">
          <Stack spacing={2}>
            <Autocomplete
              options={packageNameArray}
              style={{ width: 150 }}
              renderInput={(params) => (
                <TextField {...params} label="Services" />
              )}
              onChange={(event, value) => {
                packsAttributeResponseDtoListArray.map((item) => {
                  let duplicacy = false;
                  packageFinal.map((item) => {
                    if (item.attributeName === value) {
                      duplicacy = true;
                    }
                  });
                  if (!duplicacy) {
                    if (item.name === value) {
                      let packageAddition = {
                        ["attributeName"]: value,
                        ["attributeUuid"]: item.attributeId,
                      };
                      packageFinal.push(packageAddition);
                    }
                  }
                });

                setPackageFinal([...packageFinal]);
              }}
            />
          </Stack>
        </div>
        <div className="section3">
         
         <button
     className="templateClose" 
     onClick={() => {
       handleCloseTemplate(templateServices.serviceUuid
         );
     }}
   >
     <Close />
   </button>
 </div>
      </div>
      <div className="diagnostic_bottom">
        <div className="bottom_left">
          {packageFinal.map((item, index) => {
            if (true) {
              return (
                <div className="package_attribute" key={item.attributeUuid || item.packageUuid}>
                  <div className="leftside" key={item.attributeUuid || item.packageUuid}><span>{item.attributeName}</span></div>
                  <div className="rightside" key={item.attributeUuid || item.packageUuid}>
                    <input
                      name="attributeCount"
                      key={item.attributeUuid || item.packageUuid}
                      type="number"
                      // disabled
                      defaultValue={item.attributeCount}
                      min="0"
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      onBlur={(e) =>
                        handleChangeTest(
                          e,
                          item.attributeUuid || item.packageUuid,
                          item.attributeName || item.packageName,
                          "package"
                        )
                      }
                    />
                    <div className="center"></div>
                  </div>
                </div>
              );
            } else {
              return <div></div>;
            }
          })}
        </div>
      </div>
    </div>
  );
}
export default HRAServiceTemplate;
