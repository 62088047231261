import * as types from "../../Config/actionType"

export const updateServiceDescriptionRequest = (desc,detail) => {
    return {
        type: types.UPDATE_SERVICE_DESCRIPTION_REQUEST,
        desc,
        detail,
    }
}

export const updateServiceDescriptionSuccess = (action) => {
    return {
        type: types.UPDATE_SERVICE_DESCRIPTION_SUCCESS,
        action
    }
}

export const updateServiceDescriptionFailure = (action) => {
    return {
        type: types.UPDATE_SERVICE_DESCRIPTION_FAILURE,
        action
    }
}