import { CardContent, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import "./Services.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useNavigate } from "react-router-dom";
import { viewAllServiceForCorporateRequest } from "../../../redux/Actions/Service/viewAllServiceForCorporateAction";
import { useDispatch, useSelector } from "react-redux";
import ModalAddService from "../../Organisms/Modal/ModalAddService";
import Swal from "sweetalert2";
import { deleteServiceRequest } from "../../../redux/Actions/Service/deleteServiceAction";

function MyService() {
  const [add, setAdd] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const servicess = useSelector((state) => state.viewAllServiceForCorporate);
 

  useEffect(() => {
    // dispatch(viewAllServiceRequest());
    dispatch(viewAllServiceForCorporateRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(true);
  };
  const service = useSelector((state) => state.viewAllService);
  const numService = service.data?.action?.data.data.length;
 

  const handleClick = (uuid) => {
    navigate(`/viewServiceCorporate/${uuid}`);
  };

  const handleDelete = (id) => {
    // setOpen(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteServiceRequest(id.serviceUuid));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        // navigate("/services");
        window.location.reload();
      } else {
        navigate("/services");
      }
    });
  };

  // const handleEdit = (id) => {
  // };

  // const debouncedOnChanged = useDebouncedCallback((value) => {
  //   dispatch((value));
  //

  // const handleSearch = (e) => {
  // setValue(e.target.value);
  // debouncedOnChanged(value);
  // };

  // if(window.location.pathname === '/services'){
  //   setSuperAdmin(true)
  // }else{
  //   setSuperAdmin(false)
  // }

  return (
    <div>
      <div className="servicename">Services</div>
      <div className="servicemainBox">
        <div className="servicehead">
          <div className="leftHead">
            <h5 className="servicelist">List of My Services</h5>
            <div className="servicecount">{numService} Services</div>
          </div>
          {window.location.pathname === "/Myservice" ? (
            <button
              type="button"
              className="service-addbtn"
              onClick={() => handleAdd()}
            >
              + Add Service
            </button>
          ) : (
            ""
          )}
          {add && <ModalAddService open={setAdd} />}
        </div>
        <hr />

        <div className="card-box">
          <Box>
            <CardContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                direction="row"
              >
                {servicess.data?.action?.data.data?.myServices?.map((data) => (
                  <div className="cards">
                    <div className="card-head">
                      {data.name}
                      {/* <DeleteIcon
                        className="card-edit"
                        sx={{ color: "#319B4B" }}
                        fontSize="small"
                        onClick={() => handleDelete(data)}
                      /> */}
                    </div>
                    <div class="myServicesDescription">
                      {data.description.slice(0, 20)}...
                      <span class="myServicesDescriptionText">
                        {data.description}{" "}
                      </span>
                    </div>
                    {/* <div className="myServicesDescription">{data.description}</div> */}
                    {/* <div
                      className="card-body"
                      // onClick={() => handleClick(data.serviceUuid)}
                    ></div> */}
                  </div>
                ))}

                {/* <div className="service-custom-card">
                  <div className="custom-card-head">
                    Custom Service
                    <DeleteIcon
                      className="card-edit"
                      sx={{ color: "#319B4B" }}
                      fontSize="small"
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-text">Associate Hospital - 12</div>
                    <div className="card-text">Associate Hospital - 12</div>
                    <div className="card-text">Associate Hospital - 12</div>
                  </div>
                </div> */}
              </Grid>
            </CardContent>
          </Box>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default MyService;
