import React, { useEffect, useLayoutEffect, useState } from "react";
import "./Modal.css";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { getRelationRequest } from "../../../redux/Actions/Employee/getRelationAction";
import { programAndPlanRequest } from "../../../redux/Actions/Appointments/programAndPlan";
import { checkEmailOrNumberRequest } from "../../../redux/Actions/CreateTalkToDoctor/checkEmailOrNumberAction";
import { CreateTalkToDoctorRequest } from "../../../redux/Actions/CreateTalkToDoctor/CreateTalkToDoctorAction";

const loginSchema = Yup.object().shape({
  description: Yup.string().required("Required Field"),
});

const modalHeader = {
  fontSize: "35px",
  fontFamily: "Nunito",
  marginBottom: "30px",
  marginTop: "15px",
};

function ModalCreateTalkToDoctor(props) {
  const dispatch = useDispatch();

  const uuid = "bb4385d4-7f92-11ed-a1eb-0242ac120002";

  const [email, setEmail] = useState("");
  const [index, setIndex] = useState("");
  const [isFree, setIsFree] = useState(null);
  const [selectedRelation, setSelectedRelation] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [selectedIssueError, setSelectedIssueError] = useState(false);

  const [isCheck, setIsCheck] = useState(false);
  const [consultationError, setConsultationError] = useState(false);
  const [requestType, setRequestType] = useState("CALL");

  const RelationSearch = useSelector((state) => state.getRelation);
  const emailOrNumber = useSelector((state) => state.checkEmailOrNumber);

  const datas = {
    id: emailOrNumber?.data?.data?.data?.data.id,
    uuid: uuid,
  };

  const programAndPlan = useSelector(
    (state) => state.programAndPlan?.data?.data?.data
  );

  useEffect(() => {
    if (emailOrNumber?.data?.data?.data?.data !== undefined) {
      dispatch(programAndPlanRequest(datas));
    }
  }, [emailOrNumber?.data?.data?.data?.data]);

  useEffect(() => {
    if (emailOrNumber.data?.data.status === 200) {
      dispatch(getRelationRequest(emailOrNumber.data?.data.data.data?.id));
    }
  }, [emailOrNumber.data?.data.data.data?.id]);

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleClose = () => {
    props.open(false);
    window.location.reload();
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (selectedIssue === "") {
      setSelectedIssueError(true)
      return;
    } else {
      setSelectedIssueError(false)
    }
    let val = {};
    const { patientEmail, contactNumber, patientName, ...restValues } =
      values;



    val = {
      ...restValues,
      relationId: selectedRelation,
      healthConcern: selectedIssue,
      requestType: requestType,
    };

    dispatch(CreateTalkToDoctorRequest(val));
  };

  const handleCheckEmail = (event) => {
    setIsCheck(true);
    setEmail(event.target.value);
  };

  const handlePlans = (event) => {
    setIndex(event.target.value);
  };

  useEffect(() => {
    if (email.length > 3) {
      dispatch(checkEmailOrNumberRequest(email));
    }
  }, [email]);

  const res = useSelector((state) => state.createTalkToDoctor);


  useEffect(() => {
    if (res?.data?.data?.status === 201) {
      props.open(false);
      Swal.fire({
        title: "Consultation added successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [res])

  useEffect(() => {
    if (res?.error != null) {
      if (
        (res.data?.data.status !== 201 && res.data === undefined) ||
        res.error !== null
      ) {
        swal({
          title: "" + Object.values(res.error?.data?.response.data)[0],
          icon: "error",
        }).then(() => {
          props.open(true);
        });
      }
    }
  }, [res]);

  const IssuesOptions = [
    "Cold",
    "Cough & Fever",
    "Sexual Problems",
    "Skin Problem",
    "General Physician",
    "Online Physiotherapy",
    "Women’s Issues",
    "Ear, Nose & Throat",
    "Pregnancy Problems",
    "Child/Infant Issues",
    "I don’t Know",
  ];

  const handleFree = (e) => {
    setConsultationError(false);
    setIsFree(e.target.value === "free");
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Consultation
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>
          <Formik
            initialValues={{
              patientEmail: email,
              patientName: emailOrNumber.data?.data?.data?.data?.name,
              contactNumber: email.includes("@")
                ? emailOrNumber.data?.data?.data?.data?.number
                : emailOrNumber.data?.data?.data?.data?.email,
              userId: emailOrNumber.data?.data?.data?.data?.id,
              description: "",
              healthConcern: "",
              relationId: "",
              plan: "",
              requestType: "",
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      name="patientEmail"
                      value={email}
                      placeholder="Patient Email Or Number"
                      onChange={handleCheckEmail}
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="patientEmail"
                      component="div"
                    />
                    {emailOrNumber?.data?.data?.data?.message != null && (
                      <>
                        <div className="emailNotRegistered">
                          Email or Number Not Register
                        </div>
                      </>
                    )}
                  </label>
                  <div className="BookingNumberData">
                    {emailOrNumber?.data?.data?.data.data.number && (
                      <label>
                        Patient Number :{" "}
                        {emailOrNumber?.data?.data?.data.data.number}
                      </label>
                    )}

                    {emailOrNumber?.data?.data?.data.data.email && (
                      <label>
                        Patient Email :{" "}
                        {emailOrNumber?.data?.data?.data.data.email}
                      </label>
                    )}
                  </div>

                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <label>
                      <Field
                        type=""
                        name="patientName"
                        placeholder="Patient Name"
                        className="inputField"
                        disabled={true}
                        onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="patientName"
                        component="div"
                      />
                    </label>
                  )}
                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <label>
                      <select
                        name="healthConcern"
                        value={selectedIssue}
                        onChange={(e) => setSelectedIssue(e.target.value)}
                        className="inputField"
                      >
                        <option value="" selected disabled>Select Health Concern</option>
                        {IssuesOptions?.map((issue, index) => (
                          <option key={index} value={issue}>
                            {issue}
                          </option>
                        ))}
                      </select>
                      {selectedIssueError && (
                        <div className="emailExist">Required Field</div>
                      )}
                    </label>
                  )}
                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <label>
                      <Field
                        type=""
                        name="description"
                        placeholder="Description"
                        className="inputField"
                        onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="description"
                        component="div"
                      />
                    </label>
                  )}

                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <label>
                      <select
                        name="relationId"
                        value={selectedRelation}
                        onChange={(e) => setSelectedRelation(e.target.value)}
                        className="inputField"
                      >
                        <option value="">Myself</option>
                        {RelationSearch?.data?.data?.data?.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                              {item.relation}
                              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;({item.age})
                            </option>
                          )
                        )}
                      </select>
                    </label>
                  )}

                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <label>
                      <select
                        name="requestType"
                        value={requestType}
                        onChange={(e) => setRequestType(e.target.value)}
                        className="inputField"
                      >
                        <option value="">Request Type</option>
                        <option value="CALL" selected>CALL</option>
                        <option value="VIDEO">VIDEO</option>
                      </select>
                    </label>
                  )}
                  {emailOrNumber.data?.data?.data?.data && isCheck && (
                    <button
                      type="submit"
                      style={{ width: "40%" }}
                      className="modalButtonAppointment"
                    >
                      Add Consultation
                    </button>
                  )}
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalCreateTalkToDoctor;