import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/EditDiscountAction";
import NetworkService from "../../../network/NetworkService";

export function* EditDiscountSaga(action) {
  try {
    let response = yield call(NetworkService.editDiscount, action);
    yield put(actions.EditDiscountSuccess(response));
  } catch (err) {
    yield put(actions.EditDiscountFailure(err));
  }
}

export function* watchEditDiscountSaga() {
  yield takeLatest(types.EDIT_DISCOUNT_REQUEST, EditDiscountSaga);
}
