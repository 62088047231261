import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getBrandDropdownAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getBrandDropdownSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getBrandDropdown, action);
   
    yield put(actions.getBrandDropdownSuccess(response));
  } catch (err) {
   
    yield put(actions.getBrandDropdownFailure(err));
  }
}

export function* watchGetBrandDropdownSaga() {
 
  yield takeLatest(types.GET_BRAND_DROPDOWN_REQUEST, getBrandDropdownSaga);
}
