import * as types from "../../Config/actionType"

export const finishBookingRequest = (data) => {
    return {
        type: types.FINISH_BOOKING_REQUEST,
        data:data
    }
}

export const finishBookingSuccess = (data) => {
    return {
        type: types.FINISH_BOOKING_SUCCESS,
        data:data,
    }
}

export const finishBookingFailure = (data) => {
    return {
        type: types.FINISH_BOOKING_FAILURE,
        data:data,
    }
}