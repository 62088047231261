export const GET_BRAND_DROPDOWN_REQUEST = "GET_BRAND_DROPDOWN_REQUEST";
export const GET_BRAND_DROPDOWN_SUCCESS = "GET_BRAND_DROPDOWN_SUCCESS";
export const GET_BRAND_DROPDOWN_FAILURE = "GET_BRAND_DROPDOWN_FAILURE";

export const GET_RELATED_PRODUCT_REQUEST = "GET_RELATED_PRODUCT_REQUEST";
export const GET_RELATED_PRODUCT_SUCCESS = "GET_RELATED_PRODUCT_SUCCESS";
export const GET_RELATED_PRODUCT_FAILURE = "GET_RELATED_PRODUCT_FAILURE";

export const GET_UNITS_REQUEST = "GET_UNITS_REQUEST";
export const GET_UNITS_SUCCESS = "GET_UNITS_SUCCESS";
export const GET_UNITS_FAILURE = "GET_UNITS_FAILURE";

export const GET_CATEGORY_DROPDOWN_REQUEST = "GET_CATEGORY_DROPDOWN_REQUEST";
export const GET_CATEGORY_DROPDOWN_SUCCESS = "GET_CATEGORY_DROPDOWN_SUCCESS";
export const GET_CATEGORY_DROPDOWN_FAILURE = "GET_CATEGORY_DROPDOWN_FAILURE";

export const GET_SUB_CATEGORY_DROPDOWN_REQUEST = "GET_SUB_CATEGORY_DROPDOWN_REQUEST";
export const GET_SUB_CATEGORY_DROPDOWN_SUCCESS = "GET_SUB_CATEGORY_DROPDOWN_SUCCESS";
export const GET_SUB_CATEGORY_DROPDOWN_FAILURE = "GET_SUB_CATEGORY_DROPDOWN_FAILURE";

export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAILURE = "GET_ALL_PRODUCT_FAILURE";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";

export const DISABLE_PRODUCT_REQUEST = "DISABLE_PRODUCT_REQUEST";
export const DISABLE_PRODUCT_SUCCESS = "DISABLE_PRODUCT_SUCCESS";
export const DISABLE_PRODUCT_FAILURE = "DISABLE_PRODUCT_FAILURE";

export const GET_ALL_CATEGORY_REQUEST = "GET_ALL_CATEGORY_REQUEST";
export const GET_ALL_CATEGORY_SUCCESS = "GET_ALL_CATEGORY_SUCCESS";
export const GET_ALL_CATEGORY_FAILURE = "GET_ALL_CATEGORY_FAILURE";


export const GET_CATEGORY_DETAILS_REQUEST = "GET_CATEGORY_DETAILS_REQUEST";
export const GET_CATEGORY_DETAILS_SUCCESS = "GET_CATEGORY_DETAILS_SUCCESS";
export const GET_CATEGORY_DETAILS_FAILURE = "GET_CATEGORY_DETAILS_FAILURE";


export const GET_ALL_SUB_CATEGORY_REQUEST = "GET_ALL_SUB_CATEGORY_REQUEST";
export const GET_ALL_SUB_CATEGORY_SUCCESS = "GET_ALL_SUB_CATEGORY_SUCCESS";
export const GET_ALL_SUB_CATEGORY_FAILURE = "GET_ALL_SUB_CATEGORY_FAILURE";

export const ADD_SUB_CATEGORY_REQUEST = "ADD_SUB_CATEGORY_REQUEST";
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS";
export const ADD_SUB_CATEGORY_FAILURE = "ADD_SUB_CATEGORY_FAILURE";

export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILURE = "ADD_BRAND_FAILURE";


export const GET_ALL_BRAND_REQUEST = "GET_ALL_BRAND_REQUEST";
export const GET_ALL_BRAND_SUCCESS = "GET_ALL_BRAND_SUCCESS";
export const GET_ALL_BRAND_FAILURE = "GET_ALL_BRAND_FAILURE";


export const GET_BRAND_DETAILS_REQUEST = "GET_BRAND_DETAILS_REQUEST";
export const GET_BRAND_DETAILS_SUCCESS = "GET_BRAND_DETAILS_SUCCESS";
export const GET_BRAND_DETAILS_FAILURE = "GET_BRAND_DETAILS_FAILURE";


export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";


export const UPDATE_SUB_CATEGORY_REQUEST = "UPDATE_SUB_CATEGORY_REQUEST";
export const UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS";
export const UPDATE_SUB_CATEGORY_FAILURE = "UPDATE_SUB_CATEGORY_FAILURE";

export const GET_SUB_CATEGORY_DETAILS_REQUEST = "GET_SUB_CATEGORY_DETAILS_REQUEST";
export const GET_SUB_CATEGORY_DETAILS_SUCCESS = "GET_SUB_CATEGORY_DETAILS_SUCCESS";
export const GET_SUB_CATEGORY_DETAILS_FAILURE = "GET_SUB_CATEGORY_DETAILS_FAILURE";


export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";


export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";


export const DELETE_SUB_CATEGORY_REQUEST = "DELETE_SUB_CATEGORY_REQUEST";
export const DELETE_SUB_CATEGORY_SUCCESS = "DELETE_SUB_CATEGORY_SUCCESS";
export const DELETE_SUB_CATEGORY_FAILURE = "DELETE_SUB_CATEGORY_FAILURE";


export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";
