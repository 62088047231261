import React, {useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { updateServiceAttributeRequest } from "../../../redux/Actions/Service/updateServiceAttributeAction";
import swal from 'sweetalert';
import Swal from "sweetalert2";
import { modalHeader } from "./ModalConstants";

const loginSchema = Yup.object().shape({});

function ModalUpdateService(props) {
  //
  const dispatch = useDispatch();

  const handleClose = () => props.open(false);

  const handleSubmit = (values) => {
    let newFormValues = {
      ["attributeName"]: values.attributeName,
      ["cost"]: parseInt(values.cost),
      ["attributeDescription"]: values.attributeDescription,
    };
    let list = [];
    list.push(newFormValues);
    let val = {};
    val = { list, values };
    //
    dispatch(updateServiceAttributeRequest(val));
  };

  // const handleAdd = () => {};

  // const handleChange = (newService) => {
  //   // setCurrentService(newService);
  //  
  //   // dispatch(viewServiceRequest(newService));
  //   // setCurrentServiceDisable(newService);
  // };

  // const [inputList, setInputList] = useState([
  //   { attributeName: "", cost: "", attributeDescription: "" },
  // ]);

  const [description, setDescription] = useState([]);

  // handle input change
  const handleInputChange = (e) => {
    //
    setDescription(e.target.value);
  };

  const res = useSelector((state) => state.updateServiceAttribute);

  if (res.data?.action.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Attributes updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  
  if (
    (res.data?.data.status !== 200 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }
  
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Update Attribute
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              serviceName: props.serviceUuid,
              attributeID: props.value.attributeId,
              cost: props.value.cost,
              attributeName: props.value.attributeName,
              attributeDescription: props.value.attributeDescription,
              provider: ["HOSPITAL"],
              name: props.serviceName,
              description: props.serviceDescription,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <div className="attributeCardBox">
                    <label>
                      <Field
                        type=""
                        name="attributeName"
                        placeholder="Attribute Name"
                        className="inputFieldAddAttributeFirstBox"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="attributeName"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        type=""
                        name="cost"
                        placeholder="cost"
                        onWheel={(e) => e.target.blur()}
                        className="inputFieldAddAttributeBox"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="attributeName"
                        component="div"
                      />
                    </label>
                    {/* <label>
                      <Field
                        type=""
                        name="attributeDescription"
                        placeholder="Attribute Description"
                        className="inputFieldAttributeDescription "
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="attributeName"
                        component="div"
                      />
                    </label> */}
                    <label>
                      <Field
                        // type="textarea"
                        as="textarea"
                        name="attributeDescription"
                        placeholder="Attribute Description"
                        className="inputFieldAttributeDescription"
                        // value={attributeDescription}
                        onBlur={(e) => handleInputChange(e)}
                        rows="14"
                        cols="10"
                        wrap="soft"
                      />
                    </label>
                  </div>
                  {/* <div className="inputFieldsContainer"> */}
                  {/* <label>
                    <Field
                      type=""
                      name="serviceName"
                      placeholder="Service Name"
                      className="inputFieldAddService"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <textarea
                      type="textarea"
                      onBlur={e=> {handleDescription(e)}}
                      placeholder="Service Description"
                      className="inputFieldDescription"
                      rows="14"
                      cols="10"
                      wrap="soft"
                      // value={description}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="speciality"
                      component="div"
                    />
                    
                  </label> */}

                  {/* <Typography
                  id="modal-modal-title"
                  variant="h3"
                  component="h2"
                  sx={modalHeader2}
                >
                  Attributes
                </Typography> */}

                  <button type="submit" className="modalButtonAttribute">
                    Update Attribute
                  </button>
                </div>
                {/* </div> */}
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdateService;
