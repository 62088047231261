import * as types from "../../../Config/actionType"

export const HospitalDashboardRequest = (data) => {
    return {
        type: types.HOSPITAL_DASHBOARD_REQUEST,
        data:data,
    }
}

export const HospitalDashboardSuccess = (data) => {
    return {
        type: types.HOSPITAL_DASHBOARD_SUCCESS,
        data:data,
    }
}

export const HospitalDashboardFailure = (data) => {
    return {
        type: types.HOSPITAL_DASHBOARD_FAILURE,
        data:data,
    }
}
