import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/viewAllBookingAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllBooking(action) {
  try {
    let response = yield call(NetworkService.viewAllBooking, action);
    yield put(actions.viewAllBookingSuccess(response));
  } catch (err) {
    yield put(actions.viewAllBookingFailure(err));
  }
}

export function* watchViewAllBookingSaga() {
  yield takeLatest(types.VIEW_ALL_BOOKING_REQUEST, viewAllBooking);
}