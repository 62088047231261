import * as types from "../../Config/actionType"

export const viewAllAppointmentsRequest = (page,pageSize,value,id,city) => {
    return {
        type: types.VIEW_ALL_APPOINTMENTS_REQUEST,
        page,
        pageSize,
        value,
        id,
        city
    }
}

export const viewAllAppointmentsSuccess = (action) => {
    return {
        type: types.VIEW_ALL_APPOINTMENTS_SUCCESS,
        action
    }
}

export const viewAllAppointmentsFailure = (action) => {
    return {
        type: types.VIEW_ALL_APPOINTMENTS_FAILURE,
        action
    }
}