import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/AddBrandAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* addBrandSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.addBrand, action);
   
    yield put(actions.addBrandSuccess(response));
  } catch (err) {
   
    yield put(actions.addBrandFailure(err));
  }
}

export function* watchAddBrandSaga() {
 
  yield takeLatest(types.ADD_BRAND_REQUEST, addBrandSaga);
}
