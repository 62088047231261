import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import Swal from "sweetalert2";
import * as Yup from "yup";
import { addServiceAttributeRequest } from "../../../redux/Actions/Service/addServiceAttributeAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";



const loginSchema = Yup.object().shape({
  cost: Yup.number() 
});

function ModalAddNewAttributeService(props) {
  const dispatch = useDispatch();

  const handleClose = () => props.open(false);

  const handleSubmit = (values, { setSubmitting }) => {
    let newFormValues = {
      ["attributeName"]: values.attributeName,
      ["cost"]: parseInt(values.cost),
      ["attributeDescription"]: description,
    };
    let list = [];
    list.push(newFormValues);
    let val = {};
    val = { list, values };
    dispatch(addServiceAttributeRequest(val));
  };

  // const handleAdd = () => {};

  // const handleChange = (newService) => {
  //   // setCurrentService(newService);
  //   // dispatch(viewServiceRequest(newService));
  //   // setCurrentServiceDisable(newService);
  // };

  // const [inputList, setInputList] = useState([
  //   { attributeName: "", cost: "", attributeDescription: "" },
  // ]);

  const [description, setDescription] = useState([]);

  // handle input change
  const handleInputChange = (e) => {
    setDescription(e.target.value);
  };

  const res = useSelector((state) => state.addServiceAttribute)

  if (res.data?.action.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Attributes added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }


  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Attribute
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              serviceName: props.serviceName,
              serviceUuid:props.serviceUuid,
              serviceDescription:props.serviceDescription,
              cost: "",
              attributeName: "",
              provider: ["HOSPITAL"],
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <div className="attributeCardBox">
                    <label>
                      <Field
                        type=""
                        name="attributeName"
                        placeholder="Attribute Name"
                        className="inputFieldAddAttributeFirstBox"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="attributeName"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        type="number"
                        name="cost"
                        placeholder="cost"
                        className="inputFieldAddAttributeBox"
                      onWheel={ event => event.currentTarget.blur() }

                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="attributeName"
                        component="div"
                      />
                    </label>

                    <textarea
                      type="textarea"
                      placeholder="Attribute Description"
                      className="inputFieldAttributeDescription"
                      // value={attributeDescription}
                      onBlur={(e) => handleInputChange(e)}
                      rows="14"
                      cols="10"
                      wrap="soft"
                    />
                  </div>
                  {/* <div className="inputFieldsContainer"> */}
                  {/* <label>
                    <Field
                      type=""
                      name="serviceName"
                      placeholder="Service Name"
                      className="inputFieldAddService"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <textarea
                      type="textarea"
                      onBlur={e=> {handleDescription(e)}}
                      placeholder="Service Description"
                      className="inputFieldDescription"
                      rows="14"
                      cols="10"
                      wrap="soft"
                      // value={description}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="speciality"
                      component="div"
                    />
                    
                  </label> */}

                  {/* <Typography
                  id="modal-modal-title"
                  variant="h3"
                  component="h2"
                  sx={modalHeader2}
                >
                  Attributes
                </Typography> */}

                  <button type="submit" className="modalButtonAttribute">
                    Add New Attribute
                  </button>
                </div>
                {/* </div> */}
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddNewAttributeService;
