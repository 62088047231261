import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/LifeStyle/lifeStyleActionType";
import * as actions from "../../Actions/LifeStyle/DeleteLifeStyleAction";
import NetworkService from "../../../network/LifeStyle/viewAllLifeStyleService";

export function* DeleteLifeStyleSaga(action) {
  try {
    let response = yield call(NetworkService.DeleteLifeStyle, action);
    yield put(actions.DeleteLifeStyleSuccess(response));
  } catch (err) {
    yield put(actions.DeleteLifeStyleFailure(err));
  }
}

export function* watchDeleteLifeStyleSaga() {
  yield takeLatest(types.DELETE_LIFESTYLE_REQUEST, DeleteLifeStyleSaga);
}