import * as types from "../../Config/actionType"

export const deleteEmployeeRequest = (data) => {
    return {
        type: types.DELETE_EMPLOYEE_REQUEST,
        data
    }
}

export const deleteEmployeeSuccess = (action) => {
    return {
        type: types.DELETE_EMPLOYEE_SUCCESS,
        action
    }
}

export const deleteEmployeeFailure = (action) => {
    return {
        type: types.DELETE_EMPLOYEE_FAILURE,
        action
    }
}