import React, { useEffect, useState } from "react";
import "./Hospitals.css";
import EditIcon from "@mui/icons-material/Edit";
import { viewAllHospitalRequest } from "../../../redux/Actions/Hospitals/viewAllHospitalAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import { Box } from "@mui/system";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import { getUpdateHospitalRequest } from "../../../redux/Actions/Hospitals/getUpdateHospitalAction";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import ModalUpdateHospital from "../../Organisms/Modal/ModalUpdateHospital";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import storage from "../../../Utils/storage";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DisableHospitalModal from "../../Organisms/Modal/DisableHospitalModal";
import ActiveDropdown from "../../Organisms/ActiveDropdown/ActiveDropdown";

function Hospitals() {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [delOpen, setdelOpen] = useState(false);
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [delId, setDelId] = useState();
  const [status, setStatus] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (city === null) {
    setCity("");
  }

  const handleDelete = (id) => {
    setDelId(id);
    setdelOpen(true);
  };

  useEffect(() => {
    if (city === null) {
      dispatch(viewAllHospitalRequest(1, pageSize, searchText, "", status));
    } else {
      dispatch(
        viewAllHospitalRequest(1, pageSize, searchText, city, status)
      );
    }
    dispatch(viewAllCityRequest());
    setPage(1);
  }, [city]);

  const handleEdit = (value) => {
    dispatch(getUpdateHospitalRequest(value?.id));
    setOpen(true);
    setEdit(true);
  };

  const handleClick = (id) => {
    navigate(`/viewHospitalDetails/${id}`);
  };
  

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        viewAllHospitalRequest(page, pageSize, newSearchText, city, status)
      );
      setPage(1);
    } else {
      dispatch(
        viewAllHospitalRequest(
          searchPage,
          pageSize,
          newSearchText,
          city,
          status
        )
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllHospitalRequest(value, pageSize, searchText, city, status)
      );
      setPage(value);
    } else {
      setSearchPage(value);
      dispatch(
        viewAllHospitalRequest(value, pageSize, searchText, city, status)
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllHospitalRequest(
          page,
          event.target.value,
          searchText,
          city,
          status
        )
      );
    } else {
      dispatch(
        viewAllHospitalRequest(
          searchPage,
          event.target.value,
          searchText,
          city,
          status
        )
      );
    }
    setPageSize(event.target.value);
  };

  const viewHospital = useSelector((state) => state.viewAllHospitals);

  const viewCity = useSelector((state) => state.viewAllCity);
  const deleteHospital = useSelector((state) => state.deleteHospital);

  if (deleteHospital) {
  }

  const getUpdateHospitalData = useSelector(
    (state) => state.getUpdateHospital.data
  );

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllHospitalRequest(page, pageSize, searchText, city, e.target.value)
      );
    } else {
      dispatch(
        viewAllHospitalRequest(
          searchPage,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    }
    setStatus(e.target.value);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: " Address",
      flex: 1.8,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "contactEmail",
      headerClassName: "super-app-theme--header",
      headerName: " Email",
      flex: 1.8,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "contactPersonNumber",
      headerClassName: "super-app-theme--header",
      headerName: " Contact No",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "contactName",
      headerClassName: "super-app-theme--header",
      headerName: " Contact Person",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "noOfDoctors",
      headerClassName: "super-app-theme--header",
      headerName: "No of Doctors",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
            {viewHospital.data?.data?.data?.hospitalResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <IconButton sx={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row.id)}
                />
              </IconButton>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
            &nbsp;&nbsp;
            <Box>
              {viewHospital.data?.data?.data?.hospitalResponseDtos[
                cellValues.api.getRowIndex(cellValues.row.id)
              ]?.active === false ? (
                <div>
                  <Switch disabled sx={{ color: "#52608E" }} />
                </div>
              ) : (
                <div>
                  {" "}
                  <Switch
                    defaultChecked
                    sx={{ color: "#52608E" }}
                    onClick={() => handleDelete(cellValues.row.id)}
                  />{" "}
                </div>
              )}
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="hospgrid">
        <h4 className="h4">Hospitals</h4>
        <div className="mainBox">
          {delOpen && (
            <DisableHospitalModal delOpen={setdelOpen} delId={delId} />
          )}
          {edit && getUpdateHospitalData && (
            <ModalUpdateHospital value={getUpdateHospitalData} open={setEdit} />
          )}
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Hospitals</h5>
              <div className="hospitalcount">
                {viewHospital.data?.data.data.totalHospitals} Hospitals
              </div>
            </div>
            {/* <div className="rightheadbtn">
            <div
              type="button"
              className="btn addModalbtn"
              onClick={() => handleAdd()}
            >
            
              + Add Hospital
            </div>
          </div>

          {add && <ModalAddHospital open={setAdd} />} */}
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities" 

                cityList={cityNames}
              />
              <ActiveDropdown onChange={(e) => handleStatus(e)} />
              {/* <select
              className="requestDropdown"
              // onChange={(e) => handleStatus(e)}
            >
              <option value="">All</option>
              <option value="APPROVED">Active</option>
              <option value="DECLINED">Inactivel</option>
            </select> */}
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  className="searchField"
                  // onChange={(e) => setName(e.target.value)}
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                    fontFamily: "Nunito",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
              <button
                type="submit"
                value="Submit"
                className="corporatesearchBtn"
                style={{
                  backgroundColor: "#52608E",
                  width: "150px",
                  align: "end",
                  color: "white",
                }}
              >
                <FilterAltIcon fontSize="small" />
                Filter
              </button>
            </div> */}
              {/* <div className="searchButton">
              <form
                onSubmit={(e) => {
                  handleSearch(e);
                }}
              >
                <button
                  type="submit"
                  value="Submit"
                  className="hospitalsearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                    border: "none",
                  }}
                >
                  Search
                </button>
              </form>
            </div> */}
            </div>
          </div>
          <hr />
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewHospital.data?.data.data.hospitalResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  fontFamily: "Nunito",
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                rowCount={viewHospital.data?.data.data.totalHospitals}
                pagination
                disableColumnMenu
                rows={viewHospital.data?.data.data.hospitalResponseDtos}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                // onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // onSelectionModelChange={(itm) => handleClick(itm)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewHospital.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Hospitals;
