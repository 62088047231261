import * as types from "../../Config/actionType"

export const getDosageTypeRequest = (data) => {
    return {
        type: types.GET_DOSAGE_TYPE_REQUEST,
        data:data
    }
}

export const getDosageTypeSuccess = (data) => {
    return {
        type: types.GET_DOSAGE_TYPE_SUCCESS,
        data:data,
    }
}

export const getDosageTypeFailure = (data) => {
    return {
        type: types.GET_DOSAGE_TYPE_FAILURE,
        data:data,
    }
}