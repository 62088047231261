import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/addProductAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* addProductSaga(action) {
  try {
    let response = yield call(NetworkService.addProduct, action);

    yield put(actions.addProductSuccess(response));
  } catch (err) {
    yield put(actions.addProductFailure(err));
  }
}

export function* watchAddProductSaga() {
  yield takeLatest(types.ADD_PRODUCT_REQUEST, addProductSaga);
}
