import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/programAndPlan"
import NetworkService from "../../../network/NetworkService";

export function* programAndPlanSaga(action) {
 

  try {
    let response = yield call(NetworkService.programAndPlan, action);
    yield put(actions.programAndPlanSuccess(response));
  } catch (err) {
    yield put(actions.programAndPlanFailure(err));
  }
}

export function* watchProgramAndPlanSaga() {
  yield takeLatest(types.PROGRAM_AND_PLAN_REQUEST, programAndPlanSaga);
}