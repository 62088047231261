import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { HospitalViewDoctorRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/HospitalViewDoctorDetails";
import Footer from "../../../Organisms/Footer/FooterUpper";
import { viewAppointmentsRequest } from "../../../../redux/Actions/Appointments/viewAppointmentAction";

function ViewAppointmentStatus() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAppointmentsRequest(id));
  }, [id]);

  const res = useSelector((state) => state.viewAppointment);
  
  const date = new Date(res?.data?.data?.data?.timeSlot).toLocaleString();

  return (
    <div>
      <div className="hospitalviewdoctormain">
        <h3 className="appId">{res?.data?.data?.data.customId} </h3>
        <div className="appointment-box">
          <div className="appointment-box-body">
            <div className="appointment-details-hospitaldash">
              <div className="GridVieWDoctorArrange">
                <div className="appointment-keys">
                  Appointed Doctor
                  <br />
                  <br />
                  <br />
                  Paid Status
                  <br />
                  <br />
                  <br />
                  Appointment Date & TIme
                  <br />
                  <br />
                  <br />

                  Appointment Status
                  <br />
                  <br />
                  <br />
                  Amount Collected
                  <br />
                  <br />
                  <br />
                </div>
                <div className="appointment-value">
                  {res?.data?.data?.data?.doctorName}
                  <br />
                  <br />
                  <br />
                  {res?.data?.data?.data?.paymentStatus === "PAID" ? (
                    <span className="AppointmentPaidStatus">
                      {res?.data?.data?.data?.appointmentStatus === "CONFIRMED"
                        ? "UPCOMING"
                        : res?.data?.data?.data?.paymentStatus}
                    </span>
                  ) : (
                    <span className="AppointmentPendingStatus">
                      {res?.data?.data?.data?.appointmentStatus === "CONFIRMED"
                        ? "UPCOMING"
                        : res?.data?.data?.data?.paymentStatus}
                    </span>
                  )}
                  <br />
                  <br />
                  <br />
                  {date}
                  <br />
                  <br />
                  <br />
                  {res?.data?.data?.data?.appointmentStatus}
                  <br />
                  <br />
                  <br />₹{res?.data?.data?.data?.amountCollected}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ViewAppointmentStatus;
