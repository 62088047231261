import * as types from "../../Config/actionType"

export const searchDoctorRequest = (data) => {
   
    return {
        type: types.SEARCH_DOCTOR_REQUEST,
        data:data
    }
}

export const searchDoctorSuccess = (data) => {
   

    return {
        type: types.SEARCH_DOCTOR_SUCCESS,
        data:data,
    }
}

export const searchDoctorFailure = (data) => {
   

    return {
        type: types.SEARCH_DOCTOR_FAILURE,
        data:data,
    }
}