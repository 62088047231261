import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import TypeABanner from '../../Organisms/Banner/BannerLIst/TypeABanner';
import TypeBBanner from '../../Organisms/Banner/BannerLIst/TypeBBanner';
import TypeCBanner from '../../Organisms/Banner/BannerLIst/TypeCBanner';
import "./Banner.css"
import { useDispatch, useSelector } from 'react-redux';
import AddBannerTypeC from '../../Organisms/Banner/AddBannerTypeC';
import { getAllBannerByTypeRequest } from '../../../redux/Actions/Banner/getAllBannerByTypeAction';
import Footer from '../../Organisms/Footer/FooterUpper';


function BannerListing() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [bannerLength, setBannerLength] = useState();

    useEffect(() => {
        if(location.state.bannerTypeEnum==="TYPE_C"){
            dispatch(getAllBannerByTypeRequest("TYPE_C", location.state.bannerPosition, location.state.screenTypeEnum))

        }
    }, [])

    const getAllBannerByType = useSelector((state) => state.banner.getAllBannerByType)
    
    useEffect(() => {
        if (getAllBannerByType.data?.type === "GET_ALL_BANNER_BY_TYPE_SUCCESS") {
            setBannerLength(getAllBannerByType.data?.data.innerBannerResponseDto.length)
        }
    }, [getAllBannerByType])

    const handleButton = () => {
        navigate("/addBanner", {
            state: {
                id: getAllBannerByType.data?.data.bannerId,
                bannerId: location.state.bannerId,
                bannerName: location.state.bannerName,
                bannerType: location.state.bannerType,
                bannerPosition: location.state.bannerPosition,
                screenType: location.state.screenType,
                bannerTypeEnum: location.state.bannerTypeEnum,
                screenTypeEnum: location.state.screenTypeEnum
            }
        })
    }


    return (
        <div>
            <div className="grid">
                <h4 className="h4">{location.state.bannerType} Banner</h4>
                <div className="mainBox">
                {location.state.bannerType.toLowerCase() === 'type c' && bannerLength === 0 ? <button onClick={handleButton} className="typecAddButton">+ Add Banner</button> : ""}
                    <div className="bannerHead">
                       

                        {location.state.bannerType.toLowerCase() === 'type a' || location.state.bannerType.toLowerCase() === 'type b' ? <div className="corporate-details">
                            <div className="corporate-keys">
                                Banner Screen

                                <br />
                                <br />
                                Banner Name
                                <br />
                                <br />
                                Position
                            </div>
                            <div className="banner-value" >
                                {location.state.screenType}
                                <br />
                                <br />
                                {location.state.bannerName}
                                <br />
                                <br />
                                <div className='bannerRow'>
                                    {location.state.bannerPosition}

                                    {location.state.bannerType.toLowerCase() === 'type a' || location.state.bannerType.toLowerCase() === 'type b' ? <button onClick={handleButton}>+ Add Banner</button> : ""}
                                </div>
                            </div>
                        </div> : <></>}

                    </div>
                    {location.state.bannerType.toLowerCase() === 'type a' &&
                        <TypeABanner
                            position={location.state.bannerPosition}
                            screenType={location.state.screenType}
                            screenTypeEnum={location.state.screenTypeEnum}
                            bannerName={location.state.bannerName}
                            bannerType={location.state.bannerType}
                            bannerTypeEnum={location.state.bannerTypeEnum}
                            bannerId={location.state.bannerId}
                        />}
                    {location.state.bannerType.toLowerCase() === 'type b' &&
                        <TypeBBanner
                            position={location.state.bannerPosition}
                            screenType={location.state.screenType}
                            screenTypeEnum={location.state.screenTypeEnum}
                            bannerName={location.state.bannerName}
                            bannerType={location.state.bannerType}
                            bannerTypeEnum={location.state.bannerTypeEnum}
                            bannerId={location.state.bannerId}
                        />}
                    {location.state.bannerType.toLowerCase() === 'type c' &&
                        <TypeCBanner
                            position={location.state.bannerPosition}
                            screenType={location.state.screenType}
                            screenTypeEnum={location.state.screenTypeEnum}
                            bannerName={location.state.bannerName}
                            bannerType={location.state.bannerType}
                            bannerTypeEnum={location.state.bannerTypeEnum}
                            bannerId={location.state.bannerId}
                        />}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BannerListing;