import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/getScreenTypesAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* getScreenTypes(action) {
  try {
    let response = yield call(NetworkService.getScreenTypes, action);
    yield put(actions.getScreenTypesSuccess(response));
  } catch (err) {
    yield put(actions.getScreenTypesFailure(err));
  }
}

export function* watchGetScreenTypesSaga() {
  yield takeEvery(types.GET_SCREEN_TYPES_REQUEST, getScreenTypes);
}