import * as types from "../../Config/Product/ProductActionType";

export const getRelatedProductRequest = (data) => {
  return {
    type: types.GET_RELATED_PRODUCT_REQUEST,
    data
  };
};

export const getRelatedProductSuccess = (data) => {
  return {
    type: types.GET_RELATED_PRODUCT_SUCCESS,
    data: data,
  };
};

export const getRelatedProductFailure = (data) => {
  return {
    type: types.GET_RELATED_PRODUCT_FAILURE,
    data: data,
  };
};
