import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/addHospitalAction";
import NetworkService from "../../../network/NetworkService";

export function* addHospitalSaga(action) {
  try {
     
    let response = yield call(NetworkService.addHospital, action);
    yield put(actions.addHospitalSuccess(response));
  } catch (err) {
    yield put(actions.addHospitalFailure(err));
  }
}

export function* watchAddHospitalSaga() {
  yield takeLatest(types.ADD_HOSPITAL_REQUEST, addHospitalSaga);
}