import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import { getLotByIdActionRequest } from "../../../redux/Actions/Payment/getLotByIdAction";
import { updatePaymentActionRequest } from "../../../redux/Actions/Payment/updatePaymentAction";
import "./ModalUpdatePayment.css";

function ModalUpdatePackage(props) {
  let hospitalName = "";
  let Location = "";
  let LOT = 0;
  let Consultation = 0;
  let amount = 0;
  const navigate = useNavigate();
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  const dispatch = useDispatch();
  const lotType = "APPOINTMENT";

  useEffect(() => {
    dispatch(
      getLotByIdActionRequest(
        props.data.lotid,
        props.page,
        props.pageSize,
        lotType
      )
    );
  }, []);
  const getLotById = useSelector((state) => state.getLotById);

  getLotById?.data?.data.data.data.lotResponseDtoList.map((item) => {
    if (props.data.id === item.id) {
      hospitalName = item.hospitalName;
      Location = item.hospitalAddress;
      LOT = item.lotId;
      Consultation = item.totalAppointment;
      amount = item.unPaidAmount;
    }
  });

  const loginSchema = Yup.object().shape({
    Discount: Yup.string().required("Required Field"),
    AmountPaid: Yup.string().required("Required Field"),
    PaymentRefNo: Yup.string().required(
      "Reference Number cannot be null/empty"
    ),
    TDS: Yup.string()
      .min(1, "TDS cannot be 0")
      .test("max", "TDS must be less than  AmountPaid", function (value) {
        const { AmountPaid } = this.parent;
        return (
          !AmountPaid || !value || parseFloat(value) < parseFloat(AmountPaid)
        );
      })
      .required("Required Field")
      .test(
        "sum",
        `The sum of TDS and AmountPaid has to be ${amount}`,
        function (value) {
          const { AmountPaid } = this.parent;
          const sum = parseFloat(AmountPaid) + parseFloat(value);
          return !AmountPaid || !value || sum === amount;
        }
      ),
  });
  const handleSubmit = (values) => {
    let data = {
      amount: values.AmountPaid,
      referenceNumber: values.PaymentRefNo,
      tds: values.TDS,
      lotId: props.data.lotid,
      discount: values.Discount,
      hospitalId: props.data.id,
    };
    dispatch(updatePaymentActionRequest(data));
  };
  const updatePayment = useSelector((state) => state.updatePayment);

  useEffect(() => {
    if (
      updatePayment?.error?.data.response.status === 400 &&
      updatePayment?.error?.data.response.data.message === "Enter valid amount"
    ) {
      swal({
        title: "Please enter a valid amount",
        icon: "error",
      });
    }
  }, [updatePayment]);

  useEffect(() => {
    if (
      updatePayment?.data?.data.status === 200 &&
      updatePayment?.data?.data.data.message === "Payment Successful"
    ) {
      swal({
        title: "Payment Successfull",
        icon: "success",
      }).then(() => {
        updatePayment.data.data.status = null;
        navigate("/payments");
      });
    }
  }, [updatePayment]);

  useEffect(() => {
    if (
      updatePayment?.error?.data.response.status === 400 &&
      updatePayment?.error?.data.response.data.tds ===
        "TDS cannot be negative/zero"
    ) {
      swal({
        title: "'TDS cannot be negative/zero'",
        icon: "error",
      });
    }
  }, [updatePayment]);
  useEffect(() => {
    if (
      updatePayment?.error?.data.response.status === 400 &&
      updatePayment?.error?.data.response.data.amount ===
        "Amount cannot be negative/zero"
    ) {
      swal({
        title: "Amount cannot be negative/zero",
        icon: "error",
      });
    }
  }, [updatePayment]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1new">
          <div className="modalHeader2">
            <div className="modalHeaderTextnew">
              <h1 className="HeaderUPPart1"> Update Payment - </h1>
              <h2 className="HeaderUPPart2"> Lot No : </h2>
              <h1 className="HeaderUPPart3">{LOT} </h1>
            </div>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              AmountPaid: "",
              TDS: "",
              PaymentRefNo: "",
              Discount: "",
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainernew">
                  <div className="paymentpreParent1">
                    <div className="paymentpre1">
                      {" "}
                      <p>
                        <span className="paymentField">Hospital Name -</span>{" "}
                        {hospitalName}
                      </p>
                    </div>
                    <div className="paymentpre2">
                      <p>
                        <span className="paymentField">Location -</span>{" "}
                        {Location}
                      </p>
                    </div>
                  </div>
                  <div className="paymentpreParent2">
                    <div className="paymentpre3">
                      <p>
                        <span className="paymentField">Amount -</span> {amount}
                      </p>
                    </div>
                    <div className="paymentpre4">
                      {" "}
                      <p>
                        <span className="paymentField">Consultations - </span>
                        {Consultation}
                      </p>
                    </div>
                  </div>

                  <div className="horizontalform">
                    <label>
                      <Field
                        type="number"
                        name="AmountPaid"
                        placeholder="Amount Paid"
                        className="inputFieldnew"
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidationPayment"
                        name="AmountPaid"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        type="text"
                        name="TDS"
                        placeholder="TDS"
                        className="inputFieldnew"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidationPayment"
                        name="TDS"
                        component="div"
                      />
                    </label>
                    <label>
                      <Field
                        type="text"
                        name="Discount"
                        placeholder="Discount"
                        className="inputFieldnew"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidationPayment"
                        name="Discount"
                        component="div"
                      />
                    </label>
                    <label style={{ width: "20em" }}>
                      <Field
                        type="text"
                        name="PaymentRefNo"
                        placeholder="Payment Ref No"
                        className="inputFieldnew "
                      />
                      <ErrorMessage
                        className="errorMessageModalValidationPayment"
                        name="PaymentRefNo"
                        component="div"
                      />
                    </label>
                  </div>
                  <div className="modalButtonPackagenewdiv">
                    <button
                      style={{ width: "20em" }}
                      type="submit"
                      className="modalButtonPackagenew"
                      onClick={handleSubmit}
                    >
                      Update Payment
                    </button>
                  </div>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdatePackage;
