import * as types from "../../Config/Product/ProductActionType";

export const getBrandDetailsRequest = (data) => {
  return {
    type: types.GET_BRAND_DETAILS_REQUEST,
    data
  };
};

export const getBrandDetailsSuccess = (data) => {
  return {
    type: types.GET_BRAND_DETAILS_SUCCESS,
    data: data,
  };
};

export const getBrandDetailsFailure = (data) => {
  return {
    type: types.GET_BRAND_DETAILS_FAILURE,
    data: data,
  };
};
