import React, { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import { Box } from "@mui/system";
import ModalAddTest from "../../Organisms/Modal/ModalAddTest";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import storage from "../../../Utils/storage";
import { viewAllTestRequest } from "../../../redux/Actions/Tests/viewAllTestAction";
import { viewTestRequest } from "../../../redux/Actions/Tests/viewTestAction";
import ModalUpdateTest from "../../Organisms/Modal/ModalUpdateTest";
import { roles } from "../../../helpers/MainConstants";
import { UserContext } from "../../../App";
import {mapToElasticRequest} from "../../../redux/Actions/Doctors/mapToElastic"
import Loader from "../../../assets/Loader/loader.gif";


function Test() {
  const role = useContext(UserContext);

  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(viewAllTestRequest(page, pageSize, searchText));
  }, [page]);

  const handleAdd = () => {
    setAdd(true);
  };
  const handleEdit = (value) => {
    dispatch(viewTestRequest(value?.id));
    setEdit(true);
  };

  const viewTest = useSelector((state) => state.viewTest);
  const handleClick = (id) => {
    navigate(`/viewTest/${id}`);
  };
  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(viewAllTestRequest(value, pageSize, searchText));
      setPage(value);
    } else {
      dispatch(viewAllTestRequest(value, pageSize, searchText));
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(viewAllTestRequest(page, event.target.value, searchText));
    } else {
      dispatch(viewAllTestRequest(searchPage, event.target.value, searchText));
    }
    setPageSize(event.target.value);
  };

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(viewAllTestRequest(page, pageSize, ""));
      setPage(1);
    } else {
      dispatch(viewAllTestRequest(searchPage, pageSize, newSearchText));
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const viewAllTest = useSelector((state) => state.viewAllTest);

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Test ID",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Test Name",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerClassName: "super-app-theme--header",
      headerName: "Price",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return <div>Rs {cellValues.row.cost}/-</div>;
      },
    },
    {
      field: "reportTime",
      headerClassName: "super-app-theme--header",
      headerName: "Report Time",
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues.row.reportTime > 24 && (
              <>
                {Math.floor(cellValues.row.reportTime / 24)}day{" "}
                {cellValues.row.reportTime % 24} hours{" "}
              </>
            )}
            {cellValues.row.reportTime < 24 && (
              <>{cellValues.row.reportTime % 24}hours</>
            )}
            {cellValues.row.reportTime === 24 && <> 1 day </>}
          </div>
        );
      },
    },
    {
      field: "Modify",
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              // onClick={() => handleEdit(cellValues.row)}

              onClick={() => handleClick(cellValues.row.id)}
            />
            {role === roles.SUPER_ADMIN && (
              <EditIcon
                sx={{ color: "#1D2334" }}
                onClick={() => handleEdit(cellValues.row)}
              />
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        );
      },
    },
  ];


  function handleSync(){
    dispatch(mapToElasticRequest());
  }

  const mapToElastic=useSelector((state)=>state.mapToElastic);

  return (
    <div>
      {edit && viewTest && <ModalUpdateTest value={viewTest} open={setEdit} />}
      <div className="hospgrid">
        <h4 className="h4">Tests</h4>
        <div className="mainBox">
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Tests</h5>
              <div className="hospitalcount">
                {viewAllTest.data?.data.data.totalTest} Tests
              </div>
            </div>
            <div className="rightheadbtn">
              {mapToElastic?.isLoading===true?
              <button
              type="button"
              className="btn addModalbtn"

            
              style={{width:"10%",height:"2.3em",paddingRight:"1em",paddingLeft:"1em",marginRight:"1em",display:"flex",alignItems:"center",justifyContent:"center",background:"#3C9C4B"}}
            >
              <img src={Loader} style={{width:"50%",height:"70%"}}/>
            </button>:<button
                // type="button"
                className="btn addModalbtn"
                onClick={handleSync}
                style={{width:"10%",height:"2.3em",paddingRight:"1em",paddingLeft:"1em",marginRight:"1em",display:"flex",alignItems:"center",justifyContent:"center"}}
              >
                Sync
              </button>}
            
              <div
                // type="button"
                className="btn addModalbtn"
                onClick={() => handleAdd()}
              >
                + Add Test
              </div>
            </div>

            {add && <ModalAddTest open={setAdd} />}
          </div>
          <hr />
          <div className="row2">
            <div className="city"></div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  className="searchField"
                  // onChange={(e) => setName(e.target.value)}
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                    fontFamily: "Nunito",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
                <form
                  onSubmit={(e) => {
                    handleSearch(e);
                  }}
                >
                  <button
                    type="submit"
                    value="Submit"
                    className="hospitalsearchBtn"
                    style={{
                      backgroundColor: "#52608E",
                      width: "150px",
                      align: "end",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Search
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <hr />
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewAllTest.data?.data.data.testResponseDtoList && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  fontFamily: "Nunito",
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                rowCount={viewAllTest.data?.data.data.totalTest}
                pagination
                disableColumnMenu
                rows={viewAllTest.data?.data.data.testResponseDtoList}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                // onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // onSelectionModelChange={(itm) => handleClick(itm)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          <div className="pagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewAllTest.data?.data.data.totalPages}      
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Test;
