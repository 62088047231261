import React, { useEffect, useState } from "react";
// import "./ModalAddPackage.css";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCouponRequest } from "../../../redux/Actions/Coupon/AddCouponAction";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { modalHeader } from "./ModalConstants";
// import swal from "sweetalert";
import Swal from "sweetalert2";
const regex = /^[a-zA-Z0-9]*$/;
const loginSchema = Yup.object().shape({
  CouponName: Yup.string().required("Required Field"),
  CouponCode: Yup.string()
    .required("Required Field")
    .max(8, "Must be exactly 8 characters")
    .matches(regex, "Coupon Code should not contain special characters"),
  // DiscountAmount: Yup.string().required("Required field"),
  // DiscountPercentage: Yup.string().required("Required field"),
  UsagePerCoupon: Yup.string().required("Required Field"),
  UsagePerCustomers: Yup.string().required("Required Field"),
  MinimumAmount: Yup.string().required("Required Field"),
  // MaximumAmount: Yup.string().required("Required Field"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalAddCoupon(props) {
  const [description, setDescription] = useState("");
  // const [serviceIdSubmit, setServiceIdSubmit] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState(null);
  const [DAC, setDAC] = useState(false);
  const [AFLC, setAFLC] = useState(false);
  // const services=[{name:"Diagnostic Tests"},{name:"Health Checkup Packages"},{name:"Health Plans"}];
  const [MaximumAmountValue, SetMaximumAmountValue] = useState();
  const [DiscountAmountValue, SetDiscountAmountValue] = useState();
  const [DiscountPercentageValue, SetDiscountPercentageValue] = useState();
  const services = [
    { name: "Test" },
    { name: "Package" },
    { name: "Plan" },
    { name: "Product" },

  ];

  const [serviceIdSubmit, setServiceIdSubmit] = useState([]);
  const [selectedServices, setSelectedServices] = useState({
    Test: false,
    Plan: false,
    Package: false,
    product: false,

  });

  var startDateConverted = new Date(startDate);
  startDateConverted = startDateConverted.getTime();

  var endDateConverted = new Date(endDate);
  endDateConverted = endDateConverted.getTime() + 86399000;

  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setServiceIdSubmit(event.target.value);

    const selected = {
      Test: false,
      Plan: false,
      Package: false,
      Product:false,
    };

    if (event.target.value.includes("Test")) {
      selected.Test = true;
    }

    if (event.target.value.includes("Plan")) {
      selected.Plan = true;
    }

    if (event.target.value.includes("Package")) {
      selected.Package = true;
    }
    if (event.target.value.includes("Product")) {
      selected.Product = true;
    }

    setSelectedServices(selected);
    // setServiceIdSubmit(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
  };

  // const selected = {
  //   Test: false,
  //   Plan: false,
  //   Package: false,
  // };

  const updateRes = useSelector((state) => state.AddCoupon);

  const [flag, setFlag] = useState(true);
  useEffect(() => {
    if (updateRes?.data || updateRes?.error) {
      setFlag(true);
    }
  }, [updateRes]);

  const handleSubmit = (values, { setSubmitting }) => {
    if (description === "") {
      Swal.fire({
        title: "Please Add  Description",
        icon: "error",
      });
    } else if (isNaN(startDateConverted) === true) {
      Swal.fire({
        title: "Please Add  Start Date",
        icon: "error",
      });
    } else if (isNaN(endDateConverted) === true) {
      Swal.fire({
        title: "Please Add  End Date",
        icon: "error",
      });
    } else {
    setFlag(false);

      let data = {
        CouponName: values.CouponName,
        CouponCode: values.CouponCode,
        DiscountAmount:
          values.DiscountAmount === "" ? null : parseInt(values.DiscountAmount),
        DiscountPercentage:
          values.DiscountPercentage === ""
            ? null
            : parseInt(values.DiscountPercentage),
        MaximumAmount:
          values.MaximumAmount > 0 ? parseInt(values.MaximumAmount) : null,
        MinimumAmount: values.MinimumAmount,
        UsagePerCoupon: values.UsagePerCoupon,
        UsagePerCustomers: values.UsagePerCustomers,
        description: description,
        DAC: DAC,
        AFLC: AFLC,
        startDate: startDateConverted,
        endDate: endDateConverted,
        strValue: selectedServices,
      };

      dispatch(AddCouponRequest(data));
    }
  };

  const AddCoupon = useSelector((state) => state.AddCoupon);
  const makePackagereducer = useSelector((state) => state.makePackage);

  useEffect(() => {
    if (
      AddCoupon?.data?.data.status === 201 &&
      AddCoupon?.data?.data.data.message !== ""
    ) {
      Swal.fire({
        title: "Success",
        text: `${AddCoupon?.data?.data.data.message}`,
        icon: "success",
        zIndex: 9999,
      }).then(() => {
        handleClose();
      });
    }

    if (
      (AddCoupon.data?.data.status !== 201 && AddCoupon.data === undefined) ||
      AddCoupon.error !== null
    ) {
      Swal.fire({
        title: "" + Object.values(AddCoupon?.error?.data?.response?.data)[0],
        icon: "error",
        zIndex: 9999,
      }).then(() => {
        props.open(true);
      });
    }
  }, [AddCoupon]);

  const handleChecked1 = (e) => {
    if (e.target.value === true) {
      setDAC(!DAC);
    } else {
      setDAC(!DAC);
    }
  };

  const handleChecked2 = (e) => {
    if (e.target.value === true) {
      setAFLC(!AFLC);
    } else {
      setAFLC(!AFLC);
    }
  };

  const HandleDiscountAmount = (e) => {
    SetDiscountAmountValue(e.target.value);
    SetDiscountPercentageValue("");
    SetMaximumAmountValue("");
  };

  const HandleDiscountPercentage = (e) => {
    SetDiscountPercentageValue(e.target.value);
    SetDiscountAmountValue("");
  };

  const HandleMaximumDiscount = (e) => {
    SetMaximumAmountValue(e.target.value);
    SetDiscountAmountValue("");
  };

  useEffect(() => {
    // if (DiscountAmountValue > 0) {
    //   document.getElementById("inputfieldmaxamount").disabled = true;
    //   document.getElementById("inputfieldmaxamount").style.backgroundColor =
    //     "#748C97";
    //   document.getElementById("inputfieldmaxamount").style.color = "black";
    // }
    if (DiscountAmountValue > 0) {
      document.getElementById("inputFieldPercentage").disabled = true;

      document.getElementById("inputFieldPercentage").style.backgroundColor =
        "#748C97";
      document.getElementById("inputFieldPercentage").style.color = "black";

      document.getElementById("inputfieldmaxamount").disabled = true;

      document.getElementById("inputfieldmaxamount").style.backgroundColor =
        "#748C97";
      document.getElementById("inputfieldmaxamount").style.color = "black";
    } else {
      // document.getElementById("inputFieldPercentage").disabled = false;
      // document.getElementById("inputFieldPercentage").style.backgroundColor =
      //   "#fff";
      // document.getElementById("inputFieldPercentage").style.color = "black";
    }

    if (DiscountPercentageValue > 0) {
      document.getElementById("inputFieldAmount").disabled = true;
      document.getElementById("inputFieldAmount").style.backgroundColor =
        "#748C97";
      document.getElementById("inputFieldAmount").style.color = "black";
    }

    if (DiscountAmountValue === "") {
      document.getElementById("inputFieldPercentage").disabled = false;
      document.getElementById("inputFieldPercentage").style.backgroundColor =
        "white";
      document.getElementById("inputfieldmaxamount").disabled = false;

      document.getElementById("inputfieldmaxamount").style.backgroundColor =
        "#fff";
      document.getElementById("inputfieldmaxamount").style.color = "black";
    }
    if (DiscountPercentageValue === "") {
      document.getElementById("inputFieldAmount").disabled = false;
      document.getElementById("inputFieldAmount").style.backgroundColor =
        "white";
    }

    // if(DiscountAmountValue==="" ){
    //   document.getElementById("inputfieldmaxamount").disabled=false;
    //   document.getElementById("inputfieldmaxamount").style.backgroundColor="white";

    // }
  }, [DiscountPercentageValue, DiscountAmountValue]);

  const handleKeyPress = (e) => {
    if (e.charCode === 32) {
      e.preventDefault();
    }
  };
  const onInputChange = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Add Coupon
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              CouponName: "",
              CouponCode: "",
              DiscountAmount: "",
              DiscountPercentage: "",
              UsagePerCoupon: "",
              UsagePerCustomers: "",
              MinimumAmount: "",
              MaximumAmount: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="text"
                      name="CouponName"
                      placeholder="Coupon Name"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="CouponName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="text"
                      name="CouponCode"
                      onKeyPress={handleKeyPress}
                      placeholder="Coupon Code"
                      className="inputField"
                      style={{ textTransform: "uppercase" }}
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="CouponCode"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="MinimumAmount"
                      placeholder="Minimum Amount"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="MinimumAmount"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="DiscountAmount"
                      placeholder="Discount Amount"
                      value={DiscountAmountValue}
                      className="inputField"
                      id="inputFieldAmount"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                      onChange={(e) => {
                        HandleDiscountAmount(e);
                        setFieldValue("DiscountAmount", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="DiscountAmount"
                      component="div"
                    />
                  </label>
                  <div className="seperaterCoupenC">
                    <div className="seperaterCoupenC1"></div>
                    <p>or</p>
                    <div className="seperaterCoupenC1"></div>
                  </div>
                  <label>
                    <Field
                      type="number"
                      name="DiscountPercentage"
                      placeholder="Discount Percentage"
                      className="inputField "
                      id="inputFieldPercentage"
                      value={DiscountPercentageValue}
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                      onChange={(e) => {
                        HandleDiscountPercentage(e);
                        setFieldValue("DiscountPercentage", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="DiscountPercentage"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="MaximumAmount"
                      placeholder="Maximum Amount"
                      value={MaximumAmountValue}
                      id="inputfieldmaxamount"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                      disabled={DiscountAmountValue !== "" ? true : false}
                      onChange={(e) => {
                        HandleMaximumDiscount(e);
                        setFieldValue("MaximumAmount", e.target.value);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="MaximumAmount"
                      component="div"
                    />
                  </label>
                  <label>
                    <FormControl
                      sx={{
                        width: "40%",
                        marginBottom: "2.9rem",
                        borderRadius: "12px",
                        border: "hidden",
                        color: "#ffffff",
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                      Select Product Type
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={serviceIdSubmit}
                        onChange={handleChange}
                        input={<OutlinedInput label="   Select Product Type" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {services.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            <Checkbox
                              checked={serviceIdSubmit.indexOf(item.name) > -1}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </label>
                  {/* <label>
                    <FormControl
                      sx={{
                        // m:1,
                        width: "40%",
                        marginBottom: "2.9rem",
                        borderRadius: "12px",
                        border: "hidden",
                        color: "#ffffff",
                      }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label ">
                        Service Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={serviceIdSubmit}
                        onChange={handleChange}
                        input={<OutlinedInput label=" Service Category" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {services.map((item) => (
                          <MenuItem key={item.id} value={item.name}>
                            <Checkbox
                              checked={serviceIdSubmit.indexOf(item.name) > -1}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </label> */}

                  <label>
                    <Field
                      type="number"
                      name="UsagePerCoupon"
                      placeholder="Usage Per Coupon"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="UsagePerCoupon"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="UsagePerCustomers"
                      placeholder="Usage Per Customers"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onInput={(event) => {
                        event.target.value = event.target.value.replace(
                          /e/gi,
                          ""
                        );
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="UsagePerCustomers"
                      component="div"
                    />
                  </label>
                  <label className="featuringCheck">
                    <Field
                      type="checkbox"
                      className="featuringCheckField"
                      onChange={handleChecked1}
                      checked={DAC}
                    />
                    <span>Display at Checkout</span>
                  </label>
                  <label className="featuringCheck">
                    <Field
                      type="checkbox"
                      className="featuringCheckField"
                      onChange={handleChecked2}
                      checked={AFLC}
                    />
                    <span>Allow for Login Customer</span>
                  </label>
                  <label>
                    <textarea
                      placeholder="Add Description"
                      rows={5}
                      onChange={(e) => setDescription(e.target.value)}
                      className="CouponDescription"
                    />
                  </label>
                  <label className="DatePickerCoupon">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            style={{ width: "40%" }}
                            inputProps={{ onKeyDown: onInputChange }}
                            value={
                              startDate ? startDate.toLocaleDateString() : ""
                            }
                          />
                        )}
                        label="start date"
                        value={startDate}
                        onChange={(newValue) => {
                          setstartDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </label>
                  <label className="DatePickerCoupon">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={
                          new Date(startDate).getTime() + 24 * 60 * 60 * 1000
                        }
                        maxDate={
                          new Date(startDate).getTime() +
                          365 * 24 * 60 * 60 * 1000
                        }
                        inputFormat="dd/MM/yyyy"
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            style={{ width: "40%" }}
                            inputProps={{ onKeyDown: onInputChange }}
                            value={endDate ? endDate.toLocaleDateString() : ""}
                          />
                        )}
                        label="end date"
                        value={endDate}
                        onChange={(newValue) => {
                          setendDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </label>
                  <button
                    disabled={!flag}
                    type="submit"
                    className="modalButtonPackage modalCouponButton"
                  >
                    {flag ? "       Add Coupon" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddCoupon;
