import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/viewServiceAction2";
import NetworkService from "../../../network/NetworkService";

export function* viewServiceSaga2(action) {
  try {
    let response = yield call(NetworkService.viewService2, action);
    yield put(actions.viewServiceSuccess2(response));
  } catch (err) {
    yield put(actions.viewServiceFailure2(err));
  }
}

export function* watchViewServiceSaga2() {
  yield takeLatest(types.VIEW_SERVICE_REQUEST2, viewServiceSaga2);
}