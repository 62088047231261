import React, { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import "./Corporate.css";
import IconButton from "@mui/material/IconButton";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { viewAllCorporateRequest } from "../../../redux/Actions/Corporate/viewAllCorporateAction";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { useDebouncedCallback } from "use-debounce";
import ModalUpdateCorporate from "../../Organisms/Modal/ModalUpdateCorporate";
import ModalAddCorporate from "../../Organisms/Modal/ModalAddCorporate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { getUpdateCorporateRequest } from "../../../redux/Actions/Corporate/getUpdateCorporateAction";
import storage from "../../../Utils/storage";
import DisableCorporateModal from "../../Organisms/Modal/DisableCorporateModal";
import Switch from "@mui/material/Switch";
import { roles } from "../../../helpers/MainConstants";
import ActiveDropdown from "../../Organisms/ActiveDropdown/ActiveDropdown";
import { UserContext } from "../../../App";

function Corporate() {
  const role = useContext(UserContext);


  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState();
  const [value, setValue] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [name, setName] = React.useState("");
  const [delId, setDelId] = useState();
  const [delOpen, setdelOpen] = React.useState(false);
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [status, setStatus] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (city === null) {
      dispatch(viewAllCorporateRequest(page, pageSize, searchText, "", status));
    } else {
      dispatch(
        viewAllCorporateRequest(page, pageSize, searchText, city, status)
      );
    }
    dispatch(viewAllCityRequest());
  }, [page, pageSize, city, status]);

  const viewCorporate = useSelector((state) => state.viewAllCorporate);
  const viewCity = useSelector((state) => state.viewAllCity);
  const getUpdateCorporateData = useSelector(
    (state) => state.getUpdateCorporate.data
  );

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleDelete = (id) => {
    setDelId(id);
    setdelOpen(true);
  };

  const handleClick = (id) => {
    if (window.location.pathname === "/insurer/corporate") {
      storage.setStorage("corporateId", id);
      navigate(`/insurerViewCorporateDetails/${id}`, { id: id });
    } else {
      navigate(`/viewCorporateDetails/${id}`);
    }
  };

  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length <= 1) {
      dispatch(viewAllCorporateRequest(page, pageSize, "", city, status));
      setPage(1);
    } else {
      dispatch(
        viewAllCorporateRequest(searchPage, pageSize, newSearchText, city, status)
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleEdit = (value) => {
    // //
    dispatch(getUpdateCorporateRequest(value?.id));
    setValues(value);
    setOpen(true);
    setEdit(1);
  };

  const handleAdd = () => {
    setAdd(true);
  };

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllCorporateRequest(value, pageSize, searchText, city, status)
      );
      setPage(value);
    } else {
      dispatch(
        viewAllCorporateRequest(value, pageSize, searchText, city, status)
      );
      setSearchPage(value);
      setPage(1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllCorporateRequest(page, e.target.value, searchText, city, status)
      );
    } else {
      dispatch(
        viewAllCorporateRequest(
          searchPage,
          e.target.value,
          searchText,
          city,
          status
        )
      );
    }
    setPageSize(e.target.value);
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllCorporateRequest(
          page,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    } else {
      dispatch(
        viewAllCorporateRequest(
          searchPage,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    }
    setStatus(e.target.value);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: " Address",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "contactPersonName",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Person",
      flex: 1.1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "contactPersonEmail",
      headerClassName: "super-app-theme--header",
      headerName: " Email",
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "contactPersonNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "Modify",
      flex: 1.3,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
            {role === roles.CUSTOMER_SERVICE_AGENT ? (
              <></>
            ) : (
              <>
                {viewCorporate.data?.data?.data?.corporateResponseDtos[
                  cellValues.api.getRowIndex(cellValues.row.id)
                ]?.active === false ? (
                  <IconButton
                    sx={{ pointerEvents: "none", cursor: "not-allowed" }}
                  >
                    <EditIcon
                      sx={{ color: "#1D2334" }}
                      onClick={() => handleEdit(cellValues.row)}
                    />
                  </IconButton>
                ) : (
                  <IconButton>
                    <EditIcon
                      sx={{ color: "#1D2334" }}
                      onClick={() => handleEdit(cellValues.row)}
                    />
                  </IconButton>
                )}
              </>
            )}

            <Box>
              {role === roles.SUPER_ADMIN && (
                <>
                  {viewCorporate.data?.data?.data?.corporateResponseDtos[
                    cellValues.api.getRowIndex(cellValues.row.id)
                  ]?.active === false ? (
                    <div>
                      <Switch disabled sx={{ color: "#52608E" }} />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Switch
                        defaultChecked
                        sx={{ color: "#52608E" }}
                        onClick={() => handleDelete(cellValues.row.id)}
                      />{" "}
                    </div>
                  )}
                </>
              )}
            </Box>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="grid">
        {edit && getUpdateCorporateData && (
          <ModalUpdateCorporate value={getUpdateCorporateData} open={setEdit} />
        )}
        <h4 className="h4">Corporate</h4>
        <div className="mainBox">
          {delOpen && (
            <DisableCorporateModal delOpen={setdelOpen} delId={delId} />
          )}

          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Corporate</h5>
              <div className="corporatecount">
                {viewCorporate.data?.data.data.totalCorporates} Corporate
              </div>
            </div>
            <div className="rightheadbtn">
              <div type="button" className="btn" onClick={() => handleAdd()}>
                + Add Corporate
              </div>
            </div>

            {add && <ModalAddCorporate open={setAdd} />}
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities"
                cityList={cityNames}
              />
              <ActiveDropdown onChange={(e) => handleStatus(e)} />
            </div>
            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewCorporate.data?.data.data.corporateResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rowCount={viewCorporate.data?.data.data.totalCorporates}
                rows={viewCorporate.data?.data.data.corporateResponseDtos}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewCorporate.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Corporate;
