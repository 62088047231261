import { CleaningServices } from "@mui/icons-material";
import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css";
// import { BarChart } from '@mui/icons-material';

function BMI_DonutChart({ bmiResponseDto }) {
 

  let obese =
    bmiResponseDto?.extremelyObese +
    bmiResponseDto?.moderatelyObese +
    bmiResponseDto?.severelyObese;

  let underWeight =
    bmiResponseDto?.extremelyUnderWeight +
    bmiResponseDto?.moderatelyUnderWeight +
    bmiResponseDto?.severelyUnderWeight;
  return (
    <React.Fragment>
      <div className="container-fluid mb-3">
        <Chart
          type="donut"
          //   width={549}
          //   height={560}
          series={[
            obese,
            bmiResponseDto?.overWeight,
            bmiResponseDto?.normal,
            underWeight,
          ]}
          options={{
            labels: ["Obese", "Over Weight", "Normal", "Under Weight"],
            colors: ["#A53F2B", "#E68D36", "#44576A", "#1D2334"],

            chart: {
              type: "donut",
            },
            fill: {
              colors: ["#A53F2B", "#E68D36", "#44576A", "#1D2334"],
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,

                    // Zindex:""
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
            plugins:{
              legend:{
                  labels:{
                      usePointStyle:true,
                      pointStyle:"sqaure"
                  }
              }
          },
            dataLabels: {
              enabled: false,
              colors: ["#A53F2B", "#E68D36", "#44576A", "#1D2334"],
            },
            plotOPtions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                  },
                },
              },
            },
          }}
        />
        <div className="BMI_Donut_OuterContainer">
          <div>
            <div className="BMI_ObeseHeading">Number of Obese Employee</div>
            <div className="BMI_ObeseSubHeading">{obese}</div>
          </div>
          <div>
            <div className="BMI_ObeseHeading">
              Number of Underweight Employee
            </div>
            <div className="BMI_ObeseSubHeading">{underWeight}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BMI_DonutChart;
