import * as types from "../../Config/Banner/BannerActionType"

export const addBannerDetailRequest = (formData) => {
    return {
        type: types.ADD_BANNER_DETAIL_REQUEST,
        formData
    }
}

export const addBannerDetailSuccess = (data) => {
    return {
        type: types.ADD_BANNER_DETAIL_SUCCESS,
        data:data.data
    }
}

export const addBannerDetailFailure = (data) => {
    return {
        type: types.ADD_BANNER_DETAIL_FAILURE,
        data:data
    }
}