import * as types from "../../Config/Product/ProductActionType";

export const getAllCategoryRequest = (pageNo, pageSize, search) => {
  
  return {
    type: types.GET_ALL_CATEGORY_REQUEST,
    pageNo,
    pageSize,
    search,
  };
};

export const getAllCategorySuccess = (data) => {
  return {
    type: types.GET_ALL_CATEGORY_SUCCESS,
    data: data,
  };
};

export const getAllCategoryFailure = (data) => {
  return {
    type: types.GET_ALL_CATEGORY_FAILURE,
    data: data,
  };
};
