import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/LifeStyle/lifeStyleActionType";
import * as actions from "../../Actions/LifeStyle/UpdateLifeStyleAction";
import NetworkService from "../../../network/LifeStyle/viewAllLifeStyleService";

export function* UpdateLifeStyleSaga(action) {
  try {
    let response = yield call(NetworkService.UpdateLifeStyle, action);
    yield put(actions.UpdateLifeStyleSuccess(response));
  } catch (err) {
    yield put(actions.UpdateLifeStyleFailure(err));
  }
}

export function* watchUpdateLifeStyleSaga() {
  yield takeLatest(types.UPDATE_LIFESTYLE_REQUEST, UpdateLifeStyleSaga);
}