import * as types from "../../Config/actionType";

export const ViewOrderRequest = (data) => {
  return {
    type: types.VIEW_ORDER_REQUEST,
    data: data,
  };
};

export const ViewOrderSuccess = (data) => {
  return {
    type: types.VIEW_ORDER_SUCCESS,
    data: data,
  };
};

export const ViewOrderFailure = (data) => {
  return {
    type: types.VIEW_ORDER_FAILURE,
    data,
  };
};
