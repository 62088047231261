import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useDebouncedCallback } from "use-debounce";
import { downloadPrescriptionPdfRequest } from "../../../redux/Actions/InHouseDoctor/downloadPrescriptionPdfAction";
import { listOfConsultationsRequest } from "../../../redux/Actions/InHouseDoctor/listOfConsultationsAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalCreateTalkToDoctor from "../../Organisms/Modal/ModalCreateTalkToDoctor";
import "./ConsultationListing.css";

function ConsultationListing() {
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [showPdf, setShowPdf] = React.useState(true);
  const [add, setAdd] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listOfConsultationsRequest(page, pageSize, ""));
  }, [page, pageSize]);

  const listOfConsultations = useSelector((state) => state.listOfConsultations);



  function handlePrint(id, identifier) {
    dispatch(downloadPrescriptionPdfRequest({ id, identifier }));
  }
  const downloadPrescriptionPdf = useSelector(
    (state) => state.downloadPrescriptionPdf
  );

  if (
    downloadPrescriptionPdf?.data?.data.status === 200 &&
    downloadPrescriptionPdf?.data?.data.data !== ""
  ) {
    swal({
      title: "Download File",
      icon: "info",
    }).then(() => {
      const url_1 = window.URL.createObjectURL(
        new Blob([downloadPrescriptionPdf?.data?.data.data], {
          type: "application/octet-stream",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      link.setAttribute("download", "Report.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Consultation Id",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      //   marginLeft:4
    },
    {
      field: "customerName",
      headerClassName: "super-app-theme--header",
      headerName: "Customer Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.7,
    },
  
    {
        field: "requestType",
        headerClassName: "super-app-theme--header",
        headerName: "Consultation Type ",
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        align: "center",
        headerAlign: "center",
        },
        {
        field: "doctorName",
        headerClassName: "super-app-theme--header",
        headerName: "Name of Doctor",
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        align: "center",
        headerAlign: "center",
        },

    {
      field: "chatStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            {params.row["chatStatus"] === "CANCELLED" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
            {params.row["chatStatus"] === "FINISHED" && (
              <Typography>
                <Chip label="FINISHED" color="primary" />
              </Typography>
            )}
            {params.row["chatStatus"] === "COMPLETED" && (
              <Typography>
                <Chip label="COMPLETED" color="success" />
              </Typography>
            )}
            {params.row["chatStatus"] === "CONFIRMED" && (
              <Typography>
                <Chip label="CONFIRMED" color="info" />
              </Typography>
            )}
            {params.row["chatStatus"] === "INITIATED" && (
              <Typography>
                <Chip label="INITIATED" color="secondary" />
              </Typography>
            )}
            {params.row["chatStatus"] === "RESCHEDULED" && (
              <Typography>
                <Chip label="RESCHEDULED" color="warning" />
              </Typography>
            )}
            {params.row["chatStatus"] === "IN_PROGRESS" && (
              <Typography>
                <Chip label="INPROGRESS" color="warning" />
              </Typography>
            )}
            {params.row["chatStatus"] === "INCOMPLETE" && (
              <Typography>
                <Chip label="INCOMPLETE" color="error" />
              </Typography>
            )}
          </>
        );
      },
    },

   
   
  ];
  const debouncedOnChanged = useDebouncedCallback((value) => {
    if (value.length === 1) {
      dispatch(listOfConsultationsRequest(page, pageSize, value));
    } else {
      dispatch(listOfConsultationsRequest(page, pageSize, value));
    }
  }, 2000);
  const handleSearch = (e) => {
    debouncedOnChanged(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleAdd = () => {
    setAdd(true);
  };

  return (
    <>
      <div className="LOCMain">
        <div className="LOCHeader">
          <div className="LOCHeaderChild">
            <p>No of Consultations Booked</p>
            <p>NA</p>
          </div>
          <div className="LOCHeaderChild">
            <p>No of Consultations Completed</p>
            <p>NA</p>
          </div>
          <div className="LOCHeaderChild">
            <p>No of Consultations Cancelled</p>
            <p>NA</p>
          </div>
        </div>
        {add && <ModalCreateTalkToDoctor open={setAdd} />}
        <div className="LOCTable">
          <p className="LOCTableHeader">List of Consultations</p>
          <div className="searchSectionTalk">
            <div className="ConsultationButton">
              
              <div className="rightheadbtn">
                <button
                  type="button"
                  className="btn addModalbtn"
                  onClick={() => handleAdd()}
                >
                  + Create Consultation
                </button>
              </div>
            </div>
            <div className="searchBarTalk">
              <TextField
                type="text"
                name="search"
                className="searchField"
                onChange={(e) => handleSearch(e)}
                variant="outlined"
                sx={{
                  border: "1px solid #52608E",
                  borderRadius: "6px",
                  width: "350px",
                  fontFamily: "Nunito",
                }}
                placeholder="Search"
                size="small"
                hiddenLabel="true"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#52608E" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="MainTableStarts">
            {" "}
            <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
              {listOfConsultations?.data?.data.data
                .talkToDoctorResponseDtos && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      fontFamily: "Nunito",
                      fontWeight: 600,
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    fontFamily: "Nunito",
                    "&:hover:not(.Mui-disabled)": {
                      cursor: "pointer",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  pagination
                  rowCount={10}
                  rows={
                    listOfConsultations?.data?.data.data
                      .talkToDoctorResponseDtos
                  }
                  columns={columns}
                  rowsPerPageOptions={[10, 50, 100]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  components={{
                    Footer: "none",
                  }}
                />
              )}
            </Box>
            <div className="MainTablePagination">
              <p>Rows Per Page:</p>
              <select
                className="dropDown"
                defaultValue={10}
                style={{ backgroundColor: "#ffffff" }}
                onChange={handleDropChange}
              >
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <Pagination
                count={listOfConsultations?.data?.data.data.totalPages}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ConsultationListing;
