import * as types from "../../Config/actionType";

export const getUpdateHospitalRequest = (data) => {
 
  return {
    type: types.GET_UPDATE_HOSPITAL_REQUEST,
    data
  };
};

export const getUpdateHospitalSuccess = (data) => {
 
  return {
    type: types.GET_UPDATE_HOSPITAL_SUCCESS,
    data: data,
  };
};

export const getUpdateHospitalFailure = (data) => {
 
  return {
    type: types.GET_UPDATE_HOSPITAL_FAILURE,
    data: data,
  };
};
