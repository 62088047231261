import * as types from "../../Config/Banner/BannerActionType"

export const getBannerByScreenTypesRequest = (data) => {
    return {
        type: types.GET_BANNER_BY_SCREEN_TYPES_REQUEST,
        data:data
    }
}

export const getBannerByScreenTypesSuccess = (data) => {
    return {
        type: types.GET_BANNER_BY_SCREEN_TYPES_SUCCESS,
        data:data
    }
}

export const getBannerByScreenTypesFailure = (data) => {
    return {
        type: types.GET_BANNER_BY_SCREEN_TYPES_FAILURE,
        data:data
    }
}