import * as types from "../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const deleteDoctorReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.DELETE_DOCTOR_SUCCESS:
           
            return {
                ...initialState,
                data: action.action,
            }
        case types.DELETE_DOCTOR_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.DELETE_DOCTOR_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}