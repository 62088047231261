import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/rescheduleAppointmentAction"
import NetworkService from "../../../network/NetworkService";

export function* rescheduleAppointmentSaga(action) {
  try {
    let response = yield call(NetworkService.rescheduleAppointment, action);
    yield put(actions.rescheduleAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.rescheduleAppointmentFailure(err));
  }
}

export function* watchRescheduleAppointmentSaga() {
  yield takeLatest(types.RESCHEDULE_APPOINTMENT_REQUEST, rescheduleAppointmentSaga);
}