import * as types from "../../Config/actionType"

export const corporateGetAssignProgramRequest = (data) => {
   
    return {
        type: types.CORPORATE_GET_ASSIGN_PROGRAM_REQUEST,
        data:data
    }
}

export const corporateGetAssignProgramSuccess = (data) => {
    return {
        type: types.CORPORATE_GET_ASSIGN_PROGRAM_SUCCESS,
        data:data,
    }
}

export const corporateGetAssignProgramFailure = (data) => {
    return {
        type: types.CORPORATE_GET_ASSIGN_PROGRAM_FAILURE,
        data:data,
    }
}