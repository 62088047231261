import * as types from "../../Config/actionType"

export const rescheduleAppointmentRequest = (data,timeSlot) => {
    return {
        type: types.RESCHEDULE_APPOINTMENT_REQUEST,
        data,
        timeSlot
    }
}

export const rescheduleAppointmentSuccess = (data) => {
    return {
        type: types.RESCHEDULE_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const rescheduleAppointmentFailure = (data) => {
    return {
        type: types.RESCHEDULE_APPOINTMENT_FAILURE,
        data:data,
    }
}