import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/cancelBookingAction"
import NetworkService from "../../../network/NetworkService";

export function* cancelBookingSaga(action) {
  try {
    let response = yield call(NetworkService.cancelBooking, action);
    yield put(actions.cancelBookingSuccess(response));
  } catch (err) {
    yield put(actions.cancelBookingFailure(err));
  }
}

export function* watchCancelBookingSaga() {
  yield takeLatest(types.CANCEL_BOOKING_REQUEST, cancelBookingSaga);
}