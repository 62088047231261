import * as types from "../../Config/actionType"

export const updatePackageRequest = (data) => {
    return {
        type: types.UPDATE_PACKAGE_REQUEST,
        data:data
    }
}

export const updatePackageSuccess = (data) => {
    return {
        type: types.UPDATE_PACKAGE_SUCCESS,
        data:data,
    }
}

export const updatePackageFailure = (data) => {
    return {
        type: types.UPDATE_PACKAGE_FAILURE,
        data:data,
    }
}