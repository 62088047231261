import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";
import Accounts from "../../component/Pages/Accounts/Accounts";
import ViewOrderDetails from "../../component/Pages/Accounts/ViewOrderDetails";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";
import CreateLot from "../../component/Pages/Payment/CreateLot";
import LotStatus from "../../component/Pages/Payment/LotStatus";
import Payment from "../../component/Pages/Payment/Payment";
import UpdatePayment from "../../component/Pages/Payment/UpdatePayment";
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import PhamacyListing from "../../component/Pages/PharmacyAccount/PharmacyListing";
import CreatingLotPharmacy from "../../component/Pages/PharmacyAccount/CreatingLotPharmacy";
import UpdatePaymentPharmacyListing from "../../component/Pages/PharmacyAccount/UpdatePaymentPharmacyListing";
import UpdatingPaymentStatus from "../../component/Pages/PharmacyAccount/UpdatingPaymentStatus";
import ViewOrderDetailsRefundable from "../../component/Pages/Accounts/ViewOrderDetailsRefundable";
import ViewOrderDetailsStatus from "../../component/Pages/Accounts/ViewOrderDetailsStatus";

export default function AccountsPrivateRoute() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/accounts-Dashboard" element={<DashBoard />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
      <Route path="/payments" element={<Payment />} />
      <Route path="/createLot" element={<CreateLot />} />
      <Route path="/LotStatus" element={<LotStatus />} />
      <Route path="/UpdatePayment/:id" element={<UpdatePayment />} />
      <Route path="/ViewOrderDetails/:id" element={<ViewOrderDetails />} />
      <Route path="/accounts" element={<Accounts />} />

      {/* Pharmacy Account */}
      <Route path="/PhamacyListing" element={<PhamacyListing />} />
      <Route path="/creatinglot" element={<CreatingLotPharmacy />} />
      <Route
        path="/UpdatePaymentListing"
        element={<UpdatePaymentPharmacyListing />}
      />
      <Route
        path="/UpdatingPaymentStatus/:id"
        element={<UpdatingPaymentStatus />}
      />
      <Route path="/ViewOrderDetailsRefundable/:id" element={<ViewOrderDetailsRefundable />} />
      <Route path="/ViewOrderDetailsStatusChange/:id" element={<ViewOrderDetailsStatus />} />

    </Routes>
  );
}
