import * as types from "../../Config/actionType"

export const addProgramtRequest = (data) => {
   
    return {
        type: types.ADD_PROGRAM_REQUEST,
        data:data
    }
}

export const addProgramSuccess = (data) => {
   
    return {
        type: types.ADD_PROGRAM_SUCCESS,
        data:data,
    }
}

export const addProgramFailure = (data) => {
   
    return {
        type: types.ADD_PROGRAM_FAILURE,
        data:data,
    }
}