import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Packages/updatePackageAction"
import NetworkService from "../../../network/NetworkService";

export function* updatePackageSaga(action) {
  try {
      //
    let response = yield call(NetworkService.updatePackage, action);
    yield put(actions.updatePackageSuccess(response));
  } catch (err) {
    yield put(actions.updatePackageFailure(err));
  }
}

export function* watchUpdatePackageSaga() {
  yield takeLatest(types.UPDATE_PACKAGE_REQUEST, updatePackageSaga);
}