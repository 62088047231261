import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Organisms/Footer/FooterUpper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Pagination,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { viewAccountRequest } from "../../../redux/Actions/Accounts/ViewAccountsAction";
import storage from "../../../Utils/storage";
import "./Accounts.css";
import ModalInitiateRefund from "../../Organisms/Modal/ModalInitiateRefund";
import { viewDropdownAccountRequest } from "../../../redux/Actions/Accounts/ViewDropdownAction";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";

function Accounts() {
  const [select, setSelect] = React.useState("GLOBAL");
  const [searchPage, setSearchPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [add, setAdd] = React.useState(null);
  const [idd, setIdd] = useState(storage.getStorage("id"));
  const [showVisibility, setShowVisibility] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState("VIEW_DETAILS");
  const [statusChange, setStatusChange] = useState();
  const [refundAllowed, setRefundAllowed] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(viewAccountRequest(page, pageSize, newSearchText, select));
      setPage(1);
    } else {
      dispatch(viewAccountRequest(searchPage, pageSize, newSearchText, select));
    }

    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleViewClick = (id, refund, status) => {
    setIdd(id);
    setShowVisibility(true);
    setRefundAllowed(refund);
    setStatusChange(status);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(viewAccountRequest(value, pageSize, searchText, select));
      setPage(value);
    } else {
      setSearchPage(value);
      dispatch(viewAccountRequest(value, pageSize, searchText, select));
    }
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAccountRequest(page, event.target.value, searchText, select)
      );
    } else {
      dispatch(
        viewAccountRequest(searchPage, event.target.value, searchText, select)
      );
    }
    setPageSize(event.target.value);
  };

  const viewInAccount = useSelector((state) => state.viewAccount);

  const columns = [
    {
      field: "orderNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Order Id",
      minWidth: 60,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.9,
    },
    {
      field: "itemCount",
      headerClassName: "super-app-theme--header",
      headerName: "NO.Of Items",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.7,
    },
    {
      field: "couponUsed",
      headerClassName: "super-app-theme--header",
      headerName: "Coupon Use",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.7,
    },
    {
      field: "amount",
      headerClassName: "super-app-theme--header",
      headerName: "Amount Paid",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.7,
    },
    {
      field: "refundAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Refund Amount",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
    },
    {
      field: "productType",
      headerClassName: "super-app-theme--header",
      headerName: "Product Type",
      minWidth: 130,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
    },
    {
      field: "paymentMode",
      headerClassName: "super-app-theme--header",
      headerName: "Mode",
      minWidth: 130,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
    },

    {
      field: "date",
      headerClassName: "super-app-theme--header",
      headerName: "Purchase Date",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.2,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["date"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },

    {
      field: "customerName",
      headerClassName: "super-app-theme--header",
      headerName: "Purchase By",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 0.7,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: "number",
      headerClassName: "super-app-theme--header",
      headerName: "Phone",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.5,
    },

    {
      field: "orderStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 200,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            {params.row["orderStatus"] === "PENDING" && (
              <Typography>
                <Chip label="PENDING" color="secondary" />
              </Typography>
            )}
            {params.row["orderStatus"] === "FAILED" && (
              <Typography>
                <Chip label="FAILED" color="error" />
              </Typography>
            )}
            {params.row["orderStatus"] === "PAID" && (
              <Typography>
                <Chip label="PAID" color="success" />
              </Typography>
            )}
            {params.row["orderStatus"] === "REFUNDED" && (
              <Typography>
                <Chip label="REFUNDED" color="error" />
              </Typography>
            )}
            {params.row["orderStatus"] === "UNSUCCESSFUL" && (
              <Typography>
                <Chip label="UNSUCCESSFUL" color="secondary" size="medium" />
              </Typography>
            )}
            {params.row["orderStatus"] === "ABORTED" && (
              <Typography>
                <Chip label="ABORTED" color="warning" />
              </Typography>
            )}
            {params.row["orderStatus"] === "PARTIALLY_PAID" && (
              <Typography>
                <Chip label="PARTIALLY_PAID" color="warning" />
              </Typography>
            )}
            {params.row["orderStatus"] === "PARTIALLY_REFUNDED" && (
              <Typography>
                <Chip label="PARTIALLY_REFUNDED" color="warning" />
              </Typography>
            )}
            {params.row["orderStatus"] === "PARTIALLY_FAILED" && (
              <Typography>
                <Chip label="PARTIALLY_FAILED" color="warning" />
              </Typography>
            )}
            {params.row["orderStatus"] ===
              "PARTIALLY_PAID_PARTIALLY_REFUNDED" && (
              <Typography>
                <Chip
                  label="PARTIALLY_PAID_PARTIALLY_REFUNDED"
                  color="warning"
                />
              </Typography>
            )}
            {params.row["orderStatus"] ===
              "PARTIALLY_PAID_PARTIALLY_FAILED" && (
              <Typography>
                <Chip label="PARTIALLY_PAID_PARTIALLY_FAILED" color="warning" />
              </Typography>
            )}
            {params.row["orderStatus"] ===
              "PARTIALLY_FAILED_PARTIALLY_REFUNDED" && (
              <Typography>
                <Chip
                  label="PARTIALLY_FAILED_PARTIALLY_REFUNDED"
                  color="warning"
                />
              </Typography>
            )}
            {params.row["orderStatus"] ===
              "PARTIALLY_PAID_PARTIALLY_FAILED_PARTIALLY_REFUNDED" && (
              <Typography>
                <Chip
                  label="PARTIALLY_PAID_PARTIALLY_FAILED_PARTIALLY_REFUNDED"
                  color="warning"
                />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "deliveryStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Delivery Status",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 150,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <>
            {params.row["deliveryStatus"] === "PENDING" && (
              <Typography>
                <Chip label="PENDING" color="secondary" />
              </Typography>
            )}
            {params.row["deliveryStatus"] === "DISPATCHED" && (
              <Typography>
                <Chip label="DISPATCHED" color="info" />
              </Typography>
            )}
            {params.row["deliveryStatus"] === "ACCEPTED" && (
              <Typography>
                <Chip label="ACCEPTED" color="success" />
              </Typography>
            )}
            {params.row["deliveryStatus"] === "REFUNDED" && (
              <Typography>
                <Chip label="REFUNDED" color="error" />
              </Typography>
            )}
            {params.row["deliveryStatus"] === "DELIVERED" && (
              <Typography>
                <Chip label="DELIVERED" color="info" />
              </Typography>
            )}
            {params.row["deliveryStatus"] === "CANCELLED" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() =>
                handleViewClick(
                  cellValues.row.orderNumber,
                  cellValues.row.refundAllowed,
                  cellValues.row.statusChangesAllowed
                )
              }
            />
          </div>
        );
      },
    },
  ];
  function handleSelect(event) {
    const selectedValue = event.target.value;

    setSelect(selectedValue);
    setPage(1);
    dispatch(viewAccountRequest(1, pageSize, searchText, selectedValue));
  }
  useEffect(() => {
    dispatch(viewAccountRequest(page, pageSize, searchText, select));
  }, [page, pageSize, searchText, select]);
  useEffect(() => {
    dispatch(viewDropdownAccountRequest());
  }, []);
  const viewAccountDropdown = useSelector((state) => state.viewDropdownAccount);

  function handleOptionChange(e) {
    setSelectedPreference(e.target.value);
  }

  function handleViewDetails() {
    if (selectedPreference === "VIEW_DETAILS") {
      navigate(`/ViewOrderDetails/${idd}`);
    } else if (selectedPreference === "CHANGE_STATUS") {
      if (statusChange === true) {
        navigate(`/ViewOrderDetailsStatusChange/${idd}`);
      } else {
        swal({
          title: "Status Change Not Allowed.",
          icon: "info",
        });
      }
    } else if (selectedPreference === "INITIATE_REFUND") {
      if (refundAllowed === true) {
        navigate(`/ViewOrderDetailsRefundable/${idd}`);
      } else {
        swal({
          title: "Refund Not Allowed.",
          icon: "info",
        });
      }
    }
  }

  return (
    <>
      {showVisibility === true ? (
        <div className="ModalOrderRemarks">
          <div className="ViewAccountsStatusChangeCloseDiv">
            <Close
              className="ModalOrderRemarksCloseButton"
              onClick={() => setShowVisibility(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <select
            id="status"
            value={selectedPreference}
            onChange={handleOptionChange}
            className="initiateRefundIndividualSelect"
          >
            <option value="VIEW_DETAILS">View Details</option>
            <option value="INITIATE_REFUND">Initiate Refund</option>
            <option value="CHANGE_STATUS">Change Status</option>
          </select>
          <div
            className="ViewAccountsStatusChangebutton"
            style={{ marginTop: "2em", width: "50%" }}
          >
            <button
              onClick={handleViewDetails}
              style={{ marginTop: "1em", width: "100%", textAlign: "center" }}
            >
              {selectedPreference}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className="grid"
        style={
          showVisibility === true ? { background: "grey", opacity: "40%" } : {}
        }
        onClick={showVisibility === true ? () => setShowVisibility(false) : ""}
      >
        <h4 className="h4">Purchases</h4>
        <div className="mainBox">
          <div className="head">
            <div className="appleftHead">
              <h5 className="list">Purchase History</h5>
              <div className="count">
                {viewInAccount.data?.data.data.data.totalRecords} Purchases
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {add && <ModalInitiateRefund open={setAdd} />}
            </div>
          </div>

          <hr />
          <div className="row2">
            <div className="requestCity">
              <select
                value={select}
                onChange={handleSelect}
                className="NewDroppdownn"
              >
                {viewAccountDropdown?.data?.data?.data?.data?.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <hr />
          <div className="coupenChild3"></div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewInAccount?.data?.data?.data?.data?.orderHistoryResponseDto && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                getRowId={(row) => row.orderNumber}
                pagination
                disableColumnMenu
                rowCount={viewInAccount?.data?.data.data.data.totalRecords}
                rows={
                  viewInAccount?.data?.data.data.data.orderHistoryResponseDto
                }
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewInAccount?.data?.data?.data?.data?.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default Accounts;
