import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

function FamilyDetailsList(props) {
  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "relation",
      headerClassName: "super-app-theme--header",
      headerName: "Relationship",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "age",
      headerClassName: "super-app-theme--header",
      headerName: "Age",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
  ];
  return (
    <div>
      <Box
        sx={{
        //   height: props?.data ? `${props?.data.length * 70}px` : "100",
        height:200,  
        width: "100%",
        }}
      >
        {props?.data && (
          <DataGrid
            sx={{
              color: "#1D2334",
              "& .super-app-theme--header": {
                backgroundColor: "#52608E",
                color: "#F2EFEA",
                "& > .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              },
              "&:hover:not(.Mui-disabled)": {
                cursor: "pointer",
              },
              ".MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: "5%",
              },
            }}
            getRowId={(row) => row.name}
            // pagination
            disableColumnMenu
            rows={props?.data}
            columns={columns}
            components={{
              Footer: "none",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default FamilyDetailsList;
