import * as types from "../../Config/actionType"

export const updateInsuranceAgentRequest = (data) => {
    return {
        type: types.UPDATE_INSURANCE_AGENT_REQUEST,
        data:data
    }
}

export const updateInsuranceAgentSuccess = (data) => {
    return {
        type: types.UPDATE_INSURANCE_AGENT_SUCCESS,
        data:data,
    }
}

export const updateInsuranceAgentFailure = (data) => {
    return {
        type: types.UPDATE_INSURANCE_AGENT_FAILURE,
        data:data,
    }
}