import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Login/loginAction";
import NetworkService from "../../../network/NetworkService";

export function* LoginSaga(action) {
  try {
     
    let response = yield call(NetworkService.loginService, action);
    yield put(actions.loginSuccess(response));
  } catch (err) {
    yield put(actions.loginFailure(err));
  }
}

export function* watchLoginSaga() {
  yield takeLatest(types.LOGIN_REQUEST, LoginSaga);
}