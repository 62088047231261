import * as types from "../../Config/actionType"

export const updateHospitalRequest = (data) => {
    return {
        type: types.UPDATE_HOSPITAL_REQUEST,
        data:data
    }
}

export const updateHospitalSuccess = (data) => {
    return {
        type: types.UPDATE_HOSPITAL_SUCCESS,
        data:data,
    }
}

export const updateHospitalFailure = (data) => {
    return {
        type: types.UPDATE_HOSPITAL_FAILURE,
        data:data,
    }
}