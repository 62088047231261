import React, { useEffect } from "react";
import "../Corporate/ViewCorporateDetail.css";
import "../Appointments/ViewAppointment.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { viewLabRequest } from "../../../redux/Actions/Lab/viewLabAction";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ViewLab() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewLabRequest(id));
  }, [id]);

  const viewLab = useSelector((state) => state.viewLab);

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <div className="appointmentmain">
        <h3 className="appId">Lab - {viewLab.data?.data.data.id}</h3>
        <div className="appointment-box">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="viewBox-head">{viewLab.data?.data.data.name}</div>
            {viewLab.data?.data.data.active === false && (
              <div className="disableChip">DISABLED</div>
            )}
          </div>
          <hr />
          <div className="corporate-box-body">
            <div className="corporate-details">
              <div className="corporate-keys">
                Contact Number
                <br />
                <br />
                Official Email
                <br />
                <br />
                City
                <br />
                <br />
                Point of Contact
                <br />
                <br />
                Address
                <br />
                <br />
              </div>
              <div className="corporate-value">
                {viewLab.data?.data.data.contactPersonNumber}
                <br />
                <br />
                {viewLab.data?.data.data.contactEmail}
                <br />
                <br />
                {viewLab.data?.data.data.city}
                <br />
                <br />
                {viewLab.data?.data.data.contactName}
                <br />
                <br />
                {viewLab.data?.data.data.address}
              </div>
            </div>

            {viewLab.data?.data.data.active === false && (
              <div>
                <div className="grid-head">
                  <div className="hospitalDoctor">Disable Reason</div>
                </div>
                <div className="disableReason">
                  {viewLab.data?.data.data.disableReason}
                </div>
                <div className="disableReasonFile">
                  {viewLab.data?.data.data.fileName}
                  <VisibilityIcon
                    onClick={() => handleView(viewLab.data?.data.data.filePath)}
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      color: "#52608E",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewLab;
