import { Avatar } from "@mui/material";
import React, { useState } from "react";

import "./CustomerServiceAgentNavbar.css";
import logo from "../../../assets/logo_primary_light_variant.png";
import storage from "../../../Utils/storage";
import SwitchUserModal from "../../Organisms/Modal/SwitchUserModal";

function CustomerServiceAgent() {
  const getBackground = (curr) => {
    if (window.location.pathname === curr) {
      return "#E68D36";
    }
  };
  const [flag, setFlag] = useState(false);
  const handleSwitchUser = () => {
    setFlag(!flag);
  };
  const name = storage.getStorage("name");

  const handleLogout = () => {
    storage.clearStorage();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      {flag && <SwitchUserModal closeModal={handleSwitchUser} />}

      <a className="navbar-brand" href="/customer-Service-Dashboard">
        <img src={logo} alt="error" className="navbarLogo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <div className="navbar-nav w-100">
          <div className="nav-item dropdown">
            <a
              className="nav-link "
              href="/Booking"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                color: getBackground("/Booking"),
              }}
            >
              BOOKING
            </a>
          </div>
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              OPD
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                href="/appointments"
                style={{
                  color: getBackground("/appointments"),
                }}
              >
                APPOINTMENT
              </a>
              <a
                className="dropdown-item"
                href="/TalkToDoctor"
                style={{
                  color: getBackground("/TalkToDoctor"),
                }}
              >
                TALK TO DOCTOR
              </a>
            </div>
          </div>

          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              CLIENTS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                href="/corporate"
                style={{
                  color: getBackground("/corporate"),
                }}
              >
                CORPORATE
              </a>
              <a
                className="dropdown-item"
                href="/Users"
                style={{
                  color: getBackground("/Users"),
                }}
              >
                USERS
              </a>
            </div>
          </div>

          <div className="nav-item dropdown">
            <a
              className="nav-link "
              href="/ListOfPlan"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                color: getBackground("/ListOfPlan"),
              }}
            >
              PLAN DASHBOARD
            </a>
          </div>
          <div className="profile">
            <li className="nav-item dropdown">
              <Avatar
                sx={{ height: "32px", width: "32px" }}
                className="pic"
                alt={name}
                src="/static/images/avatar/3.jpg"
              />
              <a
                className="nav-link dropdown-toggle "
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <div
                  className="dropdown-item1"
                  onClick={handleSwitchUser}
                >
                  Switch user
                </div>
                <a className="dropdown-item" href="/" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            </li>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default CustomerServiceAgent;
