import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./clinicRequest.css";
import { networkViewClinicRequest } from "../../../../redux/Actions/Network/Clinic/NetworkViewClinicAction";
import swal from "sweetalert";
import { approveClinicRequest } from "../../../../redux/Actions/Network/Clinic/ApproveClinicAction";
import { planTypeDropdownRequest } from "../../../../redux/Actions/Plan/PlanTypeDropDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Loader from "../../../../assets/loader.gif";

function ViewClinicRequest() {
  const { id } = useParams();
  const [selectType, setSelectType] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [selectPrescriptionType, setSelectPrescriptionType] =
    React.useState(true);

  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(networkViewClinicRequest(id));
  }, []);
  useEffect(() => {
    dispatch(planTypeDropdownRequest());
  }, []);

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleApprove = (status) => {
    if (
      (selectType.length === 0 || location.length === 0) &&
      status === "APPROVED"
    ) {
      setError(true);
    } else {
      dispatch(
        approveClinicRequest(
          id,
          status,
          selectType,
          location,
          selectPrescriptionType
        )
      );
    }
  };
  const networkViewClinic = useSelector(
    (state) => state.network.networkViewClinic
  );

  const approveClinic = useSelector((state) => state.approveClinic);
  const planTypeDropdown = useSelector((state) => state.planTypeDropdown);

  if (approveClinic.data?.status === 201) {
    swal({
      title: approveClinic?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (approveClinic?.error?.action?.response?.status === 409) {
    swal({
      title: approveClinic?.error?.action?.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  const handleChange = (event) => {
    setSelectType(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handlePrescriptionChange = (event) => {
    setSelectPrescriptionType(event.target.value);
  };
  return (
    <>
      {approveClinic.isLoading ? (
        <>
          <div className="corporateDashboardLoading">
            <img alt="Loader" src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
        <>
          <div className="requestPage">
            <h3 className="requestHead">
              View Requests{" "}
              {networkViewClinic.data?.data.data.data.networkStatus ===
                "APPROVED" && <div className="approvedTile">APPROVED</div>}
              {networkViewClinic.data?.data.data.data.networkStatus ===
                "DECLINED" && <div className="approvedTileC">DECLINED</div>}
              {networkViewClinic.data?.data.data.data.networkStatus ===
                "PENDING" && <div className="approvedTileP">PENDING</div>}
            </h3>

            <div className="requestBox">
              <div className="requestTextHospital2">
                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Email:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.email}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.clinicName}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Clinic Type:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.clinicType}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name of the Owner:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.ownerName}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.address}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Location:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.location}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.address}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">City:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.city}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">PIN:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.pinCode}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Services:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.services}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Contact Number:</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.number}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Pan Number</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.panNumber === ""
                      ? "-"
                      : networkViewClinic.data?.data.data.data.panNumber}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Consultation Fees</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.consultationFees}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Clinic Photo</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.clinicPhotoName}{" "}
                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewClinic.data?.data.data.data.clinicPhoto
                        )
                      }
                      style={{
                        height: "30px",
                        width: "70px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Rate List</h1>
                  <h1 className="requestPageData">
                    {networkViewClinic.data?.data.data.data.rateListName}

                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewClinic.data?.data.data.data.rateList
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                {networkViewClinic.data?.data.data.data.networkStatus ===
                  "PENDING" && (
                  <>
                    <div className="requestPageInfowrapper">
                      <h1 className="requestPageInfo">Select Type</h1>
                      <select
                        as="Select"
                        value={selectType}
                        label="Select Type"
                        onChange={handleChange}
                        className="SelectTypeHospital"
                      >
                        <option value="" disabled selected>
                          Select Type
                        </option>
                        {planTypeDropdown?.data?.data?.data?.data.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </select>

                      {error && selectType.length <= 0 ? (
                        <label className="SelectTypeUrlValidation">
                          Please Enter The Type*
                        </label>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="requestPageInfowrapper">
                      <h1 className="requestPageInfo">Location URL</h1>
                      <div className="LocationIconMui">
                        {" "}
                        <LocationOnIcon />{" "}
                      </div>
                      <input
                        value={location}
                        onChange={handleChangeLocation}
                        placeholder="Enter Location Url"
                        className="LocationInputField"
                      ></input>

                      {error && location.length <= 0 ? (
                        <label className="SelectTypeUrlValidation">
                          Please Enter The Location URL*
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="requestPageInfowrapperClinic">
                <h1 className="requestPageInfo">Create Prescription</h1>

                <select
                  className="SelectTypeHospital"
                  onChange={handlePrescriptionChange}
                >
                  <option value={true} selected>
                    Yes
                  </option>
                  <option value={false}>No</option>
                </select>
              </div>
                  </>
                )}
              </div>
              

              {networkViewClinic.data?.data.data.data.networkStatus ===
                "PENDING" && (
                <div className="requestButton">
                  <button
                    className="requestApprove"
                    onClick={() => {
                      handleApprove("APPROVED");
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="requestDecline"
                    onClick={() => {
                      handleApprove("DECLINED");
                    }}
                  >
                    Decline
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewClinicRequest;
