import * as types from "../../Config/actionType"

export const viewAllEmployeeRequest = (page,pageSize,search,city,programUuidArray,asSuperAdmin,id) => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_REQUEST,
        page,
        pageSize,
        search,
        city,
        programUuidArray,
        asSuperAdmin,
        id,
    }
}

export const viewAllEmployeeSuccess = (action) => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_SUCCESS,
        action
    }
}

export const viewAllEmployeeFailure = (action) => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_FAILURE,
        action
    }
}