import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import {  TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AssignCardSubscriptionRequest } from "../../../redux/Actions/CreateCard/assignCardSubcriptionAction";
import { GetAllCardsDropdownRequest } from "../../../redux/Actions/CreateCard/getAllCardsDropdownAction";
function AssignCardSubscription(props) {
  const dispatch = useDispatch();

  const [cardNumber, setCardNumber] = useState("");
  useEffect(() => {
    dispatch(GetAllCardsDropdownRequest(props.Subscriptionid));
  }, []);

  const AssignCardSubscrption = useSelector(
    (state) => state.AssignCardSubscrption
  );
  const GetAllCardsDropdown = useSelector((state) => state.GetAllCardsDropdown);
  

  function handleCloseModal() {
    props.handleClose(false);
  }

  function handleCardNumber(id) {
    setCardNumber(id);
  }

  function handleSubmit() {
    if (cardNumber === "") {
      swal({
        title: "Please fill all the Details carefully",
        icon: "error",
      });
    } else {
      let data = {
        cardNumberId: cardNumber,
        subscriptionId: props.Subscriptionid,
        userId: props.userId,
      };
      dispatch(AssignCardSubscriptionRequest(data));
    }
  }
  if (AssignCardSubscrption.error != null) {
    swal({
      title: `${AssignCardSubscrption.error?.action?.response.data.errorMessage}`,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    AssignCardSubscrption.data?.action.status === 200 &&
    AssignCardSubscrption.data?.action?.data?.message !== ""
  ) {
    swal({
      title: `${AssignCardSubscrption.data?.action?.data?.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
 
  return (
    <Modal
      open={props.openAssignCardModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="mainModal"
    >
      <div className="addBannerModal">
        <div className="addBannerModalCross">
          <CloseIcon onClick={handleCloseModal} style={{ cursor: "pointer" }} />
        </div>
        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeftHeading">
            Assign Card Number{" "}
          </div>
        </div>
        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeft">Card Number </div>
          <div>
            {GetAllCardsDropdown.data?.action?.data?.data && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={GetAllCardsDropdown.data.action.data.data.map(
                  (options) => ({
                    name: options.name,
                    id: options.id,
                  })
                )}
                sx={{ width: 280 }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleCardNumber(newValue.id, event);
                  }
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Select Card Number" />
                )}
              />
            )}
          </div>
        </div>
        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeft">Subscription Id </div>
          <div className="addCardNumerModalChildRight">
            <input
              type="text"
              value={props.Subscriptionid}
              placeholder="Banner Name"
            />
          </div>
        </div>
        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeft">User Id </div>
          <div className="addCardNumerModalChildRight">
            <input type="text" value={props.userId} placeholder="Banner Name" />
          </div>
        </div>
        <button className="addCreateCardNumberButton" onClick={handleSubmit}>
          Assign Card Number
        </button>
      </div>
    </Modal>
  );
}

export default AssignCardSubscription;
