import * as types from "../../Config/actionType"

export const viewAllUsersRequest = (page,pageSize,search,city,selectedValue) => {
    return {
        type: types.VIEW_ALL_USERS_REQUEST,
        page,
        pageSize,
        search,
        city,
        selectedValue
        
    }
}

export const viewAllUsersSuccess = (action) => {
    return {
        type: types.VIEW_ALL_USERS_SUCCESS,
        action
    }
}

export const viewAllUsersFailure = (action) => {
    return {
        type: types.VIEW_ALL_USERS_FAILURE,
        action
    }
}