import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Products/createCategory";
import NetworkService from "../../../network/NetworkService";

export function* createCategorySaga(action) {
  try {
     
    let response = yield call(NetworkService.createCategory, action);
    yield put(actions.createCategorySuccess(response));
  } catch (err) {
    yield put(actions.createCategoryFailure(err));
  }
}

export function* watchCreateCategorySaga() {
  yield takeLatest(types.CREATE_CATEGORY_REQUEST, createCategorySaga);
}