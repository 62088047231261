import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import CircularProgress from "@mui/material/CircularProgress";

import * as Yup from "yup";
import { viewAllTestsNameRequest } from "../../../redux/Actions/Booking/viewAllTestsNameAction";
import { updatePackageRequest } from "../../../redux/Actions/Packages/updatePackageAction";
import { viewInPackagesRequest } from "../../../redux/Actions/Packages/viewInPackagesAction";
import "./Modal.css";
import { MenuProps, modalHeaderAddPackage } from "./ModalConstants";

const loginSchema = Yup.object().shape({
  packageName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.,&-])[A-Za-z0-9 ,&.-]+$/, "Insert only normal character"),
  packageDescription: Yup.string().required("Required Field"),
  cost: Yup.string().required("Required Field"),
  prerequisites: Yup.string().required("Required Field"),
});

function ModalUpdatePackage1(props) {
  //
  const dispatch = useDispatch();
  const [test, setTest] = useState([]);
  const packageUuid = props.editService.packageUuid;

  let testNameold = [];
  const [testName, setTestName] = React.useState([]);
  const [feature, setFeature] = useState();
  const [prerequisites, setPrerequisites] = useState("");
  const [radiology,setRadiology]=useState();

  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };

  useEffect(() => {
    dispatch(viewInPackagesRequest(packageUuid));
  }, []);

  const viewInPackages = useSelector((state) => state.viewInPackages);

  function handleRadiology(e){
    if(e.target.value==="true"){
      setRadiology(true)

    }
    else{
      setRadiology(false)

    }
  }

  const handleChange = (event) => {
    setTestName(event.target.value);
  };
  function handleInstruction(e) {
    setPrerequisites(e.target.value);
  }

  const updateRes = useSelector((state) => state.updatePackage)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    let serviceId = [];
    // let featured = viewInPackages?.data?.data?.data.featured;
    testName.map((x) => {
      viewAllTestsNames?.data?.data.data.data.map((itm) => {
        if (itm.name === x) {
          serviceId.push(parseInt(itm.id));
        }
      });
    });
    const finalDispatch = {
      values,
      serviceId,
      packageUuid,
      feature,
      prerequisites,
      radiology,
    };

    dispatch(updatePackageRequest(finalDispatch));
  };
  useEffect(() => {
    if (viewInPackages?.data?.data?.data.attributeResponseDtoList !== null) {
      viewInPackages?.data?.data?.data.attributeResponseDtoList.map((itm) => {
        testNameold.push(itm.attributeName);
      });
      setTestName(testNameold);
      setFeature(viewInPackages?.data?.data?.data.featured);
      setPrerequisites(viewInPackages?.data?.data?.data.prerequisites);
      setRadiology(viewInPackages?.data?.data?.data.radiology);
      
    }
  }, [viewInPackages]);
  const updatePackage = useSelector((state) => state.updatePackage);

  if (
    updatePackage?.data?.data.status === 201 &&
    updatePackage?.data?.data?.data.message !== ""
  ) {
    swal({
      title: `${updatePackage?.data?.data?.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    updatePackage?.error?.data?.response.status === 400 &&
    updatePackage?.error?.data?.response.data.errorMessage !== ""
  ) {
    swal({
      title: `${updatePackage?.error?.data?.response.data.errorMessage}`,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    updatePackage?.error?.data?.response.status === 409 &&
    updatePackage?.error?.data?.response.data.errorMessage !== ""
  ) {
    swal({
      title: `${updatePackage?.error?.data?.response.data.errorMessage}`,
      icon: "error",
    });
  }
  useEffect(() => {
    dispatch(viewAllTestsNameRequest());
    setTest(testName);
  }, []);

  const viewAllTestsNames = useSelector((state) => state.viewAllTestsName);

  function handleFeature() {
    setFeature(!feature);
  }
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeaderAddPackage}
            >
              Update Package
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              packageName: viewInPackages?.data?.data?.data.packageName,
              packageDescription: viewInPackages?.data?.data?.data.description,
              cost: viewInPackages?.data?.data?.data.packageCost,
              prerequisites: viewInPackages?.data?.data?.data.prerequisites,
              // interpretation: props.editService.interpretation
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="text"
                      name="packageName"
                      placeholder="Package Name"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="packageName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="packageDescription"
                      placeholder="Package Description"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="packageDescription"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="cost"
                      placeholder="Cost"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="cost"
                      component="div"
                    />
                  </label>
                  <label>
                    <select
                      className="inputField"
                      onChange={handleInstruction}
                      value={prerequisites}
                    >
                      <option value="" disabled selected>
                        Select Prerequisites
                      </option>
                      <option value="Fasting Required">Fasting Required</option>
                      <option value="Fasting Not Required">
                        Fasting Not Required
                      </option>
                    </select>
                  </label>
                  <label>
                      <select
                        className="inputField"
                        onChange={handleRadiology}
                        value={radiology}
                      >
                        <option value="" disabled selected>
                         Radiology
                        </option>
                        <option value="true">
                          True
                        </option>
                        <option value="false">
                          False
                        </option>
                      </select>
                    </label>
                  <label>
                   
                    <FormControl sx={{ mT: 1, width: "40%" }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Add Test
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        // sx={{width:400}}
                        // defaultvalue={testName}
                        value={testName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Add Test" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {viewAllTestsNames?.data?.data.data.data.map(
                          (item, index) => (
                            <MenuItem key={item.id} value={item.name}>
                              <Checkbox
                                checked={testName.indexOf(item.name) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </label>
                  <div className="featuringCheck" style={{ marginTop: "2em" }}>
                    <label>
                      <Field
                        type="checkbox"
                        name="checked"
                        onChange={handleFeature}
                        checked={feature}
                      />
                      <span style={{ marginLeft: "1em" }}>Featured</span>
                    </label>
                  </div>
                  <button type="submit"    disabled={!flag} className="modalButtonPackage">
                    
                  {flag ? "       Update Package":
                    
                    <CircularProgress/>
                    }
                    
                
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdatePackage1;
