import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/viewAllLabsAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllLabsNamesRequest(action) {
 
  try {
    let response = yield call(NetworkService.viewAllLabsNamesRequest, action);
    yield put(actions.viewAllLabNamesSuccess(response));
  } catch (err) {
    yield put(actions.viewAllLabNamesFailure(err));
  }
}

export function* watchviewAllLabsNamesSaga() {
  yield takeLatest(types.VIEW_ALL_LABS_NAMES_REQUEST, viewAllLabsNamesRequest);
}
