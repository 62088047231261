import { call, put, takeLatest } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/getItemTypeAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* getItemType(action) {
  try {
    let response = yield call(NetworkService.getItemType);
    yield put(actions.getItemTypeSuccess(response));
  } catch (err) {
    yield put(actions.getItemTypeFailure(err));
  }
}

export function* watchGetItemTypeSaga() {
  yield takeLatest(types.GET_ITEM_TYPE_REQUEST, getItemType);
}