import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/viewAllServiceAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllServiceSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllService, action);
    yield put(actions.viewAllServiceSuccess(response));
  } catch (err) {
    yield put(actions.viewAllServiceFailure(err));
  }
}

export function* watchViewAllServiceSaga() {
  yield takeLatest(types.VIEW_ALL_SERVICE_REQUEST, viewAllServiceSaga);
}