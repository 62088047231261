import React, { useEffect } from "react";
import Appointments from "../../Molecules/Appointments/Appointments";
import Title from "../../Organisms/Title/Title";
import "./DashBoard.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import storage from "../../../Utils/storage";
// import { useEffect } from "react";

function DashBoard() {
  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data && event.data.type === "SET_AUTHORIZATION") {
  //       const encryptedData = event.data.data;
  //       storage.setStorage("authorization", encryptedData);

  //       const flagData = event.data.flagData;
  //     }
  //   };

  //   window.addEventListener("message", handleMessage);

  //   return () => {
  //     // Clean up the event listener on component unmount
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  return (
    <div className="dashboard">
      <div className="dashmain">
        <div className="appointment">
          <Appointments text="No. of Appointments Appointments" number="NA" />
          <Appointments text="No. of Appointments Completed" number="NA" />
          <Appointments text="No. of Appointments Cancelled" number="NA" />
        </div>
        <Title />
      </div>

      <Footer />
    </div>
  );
}

export default DashBoard;
