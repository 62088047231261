import * as types from "../../Config/actionType"

export const GetDownloadCardDetailRequest = (data) => {
   
    return {
        type: types.GET_DOWNLOAD_CARD_DETAIL_REQUEST,
        data
            
    }
}

export const GetDownloadCardDetailSuccess = (action) => {
   
    return {
        type: types.GET_DOWNLOAD_CARD_DETAIL_SUCCESS,
        action
    }
}

export const GetDownloadCardDetailFailure = (action) => {
   
    return {
        type: types.GET_DOWNLOAD_CARD_DETAIL_FAILURE,
        action
    }
}