import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Chip,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./Booking.css";
// import ModalAddNewProgram from "../../Organisms/Modal/ModalCreateNewProgram";
import { viewAllBookingRequest } from "../../../redux/Actions/Booking/viewAllBookingAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import storage from "../../../Utils/storage";
import ModalAddBooking from "../../Organisms/Modal/ModalAddBooking";
import { roles } from "../../../helpers/MainConstants";
import { UserContext } from "../../../App";


function Booking() {
  const [add, setAdd] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const SelectedCity = storage.getStorage("cityId");
  const [city, setCity] = React.useState([]);
  const role = useContext(UserContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setCity([SelectedCity]);
  }, [SelectedCity]);
  useEffect(() => {
    const fetchData = () => {
      if (city.length === 0) {
        dispatch(viewAllBookingRequest(page, pageSize, "", ""));
      } else {
        dispatch(viewAllBookingRequest(page, pageSize, "", city));
      }
      dispatch(viewAllCityRequest());
    };
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [page, pageSize, city]);

  const viewBooking = useSelector((state) => state.viewAllBooking);
  const viewCity = useSelector((state) => state.viewAllCity);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleClick = (id) => {
    navigate(`/ViewBookingDetails/${id}`);
  };

  const debouncedOnChanged = useDebouncedCallback((value) => {
    if (value.length === 1) {
      dispatch(viewAllBookingRequest(page, pageSize, "", city));
    } else {
      dispatch(viewAllBookingRequest(page, pageSize, value, city));
    }
  }, 2000);

  const handleSearch = (e) => {
    const searchValue = e.target.value;

    setValue(searchValue);
    debouncedOnChanged(searchValue);
  };

  const columns = [
    {
      field: "customId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "memberName",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Name",
      minWidth: 130,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <div>
          {cellValues.row.memberName === null ? cellValues.row.patientName : cellValues.row.memberName}
          </div>
        );
      },
    },
    {
      field: "patientNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Number",
      minWidth: 120,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      
    },
    
    {
      field: "collectionTime",
      headerClassName: "super-app-theme--header",
      headerName: "Collection Time",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["collectionTime"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Payment Status",
      minWidth: 170,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography style={{ fontSize: 'small' }}>
              {params.row["paymentStatus"] === null ? (
                <div>-</div>
              ) : (
                params.row["paymentStatus"]
              )}
            </Typography>
          </>
        );
      },
    },
    {
      field: "labAssistantName",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Assistant",
      minWidth: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {params.row["labAssistantName"] === null ? (
                <div>-</div>
              ) : (
                params.row["labAssistantName"]
              )}
            </Typography>
          </>
        );
      },
    },
    {
      field: "labPhoneNo",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Phone",
      minWidth: 110,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {params.row["labPhoneNo"] === null
                ? "-"
                : params.row["labPhoneNo"]}
            </Typography>
          </>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      // width: 125,
      disableColumnMenu: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["status"] === "CANCELLED" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
            {params.row["status"] === "FINISHED" && (
              <Typography>
                <Chip label="FINISHED" color="success" />
              </Typography>
            )}
            {params.row["status"] === "CONFIRMED" && (
              <Typography>
                <Chip label="CONFIRMED" color="info" />
              </Typography>
            )}
            {params.row["status"] === "INITIATED" && (
              <Typography>
                <Chip label="INITIATED" color="default" size="medium" />
              </Typography>
            )}
            {params.row["status"] === "RESCHEDULED" && (
              <Typography>
                <Chip label="RESCHEDULED" color="warning" />
              </Typography>
            )}
            {params.row["status"] === "COMPLETED" && (
              <Typography>
                <Chip label="COMPLETED" color="secondary" />
              </Typography>
            )}
            {params.row["status"] === "UNPAID" && (
              <Typography>
                <Chip label="UNPAID" color="default" />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "View",
      headerClassName: "super-app-theme--header",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div>
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleClick(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);

  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleAdd = () => {
    // setAdd(true);
    window.location.href = "/AddBooking";
  };

  return (
    <div>
      <div className="grid">
        <h4 className="h4">Booking</h4>
        <div className="mainBox">
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Booking</h5>
              <div className="corporatecount">
                {viewBooking.data?.data.data.totalBookings} Bookings
              </div>
            </div>
            <div className="rightheadbtn">
              {role === "SUPER_ADMIN"  || roles.CUSTOMER_SERVICE_AGENT ? (
                <button
                  type="button"
                  className="btn addModalbtn"
                  onClick={() => handleAdd()}
                >
                  + Add Booking
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {add && <ModalAddBooking open={setAdd} />}

          <hr />
          <div className="row2">
            <div className="city">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities"
                cityList={cityNames}
              />
            </div>
            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton"> */}
              {/* <button
                  type="submit"
                  value="Submit"
                  className="corporatesearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                  }}
                >
                  <FilterAltIcon fontSize="small" />
                  Filter
                </button> */}
              {/* </div> */}
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewBooking.data?.data.data.bookingsResponseDtoList && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                rowCount={viewBooking.data?.data.data.totalBookings}
                rows={viewBooking.data?.data.data.bookingsResponseDtoList}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewBooking.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Booking;
