import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Accounts/ViewOrderDropdownAction";
import NetworkService from "../../../network/NetworkService";

export function* viewOrderDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.viewOrderDropdown, action);
    yield put(actions.ViewOrderDropdownSuccess(response));
  } catch (err) {
    yield put(actions.ViewOrderDropdownFailure(err));
  }
}

export function* watchViewOrderDropdownSaga() {
  yield takeLatest(types.VIEW_ORDER_DROPDOWN_REQUEST, viewOrderDropdownSaga);
}
