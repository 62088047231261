import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import "./Modal.css";
import { Box, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addAppointmentsRequest } from "../../../redux/Actions/Appointments/addAppointmentAction";
import { allCityAppointmentRequest } from "../../../redux/Actions/Appointments/allCityAction";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { searchDoctorRequest } from "../../../redux/Actions/Doctors/searchDoctorAction";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { setISODay } from "date-fns";
import swal from "sweetalert";
import { getRelationRequest } from "../../../redux/Actions/Employee/getRelationAction";
import storage from "../../../Utils/storage";
import { userDetailRequest } from "../../../redux/Actions/Appointments/userDetail";
import { relationshipRequest } from "../../../redux/Actions/Appointments/relationship";
import { programAndPlanRequest } from "../../../redux/Actions/Appointments/programAndPlan";
import { UserContext } from "../../../App";
import CircularProgress from "@mui/material/CircularProgress";

const modalHeader = {
  fontSize: "35px",
  fontFamily: "Nunito",
  marginBottom: "30px",
  marginTop: "15px",
};

function ModalAddAppointment(props) {
  const dispatch = useDispatch();

  const uuid = "0641b94d-16c4-430e-93ce-7877123d0574";
  const role = useContext(UserContext);

  const [patientNumberError, setPatientNumberError] = useState(false);
  const [value, setValue] = React.useState("");
  const [email, setEmail] = useState("");
  const [DocDetail, setDocDetail] = useState("");
  const [getHospital, setHospital] = useState("");
  const [index, setIndex] = useState("");
  const [cityIdd, setCityIdd] = useState("");
  const [docId, setDocId] = useState("");
  const [checkSpec, setCheckSpec] = useState(false);
  const [relation, setRelation] = useState(0);

  useEffect(() => {
    const getCities = async () => {
      dispatch(allCityAppointmentRequest());
    };
    getCities();
    // if (role === "HOSPITAL_ADMIN") {
    //   dispatch(searchDoctorRequest(0));
    // }
  }, []);

  const [contactNumber, setContactNumber] = useState("");

  function clickNumber(event) {
    setContactNumber(event.target.value);
  }

  const [cityHandle, setCityHandle] = useState(false);
  const handleCity = (event) => {
    setCityIdd(event.target.value);
    const searchObj = {
      getId: event.target.value,
      name: "",
      status: true,
    };
    dispatch(searchDoctorRequest(searchObj));
    setCityHandle(true);
  };
  const relationship = useSelector((state) => state.relationship);
  const allUserDetail = useSelector((state) => state.userDetail);
  const viewallCity = useSelector((state) => state.allCityAppointment);
  const viewAllDoctor = useSelector((state) => state.searchDoctor);

  const allUserDetailReduced = allUserDetail?.data?.action;
  function handleDoctorList(key, event) {
    setCheckSpec(true);
    setDocDetail(event.speciality);
    setHospital(event.hospital);
    setDocId(event.id);
    const searchObject = {
      getId: cityIdd,
      name: event.name,
      status: true,
    };
    dispatch(searchDoctorRequest(searchObject));
    if (!event.name) {
      const searchObject = {
        getId: cityIdd,
        name: "",
        status: true,
      };

      dispatch(searchDoctorRequest(searchObject));
    }
  }
  const [isCheck, setIsCheck] = useState(false);

  const patientRelations = relationship?.data?.data?.data;

  function handleKeyDown(key, event) {
    let name = event;
    setDocId(event.id);
    if (allUserDetailReduced?.data?.data?.cityId === null) {
      const searchObject = {
        getId: cityIdd,
        name: name,
        status: true,
      };

      dispatch(searchDoctorRequest(searchObject));
    } else {
      const searchObject = {
        getId: cityIdd,
        name: name,
        status: true,
      };

      dispatch(searchDoctorRequest(searchObject));
    }
  }

  const emailDetail = useSelector((state) => state.checkEmailAgent);

  var someDate = new Date(value);
  someDate = someDate.getTime();
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (email.length > 3) {
      dispatch(userDetailRequest(email));
      setCheck(true);
    }
  }, [email]);

  const [time, setTime] = useState();

  const datas = {
    id: allUserDetailReduced?.data?.data?.id,
    uuid: uuid,
  };

  const programAndPlan = useSelector(
    (state) => state.programAndPlan?.data?.data?.data
  );
  useEffect(() => {
    if (allUserDetailReduced?.data?.data !== undefined) {
      dispatch(programAndPlanRequest(datas));
    }
  }, [allUserDetailReduced?.data?.data]);
  useEffect(() => {
    if (emailDetail.data?.data.status === 200) {
      setLocked(false)
      dispatch(getRelationRequest(emailDetail.data?.data.data.data?.id));
    }
  }, [emailDetail]);

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleClose = () => {
    props.open(false);
    window.location.reload();
  };
  const [doctorCheck, setDoctorCheck] = useState();
  const [flag, setFlag] = useState(true);


  const handleSubmit = (values, { setSubmitting }) => {
    let val = {};

    const { version, userVersion, uuid, plan, locked } = programAndPlan?.data[index];

    val = {
      ...values,
      someDate,
      relation,
      version,
      userVersion,
      uuid,
      plan,
    };

    if (values.patientContactNumber === "") {
      setPatientNumberError(true);
      return;
    } else {
      setPatientNumberError(false);
    }

    if (!docId) {
      setDoctorCheck(true);
    } else {
      setDoctorCheck(false);
    }
    if (!value) {
      setTime(true);
    } else {
      setTime(false);
    }

    if (docId && value && patientNumberError === false) {
      setFlag(false);
      dispatch(addAppointmentsRequest(val));
    }
  };

  const handleCheckEmail = (event) => {
    setCityHandle(false);
    setLocked(false)
    setPlanCheck(false);
    setIsCheck(true);
    setEmail(event.target.value);
  };

  const [planCheck, setPlanCheck] = useState(false);
  const [locked, setLocked] = useState(false);

  const handleRelation = (event) => {
    setRelation(event.target.value);
  };

  const handlePlans = (event) => {
    setIndex(event.target.value);
    setPlanCheck(true);
    const index = event.target.value;
    if (programAndPlan?.data[index]?.locked) {
      setLocked(false)

      const data = {
        id: allUserDetailReduced?.data?.data?.id,
        uuid: programAndPlan?.data[index]?.uuid,
        version:
          programAndPlan?.data[index]?.plan === false
            ? null
            : programAndPlan?.data[index]?.version,
        userVersion:
          programAndPlan?.data[index]?.plan === false
            ? null
            : programAndPlan?.data[index]?.userVersion,
      };
      dispatch(relationshipRequest(data));
    }
    else {
      setLocked(true)
    }
  };

  useEffect(() => {
    if (email.includes("@")) {
      dispatch(checkEmailAgentRequest(email));
    }
  }, [email]);

  const res = useSelector((state) => state.addAppointment);

  if (res.data?.data?.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Appointment added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if (res?.error != null) {
      if (
        (res.data?.data.status !== 201 && res.data === undefined) ||
        res.error !== null
      ) {
        swal({
          title: "" + Object.values(res.error?.data?.response.data)[0],
          icon: "error",
        }).then(() => {
          props.open(true);
        });
      }
    }
  }, [res]);
  useEffect(() => {
    if (res?.data || res?.error) {
      setFlag(true);
    }
  }, [res]);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Appointment
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>
          <Formik
            initialValues={{
              patientEmail: email,
              patientName: allUserDetailReduced?.data?.data?.name,
              patientContactNumber: allUserDetailReduced?.data?.data?.number
                ? allUserDetailReduced?.data?.data?.number
                : "",
              contactNumber: email.includes("@")
                ? allUserDetailReduced?.data?.data?.number
                : allUserDetailReduced?.data?.data?.email,
              patientId: allUserDetailReduced?.data?.data?.id,
              doctorId: docId,
              description: "",
              address: allUserDetailReduced?.data?.data?.address,
              city: cityIdd,
              plan: "",
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      // type="email"
                      name="patientEmail"
                      value={email}
                      placeholder="Patient Email Or Number"
                      onChange={handleCheckEmail}
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="patientEmail"
                      component="div"
                    />
                    {emailDetail?.data?.data?.data?.message != null && (
                      <>
                        <div className="emailNotRegistered">
                          Email or Number Not Register
                        </div>
                      </>
                    )}
                  </label>
                  <div className="BookingNumberData">
                    {allUserDetailReduced?.data?.data?.number && (
                      <label>
                        Patient Number :{" "}
                        {allUserDetailReduced?.data?.data?.number}
                      </label>
                    )}

                    {allUserDetailReduced?.data?.data?.email && (
                      <label>
                        Patient Email :{" "}
                        {allUserDetailReduced?.data?.data?.email}
                      </label>
                    )}
                  </div>
                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <Field
                        type=""
                        name="patientName"
                        placeholder="Patient Name"
                        className="inputField"
                        disabled={true}
                        onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="patientName"
                        component="div"
                      />
                    </label>
                  )}
                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <Field
                        type="text"
                        name="address"
                        placeholder="Address"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="address"
                        component="div"
                      />
                    </label>
                  )}

                  {allUserDetailReduced?.data?.data && isCheck && (
                    <label>
                      <select
                        onChange={(e) => handleCity(e)}
                        name="city"
                        className="inputField"
                      >
                        <option value="" disabled selected>
                          Select City
                        </option>

                        {viewallCity?.data?.data?.data?.data?.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </select>
                    </label>
                  )}
                  {allUserDetailReduced?.data?.data && cityHandle && (
                    <label>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <select
                          onChange={(e) => handlePlans(e)}
                          name="plan"
                          className="inputField"
                        >
                          <option disabled selected value="">
                            Select program and plan &nbsp;
                          </option>
                          {programAndPlan?.data.map((item, index) => (
                            <>
                              <option key={index} value={index}>
                                {item.name}
                              </option>
                            </>
                          ))}
                        </select>

                        <div
                          style={{
                            marginLeft: "4vw",
                          }}
                        >
                          OPD:
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "3vw",
                            marginTop: "-1vw",
                          }}
                        >
                          <div>
                            Available |{" "}
                            {
                              programAndPlan?.data[index]
                                ?.assignedAttributeResponseDto[0]?.available
                            }
                          </div>
                          <div>
                            used |{" "}
                            {
                              programAndPlan?.data[index]
                                ?.assignedAttributeResponseDto[0]?.used
                            }
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="plan"
                        component="div"
                      />
                    </label>
                  )}
                  {locked ? <p style={{ fontSize: "1.3em", color: "red" }}>Plan is not Locked</p> :
                    <>
                      {(role === "SUPER_ADMIN" ||
                        role === "CUSTOMER_SERVICE_AGENT") && (
                          <>
                            {allUserDetailReduced?.data?.data && planCheck && (
                              <label>
                                <Field
                                  type="number"
                                  name="patientContactNumber"
                                  onBlur={clickNumber}
                                  placeholder="Patient Contact Number"
                                  className="inputField"
                                />
                                {patientNumberError ? (
                                  <div className="errorMessageModalValidation">
                                    Required Field
                                  </div>
                                ) : (
                                  ""
                                )}
                              </label>
                            )}{" "}
                            {allUserDetailReduced?.data?.data && planCheck && (
                              <label>
                                <select
                                  onChange={(e) => handleRelation(e)}
                                  name="relation"
                                  className="inputField"
                                >
                                  <option value="0" selected>
                                    Myself &nbsp;
                                  </option>

                                  {patientRelations?.data?.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                      {item.relation}
                                      &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;({item.age}
                                      )
                                    </option>
                                  ))}
                                </select>
                              </label>
                            )}
                            {allUserDetailReduced?.data?.data && planCheck && (
                              <label>
                                { }

                                <Autocomplete
                                  testId="selectDoctor"
                                  id="combo-box-demo"
                                  style={{ width: "40%" }}
                                  options={
                                    !viewAllDoctor.data?.data?.data?.data
                                      ? [{ label: "Loading...", id: 0 }]
                                      : viewAllDoctor.data?.data?.data?.data
                                  }
                                  getOptionLabel={(option) =>
                                    option.name + "    -    " + option.speciality ||
                                    ""
                                  }
                                  className="forAutocomplete"
                                  onInputChange={(key, event) =>
                                    handleKeyDown(key, event)
                                  }
                                  onClick={(key, event) => handleKeyDown(key, event)}
                                  onChange={(key, event) => {
                                    handleDoctorList(key, event);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select Doctor" />
                                  )}
                                  renderGroup={(params) => <li></li>}
                                />
                                {doctorCheck ? (
                                  <>
                                    {" "}
                                    <div className="errorMessageModalValidations">
                                      Required Field
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </label>
                            )}
                          </>
                        )}
                      {role === "HOSPITAL_ADMIN" && (
                        <>
                          <label>
                            {allUserDetailReduced?.data?.data && planCheck && (
                              <>
                                <Autocomplete
                                  testId="selectDoctor"
                                  id="combo-box-demo"
                                  style={{ width: "40%" }}
                                  options={
                                    !viewAllDoctor.data?.data?.data?.data
                                      ? [{ label: "Loading...", id: 0 }]
                                      : viewAllDoctor.data?.data?.data?.data
                                  }
                                  getOptionLabel={(option) =>
                                    option.name + "    -    " + option.speciality ||
                                    ""
                                  }
                                  className="forAutocomplete"
                                  onInputChange={(key, event) =>
                                    handleKeyDown(key, event)
                                  }
                                  onChange={(key, value) =>
                                    handleDoctorList(key, value)
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select Doctor" />
                                  )}
                                  renderGroup={(params) => <li></li>}
                                />

                                {doctorCheck ? (
                                  <>
                                    <div className="errorMessageModalValidation">
                                      Required Field
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </label>
                        </>
                      )}

                      {allUserDetailReduced?.data?.data && planCheck && (
                        <label>
                          <Field
                            type=""
                            name="description"
                            placeholder="Description"
                            className="inputField"
                          />
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="description"
                            component="div"
                          />
                        </label>
                      )}

                      {allUserDetailReduced?.data?.data && planCheck && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            minDate={new Date()}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                style={{ width: "40%", borderRadius: "12px" }}
                              />
                            )}
                            label="Select Date and Time"
                            value={value}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                          />
                          {time ? (
                            <>
                              {" "}
                              <div className="errorMessageModalValidations">
                                Required Field
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="dateTime"
                            component="div"
                          />
                        </LocalizationProvider>
                      )}
                      {allUserDetailReduced?.data?.data && planCheck && (
                        <button
                          type="submit"
                          style={{ width: "40%" }}
                          className="modalButtonAppointment"
                          //
                          disabled={!flag}
                        >
                          {flag ? (
                            "            Add Appointment"
                          ) : (
                            <CircularProgress />
                          )}
                        </button>
                      )}
                    </>
                  }

                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddAppointment;
