import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Program/getProgramAction";
import NetworkService from "../../../network/NetworkService";

export function* getProgramSaga(action) {
  try {
     
    let response = yield call(NetworkService.getProgram, action);
    yield put(actions.getProgramSuccess(response));
  } catch (err) {
    yield put(actions.getProgramFailure(err));
  }
}

export function* watchGetProgramSaga() {
  yield takeLatest(types.GET_PROGRAM_REQUEST, getProgramSaga);
}