import * as types from "../../Config/actionType"

export const checkEmailOrNumberRequest = (data) => {
    return {
        type: types.CHECK_EMAIL_OR_NUMBER_REQUEST,
        data:data
    }
}

export const checkEmailOrNumberSuccess = (data) => {
    return {
        type: types.CHECK_EMAIL_OR_NUMBER_SUCCESS,
        data:data,
    }
}

export const checkEmailOrNumberFailure = (data) => {
    return {
        type: types.CHECK_EMAIL_OR_NUMBER_FAILURE,
        data:data,
    }
}