import * as types from "../../Config/actionType";

export const getLotByIdActionRequest = (
  id,
  page,
  pageSize,
  lotType,
) => {
  return {
    type: types.GET_LOT_BY_ID_REQUEST,
    id,
    page,
    pageSize,
    lotType,
  };
};

export const getLotByIdActionSuccess = (data) => {
  return {
    type: types.GET_LOT_BY_ID_SUCCESS,
    data: data,
  };
};

export const getLotByIdActionFailure = (data) => {
  return {
    type: types.GET_LOT_BY_ID_FAILURE,
    data: data,
  };
};
