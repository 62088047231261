import React, { useEffect, useState } from "react";
import "./CreatePrescription.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useParams } from "react-router-dom";
import { submitPrescriptionRequest } from "../../../redux/Actions/InHouseDoctor/submitPrescriptionAction";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { consultationRequestsRequest } from "../../../redux/Actions/InHouseDoctor/ConsultationRequestsAction";
import storage from "../../../Utils/storage";
import Swal from "sweetalert2";
import { getMedTypeRequest } from "../../../redux/Actions/InHouseDoctor/getMedTypeAction";
import { getDosageTypeRequest } from "../../../redux/Actions/InHouseDoctor/getDosageTypeAction";
import { getDirectionRequest } from "../../../redux/Actions/InHouseDoctor/getDirectionAction";
import { CircularProgress } from "@mui/material";

function CreatePrescription() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [doctorName, setDoctorName] = useState("");
  const [complain, setComplain] = useState("");
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState("");
  const [error, setError] = useState(false);
  const testAdvice = [];
  const otherRemarks = [];
  const treatmentAdvices = [];
  const finalTreatmentAdvices = [];
  const [inputList, setInputList] = useState([{ parameterName: "" }]);
  const [inputListRemarks, setInputListRemarks] = useState([
    { otherRemarks: "" },
  ]);

  const navigate = useNavigate();
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleInputChangeRemarks = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListRemarks];
    list[index][name] = value;
    setInputListRemarks(list);
  };

  const res = useSelector((state) => state.viewAppointment);

  const identifier = res?.data?.data?.data?.identifier;

  useEffect(() => {
    dispatch(consultationRequestsRequest(id));
    dispatch(getDosageTypeRequest());
    dispatch(getMedTypeRequest());
    dispatch(getDirectionRequest());
  }, []);

  const getDosageType = useSelector((state) => state.getDosageType);

  const getMedType = useSelector((state) => state.getMedType);

  const getDirection = useSelector((state) => state.getDirection);

  const consultationRequests = useSelector(
    (state) => state.consultationRequests
  );

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleRemoveClickRemarks = (index) => {
    const list = [...inputListRemarks];
    list.splice(index, 1);
    setInputListRemarks(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: "" }]);
  };

  const handleAddClickRemarks = () => {
    setInputListRemarks([...inputListRemarks, { otherRemarks: "" }]);
  };
  const [inputList1, setInputList1] = useState([
    {
      medicineName: "",
      Dosage: "",
      medType: "",
      noOfDays: "",
      noOfTabsOrMl: "",
      total: "",
      remarks: "",
      direction: "",
    },
  ]);

  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };
  const handleInputChange1Param2 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);

    if (list[index].Dosage === "") {
      list[index].total = list[index].noOfTabsOrMl * list[index].noOfDays;
    } else {
      list[index].total =
        list[index].Dosage * list[index].noOfTabsOrMl * list[index].noOfDays;
    }
  };

  const handleInputChange1Param3 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
    if (list[index][name] !== "") {
    } else {
    }
  };

  const handleInputChange1Param4 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };

  const handleInputChange1Param5 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };

  const handleInputChange1Param6 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };

  const handleInputChange1Param7 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };

  const handleInputChange1Param8 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList1];
    list[index][name] = value;
    setInputList1(list);
  };

  const handleRemoveClick1 = (index) => {
    const list = [...inputList1];
    list.splice(index, 1);
    setInputList1(list);
  };
  const handleAddClick1 = () => {
    setInputList1([
      ...inputList1,
      {
        medicineName: "",
        Dosage: "",
        medType: "",
        noOfDays: "",
        noOfTabsOrMl: "",
        total: "",
        remarks: "",
        direction: "",
      },
    ]);
  };

  function handleChangeComplain(e) {
    setComplain(e.target.value);
  }
  function handleChangeDiagnosis(e) {
    if (e.target.value.length <= 200) {
      setProvisionalDiagnosis(e.target.value);
      setError(false);
    } else {
      setError(true);
    }
  }

  inputList.map((item) => {
    testAdvice.push(item.parameterName);
  });
  inputListRemarks.map((item) => {
    otherRemarks.push(item.otherRemarks);
  });
  inputList1.map((item, index) => {
    getDosageType?.data?.data?.data.data.map((ele) => {
      if (item.Dosage === "") {
        item.total = Math.ceil(1 * item.noOfDays * item.noOfTabsOrMl);
      }
      if (item.Dosage === ele.id) {
        item.total = Math.ceil(ele.number * item.noOfDays * item.noOfTabsOrMl);
      }
    });
  });
  inputList1.map((item) => {
    treatmentAdvices.push(item);
  });
  treatmentAdvices.map((item) => {
    finalTreatmentAdvices.push({
      medicine: item.medicineName,
      dosage: item.Dosage === "" ? null : item.Dosage,
      medType: item.medType,
      noOfDays: item.noOfDays,
      noOfUnits: item.noOfTabsOrMl,
      total: item.total,
      remarks: item.remarks === "" ? null : item.remarks,
      direction: item.direction,
    });
  });

  finalTreatmentAdvices.map((item) => {
    getDosageType?.data?.data.data.data.map((ele) => {
      if (item.dosage === ele.id) {
        item.dosage = ele.name;
      }
    });
  });
  finalTreatmentAdvices.map((item) => {
    getMedType?.data?.data.data.data.map((ele) => {
      if (item.medType === ele.id) {
        item.medType = ele.name;
      }
    });
  });
  finalTreatmentAdvices.map((item) => {
    getDirection?.data?.data.data.data.map((ele) => {
      if (item.direction === ele.id) {
        item.direction = ele.name;
      }
    });
  });

  const prescriptionData = {
    complain: complain,
    provisionalDiagnosis: provisionalDiagnosis,
    consultationId: id,
    testAdvice: testAdvice,
    treatmentAdvices: finalTreatmentAdvices,
    otherAdvices: otherRemarks,
    identifier: identifier,
  };

  function handleClick() {
    setError(true);
    if (testAdvice.length === 1 && testAdvice[0] === "") {
      Swal.fire({
        title: "Please fill Test Advice ",
        icon: "error",
      });
    }

    prescriptionData.treatmentAdvices.map((item) => {
      if (
        (item.dosage === null && item.remarks === null) ||
        item.medicine === "" ||
        item.noOfUnits === "" ||
        item.medType === "" ||
        item.noOfDays === "" 
      ) {
        Swal.fire({
          title: "Please fill Treatment Advice Properly",
          icon: "error",
        });
      }
    });
    prescriptionData.otherAdvices = prescriptionData.otherAdvices.filter(
      (item) => item !== ""
    );
    prescriptionData.testAdvice = prescriptionData.testAdvice.filter(
      (item) => item !== ""
    );
    if(prescriptionData.otherAdvices.length==0){
      prescriptionData.otherAdvices=null;
    }
    dispatch(submitPrescriptionRequest(prescriptionData));
  }
  const submittedPrescription = useSelector(
    (state) => state.submitPrescription
  );

  if (
    submittedPrescription?.data?.data.status === 201 &&
    submittedPrescription?.data?.data.data.message !== ""
  ) {
    swal({
      title: `${submittedPrescription?.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      navigate(`/hospitalAppointment`);
    });
  }

  return (
    <div className="prescriptionFormMain">
      <div className="prescriptionFormMainHeading">
        <p>Appointment Id - {id}</p>
      </div>
      <div className="prescriptionFormMainUserInfo">
        <p>Patient Name - {res?.data?.data.data.patientName}</p>

        <p>Brief Description - {res?.data?.data.data.description}</p>
      </div>
      <div className="prescriptionFormMainStarts">
        <p className="prescriptionFormMainStartsHeading">Create Prescription</p>

        <p className="labelName">Complain</p>
        <textarea
          className="input2"
          rows="1"
          type="text"
          onChange={handleChangeComplain}
        />
        <div
          className="
            prevalidation"
        >
          {error && complain.length <= 0 ? <>Required field</> : <></>}
        </div>
        <p className="labelName">Provisional Diagnosis</p>
        <textarea
          className="input3"
          rows="1"
          type="text"
          onChange={handleChangeDiagnosis}
          value={provisionalDiagnosis}
        />

        <div className="prevalidation">
          {error && (
            <>
              {provisionalDiagnosis.length === 0 && <>Required field</>}
              {provisionalDiagnosis.length >= 200 && (
                <>Please enter at most 200 characters</>
              )}
            </>
          )}
        </div>

        <p className="labelName">Test Advice</p>
        <div className="attributeCardBoxPF">
          {inputList.map((x, i) => {
            return (
              <>
                <div className="addParameterWrapperPF">
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="parameterName"
                      placeholder="Tests Name"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.parameterName}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </div>
                  <div className="addParameterCrossPF">
                    {inputList.length !== 1 && (
                      <button
                        className="addParameterCrossButtonPF"
                        onClick={() => handleRemoveClick(i)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  {inputList.length - 1 === i && (
                    <button
                      onClick={() => {
                        handleAddClick();
                      }}
                      className="addParameterButton"
                    >
                      + Add More Tests
                    </button>
                  )}
                </div>
              </>
            );
          })}
        </div>

        <p className="labelName">Treatment Advices</p>
        <div className="attributeCardBoxPF">
          {inputList1.map((x, i) => {
            return (
              <>
                <p>{i + 1}.</p>

                <div className="addParameterWrapperPF">
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="medicineName"
                      placeholder="Medicine Name"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.medicineName}
                      onChange={(e) => handleInputChange1(e, i)}
                    />
                  </div>

                  <div className="inputFieldAddParameterFirstPFParent">
                    <select
                      className="inputFieldAddParameterFirstPF"
                      name="Dosage"
                      onChange={(e) => handleInputChange1Param3(e, i)}
                      value={x.Dosage}
                      disabled={inputList1[i].remarks !== "" ? true : false}
                      style={
                        inputList1[i].remarks !== ""
                          ? { backgroundColor: "gray" }
                          : { backgroundColor: "white" }
                      }
                    >
                      <option value="">Dosages</option>

                      {getDosageType?.data?.data?.data?.data.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="inputFieldAddParameterFirstPFParent">
                    <select
                      className="inputFieldAddParameterFirstPF"
                      name="medType"
                      onChange={(e) => handleInputChange1Param4(e, i)}
                      value={x.medType}
                    >
                      <option value="" disabled={true}>
                        Medicine Type
                      </option>

                      {getMedType?.data?.data?.data?.data.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="noOfDays"
                      placeholder="Number of Days"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.noOfDays}
                      onChange={(e) => handleInputChange1Param2(e, i)}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="noOfTabsOrMl"
                      placeholder=" Number of Units"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.noOfTabsOrMl}
                      onChange={(e) => handleInputChange1Param6(e, i)}
                      onKeyPress={(e) => {
                        const pattern = /[0-9]/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="total"
                      placeholder="Total Number of Units"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.total}
                      onChange={(e) => handleInputChange1Param5(e, i)}
                      style={{ backgroundColor: "gray" }}
                      disabled={true}
                    />
                  </div>
                  <div className="inputFieldAddParameterFirstPFParent">
                    <select
                      className="inputFieldAddParameterFirstPF"
                      name="direction"
                      onChange={(e) => handleInputChange1Param8(e, i)}
                      value={x.direction}
                    >
                      <option value="" disabled={true}>
                        Direction
                      </option>

                      {getDirection?.data?.data?.data?.data.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="remarks"
                      placeholder="Remarks"
                      id="remarks"
                      className="inputFieldAddParameterFirstPF"
                      value={x.remarks}
                      disabled={inputList1[i].Dosage !== "" ? true : false}
                      style={
                        inputList1[i].Dosage !== ""
                          ? { backgroundColor: "gray" }
                          : { backgroundColor: "white" }
                      }
                      onChange={(e) => handleInputChange1Param7(e, i)}
                    />
                  </div>
                  <div className="addParameterCrossPF">
                    {inputList1.length !== 1 && (
                      <button
                        className="addParameterCrossButtonPF"
                        onClick={() => handleRemoveClick1(i)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  {inputList1.length - 1 === i && (
                    <button
                      onClick={() => {
                        handleAddClick1();
                      }}
                      className="addParameterButton"
                    >
                      + Add More
                    </button>
                  )}
                </div>
              </>
            );
          })}
        </div>

        <p className="labelName">Other Advices</p>
        <div className="attributeCardBoxPF">
          {inputListRemarks.map((x, i) => {
            return (
              <>
                <div className="addParameterWrapperPF">
                  <div className="inputFieldAddParameterFirstPFParent">
                    <input
                      name="otherRemarks"
                      placeholder="Other Advices (Optional)"
                      id="parameterName"
                      className="inputFieldAddParameterFirstPF"
                      value={x.otherRemarks}
                      onChange={(e) => handleInputChangeRemarks(e, i)}
                    />
                  </div>
                  <div className="addParameterCrossPF">
                    {inputListRemarks.length !== 1 && (
                      <button
                        className="addParameterCrossButtonPF"
                        onClick={() => handleRemoveClickRemarks(i)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>

                <div>
                  {inputListRemarks.length - 1 === i && (
                    <button
                      onClick={() => {
                        handleAddClickRemarks();
                      }}
                      className="addParameterButton"
                    >
                      + Add More Remarks
                    </button>
                  )}
                </div>
              </>
            );
          })}
        </div>
        {submittedPrescription.isLoading ? (
          <CircularProgress className="prescriptionFormLoading" />
        ) : (
          <button className="prescriptionFormButton" onClick={handleClick}>
            Submit
          </button>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default CreatePrescription;