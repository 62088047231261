import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "./ModalProductFilter.css";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { getBannerTypesRequest } from "../../../redux/Actions/Banner/getBannerTypesAction";
import swal from "sweetalert";
import { addBannerRequest } from "../../../redux/Actions/Banner/addBannerAction";
import CircularProgress from "@mui/material/CircularProgress";
import { getBrandDropdownRequest } from "../../../redux/Actions/Product/getBrandDropdownAction";
import { getCategoryDropdownRequest } from "../../../redux/Actions/Product/getCategoryDropdownAction";
import { getSubCategoryDropdownRequest } from "../../../redux/Actions/Product/getSubCategoryDropdownAction";
import Chip from "@mui/material/Chip";

function ModalProductFilter(props) {
  const dispatch = useDispatch();

  const [brand, setSelectBrand] = useState([]);
  const [subCategory, setSelectSubCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const [brandId, setSelectBrandId] = useState([]);
  const [subCategoryId, setSelectSubCategoryId] = useState([]);
  const [selectCategoryId, setSelectCategoryId] = useState([]);
  const [category, setCategory] = useState();
  
  useEffect(() => {
    dispatch(getBannerTypesRequest());
    dispatch(getBrandDropdownRequest());
    dispatch(getCategoryDropdownRequest());
  }, []);
  useEffect(() => {
    if (category) {
      dispatch(getSubCategoryDropdownRequest(category));
    }
  }, [category]);

  const getBrandDropdown = useSelector((state) => state.getBrandDropdown);
  const getCategoryDropdown = useSelector((state) => state.getCategoryDropdown);
  const getSubCategoryDropdown = useSelector(
    (state) => state.getSubCategoryDropdown
  );

  function handleCloseModal() {
    props.handleClose(false);
  }
  function handleBrand(e) {
    let selectedOption = e.target.selectedOptions[0];
    let selectedName = selectedOption.getAttribute("name");
    if (!brand.includes(selectedName)) {
        setSelectBrand((prevValue) => [...prevValue, selectedName]);
        setSelectBrandId((prevValue) => [...prevValue, parseInt(e.target.value)]);
      }

  }

  function handleSubCategory(e) {
    let selectedOption = e.target.selectedOptions[0];
    let selectedName = selectedOption.getAttribute("name");
    if(!subCategory.includes(selectedName)){
        setSelectSubCategory((prevValue) => [...prevValue, selectedName]);
        setSelectSubCategoryId((prevValue) => [
          ...prevValue,
          parseInt(e.target.value),
        ]);
    }
   
  }

  function handleCategory(e) {
    let selectedOption = e.target.selectedOptions[0];
    let selectedName = selectedOption.getAttribute("name");
    setCategory(parseInt(e.target.value));
    if(!selectCategory.includes(selectedName)){
        setSelectCategory((prevValue) => [...prevValue, selectedName]);
        setSelectCategoryId((prevValue) => [
          ...prevValue,
          parseInt(e.target.value),
        ]);
    }
   
  }

  const updateRes = useSelector((state) => state.addBanner);
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    if (updateRes?.data || updateRes?.error) {
      setFlag(true);
    }
  }, [updateRes]);

  function handleSubmit() {
    props.filter({
      brand: brandId,
      category: selectCategoryId,
      subCategory: subCategoryId,
    });
    props.handleClose(false);
  }

  const handleDeleteCategory = (index) => {
    let updatedCategory = [...selectCategory];
    updatedCategory.splice(index, 1);
    setSelectCategory(updatedCategory);

    let updatedCategoryId = [...selectCategoryId];
    updatedCategoryId.splice(index, 1);
    setSelectCategoryId(updatedCategoryId);
  };

  const handleDeleteSubCategory = (index) => {
    let updatedSubCategory = [...subCategory];
    updatedSubCategory.splice(index, 1);
    setSelectSubCategory(updatedSubCategory);

    let updatedSubCategoryId = [...subCategoryId];
    updatedSubCategoryId.splice(index, 1);
    setSelectSubCategoryId(updatedSubCategoryId);
  };

  const handleDeleteBrand = (index) => {
    let updatedBrands = [...brand];
    updatedBrands.splice(index, 1);
    setSelectBrand(updatedBrands);

    let updatedBrandsId = [...brandId];
    updatedBrandsId.splice(index, 1);
    setSelectBrandId(updatedBrandsId);
  };

  useEffect(()=>{
    if(getSubCategoryDropdown.data!==null && getSubCategoryDropdown.data?.data?.data?.data.length === 0){
        setSelectSubCategory([]);
        setSelectSubCategoryId([]);
      }
  },[getSubCategoryDropdown])
 


  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="mainModal"
    >
      <div className="ModalFilterProduct">
        <div className="addBannerModalCross">
          <CloseIcon
            onClick={handleCloseModal}
            style={{ cursor: "pointer", marginTop: "1em" }}
          />
        </div>
        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeft">Select Brand </div>
          <div className="addBannerModalChildRight">
            <select onChange={handleBrand}>
              <option value="null" selected disabled={true}>
                Select Brand
              </option>
              {getBrandDropdown.data?.data.data.data.map((item) => (
                <option value={item.id} key={item.id} name={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="addBannerModalChild">
          <div className="addBannerModalChildLeft">Select Category </div>
          <div className="addBannerModalChildRight">
            <select onChange={handleCategory}>
              <option value="null" selected disabled={true}>
                Select Category
              </option>
              {getCategoryDropdown.data?.data.data.data.map((item) => (
                <option value={item.id} key={item.id} name={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {(getSubCategoryDropdown.data!==null && getSubCategoryDropdown.data?.data?.data?.data.length !== 0 )? (
          <div className="addBannerModalChild">
            <div className="addBannerModalChildLeft">Select Sub-Category </div>
            <div className="addBannerModalChildRight">
              <select onChange={handleSubCategory}>
                <option value="null" selected disabled={true}>
                  Select Sub-Category
                </option>
                {getSubCategoryDropdown.data?.data.data.data.map((item) => (
                  <option value={item.id} key={item.id} name={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="filterSelected">
          {brand.length !== 0 ? (
            <div className="filterSelectedChild">
              <p>Brands</p>
              <div className="filterSelectedChildList">
                {brand?.map((item, index) => (
                  <>
                    <div key={index} className="chipDIvs">
                      <Chip
                        style={{
                          backgroundColor: "#67E4CE",
                          paddingTop: "1em",
                          paddingBottom: "1em",
                          height: "100%",
                          width: "100%",
                          color: "#52608E",
                          fontWeight: "500",
                          borderRadius: "10px",
                        }}
                        label={item}
                        onDelete={() => handleDeleteBrand(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          {selectCategory.length !== 0 ? (
            <div className="filterSelectedChild">
              <p>Category</p>
              <div className="filterSelectedChildList">
                {selectCategory?.map((item, index) => (
                  <>
                    <div key={index} className="chipDIvs">
                      <Chip
                        style={{
                          backgroundColor: "#67E4CE",
                          paddingTop: "1em",
                          paddingBottom: "1em",
                          height: "100%",
                          width: "100%",
                          color: "#52608E",
                          fontWeight: "500",
                          borderRadius: "10px",
                        }}
                        label={item}
                        onDelete={() => handleDeleteCategory(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}

          {subCategory.length !== 0 ? (
            <div className="filterSelectedChild">
              <p>Sub Category</p>
              <div className="filterSelectedChildList">
                {subCategory?.map((item, index) => (
                  <>
                    <div key={index} className="chipDIvs">
                      <Chip
                        style={{
                          backgroundColor: "#67E4CE",
                          paddingTop: "1em",
                          paddingBottom: "1em",
                          height: "100%",
                          width: "100%",
                          color: "#52608E",
                          fontWeight: "500",
                          borderRadius: "10px",
                        }}
                        label={item}
                        onDelete={() => handleDeleteSubCategory(index)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <button
          className="addBannerButton"
          disabled={!flag}
          onClick={handleSubmit}
          style={{ marginBottom: "1em" }}
        >
          {flag ? "    Filter" : <CircularProgress />}
        </button>
      </div>
    </Modal>
  );
}

export default ModalProductFilter;
