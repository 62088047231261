import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/mapToElasticDoctor";
import NetworkService from "../../../network/NetworkService";

export function* mapToElasticDoctorSaga(action) {
  try {
   
    let response = yield call(NetworkService.mapToElasticDoctor, action);
   
    yield put(actions.mapToElasticDoctorSuccess(response));
  } catch (err) {
    yield put(actions.mapToElasticDoctorFailure(err));
  }
}

export function* watchMapToElasticDoctorSaga() {
  yield takeLatest(types.MAP_TO_ELASTIC_DOCTOR_REQUEST, mapToElasticDoctorSaga);
}
