import * as types from "../../Config/Product/ProductActionType";

export const updateSubCategoryRequest = (data,id) => {
  return {
    type: types.UPDATE_SUB_CATEGORY_REQUEST,
    data,
    id,
  };
};

export const updateSubCategorySuccess = (data) => {
  return {
    type: types.UPDATE_SUB_CATEGORY_SUCCESS,
    data: data,
  };
};

export const updateSubCategoryFailure = (data) => {
  return {
    type: types.UPDATE_SUB_CATEGORY_FAILURE,
    data: data,
  };
};
