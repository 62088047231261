import { CardContent, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { roles } from "../../../helpers/MainConstants";
import { deleteServiceRequest } from "../../../redux/Actions/Service/deleteServiceAction";
import { viewAllServiceRequest } from "../../../redux/Actions/Service/viewAllServiceAction";
import { viewAllServiceForCorporateRequest } from "../../../redux/Actions/Service/viewAllServiceForCorporateAction";
import storage from "../../../Utils/storage";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalAddService from "../../Organisms/Modal/ModalAddService";
import "./serviceCorporate.css";
import { UserContext } from "../../../App";

function Servicess() {
  const [add, setAdd] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useContext(UserContext);


  const fetchServiceData = () => {
    if (role === roles.SUPER_ADMIN|| role === roles.INSURER) {
      dispatch(viewAllServiceRequest());
    } else {
      dispatch(viewAllServiceForCorporateRequest());
    }
  };

  useEffect(() => {
    fetchServiceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(true);
  };
  const service = useSelector((state) => state.viewAllService);
  //
  //
  const numService = service.data?.action?.data.data.length;
  const ser = useSelector((state) => state.viewAllServiceForCorporate);
  //

  const handleClick = (uuid) => {
    navigate(`/viewServiceCorporate/${uuid}`);
  };

  const handleDelete = (id) => {
    // setOpen(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteServiceRequest(id.serviceUuid));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        // navigate("/services");
        window.location.reload();
      } else {
        navigate("/services");
      }
    });
  };

  // const handleEdit = (id) => {
  // };

  // const debouncedOnChanged = useDebouncedCallback((value) => {
  //   dispatch((value));
  // }, 2000);

  // const handleSearch = (e) => {
  // setValue(e.target.value);
  // debouncedOnChanged(value);
  // };

  // if(window.location.pathname === '/services'){
  //   setSuperAdmin(true)
  // }else{
  //   setSuperAdmin(false)
  // }

  return (
    <div>
      <div className="servicename">Services</div>
      <div className="servicemainBox">
        <div className="servicehead">
          <div className="leftHead">
            <h5 className="servicelist">List of Services</h5>
            <div className="servicecount">{numService} Services</div>
          </div>
          {/* {window.location.pathname === "/services" ? ( */}
          <div className="rightheadbtn">
            <div
              type="button"
              className="btn addModalbtn"
              onClick={() => handleAdd()}
            >
              + Add Service
            </div>
          </div>

          {/* ) : (
            ""
          )} */}
          {add && <ModalAddService open={setAdd} />}
        </div>
        <hr />
        <div className="card-box">
          <Box>
            <CardContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                direction="row"
              >
                {(role === roles.SUPER_ADMIN || role === roles.INSURER) &&
                  service.data?.action?.data?.data?.map((data) => (
                    <div className="cards">
                      <div className="card-head">
                        {data.name}
                        {/* <DeleteIcon
                          className="card-edit"
                          sx={{ color: "#319B4B" }}
                          fontSize="small"
                          onClick={() => handleDelete(data)}
                        /> */}
                      </div>
                      <div class="myServicesDescription">
                      {data.description.slice(0, 20)}...
                      <span class="myServicesDescriptionText">
                        {data.description}{" "}
                      </span>
                    </div>
                      {/* <div
                        className="card-body"
                        // onClick={() => handleClick(data.serviceUuid)}
                      ></div> */}
                    </div>
                  ))}
                {role === "CORPORATE_HR" &&
                  ser.data?.action?.data?.data?.yuvaServices.map((data) => (
                    <div
                      className="cards"
                      // onClick={() => handleClick(data.serviceUuid)}
                    >
                      <div className="card-head">
                        {data.name}
                        {/* <DeleteIcon
                          className="card-edit"
                          sx={{ color: "#319B4B" }}
                          fontSize="small"
                          onClick={() => handleDelete(data)}
                        /> */}
                      </div>
                      <div class="myServicesDescription">
                      {data.description.slice(0, 20)}...
                      <span class="myServicesDescriptionText">
                        {data.description}{" "}
                      </span>
                    </div>
                  
                    </div>
                  ))}

                {/* <div className="service-custom-card">
                  <div className="custom-card-head">
                    Custom Service
                    <DeleteIcon
                      className="card-edit"
                      sx={{ color: "#319B4B" }}
                      fontSize="small"
                    />
                  </div>
                  <div className="card-body">
                    <div className="card-text">Associate Hospital - 12</div>
                    <div className="card-text">Associate Hospital - 12</div>
                    <div className="card-text">Associate Hospital - 12</div>
                  </div>
                </div> */}
              </Grid>
            </CardContent>
          </Box>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Servicess;
