import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getProductAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getProductSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getProduct, action);
   
    yield put(actions.getProductSuccess(response));
  } catch (err) {
   
    yield put(actions.getProductFailure(err));
  }
}

export function* watchGetProductSaga() {
 
  yield takeLatest(types.GET_PRODUCT_REQUEST, getProductSaga);
}
