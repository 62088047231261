import * as types from "../../Config/actionType"

export const confirmBookingRequest = (data) => {
    return {
        type: types.CONFIRM_BOOKING_REQUEST,
        data:data
    }
}

export const confirmBookingSuccess = (data) => {
    return {
        type: types.CONFIRM_BOOKING_SUCCESS,
        data:data,
    }
}

export const confirmBookingFailure = (data) => {
    return {
        type: types.CONFIRM_BOOKING_FAILURE,
        data:data,
    }
}