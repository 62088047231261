import React, { useEffect, useState } from "react";
import Footer from "../../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../Molecules/Autocomplete/AutoComplete";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { viewAllCityRequest } from "../../../../redux/Actions/City/viewAllCityAction";
import storage from "../../../../Utils/storage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { networkProfessionalServiceRequest } from "../../../../redux/Actions/Network/ProfessionalService/ProfessionalServiceAction";
import { useNavigate } from "react-router-dom";
function ProfessionalServiceRequest() {
  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    // {
    //   field: "entityType",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Entity Type",
    //   flex: 1,
    //   sortable: false,
    //   disableColumnMenu: true,
    // },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "contactNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    // {
    //   field: "ownerName",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Owner Name",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   sortable: false,
    // },
    // {
    //   field: "noOfBeds",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "No of Bed",
    //   flex: 1,
    //   disableColumnMenu: true,
    //   sortable: false,
    // },
    {
      field: "Modify",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const city = storage.getStorage("cityId");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState("");
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const viewCity = useSelector((state) => state.viewAllCity);
  const typeEnumList = []
  useEffect(() => {
    if (city === null) {
      dispatch(networkProfessionalServiceRequest(page, pageSize, searchText, "", status, typeEnumList));
    } else {
      dispatch(
        networkProfessionalServiceRequest(page, pageSize, searchText, city, status, typeEnumList)
      );
    }
    dispatch(viewAllCityRequest());
  }, [city]);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        networkProfessionalServiceRequest(page, pageSize, newSearchText, city, status, typeEnumList)
      );
      setPage(1)
    } else {
      dispatch(
        networkProfessionalServiceRequest(
          searchPage,
          pageSize,
          newSearchText,
          city,
          status,
          typeEnumList
        )
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = (id) => {
    navigate(`/ViewProfessionalServiceRequest/${id}`);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        networkProfessionalServiceRequest(value, pageSize, searchText, city, status, typeEnumList)
      );
      setPage(value);
    } else {
      dispatch(
        networkProfessionalServiceRequest(value, pageSize, searchText, city, status, typeEnumList)
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        networkProfessionalServiceRequest(page, e.target.value, searchText, city, status, typeEnumList)
      );
    } else {
      dispatch(
        networkProfessionalServiceRequest(searchPage, e.target.value, searchText, city, status, typeEnumList)
      );
    }
    setPageSize(e.target.value);
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        networkProfessionalServiceRequest(page, pageSize, searchText, city, e.target.value, typeEnumList)
      );
    } else {
      dispatch(
        networkProfessionalServiceRequest(searchPage, pageSize, searchText, city, e.target.value, typeEnumList)
      );
    }
    setStatus(e.target.value);
  };

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };
  const networkProfessionalService = useSelector((state) => state.networkProfessionalService);


  return (
    <div>
      <div className="grid">
        <h4 className="h4">Professional Request</h4>
        <div className="mainBox">
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Request</h5>
              <div className="corporatecount">
                {networkProfessionalService.data?.data.data.data.totalProfessionPartnerRequest
                }{" "}
                Request
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities"

                cityList={cityNames}
              />
              <select
                className="requestDropdown"
                onChange={(e) => handleStatus(e)}
              >
                <option value="">All</option>
                <option value="APPROVED">Approved</option>
                <option value="DECLINED">Declined</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {networkProfessionalService.data?.data.data.data
              .professionalPartnerResponseDtoList && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    "&:hover:not(.Mui-disabled)": {
                      cursor: "pointer",
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  pagination
                  disableColumnMenu
                  rowCount={
                    networkProfessionalService.data?.data.data.data.totalProfessionPartnerRequest
                  }
                  rows={
                    networkProfessionalService.data?.data.data.data
                      .professionalPartnerResponseDtoList
                  }
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={[10, 50, 100]}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  components={{
                    Footer: "none",
                  }}
                />
              )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={networkProfessionalService.data?.data.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ProfessionalServiceRequest;
