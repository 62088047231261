import * as types from "../../Config/actionType";

export const deleteDiscountRequest = (data) => {
  return {
    type: types.DELETE_DISCOUNT_REQUEST,
    data: data,
  };
};

export const deleteDiscountSuccess = (action) => {
  return {
    type: types.DELETE_DISCOUNT_SUCCESS,
    action,
  };
};

export const deleteDiscountFailure = (action) => {
  return {
    type: types.DELETE_DISCOUNT_FAILURE,
    action,
  };
};
