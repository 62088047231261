import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/addReportAction";
import NetworkService from "../../../network/NetworkService";

export function* deleteReportSaga(action) {
  try {
    let response = yield call(NetworkService.deleteReport, action);
    yield put(actions.addReportSuccess(response));
  } catch (err) {
    yield put(actions.addReportFailure(err));
  }
}

export function* watchDeleteReportSaga() {
  yield takeLatest(types.DELETE_REPORT_REQUEST, deleteReportSaga);
}
