import EditIcon from "@mui/icons-material/Edit";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { cancelBookingRequest } from "../../../redux/Actions/Booking/cancelBookingAction";
import { completeBookingRequest } from "../../../redux/Actions/Booking/completeBookingAction";
import { rescheduleBookingRequest } from "../../../redux/Actions/Booking/rescheduleBookingAction";
import "./ConfirmedBooking.css";
import { viewAllLabNamesRequest } from "../../../redux/Actions/Booking/viewAllLabsAction";
import { AssignLabRequest } from "../../../redux/Actions/Booking/AssignLabAction";

function ConfirmedBooking(props) {
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(someDate);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  var someDate = new Date(props.res.data?.data.data.timeSlot);
  someDate = someDate.toLocaleString();
  const completeBooking = useSelector((state) => state.completeBooking);
  const reschedule = useSelector((state) => state.rescheduleBooking);
  const confirm = useSelector((state) => state.confirmBooking);
  const cancel = useSelector((state) => state.cancelBooking);
  const [selectLab, setSelectLab] = useState();

  if (cancel.error !== null) {
    swal({
      title: cancel.error?.data?.response?.data?.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  if (cancel.error !== null) {
    swal({
      title: cancel.error?.data?.response?.data?.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  const handleComplete = () => {
    Swal.fire({
      title: "Collected !!",
      showDenyButton: true,
      confirmButtonText: "Confirm ",
      denyButtonText: `Close`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(completeBookingRequest(props.res.data?.data.data.id));
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  function handleSelectLab(e) {
    setSelectLab(e.target.value);
  }

  function handleConfirm() {
    if (selectLab !== undefined) {
      dispatch(AssignLabRequest(id, selectLab));
      Swal.fire({
        title: "Confirm Booking?",
        showDenyButton: true,
        confirmButtonText: "Confirm",
        denyButtonText: `Close`,
      }).then(() => {
        /* Read more about isConfirmed, isDenied below */
        window.location.reload();
      });
    } else {
      Swal.fire("Lab Not Selected", "", "info");
    }
  }

  useEffect(() => {
    dispatch(viewAllLabNamesRequest());
  }, []);
  const viewAllLabsNames = useSelector((state) => state.viewAllLabsNames);

  useEffect(() => {
    if (
      completeBooking?.error?.data?.response?.status ===
      400
    ) {
      swal({
        title: completeBooking?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [completeBooking]);
  useEffect(() => {
    if (
      completeBooking?.error?.data?.response?.data?.errorMessage ===
      "Cannot complete this booking before the scheduled time"
    ) {
      swal({
        title: completeBooking?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [completeBooking]);
  if (
    completeBooking?.data?.data?.data.message === "Status updated successfully"
  ) {
    Swal.fire({
      title: "Collected successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    reschedule?.error?.data.response.data.errorMessage ===
    "The scheduled time for booking should be 2 hours from now"
  ) {
    swal({
      title: "The scheduled time for booking should be 2 hours from now",
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  const handleCancel = () => {
    Swal.fire({
      title: "Do you want to cancel the appointment?",
      showDenyButton: true,
      confirmButtonText: "Cancel Appointment",
      denyButtonText: `Close`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cancelBookingRequest(id));
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const handleReschedule = () => {
    if (edit === false) {
      setEdit(true);
    } else {
      var date = new Date(value);
      date = date.getTime();
      Swal.fire({
        title: "Do you want to reschedule the appointment?",
        showDenyButton: true,
        confirmButtonText: "Reschedule",
        denyButtonText: `Close`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(rescheduleBookingRequest(id, date));
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  if (cancel?.data?.data?.data.message === "Status updated successfully") {
    Swal.fire({
      title: "Cancelled successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (reschedule?.data?.data?.data.message === "Status updated successfully") {
    Swal.fire({
      title: "Rescheduled successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  return (
    <div>
      <div className="appointmentmain">
        <h3 className="appId">Booking Id - {props.res.data?.data.data.id}</h3>
        <div className="appointment-box">
          <div className="appointmentbox-head">
            <div className="name">
              {props.res.data?.data.data.memberName === null
                ? props.res.data?.data.data.patientName
                : props.res.data?.data.data.memberName}
            </div>

            <div className="statusing">
              <>
                {props.res.data?.data.data.bookingStatus === "CONFIRMED" && (
                  <Typography>
                    <Chip label="CONFIRMED" color="info" />
                  </Typography>
                )}
                {props.res.data?.data.data.bookingStatus === "RESCHEDULED" && (
                  <Typography>
                    <Chip label="RESCHEDULED" color="warning" />
                  </Typography>
                )}
              </>
            </div>
          </div>
          <hr />
          <div className="appointment-box-body">
            <h5 className="app-desc">About This Package</h5>
            <p style={{ paddingRight: "2em" }}>{props.res.data?.data.data.packageNameDescriptionDtoList
              .map((item) => item.description
              )}</p>
            <div className="bookingsGrid">
              <div className="appointment-keys">Patient Phone Number</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientPhoneNumber}
              </div>
              {/* jkjjkn */}
              <div className="appointment-keys"> Patient Email</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientEmail}
              </div>{" "}
              <div className="appointment-keys"> Patient Location</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientLocation}
              </div>{" "}
              <div className="appointment-keys"> Collection Time Slot</div>
              <div className="appointment-value">{someDate}</div>{" "}
              <div className="appointment-keys"> Package Name</div>
              <div className="appointment-value">
                {props.res.data?.data.data?.packageNameDescriptionDtoList?.map(
                  (itm, index) => {
                    return index + 1 ===
                      props.res.data?.data.data?.packageNameDescriptionDtoList
                        .length
                      ? `${itm.name}  . `
                      : `${itm.name}  , `;
                  }
                )}
              </div>{" "}
              <div className="appointment-keys">Number Of Tests</div>
              <div className="appointment-value">
                {props.res.data?.data.data.testCount}
              </div>{" "}
              <div className="appointment-keys"> Test Name</div>
              <div className="appointment-value">
                {props.res.data?.data.data.testName.map((itm, index) => {
                  return index + 1 === props.res.data?.data.data.testName.length
                    ? `${itm}  .`
                    : `${itm}  , `;
                })}
              </div>
              <div className="appointment-keys">
                {" "}
                Name of the Diagnostic Center
              </div>
              <div className="appointment-value">
                {props.res.data?.data.data.labName ? props.res.data?.data.data.labName : "---"}
              </div>
              <div className="appointment-keys"> Time Slot</div>
              <div className="appointment-value">
                <div className="timeSlot">
                  <div>{someDate}</div>
                  {props.res.data?.data.data.bookingStatus ===
                    "CANCELLED" ? null : (
                    <div>
                      <EditIcon
                        className="edit"
                        sx={{ color: "#1D2334" }}
                        onClick={() => {
                          setEdit(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                {edit && (
                  <div className="dates_time">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Time Slot"
                        minDate={new Date()}
                        // defaultValue={value}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                )}
              </div>
              <div className="appointment-keys"> Assign Lab</div>
              <div className="appointment-value">
                {props.res.data?.data.data.labName ? props.res.data?.data.data.labName : <div className="BookingFormLabContainer">
                  <form>
                    <select
                      className="BookingFormLab"
                      value={selectLab}
                      onChange={(e) => handleSelectLab(e)}
                    >
                      <option value="" disabled selected>
                        Select Lab
                      </option>
                      {viewAllLabsNames?.data?.data.data.data.map(
                        (item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        )
                      )}
                    </select>
                  </form>
                </div>}

              </div>{" "}
              <div className="appointment-keys"> Assign Assistant Name</div>
              <div className="appointment-value">
                {props.res.data?.data.data.labAssistantName ? props.res.data?.data.data.labAssistantName : "---"}
              </div>{" "}
              <div className="appointment-keys"> Assign Assistant Number</div>
              <div className="appointment-value">
                {props.res.data?.data.data.labAssistantPhoneNumber ? props.res.data?.data.data.labAssistantPhoneNumber : "---"}
              </div>{" "}
              <div className="appointment-keys"> Assign Assistant Email</div>
              <div className="appointment-value">
                {props.res.data?.data.data.labAssistantEmail ? props.res.data?.data.data.labAssistantEmail : "---"}
              </div>{" "}
            </div>
            <div className="desc">
              {props.res.data?.data.data.testOrPackageDescription}
            </div>
            
          </div>
          <div className="bookingBottom">
            <div className="bookingBottomStat">
              {props.res.data?.data.data.labName !== null ?
                <button onClick={handleComplete}>Sample Collected</button> :
                <button onClick={handleConfirm}>Confirm Lab</button>}
              <button onClick={handleReschedule}>Reschedule</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmedBooking;
