
export const roles = {
    SUPER_ADMIN: "SUPER_ADMIN",
    INSURER: "INSURER",
    CORPORATE_HR: "CORPORATE_HR",
    IN_HOUSE_DOCTOR: "IN_HOUSE_DOCTOR",
    SUPER_LAB_ADMIN: "SUPER_LAB_ADMIN",
    HOSPITAL_ADMIN: "HOSPITAL_ADMIN",
    ACCOUNTS: "ACCOUNTS_ADMIN",
    RETAIL_USER: "RETAIL_USER",
    SUPER_PHARMA_ADMIN: "SUPER_PHARMA_ADMIN",
    LAB_ADMIN: "LAB_ADMIN",
    EMPLOYEE: "EMPLOYEE",
    PHARMA_ADMIN: "PHARMA_ADMIN",
    CUSTOMER_SERVICE_AGENT: "CUSTOMER_SERVICE_AGENT",
    DOCTOR: "DOCTOR"
};