import * as types from "../../Config/Product/ProductActionType";

export const updateCategoryRequest = (data,id) => {
  return {
    type: types.UPDATE_CATEGORY_REQUEST,
    data,
    id,
  };
};

export const updateCategorySuccess = (data) => {
  return {
    type: types.UPDATE_CATEGORY_SUCCESS,
    data: data,
  };
};

export const updateCategoryFailure = (data) => {
  return {
    type: types.UPDATE_CATEGORY_FAILURE,
    data: data,
  };
};
