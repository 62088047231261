import * as types from "../../../Config/actionType";

export const networkHospitalRequest = (
  page,
  pageSize,
  search,
  city,
  status
) => {
  return {
    type: types.NETWORK_HOSPITAL_REQUEST,
    page,
    pageSize,
    search,
    city,
    status,
  };
};

export const networkHospitalSuccess = (data) => {
  return {
    type: types.NETWORK_HOSPITAL_SUCCESS,
    data: data,
  };
};

export const networkHospitalFailure = (data) => {
  return {
    type: types.NETWORK_HOSPITAL_FAILURE,
    data: data,
  };
};
