import * as types from "../../Config/actionType"

export const EditCouponRequest = (data) => {
    return {
        type: types.EDIT_COUPON_REQUEST,
        data:data
    }
}

export const EditCouponSuccess = (data) => {
    return {
        type: types.EDIT_COUPON_SUCCESS,
        data:data,
    }
}

export const EditCouponFailure = (data) => {
    return {
        type: types.EDIT_COUPON_FAILURE,
        data:data,
    }
}