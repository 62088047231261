import * as types from "../../../Config/actionType"

export const approveClinicRequest = (id, status,selectType,location,createPrescription) => {
    return {
        type: types.APPROVE_CLINIC_REQUEST,
        id,
        status,
        selectType,
        location,
        createPrescription
    }
}

export const approveClinicSuccess = (action) => {
    return {
        type: types.APPROVE_CLINIC_SUCCESS,
        action
    }
}

export const approveClinicFailure = (action) => {
    return {
        type: types.APPROVE_CLINIC_FAILURE,
        action
    }
}