import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/AddDiscountGroupAction";
import NetworkService from "../../../network/NetworkService";

export function* AddDiscountGroupSaga(action) {
  try {
    let response = yield call(NetworkService.addDiscountGroup, action);
    yield put(actions.addDiscountGroupSuccess(response));
  } catch (err) {
    yield put(actions.addDiscountGroupFailure(err));
  }
}

export function* watchAddDiscountGroupSaga() {
  yield takeLatest(types.ADD_DISCOUNT_GROUP_REQUEST, AddDiscountGroupSaga);
}
