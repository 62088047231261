import { TextField, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { roles } from "../../../helpers/MainConstants";
import { AssignLabRequest } from "../../../redux/Actions/Booking/AssignLabAction";
import { cancelBookingRequest } from "../../../redux/Actions/Booking/cancelBookingAction";
import { confirmBookingRequest } from "../../../redux/Actions/Booking/confirmBookingAction";
import { rescheduleBookingRequest } from "../../../redux/Actions/Booking/rescheduleBookingAction";
import { viewAllLabNamesRequest } from "../../../redux/Actions/Booking/viewAllLabsAction";
import storage from "../../../Utils/storage";
import "./InitiatedBooking.css";
import { UserContext } from "../../../App";

function InitiatedBooking(props) {
  const AssignLab = useSelector((state) => state.AssignLab);
  const role = useContext(UserContext);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const labObjects = [];
  const [edit, setEdit] = useState(false);
  const [selectLab, setSelectLab] = useState();
  useEffect(() => {
    dispatch(viewAllLabNamesRequest());
  }, []);

  function handleSelectLab(e) {
    setSelectLab(e.target.value);
  }
  var someDate = new Date(props.res.data?.data.data.timeSlot);
  someDate = someDate.toLocaleString();

  const [value, setValue] = useState(someDate);
  const [flag, setFlag] = useState(false);


  const handleConfirm = () => {
    if (selectLab !== undefined) {
      Swal.fire({
        title: "Confirm Booking?",
        showDenyButton: true,
        confirmButtonText: "Confirm",
        denyButtonText: `Close`,
      }).then((result) => {
        if (result.isConfirmed) {
          setFlag(true)
          dispatch(AssignLabRequest(id, selectLab));
      

        } else if (result.isDenied) {
          setFlag(false)

          Swal.fire("Changes are not saved", "", "info");
        }
      });
    } else {
      Swal.fire("Lab Not Selected", "", "info");
    }
  };

  // if(AssignLab!==null)
  // {

  // }

  useEffect(()=>{

    if (flag===true && AssignLab.isLoading===false && AssignLab.data!==null )
    {
      dispatch(confirmBookingRequest(id));
    }
     else if (flag===true && AssignLab.isLoading===false && AssignLab.error?.data.response.data.errorMessage!=="") {

      Swal.fire({
        title: AssignLab?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  },[flag,AssignLab]);

  const handleReschedule = () => {
    if (edit === false) {
      setEdit(true);
    } else {
      var date = new Date(value);
      date = date.getTime();
      Swal.fire({
        title: "Do you want to reschedule the Booking?",
        showDenyButton: true,
        confirmButtonText: "Reschedule",
        denyButtonText: `Close`,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(rescheduleBookingRequest(id, date));
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Do you want to cancel the Booking?",
      showDenyButton: true,
      confirmButtonText: "Cancel Booking",
      denyButtonText: `Close`,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(cancelBookingRequest(id));
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const confirm = useSelector((state) => state.confirmBooking);
  const reschedule = useSelector((state) => state.rescheduleBooking);
  const cancel = useSelector((state) => state.cancelBooking);
  const viewAllLabsNames = useSelector((state) => state.viewAllLabsNames);

  if (cancel.error !== null) {
    swal({
      title: cancel.error?.data?.response?.data?.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }








  if (confirm?.data?.data?.data.message === "Status updated successfully") {
    Swal.fire({
      title: "Lab Assigned successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (cancel?.data?.data?.data.message === "Status updated successfully") {
    Swal.fire({
      title: "Cancelled successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    (reschedule?.data?.data?.status !== 201 &&
      reschedule?.data === undefined) ||
    reschedule?.error !== null
  ) {
    swal({
      title: `${reschedule?.error?.data?.response?.data.errorMessage}`,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

 
  return (
    <div>
      <div className="appointmentmain">
        <h3 className="appId">Booking Id - {props.res.data?.data.data.id}</h3>
        <div className="appointment-box">
          <div className="appointmentbox-head">
            <div className="name">
              {props.res.data?.data.data.memberName === null
                ? props.res.data?.data.data.patientName
                : props.res.data?.data.data.memberName}
            </div>
            <div className="statusing">
              <>
                {props.res.data?.data.data.bookingStatus === "INITIATED" && (
                  <Typography>
                    <Chip label="INITIATED" />
                  </Typography>
                )}
              </>
            </div>
          </div>
          <hr />
          <div className="appointment-box-body">
            <h5 className="app-desc">About This Package</h5>
            <p style={{ paddingRight: "2em" }}>
              {props.res.data?.data.data.packageNameDescriptionDtoList.map(
                (item) => item.description
              )}
            </p>
            <div className="bookingsGrid">
              <div className="appointment-keys">Patient Phone Number</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientPhoneNumber}
              </div>
              {/* jkjjkn */}
              <div className="appointment-keys"> Patient Email</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientEmail===null?"---":props.res.data?.data.data.patientEmail}
              </div>{" "}
              <div className="appointment-keys"> Patient Location</div>
              <div className="appointment-value">
                {props.res.data?.data.data.patientLocation}
              </div>{" "}
              <div className="appointment-keys"> Collection Time Slot</div>
              <div className="appointment-value">{someDate}</div>{" "}
              <div className="appointment-keys"> Package Name</div>
              <div className="appointment-value">
                {props.res.data?.data.data?.packageNameDescriptionDtoList?.map(
                  (itm, index) => {
                    return index + 1 ===
                      props.res.data?.data.data?.packageNameDescriptionDtoList
                        .length
                      ? `${itm.name}  . `
                      : `${itm.name}  , `;
                  }
                )}
              </div>{" "}
              <div className="appointment-keys">Number Of Tests</div>
              <div className="appointment-value">
                {props.res.data?.data.data.testCount}
              </div>{" "}
              <div className="appointment-keys"> Test Name</div>
              <div className="appointment-value">
                {props.res.data?.data.data.testName.map((itm, index) => {
                  return index + 1 === props.res.data?.data.data.testName.length
                    ? `${itm}  .`
                    : `${itm}  , `;
                })}
              </div>
              <div className="appointment-keys">
                {props.res.data?.data.data.bookingStatus === "CANCELLED" ? (
                  ""
                ) : (
                  <p>Select Lab</p>
                )}
              </div>
              <div className="appointment-value">
                {props.res.data?.data.data.bookingStatus === "CANCELLED" ? (
                  ""
                ) : (
                  <div className="BookingFormLabContainer">
                    <form>
                      <select
                        className="BookingFormLab"
                        value={selectLab}
                        onChange={(e) => handleSelectLab(e)}
                      >
                        <option value="" disabled selected>
                          Select Lab
                        </option>
                        {viewAllLabsNames?.data?.data.data.data.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </select>
                    </form>
                  </div>
                )}
              </div>
            </div>

            <div className="desc">
              {props.res.data?.data.data.testOrPackageDescription}
            </div>

            {role === roles.SUPER_ADMIN ? (
              props.res.data?.data.data.bookingStatus === "INITIATED" ||
                props.res.data?.data.data.bookingStatus === "RESCHEDULED" ? (
                <div className="appointment-buttons">
                  <button
                    className="appointment-btnAssign"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </button>
                  {/* <button
                    className="appointment-btnCancel"
                    onClick={handleReschedule}
                  >
                    Reschedule
                  </button> */}
                  <button
                    className="appointment-btnDelete"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              ) : null
            ) : props.res.data?.data.data.bookingStatus !== "CANCELLED" ? (
              <div className="appointment-buttons">
                <button
                  className="appointment-btnAssign"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
                <button
                  className="appointment-btnDelete"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InitiatedBooking;
