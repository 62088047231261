import * as types from "../../Config/Product/ProductActionType";

export const addBrandRequest = (data) => {
  return {
    type: types.ADD_BRAND_REQUEST,
    data:data
  };
};

export const addBrandSuccess = (data) => {
  return {
    type: types.ADD_BRAND_SUCCESS,
    data: data,
  };
};

export const addBrandFailure = (data) => {
  return {
    type: types.ADD_BRAND_FAILURE,
    data: data,
  };
};
