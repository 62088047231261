import * as types from "../../Config/actionType"

export const viewInPackagesRequest = (data) => {
    return {
        type: types.VIEW_IN_PACKAGES_REQUEST,
        data
    }
}

export const viewInPackagesSuccess = (action) => {
    return {
        type: types.VIEW_IN_PACKAGES_SUCCESS,
        action
    }
}

export const viewInPackagesFailure = (action) => {
    return {
        type: types.VIEW_IN_PACKAGES_FAILURE,
        action
    }
}