import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/PlanTypeDropDown";
import NetworkService from "../../../network/NetworkService";

export function* planTypeDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.planTypeDropdown, action);
    yield put(actions.planTypeDropdownSuccess(response));
  } catch (err) {
    yield put(actions.planTypeDropdownFailure(err));
  }
}

export function* watchplanTypeDropdownSaga() {
  yield takeLatest(types.PLAN_TYPE_DROPDOWN_REQUEST, planTypeDropdownSaga);
}
