import React, { useEffect, useRef, useState } from "react";
import "./CreateCategory.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Footer from "../../Organisms/Footer/FooterUpper";
import MyQuill from "./MyQuill";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { createCategoryRequest } from "../../../redux/Actions/Products/createCategory";

function CreateCategory() {
  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
  });
  const [nutritionalSwitchState, setNutritionalSwitchState] = useState(false);
  const [displayOnLandingPageSwitchState, setDisplayOnLandingPageSwitchState] =
    useState(false);

  const [editorContent, setEditorContent] = useState("");

  const handleChildData = (data) => {
    setEditorContent(data);
  };

  const handleSwitchDisplay = () => {
    setDisplayOnLandingPageSwitchState(!displayOnLandingPageSwitchState);
  };

  const res = useSelector((state) => state.createCategory);
  const handleSwitchChange = () => {
    setNutritionalSwitchState(!nutritionalSwitchState);
  };

  const [selectedImage, setSelectedImage] = useState(null);
  const [sendImage, setSendImage] = useState(null);

  const fileInputRef = useRef(null);

  const handleClearImage = () => {
    setSelectedImage(null);
  };
  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      setSendImage(file);

      reader.readAsDataURL(file);
    }
  };

  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(false);
  const [descriptionFlag, setDescriptionFlag] = useState(false);

  useEffect(() => {
    if (res?.data || res?.error) {
      setFlag(true);
    }
  }, [res]);

  useEffect(() => {
    if (selectedImage) {
      setImageFlag(false);
    }
    if (editorContent) {
      setDescriptionFlag(false);
    }
  }, [selectedImage, editorContent]);
  const handleSubmit = (values, { setSubmitting }) => {
    const categoryData = {
      name: values.name,
      description: editorContent,

      display: displayOnLandingPageSwitchState,
      nutritional: nutritionalSwitchState,
    };

    if (editorContent) {
      if (selectedImage) {
        setFlag(false);

        const newFormData = new FormData();
        newFormData.append("categoryRequest", JSON.stringify(categoryData));
        newFormData.append("categoryImage", sendImage);
        dispatch(createCategoryRequest(newFormData));
      } else {
        setImageFlag(true);
      }
    } else {
      setDescriptionFlag(true);
    }
  };

  if (res.data?.data.status === 201) {
    Swal.fire({
      title: "Category created successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (res?.error) {
    Swal.fire({
      title: res?.error.data.response.data.errorMessage,
      icon: "error",
    }).then(() => {});
  }

  return (
    <>
      <div className="editProductMainBody">
        <div className="editCategoryHeader">Add Category</div>
        <div className="editCategoryBody">
          <Formik
            initialValues={{ name: "", Parent: "", Status: "" }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form className="editCategoryFormikBody">
                <div className="editCategoryName">Category Name</div>

                <label style={{ width: "90%" }}>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Category  Name"
                    className="editCategoryField"
                  />
                  <ErrorMessage
                    className="errorMessage"
                    name="name"
                    component="div"
                  />
                </label>
                <div className="editCategoryDescription">Description</div>
                <div style={{ width: "73vw" }}>
                  <MyQuill sendContentToParent={handleChildData} />
                </div>
                {descriptionFlag && (
                  <div style={{ marginTop: "3em" }} className="errorMessage">
                    Please enter description
                  </div>
                )}

                <div className="editSwitchBody2" style={{ marginTop: "3em" }}>
                  <div className="editCategorySwitchName">
                    Nutritional Value{" "}
                  </div>
                  <div className="editCategorySwitchButton">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={nutritionalSwitchState}
                            onChange={handleSwitchChange}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="editSwitchBody2">
                  <div className="editCategorySwitchName">
                    Display on Landing page{" "}
                  </div>
                  <div className="editCategorySwitchButton">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={displayOnLandingPageSwitchState}
                            onChange={handleSwitchDisplay}
                          />
                        }
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="editCategoryDescription">Image </div>

                {imageFlag && (
                  <div className="errorMessage">Please select a image</div>
                )}

                <div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {selectedImage && (
                    <div>
                      <img
                        src={selectedImage}
                        alt="Selected"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </div>

                <div style={{ marginTop: "2em" }}>
                  {!selectedImage && (
                    <button
                      className="editButtonProduct"
                      onClick={handleEditClick}
                    >
                      Add Image
                    </button>
                  )}
                  <button
                    onClick={handleClearImage}
                    className="clearButtonProduct"
                  >
                    Clear{" "}
                  </button>
                </div>

                <div className="buttonCreate">
                  <button
                    disabled={!flag}
                    className="editSubmitButton1"
                    type="submit"
                  >
                    {flag ? " Create" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CreateCategory;
