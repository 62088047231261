import * as types from "../../Config/Banner/BannerActionType"

export const deleteByIdRequest = (bool,id) => {
    return {
        type: types.DELETE_BY_ID_REQUEST,
        bool,
        id
    }
}

export const deleteByIdSuccess = (data) => {
    return {
        type: types.DELETE_BY_ID_SUCCESS,
        data:data.data
    }
}

export const deleteByIdFailure = (data) => {
    return {
        type: types.DELETE_BY_ID_FAILURE,
        data:data
    }
}