import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getAllProductAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getAllProductSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getAllProduct, action);
   
    yield put(actions.getAllProductSuccess(response,action));
  } catch (err) {
   
    yield put(actions.getAllProductFailure(err));
  }
}

export function* watchGetAllProductSaga() {
 
  yield takeLatest(types.GET_ALL_PRODUCT_REQUEST, getAllProductSaga);
}
