import * as types from "../../Config/actionType"

export const CreateTalkToDoctorRequest = (data) => {
    return {
        type: types.CREATE_TALK_TO_DOCTOR_REQUEST,
        data:data
    }
}

export const CreateTalkToDoctorSuccess = (data) => {
    return {
        type: types.CREATE_TALK_TO_DOCTOR_SUCCESS,
        data:data,
    }
}

export const CreateTalkToDoctorFailure = (data) => {
    return {
        type: types.CREATE_TALK_TO_DOCTOR_FAILURE,
        data:data,
    }
}