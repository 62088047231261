import * as types from "../../Config/actionType"

export const deleteAttachmentRequest = (data) => {
    return {
        type: types.DELETE_ATTACHMENT_REQUEST,
        data
    }
}

export const deleteAttachmentSuccess = (data) => {
    return {
        type: types.DELETE_ATTACHMENT_SUCCESS,
        data:data,
    }
}

export const deleteAttachmentFailure = (data) => {
    return {
        type: types.DELETE_ATTACHMENT_FAILURE,
        data:data,
    }
}