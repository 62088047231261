import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/getServiceDropdownAction";
import NetworkService from "../../../network/NetworkService";

export function* getServiceDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.getServiceDropdown, action);
    yield put(actions.getServiceDropdownSuccess(response));
  } catch (err) {
    yield put(actions.getServiceDropdownFailure(err));
  }
}

export function* watchGetServiceDropdownSaga() {
  yield takeLatest(types.GET_SERVICE_DROPDOWN_REQUEST, getServiceDropdownSaga);
}