import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "./ConsultationRequests.css";
import { consultationRequestsRequest } from "../../../redux/Actions/InHouseDoctor/ConsultationRequestsAction";
import { AcceptOrRejectRequest } from "../../../redux/Actions/InHouseDoctor/AcceptOrRejectRequestAction";
import YuvaLoader from "../../Organisms/Loader/YuvaLoader"
import storage from "../../../Utils/storage";

function ConsultationRequests() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(consultationRequestsRequest(id));
  }, []);
  const consultationRequests = useSelector(
    (state) => state.consultationRequests
  );

  const handleAccept = (id) => {
    const requestData = {
      id: id,
      bool: true,
    };
    dispatch(AcceptOrRejectRequest(requestData));
  };
  const handleReject = (id) => {
    const requestData = {
      id: id,
      bool: false,
    };
    dispatch(AcceptOrRejectRequest(requestData));
    
  };


  const AcceptOrReject=useSelector((state)=>state.AcceptOrRejectRequest)

  if(AcceptOrReject.data?.data?.status===200 && AcceptOrReject.data?.data?.data.message!==""){
window.location.reload();
  }


  useEffect(() => {
    if (consultationRequests?.data?.data.data.chatStatus === "IN_PROGRESS" && (storage.getStorage("requestType")==="CALL" || storage.getStorage("requestType")==="VIDEO")) {
      navigate(`/prescriptionForm/${id}`);
    }
    if (consultationRequests?.data?.data.data.chatStatus === "IN_PROGRESS" && storage.getStorage("requestType")==="CHAT") {
      window.open(
        "https://yuva.myfreshworks.com/crm/messaging/a/690916977775396/inbox/0/0",
        "_blank"
      );
      navigate(`/chatStatus/${id}`);
    }
    
    if (consultationRequests?.data?.data.data.chatStatus === "CANCELLED") {
      navigate("/consultationRequests");
    }
  }, [consultationRequests?.data?.data.data.chatStatus]);

  
  if(AcceptOrReject.isLoading){
    return <YuvaLoader />
  }
 
  return (
    <div >
      <div className="ConsultationRequestHeader">
        Consultation Requests - (1)
      </div>

      <div className="ConsultationRequestCard">
        <div className="ConsultationRequestInfoWrapper">
          <h1 className="ConsultationRequestInfo">
            Patient Name -{" "}
            {consultationRequests?.data?.data.data.relativeName === null
              ? consultationRequests?.data?.data.data.customerName
              : consultationRequests?.data?.data.data.relativeName}
          </h1>
          <h1 className="ConsultationRequestInfo">
            Gender - {consultationRequests?.data?.data?.data.gender}
          </h1>
          <h1 className="ConsultationRequestInfo">
            Health Problem -{" "}
            {consultationRequests?.data?.data?.data.healthConcern}
          </h1>
        </div>
        <div className="ConsultationRequestButtonWrapper">
          <button
            className="ConsultationRequestAcceptButton"
            onClick={() => {
              handleAccept(id);
            }}
          >
            Accept
          </button>
          <button
            className="ConsultationRequestRejectButton"
            onClick={() => {
              handleReject(id);
            }}
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConsultationRequests;
