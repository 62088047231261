import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { updateDoctorRequest } from "../../../redux/Actions/Doctors/updateDoctorAction";
import { viewAllHospitalNamesRequest } from "../../../redux/Actions/Hospitals/viewAllHospitalNamesAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import { Input } from "antd";
import CircularProgress from "@mui/material/CircularProgress";


const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  speciality: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z &.,]+$/, "Insert only normal character"),
  qualifications: Yup.string().required("Required Field"),
  experience: Yup.string().required("Required Field").max(2, "Not Valid"),
  hospitalId: Yup.string().required("Required Field"),
});

function ModalUpdateDoctor(props) {
  useEffect(() => {
    dispatch(viewAllHospitalNamesRequest());
  }, []);
  const [flag, setFlag] = useState(true);
 
  const viewHospitalNames = useSelector((state) => state.viewAllHospitalNames);
  const [validationsignaturePhoto, setvalidationsignaturePhoto] =
    useState(false);
  const [signaturePhoto, setSignaturePhoto] = useState({});
  const [clickUpload, setClickUpload] = useState(false);

  const [check, setCheck] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput3") &&
        document.getElementsByClassName("file-upload-select")[0]
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }

  waitForLoad("subm", function () {
    let fileInput3 = document.getElementById("fileUploadInput3");

    fileInput3.onchange = function () {
      let filename = fileInput3.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name3")[0];
      selectName.innerText = filename;
    };
  });

  const handleFileHospitalPhotoChange = (event) => {
    if (
      event.target.files[0]?.type === "image/png" ||
      event.target.files[0]?.type === "image/jpeg" ||
      event.target.files[0]?.type === "image/jpg"
    ) {
      setSignaturePhoto(event.target.files[0]);
      setvalidationsignaturePhoto(false);
      // setCheck(false);
    } else {
      setvalidationsignaturePhoto(true);
      // setCheck(false);
    }
  };

  const dispatch = useDispatch();
  const handleClose = () => props.open(false);
  const updateRes = useSelector((state) => state.updateDoctor)
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const showUploadField = () => {
    setClickUpload(true);
  };
  const handleSubmit = (values, { setSubmitting }) => {
    setCheck(true);
    setFlag(false)

    const DoctorData = {
      cost: parseInt(values.cost),
      experience: parseInt(values.experience),
      hospitalId: parseInt(values.hospitalId),
      name: values.name.trim(),
      qualifications: values.qualifications,
      rating: parseInt(values.rating),
      registrationNumber: values.registrationNumber,
      speciality: values.speciality.trim(),
      id: values.id,
    };

    const newFormData = new FormData();

    newFormData.append("doctorRequest", JSON.stringify(DoctorData));
    newFormData.append("signatureFile", signaturePhoto);
    dispatch(updateDoctorRequest(newFormData));
  };

  const res = useSelector((state) => state.updateDoctor);

  if (res.data?.data.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Doctor details updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 200 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Update Doctor
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              name: props.value.name,
              speciality: props.value.speciality,
              qualifications: props.value.qualifications,
              experience: props.value.experience,
              hospitalId: props.value.hospitalId,
              id: props.value.id,
              cost: props.value.cost,
              registrationNumber: props.value.registrationNumber,
              rating: props.value.rating,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type=""
                      name="name"
                      placeholder="Doctor Name"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type=""
                      name="speciality"
                      placeholder="Doctor Speciality"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="speciality"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="qualifications"
                      placeholder="Doctor Qualification"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="qualifications"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="experience"
                      placeholder="Years of Experience"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="experience"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="cost"
                      placeholder="Consultation Cost"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="cost"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="rating"
                      placeholder="Rating"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="rating"
                      component="div"
                    />
                  </label>
                  <Field
                    type="text"
                    name="registrationNumber"
                    placeholder="Registration Number"
                    className="inputField"
                  ></Field>

                  <ErrorMessage
                    className="errorMessageModalValidation"
                    name="registrationNumber"
                    component="div"
                  />
                  {props?.value?.filePath === null || clickUpload ? (
                    <>
                      <label>
                        <div class="file-upload">
                          <div class="file-upload-select">
                            <div class="file-select-button">
                              Upload Signature
                            </div>
                            <div class="file-select-name3">
                              Supported format .jpg and .png
                            </div>
                            <Input
                              type="file"
                              name="HospitalPhoto"
                              accept="image/png, image/jpeg ,image/jpg"
                              id="fileUploadInput3"
                              className="NetworkPageFormInputField"
                              // value={signaturePhoto}
                              onChange={(event) =>
                                handleFileHospitalPhotoChange(event)
                              }
                            />
                          </div>
                          {validationsignaturePhoto && (
                            <div className="errorFileValidationAddModal">
                              Please Select a valid .png or .jpeg File
                            </div>
                          )}
                        </div>

                        {signaturePhoto?.size / 1024 > 10240 && (
                          <div className="errorFileValidationAddModal">
                            {" "}
                            File Size should be less than 10mb
                          </div>
                        )}
                      </label>
                    </>
                  ) : (
                    <>
                      <div className="inputFieldSignature">DoctorSignature</div>
                    </>
                  )}
                  {props?.value?.filePath !== null && clickUpload === false && (
                    <>
                      <div
                        className="updatedoctorsignatureBtncheck"
                        onClick={showUploadField}
                      >
                        Update Signature
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    className="modalButton"
                    disabled={!flag || isSubmitting || validationsignaturePhoto}
                  >
                    {flag ? "   Update Doctor":
                    
                    <CircularProgress/>
                    
                    }
                 
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdateDoctor;
