import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/addServiceAction";
import NetworkService from "../../../network/NetworkService";

export function* addServiceSaga(action) {
  try {
   
    let response = yield call(NetworkService.addService, action);
   
    yield put(actions.addServiceSuccess(response));
  } catch (err) {
    yield put(actions.addServiceFailure(err));
  }
}

export function* watchAddServiceSaga() {
  yield takeLatest(types.ADD_SERVICE_REQUEST, addServiceSaga);
}
