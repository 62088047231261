import React,{useEffect,useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./ConsultationRequests.css";
import {consultationRequestsRequest} from "../../../redux/Actions/InHouseDoctor/ConsultationRequestsAction"

function ReasonForIncomplete() {
  const { id } = useParams();
 const [status,setStatus]=useState("");
const navigate = useNavigate();

  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(consultationRequestsRequest(id))
  },[])
  const consultationRequests=useSelector((state)=>state.consultationRequests);

  return (
    <div>
      <div className="ConsultationRequestHeader">Consultation Requests - (1)</div>
      
        <div className="ConsultationRequestCard">
          <div className="ConsultationRequestInfoWrapper">
            <h1 className="ConsultationRequestInfo">
              Patient Name - {consultationRequests?.data?.data.data.relativeName === null
          ? consultationRequests?.data?.data.data.customerName
          : consultationRequests?.data?.data.data.relativeName}
            </h1>
            <h1 className="ConsultationRequestInfo">
              Gender - {consultationRequests?.data?.data?.data.gender}
            </h1>
            <h1 className="ConsultationRequestInfo">
              Health Problem - {consultationRequests?.data?.data?.data.healthConcern}
            </h1>
            <h1 className="ConsultationRequestInfo">
              Reason - {consultationRequests?.data?.data?.data.incompleteReason}
            </h1>
          </div>
          <div className="ConsultationRequestButtonWrapper">
            {/* <button
              className="ConsultationRequestAcceptButton"
              onClick={() => {
                handleAccept(id);
              }}
            >
              Accept
            </button> */}
            {/* <button
              className="ConsultationRequestRejectButton"
              onClick={() => {
                handleReject(id);
              }}
            >
              Reject
            </button> */}
          </div>
        </div>
      
    </div>
  );
}

export default ReasonForIncomplete;
