import * as types from "../../Config/actionType";

export const createCategoryRequest = (data) => {

  return {
    type: types.CREATE_CATEGORY_REQUEST,
    data: data,
  };
};

export const createCategorySuccess = (data) => {
  return {
    type: types.CREATE_CATEGORY_SUCCESS,
    data: data,
  };
};

export const createCategoryFailure = (data) => {
  return {
    type: types.CREATE_CATEGORY_FAILURE,
    data: data,
  };
};
