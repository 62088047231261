import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css";
// import { BarChart } from '@mui/icons-material';

function NutritionalRisk_BarChart({nutritionalResponseDto}) {
 
  const options = {
    labels: ["High Risk", "Moderate", "Goods"],
    position: "Bottom",

    chart: {
      // height: 200,
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "85%",
        borderRadius: 7
      },
    },
    stroke: {
        show: true,
        width: 22,
        // alignItem:"center",
        // display:"flex",
        // marginLeft:"80px",
        colors: ['transparent']
      },
    // xaxis: {
    //     show: false,
    // axisTicks: {
    //     show: false
    //   },
    //   axisBorder: {
    //     show: false
    //   }},
    // plugins:{
    legend: {
      show: true,
      position: "top",
      floating: true,
      horizontalAlign: "left",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    // },
    dataLabels: {
      enabled: false,
    },
    // xaxis: {
    //   //   position: "top",
    //   show: false,
    //   floating: false,
    //   labels: {
    //     show: false,
    //   },
    // },
    yaxis: {
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 0,
      },
    },
    colors: ["#A53F2B", "#95990C", "#52608E"],
  };

  const series = [
    {
      name: ["Nutritional Risk"],

      data: [nutritionalResponseDto?.high, nutritionalResponseDto?.moderate, nutritionalResponseDto?.good],
    },
  ];

  return (
    <>
      <Chart
        options={options}
        series={series}
        type="bar"
        height="auto"
        className="apex-charts"
        dir="ltr"
      />
      <div>
        <div className="Nutri_Heading">Number of Employees In High Risk</div>
        <div className="Nutri_SubHeading">{nutritionalResponseDto?.high}</div>
      </div>
    </>
  );
}
export default NutritionalRisk_BarChart;
