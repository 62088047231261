import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/ProfessionalService/ProfessionalServiceAction"
import NetworkService from "../../../../network/NetworkService";

export function* networkProfessionalServiceSaga(action) {
  try {
    let response = yield call(NetworkService.networkProfessionalService,action);
    yield put(actions.networkProfessionalServiceSuccess(response));
  } catch (err) {
    yield put(actions.networkProfessionalServiceFailure(err));
  }
}

export function* watchProfessionalServiceSaga() {
  yield takeLatest(types.NETWORK_PROFESSIONAL_SERVICE_REQUEST, networkProfessionalServiceSaga);
}