import * as types from "../../Config/actionType"

export const updateLabRequest = (data) => {
    return {
        type: types.UPDATE_LAB_REQUEST,
        data:data
    }
}

export const updateLabSuccess = (data) => {
    return {
        type: types.UPDATE_LAB_SUCCESS,
        data:data,
    }
}

export const updateLabFailure = (data) => {
    return {
        type: types.UPDATE_LAB_FAILURE,
        data:data,
    }
}