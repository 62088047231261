import * as types from "../../Config/actionType"

export const checkNumberRequest = (data) => {
    return {
        type: types.CHECK_NUMBER_REQUEST,
        data:data
    }
}

export const checkNumberSuccess = (data) => {
    return {
        type: types.CHECK_NUMBER_SUCCESS,
        data:data,
    }
}

export const checkNumberFailure = (data) => {
    return {
        type: types.CHECK_NUMBER_FAILURE,
        data:data,
    }
}