import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Program/viewProgramAction";
import NetworkService from "../../../network/NetworkService";

export function* viewProgramSaga(action) {
  try {
     
    let response = yield call(NetworkService.viewProgram, action);
    yield put(actions.viewProgramSuccess(response));
  } catch (err) {
    yield put(actions.viewProgramFailure(err));
  }
}

export function* watchViewProgramSaga() {
  yield takeLatest(types.VIEW_PROGRAM_REQUEST, viewProgramSaga);
}