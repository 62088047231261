import * as types from "../../Config/actionType";

export const ViewOrderDropdownRequest = (data) => {
  return {
    type: types.VIEW_ORDER_DROPDOWN_REQUEST,
    data: data,
  };
};

export const ViewOrderDropdownSuccess = (data) => {
  return {
    type: types.VIEW_ORDER_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const ViewOrderDropdownFailure = (data) => {
  return {
    type: types.VIEW_ORDER_DROPDOWN_FAILURE,
    data,
  };
};
