import React, { useEffect, useState } from "react";
import "./FinalBookingPage.css";
import Footer from "../../Organisms/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { uploadReportRequest } from "../../../redux/Actions/Booking/uploadReportAction";
import { finishBookingRequest } from "../../../redux/Actions/Booking/finishBookingAction";
import { downloadAttachmentRequest } from "../../../redux/Actions/Booking/downloadAttachmentAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import Chip from "@mui/material/Chip";
import { Typography } from "@mui/material";
import { deleteAttachmentRequest } from "../../../redux/Actions/Booking/deleteAttachmentAction";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadPrescriptionRequest } from "../../../redux/Actions/Appointments/downloadPrescriptionAction";

function UnpaidView(props) {
  const [files, setFiles] = React.useState([]);

  const dispatch = useDispatch();

  const handleFinish = () => {
    dispatch(finishBookingRequest(props.res.data?.data.data.id));
  };

  const uploadAttachment = useSelector((state) => state.uploadReport);
  const downloadAttachment = useSelector((state) => state.downloadPrescription);
  const deleteAttachment = useSelector((state) => state.deleteAttachment);
  const finish = useSelector((state) => state.finishBooking);
  var someDate = new Date(props.res.data?.data.data.timeSlot);
  someDate = someDate.toLocaleString();

  if (uploadAttachment.data?.data.data.status === true) {
    swal({
      title: "Uploaded successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (deleteAttachment.data?.data.data.status === true) {
    swal({
      title: "Deleted successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (downloadAttachment.data?.data.status === 200) {
    swal({
      title: "Download File",
      icon: "info",
    }).then(() => {
      const url_1 = window.URL.createObjectURL(
        new Blob([downloadAttachment.data?.data.data], {
          type: "application/octet-stream",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      // link.target = "_blank";
      link.setAttribute("download", "Report.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }

  if (finish.data?.data.data.message === "Status updated successfully") {
    swal({
      title: "Status updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  const handleUpload = () => {
    if (files.length === 0) {
      swal({
        icon: "error",
        title: "Please select a file first!",
      });
    } else {
      const formData = new FormData();
      formData.append("file", files[0]);
      dispatch(uploadReportRequest(formData, props.res.data?.data.data.id));
    }
  };

  useEffect(() => {
    if (uploadAttachment.error?.data?.request?.status == 400) {
      swal({
        title: uploadAttachment.error?.data?.response?.data?.errorMessage,
        icon: "error",
      });
    }
  }, [uploadAttachment.error?.data?.request?.status]);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleView = (ind) => {
    const link = document.createElement("a");
    link.href = props.res.data?.data.data.attachmentList[ind]?.filePath;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = (ind) => {
    dispatch(
      downloadPrescriptionRequest(
        props.res.data?.data.data.attachmentList[ind].id
      )
    );
  };

  const handleDelete = (id) => {
    dispatch(deleteAttachmentRequest(id));
  };

  return (
    <div className="finalScreen">
      <div className="finalScreenHeading">
        <p>Bookings- {props.res.data?.data.data.id}</p>
      </div>
      <div className="finalScreenDetails">
        <div className="finalScreenDetailsName">
          <div className="finalScreenDetailsNameChild">
            <p>
              {props.res.data?.data.data.memberName === null
                ? props.res.data?.data.data.patientName
                : props.res.data?.data.data.memberName}
            </p>
            <>
              {props.res.data?.data.data.bookingStatus === "UNPAID" && (
                <Typography>
                  <Chip label="UNPAID" color="default" />
                </Typography>
              )}
            </>
          </div>
        </div>
        <div className="finalScreenDetailsDescription">
          <p className="finalScreenDetailsDescriptionChild1">
            About This Package
          </p>
          <p className="finalScreenDetailsDescriptionChild2">
            {props.res.data?.data.data.testOrPackageDescription}
          </p>
          <div className="personalDetails personalDetailsParent">
            <div className="field">
              <p>Patient Phone Number</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.patientPhoneNumber}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Patient Email</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.patientEmail}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Address</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.address}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Collection Time Slot</p>
            </div>
            <div className="value">
              <p> {someDate}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Lab Name</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.labName}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Lab Assistant</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.labAssistantName}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Lab Assistant Email</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.labAssistantEmail}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Lab Phone Number</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.labAssistantPhoneNumber}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Patient Location</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.patientLocation}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Package Name</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.packageName}</p>
            </div>
          </div>
          <div className="personalDetails">
            <div className="field">
              <p>Number of tests </p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.testCount}</p>
            </div>
          </div>
          <div className="UNpaidpersonalDetails">
            <div className="field">
              <p>Test Name</p>
            </div>
            <div className="value">
              <p> {props.res.data?.data.data.testName}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UnpaidView;
