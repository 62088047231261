import * as types from "../../Config/Product/ProductActionType";

export const getBrandDropdownRequest = (data) => {
  return {
    type: types.GET_BRAND_DROPDOWN_REQUEST,
    data
  };
};

export const getBrandDropdownSuccess = (data) => {
  return {
    type: types.GET_BRAND_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const getBrandDropdownFailure = (data) => {
  return {
    type: types.GET_BRAND_DROPDOWN_FAILURE,
    data: data,
  };
};
