import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/AddBookingAction"
import NetworkService from "../../../network/NetworkService";

export function* AddBookingSaga(action) {
  try {
    let response = yield call(NetworkService.AddBooking, action);
    yield put(actions.AddBookingSuccess(response));
  } catch (err) {
    yield put(actions.AddBookingFailure(err));
  }
}

export function* watchAddBookingSaga() {
  yield takeLatest(types.ADD_BOOKING_REQUEST, AddBookingSaga);
}