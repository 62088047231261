import * as types from "../../Config/actionType"

export const getMedTypeRequest = (data) => {
    return {
        type: types.GET_MED_TYPE_REQUEST,
        data:data
    }
}

export const getMedTypeSuccess = (data) => {
    return {
        type: types.GET_MED_TYPE_SUCCESS,
        data:data,
    }
}

export const getMedTypeFailure = (data) => {
    return {
        type: types.GET_MED_TYPE_FAILURE,
        data:data,
    }
}