import * as types from "../../Config/actionType";

export const addDiscountIndividualRequest = (data) => {
  return {
    type: types.ADD_DISCOUNT_INDIVIDUAL_REQUEST,
    data: data,
  };
};

export const addDiscountIndividualSuccess = (data) => {
  return {
    type: types.ADD_DISCOUNT_INDIVIDUAL_SUCCESS,
    data: data,
  };
};

export const addDiscountIndividualFailure = (data) => {
  return {
    type: types.ADD_DISCOUNT_INDIVIDUAL_FAILURE,
    data: data,
  };
};
