import { Box } from '@mui/material';
import Switch from "@mui/material/Switch";
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBannerByTypeRequest } from "../../../../redux/Actions/Banner/getAllBannerByTypeAction";
import { deleteByIdRequest } from '../../../../redux/Actions/Banner/deleteByIdAction';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import swal from 'sweetalert';

function TypeBBanner({ position, screenType, screenTypeEnum }) {

    const dispatch = useDispatch()
    const getAllBannerByType = useSelector((state) => state.banner.getAllBannerByType)
    const deleteById = useSelector((state) => state.banner.deleteById)

    const columns = [
        {
            field: "innerBannerName",
            headerClassName: "super-app-theme--header",
            headerName: "Banner Name",
            flex: 3.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "description",
            headerClassName: "super-app-theme--header",
            headerName: "Description",
            flex: 3,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "contentType",
            headerClassName: "super-app-theme--header",
            headerName: "Content Type",
            flex: 1.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "coupon",
            headerClassName: "super-app-theme--header",
            headerName: "Coupon Code",
            flex: 1.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "positionInCarousal",
            headerClassName: "super-app-theme--header",
            headerName: "Position in Carousel",
            flex: 1.5,
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: "Modify",
            headerClassName: "super-app-theme--header",
            headerName: "Modify",
            flex: 1.5,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                     <div className="modify">
                        <Box>
                            {getAllBannerByType.data?.data.innerBannerResponseDto[cellValues.api.getRowIndex(cellValues.row.id)]?.visible === false ? (
                                <div>
                                    <Switch sx={{ color: "#52608E" }} onClick={() => handleDisable(cellValues.row.id)} />
                                </div>
                            ) : (
                                <div>
                                    {" "}
                                    <Switch
                                        defaultChecked
                                        sx={{ color: "#52608E" }}
                                        onClick={() => handleDisable(cellValues.row.id)}
                                    />{" "}
                                </div>
                            )}
                        </Box>
                        <DeleteForeverRoundedIcon onClick={() => handleDelete(cellValues.row.id)} style={{ cursor: "pointer", marginRight: "1em", marginLeft: "1em" }} />
                    </div >
                );
            },
        },
    ]

    useEffect(() => {
        dispatch(getAllBannerByTypeRequest("TYPE_B", position, screenTypeEnum))
    }, [position])

    useEffect(() => {
        if (deleteById.data?.data.status === true) {
            swal({
                title: deleteById.data.data.message,
                icon: "success",
            }).then(() => {
                window.location.reload()
            })
        } else if (deleteById.error !== null) {
            swal({
                title: "Something Went Wrong",
                icon: "error",
            }).then(() => {
                window.location.reload()
            })
        }
    }, [deleteById])

    const handleDisable = (id) => {
        dispatch(deleteByIdRequest(false, id))
    }
    const handleDelete = (id) => {
        dispatch(deleteByIdRequest(true, id))
    }

    return (
        <div>
            <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
                {getAllBannerByType.data?.data.innerBannerResponseDto &&
                    <DataGrid
                        sx={{
                            color: "#1D2334",
                            "& .super-app-theme--header": {
                                backgroundColor: "#52608E",
                                color: "#F2EFEA",
                                "& > .MuiDataGrid-columnSeparator": {
                                    visibility: "hidden",
                                },
                            },
                            "&:hover:not(.Mui-disabled)": {
                                cursor: "pointer",
                            },
                            ".MuiTablePagination-toolbar": {
                                alignItems: "baseline",
                                marginTop: "5%",
                            },
                        }}
                        disableColumnMenu
                        rows={getAllBannerByType.data?.data.innerBannerResponseDto}
                        columns={columns}
                        components={{
                            Footer: "none",
                        }}
                    />
                }
            </Box>

        </div>
    )
}

export default TypeBBanner