import React, { useEffect,useState } from "react";

import { getInsurerAssignedProgramsRequest } from "../../../redux/Actions/Agents/getInsurerAssignedProgramsAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";

function ViewProgramAssignedInsurer() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [totalLimit,setTotalLimit]=useState();
  const [subLimit,setSubLimit]=useState();
  useEffect(() => {
    dispatch(getInsurerAssignedProgramsRequest(id));
  }, []);
  const getInsurerAssignedPrograms = useSelector(
    (state) => state.getInsurerAssignedPrograms
  );
 

  useEffect(()=>{
    getInsurerAssignedPrograms?.data?.action.data.data.services.map((item)=>{
        if(item.serviceUuid==="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
            item.attributeCountDtoList.map((ele)=>{
                setTotalLimit(`₹ ${ele.topLimit} /-`);
                setSubLimit(`₹ ${ele.subLimit} /-`);
            })
        }
    })
},[getInsurerAssignedPrograms])
  return (
    <div>
      <div>
        <div className="mainHeadingCorporate">View Program</div>
        <div className="boxTopCorporate">
          <div className="boxTopNameCorporate">
            {getInsurerAssignedPrograms.data?.action.data.data.name}
          </div>
          <div className="boxTopBalanceBoxCorporate">
            Balance - {getInsurerAssignedPrograms.data?.action.data.data.balance}
          </div>

          {/* <div className="editCorporate">
            <EditIcon fontSize="medium"  />
            <a style={{color:"#1D2334"}}>Edit</a>
          </div> */}
        </div>
        {/* <hr style={{width:"80%",m:"auto auto"}}/> */}
        <div className="boxBottomCorporate">
          <div className="boxBottomNameCorporate">Breif Description</div>
         
          <div className="boxBottomDescCorporate">
            <p>{getInsurerAssignedPrograms.data?.action.data.data.description}</p>
          </div>
          <div className="boxBottomName">Relation :- <span className="RelationInsurer">{getInsurerAssignedPrograms.data?.action.data.data.relationsAllowed}</span></div>
          <div className="boxBottomName">{getInsurerAssignedPrograms.data?.action.data.data.childrenCount!==null?`Number of Children :- ${getInsurerAssignedPrograms.data?.action.data.data.childrenCount}`:""}</div>
          {/* <div className="boxBottomName">Relation-"{corporateGetAssignProgram.data?.data.data.data.relationsAllowed}"</div>
        <div className="boxBottomName">Number of Children-{corporateGetAssignProgram.data?.data.data.data.childrenCount}</div> */}
          <div className="mainTemplateCardCorporate">
            {getInsurerAssignedPrograms.data?.action.data.data.services.map(
              (item) => {
                if(item.serviceUuid!=="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
                  return (
                    <div className="ViewProgramtemplateCardCorporate">
                      <div className="AssignedviewProgramDisplay">{item.serviceName}</div>
                      <div className="AssignedviewProgramCorporate">
                       <div className="AssignedviewProgramCorporateField"> 
                        <div className="AssignedviewProgramCorporateFieldChild1">Assigned</div>
                        <div className="AssignedviewProgramCorporateFieldChild1">Remaining</div>
    </div>
                      {item.attributeCountDtoList.map((x) => (
    
                        <div className="AssignedviewProgramCorporateChild0">
                          <div className="AssignedviewProgramCorporateChild1">
                            {x.attributeName}
                          </div>
                          <div className="AssignedviewProgramCorporateChild2">
                            <div className="AssignedviewProgramCorporateChild1Part1">
                              {/* Assigned */}
                              <span>{x.attributeCount}</span>
                            </div>
                            <div className="AssignedviewProgramCorporateChild1Part1">
                              {/* Remaining */}
                             <span>{x.attributeCount}</span>
                            </div>
                          </div>
                        </div>
    
                      ))}
                      </div>
                    </div>
                  )
                }
                else{
                  return (
                    <div className="pharmacyServiceProgramDetail">
                    <div className="pharmacyService1Program">
                     Pharmacy
                    </div>
                    <div className="pharmacyService2">
                     <div className="pharmacyService2Child1">
                      <p>Total Limit</p>
                      <input type="text" value={totalLimit} disabled={true} />
                     </div>
                     <div className="pharmacyService2Child1">
                     <p>Sub Limit</p>
                      <input type="text" value={subLimit} disabled={true} />
                     </div>
                    </div>
                    <div className="pharmacyService3">
                    </div>
                  </div>
                  )
                }
              }
            )}
          </div>
        </div>

        <br />
        <br />
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default ViewProgramAssignedInsurer;
