import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import { getBrandDetailsRequest } from "../../../redux/Actions/Product/getBrandDetailsAction";

function ViewBrandDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandDetailsRequest(id));
  }, []);

  const details = useSelector((state) => state.getBrandDetails);

  return (
    <div>
      <div className="hospital-main">
        <h3 className="headerViewName">{details.data?.data.data.name}</h3>
        <div className="hospital-box">
          <div className="counts">
            <div className="divCount">
              {details.data?.data.data.active === false ? (
                <div className="disableChip">Disabled</div>
              ) : (
                <div className="docCount">
                  {details.data?.data.data.data.name}
                </div>
              )}
            </div>
          </div>
          <hr />

          <div className="body">
            <div className="hospitalDetails">
              <div className="keys">
                Id
                <br />
                <br />
                <br />
                <br />
                <br />

                Image 
              </div>
              <div className="valuess">
                {details?.data?.data.data.data.id}
                <br />
                <br />
                <img
                  src={details?.data?.data.data.data.filePath}
                  alt="Selected"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewBrandDetails;
