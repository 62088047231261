import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDiscountRequest } from "../../../redux/Actions/Discount/DeleteDiscountAction";
import { ViewDiscountRequest } from "../../../redux/Actions/Discount/ViewDiscountAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewDiscount.css";
import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Typography
  } from "@mui/material";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';

function ViewDiscountProduct() {
    const { id } = useParams();
  const { enumType } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteDiscount = useSelector((state) => state.deleteDiscount)
  const viewDiscountData = useSelector((state) => state.viewDiscount);


  useEffect(() => {
    let data = { id, enumType };
    dispatch(ViewDiscountRequest(data));
  }, [id]);



  const columns = [
    {
      field: "unit",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      disableColumnMenu: true,
      sortable: false,
      flex: .8,
    },
    {
      field: "unitEnum",
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: .8,
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: .8,
    },
    {
      field: "discountAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Amount",
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: .8,
    },
    {
      field: "discountPercentage",
      headerClassName: "super-app-theme--header",
      headerName: "Discount Percentage",
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      flex: .8,
     
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
  
    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      headerName: "View",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {cellValues.row.status !== false ?  <EditIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleClick(cellValues.row.priceId)}
            /> : ""}
            <DeleteIcon style={{color:"#52608E",cursor:"pointer"}} onClick={()=>handleDeleteDiscount(cellValues.row.priceId)}/>
          </div>
        );
      },
    },
  ];

  window.addEventListener('popstate', function (event) {
    navigate("/Discount")
  });


  const handleClick = (id) => {
    navigate(`/EditDiscountProduct/${id}`,{
        state: {
  
          enumType: enumType,
          details: viewDiscountData?.data?.data?.data?.data,
        },
      })
  }

  const handleEdit = (id) => {
    if(enumType==="PRODUCT"){
      navigate(`/EditDiscountProduct`, {
        state: {
  
          enumType: enumType,
          details: viewDiscountData?.data?.data?.data?.data,
        },
      });
    }
    else{
    navigate(`/EditDiscount`, {
      state: {

        enumType: enumType,
        details: viewDiscountData?.data?.data?.data?.data,
      },
    });
  }
  };

  const handleDeleteDiscount = (uuid) => {
    let submitdata = { uuid, enumType };
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDiscountRequest(submitdata));
      }
    })

  };



  if (
    deleteDiscount?.data?.action?.status === 200 &&
    deleteDiscount?.data?.action?.data.message !== ""
  ) {
    Swal.fire({
      title: `${deleteDiscount?.data?.action?.data.message}`,
      icon: "success",
    })
      .then(() => {
        deleteDiscount.data.action.status=null;
        navigate(`/Discount`);
        window.location.reload();
      });
  }
  return (
    <>
    <div className="appointmentmain">
        <h3 className="appId">View Discount</h3>
        <div className="appointment-box">
          <div className="appointmentbox-head">
            <div className="name">
              {viewDiscountData.data?.data.data.data.name}
            </div>
          </div>
          <hr />
           <div className='' style={{ zIndex: "1" }}>
        <Box sx={{ height: 600, width: "100%"}}>
          {viewDiscountData.data?.data.data.data
            .discountResponseDtoForProductPriceWiseList && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                getRowId={(row) => row.priceId}
               
                rows={
                    viewDiscountData.data?.data.data.data
                    .discountResponseDtoForProductPriceWiseList
                }
                
                columns={columns}
               
                rowsPerPageOptions={[10, 50, 100]}
               
                components={{
                  Footer: "none",
                }}
              />
            )}
        </Box>
        </div>
        </div>
      </div>
        <Footer />
  </>
  )
}

export default ViewDiscountProduct