import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/ConsultationRequestsAction";
import NetworkService from "../../../network/NetworkService";

export function* consultationRequests(action) {
  try {
    let response = yield call(NetworkService.consultationRequests, action);
    yield put(actions.consultationRequestsSuccess(response));
  } catch (err) {
    yield put(actions.consultationRequestsFailure(err));
  }
}

export function* watchConsultationRequests() {
  yield takeLatest(types.CONSULTATION_REQUESTS_REQUEST, consultationRequests);
}

