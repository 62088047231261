import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Pharmacy/PharmacyActionType";
import * as actions from "../../Actions/Pharmacy/AddPharmacyRequestAction"
import NetworkService from "../../../network/Pharmacy/PharmacyNetworkServices";

export function* addPharmacySaga(action) {
  try {
     
    let response = yield call(NetworkService.addPharmacy, action);
    yield put(actions.addPharmacySuccess(response));
  } catch (err) {
    yield put(actions.addPharmacyFailure(err));
  }
}

export function* watchAddPharmacySaga() {
  yield takeLatest(types.ADD_PHARMACY_REQUEST, addPharmacySaga);
}