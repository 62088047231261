import * as types from "../../Config/actionType"

export const viewAllServiceForCorporateRequest = () => {
   
    return {
        type: types.VIEW_ALL_SERVICE_FOR_CORPORATE_REQUEST,
    }
}

export const viewAllServiceForCorporateSuccess = (action) => {
    return {
        type: types.VIEW_ALL_SERVICE_FOR_CORPORATE_SUCCESS,
        action
    }
}

export const viewAllServiceForCorporateFailure = (action) => {
    return {
        type: types.VIEW_ALL_SERVICE_FOR_CORPORATE_FAILURE,
        action
    }
}