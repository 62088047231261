import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/viewAllHospitalNamesAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllHospitalNamesSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllHospitalNames, action);
    yield put(actions.viewAllHospitalNamesSuccess(response));
  } catch (err) {
    yield put(actions.viewAllHospitalNamesFailure(err));
  }
}

export function* watchViewAllHospitalNames() {
  yield takeLatest(types.VIEW_ALL_HOSPITAL_NAMES_REQUEST, viewAllHospitalNamesSaga);
}

