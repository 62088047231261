import * as types from "../../../Config/actionType"

export const viewAllTemplateDataRequest = (value) => {
    return {
        type: types.VIEW_ALL_TEMPLATE_DATA_REQUEST,
        value
    }
}

export const  viewAllTemplateDataSuccess = (action) => {
  // 
    return {
        type: types.VIEW_ALL_TEMPLATE_DATA_SUCCESS,
        action
    }
}

export const  viewAllTemplateDataFailure = (action) => {
   //
    return {
        type: types.VIEW_ALL_TEMPLATE_DATA_FAILURE,
        action
    }
}