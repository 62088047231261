import { applyMiddleware, createStore } from "redux";
import rootSaga from "./redux/Saga/rootSaga"
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./redux/Reducers/rootReducer";


const saga = createSagaMiddleware()

export const store = createStore(rootReducer, applyMiddleware(saga))

saga.run(rootSaga)


export default store