import * as types from "../../Config/actionType"

export const submitPrescriptionRequest = (data) => {
    return {
        type: types.SUBMIT_PRESCRIPTION_REQUEST,
        data:data
    }
}

export const submitPrescriptionSuccess = (data) => {
    return {
        type: types.SUBMIT_PRESCRIPTION_SUCCESS,
        data:data,
    }
}

export const submitPrescriptionFailure = (data) => {
    return {
        type: types.SUBMIT_PRESCRIPTION_FAILURE,
        data:data,
    }
}