import * as types from "../../Config/actionType"

export const viewHospitalDetailRequest = (id) => {
    return {
        type: types.VIEW_HOSPITAL_DETAIL_REQUEST,
        id
    }
}

export const viewHospitalDetailSuccess = (action) => {
    return {
        type: types.VIEW_HOSPITAL_DETAIL_SUCCESS,
        action
    }
}

export const viewHospitalDetailFailure = (action) => {
    return {
        type: types.VIEW_HOSPITAL_DETAIL_FAILURE,
        action
    }
}