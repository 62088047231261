import * as types from "../../../Config/actionType";

export const networkProfessionalServiceRequest = (
  page,
  pageSize,
  search,
  city,
  status,
  typeEnumList,
) => {
  return {
    type: types.NETWORK_PROFESSIONAL_SERVICE_REQUEST,
    page,
    pageSize,
    search,
    city,
    status,
    typeEnumList,
  };
};

export const networkProfessionalServiceSuccess = (data) => {
  return {
    type: types.NETWORK_PROFESSIONAL_SERVICE_SUCCESS,
    data: data,
  };
};

export const networkProfessionalServiceFailure = (data) => {
  return {
    type: types.NETWORK_PROFESSIONAL_SERVICE_FAILURE,
    data: data,
  };
};
