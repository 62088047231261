import * as types from "../../Config/Product/ProductActionType";

export const deleteBrandRequest = (data) => {
  return {
    type: types.DELETE_BRAND_REQUEST,
    data,
  
  };
};

export const deleteBrandSuccess = (data) => {
  return {
    type: types.DELETE_BRAND_SUCCESS,
    data: data,
  };
};

export const deleteBrandFailure = (data) => {
  return {
    type: types.DELETE_BRAND_FAILURE,
    data: data,
  };
};
