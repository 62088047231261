import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getPharmacyDetailRequest } from '../../../redux/Actions/Pharmacy/viewPharmacyDetailAction';
import Footer from '../../Organisms/Footer/FooterUpper'
import "./ViewPharmacyDetail.css"

function ViewPharmacyDetail() {
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPharmacyDetailRequest(id));
    }, [])

    const getPharmacyDetail = useSelector((state) => state.pharmacy.getPharmacyDetail);
    function isUrl(url) {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[^\s]*)?$/;
        return pattern.test(url);
      }

      const date = new Date(getPharmacyDetail?.data?.data?.data?.data.validity);
    return (
        <div>
            <div className="appointmentmain">
                <h3 className="appId">Pharmacy Details -
                    {getPharmacyDetail?.data?.data?.data?.data.id}
                </h3>
                <div className="appointment-box">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="viewBox-head">
                            {getPharmacyDetail?.data?.data?.data?.data.pharmacyName}
                        </div>
                    </div>
                    <hr />
                    <div className="corporate-box-body">
                        <div className="corporate-details">
                            <div className="corporate-keys">
                                Contact Person Name
                                <br />
                                <br />
                                Contact Person Number
                                <br />
                                <br />
                                Licence Number
                                <br />
                                <br />
                                Pharmacy Validity
                                <br />
                                <br />
                                Address
                                <br />
                                <br />
                                City
                                <br />
                                <br />
                                Pin-code
                                <br />
                                <br />
                                Map URL
                                <br />
                                <br />
                                Total Orders
                                <br />
                                <br />
                                Total Amount Collected
                                <br />
                                <br />
                                Total Amount Collected<br />
                                From Customers
                                <br />
                                <br />
                                Amount Collected From<br />
                                Yuva Health
                                <br />
                                <br />
                                Amount To Be Paid
                            </div>
                            <div className="corporate-value_Pharmacy">
                                {getPharmacyDetail?.data?.data?.data?.data.contactPersonName}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.contactPersonNumber}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.licenseNumber}
                                <br />
                                <br />
                                {date.toDateString()}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.address}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.city}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.pinCode}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.mapUrl &&
                                    isUrl(getPharmacyDetail?.data?.data?.data?.data.mapUrl) ? (
                                    <a
                                        className="tagcolor"
                                        target="_blank"
                                        href={getPharmacyDetail?.data?.data?.data?.data.mapUrl}
                                    >
                                        View On Map
                                    </a>
                                ) : (
                                    "----"
                                )}
                                <br />
                                <br />
                                {getPharmacyDetail?.data?.data?.data?.data.totalOrders}
                                <br />
                                <br />
                                Rs {getPharmacyDetail?.data?.data?.data?.data.totalAmount} /-
                                <br />
                                <br />
                                Rs {getPharmacyDetail?.data?.data?.data?.data.totalAmountCustomer} /-
                                <br />
                                <br />
                                <br />
                                Rs {getPharmacyDetail?.data?.data?.data?.data.amountYuva} /-
                                <br />
                                <br />
                                <br />
                                Rs {getPharmacyDetail?.data?.data?.data?.data.unPaidAmount} /-
                            </div>
                        </div>
                        <div className='pharmacyDetailButton'>
                            {/* <button >Mark as Finish</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <Footer />
        </div>
    )
}

export default ViewPharmacyDetail
