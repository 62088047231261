import * as types from "../../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const networkClinicReducer = (state = initialState, action = {}) => {
   
    switch (action.type) {

        case types.NETWORK_CLINIC_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
        case types.NETWORK_CLINIC_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.NETWORK_CLINIC_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}