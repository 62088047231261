import React, { useEffect } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addDoctorRequest } from "../../../redux/Actions/Doctors/addDoctorAction";
import { viewAllHospitalNamesRequest } from "../../../redux/Actions/Hospitals/viewAllHospitalNamesAction";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { modalHeader } from "./ModalConstants";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . ,]+$/, "Insert only normal character"),
  speciality: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . ,]+$/, "Insert only normal character"),
  qualifications: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z & . ,]+$/, "Insert only normal character"),
  experience: Yup.string().required("Required Field").max(2, "Not Valid"),
  cost: Yup.string()
    .matches(/^[0-9]+$/, "Input must contain only digits")
    .required("Required Field"),
});



function ModalAddDoctorHospital(props) {
  useEffect(() => {
    dispatch(viewAllHospitalNamesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const handleClose = () => props.open(false);

  const handleSubmit = (values, { setSubmitting }) => {
    const value = { ...values, hospitalId: props.props.id };
    setTimeout(() => {
      dispatch(addDoctorRequest(value));
      setSubmitting(false);
    }, 400);
  };

  const res = useSelector((state) => state.addDoctor);

  if (res.data?.data.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Doctor added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    props.open(false);
    Swal.fire({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Add New Doctor
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>
          <div className="DoctorInHospitalName">{props.props.name}</div>

          <Formik
            initialValues={{
              name: "",
              speciality: "",
              qualifications: "",
              experience: "",
              hospitalId: props?.id,
              cost: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type=""
                      name="name"
                      placeholder="Doctor Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type=""
                      name="speciality"
                      placeholder="Doctor Speciality"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="speciality"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="qualifications"
                      placeholder="Doctor Qualification"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="qualifications"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="experience"
                      placeholder="Years of Experience"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="experience"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="cost"
                      placeholder="Consultation Cost"
                      className="inputField"
                      onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="cost"
                      component="div"
                    />
                  </label>
                  {/* <label>
                 <Field
                      type=""
                      name="hospitalId"
                      placeholder={props.props.name}
                      // value={props?.name}
                      disabled
                      className="inputField"
                    />
                  </label> */}

                  <button type="submit" className="modalButton">
                    Add Doctor
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddDoctorHospital;
