import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/viewAllServiceForCorporateAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllServiceForCorporateSaga(action) {
   
  try {
    let response = yield call(NetworkService.viewAllServiceForCorporate, action);
   
    yield put(actions.viewAllServiceForCorporateSuccess(response));
  } catch (err) {
    yield put(actions.viewAllServiceForCorporateFailure(err));
  }
}

export function* watchViewAllServiceForCorporateSaga() {
  yield takeLatest(types.VIEW_ALL_SERVICE_FOR_CORPORATE_REQUEST, viewAllServiceForCorporateSaga);
}