import React from 'react'
import { useLocation } from 'react-router-dom'
import AddBannerTypeA from '../../Organisms/Banner/AddBannerTypeA'
import AddBannerTypeB from '../../Organisms/Banner/AddBannerTypeB'
import AddBannerTypeC from '../../Organisms/Banner/AddBannerTypeC'
import Footer from "../../Organisms/Footer/FooterUpper"

function AddBanner() {
    const location = useLocation()
    return (
        <div>
            {location.state.bannerTypeEnum.toLowerCase() === "type_a" && <AddBannerTypeA id={location.state.id}
                bannerId={location.state.bannerId}
                bannerName={location.state.bannerName}
                bannerType={location.state.bannerType}
                bannerPosition={location.state.bannerPosition}
                screenType={location.state.screenType}
                bannerTypeEnum={location.state.bannerTypeEnum}
                screenTypeEnum={location.state.screenTypeEnum} />}
            {location.state.bannerTypeEnum.toLowerCase() === "type_b" && <AddBannerTypeB id={location.state.id}
                bannerId={location.state.bannerId}
                bannerName={location.state.bannerName}
                bannerType={location.state.bannerType}
                bannerPosition={location.state.bannerPosition}
                screenType={location.state.screenType}
                bannerTypeEnum={location.state.bannerTypeEnum}
                screenTypeEnum={location.state.screenTypeEnum} />}
            {location.state.bannerTypeEnum.toLowerCase() === "type_c" && <AddBannerTypeC id={location.state.id}
                bannerId={location.state.bannerId}
                bannerName={location.state.bannerName}
                bannerType={location.state.bannerType}
                bannerPosition={location.state.bannerPosition}
                screenType={location.state.screenType}
                bannerTypeEnum={location.state.bannerTypeEnum}
                screenTypeEnum={location.state.screenTypeEnum} />}
                <Footer />
        </div>
    )
}

export default AddBanner