import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/getPlanDropdownAction";
import NetworkService from "../../../network/NetworkService";

export function* getPlanDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanDropdown, action);
    yield put(actions.getPlanDropdownSuccess(response));
  } catch (err) {
    yield put(actions.getPlanDropdownFailure(err));
  }
}

export function* watchGetPlanDropdownSaga() {
  yield takeLatest(types.GET_PLAN_DROPDOWN_REQUEST, getPlanDropdownSaga);
}