import React from "react";
import "./Footer.css";
import logo from "../../../assets/logo_primary.png";
import { SocialIcon } from "react-social-icons";
import background from "../../../assets/footer.jpg"

function Footer() {
  return (
    <div className="footerMain" style={{backgroundImage: `url(${background})`}}>
      <div className="footerPart1">
        <div>
          <img src={logo} alt="error" className="footerLogo" />
        </div>
        <div>you can write to us at</div>
        <div>
          <a
            href="mailto:contact@yuvahealth.in"
            style={{ color: "rgba(82, 96, 142, 1)", textDecoration: "none" }}
          >
            contact@yuvahealth.in
          </a>
        </div>
      </div>
      {/* <div className="circle"></div> */}

      <div className="footerPart2">
        <div className="footerPart2Top">
          <a href="/career" style={{ color: "#1D2334", textDecoration: "none" }}>
            Career
            
          </a>
          <a href="/TermsAndConditions" style={{ color: "#1D2334", textDecoration: "none" }}>
            Terms and Conditions
          </a>
          <a
            href="/PrivacyPolicy"
            style={{ color: "#1D2334", textDecoration: "none" }}
          >
            Privacy Policy
          </a>
        </div>

        <div className="footerPart2Bottom">
          <p className="bottom-footer">
            &copy; Yuva - {new Date().getFullYear()}
          </p>
        </div>
      </div>

      <div className="footerPart3">
        <a
          href="instagram"
          style={{ color: "#1D2334", textDecoration: "none" }}
        >
          <SocialIcon
            url="https://www.instagram.com/yuva_health/"
            target={"_blank"}
            fgColor="#252C41"
            bgColor="#EEF1F9"
            style={{ height: 35, width: 35 }}
          />
        </a>
        <a href="facebook" style={{ color: "#1D2334", textDecoration: "none" }}>
          <SocialIcon
            url="https://www.facebook.com/yuvahealthwellness"
            target={"_blank"}
            fgColor="#252C41"
            bgColor="#EEF1F9"
            style={{ height: 35, width: 35 }}
          />
        </a>
        <a href="twitter" style={{ color: "#1D2334", textDecoration: "none" }}>
          <SocialIcon
            url="https://twitter.com/"
            target={"_blank"}
            fgColor="#252C41"
            bgColor="#EEF1F9"
            style={{ height: 35, width: 35 }}
          />
        </a>
        <a href="linkedin" style={{ color: "#1D2334", textDecoration: "none" }}>
          <SocialIcon
            url="https://www.linkedin.com/company/yuvahealth/"
            target={"_blank"}
            fgColor="#252C41"
            bgColor="#EEF1F9"
            style={{ height: 35, width: 35 }}
          />
        </a>
      </div>
    </div>
  );
}

export default Footer;
