import * as types from "../../Config/Product/ProductActionType";

export const disableProductRequest = (data) => {
  return {
    type: types.DISABLE_PRODUCT_REQUEST,
    data:data
  };
};

export const disableProductSuccess = (data) => {
  return {
    type: types.DISABLE_PRODUCT_SUCCESS,
    data: data,
  };
};

export const disableProductFailure = (data) => {
  return {
    type: types.DISABLE_PRODUCT_FAILURE,
    data: data,
  };
};
