import * as types from "../../Config/actionType"

export const GetAllCardsDropdownRequest = (data) => {
   
    return {
        type: types.GET_ALL_CARDS_DROPDOWN_REQUEST,
        data
            
    }
}

export const GetAllCardsDropdownSuccess = (action) => {
   
    return {
        type: types.GET_ALL_CARDS_DROPDOWN_SUCCESS,
        action
    }
}

export const GetAllCardsDropdownFailure = (action) => {
   
    return {
        type: types.GET_ALL_CARDS_DROPDOWN_FAILURE,
        action
    }
}