import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/listOfConsultationsAction";
import NetworkService from "../../../network/NetworkService";

export function* listOfConsultations(action) {
  try {
    let response = yield call(NetworkService.listOfConsultations, action);
    yield put(actions.listOfConsultationsSuccess(response));
  } catch (err) {
    yield put(actions.listOfConsultationsFailure(err));
  }
}

export function* watchListOfConsultations() {
  yield takeLatest(types.LIST_OF_CONSULTATIONS_REQUEST, listOfConsultations);
}