import React from "react";
import Chart from "react-apexcharts";
// import { BarChart } from '@mui/icons-material';

function Illness_BarChart({illnessResponseDto}) {
  const options = {
    labels: ["Diabetes", "Hypertension","Cancer","Others"],


    chart: {
   
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        barHeight: "85%",
        // borderRadius: 7,
        columnWidth: "20%",

        
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
        position: "bottom",
      show: true,
      floating: false,
      labels: {
        show: true,
      },
    },
    yaxis: {
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 30,
        bottom: 10,
        left: 0,
      },
    },
    colors: ["#9CFF2E", "#F7EA00", "#53BF9D", "#FF8E00"],
  };

  const series = [
    {
name:"Illness",
      data: [illnessResponseDto?.diabetes,illnessResponseDto?.hyperTension,illnessResponseDto?.cancer,illnessResponseDto?.other],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="auto"
      className="apex-charts"
      dir="ltr"
    />
  );
}

export default Illness_BarChart;
