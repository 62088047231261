import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Banner/BannerActionType";
import * as actions from "../../Actions/Banner/deletePositionBannerAction";
import NetworkService from "../../../network/Banner/BannerNetworkServices";

export function* deletePositionBanner(action) {
  try {
    let response = yield call(NetworkService.deletePositionBanner, action);
    yield put(actions.deletePositionBannerSuccess(response));
  } catch (err) {
    yield put(actions.deletePositionBannerFailure(err));
  }
}

export function* watchDeletePositionBannerSaga() {
  yield takeLatest(types.DELETE_POSITION_BANNER_REQUEST, deletePositionBanner);
}
