import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/CreateCard/CreateCardNumberAction"
import NetworkService from "../../../network/NetworkService";

export function* createCardNumberSaga(action) {
  try {
    let response = yield call(NetworkService.createCardNumberService, action);
    yield put(actions.CreateCardNumberSuccess(response));
  } catch (err) {
    yield put(actions.CreateCardNumberFailure(err));
  }
}

export function* watchCreateCardNumberSaga() {
  yield takeLatest(types.CREATE_CARD_NUMBER_REQUEST, createCardNumberSaga);
}