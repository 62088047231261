import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import { Box } from "@mui/system";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import ModalUpdateLab from "../../Organisms/Modal/ModalUpdateLab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { viewAllLabRequest } from "../../../redux/Actions/Lab/ViewAllLabAction";
import { viewLabRequest } from "../../../redux/Actions/Lab/viewLabAction";
import storage from "../../../Utils/storage";
import ActiveDropdown from "../../Organisms/ActiveDropdown/ActiveDropdown";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DisableLabModal from "../../Organisms/Modal/DisableLabModal";

function Lab() {
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = useState();
  const [delOpen, setdelOpen] = React.useState(false);
  const [delId, setDelId] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  if (city === null) {
    setCity("");
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    setDelId(id);
    setdelOpen(true);
  };

  useEffect(() => {
    if (city === null) {
      dispatch(viewAllLabRequest(page, pageSize, searchText, city, status));
    } else {
      dispatch(viewAllLabRequest(page, pageSize, searchText, city, status));
    }
    dispatch(viewAllCityRequest());
  }, [city]);

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(viewAllLabRequest(page, pageSize, "", city, status));
      setPage(1)
    } else {
      dispatch(
        viewAllLabRequest(searchPage, pageSize, newSearchText, city, status)
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleEdit = (value) => {
   
    dispatch(viewLabRequest(value?.id));
    setValues(value);
    setOpen(true);
    setEdit(true);
  };

  const handleClick = (id) => {
    navigate(`/viewLab/${id}`);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(viewAllLabRequest(value, pageSize, searchText, city, status));
      setPage(value);
    } else {
      dispatch(
        viewAllLabRequest(value, pageSize, searchText, city, status)
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(viewAllLabRequest(page, event.target.value, searchText, city, status));
    } else {
      dispatch(
        viewAllLabRequest(searchPage, event.target.value, searchText, city, status)
      );
    }
    setPageSize(event.target.value);
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(viewAllLabRequest(page, pageSize, searchText, city, e.target.value));
    } else {
      dispatch(
        viewAllLabRequest(searchPage, pageSize, searchText, city, e.target.value)
      );
    }
    setStatus(e.target.value);
  };

  const viewAllLab = useSelector((state) => state.viewAllLab);
  const viewCity = useSelector((state) => state.viewAllCity);
  const viewLab = useSelector((state) => state.viewLab.data);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });
  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Name",
      minWidth: 130,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Address",
      minWidth: 180,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "contactEmail",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Email",
      minWidth: 140,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    {
      field: "contactPersonNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Contact No",
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "contactName",
      headerClassName: "super-app-theme--header",
      headerName: "Lab Contact Person",
      minWidth: 160,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },
    // {
    //   field: "",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Status",
    //   minWidth: 150,
    //   flex: 1,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   fontFamily: "Nunito",
    // },
    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
            {viewAllLab.data?.data?.data?.labResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <IconButton sx={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EditIcon sx={{ color: "gray" }} />
              </IconButton>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
            &nbsp;&nbsp;
            <Box>
              {viewAllLab.data?.data?.data?.labResponseDtos[
                cellValues.api.getRowIndex(cellValues.row.id)
              ]?.active === false ? (
                <div>
                  <Switch disabled sx={{ color: "#52608E" }} />
                </div>
              ) : (
                <div>
                  {" "}
                  <Switch
                    defaultChecked
                    sx={{ color: "#52608E" }}
                    onClick={() => handleDelete(cellValues.row.id)}
                  />{" "}
                </div>
              )}
            </Box>
          </div>
        );
      },
    },
  ];

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  return (
    <div>
      <div className="hospgrid">
        {edit && viewLab && <ModalUpdateLab value={viewLab} open={setEdit} />}
        {delOpen && <DisableLabModal delOpen={setdelOpen} delId={delId} />}
        <h4 className="h4">Labs</h4>
        <div className="mainBox">
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Labs</h5>
              <div className="hospitalcount">
                {viewAllLab.data?.data.data.totalLabs} Labs
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities" 

                cityList={cityNames}
              />
              <ActiveDropdown onChange={(e) => handleStatus(e)} />
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  className="searchField"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                    fontFamily: "Nunito",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
                <button
                  type="submit"
                  value="Submit"
                  className="corporatesearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                  }}
                >
                  <FilterAltIcon fontSize="small" />
                  Filter
                </button>
              </div> */}
              {/* <div className="searchButton">
                <form
                  onSubmit={(e) => {
                    handleSearch(e);
                  }}
                >
                  <button
                    type="submit"
                    value="Submit"
                    className="hospitalsearchBtn"
                    style={{
                      backgroundColor: "#52608E",
                      width: "150px",
                      align: "end",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Search
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <hr />
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewAllLab.data?.data.data.labResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  fontFamily: "Nunito",
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                rowCount={viewAllLab.data?.data.data.totalLabs}
                pagination
                disableColumnMenu
                rows={viewAllLab.data?.data.data.labResponseDtos}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                // onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // onSelectionModelChange={(itm) => handleClick(itm)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewAllLab.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Lab;
