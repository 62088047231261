import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/viewAllCorporateAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllCorporate(action) {
  try {
    let response = yield call(NetworkService.viewAllCorporate, action);
    yield put(actions.viewAllCorporateSuccess(response));
  } catch (err) {
    yield put(actions.viewAllCorporateFailure(err));
  }
}

export function* watchViewAllCorporate() {
  yield takeLatest(types.VIEW_ALL_CORPORATE_REQUEST, viewAllCorporate);
}

