import { combineReducers } from "redux";
import { addInsuranceAgentReducer } from "./Agents/addInsuranceAgent";
import { checkEmailAgentReducer } from "./Agents/checkEmailAgentReducer";
import { deleteAgentReducer } from "./Agents/deleteAgentReducer";
import { getUpdateInsurerReducer } from "./Agents/getUpdateInsurerReducer";
import { updateInsuranceAgentReducer } from "./Agents/updateInsuranceAgentReducer";
import { viewAgentDetailReducer } from "./Agents/viewAgentsDetailReducer";
import { viewAllAgentsReducer } from "./Agents/viewAllAgentsReducer";
import { addAppointmentsReducer } from "./Appointments/addAppointmentReducer";
import { allCityAppointmentsReducer } from "./Appointments/allCityAppointmentReducer";
import { cancelAppointmentReducer } from "./Appointments/cancelAppointmentReducer";
import { confirmAppointmentReducer } from "./Appointments/confirmAppointmentReducer";
import { deleteAppointmentsReducer } from "./Appointments/deleteAppointmentReducer";
import { rescheduleAppointmentReducer } from "./Appointments/rescheduleAppointmentReducer";
import { updateAppointmentsReducer } from "./Appointments/updateAppointmentsReducer";
import { validateOtpReducer } from "./Appointments/validateOtpReducer";
import { viewAllAppointmentsReducer } from "./Appointments/viewAllAppointmentsReducer";
import { viewAppointmentsReducer } from "./Appointments/viewAppointmentReducer";
import { cancelBookingReducer } from "./Booking/cancelBookingReducer";
import { confirmBookingReducer } from "./Booking/confirmBookingReducer";
import { finishBookingReducer } from "./Booking/finishBookingReducer";
import { makePackageReducer } from "./Booking/makePackageReducer";
import { rescheduleBookingReducer } from "./Booking/rescheduleBookingReducer";
import { uploadReportReducer } from "./Booking/uploadReportReducer";
import { viewAllBookingReducer } from "./Booking/viewAllBookingReducer";
import { viewAllTestsNameReducer } from "./Booking/viewAllTestsNameReducer";
import { viewBookingReducer } from "./Booking/viewBookingReducer";
import { viewAllCityReducer } from "./City/viewAllCityReducer";
import { addCorporateReducer } from "./Corporate/addCorporateReducer";
import { assignProgramToEmployeeReducer } from "./Corporate/assignProgramToEmployeeReducer";
import { deleteCorporateReducer } from "./Corporate/deleteCorporateReducer";
import { getUpdateCorporateReducer } from "./Corporate/getUpdateCorporateReducer";
import { updateCorporateReducer } from "./Corporate/updateCorporateReducer";
import { viewAllCorporateReducer } from "./Corporate/viewAllCorporateReducer";
import { viewCorporateDetailReducer } from "./Corporate/viewCorporateDetailReducer";
import { addDoctorReducer } from "./Doctors/addDoctorReducer";
import { addReportReducer } from "./Doctors/addReportReducer";
import { deleteDoctorReducer } from "./Doctors/deleteDoctorReducer";
import { deleteReportReducer } from "./Doctors/deleteReportReducer";
import { endAppointmentReducer } from "./Doctors/endAppointmentReducer";
import { searchDoctorReducer } from "./Doctors/searchDoctorReducer";
import { updateDoctorReducer } from "./Doctors/updateDoctorReducer";
import { addEmployeeFileReducer } from "./Employee/addEmployeeFileReducer";
import { addEmployeeReducer } from "./Employee/addEmployeeReducer";
import { deleteEmployeeReducer } from "./Employee/deleteEmployeeReducer";
import { fileTemplateReducer } from "./Employee/fileTemplateReducer";
import { getEmployeeProgramReducer } from "./Employee/getEmployeeProgramsReducer";
import { viewAllEmployeeProgramReducer } from "./Employee/viewAllEmployeeProgramReducer";
import { viewAllEmployeeReducer } from "./Employee/viewAllEmployeeReducer";
import { viewEmployeeDetailReducer } from "./Employee/viewEmployeeDetailReducer";
import { addHospitalReducer } from "./Hospitals/addHospitalReducer";
import { deleteHospitalReducer } from "./Hospitals/deleteHospitalReducer";
import { getUpdateHospitalReducer } from "./Hospitals/getUpdateHospitalReducer";
import { updateHospitalReducer } from "./Hospitals/updateHospitalReducer";
import { viewAllHospitalNamesReducer } from "./Hospitals/viewAllHospitalNamesReducer";
import { viewAllHospitalReducer } from "./Hospitals/viewAllHospitalReducer";
import { viewHospitalDetailReducer } from "./Hospitals/viewHospitalDetailReducer";
import { addLabReducer } from "./Lab/AddLabReducer";
import { viewAllLabReducer } from "./Lab/ViewAllLabReducer";
import { deleteLabReducer } from "./Lab/deleteLabReducer";
import { updateLabReducer } from "./Lab/updateLabReducer";
import { viewLabReducer } from "./Lab/viewLabReducer";
import { loginLabAgentReducer } from "./Login/loginLabAgentReducer";
import { loginReducer } from "./Login/loginReducer";
import { validateOtpLoginReducer } from "./Login/validateOtpLoginReducer";
import { viewInPackagesReducer } from "./Packages/viewInPackagesReducer";
import { viewPackagesReducer } from "./Packages/viewPackagesReducer";
import { viewAllPackageNamesReducer } from "./Program Template/Package/viewAllPackageNamesReducer";
import { addProgramTemplateReducer } from "./Program Template/Program/addProgramTemplateReducer";
import { assignProgramReducer } from "./Program Template/Program/assignProgramReducer";
import { getProgramTemplateReducer } from "./Program Template/Program/getProgramTemplateReducer";
import { viewAllProgramTemplateNamesReducer } from "./Program Template/Program/viewAllProgramTemplateNamesReducer";
import { viewAllTemplateDataReducer } from "./Program Template/Program/viewAllTemplateDataReducer";
import { viewAllServicesAttributesReducer } from "./Program Template/Services/viewAllServicesAttributesReducer";
import { viewAllServicesNameAndUuidReducer } from "./Program Template/Services/viewAllServicesNameAndUuidReducer";
import { addProgramReducer } from "./Program/addProgramReducer";
import { deleteProgramReducer } from "./Program/deleteProgramReducer";
import { getProgramReducer } from "./Program/getProgramReducer";
import { viewAllProgramReducer } from "./Program/viewAllProgramReducer";
import { viewProgramReducer } from "./Program/viewProgramReducer";
import { addServiceAttributeReducer } from "./Service/addServiceAttributeReducer";
import { addServiceReducer } from "./Service/addServiceReducer";
import { deleteServiceReducer } from "./Service/deleteServiceReducer";
import { getServiceReducer } from "./Service/getAllServiceReducer";
import { updateServiceAttributeReducer } from "./Service/updateServiceAttributeReducer";
import { updateServiceDescriptionReducer } from "./Service/updateServiceDescriptionReducer";
import { updateServiceTitleReducer } from "./Service/updateServiceTitleReducer";
import { viewAllServiceForCorporateReducer } from "./Service/viewAllServiceForCorporateReducer";
import { viewAllServiceReducer } from "./Service/viewAllServiceReducer";
import { viewServiceReducer } from "./Service/viewServiceRequest";
import { viewServiceReducer1 } from "./Service/viewServiceRequest1";
import { viewServiceReducer2 } from "./Service/viewServiceRequest2";
import { addTestReducer } from "./Tests/AddTestReducer";
import { updateTestReducer } from "./Tests/updateTestReducer";
import { viewAllTestReducer } from "./Tests/viewAllTestReducer";
import { viewTestReducer } from "./Tests/viewTestReducer";
// import { conmpleteBookingReducer } from "./Booking/completeBookingReducer";
import { addInitiateRefundReducer } from "./Accounts/AddInitiateRefundReducer";
import { ChangeOrderStatusReducer } from "./Accounts/ChangeOrderStatusReducer";
import { viewAccountReducer } from "./Accounts/ViewAccountsReducer";
import { ViewOrderDropdownReducer } from "./Accounts/ViewOrderDropdownReducer";
import { viewOrderReducer } from "./Accounts/ViewOrderReducer";
import { getInsurerAssignedProgramsReducer } from "./Agents/getInsurerAssignedProgramsReducer";
import { viewAllProgramOfInsurerReducer } from "./Agents/viewAllProgramOfInsurer";
import { downloadPrescriptionReducer } from "./Appointments/downloadPrescriptionReducer";
import { finishAppointmentReducer } from "./Appointments/finishAppointmentReducer";
import { programAndPlanReducer } from "./Appointments/programAndPlan";
import { relationshipReducer } from "./Appointments/relationship";
import { uploadReportAppointmentsReducer } from "./Appointments/uploadReportAppointmentsReducer";
import { userDetailReducer } from "./Appointments/userDetail";
import { AddBookingReducer } from "./Booking/AddBookingReducer";
import { AssignLabReducer } from "./Booking/AssignLabReducer";
import { completeBookingReducer } from "./Booking/completeBookingReducer";
import { deleteAttachmentReducer } from "./Booking/deleteAttachmentReducer";
import { downloadAttachmentReducer } from "./Booking/downloadAttachmentReducer";
import { viewAllLabsNamesReducer } from "./Booking/viewAllLabsNameReducers";
import { corporateGetAssignProgramReducer } from "./Corporate/getAssignProgramReducer";
import { corporateViewAssignProgramReducer } from "./Corporate/viewAssignProgramReducer";
import { CorporateDashboardReducer } from "./CorporateDashboard/DashboardOverviewReducer";
import { AddCouponReducer } from "./Coupon/AddCouponReducer";
import { DeleteCouponReducer } from "./Coupon/DeleteCouponReducer";
import { EditCouponReducer } from "./Coupon/EditCouponReducer";
import { GetAllCouponReducer } from "./Coupon/GetAllCouponReducer";
import { GetCouponDetailsReducer } from "./Coupon/GetCouponDetailsReducer";
import { addDiscountGroupReducer } from "./Discount/AddDiscountGroupReducer";
import { addDiscountIndividualReducer } from "./Discount/AddDiscountIndividualReducer";
import { deleteDiscountReducer } from "./Discount/DeleteDiscountReducer";
import { editDiscountReducer } from "./Discount/EditDiscountReducer";
import { getAllDiscountReducer } from "./Discount/GetAllDiscountReducer";
import { viewDiscountReducer } from "./Discount/ViewDiscountReducer";
import rootDoctorReducer from "./Doctors/rootDoctorReducer";
import { getMyTestsAndPackageReducer } from "./Employee/getMyTestsAndPackageReducer";
import { getRelationReducer } from "./Employee/getRelationReducer";
import { approveHospitalReducer } from "./Hospitals/approveHospitalReducer";
import { AcceptOrRejectRequestReducer } from "./InHouseDoctor/AcceptOrRejectRequestReducer";
import { chatStatusReducer } from "./InHouseDoctor/ChatStatusReducer";
import { consultationRequestsReducer } from "./InHouseDoctor/ConsultationRequestsReducer";
import { listOfConsultationsReducer } from "./InHouseDoctor/listOfConsultationsReducer";
import { submitPrescriptionReducer } from "./InHouseDoctor/submitPrescriptionReducer";
import { AssignProgramToInsurerReducer } from "./Insurer/AssignProgramToInsurerReducer";
import { getProgramInsurerReducer } from "./Insurer/getProgramInsurerReducer";
import { approveDiagnosticReducer } from "./Lab/approveDiagnosticReducer";
import { checkNumberReducer } from "./Login/checkNumberReducer";
import { approveClinicReducer } from "./Network/Clinic/ApproveClinicReducer";
import { approveProfessionalServiceReducer } from "./Network/ProfessionalServiceReducer/ApproveProfessionalServiceReducer";
import { networkProfessionalServiceReducer } from "./Network/ProfessionalServiceReducer/NetworkProfessionalServiceReducer";
import { networkViewProfessionalServiceReducer } from "./Network/ProfessionalServiceReducer/NetworkViewProfessionalServiceReducer";
import rootNetwork from "./Network/rootNetwork";
import { updatePackageFeatureReducer } from "./Packages/updatePackageFeatureReducer";
import { updatePackageReducer } from "./Packages/updatePackageReducer";
import { createLotReducer } from "./Payment/createLotReducer";
import { getAllLotsIdsReducer } from "./Payment/getAllLotsIdsReducer";
import { getAllLotsReducer } from "./Payment/getAllLotsReducer";
import { getLotByIdReducer } from "./Payment/getLotByIdReducer";
import { updatePaymentReducer } from "./Payment/updatePaymentReducer";
import { viewAllAppointmentsWithoutLotReducer } from "./Payment/viewAllAppointmentWithoutLotReducer";
import { viewAllPaymentReducer } from "./Payment/viewAllPaymentReducer";
import { planTypeDropdownReducer } from "./Plan/PlanTypeDropdownReducer";
import { addPlanReducer } from "./Plan/addPlanReducer";
import { assignProgramInsurerReducer } from "./Program Template/Program/assignProgramInsurerReducer";
import { deleteProgramTemplateReducer } from "./Program Template/Program/deleteProgramTemplateReducer";
import { viewAllUsersDropdownReducer } from "./Users/usersDropdownReducer";
import { viewAllUsersReducer } from "./Users/viewAllUsersReducer";

import { viewAllPlanReducer } from "./Plan/viewAllPlanReducer";
import { deletePlanReducer } from "./Plan/deletePlanReducer";
import { getPlanDetailReducer } from "./Plan/getPlanDetailReducer";
import { getDosageTypeReducer } from "./InHouseDoctor/getDosageTypeReducer";
import { getMedTypeReducer } from "./InHouseDoctor/getMedTypeReducer";
import { getDirectionReducer } from "./InHouseDoctor/getDirectionReducer";
import { downloadPrescriptionPdfReducer } from "./InHouseDoctor/downloadPrescriptionPdfReducer";
import { GetUserProgramReducer } from "./Users/UserProgramDetailsReducer";
import { GetUserPlanReducer } from "./Users/UserPlanDetailsReducer";
import { GetUserIndividualReducer } from "./Users/GetUserIndividualReducer";

import { AllHospitalNameDropdownReducer } from "./Dashboard/HospitalDashboard/AllHospitalNameDropdownReducer";
import { DoctorListDetailReducer } from "./Dashboard/HospitalDashboard/DoctorListDetailsReducer";
import { HospitalDasboardReducer } from "./Dashboard/HospitalDashboard/HospitalDashboardReducer";
import { HospitalViewDoctorReducer } from "./Dashboard/HospitalDashboard/HospitalViewDoctorReducer";
import { viewDropdownAccountReducer } from "./Accounts/viewDropdownReducer";
import rootPharmacyReducer from "./Pharmacy/rootPharmacyReducer";
import { AccountviewAllPaymentListingReducer } from "./Accounts/ViewAllPharmacyListingReducers";
import { addUserReducer } from "./Users/AddUserReducer";
import { checkEmailOrNumberReducer } from "./CreateTalkToDoctor/CheckEmailOrNumberReducer";
import { CreateTalkToDoctorReducer } from "./CreateTalkToDoctor/CreateTalkToDoctorReducer";
import { getScreenTypesReducer } from "./Banner/getScreenTypesReducer";
import { getBannerByScreenTypesReducer } from "./Banner/getBannerByScreenTypesReducer";
import { addBannerReducer } from "./Banner/addBannerReducer";
import { deleteBannerReducer } from "./Banner/deleteBannerReducer";
import { getBannerTypesReducer } from "./Banner/getBannerTypesReducer";
import { deletePositionBannerReducer } from "./Banner/deletePositionBannerReducer";
import rootBannerReducer from "./Banner/rootBannerReducer";
import { getServiceDropdownReducer } from "./Service/getServiceDropdownReducer";
import { getPackageDropdownReducer } from "./Packages/getPackageDropdownReducer";
import { getPlanDropdownReducer } from "./Plan/getPlanDropdownReducer";
import rootLifeStyleReducer from "./LifeStyle/rootLifeStyleReducer";
import { corporateDropdownReducer } from "./Corporate/CorporateDropdownReducer";
import { getPlanDropdownForCardReducer } from "./Plan/getPlanDropdownForCardReducer";
import { CreateCardNumberReducer } from "./CreateCard/CreateCardNumberReducer";
import { AssignCardSubscrptionReducer } from "./CreateCard/AssignCardSubscriptionReducer";
import { GetAllCardsDropdownReducer } from "./CreateCard/GetAllCardsDropdownReducer";
import { DownloadCardDetailReducer } from "./CreateCard/DownloadCardDetailReducer";
import { updatePlanReducer } from "./Plan/updatePlanReducer";
import { getBrandDropdownReducer } from "./Product/getBrandDropdownReducer";
import { getRelatedProductReducer } from "./Product/getRelatedProductReducer";
import { getUnitsReducer } from "./Product/getUnitsReducer";
import { getCategoryDropdownReducer } from "./Product/getCategoryDropdownReducer";
import { getSubCategoryDropdownReducer } from "./Product/getSubCategoryDropdownReducer";
import { addProductReducer } from "./Product/addProductReducer";
import { createCategoryReducer } from "./Products/createCategoryReducer";
import { getAllProductReducer } from "./Product/getAllProductReducer";
import { getProductReducer } from "./Product/getProductReducer";
import { editProductReducer } from "./Product/editProductReducer";
import { disableProductReducer } from "./Product/disableProductReducer";
import { getAllCategoryReducer } from "./Product/getAllCategoryReducer";
import { getCategoryDetailsReducer } from "./Product/getCategoryDetailsReducer";
import { getAllSubCategoryReducer } from "./Product/getAllSubCategoryReducer";
import { addSubCategoryReducer } from "./Product/addSubCategoryReducer";
import { addBrandReducer} from "./Product/addBrandReducer";
import { getAllBrandReducer} from "./Product/getAllBrandReducer";
import { getBrandDetailsReducer} from "./Product/getBrandDetailsReducer";
import { updateCategoryReducer} from "./Product/updateCategoryReducer";
import { updateSubCategoryReducer} from "./Product/updateSubCategoryReducer";
import { getSubCategoryDetailsReducer} from "./Product/getSubCategoryDetailsReducer";
import { updateBrandReducer} from "./Product/updateBrandReducer";
import { deleteCategoryReducer} from "./Product/deleteCategoryReducer";
import { deleteSubCategoryReducer} from "./Product/deleteSubCategoryReducer";
import { deleteBrandReducer} from "./Product/deleteBrandReducer";
import { editDiscountProductReducer } from "./Discount/EditDiscountProductReducer";
import { mapToElasticReducer } from "./Doctors/mapToElastic";
import { mapToElasticDoctorReducer } from "./Doctors/mapToElasticDoctor";






export default combineReducers({
  login: loginReducer,
  addDoctor: addDoctorReducer,
  addHospital: addHospitalReducer,
  doctor: rootDoctorReducer,
  // viewAllDoctors: viewAllDoctorsReducer,
  viewAllHospitals: viewAllHospitalReducer,
  viewAllAgents: viewAllAgentsReducer,
  deleteAgent: deleteAgentReducer,
  viewHospitalDetail: viewHospitalDetailReducer,
  deleteHospital: deleteHospitalReducer,
  viewAllCorporate: viewAllCorporateReducer,
  deleteCorporate: deleteCorporateReducer,
  viewAgentDetail: viewAgentDetailReducer,
  addInsuranceAgent: addInsuranceAgentReducer,
  deleteDoctor: deleteDoctorReducer,
  viewCorporateDetail: viewCorporateDetailReducer,
  viewAllAppointments: viewAllAppointmentsReducer,
  addCorporate: addCorporateReducer,
  updateDoctor: updateDoctorReducer,
  viewAllHospitalNames: viewAllHospitalNamesReducer,
  updateAppointment: updateAppointmentsReducer,
  updateCorporate: updateCorporateReducer,
  updateInsuranceAgent: updateInsuranceAgentReducer,
  updateHospital: updateHospitalReducer,
  viewAllService: viewAllServiceReducer,
  viewService: viewServiceReducer,
  addProgram: addProgramReducer,
  getProgram: getProgramReducer,
  loginLabAgent: loginLabAgentReducer,
  viewAllProgram: viewAllProgramReducer,
  viewAllEmployee: viewAllEmployeeReducer,
  viewProgram: viewProgramReducer,
  addService: addServiceReducer,
  deleteProgram: deleteProgramReducer,
  addEmployee: addEmployeeReducer,
  viewEmployeeDetail: viewEmployeeDetailReducer,
  deleteEmployee: deleteEmployeeReducer,
  addEmployeeFile: addEmployeeFileReducer,
  viewAppointment: viewAppointmentsReducer,
  viewService1: viewServiceReducer1,
  viewService2: viewServiceReducer2,
  endAppointment: endAppointmentReducer,
  addReport: addReportReducer,
  deleteReport: deleteReportReducer,
  getService: getServiceReducer,
  addAppointment: addAppointmentsReducer,
  confirmAppointment: confirmAppointmentReducer,
  rescheduleAppointment: rescheduleAppointmentReducer,
  cancelAppointment: cancelAppointmentReducer,
  validateOtp: validateOtpReducer,
  deleteAppointment: deleteAppointmentsReducer,
  updateServiceDescription: updateServiceDescriptionReducer,
  updateServiceTitle: updateServiceTitleReducer,
  addServiceAttribute: addServiceAttributeReducer,
  updateServiceAttribute: updateServiceAttributeReducer,
  addProgramTemplateReducer: addProgramTemplateReducer,
  viewAllProgramTemplateNamesReducer: viewAllProgramTemplateNamesReducer,
  viewAllTemplateDataReducer: viewAllTemplateDataReducer,
  viewAllPackageNamesReducer: viewAllPackageNamesReducer,
  viewAllServicesNameAndUuidReducer: viewAllServicesNameAndUuidReducer,
  viewAllServicesAttributesReducer: viewAllServicesAttributesReducer,
  getAllProgramTemplate: getProgramTemplateReducer,
  assignProgram: assignProgramReducer,
  assignProgramInsurer: assignProgramInsurerReducer,
  viewAllCity: viewAllCityReducer,
  getUpdateHospital: getUpdateHospitalReducer,
  getUpdateCorporate: getUpdateCorporateReducer,
  getUpdateInsurer: getUpdateInsurerReducer,
  deleteService: deleteServiceReducer,
  checkEmailAgent: checkEmailAgentReducer,
  allCityAppointment: allCityAppointmentsReducer,
  searchDoctor: searchDoctorReducer,
  viewAllServiceForCorporate: viewAllServiceForCorporateReducer,
  assignProgramToEmployee: assignProgramToEmployeeReducer,
  getEmployeeProgram: getEmployeeProgramReducer,
  viewAllEmployeeProgram: viewAllEmployeeProgramReducer,
  validateOtpLogin: validateOtpLoginReducer,
  fileTemplate: fileTemplateReducer,
  addLab: addLabReducer,
  addTest: addTestReducer,
  viewAllTestsName: viewAllTestsNameReducer,
  makePackage: makePackageReducer,
  viewAllLab: viewAllLabReducer,
  viewLab: viewLabReducer,
  deleteLab: deleteLabReducer,
  updateLab: updateLabReducer,
  uploadReport: uploadReportReducer,
  viewAllTest: viewAllTestReducer,
  viewPackages: viewPackagesReducer,
  viewBooking: viewBookingReducer,
  confirmBooking: confirmBookingReducer,
  rescheduleBooking: rescheduleBookingReducer,
  cancelBooking: cancelBookingReducer,
  viewTest: viewTestReducer,
  updateTest: updateTestReducer,
  viewInPackages: viewInPackagesReducer,
  viewAllBooking: viewAllBookingReducer,
  finishBooking: finishBookingReducer,
  updatePackage: updatePackageReducer,
  completeBooking: completeBookingReducer,
  downloadAttachment: downloadAttachmentReducer,
  uploadReportAppointments: uploadReportAppointmentsReducer,
  finishAppointment: finishAppointmentReducer,
  downloadPrescription: downloadPrescriptionReducer,
  listOfConsultations: listOfConsultationsReducer,
  consultationRequests: consultationRequestsReducer,
  submitPrescription: submitPrescriptionReducer,
  CorporateDashboard: CorporateDashboardReducer,
  AcceptOrRejectRequest: AcceptOrRejectRequestReducer,
  AssignLab: AssignLabReducer,
  viewAllLabsNames: viewAllLabsNamesReducer,
  deleteAttachment: deleteAttachmentReducer,
  viewAllPayment: viewAllPaymentReducer,
  chatStatus: chatStatusReducer,
  viewAllAppointmentsWithoutLot: viewAllAppointmentsWithoutLotReducer,
  createLot: createLotReducer,
  getAllLots: getAllLotsReducer,
  getLotById: getLotByIdReducer,
  updatePayment: updatePaymentReducer,
  getRelation: getRelationReducer,
  getAllLotsIds: getAllLotsIdsReducer,
  AddBooking: AddBookingReducer,
  getMyTestsAndPackage: getMyTestsAndPackageReducer,
  network: rootNetwork,
  approveHospital: approveHospitalReducer,
  approveDiagnostic: approveDiagnosticReducer,
  updatePackageFeature: updatePackageFeatureReducer,
  userDetail: userDetailReducer,
  relationship: relationshipReducer,
  programAndPlan: programAndPlanReducer,
  corporateViewAssignProgram: corporateViewAssignProgramReducer,
  corporateGetAssignProgram: corporateGetAssignProgramReducer,
  checkNumber: checkNumberReducer,
  viewAllProgramOfInsurer: viewAllProgramOfInsurerReducer,
  addPlan: addPlanReducer,
  deleteProgramTemplate: deleteProgramTemplateReducer,
  getInsurerAssignedPrograms: getInsurerAssignedProgramsReducer,
  approveClinic: approveClinicReducer,
  AssignProgramToInsurer: AssignProgramToInsurerReducer,
  getProgramInsurer: getProgramInsurerReducer,
  GetAllCoupon: GetAllCouponReducer,
  EditCoupon: EditCouponReducer,
  DeleteCoupon: DeleteCouponReducer,
  AddCoupon: AddCouponReducer,
  GetCouponDetails: GetCouponDetailsReducer,
  addDiscountGroup: addDiscountGroupReducer,
  addDiscountIndividual: addDiscountIndividualReducer,
  editDiscount: editDiscountReducer,
  getAllDiscount: getAllDiscountReducer,
  viewDiscount: viewDiscountReducer,
  deleteDiscount: deleteDiscountReducer,
  planTypeDropdown: planTypeDropdownReducer,
  networkProfessionalService: networkProfessionalServiceReducer,
  networkViewProfessionalService: networkViewProfessionalServiceReducer,
  approveProfessionalService: approveProfessionalServiceReducer,
  viewAccount: viewAccountReducer,
  addInitiateRefund: addInitiateRefundReducer,
  viewOrder: viewOrderReducer,
  ViewOrderDropdown: ViewOrderDropdownReducer,
  ChangeOrderStatus: ChangeOrderStatusReducer,
  ViewAllUsers: viewAllUsersReducer,
  ViewAllUsersDropdown: viewAllUsersDropdownReducer,
  viewAllPlan: viewAllPlanReducer,
  deletePlan: deletePlanReducer,
  getPlanDetail: getPlanDetailReducer,
  getDosageType: getDosageTypeReducer,
  getMedType: getMedTypeReducer,
  getDirection: getDirectionReducer,
  downloadPrescriptionPdf: downloadPrescriptionPdfReducer,
  GetUserIndividual: GetUserIndividualReducer,
  GetUserPlan: GetUserPlanReducer,
  GetUserProgram: GetUserProgramReducer,
  pharmacy: rootPharmacyReducer,
  AllHospitalNameDropdown: AllHospitalNameDropdownReducer,
  DoctorListDetail: DoctorListDetailReducer,
  HospitalDasboard: HospitalDasboardReducer,
  HospitalViewDoctor: HospitalViewDoctorReducer,
  viewDropdownAccount: viewDropdownAccountReducer,
  AccountviewAllPaymentListing: AccountviewAllPaymentListingReducer,
  addUser : addUserReducer,
  checkEmailOrNumber:checkEmailOrNumberReducer,
  createTalkToDoctor:CreateTalkToDoctorReducer,
  getScreenTypes:getScreenTypesReducer,
  getBannerByScreenTypes:getBannerByScreenTypesReducer,
  addBanner:addBannerReducer,
  deleteBanner:deleteBannerReducer,
  getBannerTypes:getBannerTypesReducer,
  deletePositionBanner:deletePositionBannerReducer,
  banner : rootBannerReducer,
  serviceDropdown: getServiceDropdownReducer,
  packageDropdown: getPackageDropdownReducer,
  planDropdown: getPlanDropdownReducer,
  lifeStyle: rootLifeStyleReducer,
  corporateDropdown:corporateDropdownReducer,
  getPlanDropdownForCard:getPlanDropdownForCardReducer,
  CreateCardNumber:CreateCardNumberReducer,
  AssignCardSubscrption:AssignCardSubscrptionReducer,
  GetAllCardsDropdown:GetAllCardsDropdownReducer,
  DownloadCardDetail:DownloadCardDetailReducer,
  updatePlan:updatePlanReducer,
  getBrandDropdown:getBrandDropdownReducer,
  getRelatedProduct:getRelatedProductReducer,
  getUnits:getUnitsReducer,
  getCategoryDropdown:getCategoryDropdownReducer,
  getSubCategoryDropdown:getSubCategoryDropdownReducer,
  addProduct:addProductReducer,
  createCategory: createCategoryReducer,
  getAllProduct:getAllProductReducer,
  getProduct:getProductReducer,
  editProduct:editProductReducer,
  disableProduct:disableProductReducer,
  getAllCategory:getAllCategoryReducer,
  getCategoryDetails: getCategoryDetailsReducer,
  getAllSubCategory: getAllSubCategoryReducer,
  addSubCategory:addSubCategoryReducer,
  addBrand:addBrandReducer,
  getAllBrand:getAllBrandReducer,
  getBrandDetails:getBrandDetailsReducer,
  updateCategory:updateCategoryReducer,
  updateSubCategory:updateSubCategoryReducer,
  getSubCategoryDetails:getSubCategoryDetailsReducer,
  updateBrand:updateBrandReducer,
  deleteCategory:deleteCategoryReducer,
  deleteSubCategory:deleteSubCategoryReducer,
  deleteBrand:deleteBrandReducer,
  editDiscountProduct:editDiscountProductReducer,
  mapToElastic:mapToElasticReducer,
  mapToElasticDoctor:mapToElasticDoctorReducer,
});
