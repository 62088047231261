import * as types from "../../Config/actionType"

export const viewAllServiceRequest = () => {
   
    return {
        type: types.VIEW_ALL_SERVICE_REQUEST,
    }
}

export const viewAllServiceSuccess = (action) => {
    return {
        type: types.VIEW_ALL_SERVICE_SUCCESS,
        action
    }
}

export const viewAllServiceFailure = (action) => {
    return {
        type: types.VIEW_ALL_SERVICE_FAILURE,
        action
    }
}