import * as types from "../../Config/Product/ProductActionType";

export const getCategoryDropdownRequest = (data) => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_REQUEST,
    data
  };
};

export const getCategoryDropdownSuccess = (data) => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const getCategoryDropdownFailure = (data) => {
  return {
    type: types.GET_CATEGORY_DROPDOWN_FAILURE,
    data: data,
  };
};
