import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "./LifeStyle.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { getBannerByScreenTypesRequest } from "../../../redux/Actions/Banner/getBannerByScreenTypesAction";
import ModalAddBanner from "../../Organisms/Modal/ModalAddBanner";
import { deleteBannerRequest } from "../../../redux/Actions/Banner/deleteBannerAction";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { deletePositionBannerRequest } from "../../../redux/Actions/Banner/deletePositionBannerAction";
import { useNavigate } from "react-router-dom";
import { viewAllLifeStyleRequest } from "../../../redux/Actions/LifeStyle/viewAllLifeStyleAction";
import { DeleteLifeStyleRequest } from "../../../redux/Actions/LifeStyle/DeleteLifeStyleAction";

function LifeStyle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fixed, setFixed] = useState();
  const [fromFixedDemo, setFromFixedDemo] = useState();
  const [screenType, setScreenType] = useState();
  const [activeKey, setActiveKey] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [bannerName, setBannerName] = useState();
  const [bannerEnumName, setBannerEnumName] = useState();

  const viewLifeStyle =useSelector((state) => state?.lifeStyle?.viewAllLifeStyle);


 
  
  const deletePositionBanner = useSelector(
    (state) => state.deletePositionBanner
  );

  function handleSelectChange(e) {
    localStorage.setItem("fixed", e.target.value)
    setFromFixedDemo(e.target.value);
  }

  useEffect(() => {
    const fwe = localStorage.getItem("fixed") === "true" ? true : false;
    setFixed(fwe);
  }, [fromFixedDemo]);

 

 

  useEffect(() => {
    if (fixed !== undefined) {
      dispatch(viewAllLifeStyleRequest(fixed));
      setActiveKey(null);
    }
  }, [fixed]);

  useEffect(() => {
    if (screenType !== undefined) {
      dispatch(getBannerByScreenTypesRequest(screenType));
    }
  }, [screenType]);

 

  function handleClose(bool) {
    setOpenModal(bool);
  }

 
  function addLifeStyle() {
    navigate(`/AddLifeStyle`);
  }

  function updateLifeStyle(id,name){
    
 
    navigate(`/updateLifeStyle/${id}`,{
      state:{
        name:name,
        fixed:fixed,
      }
    });
  }
  
  

  


  function handlePositionDelete(uuid) {
    Swal.fire({
      title: "Do you want to delete ?",
      showDenyButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = uuid;
        dispatch(DeleteLifeStyleRequest(data));
      } else if (result.isDenied) {
      }
    });
  }

  const DeleteLifeStyle=useSelector((state)=>state.lifeStyle.DeleteLifeStyle);

  if (
    DeleteLifeStyle.data?.action.status === 200 &&
    DeleteLifeStyle.data?.action.data.message !== ""
  ) {
    swal({
      title: `${ DeleteLifeStyle.data?.action.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  

 

  return (
    <div className="BannerListing">
      <ModalAddBanner
        open={openModal}
        handleClose={handleClose}
        bannerName={bannerName}
        bannerEnumName={bannerEnumName}
      />
      <div className="BannerListingSection1">
        <p>LifeStyle Packages</p>
      </div>

      <div className="BannerListingSection2">
        <div className="BannerListingSection2_1LifeStyle">
        {fixed === true ? <select
            name="selectOption"
            id="selectOption"
            onChange={handleSelectChange}
          >
            <option value="true" selected>
              Fixed
            </option>
            <option value="false">Dynamic</option>
          </select> : <select
            name="selectOption"
            id="selectOption"
            onChange={handleSelectChange}
          >
            <option value="true" >
              Fixed
            </option>
            <option value="false" selected>Dynamic</option>
          </select>}
          
          {fixed===false ?
              <div className="rightheadbtn">
                <div
                  type="button"
                  className="btnForLifeStyle"
                  onClick={addLifeStyle}
                >
                  + Add LifeStyle Package
                </div>
            </div>:""
          }
          
        </div>

        <div className="BannerListingSection2_2">
          {viewLifeStyle.data?.data.data.data.map((item, index) => (
            <>
              <div className="BannerListingSection2_2Children">
                <div className="BannerListingSection2_2ChildrenLeft">
                  <p>{item.displayName}</p>
                </div>
                <div className="BannerListingSection2_2ChildrenRight">
                  <EditIcon
                    className="edit"
                    sx={{ color: "#1D2334" ,marginRight:"2em"}}
                    onClick={()=>updateLifeStyle(item.enumName,item.displayName)}
                  />
                  {fixed===false && (
                    <div>
                      <DeleteIcon
                        sx={{
                          color: "#1D2334",
                          marginRight: "2vw",
                          marginTop: "0.5vw",
                          cursor:"pointer"
                        }}
                        onClick={()=>handlePositionDelete(item.enumName)}
                      />
                    </div>
                  )}

                </div>
              </div>
              
            </>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LifeStyle;
