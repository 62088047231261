import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/searchDoctorAction";
import NetworkService from "../../../network/NetworkService";

export function* searchDoctorSaga(action) {
  try {
   
    let response = yield call(NetworkService.searchDoctor, action);
   
    yield put(actions.searchDoctorSuccess(response));
  } catch (err) {
    yield put(actions.searchDoctorFailure(err));
  }
}

export function* watchSearchDoctorSaga() {
  yield takeLatest(types.SEARCH_DOCTOR_REQUEST, searchDoctorSaga);
}
