import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { modalHeader } from "./ModalConstants";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

import MyQuill from "../../Pages/Products/MyQuill";
import { useDispatch, useSelector } from "react-redux";

// import { addSubCategoryRequest } from "../../../redux/Actions/Product/addSubCategoryAction";
import { updateSubCategoryRequest } from "../../../redux/Actions/Product/updateSubCategoryAction";

function ModalUpdateSubCategory(props) {
  const details = props?.value.data.data.data;



  const dispatch = useDispatch();
  const handleClose = () => props.open(false);

  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
    description: Yup.string().required("Please enter description"),

  });
  const [nutritionalSwitchState, setNutritionalSwitchState] = useState(details?.data.nutritional);
  const [displayOnLandingPageSwitchState, setDisplayOnLandingPageSwitchState] =
    useState(details?.data.display);
  const [statuSwitchState, setStatuSwitchState] = useState(false);

  const [editorContent, setEditorContent] = useState("");

  const handleChildData = (data) => {
    setEditorContent(data);
  };


  const handleStatuSwitch = () => {
    setStatuSwitchState(!statuSwitchState);
  };
  const handleSwitchDisplay = () => {
    setDisplayOnLandingPageSwitchState(!displayOnLandingPageSwitchState);
  };

  const res = useSelector((state) => state.updateSubCategory);
  const handleSwitchChange = () => {
    setNutritionalSwitchState(!nutritionalSwitchState);
  };

  const [selectedImage, setSelectedImage] = useState(  details?.data.imageFilepath);
  const [sendImage, setSendImage] = useState(null);

  const fileInputRef = useRef(null);

  const handleClearImage = () => {
    setSelectedImage(null);
    setSendImage(null);

  };
  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      setSendImage(file);

      reader.readAsDataURL(file);
    }
  };

  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(false);
  const [descriptionFlag, setDescriptionFlag] = useState(false);
  useEffect(() => {
    if (res?.data || res?.error) {
      setFlag(true);
    }
  }, [res]);

  useEffect(() => {
    if (selectedImage) {
      setImageFlag(false);
    }
  }, [selectedImage]);


  const handleSubmit = (values, { setSubmitting }) => {
   
    const categoryData = {
      name: values.name,
      description: editorContent,

      display: displayOnLandingPageSwitchState,
      nutritional: nutritionalSwitchState,
    };


      if (selectedImage) {
        setFlag(false);

        const newFormData = new FormData();
        newFormData.append("subCategoryId", props?.value.data.data.data.data.id);

        newFormData.append("subCategoryRequest", JSON.stringify(categoryData));
        newFormData.append("subCategoryImage", sendImage);

        dispatch(updateSubCategoryRequest(newFormData, details?.data.id));
      } else {
        setImageFlag(true);
      }
    
  };

  if (res.data?.data.status === 200) {
    Swal.fire({
      title: "Sub-Category updated!",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (res?.error) {
    Swal.fire({
      title: res?.error.data.response.data.errorMessage,
      icon: "error",
    }).then(() => {});
  }

 

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <div className="editProductMainBody2">
          <div className="editCategoryBody">
            <div className="modalHeader1">
              <div
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modalHeaderText"
                sx={modalHeader}
              >
                Update Sub-Category
              </div>

              <button className="closeButtonModal" onClick={handleClose}>
                <Close />
              </button>
            </div>
            <Formik
              initialValues={{
                name: details?.data.name,
                description: details?.data?.description,
              }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <Form className="editCategoryFormikBody">
                  <div className="editCategoryName">Subcategory Name</div>

                  <label style={{ width: "90%" }}>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Subcategory  Name"
                      className="editCategoryField"
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="name"
                      component="div"
                    />
                  </label>
                  <div className="editCategoryDescription">Description</div>
                  <div style={{ width: "80%" }}>
                    <MyQuill sendContentToParent={handleChildData} desc={details?.data.description}/>
                  </div>
                 

                  <div className="editSwitchBody1">
                    <div style={{ marginTop: "4vw" }}>Nutritional Value </div>
                    <div className="editCategorySwitchButton1">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={nutritionalSwitchState}
                              onChange={handleSwitchChange}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="editSwitchBody1">
                    <div className="editCatsegorySwitchName">
                      Display on Landing page{" "}
                    </div>
                    <div className="editCategorySwitchButton1">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={displayOnLandingPageSwitchState}
                              onChange={handleSwitchDisplay}
                            />
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="editCategoryDescription">Image </div>
                  {imageFlag && (
                    <div className="errorMessage">Please select a Image</div>
                  )}

                  <div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    {selectedImage && (
                      <div>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    {!selectedImage && (
                      <button
                        className="editButtonProduct"
                        onClick={handleEditClick}
                      >
                        Add Image
                      </button>
                    )}

                    <button
                      onClick={handleClearImage}
                      className="clearButtonProduct"
                    >
                      Clear{" "}
                    </button>
                  </div>
                  <div style={{ marginTop: "2em" }} className="buttonCreate">
                    <button
                      disabled={!flag}
                      className="editSubmitButtons"
                      type="submit"
                    >
                      {flag ? " Update" : <CircularProgress />}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalUpdateSubCategory;
