import * as types from "../../Config/Product/ProductActionType";

export const addSubCategoryRequest = (data,id) => {
  return {
    type: types.ADD_SUB_CATEGORY_REQUEST,
    data,
    id,
  };
};

export const addSubCategorySuccess = (data) => {
  return {
    type: types.ADD_SUB_CATEGORY_SUCCESS,
    data: data,
  };
};

export const addSubCategoryFailure = (data) => {
  return {
    type: types.ADD_SUB_CATEGORY_FAILURE,
    data: data,
  };
};
