import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/checkEmailAgentAction";
import NetworkService from "../../../network/NetworkService";

export function* checkEmailAgentSaga(action) {
  try {
    let response = yield call(NetworkService.checkEmailAgent, action);
    yield put(actions.checkEmailAgentSuccess(response));
  } catch (err) {
    yield put(actions.checkEmailAgentFailure(err));
  }
}

export function* watchCheckEmailAgentSaga() {
  yield takeLatest(types.CHECK_EMAIL_AGENT_REQUEST, checkEmailAgentSaga);
}