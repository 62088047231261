import * as types from "../../Config/actionType"

export const approveHospitalRequest = (id, status,selectType,location,createPrescription) => {
    return {
        type: types.APPROVE_HOSPITAL_REQUEST,
        id,
        status,
        selectType,
        location,
        createPrescription
    }
}

export const approveHospitalSuccess = (action) => {
    return {
        type: types.APPROVE_HOSPITAL_SUCCESS,
        action
    }
}

export const approveHospitalFailure = (action) => {
    return {
        type: types.APPROVE_HOSPITAL_FAILURE,
        action
    }
}