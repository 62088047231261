import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/addAppointmentAction"
import NetworkService from "../../../network/NetworkService";

export function* addAppointmentSaga(action) {
  try {
     
    let response = yield call(NetworkService.addAppointment, action);
    yield put(actions.addAppointmentsSuccess(response));
  } catch (err) {
    yield put(actions.addAppointmentsFailure(err));
  }
}

export function* watchAddAppointmentSaga() {
  yield takeLatest(types.ADD_APPOINTMENT_REQUEST, addAppointmentSaga);
}