import { Route, Routes } from "react-router-dom";
import ModalAddBooking from "../../component/Organisms/Modal/ModalAddBooking";
import ViewAllAppointments from "../../component/Pages/Appointments/Appointment";
import ViewAppointment from "../../component/Pages/Appointments/ViewAppointment";
import Booking from "../../component/Pages/Booking/Booking";
import ViewBooking from "../../component/Pages/Booking/ViewBooking";
import Corporate from "../../component/Pages/Corporate/Corporate";
import ViewCorporate from "../../component/Pages/Corporate/ViewCorporate";
import ViewProgramAssigned from "../../component/Pages/Corporate/ViewProgramAssigned";
import ConsultationListing from "../../component/Pages/CustomerTalkToDoctor/ConsultationListing";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import ListOfPlan from "../../component/Pages/Plan/ListOfPlan";
import ViewPlanDetail from "../../component/Pages/Plan/ViewPlanDetail";
import AddProgramTemplate from "../../component/Pages/Program Template/AddProgramNew";
import AssignProgramTemplate from "../../component/Pages/Program Template/AssignProgramNew";
import ViewAssignedProgram from "../../component/Pages/Programs/ViewAssignedProgram";
import Users from "../../component/Pages/Users/users";
import ViewUser from "../../component/Pages/Users/ViewUser";
export default function CustomerServiceAgentPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      {/* Corporate */}
      <Route path="/customer-Service-Dashboard" element={<DashBoard />} />
      <Route path="/corporate" element={<Corporate />} />
      <Route path="/viewCorporateDetails/:id" element={<ViewCorporate />} />

      {/* Assign Program */}
      <Route path="/programTemplate" element={<AddProgramTemplate />} />
      <Route path="/programAssign" element={<AssignProgramTemplate />} />
      <Route path="/viewAssignedProgram" element={<ViewAssignedProgram />} />
      <Route
        path="/viewProgramAssigned/:id"
        element={<ViewProgramAssigned />}
      />

      {/* Appointment */}
      <Route path="/appointments" element={<ViewAllAppointments />} />
      <Route path="/viewAppointmentDetails/:id" element={<ViewAppointment />} />

      {/* Booking */}
      <Route path="/Booking" element={<Booking />} />
      <Route path="/ViewBookingDetails/:id" element={<ViewBooking />} />
      {/* plan dashboard */}
      <Route path="/ListOfPlan" element={<ListOfPlan />} />
      <Route path="/planDetails/:uuid" element={<ViewPlanDetail />} />

      {/* user */}
      <Route path="/Users" element={<Users />} />
      <Route path="/ViewUser/:id" element={<ViewUser />} />

      <Route path="/TalkToDoctor" element={<ConsultationListing />} />
      <Route path="/AddBooking" element={<ModalAddBooking />} />
    </Routes>
  );
}
