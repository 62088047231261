import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/disableProductAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* disableProductSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.disableProduct, action);
   
    yield put(actions.disableProductSuccess(response));
  } catch (err) {
   
    yield put(actions.disableProductFailure(err));
  }
}

export function* watchDisableProductSaga() {
 
  yield takeLatest(types.DISABLE_PRODUCT_REQUEST, disableProductSaga);
}
