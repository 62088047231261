import React, { useEffect,useState } from "react";
import "./ViewProgram.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProgramTemplateRequest } from "../../../redux/Actions/Program Template/Program/getProgramTemplateAction";
// import Footer from "../../Organisms/Footer/Footer";
import Footer from "../../Organisms/Footer/FooterUpper";


function ViewProgram() {
  const { name } = useParams();
  const dispatch = useDispatch();
  const [totalLimit,setTotalLimit]=useState();
  const [subLimit,setSubLimit]=useState();

  useEffect(() => {
    dispatch(getProgramTemplateRequest(name));
  }, []);

  const details = useSelector((state) => state.getAllProgramTemplate);

  useEffect(()=>{
    details?.data?.action.data.data.services.map((item)=>{
        if(item.serviceUuid==="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
            item.attributeCountDtoList.map((ele)=>{
                setTotalLimit(`₹ ${ele.topLimit} /-`);
                setSubLimit(`₹ ${ele.subLimit} /-`);
            })
        }
    })
},[details])

  return (
    <div>
      <div className="mainHeading">Program Details</div>

      <div className="boxTop">
        <div className="boxTopName">{details.data?.action.data.data.name}</div>
    
      </div>
      {/* <hr style={{width:"80%",m:"auto auto"}}/> */}
      <div className="boxBottom">
        <div className="boxBottomName">Breif Description</div>
        <div className="boxBottomDesc">
          <p>{details.data?.action.data.data.description}</p>
        </div>
        <div className="boxBottomName">Relation-{details.data?.action.data.data.relationsAllowed}</div>
        <div className="boxBottomName">Number of Children-{details.data?.action.data.data.childrenCount}</div>
        {/* <div className="boxBottomRelation">
          <p>{details.data?.action.data.data.relationsAllowed}</p>
        </div> */}
        {details.data?.action.data.data.services.map((item) => {
          if(item.serviceUuid!=="b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb"){
            return(
              <div className="templateCard">
              <div className="serviceName">{item.serviceName}</div>
              <div className="serviceDetails">
                {item.attributeCountDtoList.map((x) => (
                  <div className="attribute">
                    <p>{x.attributeName}</p>
                    <p>{x.attributeCount}</p>
                  </div>
                ))}
              </div>
            </div>
            )
          }
          else{
            return (
              <div className="pharmacyServiceProgramDetail">
              <div className="pharmacyService1Program">
               Pharmacy
              </div>
              <div className="pharmacyService2">
               <div className="pharmacyService2Child1">
                <p>Total Limit</p>
                <input type="text" value={totalLimit} disabled={true} />
               </div>
               <div className="pharmacyService2Child1">
               <p>Sub Limit</p>
                <input type="text" value={subLimit} disabled={true} />
               </div>
              </div>
              <div className="pharmacyService3">
              </div>
            </div>
            )
          }
         
})}
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewProgram;
