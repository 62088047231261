import * as types from "../../Config/LifeStyle/lifeStyleActionType"

export const viewAllLifeStyleRequest = (data) => {
    
    return {
        type: types.GET_LIFE_STYLE_REQUEST,
        data:data
    }
}

export const viewAllLifeStyleSuccess = (data) => {
    return {
        type: types.GET_LIFE_STYLE_SUCCESS,
        data:data
    }
}

export const viewAllLifeStyleFailure = (data) => {
    return {
        type: types.GET_LIFE_STYLE_FAILURE,
        data:data
    }
}