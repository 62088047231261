import * as types from "../../Config/actionType"

export const mapToElasticDoctorRequest = (data) => {
   
    return {
        type: types.MAP_TO_ELASTIC_DOCTOR_REQUEST,
        data:data
    }
}

export const mapToElasticDoctorSuccess = (data) => {
   

    return {
        type: types.MAP_TO_ELASTIC_DOCTOR_SUCCESS,
        data:data,
    }
}

export const mapToElasticDoctorFailure = (data) => {
   

    return {
        type: types.MAP_TO_ELASTIC_DOCTOR_FAILURE,
        data:data,
    }
}