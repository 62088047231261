import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import CorporateHrPrivateRoutes from "./privateRoute/CorporateHrPrivateRoutes";
import CustomerAgentPrivateRoutes from "./privateRoute/SuperAdminPrivateRoutes";
import InsuranceAgentPrivateRoutes from "./privateRoute/InsuranceAgentPrivateRoutes";
import DoctorPrivateRoutes from "./privateRoute/DoctorPrivateRoutes";
import PublicRoutes from "./publicRoute/publicRoutes";
import LabPrivateRoutes from "./privateRoute/LabPrivateRoutes";
import HospitalAdminPrivateRoutes from "./privateRoute/HospitalAdminPrivateRoutes";
import InHouseDoctorRoutes from "./privateRoute/InHouseDoctorPrivateRoutes";
import { roles } from "../helpers/MainConstants";
import storage from "../Utils/storage";
import ScrollToTop from "../component/Molecules/ScrollToTop/ScrollToTop";
import AccountsPrivateRoute from "./privateRoute/AccountsPrivateRoute";
import PharmacyPrivateRoute from "./privateRoute/PharmacyPrivateRoute";
import CustomerServiceAgentPrivateRoutes from "./privateRoute/CustomerServiceAgentPrivateRoute";
import CryptoJS from 'crypto-js';
import { UserContext } from "../App";

function AllRoutes() {

  const role = useContext(UserContext);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {role === null ? <PublicRoutes /> : ""}
      {role === roles.SUPER_ADMIN ? <CustomerAgentPrivateRoutes /> : ""}
      {role === roles.INSURER ? <InsuranceAgentPrivateRoutes /> : ""}
      {role === roles.CORPORATE_HR ? <CorporateHrPrivateRoutes /> : ""}
      {role === roles.DOCTOR ? <DoctorPrivateRoutes /> : ""}
      {role === roles.SUPER_LAB_ADMIN ? <LabPrivateRoutes /> : ""}
      {role === roles.HOSPITAL_ADMIN ? <HospitalAdminPrivateRoutes /> : ""}
      {role === roles.IN_HOUSE_DOCTOR ? <InHouseDoctorRoutes /> : ""}
      {role === roles.ACCOUNTS ? <AccountsPrivateRoute /> : ""}
      {role === roles.SUPER_PHARMA_ADMIN ? <PharmacyPrivateRoute /> : ""}
      {role === roles.CUSTOMER_SERVICE_AGENT ? <CustomerServiceAgentPrivateRoutes /> : ""}
    </BrowserRouter>
  );
}

export default AllRoutes;