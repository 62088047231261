export const GET_SCREEN_TYPES_REQUEST = "GET_SCREEN_TYPES_REQUEST";
export const GET_SCREEN_TYPES_SUCCESS = "GET_SCREEN_TYPES_SUCCESS";
export const GET_SCREEN_TYPES_FAILURE = "GET_SCREEN_TYPES_FAILURE";

export const GET_BANNER_BY_SCREEN_TYPES_REQUEST = "GET_BANNER_BY_SCREEN_TYPES_REQUEST";
export const GET_BANNER_BY_SCREEN_TYPES_SUCCESS = "GET_BANNER_BY_SCREEN_TYPES_SUCCESS";
export const GET_BANNER_BY_SCREEN_TYPES_FAILURE = "GET_BANNER_BY_SCREEN_TYPES_FAILURE";

export const ADD_BANNER_REQUEST = "ADD_BANNER_REQUEST";
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS";
export const ADD_BANNER_FAILURE = "ADD_BANNER_FAILURE";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAILURE = "DELETE_BANNER_FAILURE";

export const DELETE_POSITION_BANNER_REQUEST = "DELETE_POSITION_BANNER_REQUEST";
export const DELETE_POSITION_BANNER_SUCCESS = "DELETE_POSITION_BANNER_SUCCESS";
export const DELETE_POSITION_BANNER_FAILURE = "DELETE_POSITION_BANNER_FAILURE";

export const GET_BANNER_TYPES_REQUEST = "GET_BANNER_TYPES_REQUEST";
export const GET_BANNER_TYPES_SUCCESS = "GET_BANNER_TYPES_SUCCESS";
export const GET_BANNER_TYPES_FAILURE = "GET_BANNER_TYPES_FAILURE";
export const GET_ALL_BANNER_BY_TYPE_REQUEST = "GET_ALL_BANNER_BY_TYPE_REQUEST"
export const GET_ALL_BANNER_BY_TYPE_SUCCESS = "GET_ALL_BANNER_BY_TYPE_SUCCESS"
export const GET_ALL_BANNER_BY_TYPE_FAILURE = "GET_ALL_BANNER_BY_TYPE_FAILURE"

export const GET_ITEM_TYPE_REQUEST = "GET_ITEM_TYPE_REQUEST"
export const GET_ITEM_TYPE_SUCCESS = "GET_ITEM_TYPE_SUCCESS"
export const GET_ITEM_TYPE_FAILURE = "GET_ITEM_TYPE_FAILURE"

export const ADD_BANNER_DETAIL_REQUEST = "ADD_BANNER_DETAIL_REQUEST"
export const ADD_BANNER_DETAIL_SUCCESS = "ADD_BANNER_DETAIL_SUCCESS"
export const ADD_BANNER_DETAIL_FAILURE = "ADD_BANNER_DETAIL_FAILURE"

export const DELETE_BY_ID_REQUEST = "DELETE_BY_ID_REQUEST"
export const DELETE_BY_ID_SUCCESS = "DELETE_BY_ID_SUCCESS"
export const DELETE_BY_ID_FAILURE = "DELETE_BY_ID_FAILURE"
