import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/viewAllEmployeeProgram";
import NetworkService from "../../../network/NetworkService";

export function* viewAllEmployeeProgram() {
  try {
    let response = yield call(NetworkService.viewAllEmployeeProgram);
    yield put(actions.viewAllEmployeeProgramSuccess(response));
  } catch (err) {
    yield put(actions.viewAllEmployeeProgramFailure(err));
  }
}

export function* watchViewAllEmployeeProgramSaga() {
  yield takeLatest(types.VIEW_ALL_EMPLOYEE_PROGRAM_REQUEST, viewAllEmployeeProgram);
}