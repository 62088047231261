import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { DataGrid } from "@mui/x-data-grid";
import { set } from "date-fns";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteCouponRequest } from "../../../redux/Actions/Coupon/DeleteCouponAction";
import { GetAllCouponRequest } from "../../../redux/Actions/Coupon/GetAllCouponAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalAddCoupon from "../../Organisms/Modal/ModalAddCoupon";
import "./Coupon.css";

function Coupon() {
  const [add, setAdd] = React.useState(null);
  const [select, setSelect] = React.useState("All");
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [delId, setDelId] = useState();
  const [delOpen, setdelOpen] = React.useState(false);
  const [search, setSearch] = useState("");

  const deleteCouponSwitch = useSelector((state) => state.DeleteCoupon);



  const GetAllCoupon = useSelector((state) => state.GetAllCoupon);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(DeleteCouponRequest(id));


      }
      else {
        window.location.reload();
      }
    })
  };
  useEffect(() => {
    if (
      deleteCouponSwitch?.data?.action?.status === 200 &&
      deleteCouponSwitch?.data?.action?.data?.message !== ""
    ) {
      // swal(
      //   "Success",
      //   `${deleteCouponSwitch?.data?.action?.data?.message}`,
      //   "success"
      // ).then(() => {
      //   window.location.reload();
      // });
      window.location.reload();
    }
  }, [deleteCouponSwitch]);
  const columns = [
    {
      field: "couponName",
      headerClassName: "super-app-theme--header",
      headerName: "Coupon Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
    },
    {
      field: "couponCode",
      headerClassName: "super-app-theme--header",
      headerName: "Coupon Code",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.8,
    },
    {
      field: "maxDiscount",
      headerClassName: "super-app-theme--header",
      headerName: "Max Discounted Amount",
      disableColumnMenu: true,
      // minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="ColumnColourRed" style={{ color: "green", marginLeft: "25%" }}>
               {params.row["maxDiscount"]===null?"-":`₹${params.row["maxDiscount"]}`}
            </div>
          </>
        );
      },
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      disableColumnMenu: true,
      sortable: false,
      flex: .8,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      disableColumnMenu: true,
      sortable: false,
      flex: .8,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleDateString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "status",
      headerClassName: "super-app-theme--header",
      headerName: "status",
      disableColumnMenu: true,
      sortable: false,
      flex: .7,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {GetAllCoupon.data?.action?.data?.data.couponResponseDtoList[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.status === false ? (
              <div>
                <Switch
                  disabled
                  sx={{ color: "#52608E" }}
                />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      headerName: "View",
      disableColumnMenu: true,
      sortable: false,
      // flex: 1,
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {cellValues.row.status !== false ? <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2, cursor: "pointer" }}
              onClick={() => handleClick(cellValues.row.id)}
            /> : ""}
          </div>
        );
      },
    },
  ];

  const handleClick = (id) => {
    navigate(`/viewCoupon/${id}`)
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleSearch = (e) => {
    
    setSearch(e.target.value)
  }
  useEffect(() => {
    let data = {};
    if (select === "All") {
      data = {
        productType: "",
        search: search,
        page: page,
        pageSize: pageSize,
      }
    }
    else if (select === "PACKAGE") {
      data = {
        
        productType: "PACKAGE",
        search: search,
        page: page,
        pageSize: pageSize,
      }
    }
    else if (select === "TEST") {
      data = {
        productType: "TEST",
        search: search,
        page: page,
        pageSize: pageSize,
      }
    }
    else if (select === "PRODUCT") {
      data = {
        productType: "PRODUCT",
        search: search,
        page: page,
        pageSize: pageSize,
      }
    }
    else {
      data = {
        productType: "PLAN",
        search: search,
        page: page,
        pageSize: pageSize,
      }
    }
    dispatch(GetAllCouponRequest(data))
  }, [select, page, pageSize, search])



  function handleAdd() {
    setAdd(true)
  }

  function handleSelect(e) {
    setSelect(e.target.value);
    setPage(1)
  }
  return (
    <div className='coupenMain'>
      {add && <ModalAddCoupon open={setAdd} />}

      <div className='coupenChild1'>
        <p>Coupons</p>
      </div>
      <div className='coupenChild2'>
        <p>Coupon Lists</p>
        <button onClick={handleAdd}>
          + Add Coupons</button>
      </div>
      <div className='coupenChild3'>
        <div className='coupenChild3_1'>
          <select className="coupenChild3_1_select" onChange={handleSelect}>
            <option value="All">All</option>
            <option value="PACKAGE">Package</option>
            <option value="TEST">Test</option>
            <option value="PLAN">Plan</option>
            <option value="PRODUCT">Product</option>

          </select>
        </div>
        {/* <div className='coupenChild3_2'> */}
        <div className="searchBar">
          <TextField
            type="text"
            name="search"
            variant="outlined"
            sx={{
              borderRadius: "6px",
              border: "1px solid #52608E",
            }}
            placeholder="Search"
            size="small"
            style={{ marginRight: "3em" }}
            hiddenLabel="true"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#52608E" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* </div> */}
      </div>
      <div className='coupenChild4' style={{ zIndex: "1" }}>
        <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
          {GetAllCoupon.data?.action.data?.data
            .couponResponseDtoList && (
              <DataGrid
                // checkboxSelection
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                onSelectionModelChange={(itm) => handleSelect(itm)}
                pagination
                disableColumnMenu
                rowCount={
                  GetAllCoupon.data?.action.data?.data
                    .totalCoupons
                }
                rows={
                  GetAllCoupon.data?.action.data?.data
                    .couponResponseDtoList
                }
                columns={columns}
                // pageSize=pageSize
                rowsPerPageOptions={[10, 50, 100]}
                // onSelectionModelChange={(itm) => handleClick(itm)}
                components={{
                  Footer: "none",
                }}
              />
            )}
        </Box>

        <div className="MainTablePagination">
          <p>Rows Per Page:</p>
          <select
            className="dropDown"
            defaultValue={10}
            onChange={handleDropChange}
          >
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <Pagination
            count={
              GetAllCoupon.data?.action.data?.data.totalPages
            }
            
            // page={
            //   searchText === "" || searchText.length <= 1 ? page : searchPage
            // }
            // page={
            //   select === "All" ? page : value
            // }
            color="primary"
            onChange={handlePageChange}
            // page={page}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Coupon;
