import * as types from "../../../Config/actionType"

export const viewAllPackageNamesRequest = () => {
    return {
        type: types.VIEW_ALL_PACKAGE_NAMES_REQUEST,
    }
}

export const viewAllPackageNamesSuccess = (action) => {
    return {
        type: types.VIEW_ALL_PACKAGE_NAMES_SUCCESS,
        action
    }
}

export const viewAllPackageNamesFailure = (action) => {
             
    return {
        type: types.VIEW_ALL_PACKAGE_NAMES_FAILURE,
        action
    }
}