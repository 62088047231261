import React, { useEffect, useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addCorporateRequest } from "../..//../redux/Actions/Corporate/addCorporateAction";
import Close from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";

import { modalHeader, modalHeaderSub } from "./ModalConstants";
import { CircularProgress } from "@mui/material";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . & , ]+$/, "Insert only normal character"),
  contactNumber: Yup.string()
    .required("Required Field")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string().required("Required Field").email("Invalid Email"),
  contactName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  address: Yup.string().required("Required Field"),
  city: Yup.string().required("Required Field"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  pinCode: Yup.string()
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .required("Required Field"),
});

function ModalAddCorporate(props) {
  const handleClose = () => {
    // window.location.reload();
    props.open(false);
  };

  const INITIAL_STATE = {};
  const [matchApiCall, setMatchApiCall] = useState(false);
  const [inputList, setInputList] = useState([{ parameterName: "" }]);
  const [FormValidation, setFormValidation] = useState([INITIAL_STATE]);
  const [logoImage, setLogoImage] = useState();
const [domainValid,setDomainValid]=useState(false);
  const [matchApiRes, setMatchApiRes] = useState(null);

  const dispatch = useDispatch();
  const res = useSelector((state) => state.addCorporate);

  const [recvEmail, setRecvEmail] = useState();

  function hasDuplicateParameter(inputList, parameterName) {
    const parameterValues = new Set();
  
    for (const obj of inputList) {
      const parameterValue = obj[parameterName];
      if (parameterValues.has(parameterValue)) {
        return true; // Duplicate parameter value found, return true
      }
      parameterValues.add(parameterValue);
    }
  
    return false; // No duplicate parameter values found, return false
  }

  useEffect(()=>{
   if(inputList.length>=1 && inputList[0].parameterName!==""){
    const hasDuplicates = hasDuplicateParameter(inputList, 'parameterName');
    setDomainValid(hasDuplicates)
   }
  },[inputList])
  

  const updateRes = useSelector((state) => state.addCorporate)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    if(!domainValid){
      let csvString = [];

    inputList.map((index) => {
      csvString.push(index.parameterName.trim());
    });
    csvString= csvString.filter((item)=>{
      return item!==""
    })
    if (csvString.length === 0) {
      csvString = null;
    }

    const coporateData = {
      address: values.address,
      cityId: parseInt(values.city),
      contactPersonName: values.contactName,
      contactPersonNumber: values.contactNumber.toString(),
      contactPersonEmail: values.email,
      name: values.name,
      password: values.password,
      pinCode: values.pinCode.toString(),
      domains: csvString,
    }
    const formData = new FormData();
    formData.append("corporateRequest", JSON.stringify(coporateData));
    formData.append("logo", logoImage)

    setMatchApiRes(null);
    setMatchApiCall(true);
    setTimeout(() => {
      dispatch(addCorporateRequest(formData));
      setSubmitting(false);
    }, 400);
    }
    else{
      swal({
        title: "Please Fill Domain Carefully",
        icon: "error",
      })   }
    
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: "" }]);
    FormValidation.push(INITIAL_STATE);
  };

  useEffect(() => {
    if (matchApiCall) {
      if (matchApiRes !== null && matchApiRes) {
        handleEmailMobileValidation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchApiCall, matchApiRes]);

  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  const handleEmailMobileValidation = () => {
    if (matchApiRes?.data?.data?.status === 201) {
      props.open(false);
      Swal.fire({
        title: "Corporate Added Successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
        setMatchApiCall(false);
        setMatchApiRes(null);
      });
    } else if (
      matchApiRes?.error !== null &&
      matchApiRes?.error?.data?.response !== 201 &&
      matchApiRes?.error?.data?.response?.data !== null
    ) {
      swal({
        title: "" + Object.values(matchApiRes?.error?.data?.response?.data),
        icon: "error",
      }).then(() => {
        setMatchApiCall(false);
        setMatchApiRes(null);

        props.open(true);
      });
    }
  };

  const handleCheckEmail = (event) => {
    setRecvEmail(event.target.value);
  };

  const handleFileChange = (event) => {
    setLogoImage(event.target.files[0])
  }

  // eslint-disable-next-line
  const [fieldDisable, setFieldDisable] = useState(false);

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const emailDetails = emailDetail?.data?.data.data;

  useEffect(() => {
    emailDetails?.data ? setFieldDisable(true) : setFieldDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailDetails]);

  useEffect(() => {
    dispatch(viewAllCityRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewCity = useSelector((state) => state.viewAllCity);
 

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Corporate
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={
              emailDetails?.data === null
                ? {
                  name: "",
                  contactNumber: "",
                  email: recvEmail,
                  contactName: "",
                  address: "",
                  city: "",
                  password: "",
                  confirmPassword: "",
                  pinCode: "",

                }
                : {
                  name: "",
                  contactNumber: emailDetails?.data.number,
                  email: emailDetails?.data.email,
                  address: "",
                  city: "",
                  password: emailDetails?.data.password,
                  confirmPassword: emailDetails?.data.password,
                  contactName: emailDetails?.data.name,
                  pinCode: "",
                }
            }
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="email"
                      name="email"
                      // value={recvEmail}
                      placeholder=" Email"
                      className="inputField"
                      onBlur={(e) => {
                        handleCheckEmail(e);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="name"
                      placeholder=" Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder=" Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="pinCode"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      placeholder="Pincode"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select City
                      </option>
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="city"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="text"
                      name="contactName"
                      // disabled={fieldDisable}
                      placeholder="Contact Person Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Contact Person Number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>
                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Generate Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="password"
                        component="div"
                      />
                    </label>
                  )}

                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Retype Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="confirmPassword"
                        component="div"
                      />
                    </label>
                  )}
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                    sx={modalHeaderSub}
                  >
                    Domains
                  </Typography>
                  <div className="attributeCardBox">
                    {inputList.map((x, i) => {
                      return (
                        <>
                          <div className="addParameterWrapper">
                            <div>
                              <input
                                name="parameterName"
                                placeholder="Domain name"
                                id="parameterName"
                                className="inputFieldAddParameterFirst"
                                value={x.parameterName}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                              <p className="errorMessageModalValidatio">
                                {FormValidation[i]?.parameterName}
                              </p>
                            </div>
                            <div className="addParameterCross">
                              {inputList.length !== 1 && (
                                <button
                                  className="addParameterCrossButton"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  X
                                </button>
                              )}
                            </div>
                          </div>
                          <div>
                            {inputList.length - 1 === i && (
                              <button
                                onClick={() => {
                                  handleAddClick();
                                }}
                                className="addParameterButton"
                              >
                                + Add Domain
                              </button>
                            )}
                          </div>
                        </>
                      );
                    })}
                    {domainValid?<p style={{color:"red",marginLeft:"10%"}}>Please Don't repeat the Domains</p>:""}
                  </div>
                  <div>
                    <div style={{ marginBottom: "1em", fontSize: "1.2em" }}>
                      Logo
                    </div>
                    <input
                      type="file"
                      className="fileInput presupload"
                      onChange={(e) => handleFileChange(e)}
                      accept="image/png, image/jpeg ,image/jpg"
                    />
                  </div>
                  <button type="submit"   disabled={!flag}className="modalButton" style={res.isLoading?{background:"transparent"}:{}}>
                  {flag ? "   Add Corporate":
                    
                    <CircularProgress/>
                    }
                   
                   
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddCorporate;
