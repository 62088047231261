import React from 'react'
import './Appointments.css'

function Appointments(props) {
  return (
    <div className='booked'>
      <div className='dashboardbox'>
        <small>{props.text}</small>
        <h3 className='number'>{props.number}</h3>
      </div>
    </div>
  )
}

export default Appointments