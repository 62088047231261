import { Route, Routes } from "react-router-dom";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import Career from "../../component/Organisms/Footer/Career/Career";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions"
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy"
import Booking from "../../component/Pages/Booking/Booking"
import ViewTests from "../../component/Pages/Labs/ViewTests";
import Packages from "../../component/Pages/Packages/Packages";
import ViewBooking from "../../component/Pages/Booking/ViewBooking"
import ViewPackages from "../../component/Pages/Packages/ViewPackages";

import Lab from "../../component/Pages/Lab/Lab";
import Test from "../../component/Pages/Lab/Test";
import ViewLab from "../../component/Pages/Lab/viewLab";
import ViewTest from "../../component/Pages/Lab/viewTest";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound"
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import ModalAddBooking from "../../component/Organisms/Modal/ModalAddBooking";

export default function LabPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/lab-Dashboard" element={<DashBoard />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/Booking" element={<Booking />}/>
      <Route path="/ViewTests" element={<ViewTests />}/>
      <Route path="/Packages" element={<Packages />}/>
      <Route path="/ViewBookingDetails/:id" element={<ViewBooking />}/>
      <Route path="/ViewPackages/:uuid" element={<ViewPackages />}/>
      <Route path="/Lab" element={<Lab />}/>
      <Route path="/Test" element={<Test />}/>
      <Route path="/viewLab/:id" element={<ViewLab />} />
      <Route path="/viewTest/:id" element={<ViewTest />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}
      <Route path="/AddBooking" element={<ModalAddBooking />} />

    </Routes>
  );
}
