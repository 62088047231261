import * as types from "../../Config/actionType"

export const endAppointmentRequest = (data) => {
    return {
        type: types.END_APPOINTMENT_REQUEST,
        data:data
    }
}

export const endAppointmentSuccess = (data) => {
    return {
        type: types.END_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const endAppointmentFailure = (data) => {
    return {
        type: types.END_APPOINTMENT_FAILURE,
        data:data,
    }
}