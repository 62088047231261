
import * as types from "../../../Config/actionType"

export const HospitalViewDoctorRequest = (data) => {
    return {
        type: types.HOSPITAL_VIEW_DOCTOR_REQUEST,
        data:data,
    }
}

export const HospitalViewDoctorSuccess = (data) => {
    return {
        type: types.HOSPITAL_VIEW_DOCTOR_SUCCESS,
        data:data,
    }
}

export const HospitaLViewDoctorFailure = (data) => {
    return {
        type: types.HOSPITAL_VIEW_DOCTOR_FAILURE,
        data:data,
    }
}