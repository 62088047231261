import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { addDiscountIndividualRequest } from "../../../redux/Actions/Discount/AddDiscountIndividualAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import { viewDiscount } from "./DiscountConstants";
import "./ViewDiscount.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import { EditDiscountRequest } from "../../../redux/Actions/Discount/EditDiscountAction";
import CircularProgress from "@mui/material/CircularProgress";

function EditDiscount() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { enumType } = location.state;
  const { details } = location.state;

  const [totalAmountValue, SetTotalAmountValue] = useState(0);
  const [DiscountPercentageValue, SetDiscountPercentageValue] = useState(
    details.discountPercentage
  );
  const [DiscountAmountValue, SetDiscountAmountValue] = useState();

  const xyz = new Date(details.endDate);
  const [startDate, setStartDate] = useState(new Date(details.startDate));
  const [endDate, setEndDate] = useState(new Date(details.endDate));

  const addSchema = Yup.object().shape({
    DiscountPercentage: Yup.number()
      .min(0, "Discount Percentage must be greater than or equal to 0")
      .max(100, "Discount Percentage must be less than or equal to 100")
      .required("Required Field"),
    Description: Yup.string().required("Required Field"),
  });


  const HandleDiscountPercentage = (e) => {
    SetDiscountPercentageValue(e.target.value);
    SetDiscountAmountValue(e.target.value * 0.01 * totalAmountValue);
  };

  const HandleDiscountAmount = (e) => {
    SetDiscountAmountValue(e.target.value);
    SetDiscountPercentageValue((e.target.value / totalAmountValue) * 100);
  };

  const updateRes = useSelector((state) => state.EditDiscount)

  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const handleSubmit = (values) => {
    setFlag(false);
    var sdate = new Date(startDate);
    var edate = new Date(endDate);
    sdate = sdate.getTime();
    edate = edate.getTime();
    let submitData = {
      values,
      details,
      enumType,
      sdate,
      edate,
      DiscountPercentageValue,
    };
    dispatch(EditDiscountRequest(submitData));
  };

  const editDiscount = useSelector(
    (state) => state.editDiscount
  );

  if (
    editDiscount?.data?.data.status === 200 &&
    editDiscount?.data?.data?.data.message !== ""
  ) {
    Swal.fire({
      title: `${editDiscount?.data?.data?.data.message}`,
      icon: "success",
    })
      .then(() => {
        navigate(`/Discount`);
        window.location.reload();
      });
  }

  return (
    <>
      <div className="ViewDiscountWrapper">
        <h1 className="ViewDiscountHeader">
          {viewDiscount.EditDiscountHeader}
        </h1>
        <Formik
          initialValues={{
            TotalAmount: "",
            DiscountPercentage: "",
            DiscountAmount: "",
            Description: details.description,
          }}
          validationSchema={addSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="ViewDiscountInfoSection">
                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo1}
                  </h1>
                  <h1 className="ViewDiscountData">{details.name}</h1>
                </div>

                {enumType !== "Health_Plans" && (
                  <div className="ViewDiscountInfoIndividual">
                    <h1 className="ViewDiscountInfo">
                      {viewDiscount.ViewDiscountInfo2}
                    </h1>
                    <h1 className="ViewDiscountData">
                      ₹ {details.totalAmount}
                    </h1>
                  </div>
                )}

                {enumType === "Health_Plans" && (
                  <>
                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo7}
                      </h1>
                      <h1 className="ViewDiscountData">
                        ₹ {details.quarterly}
                      </h1>
                    </div>

                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo8}
                      </h1>
                      <h1 className="ViewDiscountData">
                        ₹ {details.halfYearly}
                      </h1>
                    </div>

                    <div className="ViewDiscountInfoIndividual">
                      <h1 className="ViewDiscountInfo">
                        {viewDiscount.ViewDiscountInfo9}
                      </h1>
                      <h1 className="ViewDiscountData">₹ {details.yearly}</h1>
                    </div>
                  </>
                )}

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo3}
                  </h1>
                  <label>
                    <Field
                      type="number"
                      name="DiscountPercentage"
                      placeholder="Discount Percentage"
                      value={DiscountPercentageValue}
                      className="AddDiscountInputField"
                      onChange={(e) => {
                        HandleDiscountPercentage(e);
                        setFieldValue("DiscountPercentage", e.target.value);
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="DiscountPercentage"
                      component="div"
                    />
                  </label>
                </div>

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo4}
                  </h1>

                  <label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Start Date"
                        minDateTime={new Date()}
                        inputFormat="dd/MM/yyyy hh:mm a"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="StartDate"
                      component="div"
                    />
                  </label>
                </div>

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo5}
                  </h1>
                  <label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="End Date"
                        minDateTime={startDate}
                        value={endDate}
                        inputFormat="dd/MM/yyyy hh:mm a"
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="EndDate"
                      component="div"
                    />
                  </label>
                </div>

                <div className="ViewDiscountInfoIndividual">
                  <h1 className="ViewDiscountInfo">
                    {viewDiscount.ViewDiscountInfo6}
                  </h1>
                  <label>
                    <Field
                      as="textarea"
                      placeholder="Description"
                      className="AddDiscountInputFieldDescription"
                      name="Description"
                      rows="4"
                      wrap="soft"
                    />
                    <ErrorMessage
                      className="ValidationAddDiscount"
                      name="Description"
                      component="div"
                    />
                  </label>
                </div>
                <div className="SubmitButtonWrapper">
                  {" "}
                  <button    disabled={!flag} type="submit" className="AddDiscountSubmitButton">
                  
                  {flag ? viewDiscount.EditDiscountButton:
                    
                    <CircularProgress/>
                    }
                   
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </>
  );
}

export default EditDiscount;
