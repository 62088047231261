import React from "react";
import "./index.css";
import gear from "../../../assets/gear.gif";
// import Footer from "../../Organisms/Footer/Footer";
import Footer from "../../Organisms/Footer/FooterUpper";

function UnderConstuction() {
  return (
    <div>
      <div className="underMain">
        <h1>Under Construction</h1>
        <img className="image" src={gear} alt="loading..." />
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default UnderConstuction;
