import React from "react";
import { Route, Routes } from "react-router-dom";
import Career from "../../component/Organisms/Footer/Career/Career";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions";
import SingleLogin from "../../component/Pages/Login/SingleLogin";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";
import UnderConstuction from "../../component/Pages/UnderConstruction/UnderConstruction";

export default function publicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/underConstruction" element={<UnderConstuction />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
}