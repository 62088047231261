import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
// import "./ViewTests.css";
// import Footer from "../../Organisms/Footer/Footer";
import Footer from "../../Organisms/Footer/FooterUpper";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewInLabRequest } from "../../../redux/Actions/Lab/viewInLabAction";
// import EditAgent from "./EditAgent";
import Swal from "sweetalert2";
import { deleteAgentRequest } from "../../../redux/Actions/Agents/deleteAgentAction";
import ModalUpdateInsuranceAgent from "../../Organisms/Modal/ModalUpdateInsuranceAgent";
import "./ViewTests.css"

function ViewTests() {
  const [edit, setEdit] = React.useState(false);
  const [values, setValues] = React.useState();
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewInLabRequest(id));
  }, [id]);
  const navigate = useNavigate();

  const handleClick = (value) => {
    setValues(value);
    setEdit(1);
  };
  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAgentRequest(id));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        navigate("/agents");
        window.location.reload();
      }
    });
  };
  const agent = useSelector((state) => state.viewInLab);
  return (
    <div>
      <div className="insurance-main">
        <div className="insurance-id">Test Name - {agent.data?.data.data.id}</div>
        <div className="box">
          <div className="desc-head">About This Test </div>
          {/* <div className="lab-edit">

            <EditIcon
              fontSize="medium"
              // onClick={() => handleClickDesc()}
              sx={{ cursor: "pointer" }}
            />


            <button
              // onClick={() => handleClickDesc()}
              className="Lab-editable"
            >
              Edit
            </button>

          </div> */}
          <div className="insurance-box-head">
            <div className="agentHeaderName">{agent.data?.data.data.name}</div>
            {/* <div className="edit">
              <EditIcon fontSize="medium" />
              <button
                onClick={() => handleClick(agent.data?.data.data)}
                className="editBtn"
              >
                Edit
              </button>
              {edit && values && (
              <ModalUpdateInsuranceAgent value={values} open={setEdit} />
            )}
            </div> */}
          </div>
          <hr />
          <div className="box-bodyy">
            <div className="detailss">
              <div className="keyss">
                Test Amount
                <br />
                <br />
                Specific Instructions
                <br />
                {/* <br />
                Address
                <br /> */}
                <br />
                Interpretations
              </div>
              <div className="insurance-value">
                {agent.data?.data.data.number}
                <br />
                <br />
                {agent.data?.data.data.email}
                <br />
                {/* <br />
                {agent.data?.data.data.address}
                <br /> */}
                <br />
                {agent.data?.data.data.companyName}
              </div>
            </div>
            {/* <div className="buttons">
              <button className="btnDeletes" onClick={handleDelete}>
                Delete Agent
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
export default ViewTests;
