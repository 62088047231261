import React, { useEffect, useState } from "react";
import "./AddLifeStyle.css";
import Chip from "@mui/material/Chip";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";

import { viewLifeStyleRequest } from "../../../redux/Actions/LifeStyle/viewLifeStyleAction";
import { useNavigate, useParams } from "react-router-dom";
import { viewAllTestsNameRequest } from "../../../redux/Actions/Booking/viewAllTestsNameAction";
import { getPackageDropdownRequest } from "../../../redux/Actions/Packages/getPackageDropdownAction";
import { AddLifeStyleRequest } from "../../../redux/Actions/LifeStyle/AddLifeStyleAction";
import swal from "sweetalert";

function AddLifeStyle() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const pathname  = useLocation();


  useEffect(() => {
    dispatch(viewLifeStyleRequest(id));
  }, [id]);

  const viewLifeStyleParticular = useSelector(
    (state) => state.lifeStyle.viewLifeStyle
  );



  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedTestsUUIDS, setSelectedTestsUUIDS] = useState([]);
  const [selectedPackagesUUIDS, setSelectedPackagesUUIDS] = useState([]);
  // const selectedTestsUUIDS=[];
  const [availableTestOptions, setAvailableTestOptions] = useState([]);
  const [lifestyleName, setLifestyleName] = useState();


  useEffect(() => {
    setAvailableTestOptions(viewLifeStyleParticular.data?.data.data.testData);
  }, [viewLifeStyleParticular]);

  useEffect(() => {
    dispatch(viewAllTestsNameRequest());
    dispatch(getPackageDropdownRequest())
  }, [])
  const viewAllTestsNames = useSelector((state) => state.viewAllTestsName);
  const viewPackagesDropdown = useSelector((state) => state.packageDropdown);


  const handleTestSelect = (e) => {
    viewAllTestsNames.data?.data.data.data.map((item) => {
      if (item.id === e.target.value) {
        if (selectedTests.some((ele) => ele === item.name) === false) {
          setSelectedTests([...selectedTests, item.name])

        }
      }
    })
  };




  const handlePackageSelect = (e) => {
    viewPackagesDropdown.data?.action.data.data.map((item) => {
      if (item.id === e.target.value) {
        if (selectedPackages.some((ele) => ele === item.name) === false) {
          setSelectedPackages([...selectedPackages, item.name])
        }
      }
    })

  };

  useEffect(() => {
    const updatedUUIDS = selectedTests.map((ele) => {
      const matchingItem = viewAllTestsNames.data?.data.data.data.find(
        (item) => item.name === ele
      );
      return matchingItem ? matchingItem.id : null;
    });

    setSelectedTestsUUIDS(updatedUUIDS.filter((id) => id !== null));
  }, [selectedTests, viewAllTestsNames.data?.data.data.data]);

  useEffect(() => {
    const updatedUUIDS = selectedPackages.map((ele) => {
      const matchingItem = viewPackagesDropdown.data?.action.data.data.find(
        (item) => item.name === ele
      );
      return matchingItem ? matchingItem.id : null;
    });

    setSelectedPackagesUUIDS(updatedUUIDS.filter((id) => id !== null));
  }, [selectedPackages, viewPackagesDropdown.data?.action.data.data]);



  const handleDeleteTest = (index) => {
    const updatedTests = [...selectedTests];
    updatedTests.splice(index, 1);
    setSelectedTests(updatedTests);

    // Remove the corresponding ID from selectedTestsUUIDS
    const updatedUUIDS = [...selectedTestsUUIDS];
    updatedUUIDS.splice(index, 1);
    setSelectedTestsUUIDS(updatedUUIDS);
  };

  const handleDeletePackage = (index) => {
    const updatedTests = [...selectedPackages];
    updatedTests.splice(index, 1);
    setSelectedPackages(updatedTests);

    // Remove the corresponding ID from selectedTestsUUIDS
    const updatedUUIDS = [...selectedPackagesUUIDS];
    updatedUUIDS.splice(index, 1);
    setSelectedPackagesUUIDS(updatedUUIDS);
  };

  function lifeStyleName(e) {
    setLifestyleName(e.target.value);
  }


  function AddLifestyle() {

    if(selectedTests.length===0 && selectedPackages.length===0){
      swal({
        title: "Please Select Tests/Packages",
        icon: "info",
      })
    }

     else{
      let data = {
        lifestyleName: lifestyleName,
        lifeStylePackageEnum: null,
        selectedTestsUUIDS: selectedTestsUUIDS,
        selectedPackagesUUIDS: selectedPackagesUUIDS,
      }
      dispatch(AddLifeStyleRequest(data));
     }
    



  }

  const AddLifeStyle = useSelector((state) => state.lifeStyle.AddLifeStyle);

  if (
    AddLifeStyle.data?.data.status === 201 &&
    AddLifeStyle.data?.data.data.message !== ""
  ) {
    swal({
      title: `${AddLifeStyle.data?.data.data.message}`,
      icon: "success",
    }).then(() => {
      AddLifeStyle.data.data.status =null
      navigate("/lifeStyle")
    });
  }

  if (
    (AddLifeStyle.error?.data.response.status === 400 || AddLifeStyle.error?.data.response.status === 401 || AddLifeStyle.error?.data.response.status === 402 || AddLifeStyle.error?.data.response.status === 403) &&
    AddLifeStyle.error?.data.response.data.lifeStylePackageName !== ""
  ) {
    swal({
      title: `${AddLifeStyle.error?.data.response.data.lifeStylePackageName}`,
      icon: "info",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <div className="mainDivLifeStyle">
      <div className="nameAndOtherText">
        <div className="nameAndSelect">
          <div className="nameLifeStyle">Name</div>
          <div className="nameLifeStyleValue">
            <input
              name="lifeStyleName"
              placeholder="Life Style Package Name"
              id="parameterName"
              onChange={lifeStyleName}
            />
          </div>
        </div>
        <div>
          <div className="nameAndSelect">
            <div className="nameLifeStyle">Test</div>
            <div className="nameLifeStyleValue">
              <select
                className="selectNmaeLifeStyle"
                onChange={handleTestSelect}
                value=""

              >
                <option value="" disabled>
                  Select Test
                </option>
                {viewAllTestsNames.data?.data.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="nameAndSelect">
            <div className="nameLifeStyle">Packages</div>
            <div className="nameLifeStyleValue">
              <select
                className="selectNmaeLifeStyle"
                onChange={handlePackageSelect}
                value=""
              >
                <option value="" disabled>
                  Select Package
                </option>
                {viewPackagesDropdown.data?.action.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="chipsetTagAndName">
            <div className="nameLifeStylePackage">{selectedTests.length !== 0 ? "Selected Tests" : ""}</div>
            <div className="chipsetTags">
              {selectedTests?.map((test, index) => (
                <>
                  <div key={index} className="chipDIvs">
                    <Chip
                      style={{
                        backgroundColor: "#67E4CE",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        height: "100%",
                        width: "100%",
                        color: "#52608E",
                        fontWeight: "500",
                        borderRadius: "10px"
                      }}
                      label={test}
                      onDelete={() => handleDeleteTest(index)}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="chipsetTagAndName">
            <div className="nameLifeStylePackage">{selectedPackages.length !== 0 ? "Selected Packages" : ""}</div>
            <div className="chipsetTags">
              {selectedPackages?.map((pkg, index) => (
                <>
                  <div key={index} className="chipDIvs">
                    <Chip
                      style={{
                        backgroundColor: "#67E4CE",
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        height: "100%",
                        width: "100%",
                        color: "#52608E",
                        fontWeight: "500",
                        borderRadius: "10px"
                      }}
                      label={pkg}
                      onDelete={() => handleDeletePackage(index)}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        <button className="buttonOfLifeStyle" onClick={AddLifestyle}>Save</button>
      </div>
     

    </div>
  );
}

export default AddLifeStyle;
