import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Login/loginDoctor";
import NetworkService from "../../../network/NetworkService";

export function* LoginDoctorSaga(action) {
  try {
     
    let response = yield call(NetworkService.loginDoctorService, action);
    yield put(actions.loginDoctorSuccess(response));
  } catch (err) {
    yield put(actions.loginDoctorFailure(err));
  }
}

export function* watchLoginDoctorSaga() {
  yield takeLatest(types.LOGIN_DOCTOR_REQUEST, LoginDoctorSaga);
}