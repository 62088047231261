import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Coupon/DeleteCouponAction";
import NetworkService from "../../../network/NetworkService";

export function* DeleteCouponSaga(action) {
  try {
    let response = yield call(NetworkService.DeleteCoupon, action);
    yield put(actions.DeleteCouponSuccess(response));
  } catch (err) {
    yield put(actions.DeleteCouponFailure(err));
  }
}

export function* watchDeleteCouponSaga() {
  yield takeLatest(types.DELETE_COUPON_REQUEST, DeleteCouponSaga);
}