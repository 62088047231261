import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/getBannerTypesAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* getBannerTypes(action) {
  try {
    let response = yield call(NetworkService.getBannerTypes, action);
    yield put(actions.getBannerTypesSuccess(response));
  } catch (err) {
    yield put(actions.getBannerTypesFailure(err));
  }
}

export function* watchGetBannerTypesSaga() {
  yield takeEvery(types.GET_BANNER_TYPES_REQUEST, getBannerTypes);
}