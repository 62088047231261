import * as types from "../../Config/Pharmacy/PharmacyActionType"

export const viewAllPharmacyRequest = (id,page,pageSize,name,city,status) => {
    return {
        type: types.VIEW_ALL_PHARMACY_REQUEST,
        id,
        page,
        pageSize,
        name,
        city,
        status
    }
}

export const viewAllPharmacySuccess = (action) => {
    return {
        type: types.VIEW_ALL_PHARMACY_SUCCESS,
        action
    }
}

export const viewAllPharmacyFailure = (action) => {
    return {
        type: types.VIEW_ALL_PHARMACY_FAILURE,
        action
    }
}