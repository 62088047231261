import * as types from "../../Config/actionType";

export const ViewDiscountRequest = (data) => {
  return {
    type: types.VIEW_DISCOUNT_REQUEST,
    data: data,
  };
};

export const ViewDiscountSuccess = (data) => {
  return {
    type: types.VIEW_DISCOUNT_SUCCESS,
    data: data,
  };
};

export const ViewDiscountFailure = (data) => {
  return {
    type: types.VIEW_DISCOUNT_FAILURE,
    data: data,
  };
};
