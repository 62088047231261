import * as types from "../../Config/actionType"

export const addUserRequest = (data) => {
    return {
        type: types.ADD_USER_REQUEST,
        data
    }
}

export const addUserSuccess = (data) => {
    return {
        type: types.ADD_USER_SUCCESS,
        data
    }
}

export const addUserFailure = (data) => {
    return {
        type: types.ADD_USER_FAILURE,
        data
    }
}