import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css"
// import { BarChart } from '@mui/icons-material';

function WaistCircumferance_DonutChart({waistCircumferenceResponseDto}) {
   
let waistCircumferenceResponseSum =waistCircumferenceResponseDto?.waistCircumferenceResponseDto.greaterThan35Female + waistCircumferenceResponseDto?.waistCircumferenceResponseDto?.greaterThan40Male

 

    let healthyPeople=waistCircumferenceResponseDto?.totalEmployeeTakenHra - waistCircumferenceResponseSum
 
  return (
    <React.Fragment>
      <div className="container-fluid mb-3">
        <Chart
          type="donut"
            // width={600}
          //   height={560}
          series={[waistCircumferenceResponseDto?.waistCircumferenceResponseDto?.greaterThan40Male, waistCircumferenceResponseDto?.waistCircumferenceResponseDto?.greaterThan35Female,healthyPeople]}
          options={{
            labels: ["Unhealthly Male", " Unhealthly Female"," OverAll Healthly"],
            colors: ["#353643", "#FFA384","#4EC33D"],


            chart: {
              type: "donut",
            },
            fill: {
                colors: ["#353643", "#FFA384","#4EC33D"],
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,

                    // Zindex:""
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],

            dataLabels: {
              enabled: false,
              colors: ["#353643", "#FFA384","#4EC33D"],

            },
            plotOPtions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                  },
                },
              },
            },
          }}
        />
        <div className="BMI_Donut_OuterContainer">
          <div >
            <div className="BMI_ObeseHeading"> Unhealthly Male</div>
            <div className="BMI_ObeseSubHeading">{waistCircumferenceResponseDto?.waistCircumferenceResponseDto?.greaterThan40Male}</div>
          </div>
          <div >
            <div className="BMI_ObeseHeading"> Unhealthly Female</div>
            <div className="BMI_ObeseSubHeading">{ waistCircumferenceResponseDto?.waistCircumferenceResponseDto?.greaterThan35Female}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WaistCircumferance_DonutChart;
