import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalUpdateDoctor from "../../Organisms/Modal/ModalUpdateDoctor";
import { getBrandDetailsRequest } from "../../../redux/Actions/Product/getBrandDetailsAction";

import storage from "../../../Utils/storage";
import { getSubCategoryDetailsRequest } from "../../../redux/Actions/Product/getSubCategoryDetailsAction";

function ViewSubCategoryDetails() {
  const [values, setValues] = React.useState(false);
  const [doctorEdit, setDoctorEdit] = React.useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubCategoryDetailsRequest(id));
  }, []);

  const details = useSelector((state) => state.getSubCategoryDetails);

  return (
    <div>
      {doctorEdit && values && (
        <ModalUpdateDoctor value={values} open={setDoctorEdit} />
      )}
      <div className="hospital-main">
        <h3 className="headerViewName">{details.data?.data.data.name}</h3>
        <div className="hospital-box">
          <div className="counts">
            <div className="divCount">
              {details.data?.data.data.active === false ? (
                <div className="disableChip">Disabled</div>
              ) : (
                <div className="docCount">
                  {details.data?.data.data.data.name}
                </div>
              )}
            </div>
          </div>
          <hr />

          <div className="body">
            <div className="hospitalDetails">
            <div className="productDetails" >
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft'>Id</div>
              <div className='productDetailsRight'>{details?.data?.data?.data?.data.id}</div>
            </div>
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft'>Display</div>
              <div className='productDetailsRight'>{details?.data?.data?.data?.data.display.toString()}</div>
            </div>
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft productDetailsLeftDescription'>Description</div>
              <div className='productDetailsRight'><span style={{}} dangerouslySetInnerHTML={{ __html: details?.data?.data?.data?.data.description }} /></div>
            </div>
            <div className='productDetailsMain'>
              <div className='productDetailsLeft productDetailsLeftDescription'>Nutritional Value</div>
              <div className='productDetailsRight'><span style={{}} dangerouslySetInnerHTML={{ __html: details?.data?.data?.data?.data.nutritional }} /></div>
            </div>
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft productDetailsLeftDescription'>Image</div>
              <div className='productDetailsRight'><img src={details?.data?.data.data.data.imageFilepath} style={{ width: "100px", height: "100px" }} /></div>
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewSubCategoryDetails;
