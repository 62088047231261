import { Route, Routes } from "react-router-dom";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import ConsultationRequests from "../../component/Pages/InHouseDoctor/ConsultationRequests";
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound"
import ListOfConsultations from "../../component/Pages/InHouseDoctor/ListOfConsultations";
import PrescriptionForm from "../../component/Pages/InHouseDoctor/PrescriptionForm";
import ChatStatus from "../../component/Pages/InHouseDoctor/ChatStatus"
import ReasonForIncomplete from "../../component/Pages/InHouseDoctor/ReasonForIncomplete"
import SingleLogin from "../../component/Pages/Login/SingleLogin";



export default function InHouseDoctorRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/inHouse-Doctor-Dashboard" element={<DashBoard />} />
      <Route path="/consultationRequests/:id" element={<ConsultationRequests />} />
      <Route path="/consultationRequests" element={<ListOfConsultations />} />
      <Route path="/prescriptionForm/:id" element={<PrescriptionForm />} />
      <Route path="/chatStatus/:id" element={<ChatStatus />} />
      <Route path="/ReasonForIncomplete/:id" element={<ReasonForIncomplete />} />
      <Route path="*" element={<PageNotFound />} />

    </Routes>
  );
}
