import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/getLotByIdAction"
import NetworkService from "../../../network/NetworkService";

export function* getLotByIdSaga(action) {
  try {
    let response = yield call(NetworkService.getLotById, action);
    yield put(actions.getLotByIdActionSuccess(response));
  } catch (err) {
    yield put(actions.getLotByIdActionFailure(err));
  }
}

export function* watchGetLotByIdSaga() {
  yield takeLatest(types.GET_LOT_BY_ID_REQUEST, getLotByIdSaga);
}