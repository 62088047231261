import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Program/addNewProgramAction";
import NetworkService from "../../../network/NetworkService";

export function* addProgramSaga(action) {
  try {
     
    let response = yield call(NetworkService.addProgram, action);
    yield put(actions.addProgramSuccess(response));
  } catch (err) {
    yield put(actions.addProgramFailure(err));
  }
}

export function* watchAddProgramSaga() {
  yield takeLatest(types.ADD_PROGRAM_REQUEST, addProgramSaga);
}