import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import "./CardNumberForm.css";
import DownloadIcon from "@mui/icons-material/Download";
import Footer from "../../Organisms/Footer/FooterUpper";
import { corporateDropdownRequest } from "../../../redux/Actions/Corporate/CorporateDropdownAction";
import { getPlanDropdownForCardRequest } from "../../../redux/Actions/Plan/getPlansDropdownForCard";
import { CreateCardNumberRequest } from "../../../redux/Actions/CreateCard/CreateCardNumberAction";
import { GetDownloadCardDetailRequest } from "../../../redux/Actions/CreateCard/DownloadCardDetailsAction";
import CircularProgress from "@mui/material/CircularProgress";

function CardNumberForm() {
  const dispatch = useDispatch();

  const [cardCount, setCardCount] = useState();
  const [numberOfYear, setNumberOfYear] = useState();
  const [corporateId, setCorporateId] = useState(null);
  const [planUuidId, setPlanUuidId] = useState(null);

  useEffect(() => {
    dispatch(corporateDropdownRequest());
    dispatch(getPlanDropdownForCardRequest());
    dispatch(GetDownloadCardDetailRequest());
  }, []);

  const DownloadCardDetail = () => {
    if (downloadCardDetail.data?.action.status === 200) {
      const url_1 = window.URL.createObjectURL(
        new Blob([downloadCardDetail.data?.action.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url_1;
      link.setAttribute("download", `CardNumbers.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.location.reload();
    }
  };
  const downloadCardDetail = useSelector((state) => state.DownloadCardDetail);

  const corporateDropdown = useSelector((state) => state.corporateDropdown);
  const getPlanDropdownForCard = useSelector(
    (state) => state.getPlanDropdownForCard
  );

  const CreateCardNumber = useSelector((state) => state.CreateCardNumber);
  function handleNumberOfYear(e) {
    let inputYear = e.target.value;

    setNumberOfYear(inputYear);
  }

  function handleCount(e) {
    setCardCount(e.target.value);
  }
  function handlePlanUuidId(e) {
    setPlanUuidId(e.target.value);
  }
  function handleCorporateId(e) {
    setCorporateId(e.target.value);
  }
  const updateRes = useSelector((state) => state.CreateCardNumber)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  function handleSubmit() {
    if (cardCount === "" || numberOfYear === "" || planUuidId === "null") {
      swal({
        title: "Please fill all the Details carefully",
        icon: "error",
      });
    } else if (numberOfYear.length !== 4) {
      swal({
        title: "Please enter exactly 4 digits for the year.",
        icon: "error",
      });
    } else {
    setFlag(false)

      let createcardNumber = {
        corporateId: corporateId,
        count: cardCount,
        planUuid: planUuidId,
        year: numberOfYear.slice(-2),
      };
      dispatch(CreateCardNumberRequest(createcardNumber));
    }
  }
  

  if (
    CreateCardNumber.data?.action.status === 201 &&
    CreateCardNumber.data?.action.data.message !== ""
  ) {
    swal({
      title: `${CreateCardNumber.data?.action.data.message}`,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    CreateCardNumber.error != null
   
  ) {
    swal({
      title: `${CreateCardNumber.error?.action.response.data.planUuid || CreateCardNumber.error?.action.response.data.count || CreateCardNumber.error?.action.response.data.corporateId || CreateCardNumber.error?.action.response.data.year}`,
      icon: "error",
    });

    CreateCardNumber.error.action.response.status = null;
  }

  return (
    <div>
      <div>
        <h4 className="h4">CREATE CARD NUMBER</h4>
        <div className="addCreateCardNumberModal">
          <div
            className="CreateCardNumberDownloadButton"
            onClick={DownloadCardDetail}
          >
            {" "}
            <DownloadIcon /> Download Card Number
          </div>

          <div className="addBannerModalChild">
            <div className="addCreateCardNumberChildLeft">Corporate :</div>
            <div className="addCreateCardNumberChildRight">
              <select onChange={handleCorporateId}>
                <option value="null" selected disabled={true}>
                  Select Corporate
                </option>
                {corporateDropdown.data?.data.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="addBannerModalChild">
            <div className="addCreateCardNumberChildLeft">Count : </div>
            <div className="addCreateCardNumberChildRight">
              <input
                type="number"
                onChange={handleCount}
                onKeyDown={(e) =>
                  (e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
                placeholder="Count"
              />
            </div>
          </div>
          <div className="addBannerModalChild">
            <div className="addCreateCardNumberChildLeft">Plan :</div>
            <div className="addCreateCardNumberChildRight">
              <select onChange={handlePlanUuidId}>
                <option value="null" selected disabled={true}>
                  Select Plan
                </option>
                {getPlanDropdownForCard.data?.data.data.data.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="addBannerModalChild">
            <div className="addCreateCardNumberChildLeft">Year :</div>
            <div className="addCreateCardNumberChildRight">
              <input
                type="number"
                onChange={handleNumberOfYear}
                placeholder="Enter Year (YYYY) "
                onKeyDown={(e) =>
                  (e.key === "e" ||
                    e.key === "E" ||
                    e.key === "+" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onWheel={(event) => event.currentTarget.blur()}
              />
            </div>
          </div>

          <button className="addBannerButton"  disabled={!flag} onClick={handleSubmit}>
           

            {flag ? "    Create Card Generation":
                    
                    <CircularProgress/>
                    }
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default CardNumberForm;
