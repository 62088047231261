import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Banner/BannerNetworkServices"
import * as actions from "../../Actions/Banner/addBannerDetailAction";
import * as types from "../../Config/Banner/BannerActionType";

export function* addBannerDetail(action) {
  try {
    let response = yield call(NetworkService.addBannerDetail, action);
    yield put(actions.addBannerDetailSuccess(response));
  } catch (err) {
    yield put(actions.addBannerDetailFailure(err));
  }
}

export function* watchAddBannerDetail() {
  yield takeEvery(types.ADD_BANNER_DETAIL_REQUEST, addBannerDetail);
}