import * as types from "../../Config/LifeStyle/lifeStyleActionType"

export const AddLifeStyleRequest = (data) => {
    return {
        type: types.ADD_LIFESTYLE_REQUEST,
        data:data,
    }
}

export const AddLifeStyleSuccess = (data) => {
    return {
        type: types.ADD_LIFESTYLE_SUCCESS,
        data:data,
    }
}

export const AddLifeStyleFailure = (data) => {
    return {
        type: types.ADD_LIFESTYLE_FAILURE,
        data:data,
    }
}