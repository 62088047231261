import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getBrandDetailsAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getBrandDetailsSaga(action) {

  try {
    let response = yield call(NetworkService.getBrandDetails, action);

    yield put(actions.getBrandDetailsSuccess(response));
  } catch (err) {
    yield put(actions.getBrandDetailsFailure(err));
  }
}

export function* watchGetBrandDetailsSaga() {
  yield takeLatest(types.GET_BRAND_DETAILS_REQUEST, getBrandDetailsSaga);
}
