import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/viewAssignProgram";
import NetworkService from "../../../network/NetworkService";

export function* corporateViewAssignProgramSaga(action) {

  try {
     
    let response = yield call(NetworkService.corporateViewAssignProgramNetwork, action);
    yield put(actions.corporateViewAssignProgramSuccess(response));
  } catch (err) {
    yield put(actions.corporateViewAssignProgramFailure(err));
  }
}

export function* watchcorporateViewAssignProgramSaga() {
  yield takeLatest(types.CORPORATE_VIEW_ASSIGN_PROGRAM_REQUEST, corporateViewAssignProgramSaga);
}