import * as types from "../../Config/actionType"

export const downloadPrescriptionRequest = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_REQUEST,
        data:data
    }
}

export const downloadPrescriptionSuccess = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_SUCCESS,
        data:data,
    }
}

export const downloadPrescriptionFailure = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_FAILURE,
        data:data,
    }
}