import * as types from "../../Config/Product/ProductActionType";

export const getAllBrandRequest = (pageNo, pageSize, search) => {
  
  return {
    type: types.GET_ALL_BRAND_REQUEST,
    pageNo,
    pageSize,
    search,
  };
};

export const getAllBrandSuccess = (data) => {
  return {
    type: types.GET_ALL_BRAND_SUCCESS,
    data: data,
  };
};

export const getAllBrandFailure = (data) => {
  return {
    type: types.GET_ALL_BRAND_FAILURE,
    data: data,
  };
};
