import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/updateServiceAttributeAction";
import NetworkService from "../../../network/NetworkService";

export function* updateServiceAttributeSaga(action) {
  try {
   
    let response = yield call(NetworkService.updateServiceAttribute, action);
   
    yield put(actions.updateServiceAttributeSuccess(response));
  } catch (err) {
    yield put(actions.updateServiceAttributeFailure(err));
  }
}

export function* watchUpdateServiceAttributeSaga() {
  yield takeLatest(types.UPDATE_SERVICE_ATTRIBUTE_REQUEST, updateServiceAttributeSaga);
}
