import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Users/UserProgramDetailsAction";
import NetworkService from "../../../network/NetworkService";

export function* GetUserProgramSaga(action) {
  try {
    let response = yield call(NetworkService.getUserProgram, action);
    yield put(actions.GetUserProgramSuccess(response));
  } catch (err) {
    yield put(actions.GetUserProgramFailure(err));
  }
}

export function* watchGetUserProgramSaga() {
  yield takeLatest(types.GET_USER_PROGRAM_REQUEST, GetUserProgramSaga);
}
