import * as types from "../../Config/LifeStyle/lifeStyleActionType"

export const viewLifeStyleRequest = (id) => {
    return {
        type: types.VIEW_LIFE_STYLE_REQUEST,
        id
    }
}

export const viewLifeStyleSuccess = (action) => {
    return {
        type: types.VIEW_LIFE_STYLE_SUCCESS,
        action
    }
}

export const viewLifeStyleFailure = (action) => {
    return {
        type: types.VIEW_LIFE_STYLE_FAILURE,
        action
    }
}