import * as types from "../../Config/actionType";

export const viewAllPaymentRequest = (
  page,
  pageSize,
  startDate,
  endDate,
  search,
  appointmentStatus,
  HospitalId,
  paymentStatus
) => {
  
  return {
    type: types.VIEW_ALL_PAYMENT_REQUEST,
    page,
    pageSize,
    startDate,
    endDate,
    search,
    appointmentStatus,
    HospitalId,
    paymentStatus
  };
};

export const viewAllPaymentSuccess = (data) => {
  return {
    type: types.VIEW_ALL_PAYMENT_SUCCESS,
    data: data,
  };
};

export const viewAllPaymentFailure = (data) => {
  return {
    type: types.VIEW_ALL_PAYMENT_FAILURE,
    data: data,
  };
};
