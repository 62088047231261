import * as types from "../../../Config/actionType";

export const DoctorListDetailRequest = (
  page,
  pageSize,
  searchText,
  hospitalDropdown,
  status,
  endDate,
  startDate,
) => {
  return {
    type: types.GET_DOCTOR_LIST_REQUEST,
    page: page,
    pageSize: pageSize,
    searchText: searchText,
    hospitalDropdown: hospitalDropdown,
    status: status,
    endDate: endDate,
    startDate: startDate,
  };
};

export const DoctorListDetailSuccess = (data) => {
  return {
    type: types.GET_DOCTOR_LIST_SUCCESS,
    data: data,
  };
};

export const DoctorListDetailFailure = (data) => {
  return {
    type: types.GET_DOCTOR_LIST_FAILURE,
    data: data,
  };
};