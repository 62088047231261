import { all } from "redux-saga/effects";
import { watchGetAllBannerByType } from "./getAllBannerByTypeSaga";
import { watchGetItemTypeSaga } from "./getItemTypeSaga";
import { watchAddBannerDetail } from "./addBannerDetailSaga";
import { watchDeleteById } from "./deleteByIdSaga";


function* rootBannerSaga() {
  yield all([
    watchGetAllBannerByType(),
    watchGetItemTypeSaga(),
    watchAddBannerDetail(),
    watchDeleteById(),
  ]);
}

export default rootBannerSaga;