import * as types from "../../Config/actionType"

export const  makePackageRequest = (data) => {
    
    return {
        type: types.MAKE_PACKAGE_REQUEST,
        data:data
    }
}

export const  makePackageSuccess = (data) => {
    return {
        type: types.MAKE_PACKAGE_SUCCESS,
        data:data,
    }
}

export const makePackageFailure = (data) => {
    return {
        type: types.MAKE_PACKAGE_FAILURE,
        data:data,
    }
}