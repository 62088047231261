import * as types from "../../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const viewAllProgramTemplateNamesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_SUCCESS:
            // 
            return {
                ...initialState,
                data: action,
            }
        case types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}