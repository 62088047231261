import * as types from "../../Config/Product/ProductActionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  filter : {
    brandId: [],
    subCategoryId: [],
    selectCategoryId: []
  }
};

export const getAllProductReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_ALL_PRODUCT_SUCCESS:
      return {
        ...initialState,
        data: action,
        filter: {
          brandId: action.filter.data.brandId,
          subCategoryId: action.filter.data.subCategoryId,
          selectCategoryId: action.filter.data.selectCategoryId
        },
      };
    case types.GET_ALL_PRODUCT_FAILURE:

      return {
        ...initialState,
        error: action,
      };
    case types.GET_ALL_PRODUCT_REQUEST:

      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
