import { Box } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import "./AddBanner.css";
import { useDispatch, useSelector } from "react-redux";
import { getItemTypeRequest } from "../../../redux/Actions/Banner/getItemTypeAction";
import { viewAllTestsNameRequest } from "../../../redux/Actions/Booking/viewAllTestsNameAction";
import { getServiceDropdownRequest } from "../../../redux/Actions/Service/getServiceDropdownAction";
import { getPackageDropdownRequest } from "../../../redux/Actions/Packages/getPackageDropdownAction";
import { getPlanDropdownRequest } from "../../../redux/Actions/Plan/getPlanDropdownAction";
import { addBannerDetailRequest } from "../../../redux/Actions/Banner/addBannerDetailAction";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";


function AddBannerTypeC({ id, bannerId, bannerName, bannerType, bannerPosition, screenType, bannerTypeEnum, screenTypeEnum }) {
    const loginSchema = Yup.object().shape({
        bannerName: Yup.string()
            .required("Required Field"),
        title: Yup.string()
            .required("Required Field"),
        description: Yup.string()
            .required("Required Field"),
        test: Yup.string()
            .required("Required Field"),
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getItemTypeRequest())
    }, [])

    const getItemType = useSelector((state) => state.banner.getItemType)
    const viewAllTestsNames = useSelector((state) => state.viewAllTestsName);
    const viewServiceDropdown = useSelector(
        (state) => state.serviceDropdown
    );
    const viewPackagesDropdown = useSelector((state) => state.packageDropdown);
    const viewPlanDropdown = useSelector((state) => state.planDropdown);
    const addBannerDetail = useSelector((state) => state.banner.addBannerDetail);

    const [errorBannerPhoto, setErrorBannerPhoto] = useState(false)
    const [bannerPhoto, setBannerPhoto] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [check, setCheck] = useState(false)
    const [changeValue, setChangeValue] = useState()

    useEffect(() => {
        if (addBannerDetail.data?.data.status === true) {
            swal({
                title: addBannerDetail.data?.data.message,
                icon: "success"
            }).then(() => {
                navigate("/bannerList", {
                    state: {
                        bannerId: bannerId,
                        bannerName: bannerName,
                        bannerType: bannerType,
                        bannerPosition: bannerPosition,
                        screenType: screenType,
                        bannerTypeEnum: bannerTypeEnum,
                        screenTypeEnum: screenTypeEnum
                    }
                })
                window.location.reload()
            })
        }
        else if (addBannerDetail.error !== null) {
            swal({
                title: addBannerDetail.error.data.response.data.errorMessage,
                icon: "error"
            })
        }
    }, [addBannerDetail])


    const handleBannerPhotoChange = (event) => {

        if (event.target.files[0].type === "image/png" ||
            event.target.files[0].type === "image/jpeg" ||
            event.target.files[0].type === "image/jpg"
        ) {
            setBannerPhoto(event.target.files[0]);
            setErrorBannerPhoto(false);
        } else {
            setErrorBannerPhoto(true);
        }
    }

    const handleSubmit = (values) => {
        setIsSubmitting(true);
        setCheck(true);

        const data = {
            bannerId: id,
            innerBannerName: values.bannerName,
            contentType: changeValue,
            itemId: values.test,
            title: values.title,
            description: values.description
        }

        const newFormData = new FormData();

        newFormData.append(
            "bannersRequest",
            JSON.stringify(data)
        );

        newFormData.append("image", bannerPhoto);
        dispatch(addBannerDetailRequest(newFormData));
    }

    const handleChange = (e) => {
        setChangeValue(e.target.value)
        if (e.target.value === "TEST") {
            dispatch(viewAllTestsNameRequest())
        }
        else if (e.target.value === "SERVICE") {
            dispatch(getServiceDropdownRequest())
        }
        else if (e.target.value === "PACKAGE") {
            dispatch(getPackageDropdownRequest())
        }
        else if (e.target.value === "PLAN") {
            dispatch(getPlanDropdownRequest())
        }
    }
    return (
        <div className='grid'>
            <h4 className="h4">Create Banner C</h4>
            <div className='mainBox'>
                <Box className="bookingStyle1">
                    <Formik
                        initialValues={{
                            bannerName: "",
                            test: "",
                            title: "",
                            description: "",
                        }}
                        enableReinitialize={true}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                    >
                        {
                            <Form>
                                <div className="inputFieldBody" style={{ paddingTop: "6%" }}>
                                    <div className="inputFieldsContainerBanner">
                                        <label>
                                            <Field
                                                name="bannerName"
                                                placeholder="Banner Name"
                                                className="inputField"
                                            />
                                            <ErrorMessage
                                                className="errorMessageModalValidation"
                                                name="bannerName"
                                                component="div"
                                            />
                                        </label>
                                    </div>
                                    <div className="inputFieldsContainerBanner">
                                        <label>
                                            <Field
                                                as="select"
                                                name="position"
                                                placeholder="Select"
                                                className="inputField"
                                                value={changeValue}
                                                onChange={(e) => handleChange(e)}
                                            >
                                                <option value={null} disabled selected >Select Test/Package/Services</option>
                                                {getItemType.data?.data.data.data.map((item) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </Field>
                                            {changeValue === null &&
                                                <div className="errorFile">
                                                    Required Field
                                                </div>
                                            }
                                        </label>
                                    </div>
                                    {viewAllTestsNames.data && changeValue === "TEST" && (
                                        <div className="inputFieldsContainerBanner">
                                            <label>
                                                <Field
                                                    as="select"
                                                    name="test"
                                                    placeholder="Select"
                                                    className="inputField"
                                                >
                                                    <option value={""} selected disabled>Select Test</option>
                                                    {viewAllTestsNames.data.data.data.data.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    className="errorMessageModalValidation"
                                                    name="test"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {viewPlanDropdown.data && changeValue === "PLAN" && (
                                        <div className="inputFieldsContainerBanner">
                                            <label>
                                                <Field
                                                    as="select"
                                                    name="test"
                                                    placeholder="Select"
                                                    className="inputField"
                                                >
                                                    <option value={""} selected disabled>Select Plan</option>
                                                    {viewPlanDropdown.data.data.data.data.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    className="errorMessageModalValidation"
                                                    name="test"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {viewPackagesDropdown.data && changeValue === "PACKAGE" && (
                                        <div className="inputFieldsContainerBanner">
                                            <label>
                                                <Field
                                                    as="select"
                                                    name="test"
                                                    placeholder="Select"
                                                    className="inputField"
                                                >
                                                    <option value={""} selected disabled>Select Service</option>
                                                    {viewPackagesDropdown.data.action.data.data.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    className="errorMessageModalValidation"
                                                    name="test"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                    )}
                                    {viewServiceDropdown.data && changeValue === "SERVICE" && (
                                        <div className="inputFieldsContainerBanner">
                                            <label>
                                                <Field
                                                    as="select"
                                                    name="test"
                                                    placeholder="Select"
                                                    className="inputField"
                                                >
                                                    <option value={null} selected disabled>Select Test</option>
                                                    {viewServiceDropdown.data.action.data.data.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage
                                                    className="errorMessageModalValidation"
                                                    name="test"
                                                    component="div"
                                                />
                                            </label>
                                        </div>
                                    )}
                                    <div className="inputFieldsContainerBanner">
                                        <label>
                                            <Field
                                                name="title"
                                                placeholder="Title"
                                                className="inputField"
                                            />
                                            <ErrorMessage
                                                className="errorMessageModalValidation"
                                                name="title"
                                                component="div"
                                            />
                                        </label>
                                    </div>
                                    <div className="inputFieldsContainerBanner">
                                        <label>
                                            <Field
                                                name="description"
                                                placeholder="Description"
                                                className="inputField"
                                            />
                                            <ErrorMessage
                                                className="errorMessageModalValidation"
                                                name="description"
                                                component="div"
                                            />
                                        </label>
                                    </div>
                                    <div className="inputFieldsContainerBanner">
                                        <input
                                            type="file"
                                            className='NetworkPageFormInputField'
                                            id="fileUploadInput"
                                            accept="image/png, image/jpeg ,image/jpg"
                                            onChange={(event) => handleBannerPhotoChange(event)}
                                        />
                                        {errorBannerPhoto && (
                                            <div className="errorFile">
                                                Please Select only .png or .jpeg or .jpg Extensions
                                            </div>
                                        )}
                                        {bannerPhoto === null && check && (
                                            <div className="FileSizedisable"> Select the file</div>
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        style={{ width: "40%", marginLeft: "6.875em" }}
                                        className="modalButtonBanner"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        }
                    </Formik>
                </Box>
            </div>
        </div>
    )
}

export default AddBannerTypeC