import React from "react";
import Chart from "react-apexcharts";
// import { BarChart } from '@mui/icons-material';

function HRA_Overview({ hraOverview }) {
  let BMI = hraOverview?.bmiResponseDto;
  let bmiModerate =
    BMI?.moderatelyObese + BMI?.moderatelyUnderWeight + BMI?.overWeight;
  let bmiExtreme =
    BMI?.severelyObese +
    BMI?.severelyUnderWeight +
    BMI?.extremelyUnderWeight +
    BMI?.extremelyObese;
  let bminormal = BMI?.normal;
 

  let Nutrition = hraOverview?.nutritionalResponseDto;
 

  let Alcohol = hraOverview?.alcoholResponseDto;

  let Smoking = hraOverview?.smokingTobaccoResponseDto;
  let smokingRisk = Smoking?.smokerOrTobaccoConsumer;

  let smokingGood =  hraOverview?.totalEmployeeAfterFilter - smokingRisk
  // let smokingModerate = Smoking?.smokerOrTobaccoConsumer;

  let Safty = hraOverview?.safetyResponseDto;

  let SaftyModerate = Safty?.helmetYes + Safty?.seatBeltYes;

  let MentalHealth = hraOverview?.mentalHealthGood;
  let MentalHealthModerate = hraOverview?.mentalHealthModerate;
  let MentalHealthHighRisk =
  hraOverview?.mentalHealthHigh;


  let Waist = hraOverview?.waistCircumferenceResponseDto;
  let WaistRisk = Waist?.greaterThan35Female + Waist?.greaterThan40Male;
  let WaistGood= hraOverview?.totalEmployeeAfterFilter - WaistRisk

 

  let Illness = hraOverview?.illnessResponseDto;
  //  let IllnessRisk= Illness?.cancer+ Illness?.diabetes+ Illness?.hyperTension+ Illness?.other;
  let IllnessRisk = Illness?.illPerson;
  let IllnessGood= hraOverview?.totalEmployeeAfterFilter - IllnessRisk;

  return (
    <React.Fragment>
      <div className="container-fluid mb-3">
        <Chart
       
          type="bar"
          // width={1049}
          // height={560}
          series={[
            {
              name: "High Risk",
              data: [
                bmiExtreme,
                WaistRisk,
                Nutrition?.high,
                MentalHealthHighRisk,
                Alcohol?.heavyDrinker,
                smokingRisk,
                Safty?.bothNo,
                IllnessRisk,
              ],
              width: "10%",
              barHeight: "70%",
              color: "#A53F2B",
            },
            {
              name: "Moderate",
              data: [
                bmiModerate,
                0,
                Nutrition?.moderate,
                MentalHealthModerate,
                Alcohol?.moderate + Alcohol?.occasional,
                0,
                SaftyModerate,
                0,
              ],
              color: "#E68D36",
            },
            {
              name: "Good",
              data: [
                bminormal,
                WaistGood,
                Nutrition?.good,
                MentalHealth,
                Alcohol?.nonDrinker,
                smokingGood,
                Safty?.bothYes,
                IllnessGood,
              ],
              color: "#52608E",
            },
          ]}
          options={{
            toolbar: {
              show: false},
            title: {
              // text:"Enegry Consumption in Years"
            },
            chart: {
              stacked: true,
            },
            dataLabels: {
              enabled: false,
            },
            plotOptions: {
              bar: {
                //   distributed: true,
                columnWidth: "20%",
                //   barHeight: "20%"
              },
            },
            stroke: {
              show: true,
              width: 2,
              // alignItem:"center",
              // display:"flex",
              // marginLeft:"80px",
              colors: ["transparent"],
            },
            xaxis: {
              title: {
                // text:"Energy Consumption in Year's"
              },
              categories: [
                "BMI & Fitnees",
                "Waist Circumference",
                "Nutrition",
                "Mental Health",
                "Alcohol",
                "Smoking & Tobacco",
                "Safety Habits",
                "Illness",
              ],
            },

            // legend:{
            //     position: 'bottom'
            // },
            // dataLabels:{
            //     enabled:true,
            // },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: false,
                },
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default HRA_Overview;
