import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { ViewOrderRequest } from "../../../redux/Actions/Accounts/ViewOrderAction";
import { ViewOrderDropdownRequest } from "../../../redux/Actions/Accounts/ViewOrderDropdownAction";
import "./ViewOrderDetails.css";
import Footer from "../../Organisms/Footer/FooterUpper";

function ViewOrderDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const viewOrder = useSelector((state) => state.viewOrder);
  const ChangeOrderStatus = useSelector((state) => state.ChangeOrderStatus);
  const [orderSelected, setOrderSelected] = useState([]);
  const [orderObjects, setOrderObjects] = useState([]);

  useEffect(() => {
    dispatch(ViewOrderRequest(id));
    dispatch(ViewOrderDropdownRequest(id));
  }, []);

  if (ChangeOrderStatus.data?.data.status === 200) {
    swal({
      title: ChangeOrderStatus?.data?.data.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (ChangeOrderStatus?.error?.action?.response?.status === 409) {
    swal({
      title: ChangeOrderStatus?.error?.action?.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  window.addEventListener("popstate", function (event) {
    window.location.href = "/accounts";
  });

  useEffect(() => {
    if (viewOrder.data?.data.data.data.orderItemDetailResponseDtoList) {
      viewOrder.data?.data.data.data.orderItemDetailResponseDtoList.map(
        (item) => {
          setOrderObjects((prevValue) => [...prevValue, item]);
        }
      );
    }
  }, [viewOrder.data?.data.data.data.orderItemDetailResponseDtoList]);
  function handleDivSelect(orderId) {
    if (orderSelected.length === 0) {
      setOrderSelected((prevValue) => [...prevValue, orderId]);
    } else {
      orderSelected.map((ele, index) => {
        if (ele === orderId) {
          orderSelected.splice(index, 1);
          setOrderSelected([...orderSelected]);
        } else if (index === orderSelected.length - 1) {
          setOrderSelected((prevValue) => [...prevValue, orderId]);
        }
      });
    }
  }

  return (
    <>
      <div className="requestPage">
        <h3 className="requestHead">
          Order Id - {viewOrder.data?.data.data.data.orderNumber}
        </h3>
        <div className="requestBox">
          <div className="requestTextHospital2">
            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">No Of Units :</h1>
              <h1 className="requestPageData">
                <div className="infoViewOrderDetails">
                  {viewOrder.data?.data.data.data.itemCount}
                </div>
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Amount Paid :</h1>
              <h1 className="requestPageData">
                ₹ {viewOrder.data?.data.data.data.amount} /-
              </h1>
            </div>
            {viewOrder.data?.data.data.data.refundAmount !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Amount Refunded :</h1>
                <h1 className="requestPageData">
                  ₹{viewOrder.data?.data.data.data.refundAmount}/-
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Coupon Applied :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.couponUsed ? "Yes" : "No"}
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Purchase Date :</h1>
              <h1 className="requestPageData">
                {new Date(
                  viewOrder?.data?.data?.data?.data?.date
                ).toLocaleString()}
              </h1>
            </div>
            {viewOrder.data?.data.data.data.productType !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Products :</h1>
                <h1 className="requestPageData">
                  {viewOrder.data?.data.data.data.productType}
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Mode of Payment :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.paymentMode === null
                  ? "---"
                  : viewOrder.data?.data.data.data.paymentMode}
              </h1>
            </div>

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Booked By :</h1>
              <h1 className="requestPageData">
                {viewOrder.data?.data.data.data.customerName}
              </h1>
            </div>
            {viewOrder.data?.data.data.data.patientName !== null ? (
              <div className="viewAccountsPageInfowrapper">
                <h1 className="requestPageInfo">Booked for :</h1>
                <h1 className="requestPageData">
                  {viewOrder.data?.data.data.data.patientName}
                </h1>
              </div>
            ) : (
              <></>
            )}

            <div className="viewAccountsPageInfowrapper">
              <h1 className="requestPageInfo">Payment Status :</h1>
              <h1 className="ViewAccountsStatus_Unsuccessful">
                {" "}
                {viewOrder.data?.data.data.data.orderStatus}
              </h1>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="orderHistoryTilesArea">
            <p className="orderHistoryTilesAreaHeading">Order Items :</p>

            <div className="orderHistoryTilesAreaChild">
              {orderObjects.map((item) => (
                <div
                  className={`orderHistoryTiles ${
                    orderSelected.includes(item.orderItemId) ? "selected" : ""
                  }`}
                  id="orderHistoryTiles"
                  key={item.orderItemId}
                  style={{ cursor: "default" }}
                >
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Order Item Id</div>
                    <div className="orderHistoryTilesRight">
                      {item.orderItemId}
                    </div>
                  </div>
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Name</div>
                    <div className="orderHistoryTilesRight">
                      {item.itemName}
                    </div>
                  </div>
                  {item.deliveryStatus !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div
                        className="orderHistoryTilesLeft"
                        style={{ display: "flex", alignItems: "centre" }}
                      >
                        <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                          Delivery Status
                        </p>
                      </div>
                      <div
                        className="orderHistoryTilesRight "
                        style={{ width: "auto" }}
                      >
                        <p
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          className="orderHistoryTilesRightDeliveryStatus"
                        >
                          {item.deliveryStatus}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.paymentStatus !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div
                        className="orderHistoryTilesLeft"
                        style={{ display: "flex", alignItems: "centre" }}
                      >
                        <p style={{ marginTop: "auto", marginBottom: "auto" }}>
                          Payment Status
                        </p>
                      </div>
                      <div
                        className="orderHistoryTilesRight "
                        style={{ width: "auto" }}
                      >
                        <p
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          className="orderHistoryTilesRightDeliveryStatus"
                        >
                          {item.paymentStatus}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Amount</div>
                    <div className="orderHistoryTilesRight">{item.amount}</div>
                  </div>
                  <div className="orderHistoryTilesRows">
                    <div className="orderHistoryTilesLeft">Number of units</div>
                    <div className="orderHistoryTilesRight">
                      {item.itemCount}
                    </div>
                  </div>
                  {item.refundAmount !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div className="orderHistoryTilesLeft">Refund Amount</div>
                      <div className="orderHistoryTilesRight">
                        {item.refundAmount}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item.remarks !== null ? (
                    <div className="orderHistoryTilesRows">
                      <div className="orderHistoryTilesLeft">Remarks</div>
                      <div className="orderHistoryTilesRight">
                        {item.remarks}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewOrderDetails;
