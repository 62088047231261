import * as types from "../../Config/actionType";

export const viewAllPlanRequest = (data) => {
  return {
    type: types.VIEW_ALL_PLAN_REQUEST,
    data: data,
  };
};

export const viewAllPlanSuccess = (data) => {
  return {
    type: types.VIEW_ALL_PLAN_SUCCESS,
    data: data,
  };
};

export const viewAllPlanFailure = (data) => {
  return {
    type: types.VIEW_ALL_PLAN_FAILURE,
    data: data,
  };
};
