import { Route, Routes } from "react-router-dom";
import DashBoard from "../../component/Pages/Dashboard/DashBoard";
// import AppointmentDetail from "../../component/Pages/Doctors/AppointmentDetail";
// import AppointmentStart from "../../component/Pages/Doctors/AppointmentStart";
// import DoctorAppointments from "../../component/Pages/Doctors/DoctorAppointment"
import Career from "../../component/Organisms/Footer/Career/Career";
import TermsAndConditions from "../../component/Organisms/Footer/TermsAndConditions"
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy"
import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound"
import SingleLogin from "../../component/Pages/Login/SingleLogin";


export default function DoctorPrivateRoutes() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />
      <Route path="/doctor-Dashboard" element={<DashBoard />} />
      <Route path="/career" element={<Career />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      {/* <Route path="*" element={<PageNotFound />}></Route> */}



    </Routes>
  );
}
