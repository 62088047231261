import axios from "axios";

import storage from "./storage.js";

let token = storage.getStorage("token");

if (token === null || token === undefined) {
  token = "";
}

const instance1 = axios.create({
  baseURL: "/api/v1/yuva",
});

instance1.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  "Access-Control-Allow-Origin": "*",
  "content-disposition": "attachment;",
//   "content-type": 
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const requestHandler = (request) => {
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

instance1.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

instance1.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default instance1;
