import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Accounts/ViewAccountsAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAccount(action) {
  try {
    let response = yield call(NetworkService.viewAccount, action);
    yield put(actions.viewAccountSuccess(response));
  } catch (err) {
    yield put(actions.viewAccountFailure(err));
  }
}

export function* watchViewAccount() {
  yield takeLatest(types.VIEW_ACCOUNT_REQUEST, viewAccount);
}