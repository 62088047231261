import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/addEmployeeFileAction";
import NetworkService from "../../../network/NetworkService";

export function* addEmployeeFile(action) {
  try {
    let response = yield call(NetworkService.addEmployeeFile, action);
    yield put(actions.addEmployeeFileSuccess(response));
  } catch (err) {
    yield put(actions.addEmployeeFileFailure(err));
  }
}

export function* watchAddEmployeeFileSaga() {
  yield takeLatest(types.ADD_EMPLOYEE_FILE_REQUEST, addEmployeeFile);
}

