import { combineReducers } from "redux";
import { AddLifeStyleReducer } from "./AddLifeStyleReducer";
import { DeleteLifeStyleReducer } from "./DeleteLifeStyleReducer";
import { UpdateLifeStyleReducer } from "./UpdateLifeStyleReducer";

import { viewAllLifeStyleReducer } from "./viewAllLifeStyleReducer";
import { viewLifeStyleReducer } from "./viewLifeStyleReducer";

export default combineReducers({
  viewAllLifeStyle: viewAllLifeStyleReducer,
  viewLifeStyle: viewLifeStyleReducer,
  AddLifeStyle:AddLifeStyleReducer,
  DeleteLifeStyle:DeleteLifeStyleReducer,
  UpdateLifeStyle:UpdateLifeStyleReducer,
});
