import { Avatar } from "@mui/material";
import React, { useState } from "react";

import "./CustomerServiceAgentNavbar.css";
import logo from "../../../assets/logo_primary_light_variant.png";
import storage from "../../../Utils/storage";
import SwitchUserModal from "../../Organisms/Modal/SwitchUserModal";
function Navbar() {
  const name = storage.getStorage("name");
  const [flag, setFlag] = useState(false);
  const handleSwitchUser = () => {
    setFlag(!flag);
  };
  const handleLogout = () => {
    storage.clearStorage();
  };

  const getBackground = (curr) => {
    if (window.location.pathname === curr) {
      return "#E68D36";
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      {flag && <SwitchUserModal closeModal={handleSwitchUser} />}
      <a className="navbar-brand" href="/super-Admin-Dashboard">
        <img src={logo} alt="error" className="navbarLogo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav w-100">
          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              OPDS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/hospital">
                HOSPITALS
              </a>
              <a className="dropdown-item" href="/doctor">
                DOCTORS
              </a>
              <a className="dropdown-item" href="/appointments">
                APPOINTMENTS
              </a>
              {/* <a className="dropdown-item" href="/payments">
                PAYMENTS
              </a> */}
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              REQUEST
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/hospitalRequest">
                HOSPITALS
              </a>
              <a className="dropdown-item" href="/clinicRequest">
                CLINICS
              </a>
              <a className="dropdown-item" href="/diagnosticRequest">
                DIAGNOSTIC CENTER
              </a>
              <a className="dropdown-item" href="/ProfessionalServicesRequest">
                PROFESSIONAL SERVICES
              </a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              COUPON
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/coupon">
                COUPON
              </a>
              <a className="dropdown-item" href="/Discount">
                DISCOUNT
              </a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              LABS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/Lab">
                LAB
              </a>
              <a className="dropdown-item" href="/Test">
                TEST
              </a>
              <a className="dropdown-item" href="/Packages">
                PACKAGE
              </a>
              <a className="dropdown-item" href="/Booking">
                BOOKING
              </a>
            </div>
          </li>

          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              CLIENTS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/corporate">
                CORPORATE
              </a>
              <a className="dropdown-item" href="/agents">
                INSURER
              </a>
              <a className="dropdown-item" href="/Users">
                USERS
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              PROGRAMS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/programTemplate">
                ADD PROGRAM TEMPLATE
              </a>
              <a className="dropdown-item" href="/addPlan">
                ADD PLAN
              </a>
              <a className="dropdown-item" href="/program">
                VIEW PROGRAM TEMPLATE
              </a>
              <a className="dropdown-item" href="/viewAssignedProgram">
                ASSIGNED PROGRAMS
              </a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              PLANS
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
              <a className="dropdown-item" href="/ListOfPlan">
                PLAN DASHBOARD
              </a>
              <a className="dropdown-item" href="/CardForm">
                CREATE CARD
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/banner"
              style={{
                color: getBackground("/banner"),
              }}
            >
              BANNER
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/lifeStyle"
              style={{
                color: getBackground("/lifeStyle"),
              }}
            >
              LIFESTYLE
            </a>
          </li>

          <li class="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle "
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              PRODUCT
            </a>
            <div
              className="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
            >
             
              <a className="dropdown-item" href="/viewCategory">
                CATEGORIES
              </a>
              <a className="dropdown-item" href="/viewBrand">
                BRANDS
              </a>
              <a className="dropdown-item" href="/ProductListing">
                PRODUCTS
              </a>
              {/* <a className="dropdown-item" href="/viewAssignedProgram">
                PRODUCTS
              </a> */}
            </div>
          </li>

          <div className="profile">
            <li className="nav-item dropdown">
              <Avatar
                sx={{ height: "32px", width: "32px" }}
                className="pic"
                alt={name}
                src="/static/images/avatar/3.jpg"
              />
              <a
                className="nav-link dropdown-toggle "
                href="/"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {name}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <div className="dropdown-item1" onClick={handleSwitchUser}>
                  Switch user
                </div>
                <a className="dropdown-item" href="/" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
