class StorageService {
  getStorage = (key) => {
    const value = sessionStorage.getItem(key);
    if (value) {
      return value;
    } else {
      return null;
    }
  };
  setStorage(key, value) {
    return sessionStorage.setItem(key, value);
  }

  clearStorage() {
    return sessionStorage.clear();
  }

  removeStorage(key) {
    return sessionStorage.removeItem(key);
  }
}

const storage = new StorageService();

export default storage;