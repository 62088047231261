import * as types from "../../Config/actionType"

export const getPlanDropdownForCardRequest = () => {
    return {
        type: types.PLANS_DROPDOWN_FOR_CARD_REQUEST,
    }
}

export const getPlanDropdownForCardSuccess = (data) => {
    return {
        type: types.PLANS_DROPDOWN_FOR_CARD_SUCCESS,
        data
    }
}

export const getPlanDropdownForCardFailure = (data) => {
    return {
        type: types.PLANS_DROPDOWN_FOR_CARD_FAILURE,
        data
    }
}