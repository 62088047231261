import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/confirmAppointmentAction"
import NetworkService from "../../../network/NetworkService";

export function* confirmAppointmentSaga(action) {
  try {
    let response = yield call(NetworkService.confirmAppointment, action);
    yield put(actions.confirmAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.confirmAppointmentFailure(err));
  }
}

export function* watchConfirmAppointmentSaga() {
  yield takeLatest(types.CONFIRM_APPOINTMENT_REQUEST, confirmAppointmentSaga);
}