import * as types from "../../Config/actionType"

export const checkEmailAgentRequest = (data) => {
    return {
        type: types.CHECK_EMAIL_AGENT_REQUEST,
        data:data
    }
}

export const checkEmailAgentSuccess = (data) => {
    return {
        type: types.CHECK_EMAIL_AGENT_SUCCESS,
        data:data,
    }
}

export const checkEmailAgentFailure = (data) => {
    return {
        type: types.CHECK_EMAIL_AGENT_FAILURE,
        data:data,
    }
}