import * as types from "../../Config/Product/ProductActionType";

export const getCategoryDetailsRequest = (data) => {
  return {
    type: types.GET_CATEGORY_DETAILS_REQUEST,
    data
  };
};

export const getCategoryDetailsSuccess = (data) => {
  return {
    type: types.GET_CATEGORY_DETAILS_SUCCESS,
    data: data,
  };
};

export const getCategoryDetailsFailure = (data) => {
  return {
    type: types.GET_CATEGORY_DETAILS_FAILURE,
    data: data,
  };
};
