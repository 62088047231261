import * as types from "../../Config/actionType"

export const getServiceDropdownRequest = () => {
    return {
        type: types.GET_SERVICE_DROPDOWN_REQUEST,
    }
}

export const getServiceDropdownSuccess = (action) => {
    return {
        type: types.GET_SERVICE_DROPDOWN_SUCCESS,
        action
    }
}

export const getServiceDropdownFailure = (action) => {
    return {
        type: types.GET_SERVICE_DROPDOWN_FAILURE,
        action
    }
}