import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Service/viewServiceAction1";
import NetworkService from "../../../network/NetworkService";

export function* viewServiceSaga1(action) {
  try {
    let response = yield call(NetworkService.viewService1, action);
    yield put(actions.viewServiceSuccess1(response));
  } catch (err) {
    yield put(actions.viewServiceFailure1(err));
  }
}

export function* watchViewServiceSaga1() {
  yield takeLatest(types.VIEW_SERVICE_REQUEST1, viewServiceSaga1);
}