import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import "./Payment.css";
import { viewAllPaymentRequest } from "../../../redux/Actions/Payment/viewAllPaymentAction";
import { DataGrid } from "@mui/x-data-grid";


function Payment() {
  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Id",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "patientName",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
    {
      field: "hospitalName",
      headerClassName: "super-app-theme--header",
      headerName: "Hospital",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
    {
      field: "doctorName",
      headerClassName: "super-app-theme--header",
      headerName: "Doctor",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
    {
      field: "patientNumber",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Number",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
   
    {
      field: "timeSlot",
      headerClassName: "super-app-theme--header",
      headerName: "Date of Appointment",
      disableColumnMenu: true,
      sortable: false,
      flex: 3,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["timeSlot"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "appointmentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row["appointmentStatus"] === "CANCELLED" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "FINISHED" && (
              <Typography>
                <Chip label="FINISHED" color="primary" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "COMPLETED" && (
              <Typography>
                <Chip label="COMPLETED" color="success" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "CONFIRMED" && (
              <Typography>
                <Chip label="CONFIRMED" color="info" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "INITIATED" && (
              <Typography>
                <Chip label="INITIATED" color="secondary" size="medium" />
              </Typography>
            )}
            {params.row["appointmentStatus"] === "RESCHEDULED" && (
              <Typography>
                <Chip label="RESCHEDULED" color="warning" />
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "amount",
      headerClassName: "super-app-theme--header",
      headerName: "Consultation Amount",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Payment Status",
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row["paymentStatus"] === "SEND_TO_ACCOUNT" && (
              <p1 className="paymentStatus_SEND_TO_ACCOUNT">Send To Account</p1>
            )}
            {params.row["paymentStatus"] === "PAID" && (
              <p1 className="paymentStatus_Paid">Paid</p1>
            )}
            {params.row["paymentStatus"] === "PENDING" && (
              <p1 className="paymentStatus_PENDING">Pending</p1>
            )}
          </>
        );
      },
    },
    {
      field: "lotId",
      headerClassName: "super-app-theme--header",
      headerName: "Lot ID",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        if (params.row["lotId"] === null) {
          return <p>-</p>;
        } else {
          <p>{params.row["lotId"]}</p>;
        }
      },
    },
  ];

 

  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [startDate, setStartDate] = React.useState(0);
  const [endDate, setEndDate] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [ setV] = React.useState(1);
  const [searchPage, setSearchPage] = useState(1);

  const payment = useSelector((state) => state.viewAllPayment);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
 
    dispatch(
      viewAllPaymentRequest(1, pageSize, startDate, endDate, searchText)
    );
    setPage(1);
  }, [startDate, endDate]);

  const handlePageChange = (e, value) => {
  
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllPaymentRequest(value, pageSize, startDate, endDate, searchText)
      );
      setPage(value);
    } else {
      dispatch(
        viewAllPaymentRequest(value, pageSize, startDate, endDate, searchText)
      );
      setSearchPage(value);
    }

    setV(e.target.value);
  };

  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      viewAllPaymentRequest(
        page,
        e.target.value,
        startDate,
        endDate,
        searchText
      );
      setPage(1);
    } else {
      viewAllPaymentRequest(
        searchPage,
        e.target.value,
        startDate,
        endDate,
        searchText
      );
    }
    setPageSize(e.target.value);
  };

  const handleCreateLot = () => {
    navigate("/createLot");
  };

  const handleUpdatePayment = () => {
    navigate("/lotStatus");
  };

  const handleStartDate = (e) => {
    var tempStartDate = new Date(e.target.value);
    tempStartDate = tempStartDate.getTime();
    setStartDate(tempStartDate);
  };

  const handleEndDate = (e) => {
    var date = new Date(e.target.value);
    date = date.getTime();
    setEndDate(date);
  };

  var tempDate = new Date(startDate).toISOString().split("T")[0];
  const handleSearch=(e)=> {

    const newSearchText = e.target.value;

   
    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        viewAllPaymentRequest(page, pageSize, startDate, endDate, newSearchText)
      );
      setPage(1);
    } else {
      dispatch(
        viewAllPaymentRequest(
          searchPage,
          pageSize,
          startDate,
          endDate,
          newSearchText
        )
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <div>
      <div className="grid">
        <h4 className="h4">Payments</h4>
        <div className="mainBox">
          <div className="paymentHead">
            <div className="appleftHead">
              <h5 className="list">List of Payments</h5>
              <div className="count">
                {payment.data?.data.data.data.totalAppointments} Payments
              </div>
            </div>
            <div className="paymentrightheadbtn">
              <button
                type="button"
                className="btn updatePaymentBtn"
                onClick={() => handleUpdatePayment()}
              >
                Update Payment
              </button>
              <button
                type="button"
                className="btn createLotBtn"
                onClick={() => handleCreateLot()}
              >
                Create Lot
              </button>
            </div>

          </div>
          <hr />
          <div className="paymentRow2">
            <div className="paymentDate">
              <div className="paymentStartDate">
                Start Date
                <input
                  type="date"
                  onChange={(e) => handleStartDate(e)}
                  style={{
                    border:
                      endDate !== 0 && startDate === 0
                        ? "1px solid red"
                        : "1px solid black",
                  }}
                ></input>
              </div>
              <div className="paymentEndDate">
                End Date
                <input
                  type="date"
                  onChange={(e) => handleEndDate(e)}
                  min={tempDate}
                  style={{
                    border:
                      startDate !== 0 && endDate === 0
                        ? "1px solid red"
                        : "1px solid black",
                  }}
                ></input>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #52608E",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              
            </div>
          </div>
          <div>
            {" "}
            {endDate !== 0 && startDate === 0 && (
              <div
                style={{ color: "red", fontSize: "12px", marginLeft: "12em" }}
              >
                Please select Start Date
              </div>
            )}
            {startDate !== 0 && endDate === 0 && (
              <div
                style={{ color: "red", fontSize: "12px", marginLeft: "34.6em" }}
              >
                Please select End Date
              </div>
            )}
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {payment.data?.data.data.data.appointmentLotResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rowCount={payment.data?.data.data.data.totalAppointments}
                rows={payment.data?.data.data.data.appointmentLotResponseDtos}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={payment.data?.data.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
