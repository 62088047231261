import * as types from "../../Config/actionType";

export const updatePaymentActionRequest = (data) => {
  return {
    type: types.UPDATE_PAYMENT_REQUEST,
    data:data,
  };
};

export const updatePaymentActionSuccess = (data) => {
  return {
    type: types.UPDATE_PAYMENT_SUCCESS,
    data: data,
  };
};

export const updatePaymentActionFailure = (data) => {
  return {
    type: types.UPDATE_PAYMENT_FAILURE,
    data: data,
  };
};
