import * as types from "../../Config/actionType"

export const uploadReportAppointmentsRequest = (data,id) => {
    return {
        type: types.UPLOAD_REPORT_APPOINTMENTS_REQUEST,
        data,
        id
    }
}

export const uploadReportAppointmentsSuccess = (data) => {
    return {
        type: types.UPLOAD_REPORT_APPOINTMENTS_SUCCESS,
        data:data,
    }
}

export const uploadReportAppointmentsFailure = (data) => {
    return {
        type: types.UPLOAD_REPORT_APPOINTMENTS_FAILURE,
        data:data,
    }
}