import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Lab/approveDiagnosticAction";
import NetworkService from "../../../network/NetworkService";

export function* approveDiagnostic(action) {
  try {
    let response = yield call(NetworkService.approveDiagnostic, action);
    yield put(actions.approveDiagnosticSuccess(response));
  } catch (err) {
    yield put(actions.approveDiagnosticFailure(err));
  }
}

export function* watchApproveDiagnostic() {
  yield takeLatest(types.APPROVE_DIAGNOSTIC_REQUEST, approveDiagnostic);
}

