import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CardContent, Grid, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { roles } from "../../../helpers/MainConstants";
import { viewPackagesRequest } from "../../../redux/Actions/Packages/viewPackagesAction";
import storage from "../../../Utils/storage";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalAddPackage from "../../Organisms/Modal/ModalAddPackage";
import ModalUpdatePackage1 from "../../Organisms/Modal/ModalUpdatePackage1";
import "./Packages.css";
import { UserContext } from "../../../App";
import { mapToElasticRequest } from "../../../redux/Actions/Doctors/mapToElastic"
import Loader from "../../../assets/Loader/loader.gif";

function Packages() {
  const [add, setAdd] = useState(null);
  const [value, setValue] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [editService, setEditService] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const role = useContext(UserContext);
  function handleSync() {
    dispatch(mapToElasticRequest());
  }

  const mapToElastic = useSelector((state) => state.mapToElastic);

  const debouncedOnChanged = useDebouncedCallback((value) => {
    if (value.length === 1) {
      dispatch(viewPackagesRequest(""));
    } else {
      dispatch(viewPackagesRequest(value));
    }
  }, 2000);

  const handleSearch = (e) => {

    setValue(e.target.value);

    debouncedOnChanged(value);
  };

  useEffect(() => {
    dispatch(viewPackagesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setAdd(true);
  };
  const service = useSelector((state) => state.viewPackages);
  const numService = service.data?.data?.data?.data?.length;
  // const allPackageArray = ;


  // const ser = useSelector((state) => state.viewAllServiceForCorporate);

  const handleClick = (packageUuid) => {
    navigate(`/viewPackages/${packageUuid}`);
  };

  const EditPackageClick = (name) => {
    setShowModal(true);
    service.data?.data?.data.data.map((item) => {
      if (name === item.packageName) {
        setEditService(item);
      }
    });
  };
  // const handleEdit = (id) => {
  // };

  // const debouncedOnChanged = useDebouncedCallback((value) => {
  //   dispatch((value));
  // }, 2000);

  // const handleSearch = (e) => {
  // setValue(e.target.value);
  // debouncedOnChanged(value);
  // };

  // if(window.location.pathname === '/services'){
  //   setSuperAdmin(true)
  // }else{
  //   setSuperAdmin(false)
  // }
  return (
    <div>
      <div className="servicename">Packages</div>
      <div className="servicemainBox">
        <div className="servicehead">
          <div className="left__Head">
            <h5 className="servicelist">List of Packages</h5>
            &nbsp;&nbsp;&nbsp;
            <div className="servicecount">{numService} Packages</div>
          </div>
          <div className="rightheadbtn">
            {mapToElastic?.isLoading === true ? <button className="btn"
              style={{ width: "10%", height: "2.3em", paddingRight: "1em", paddingLeft: "1em", marginRight: "1em", display: "flex", alignItems: "center", justifyContent: "center", background: "#3C9C4B" }}

            >
              <img src={Loader} style={{ width: "50%", height: "70%" }} />

            </button> : <button className="btn" onClick={handleSync}
                style={{width:"10%",height:"2.3em",paddingRight:"1em",paddingLeft:"1em",marginRight:"1em",display:"flex",alignItems:"center",justifyContent:"center"}}
            
            >
              Sync
            </button>}

            {role === roles.SUPER_ADMIN && (
              <div type="button" className="btn" onClick={() => handleAdd()}>
                + Add Package
              </div>
            )}
          </div>

          {add && <ModalAddPackage open={setAdd} />}
          {showModal && (
            <ModalUpdatePackage1
              open={setShowModal}
              editService={editService}
            />
          )}
        </div>
        <hr />
        <div className="row2">
          <div></div>
          <div className="searchersSection">
            <div className="searchBar">
              <TextField
                type="text"
                name="search"
                onChange={handleSearch}
                variant="outlined"
                sx={{
                  borderRadius: "6px",
                  border: "1px solid #52608E",
                }}
                placeholder="Search"
                size="small"
                hiddenLabel="true"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#52608E" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <div className="searchButton">
              <button
                type="submit"
                value="Submit"
                className="agentsearchBtn"
                style={{
                  backgroundColor: "#52608E",
                  width: "150px",
                  align: "end",
                  color: "white",
                }}
              >
                Filter
              </button>
            </div> */}
          </div>
        </div>
        <hr />

        <div className="card-box">
          <Box>
            <CardContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                direction="row"
              >
                {service.data?.data?.data?.data?.map((data) => (
                  <div className="cardse1">
                    <div className="card-head1">
                      <div className="card-head1Child1"> {data.packageName}</div>
                      <div className="card-head1Child2">


                        {role === roles.SUPER_ADMIN && data.edit === true && (
                          <EditIcon
                            onClick={() => EditPackageClick(data.packageName)}
                            // onClick = {props.onClick}
                            className="service-card-edit"
                            sx={{ color: "#52608E;" }}
                            fontSize="small"
                          />
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <VisibilityIcon
                          className="service-card-edit"
                          sx={{ color: "#52608E" }}
                          fontSize="small"
                          onClick={() => handleClick(data.packageUuid)}
                        // onClick={() => handleDelete(data)}
                        />
                      </div>
                    </div>
                    <div className="carding-bottom1">
                      <div className="carding-bottom1Child1">
                        <p>Rs {data.packageCost} /-</p>
                      </div>
                      <div className="carding-bottom1Child2">
                        {data.featured === true ? <p>Featured</p> : ""}
                      </div>



                    </div>

                  </div>
                ))}
              </Grid>
            </CardContent>
          </Box>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Packages;