import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/LifeStyle/lifeStyleActionType";
import * as actions from "../../Actions/LifeStyle/AddLifeStyleAction"
import NetworkService from "../../../network/LifeStyle/viewAllLifeStyleService";

export function* AddLifeStyleSaga(action) {
  try {
    let response = yield call(NetworkService.AddLifeStyle, action);
    yield put(actions.AddLifeStyleSuccess(response));
  } catch (err) {
    yield put(actions.AddLifeStyleFailure(err));
  }
}

export function* watchAddLifeStyleSaga() {
  yield takeLatest(types.ADD_LIFESTYLE_REQUEST, AddLifeStyleSaga);
}