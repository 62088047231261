import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Hospital/NetworkViewHospitalAction"
import NetworkService from "../../../../network/NetworkService";

export function* networkViewHospitalSaga(action) {
  try {
    let response = yield call(NetworkService.networkViewHospital, action);
    yield put(actions.networkViewHospitalSuccess(response));
  } catch (err) {
    yield put(actions.networkViewHospitalFailure(err));
  }
}

export function* watchNetworkViewHospitalSaga() {
  yield takeLatest(types.NETWORK_VIEW_HOSPITAL_REQUEST, networkViewHospitalSaga);
}