import React, { useEffect, useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addDoctorRequest } from "../../../redux/Actions/Doctors/addDoctorAction";
import { viewAllHospitalNamesRequest } from "../../../redux/Actions/Hospitals/viewAllHospitalNamesAction";
import { Close } from "@mui/icons-material";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { modalHeader } from "./ModalConstants";
import { Input } from "antd";

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  speciality: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . ,]+$/, "Insert only normal character"),
  qualifications: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . , &]+$/, "Insert only normal character"),
  registrationNumber: Yup.string().required("Required Field"),
  rating: Yup.string().required("Required Field"),

  experience: Yup.string()
    .required("Required Field")
    .matches(/^[0-9]+$/, "Input must contain only digits")
    .max(2, "Not Valid"),
  hospitalId: Yup.string().required("Required Field"),
  cost: Yup.string()
    .matches(/^[0-9]+$/, "Input must contain only digits")
    .required("Required Field"),
});

function ModalAddDoctor(props) {
  useEffect(() => {
    dispatch(viewAllHospitalNamesRequest());
  }, []);

  const viewHospitalNames = useSelector((state) => state.viewAllHospitalNames);

  const dispatch = useDispatch();

  const handleClose = () => props.open(false);

  const [validationsignaturePhoto, setvalidationsignaturePhoto] =
    useState(false);
  const [signaturePhoto, setSignaturePhoto] = useState();
  const [check, setCheck] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function waitForLoad(id, callback) {
    var timer = setInterval(function () {
      if (
        document.getElementById("fileUploadInput3") &&
        document.getElementsByClassName("file-upload-select")[0]
      ) {
        clearInterval(timer);
        callback();
      }
    }, 200);
  }

  waitForLoad("subm", function () {
    let fileInput3 = document.getElementById("fileUploadInput3");

    fileInput3.onchange = function () {
      let filename = fileInput3.files[0].name;
      let selectName = document.getElementsByClassName("file-select-name3")[0];
      selectName.innerText = filename;
    };
  });

  const handleFileHospitalPhotoChange = (event) => {
    if (
      event.target.files[0]?.type === "image/png" ||
      event.target.files[0]?.type === "image/jpeg" ||
      event.target.files[0]?.type === "image/jpg"
    ) {
      setSignaturePhoto(event.target.files[0]);
      setvalidationsignaturePhoto(false);
    } else {
      setvalidationsignaturePhoto(true);
    }
  };


  const handleSubmit = (values, { setSubmitting }) => {
    setCheck(true);
    setIsSubmitting(true);
    const DoctorData = {
      cost: parseInt(values.cost),
      experience: parseInt(values.experience),
      hospitalId: parseInt(values.hospitalId),
      name: values.name,
      qualifications: values.qualifications,
      rating: parseInt(values.rating),
      registrationNumber: values.registrationNumber,
      speciality: values.speciality,
    };

    const newFormData = new FormData();

    newFormData.append("doctorRequest", JSON.stringify(DoctorData));
    newFormData.append("signatureFile", signaturePhoto);
    dispatch(addDoctorRequest(newFormData));
  };
  const res = useSelector((state) => state.addDoctor);
  if (res.data?.data.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Doctor added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response?.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Add New Doctor
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>
          <div className="DoctorInHospitalName">{props.name}</div>


          <Formik
            initialValues={{
              name: "",
              speciality: "",
              qualifications: "",
              registrationNumber: "",
              experience: "",
              hospitalId: props.id === undefined ? "" : props.id,
              cost: "",
              rating: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Doctor Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="speciality"
                      placeholder="Doctor Speciality"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="speciality"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type=""
                      name="qualifications"
                      placeholder="Doctor Qualification"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="qualifications"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      name="experience"
                      placeholder="Years of Experience"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="experience"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="cost"
                      placeholder="Consultation Cost"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="cost"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="rating"
                      placeholder="Rating"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="rating"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="registrationNumber"
                      placeholder="Registration Number"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="registrationNumber"
                      component="div"
                    />
                  </label>
                  {props.id === undefined ?
                    <label>
                      <Field
                        as="select"
                        name="hospitalId"
                        placeholder="Select Services"
                        className="inputField"
                      >
                        <option value="" disabled selected>
                          Select Hospital
                        </option>
                        {viewHospitalNames.data?.data?.data?.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        )}
                      </Field>
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="hospitalId"
                        component="div"
                      />
                    </label>
                    : ""}
                  <label>
                    <div class="file-upload">
                      <div class="file-upload-select">
                        <div class="file-select-button">Upload Signature</div>
                        <div class="file-select-name3">
                          Supported format .jpg and .png
                        </div>
                        <Input
                          type="file"
                          name="HospitalPhoto"
                          accept="image/png, image/jpeg ,image/jpg"
                          id="fileUploadInput3"
                          className="NetworkPageFormInputField"
                          onChange={(event) =>
                            handleFileHospitalPhotoChange(event)
                          }
                        />
                      </div>
                      {validationsignaturePhoto && (
                        <div className="errorFileValidationAddModal">
                          Please Select a valid .png or .jpeg File
                        </div>
                      )}
                    </div>

                    {signaturePhoto?.size / 1024 > 10240 && (
                      <div className="errorFileValidationAddModal">
                        {" "}
                        File Size should be less than 10mb
                      </div>
                    )}

                  </label>
                  <button
                    type="submit"
                    className="modalButton"
                    disabled={isSubmitting || validationsignaturePhoto}
                  >
                    Add Doctor
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddDoctor;
