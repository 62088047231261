import * as types from "../../../Config/actionType"

export const viewAllProgramTemplateNamesRequest = (data) => {
    // 
    return {
        type: types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_REQUEST,
        data
    }
}

export const viewAllProgramTemplateNamesSuccess = (action) => {
    //
    return {
        type: types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_SUCCESS,
        action
    }
}

export const viewAllProgramTemplateNamesFailure = (action) => {
    //
    return {
        type: types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_FAILURE,
        action
    }
}