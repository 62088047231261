import * as types from "../../Config/actionType"

export const getRelationRequest = (id) => {
    return {
        type: types.GET_RELATION_REQUEST,
        id,      
    }
}

export const getRelationSuccess = (action) => {
    return {
        type: types.GET_RELATION_SUCCESS,
        action
    }
}

export const getRelationFailure = (action) => {
    return {
        type: types.GET_RELATION_FAILURE,
        action
    }
}