import * as types from "../../Config/actionType"

export const updatePackageFeatureRequest = (data) => {
    return {
        type: types.UPDATE_PACKAGE_FEATURE_REQUEST,
        data:data
    }
}

export const updatePackageFeatureSuccess = (data) => {
    return {
        type: types.UPDATE_PACKAGE_FEATURE_SUCCESS,
        data:data,
    }
}

export const updatePackageFeatureFailure = (data) => {
    return {
        type: types.UPDATE_PACKAGE_FEATURE_FAILURE,
        data:data,
    }
}