import { combineReducers } from "redux";
import { getAllBannerByTypeReducer } from "./getAllBannerByTypeReducer";
import { getItemTypeReducer } from "./getItemTypeReducer";
import { addBannerDetailReducer } from "./addBannerDetailReducer";
import { deleteByIdReducer } from "./deleteByIdReducer";

export default combineReducers({
    getAllBannerByType : getAllBannerByTypeReducer,
    getItemType : getItemTypeReducer,
    addBannerDetail :addBannerDetailReducer,
    deleteById : deleteByIdReducer,
});
