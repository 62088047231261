import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/getDirectionAction"
import NetworkService from "../../../network/NetworkService";

export function* getDirectionSaga(action) {
  try {
      //
    let response = yield call(NetworkService.getDirection, action);
    yield put(actions.getDirectionSuccess(response));
  } catch (err) {
    yield put(actions.getDirectionFailure(err));
  }
}

export function* watchGetDirectionSaga() {
  yield takeLatest(types.GET_DIRECTION_REQUEST, getDirectionSaga);
}