import { all } from "redux-saga/effects";
import { watchAddLifeStyleSaga } from "./AddLifeStyleSaga";
import { watchDeleteLifeStyleSaga } from "./DeleteLifeStyleSaga";
import { watchUpdateLifeStyleSaga } from "./UpdateLifeStyleSaga";
import { watchViewAllLifeStyle } from "./viewAllLifeStyleSaga";
import { watchViewLifeStyleDetail } from "./viewLifeStyleSaga";


function* rootLifeStyleSaga() {
  yield all([
    watchViewAllLifeStyle(),
    watchViewLifeStyleDetail(),
    watchAddLifeStyleSaga(),
    watchDeleteLifeStyleSaga(),
    watchUpdateLifeStyleSaga(),
  ]);
}

export default rootLifeStyleSaga;