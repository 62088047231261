import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css";
// import { BarChart } from '@mui/icons-material';
import { Formik, Form, Field } from "formik";
import GAD_BarChart from "./GAD_BarChart";
import InfoIcon from "@mui/icons-material/Info";

function MentalHealthRisk_BarChart({ phqResponseDto }) {
  const [selectChart, setSelectchart] = React.useState(true);

 
  const options = {
    labels: ["Minimal","Mild" ,"Moderate","Moderately Severe ","Severe"],
    name: ["Minimal", "Mild","Moderate","Moderately Severe","Severe"],

    chart: {
      height: 200,
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "85%",
        borderRadius: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      position: "top",
      show: true,
      floating: false,
      labels: {
        show: true,
      },
    },
    yaxis: {
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 40,
        bottom: 2,
        left: 0,
      },
    },
    colors: ["#77D970", "#E6BF36","#E68D36","#570A57",  "#C82404"],
  };

  const series = [
    {
      name: ["Mental Health Risk"],

      data: [
        phqResponseDto?.phqResponseDto?.nonMinimal,
        phqResponseDto?.phqResponseDto?.moderatelySevere,
        phqResponseDto?.phqResponseDto?.mild,
        phqResponseDto?.phqResponseDto?.moderate,
        phqResponseDto?.phqResponseDto?.severe,
      ],
    },
  ];

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      // dispatch(addDoctorRequest(values));
      setSubmitting(false);
    }, 400);
  };
  const handleChangeGAD = (event) => {
   
    if (event.target.value === "PHQ") {
      setSelectchart(true);
    } else {
      setSelectchart(false);
    }

   
  };

  return (
    <>
      <div className="infoMentalHealth">
        <InfoIcon></InfoIcon>{" "}
        <span class="myServicesDescriptionText">
          "The PHQ-9 is a multipurpose instrument for screening, diagnosing,
          monitoring and measuring the severity of depression.
           <br/>
           <br/>

           The Generalized
          Anxiety Disorder 7 (GAD-7) is a easy to perform initial screening tool
          for generalized anxiety disorder.
        </span>
      </div>
      <Formik
        initialValues={{
          name: "",
        }}
        // validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {
          <Form>
            <label>
              <Field
                as="select"
                name="hospitalId"
                placeholder="Select Services"
                className="chartsinputfields"
                onChange={handleChangeGAD}
              >
                <option value="PHQ">
                  PHQ (personal health questionnaire){" "}
                </option>
                <option value="GAD">GAD (general anxiety disorder)</option>
              </Field>
            </label>
          </Form>
        }
      </Formik>

      {selectChart ? (
        <Chart
          options={options}
          series={series}
          type="bar"
          height="auto"
          className="apex-charts"
          dir="ltr"
        />
      ) : (
        <GAD_BarChart phqResponseDto={phqResponseDto} />
      )}
    </>
  );
}
export default MentalHealthRisk_BarChart;
