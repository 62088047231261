import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

function ProgramDetailsList(props) {
  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Program Name",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      headerName: "Start Date",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "corporateName",
      headerClassName: "super-app-theme--header",
      headerName: "Corporate Name",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "programStatus",
      headerClassName: "super-app-theme--header",
      headerName: " Status",
      flex: 0.5,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row["status"] === false && (
              <Typography>
                <Chip label="Inactive" color="error" />
              </Typography>
            )}
            {params.row["status"] === true && (
              <Typography>
                <Chip label="Active" color="primary" />
              </Typography>
            )}

            {params.row["status"] === "Upcoming" && (
              <Typography>
                <Chip label="Upcoming" color="warning" />
              </Typography>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <Box
        sx={{
          height: 150,
          width: "100%",
        }}
      >
        {props?.data && (
          <DataGrid
            sx={{
              color: "#1D2334",
              "& .super-app-theme--header": {
                backgroundColor: "#52608E",
                color: "#F2EFEA",
                "& > .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              },
              "&:hover:not(.Mui-disabled)": {
                cursor: "pointer",
              },
              ".MuiTablePagination-toolbar": {
                alignItems: "baseline",
                marginTop: "5%",
              },
            }}
            getRowId={(row) => row.name + row.startDate}
            // pagination
            disableColumnMenu
            rows={props?.data}
            columns={columns}
            components={{
              Footer: "none",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default ProgramDetailsList;
