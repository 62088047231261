import * as types from "../../Config/actionType"

export const viewTestRequest = (page,pageSize,search,city) => {
    return {
        type: types.VIEW_TEST_REQUEST,
        page,
        pageSize,
        search,
        city
    }
}

export const viewTestSuccess = (action) => {
    return {
        type: types.VIEW_TEST_SUCCESS,
        action
    }
}

export const viewTestFailure = (action) => {
    return {
        type: types.VIEW_TEST_FAILURE,
        action
    }
} 