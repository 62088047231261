import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/viewAllProgramTemplateNamesAction";
import NetworkService from "../../../../network/NetworkService";

export function* viewAllProgramTemplateNamesSaga(action) {
  try {
    // 
    let response = yield call(NetworkService.viewAllProgramTemplateNames, action);
    //
    yield put(actions.viewAllProgramTemplateNamesSuccess(response));
  } catch (err) {
    yield put(actions.viewAllProgramTemplateNamesFailure(err));
  }
}

export function* watchviewAllProgramTemplateNamesSaga() {
  yield takeLatest(types.VIEW_ALL_PROGRAM_TEMPLATE_NAMES_REQUEST, viewAllProgramTemplateNamesSaga);
}
