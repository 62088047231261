import * as types from "../../Config/actionType"

export const loginLabAgentRequest = (data) => {
   
    return {
        type: types.LOGIN_LAB_AGENT_REQUEST,
        data:data
    }
}

export const loginLabAgentSuccess = (data) => {
   
    return {
        type: types.LOGIN_LAB_AGENT_SUCCESS,
        data:data,
    }
}

export const loginLabAgentFailure = (data) => {
   
    return {
        type: types.LOGIN_LAB_AGENT_FAILURE,
        data:data,
    }
}