export const api = {
    getScreenTypes:"/banner",
    getBannerByScreenTypes:"/banner",
    getBannerTypes:"/banner",
    addBanner:"/banner",
    deleteBanner:"/banner",
    deletePositionBanner:"/banner",
    getScreenTypes: "/banner",
    getBannerByScreenTypes: "/banner",
    getAllBannerByType: "/banner/all",
    getItemType: "/banner/item-type",
    addBannerDetail: "/banner/detail",
    deleteById: "/banner",
}