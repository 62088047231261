import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { addTestRequest } from "../../../redux/Actions/Tests/addTestAction";
import "./Modal.css";
import { modalHeader, modalHeaderSub } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const addSchema = Yup.object().shape({
  TestName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.,&-])[A-Za-z0-9 (),&.-]+$/, "Insert only normal character"),
  TestTime: Yup.string().required("Required Field"),
  TestTimeHours: Yup.string().required("Required Field"),
  TestAmount: Yup.string().required("Required Field"),
  // Interpretation: Yup.string().required("Required Field"),
  Description: Yup.string().required("Required Field"),
  TestTimeHours: Yup.number()
    .integer("Test Time Hours must be an integer")
    .min(0, "Test Time Hours  must be greater than or equal to 0")
    .max(24, "Test Time Hours must be less than or equal to 24")
    .required("Required Field"),
});

function ModalAddTest(props) {
  const res = useSelector((state) => state.addTest);
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const INITIAL_STATE = {};
  const handleClose = () => props.open(false);
  const [FormValidation, setFormValidation] = useState([INITIAL_STATE]);
  const [inputList, setInputList] = useState([{ parameterName: "" }]);
  const [instruction, setInstruction] = useState("");
  const [radiology, setRadiology] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: "" }]);
    FormValidation.push(INITIAL_STATE);
  };

  function handleInstruction(e) {
    setInstruction(e.target.value);
  }
  const updateRes = useSelector((state) => state.addTest)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])
  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    const TestTotalTime = values.TestTime * 24 + values.TestTimeHours;
    let csvString = [];
    inputList.map((index) => {
      csvString.push({ parameterName: index.parameterName.trim() });
    });
    if (csvString[0].parameterName === "") {
      csvString = [];
    }
    let submitValues = {
      values,
      csvString,
      description,
      TestTotalTime,
      instruction,
      radiology,
    };
    dispatch(addTestRequest(submitValues));
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  if (res.data?.data?.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Test added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res?.error?.data?.response?.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  function handleRadiology(e) {
    if (e.target.value === "true") {
      setRadiology(true);
    } else {
      setRadiology(false);
    }
  }
  function preventTextInput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode < 47 && keycode > 58) || (keycode < 95 && keycode > 107)) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="modalHeaderText"
              sx={modalHeader}
            >
              Add New Test
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              TestName: "",
              TestAmount: "",
              Interpretation: "",
              TestTime: "",
              TestTimeHours: "",
              Description: "",
            }}
            validationSchema={addSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="text"
                      name="TestName"
                      placeholder="Name"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="TestName"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      as="textarea"
                      // onBlur={(e) => {
                      //   handleDescription(e);
                      // }}
                      placeholder="Description"
                      className="inputFieldDescription"
                      // id="description"
                      name="Description"
                      rows="14"
                      cols="10"
                      wrap="soft"
                      // value={description}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="Description"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="number"
                      name="TestAmount"
                      placeholder="Amount"
                      className="inputField"
                      onKeyDown={(e) => {
                        ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault();
                      }}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="TestAmount"
                      component="div"
                    />
                  </label>
                  <div className="alignTime">
                    <div>
                      <label>
                        <Field
                          type="number"
                          name="TestTime"
                          // value={TestTime}
                          placeholder="Time (in days)"
                          className="textingField"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="TestTime"
                          component="div"
                        />
                      </label>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                      <label>
                        <Field
                          type="number"
                          name="TestTimeHours"
                          placeholder="Time (in hours)"
                          className="textingField"
                          onWheel={(event) => event.currentTarget.blur()}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="TestTimeHours"
                          component="div"
                        />
                      </label>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                      {/* <label>
                        <Field
                          type="number"
                          name="TestTimeHours"
                          placeholder="Time (in hours)"
                          className="textingField"
                          onWheel={event => event.currentTarget.blur()}

                        />
                        <ErrorMessage
                          className="errorMessageModalValidation"
                          name="TestTimeHours"
                          component="div"
                        />
                      </label> */}
                    </div>
                  </div>

                  <label>
                    <select className="inputField" onChange={handleInstruction}>
                      <option value="" disabled selected>
                        Select Instructions
                      </option>
                      <option value="Fasting Required">Fasting Required</option>
                      <option value="Fasting Not Required">
                        Fasting Not Required
                      </option>
                    </select>
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="Interpretation"
                      placeholder="Interpretation (optional)"
                      className="inputField"
                      // onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="Interpretation"
                      component="div"
                    />
                  </label>
                  <label>
                    <select className="inputField" onChange={handleRadiology}>
                      <option value="" disabled selected>
                        Radiology
                      </option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                  </label>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                    sx={modalHeaderSub}
                  >
                    Parameters
                  </Typography>
                  <div className="attributeCardBox">
                    {inputList.map((x, i) => {
                      return (
                        <>
                          <div className="addParameterWrapper">
                            <div>
                              <input
                                name="parameterName"
                                placeholder="Parameter name"
                                id="parameterName"
                                className="inputFieldAddParameterFirst"
                                value={x.parameterName}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                              <p className="errorMessageModalValidatio">
                                {FormValidation[i]?.parameterName}
                              </p>
                            </div>
                            <div className="addParameterCross">
                              {inputList.length !== 1 && (
                                <button
                                  className="addParameterCrossButton"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  X
                                </button>
                              )}
                            </div>
                          </div>

                          <div>
                            {inputList.length - 1 === i && (
                              <button
                                onClick={() => {
                                  handleAddClick();
                                }}
                                className="addParameterButton"
                              >
                                + Add Parameter
                              </button>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <button type="submit"   disabled={!flag} className="modalButton">
                    {flag ? "      Add Test" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddTest;
