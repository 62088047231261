import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/getEmployeeProgramsAction";
import NetworkService from "../../../network/NetworkService";

export function* getEmployeeProgram(action) {
  try {
    let response = yield call(NetworkService.getEmployeeProgram, action);
    yield put(actions.getEmployeeProgramSuccess(response));
  } catch (err) {
    yield put(actions.getEmployeeProgramFailure(err));
  }
}

export function* watchGetEmployeeProgramSaga() {
  yield takeLatest(types.GET_EMPLOYEE_PROGRAM_REQUEST, getEmployeeProgram);
}

