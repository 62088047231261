import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/allCityAction"
import NetworkService from "../../../network/NetworkService";

export function* allCityAppointmentSaga(action) {
  try {
    //  
    let response = yield call(NetworkService.allCityAppointment, action);
    //
    yield put(actions.allCityAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.allCityAppointmentFailure(err));
  }
}

export function* watchallCityAppointmentSaga() {
  yield takeLatest(types.ALL_CITY_APPOINTMENT_REQUEST, allCityAppointmentSaga);
}