import { Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import "./ServicesNew.css";

function PharmacyServiceTemplate(prop) {
  const {
    servicesInfo,
    handleServicesAttributeData,
    templateServices,
    list,
    pharmacyService,
  } = prop;

  const [totalLimit, setTotalLimit] = useState(pharmacyService[0]?.topLimit);
  const [subLimit, setSubLimit] = useState(pharmacyService[0]?.subLimit);

  const [attributeCountDtoList1, setattributeCountDtoList1] = useState([]);
  const [servicesAttribute, setServicesAttribute] = useState([]);
  // const servicesAttribute=[];
  const [OCservices, setOCServices] = useState([]);
  const [ff, setFf] = useState([]);
  const servicesNames = [];
  const servicesInfoObject = [];
  const [serviceFinal, setServiceFinal] = useState([]);

  useEffect(() => {
    setTotalLimit(pharmacyService[0]?.topLimit);
    setSubLimit(pharmacyService[0]?.subLimit);
  }, []);

  useEffect(() => {
    setServicesAttribute([...servicesInfoObject]);
    setServiceFinal(list);

    setattributeCountDtoList1(templateServices.attributeCountDtoList);
  }, []);

  templateServices.attributeCountDtoList.map((item) => {
    servicesInfoObject.push(item);
  });

  servicesInfoObject.map((item) => {
    OCservices.push(item.attributeName);
  });

  useEffect(() => {
    let newFormValues = {
      attributeCount: pharmacyService[0].attributeCount,
      attributeUuid: pharmacyService[0].attributeUuid.toString(),
      attributeType: "INDIVIDUAL_TEST",
      exclusive: true,
      attributeName: pharmacyService[0].attributeName,
      subLimit: parseInt(subLimit),
      topLimit: parseInt(totalLimit),
    };

    if (attributeCountDtoList1.length === 0) {
      attributeCountDtoList1.push(newFormValues);
    } else {
      let boolT = attributeCountDtoList1.some((item) => {
        if (item.attributeUuid === newFormValues.attributeUuid) {
          return true;
        }
      });
      if (boolT === true) {
        attributeCountDtoList1.map((item, index) => {
          if (item.attributeName === newFormValues.attributeName) {
            attributeCountDtoList1.splice(index, 1);

            attributeCountDtoList1.push(newFormValues);
            setattributeCountDtoList1([...attributeCountDtoList1]);
          }
        });
      } else {
        attributeCountDtoList1.push(newFormValues);
        setattributeCountDtoList1([...attributeCountDtoList1]);
      }
    }

    let ffval = {
      ["serviceName"]: templateServices.serviceName,
      ["serviceUuid"]: templateServices.serviceUuid,
      ["attributeCountDtoList"]: attributeCountDtoList1,
    };

    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceName === ffval.serviceName) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }
    let z = ff[0];
    handleServicesAttributeData(z);
  }, [totalLimit, subLimit]);

  function handleCloseTemplate(uuid) {
    prop.handleCloseTemplate(uuid);
  }

  function handleTotalLimit(e) {
    setTotalLimit(e.target.value);
  }
  function handleSubLimit(e) {
    setSubLimit(e.target.value);
  }

  return (
    <div className="pharmacyService">
      <div className="pharmacyService1">Pharmacy</div>
      <div className="pharmacyService2">
        <div className="pharmacyService2Child1">
          <p>Total Limit</p>
          <input
            type="number"
            value={totalLimit}
            onChange={handleTotalLimit}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>
        <div className="pharmacyService2Child1">
          <p>Sub Limit</p>
          <input
            type="number"
            value={subLimit}
            onChange={handleSubLimit}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}
          />
        </div>
      </div>
      <div className="pharmacyService3">
        <button
          className="templateClose"
          onClick={() => {
            handleCloseTemplate(templateServices.serviceUuid);
          }}
        >
          <Close />
        </button>
      </div>
    </div>
  );
}
export default PharmacyServiceTemplate;
