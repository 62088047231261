import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/deleteProgramTemplateAction";
import NetworkService from "../../../../network/NetworkService";

export function* deleteProgramTemplate(action) {
  try {
    let response = yield call(NetworkService.deleteProgramTemplate, action);
    yield put(actions.deleteProgramTemplateSuccess(response));
  } catch (err) {
    yield put(actions.deleteProgramTemplateFailure(err));
  }
}

export function* watchProgramTemplateSaga() {
  yield takeLatest(types.DELETE_PROGRAM_TEMPLATE_REQUEST, deleteProgramTemplate);
}

