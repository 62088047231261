import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/createLotAction"
import NetworkService from "../../../network/NetworkService";

export function* createLotSaga(action) {
  try {
    let response = yield call(NetworkService.createLot, action);
    yield put(actions.createLotSuccess(response));
  } catch (err) {
    yield put(actions.createLotFailure(err));
  }
}

export function* watchCreateLotSaga() {
  yield takeLatest(types.CREATE_LOT_REQUEST, createLotSaga);
}