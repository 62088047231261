import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/getAllLotsIdsAction"
import NetworkService from "../../../network/NetworkService";

export function* getAllLotsIdsSaga(action) {
  try {
    let response = yield call(NetworkService.getAllLotsIds, action);
    yield put(actions.getAllLotsIdsSuccess(response));
  } catch (err) {
    yield put(actions.getAllLotsIdsFailure(err));
  }
}

export function* watchGetAllLotsIdsSaga() {
  yield takeLatest(types.GET_ALL_LOTS_IDS_REQUEST, getAllLotsIdsSaga);
}