import React, { useEffect, useState } from "react";
import "./CategoryListing.css";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import { Box } from "@mui/system";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import storage from "../../../Utils/storage";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DisableHospitalModal from "../../Organisms/Modal/DisableHospitalModal";
import { getAllBrandRequest } from "../../../redux/Actions/Product/getAllBrandAction";
import ModalAddBrand from "../../Organisms/Modal/ModalAddBrand";
import Swal from "sweetalert2";
import ModalUpdateBrand from "../../Organisms/Modal/ModalUpdateBrand";
import { getBrandDetailsRequest } from "../../../redux/Actions/Product/getBrandDetailsAction";
import { deleteBrandRequest } from "../../../redux/Actions/Product/deleteBrandAction";

function BrandListing() {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [delOpen, setdelOpen] = useState(false);
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [delId, setDelId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (city === null) {
    setCity("");
  }

  const handleDelete = (id) => {
    Swal.fire({
      text: "Do You want to disable this Brand ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, disable it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteBrandRequest(id));
      }
      else{
        Swal.fire("Changes not saved", "", "info").then(()=>{
          window.location.reload();
        });
      }
    });
  };

  useEffect(() => {
    dispatch(getAllBrandRequest(1, pageSize, searchText));

    setPage(1);
  }, [city]);

  const handleEdit = (value) => {
    dispatch(getBrandDetailsRequest(value?.id));
    setOpen(true);
    setEdit(true);
  };

  const handleClick = (id) => {
    navigate(`/viewBrandDetails/${id}`);
  };

  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(getAllBrandRequest(page, pageSize, newSearchText));
      setPage(1);
    } else {
      dispatch(getAllBrandRequest(searchPage, pageSize, newSearchText));
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (event, page) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(getAllBrandRequest(page, pageSize, searchText));

      setPage(page);
    } else {
      setSearchPage(page);
      dispatch(getAllBrandRequest(page, pageSize, searchText));
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(getAllBrandRequest(page, event.target.value, searchText));
    } else {
      dispatch(getAllBrandRequest(searchPage, event.target.value, searchText));
    }
    setPageSize(event.target.value);
  };

  const getAllBrand = useSelector((state) => state.getAllBrand);
  const getBrandDetails = useSelector((state) => state.getBrandDetails);

  const viewCity = useSelector((state) => state.viewAllCity);
  const deleteBrand = useSelector((state) => state.deleteBrand);
  if (deleteBrand?.data) {
    if (deleteBrand?.data.data.status === 200) {

      Swal.fire({
        title: "Disabled!",
        text: "Your brand has been disabled.",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
  }

  const [add, setAdd] = React.useState(false);

  const handleAdd = () => {
    setAdd(true);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "",
      headerClassName: "super-app-theme--header",
      headerName: "",
      flex: 3,
      alignItems: "Right",
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
    },

    {
      field: "Modify",
      headerClassName: "super-app-theme--header",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,

      renderCell: (cellValues) => {
        return (
          <div className="modify">
            
              <VisibilityIcon
                sx={{ color: "#52608E", marginRight: 2 }}
                onClick={() => handleClick(cellValues.row.id)}
              />
            
           
            &nbsp;&nbsp;
            <Box>
              {getAllBrand.data?.data?.data?.data?.responseDto[
                cellValues.api.getRowIndex(cellValues.row.id)
              ]?.status === false ? (
                <div>
                  <Switch
                    disabled
                    sx={{ cursor: "not-allowed", color: "#52608E" }}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <Switch
                    defaultChecked
                    sx={{ color: "#52608E" }}
                    onClick={() => handleDelete(cellValues.row.id)}
                  />{" "}
                </div>
              )}
            </Box>
            {getAllBrand.data?.data?.data?.data?.responseDto[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.status === false ? (
              <></>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="hospgrid">
        <h4 className="h4">Brands</h4>
        <div className="mainBox">
          {delOpen && (
            <DisableHospitalModal delOpen={setdelOpen} delId={delId} />
          )}
          {edit && getBrandDetails.data && (
            <ModalUpdateBrand value={getBrandDetails} open={setEdit} />
          )}
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Brands</h5>
              <div className="hospitalcount">
                {getAllBrand?.data?.data?.data?.data?.totalRecords} Brands
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="rightheadbtn2" >
              <button className="btn addModalbtn" style={{padding:"0.5em 1em 0.5em 1em"}} onClick={() => handleAdd()}>
                + Add Brand
              </button>
            </div>

            {add && <ModalAddBrand open={setAdd} />}

            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  className="searchField"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                    fontFamily: "Nunito",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {getAllBrand.data?.data.data.data?.responseDto && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    fontFamily: "Nunito",
                    fontWeight: 600,
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  fontFamily: "Nunito",
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                rowCount={getAllBrand?.data?.data?.data?.data?.totalRecords}
                pagination
                disableColumnMenu
                rows={getAllBrand?.data?.data?.data?.data?.responseDto}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={getAllBrand.data?.data?.data?.data?.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default BrandListing;
