import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/viewAllAppointmentWithoutLotAction"
import NetworkService from "../../../network/NetworkService";

export function* viewAllAppointmentsWithoutLotSuccess(action) {
  try {
    let response = yield call(NetworkService.viewAllAppointmentWithoutLot, action);
   
    yield put(actions.viewAllAppointmentsWithoutLotSuccess(response));
  } catch (err) {
    yield put(actions.viewAllAppointmentsWithoutLotSuccess(err));
  }
}

export function* watchViewAllAppointmentWithoutLotSaga() {
  yield takeLatest(types.VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_REQUEST, viewAllAppointmentsWithoutLotSuccess);
}