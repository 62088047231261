import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/deleteAppointment"
import NetworkService from "../../../network/NetworkService";

export function* deleteAppointmentSaga(action) {
  try {
    let response = yield call(NetworkService.deleteAppointment, action);
    yield put(actions.deleteAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.deleteAppointmentFailure(err));
  }
}

export function* watchDeleteAppointmentSaga() {
  yield takeLatest(types.DELETE_APPOINTMENTS_REQUEST, deleteAppointmentSaga);
}