import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/addProgramTemplateAction";
import NetworkService from "../../../../network/NetworkService";

export function* addProgramTemplateSaga(action) {
  try {
   
    let response = yield call(NetworkService.addProgram, action);
   
    yield put(actions.addProgramTemplateSuccess(response));
  } catch (err) {
    yield put(actions.addProgramTemplateFailure(err));
  }
}

export function* watchAddProgramTemplateSaga() {
  yield takeLatest(types.ADD_PROGRAM_TEMPLATE_REQUEST, addProgramTemplateSaga);
}
