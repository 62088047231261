import * as types from "../../Config/actionType"

export const consultationRequestsRequest = (id) => {
    return {
        type: types.CONSULTATION_REQUESTS_REQUEST,
        id
    }
}

export const consultationRequestsSuccess = (action) => {
    return {
        type: types.CONSULTATION_REQUESTS_SUCCESS,
        action
    }
}

export const consultationRequestsFailure = (action) => {
    return {
        type: types.CONSULTATION_REQUESTS_FAILURE,
        action
    }
}