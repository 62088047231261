import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/updateSubCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* updateSubCategorySaga(action) {
 
  try {
   
    let response = yield call(NetworkService.updateSubCategory, action);
   
    yield put(actions.updateSubCategorySuccess(response));
  } catch (err) {
   
    yield put(actions.updateSubCategoryFailure(err));
  }
}

export function* watchUpdateSubCategorySaga() {
 
  yield takeLatest(types.UPDATE_SUB_CATEGORY_REQUEST, updateSubCategorySaga);
}
