import * as types from "../../Config/actionType"

export const viewAllLabNamesRequest = (data) => {
    return {
        type: types.VIEW_ALL_LABS_NAMES_REQUEST,
        data:data
    }
}

export const viewAllLabNamesSuccess = (data) => {
    return {
        type: types.VIEW_ALL_LABS_NAMES_SUCCESS,
        data:data,
    }
}

export const viewAllLabNamesFailure = (data) => {
    return {
        type: types.VIEW_ALL_LABS_NAMES_FAILURE,
        data:data,
    }
}