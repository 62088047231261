import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InputAdornment, Pagination, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { getCategoryDetailsRequest } from "../../../redux/Actions/Product/getCategoryDetailsAction";
import { getAllSubCategoryRequest } from "../../../redux/Actions/Product/getAllSubCategoryAction";
import ModalAddSubCategory from "../../Organisms/Modal/ModalAddSubCategory";
import ModalUpdateSubCategory from "../../Organisms/Modal/ModalUpdateSubCategory";
import { getSubCategoryDetailsRequest } from "../../../redux/Actions/Product/getSubCategoryDetailsAction";
import { deleteSubCategoryRequest } from "../../../redux/Actions/Product/deleteSubCategoryAction";
import { useNavigate } from "react-router-dom";
import "./ProductDetails.css"

function ViewCategoryDetails() {
  const navigate = useNavigate();

  const [add, setAdd] = React.useState(false);
  const [doctorEdit, setDoctorEdit] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  const { id } = useParams();
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(getAllSubCategoryRequest(page, pageSize, id, newSearchText));
      setPage(1);
    } else {
      dispatch(
        getAllSubCategoryRequest(searchPage, pageSize, id, newSearchText)
      );
    }
    setSearchText(newSearchText);
  };
  const handleAdd = () => {
    setAdd(true);
  };

  const handleEdit = (value) => {
    dispatch(getSubCategoryDetailsRequest(value.id));
    setDoctorEdit(true);
  };

  const handlePageChange = (event, page1) => {
    dispatch(getAllSubCategoryRequest(page1, pageSize, id, searchText));

    setPage(page1);
  };

  const handleDropChange = (event) => {
    dispatch(
      getAllSubCategoryRequest(page, event.target.value, id, searchText)
    );

    setPageSize(event.target.value);
  };

  useEffect(() => {
    dispatch(getCategoryDetailsRequest(id));

    dispatch(getAllSubCategoryRequest(1, pageSize, id, searchText));
    setPage(1);
  }, []);

  const subCategoryDetails = useSelector(
    (state) => state.getSubCategoryDetails
  );

  useEffect(() => {
    dispatch(getAllSubCategoryRequest(1, pageSize, id));

    setPage(1);
  }, [pageSize]);
  const details = useSelector((state) => state.getCategoryDetails);
  const grid = useSelector((state) => state.getAllSubCategory);
  const deleteSubCategory = useSelector((state) => state.deleteSubCategory);

  if (deleteSubCategory?.data) {
    if (deleteSubCategory?.data.data.status === 200) {
      Swal.fire("Deleted!", "Your file has been deleted.", "success");

      Swal.fire({
        title: "Deleted!",
        text: "Your file has been deleted.",
        icon: "success",
      }).then(() => {
        window.location.reload();
      });
    }
  }

  const handleClick = (id) => {
    navigate(`/viewSubCategoryDetails/${id}`);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSubCategoryRequest(id));
      } else {
        window.location.reload();
      }
    });
  };

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      sortable: false,
      flex: 1,
      fontFamily: "Nunito",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      fontFamily: "Nunito",
    },
    {
      field: "nutritional",
      headerClassName: "super-app-theme--header",
      headerName: "Nutritional value",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      fontFamily: "Nunito",
    },

    {
      field: "display",
      headerClassName: "super-app-theme--header",
      headerName: "Display",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      fontFamily: "Nunito",
    },

    {
      field: "Modify",
      sortable: false,
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
      fontFamily: "Nunito",
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">

            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 2 ,cursor:"pointer"}}
              onClick={() => handleClick(cellValues.row.id)}
            />


            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {grid.data?.data?.data?.data?.responseDto[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.status === false ? (
              <div>
                <Switch disabled sx={{ color: "#52608E" }} />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
            {grid.data?.data?.data?.data?.responseDto[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.status === false ? (
              <></>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      {doctorEdit && subCategoryDetails?.data && (
        <ModalUpdateSubCategory
          value={subCategoryDetails}
          open={setDoctorEdit}
        />
      )}
      <div className="hospital-main">
        <h3 className="headerViewName">{details.data?.data.data.name}</h3>
        <div className="hospital-box">
          <div className="counts">
            <div className="divCount">
              {details.data?.data.data.active === false ? (
                <div className="disableChip">Disabled</div>
              ) : (
                <div className="docCount">
                  {details.data?.data.data.data.name}
                </div>
              )}
            </div>
          </div>
          <div className="productDetails" >
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft'>Id</div>
              <div className='productDetailsRight'>{details?.data?.data?.data?.data.id}</div>
            </div>
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft productDetailsLeftDescription'>Description</div>
              <div className='productDetailsRight'><span style={{}} dangerouslySetInnerHTML={{ __html: details?.data?.data?.data?.data.description }} /></div>
            </div>
            <div className='productDetailsMain'>
              <div className='productDetailsLeft productDetailsLeftDescription'>Nutritional Value</div>
              <div className='productDetailsRight'><span style={{}} dangerouslySetInnerHTML={{ __html: details?.data?.data?.data?.data.nutritional }} /></div>
            </div>
            <div className='productDetailsMain' style={{ marginTop: "1em" }}>
              <div className='productDetailsLeft productDetailsLeftDescription'>Image</div>
              <div className='productDetailsRight'><img src={details?.data?.data.data.data.imageFilepath} style={{ width: "100px", height: "100px" }} /></div>
            </div>
          </div>

          <div className="body" >


            <div className="grid">




              <div className="searchAreaViewCategory" style={{ borderTop: "1px solid gray", paddingTop: "1em" }}>
                <div className="hospitalDoctorCategory">List of SubCategories</div>
                <div className="searchSectionCategory">
                  <div className="searchBar">
                    <TextField
                      type="text"
                      name="search"
                      className="searchField"
                      onChange={handleSearch}
                      variant="outlined"
                      sx={{
                        border: "1px solid #52608E",
                        borderRadius: "6px",
                        width: "100%",
                        fontFamily: "Nunito",
                      }}
                      placeholder="Search"
                      size="small"
                      hiddenLabel="true"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "#52608E" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                {details?.data?.data.data.data.status === false ? <></> : <div className="rightheadbtnCategory">
                  <button
                    onClick={() => handleAdd()}
                  >
                    + Add SubCategory
                  </button>
                </div>}

                {add && (
                  <ModalAddSubCategory
                    open={setAdd}
                    id={details.data?.data.data.data.id}
                  />
                )}
              </div>
              {grid?.data?.data?.data?.data?.responseDto ? (
                <div>
                  <div style={{ zIndex: "-1" }}>
                    <Box sx={{ height: 400, width: "100%", marginTop: "3%" }}>
                      {true && (
                        <DataGrid
                          sx={{
                            color: "#1D2334",
                            "& .super-app-theme--header": {
                              backgroundColor: "#52608E",
                              color: "#F2EFEA",
                              height: "100%",
                              "& > .MuiDataGrid-columnSeparator": {
                                visibility: "hidden",
                              },
                            },
                            ".MuiTablePagination-toolbar": {
                              alignItems: "baseline",
                              marginTop: "5%",
                            },
                            ".MuiTablePagination-toolbar": {
                              alignItems: "baseline",
                              marginTop: "5%",
                            },
                          }}
                          rows={grid?.data?.data?.data?.data?.responseDto}
                          columns={columns}
                          pageSize={pageSize}
                          disableSelectionOnClick
                        />
                      )}
                    </Box>

                    <div className="hospital-pagination">
                      <p>Rows Per Page:</p>
                      <select
                        className="hospital-dropDown"
                        defaultValue={10}
                        onChange={handleDropChange}
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <Pagination
                        count={grid.data?.data?.data?.data?.totalPages}
                        color="primary"
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewCategoryDetails;
