import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/getMedTypeAction"
import NetworkService from "../../../network/NetworkService";

export function* getMedTypeSaga(action) {
  try {
      //
    let response = yield call(NetworkService.getMedType, action);
    yield put(actions.getMedTypeSuccess(response));
  } catch (err) {
    yield put(actions.getMedTypeFailure(err));
  }
}

export function* watchGetMedTypeSaga() {
  yield takeLatest(types.GET_MED_TYPE_REQUEST, getMedTypeSaga);
}