import * as types from "../../Config/actionType"

export const viewPackagesRequest = (data) => {
   
    return {
        type: types.VIEW_PACKAGES_REQUEST,
        data:data
        
    }
}

export const viewPackagesSuccess = (action) => {
    return {
        type: types.VIEW_PACKAGES_SUCCESS,
        data:action,
    }
}

export const viewPackagesFailure = (action) => {
    return {
        type: types.VIEW_PACKAGES_FAILURE,
        data:action,
    }
}