import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Package/viewAllPackageNamesAction";
import NetworkService from "../../../../network/NetworkService";

export function* viewAllPackageNamesSaga(action) {
  try {
    //
    let response = yield call(NetworkService.viewAllPackageNames, action);
    yield put(actions.viewAllPackageNamesSuccess(response));
  } catch (err) {
    yield put(actions.viewAllPackageNamesFailure(err));
  }
}



export function* watchViewAllPackageNamesSaga() {
  yield takeLatest(types.VIEW_ALL_PACKAGE_NAMES_REQUEST, viewAllPackageNamesSaga);
}

