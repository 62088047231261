import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./ViewCorporateProgram.css";
import { viewProgramRequest } from "../../../redux/Actions/Program/viewProgramAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalAddEmployeeFile from "../../Organisms/Modal/ModalAddEmployeeFile";
import ModalAssignEmployeeProgram from "../../Organisms/Modal/ModalAssignEmployeeProgram";

function ViewCorporateProgram() {
  const [addFile, setAddFile] = React.useState(false);
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [add, setAdd] = React.useState(false);

  const handleAdd = () => {
    setAdd(true);
  };
  //

  useEffect(() => {
    dispatch(viewProgramRequest(uuid));
  }, []);

  const details = useSelector((state) => state.viewProgram);

 

  const handleAddFile = () => {
    setAddFile(true);
  };
  return (
    <div>
      <div className="mainHeadingCorporate">View Program</div>
      <div className="boxTopCorporate">
        <div className="boxTopNameCorporate">
          {details.data?.data.data.data.name}
        </div>
        <div className="boxTopBalanceBoxCorporate">
          Balance - {details.data?.data.data.data.balance}
        </div>

        {/* <div className="editCorporate">
            <EditIcon fontSize="medium"  />
            <a style={{color:"#1D2334"}}>Edit</a>
          </div> */}
      </div>
      {/* <hr style={{width:"80%",m:"auto auto"}}/> */}
      <div className="boxBottomCorporate">
        <div className="boxBottomNameCorporate">Breif Description</div>
        <div className="boxBottomDescCorporate">
          <p>{details.data?.data.data.data.description}</p>
        </div>
        {details.data?.data.data.data.relationsAllowed === null ? (
          ""
        ) : (
          <div className="boxBottomName">
            Relation-{details.data?.data.data.data.relationsAllowed}
          </div>
        )}

        {details.data?.data.data.data.childrenCount === 0 ? (
          ""
        ) : (
          <div className="boxBottomName">
            Number of Children-{details.data?.data.data.data.childrenCount}
          </div>
        )}
        <div className="mainTemplateCardCorporate">
          {details.data?.data.data.data.services.map((item) => (
            <div className="templateCardCorporate">
              <div className="serviceNameCorporate">{item.serviceName}</div>
              <div className="serviceDetailsCorporate">
                {item.attributeCountDtoList.map((x) => (
                  <div className="attributeCorporate">
                    <p>{x.attributeName}</p>
                    <p>{x.attributeCount}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="boxBottomButtonsCorporate">
        <button onClick={handleAddFile} className="boxBottomButtonCorporates">
          Upload List of Employee
        </button>
        {addFile && <ModalAddEmployeeFile open={setAddFile} />}

        <button onClick={handleAdd} className="boxBottomButtonCorporates">
          Enter Manually
        </button>
        {add && (
          <ModalAssignEmployeeProgram
            open={setAdd}
            uuid={details.data?.data.data.data.programUuid}
          />
        )}
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewCorporateProgram;
