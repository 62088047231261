import * as types from "../../Config/actionType";

export const getAllLotsActionRequest = (
  page,
  pageSize,
  lotType
) => {
  return {
    type: types.GET_ALL_LOTS_REQUEST,
    page,
    pageSize,
    lotType
  };
};

export const getAllLotsActionSuccess = (data) => {
  return {
    type: types.GET_ALL_LOTS_SUCCESS,
    data: data,
  };
};

export const getAllLotsActionFailure = (data) => {
  return {
    type: types.GET_ALL_LOTS_FAILURE,
    data: data,
  };
};
