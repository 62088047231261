import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import "./ServicesNew.css";

function PharmacyService(prop) {
  const [servicesAttribute, setServicesAttribute] = useState([]);
  const servicesNames = [];
  const servicesInfoObject = [];
  const [totalLimit, setTotalLimit] = useState();
  const [subLimit, setSubLimit] = useState();

  function handleTotalLimit(e) {
    setTotalLimit(e.target.value);
  }
  function handleSubLimit(e) {
    setSubLimit(e.target.value);
  }

  useEffect(() => {
    if (
      parseInt(totalLimit) < parseInt(subLimit) &&
      parseInt(totalLimit) >= 0
    ) {
      prop.TopSubLimiterror(true);
    }
    if (parseInt(totalLimit) > parseInt(subLimit)) {
      prop.TopSubLimiterror(false);
    }
    if (parseInt(totalLimit) === parseInt(subLimit)) {
      prop.TopSubLimiterror(false);
      prop.EmptyPharmacyTopSubLimiterror(false);
    }
    if (parseInt(totalLimit) <= 0 || parseInt(subLimit) <= 0) {
      prop.ZeroTopSubLimiterror(true);
    }
    if (parseInt(totalLimit) > 0 && parseInt(subLimit) > 0) {
      prop.ZeroTopSubLimiterror(false);
    }
    if (totalLimit === "" || subLimit === "") {
      prop.EmptyTopSubLimiterror(true);
      prop.EmptyPharmacyTopSubLimiterror(false);
      prop.ZeroTopSubLimiterror(false);
      prop.TopSubLimiterror(false);
    }
    if (totalLimit === undefined || subLimit === undefined) {
      prop.EmptyPharmacyTopSubLimiterror(true);
      prop.EmptyTopSubLimiterror(true);
    }
    if (
      (totalLimit !== "" && subLimit === undefined) ||
      (totalLimit === undefined && subLimit !== "")
    ) {
      prop.EmptyTopSubLimiterror(true);
    }
    if (totalLimit !== "" && subLimit !== "") {
      prop.EmptyTopSubLimiterror(false);
    }
  }, [totalLimit, subLimit]);

  const { handleClose, servicesInfo, handleServicesAttributeData } = prop;

  useEffect(() => {
    servicesAttribute.push(servicesInfoObject);
    setServicesAttribute([...servicesInfoObject]);
  }, []);

  servicesInfo.attributeResponseDtoList.map((item) => {
    servicesInfoObject.push(item);
    servicesNames.push(item.name);
  });

  useEffect(() => {
    let newFormValues = [
      {
        attributeUuid: servicesInfoObject[0].attributeId.toString(),
        attributeType: "INDIVIDUAL_TEST",
        attributeCount: servicesInfoObject[0].parameterCount,
        exclusive: true,
        attributeName: servicesInfoObject[0].attributeName,
        subLimit: parseInt(subLimit),
        topLimit: parseInt(totalLimit),
      },
    ];

    let ffval = {
      serviceName: servicesInfo.name,
      serviceUuid: servicesInfo.serviceUUID,
      attributeCountDtoList: newFormValues,
    };

    handleServicesAttributeData(ffval);
  }, [totalLimit, subLimit]);

  return (
    <div className="pharmacyService">
      <div className="pharmacyService1">Pharmacy</div>
      <div className="pharmacyService2">
        <div className="pharmacyService2Child1">
          <p>Total Limit</p>
          <input
            type="number"
            onChange={handleTotalLimit}
            value={totalLimit}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}

          />
        </div>
        <div className="pharmacyService2Child1">
          <p>Sub Limit</p>
          <input
            type="number"
            onChange={handleSubLimit}
            value={subLimit}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(event) => event.currentTarget.blur()}

          />
        </div>
      </div>
      <div className="pharmacyService3">
        <button
          className="templateClose"
          onClick={() => {
            handleClose(servicesInfo.serviceUUID);
          }}
        >
          <Close />
        </button>
      </div>
    </div>
  );
}
export default PharmacyService;
