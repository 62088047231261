import * as types from "../../../Config/actionType";

export const approveProfessionalServiceRequest = (id, status, location) => {
  return {
    type: types.APPROVE_PROFESSIONAL_SERVICES_REQUEST,
    id,
    status,

    location,
  };
};

export const approveProfessionalServiceSuccess = (action) => {
  return {
    type: types.APPROVE_PROFESSIONAL_SERVICES_SUCCESS,
    action,
  };
};

export const approveProfessionalServiceFailure = (action) => {
  return {
    type: types.APPROVE_PROFESSIONAL_SERVICES_FAILURE,
    action,
  };
};
