import * as types from "../../Config/actionType"

export const viewDropdownAccountRequest = () => {
    return {
        type: types.VIEW_DROPDOWN_ACCOUNT_REQUEST,
    }
}

export const viewDropdownAccountSuccess = (data) => {
    return {
        type: types.VIEW_DROPDOWN_ACCOUNT_SUCCESS,
        data:data,
    }
}

export const viewDropdownAccountFailure = (data) => {
    return {
        type: types.VIEW_DROPDOWN_ACCOUNT_FAILURE,
        data:data,
    }
}