import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css";
// import { BarChart } from '@mui/icons-material';

function GAD_BarChart({ phqResponseDto }) {

  const options = {
    labels: ["No To Low Risk","Mild Anxiety", "Moderate Anxiety",  "Severe Anxiety"],

    chart: {
    
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "85%",
        borderRadius: 7,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      position: "top",
      show: true,
      floating: false,
      labels: {
        show: true,
      },
    },
    yaxis: {
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 0,
      },
    },
    colors: ["#7FE9DE","#77D970", "#7027A0",  "#C82404"],
  };

  const series = [
    {
      labels: [
        "noToLowRisk",
        "mildAnxiety",
        "moderateAnxiety",
        "severeAnxiety",
      ],

      data: [
        phqResponseDto?.gadResponseDto?.noToLowRisk,
        phqResponseDto?.gadResponseDto?.mildAnxiety,
        phqResponseDto?.gadResponseDto?.moderateAnxiety,
        phqResponseDto?.gadResponseDto?.severeAnxiety,
      ],
    },
  ];

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      // dispatch(addDoctorRequest(values));
      setSubmitting(false);
    }, 400);
  };
  return (
    <>
      <Chart
        options={options}
        series={series}
        type="bar"
        height="auto"
        className="apex-charts"
        dir="ltr"
      />
    </>
  );
}
export default GAD_BarChart;
