import * as types from "../../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const getProgramTemplateReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.GET_PROGRAM_TEMPLATE_SUCCESS:
           
            return {
                ...initialState,
                data: action,
            }
        case types.GET_PROGRAM_TEMPLATE_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.GET_PROGRAM_TEMPLATE_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}