import * as types from "../../Config/actionType"

export const viewAllUsersDropdownRequest = () => {
    return {
        type: types.VIEW_ALL_USERS_DROPDOWN_REQUEST,
    }
}

export const viewAllUsersDropdownSuccess = (data) => {
    return {
        type: types.VIEW_ALL_USERS_DROPDOWN_SUCCESS,
        data:data,
    }
}

export const viewAllUsersDropdownFailure = (data) => {
    return {
        type: types.VIEW_ALL_USERS_DROPDOWN_FAILURE,
        data:data,
    }
}