import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import "./DiagnosticService.css";
import Close from "@mui/icons-material/Close";
import { Stack, Autocomplete, TextField } from "@mui/material";

function DiagnosticService(prop) {
  const servicesAttributeResponseDtoListArray = [];
  const testsAttributeResponseDtoListArray = [];
  const packsAttributeResponseDtoListArray = [];
  const packageNameArray = [];
  const testsNameArray = [];
  const [test, setTest] = useState([]);
  const [pack, setPack] = useState([]);
  const {
    handleClose,
    handleCloseAttribute,
    servicesInfo,
    handleServicesAttributeData,
  } = prop;
  const [testObjectArray, setTestObjectArray] = useState([]);
  const [packageObjectArray, setPackageObjectArray] = useState([]);
  const [ff, setFf] = useState([]);
  const [closAttributeFinal, setClosAttributeFinal] = useState([]);

  const [
    testsAttributeResponseDtoListArray1,
    settestsAttributeResponseDtoListArray1,
  ] = useState([]);

  const [
    packsAttributeResponseDtoListArray1,
    setpacksAttributeResponseDtoListArray1,
  ] = useState([]);

  useEffect(() => {
    setpacksAttributeResponseDtoListArray1([
      ...packsAttributeResponseDtoListArray,
    ]);
    settestsAttributeResponseDtoListArray1([
      ...testsAttributeResponseDtoListArray,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Package attribute work

  const viewPackageNames = useSelector(
    (state) => state.viewAllPackageNamesReducer
  );

  viewPackageNames?.data?.data?.data?.map((item) =>
    packsAttributeResponseDtoListArray.push(item)
  );
  packsAttributeResponseDtoListArray.map((item) =>
    packageNameArray.push(item.packageName)
  );

  const viewTestNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewTestNames?.data?.data?.data?.map((item) =>
    servicesAttributeResponseDtoListArray.push(item.attributeResponseDtoList)
  );

  let handleChangeTest = (e, attributeUuid, name, variable, desc) => {
    let testValues = {
      [e.target.name]: parseInt(e.target.value),
      ["attributeName"]: name,
      ["attributeUuid"]: attributeUuid,
      ["attributeType"]: "PACKAGE",
      ["exclusive"]: true,
      ["attributeDescription"]: desc,
    };
    if (testObjectArray.length === 0) {
      testObjectArray.push(testValues);
    } else {
      let boolT = testObjectArray.some((item) => {
        if (testValues.attributeUuid === item.attributeUuid) {
          return true;
        }
      });
      if (boolT === true) {
        testObjectArray.map((item, index) => {
          if (item.attributeUuid === testValues.attributeUuid) {
            testObjectArray.splice(index, 1);
            testObjectArray.push(testValues);
            setTestObjectArray([...testObjectArray]);
          }
        });
      } else {
        testObjectArray.push(testValues);
        setTestObjectArray([...testObjectArray]);
      }
    }
    let ffval = {
      ["serviceName"]: servicesInfo.name,
      ["serviceUuid"]: servicesInfo.serviceUUID,
      ["attributeCountDtoList"]: testObjectArray,
    };

    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceUuid === ffval.serviceUuid) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }
    let fullyFinal = {};
    ff.map((item) => {
      fullyFinal = item;
    });
    handleServicesAttributeData(fullyFinal);
  };

  const fil1 = [];

  return (
    <div className="parentDiagnostic">
      <div className="diagnostic">
        <div className="diagnostic_top">
          <div className="Diagnostic-heading">{servicesInfo.name}</div>
          <div className="inputlabel">
            <Stack spacing={2}>
              <Autocomplete
                options={packageNameArray}
                style={{ width: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="Package" />
                )}
                onChange={(event, value) => {
                  setPack([...pack, value]);
                }}
              />
            </Stack>
          </div>
          {/* 
      //  Diagnostic Test commented code can be use later
        
        <div className="inputlabel">
          <Stack spacing={2}>
            <Autocomplete
              options={testsNameArray}
              style={{ width: 150 }}
              renderInput={(params) => <TextField {...params} label="Test" />}
              onChange={(event, value) => {
                setTest([...test, value]);
              }}
            />
          </Stack>
        </div> */}
          <button
            className="diagnosticClose"
            onClick={() => handleClose(servicesInfo.serviceUUID)}
          >
            <Close />
          </button>
        </div>
        <div className="diagnostic_bottom">
          <div className="bottom_left">
            {/* <div className="parent_placeholder">  // can be use
              <div className="placeholder">Package :</div>
            </div> */}
            {packsAttributeResponseDtoListArray1.map((item, index) => {
              let desc = item.description === null ? "" : item.description;
              if (pack.includes(item.packageName)) {
                return (
                  <div className="package_attribute" key={item.packageUuid}>
                    <span key={item.packageUuid}>{item.packageName}</span>
                    <div className="rightside" key={item.packageUuid}>
                      <input
                        name="attributeCount"
                        key={item.packageUuid}
                        type="number"
                        min="0"
                        placeholder="count"
                        onWheel={(event) => event.currentTarget.blur()}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlur={(e) =>
                          handleChangeTest(
                            e,
                            item.packageUuid,
                            item.packageName,
                            "package",
                            desc
                          )
                        }
                      />
                      <div className="center">
                        <CancelIcon
                          onClick={() => {
                            handleCloseAttribute(servicesInfo.serviceUUID);
                            let fil = pack.filter(
                              (ele) => ele !== item.packageName
                            );

                            fil.map((item) => {
                              testObjectArray.map((ele) => {
                                if (item === ele.attributeName) {
                                  fil1.push(ele);
                                }
                              });
                            });

                            setPack([...fil]);

                            setPackageObjectArray([...fil1]);

                            let ffvalPackage = {
                              ["serviceName"]: servicesInfo.name,
                              ["serviceUuid"]: servicesInfo.serviceUUID,
                              ["attributeCountDtoList"]: fil1,
                            };

                            if (closAttributeFinal.length === 0) {
                              closAttributeFinal.push(ffvalPackage);
                            } else {
                              let boolT = closAttributeFinal.some((item) => {
                                if (
                                  item.serviceUuid === ffvalPackage.serviceUuid
                                ) {
                                  return true;
                                }
                              });
                              if (boolT === true) {
                                closAttributeFinal.map((item, index) => {
                                  if (
                                    item.serviceUuid ===
                                    ffvalPackage.serviceUuid
                                  ) {
                                    closAttributeFinal.splice(index, 1);

                                    closAttributeFinal.push(ffvalPackage);
                                    setClosAttributeFinal([
                                      ...closAttributeFinal,
                                    ]);
                                  }
                                });
                              } else {
                                closAttributeFinal.push(ffvalPackage);
                                setClosAttributeFinal([...closAttributeFinal]);
                              }
                            }
                            let newFinal = {};
                            closAttributeFinal.map((item) => {
                              newFinal = item;
                            });
                            handleServicesAttributeData(newFinal);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return <div></div>;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default DiagnosticService;
