import React, { useEffect, useState } from "react";
import "./ServicesNew.css";
import Close from "@mui/icons-material/Close";


function ServicesTemplate(prop) {
  const [attributeCountDtoList1, setattributeCountDtoList1] = useState([]);
  const [servicesAttribute, setServicesAttribute] = useState([]);
  const [OCservices, setOCServices] = useState([]);
  const [ff, setFf] = useState([]);
  const servicesNames = [];
  const servicesInfoObject = [];
  const [serviceFinal, setServiceFinal] = useState([]);
  const {
    servicesInfo,
    handleServicesAttributeData,
    templateServices,
    dropDown,
    list,
    handleClose,

  } = prop;
  useEffect(() => {
    setServicesAttribute([...servicesInfoObject]);
    setServiceFinal(list);

    setattributeCountDtoList1(templateServices.attributeCountDtoList);
  }, [templateServices]);

  templateServices.attributeCountDtoList.map((item) => {
    servicesInfoObject.push(item);
  });

  dropDown.map((item) => {
    servicesNames.push(item.attributeName);
  });

  servicesInfoObject.map((item) => {
    OCservices.push(item.attributeName);
  });

  let handleChange = (e, attributeName, x) => {
    let newFormValues = {
      [e.target.name]: parseInt(e.target.value),
      ["attributeUuid"]: x.toString(),
      ["attributeType"]: "INDIVIDUAL_TEST",
      ["exclusive"]: true,
      ["attributeName"]: attributeName,
    };

    if (attributeCountDtoList1.length === 0) {
      attributeCountDtoList1.push(newFormValues);
    } else {
      let boolT = attributeCountDtoList1.some((item) => {
        if (item.attributeUuid === newFormValues.attributeUuid) {
          return true;
        }
      });
      if (boolT === true) {
        attributeCountDtoList1.map((item, index) => {
          if (item.attributeName === newFormValues.attributeName) {
            attributeCountDtoList1.splice(index, 1);

            attributeCountDtoList1.push(newFormValues);
            setattributeCountDtoList1([...attributeCountDtoList1]);
          }
        });
      } else {
        attributeCountDtoList1.push(newFormValues);
        setattributeCountDtoList1([...attributeCountDtoList1]);
      }
    }

    let ffval = {
      ["serviceName"]: templateServices.serviceName,
      ["serviceUuid"]: templateServices.serviceUuid,
      ["attributeCountDtoList"]: attributeCountDtoList1,
    };
    if (ff.length === 0) {
      ff.push(ffval);
    } else {
      let boolT = ff.some((item) => {
        if (item.serviceUuid === ffval.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        ff.map((item, index) => {
          if (item.serviceName === ffval.serviceName) {
            ff.splice(index, 1);

            ff.push(ffval);
            setFf([...ff]);
          }
        });
      } else {
        ff.push(ffval);
        setFf([...ff]);
      }
    }
    handleServicesAttributeData(ff);
  };
  function handleCloseTemplate(uuid){
prop.handleCloseTemplate(uuid)

  }

  return (
    <div className="cashlessOpd">
      <div className="cashlessOpd-top">
        <div className="section1" value={servicesInfo.serviceUuid}>
          {servicesInfo.serviceName}
        </div>
        <div className="section2"></div>
        <div className="section3">
         
                <button
            className="templateClose" 
            onClick={() => {
              handleCloseTemplate(servicesInfo.serviceUuid
                );
            }}
          >
            <Close />
          </button>
        </div>
      </div>
      <div className="cashlessOpd-bottom">
        {servicesInfo.attributeCountDtoList.map((item, index) => {
          if (true) {
            return (
              <div className="services_attribute_new" key={item.attributeUuid}>
                <div className="leftside">{item.attributeName}</div>
                <div className="rightside" key={item.attributeUuid}>
                  <input
                    name="attributeCount"
                    key={item.attributeUuid}
                    // disabled
                    defaultValue={item.attributeCount}
                    min="0"
                    type="number"
                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    onWheel={(event) => event.currentTarget.blur()}
                    onBlur={(e) =>
                      handleChange(
                        e,
                        item.attributeName,
                        item.attributeUuid,
                        item.attributeCount
                      )
                    }
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
export default ServicesTemplate;