import React, { useEffect, useState } from "react";
import "./DisableModal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import uploadSign from "../../../assets/uploadSign.png";
import swal from "sweetalert";
import { deleteHospitalRequest } from "../../../redux/Actions/Hospitals/deleteHospitalAction";
import { CircularProgress } from "@mui/material";

import { Formik, Form, Field, ErrorMessage } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const loginSchema = Yup.object().shape({
  disableReason: Yup.string().required("Required Field"),
});
function DisableHospitalModal(props) {
  const [file, setFile] = useState();

  const dispatch = useDispatch();
  const handleClose = () => {
    window.location.reload();
    props.delOpen(false);
  };
  const deleteHospital = useSelector((state) => state.deleteHospital);
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(deleteHospital?.data || deleteHospital?.error)
    {

      setFlag(true);
    }

  },[deleteHospital])
  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();

    formData.append("approvalFile", file);

    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    setFlag(false)

    dispatch(
      deleteHospitalRequest(props.delId, values.disableReason, formData)
    );
  };
  if (deleteHospital?.data?.data?.status === true) {
    swal({
      title: deleteHospital?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
  };
  const handleFileChange = (event) => {
   
    setFile(event.target.files[0]);
  };

  const handleCheckFile = (e) => {
    const fileType = e.target.files[0];

    if (fileType.type.includes("pdf") === false) {
      document.getElementById("fileUploadInput").value = "";
      return alert("Please upload a pdf file");
    }
  };
  return (
    <div>
      <Modal
        open={props.delOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="DisableModalHeading"
            >
              Disable Hospital from Network
            </Typography>
            <div className="DisableModalSubHeading">Disable Reason</div>
          </div>

          <Formik
            initialValues={{
              disableReason: "",
              UploadApproval: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Form>
                <div className="Responsiveyu">
                  <label>
                    <Field
                      as="Textarea"
                      type="text"
                      name="disableReason"
                      placeholder="Reason To Disable"
                      className="disableReasontextarea"
                    />
                  </label>
                  <ErrorMessage
                    className="errorMessageModalValidationhosp"
                    name="disableReason"
                    component="div"
                  />
                  <div className="DisableModalSubHeading">Upload Approval</div>
                  <div className="DisableModalUpload">
                    <img
                      src={uploadSign}
                      alt="uploadSign"
                      className="uploadSignMain"
                    ></img>
                    <div>
                      <div className="fileUploadContainer">
                        <input
                          type="file"
                          name="UploadApproval"
                          id="fileUploadInput"
                          ondrop={handleDragOver}
                          ondragover={handleDrop}
                          accept="application/pdf"
                          onBlur={(event) => handleFileChange(event)}
                          onChange={(event) => handleCheckFile(event)}
                        />
                      </div>
                    </div>
                    {file?.size / 1024 > 10240 && (
                      <div className="errorDisableModalValidation1"> File Size should be less than 10mb</div>
                    )}
                    {file === null && (
                      <div className="selectFileMessage"> Select the file</div>
                    )}
                  </div>
                  <div className="disableModalSubmitButton">
                    <button disabled={!flag} type="submit" className="disableModalButton">
                    {flag ? "  Submit":
                    
                    <CircularProgress/>
                    }
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default DisableHospitalModal;
