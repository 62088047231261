import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/deleteHospitalAction";
import NetworkService from "../../../network/NetworkService";

export function* deleteHospitals(action) {
  try {
    let response = yield call(NetworkService.deleteHospital, action);
    yield put(actions.deleteHospitalSuccess(response));
  } catch (err) {
    yield put(actions.deleteHospitalFailure(err));
  }
}

export function* watchDeleteHospitals() {
  yield takeLatest(types.DELETE_HOSPITAL_REQUEST, deleteHospitals);
}

