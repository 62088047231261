import * as types from "../../Config/actionType";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const GetUserIndividualReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_USER_INDIVIDUAL_SUCCESS:
      return {
        ...initialState,
        data: action,
      };
    case types.GET_USER_INDIVIDUAL_FAILURE:
      return {
        ...initialState,
        error: action,
      };
    case types.GET_USER_INDIVIDUAL_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    default:
      return state;
  }
};
