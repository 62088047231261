import * as types from "../../Config/actionType"

export const CreateCardNumberRequest = (data) => {
   
    return {
        type: types.CREATE_CARD_NUMBER_REQUEST,
        data
            
    }
}

export const CreateCardNumberSuccess = (action) => {
   
    return {
        type: types.CREATE_CARD_NUMBER_SUCCESS,
        action
    }
}

export const CreateCardNumberFailure = (action) => {
   
    return {
        type: types.CREATE_CARD_NUMBER_FAILURE,
        action
    }
}