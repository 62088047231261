import {DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewDiscount.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import { EditDiscountProductRequest } from "../../../redux/Actions/Discount/EditDiscountProductAction";
import Loader from "../../../assets/loader.gif";


function EditDiscountProduct() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { enumType } = location.state;
    const { details } = location.state;
    const [discountPercentageValue, setDiscountPercentageValue] = useState();
    const [quantityInfo, setQuantityInfo] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [priceId, setPriceId] = useState();
    const [discountDescription, setDiscountDescription] = useState();

    var startDateConverted = new Date(startDate);
    startDateConverted = startDateConverted.getTime();

    var endDateConverted = new Date(endDate);
    endDateConverted = endDateConverted.getTime()

    var currentDate = new Date();
    currentDate = currentDate.getTime()


    const handleDiscountPercentage = (e) => {
        if (e.target.value <= 100) {

            setDiscountPercentageValue(e.target.value);
        }
    };

    useEffect(() => {
        if (quantityInfo) {
            setDiscountDescription(quantityInfo?.description);
            setPriceId(quantityInfo?.priceId);
            setEndDate(quantityInfo?.enddate);
            setStartDate(quantityInfo?.startDate);
            setDiscountPercentageValue(quantityInfo?.discountPercentage);
        }
    }, [quantityInfo])

   



    const handleSubmit = () => {
        let submitData = {
            priceId,
            enumType,
            startDateConverted,
            endDateConverted,
            discountPercentageValue,
            discountDescription,
        };
        if(submitData.discountDescription==="" || submitData.discountDescription===null){
            Swal.fire({
                title: "Please Fill Description",
                icon: "info"
            })
        }
        else if(submitData.discountPercentageValue==="" || submitData.discountPercentageValue===null){
            Swal.fire({
                title: "Please Fill Discount Percentage",
                icon: "info"
            })
        }
        else if(submitData.startDateConverted< currentDate){
            Swal.fire({
                title: "Please Select start date correctly",
                icon: "info"
            })
        }
        else if(submitData.endDateConverted<submitData.startDateConverted || submitData.endDateConverted< currentDate){
            Swal.fire({
                title: "Please Select End Date correctly",
                icon: "info"
            })
        }
        else{
            dispatch(EditDiscountProductRequest(submitData));
        }

       
    };

    const editDiscountProduct = useSelector(
        (state) => state.editDiscountProduct
    );
    if (
        editDiscountProduct?.data?.data.status === 200 &&
        editDiscountProduct?.data?.data?.data.message !== ""
    ) {
        Swal.fire({
            title: `${editDiscountProduct?.data?.data?.data.message}`,
            icon: "success",
        })
            .then(() => {
                editDiscountProduct.data.data.status = null;
                navigate(`/ViewDiscountProduct/${enumType}/${details.uuid}`);
            });
    }

    if (editDiscountProduct?.error) {
        Swal.fire({
            title: editDiscountProduct.error?.data?.response?.data?.errorMessage,
            icon: "error"
        }).then(() => {
            editDiscountProduct.error = null;
        })

    }


    useEffect(() => {
        if (quantityInfo) {
            setStartDate(new Date(quantityInfo.startDate));
            setEndDate(new Date(quantityInfo.endDate));
        }
    }, [quantityInfo])

    useEffect(() => {
        if (details) {
            details?.discountResponseDtoForProductPriceWiseList.map((item) => {
                if (item.priceId === parseInt(id)) {
                    setQuantityInfo(item);

                }
            })
        }
    }, [details])



    function handleDiscountDescription(e) {
        setDiscountDescription(e.target.value);
    }

    return (
        <div className="editDiscountProduct">
            <div className="appointmentmain">
                <h3 className="appId">Edit Discount</h3>
                <div className="discountProductArea">
                    <div className="discountProductTilesArea">
                        <div className="discountProductTiles"  >
                            <div className="discountProductTilesRows">
                                <div className="discountProductRowsLeft">Quantity</div>
                                <div className="discountProductRowsRight">{quantityInfo?.unit} {quantityInfo?.unitEnum} </div>
                            </div>
                            <div className="discountProductTilesRows">
                                <div className="discountProductRowsLeft">Description</div>
                                <div className="discountProductRowsRight"> <textarea type="text" value={discountDescription} onChange={handleDiscountDescription} className="discountPercentageInput" /></div>
                            </div>
                            <div className="discountProductTilesRows">
                                <div className="discountProductRowsLeft">Discount Percentage</div>
                                <div className="discountProductRowsRight"> <input type="number" value={discountPercentageValue} onKeyDown={(e) =>
                                    (e.key === "e" ||
                                        e.key === "E" ||
                                        e.key === "+" ||
                                        e.key === "-") &&
                                    e.preventDefault()
                                }
                                    onWheel={(event) => event.currentTarget.blur()} onChange={handleDiscountPercentage} className="discountPercentageInput" /></div>
                            </div>
                            <div className="discountProductTilesRows">
                                <div className="discountProductRowsLeft">Start Date</div>
                                <div className="discountProductRowsRight">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        <DateTimePicker
                                            minDate={new Date()}
                                            // inputFormat="dd/MM/yyyy"
                                            renderInput={(props) => (
                                                <TextField {...props} style={{ width: "100%" }} />
                                            )}
                                            label="Select Date and Time"
                                            value={startDate}
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className="discountProductTilesRows">
                                <div className="discountProductRowsLeft">End Date</div>
                                <div className="discountProductRowsRight">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DateTimePicker
                                            minDate={
                                                new Date(startDate)
                                            }
                                            // inputFormat="dd/MM/yyyy"
                                            renderInput={(props) => (
                                                <TextField {...props} style={{ width: "100%" }} />
                                            )}
                                            label="Select Date and Time"
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                        </div>
                    </div>
                    {editDiscountProduct?.isLoading===true?<button className="editDiscountProductButton">
                  <img alt="Loader" src={Loader} style={{width:"15%",height:"90%"}} /> 

                    </button>:<button  onClick={handleSubmit} className="editDiscountProductButton">Save</button>}
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default EditDiscountProduct;

