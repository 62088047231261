import { Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllLotsActionRequest } from "../../../redux/Actions/Payment/getAllLotsAction";

function UpdatePaymentPharmacyListing() {
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lotType = "PRESCRIPTION";
  useEffect(() => {
    dispatch(getAllLotsActionRequest(page, pageSize, lotType));
  }, [page, pageSize]);

  const getAllLots = useSelector((state) => state.getAllLots);
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleUpdateLot = (id) => {
    navigate(`/UpdatingPaymentStatus/${id}`);
  };

  const columns = [
    {
      field: "lotId",
      headerClassName: "super-app-theme--header",
      headerName: "LOT No.",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalPrescription",
      headerClassName: "super-app-theme--header",
      headerName: "Total Prescription",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paidPrescription",
      headerClassName: "super-app-theme--header",
      headerName: "Prescription Update",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lotCreationDate",
      headerClassName: "super-app-theme--header",
      headerName: "Date of LOT",
      flex: 1.4,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="modify">
            {new Date(params.row["lotCreationDate"]).toLocaleString()}
          </div>
        );
      },
    },
    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Amount",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "paidAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Amount Paid",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Status",
      headerClassName: "super-app-theme--header",
      headerName: "Update Status",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {cellValues.row["paid"] === false && (
              <button
                className="LotStatusUpdateButton"
                onClick={() => {
                  handleUpdateLot(cellValues.row["lotId"]);
                }}
              >
                Update
              </button>
            )}
            {cellValues.row["paid"] === true && (
              <div className="PaidUpdateStatus">PAID</div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="LotStatusPageWrapper">
      <div className="LotStatusHeader">
        <h1 className="LotStatusHeaderText">Payments</h1>
      </div>
      <div className="LotStatusTable">
        <div className="LotStatusTableRow1">
          <div className="LotStatusTableRow1Part1">
            <div className="LotStatusTableRow1Part1Header">List of Lots </div>
            <div className="LotStatusTableRow1Part1NumberCard">
              {getAllLots.data?.data.data.data.totalLot} Lots
            </div>
          </div>
        </div>

        <Box sx={{ height: 600, width: "100%" }}>
          {getAllLots.data?.data.data.data.lotResponseDtoList && (
            <DataGrid
              sx={{
                color: "#1D2334",
                "& .super-app-theme--header": {
                  backgroundColor: "#52608E",
                  color: "#F2EFEA",
                  fontFamily: "Nunito",
                  fontWeight: 600,
                  "& > .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                },
                fontFamily: "Nunito",
                "&:hover:not(.Mui-disabled)": {
                  cursor: "pointer",
                },
                ".MuiTablePagination-toolbar": {
                  alignItems: "baseline",
                  marginTop: "5%",
                },
              }}
              justify="center"
              rowCount={getAllLots.data?.data.data.data.totalLot}
              pagination
              disableColumnMenu
              rows={getAllLots.data?.data.data.data.lotResponseDtoList}
              columns={columns}
              pageSize={pageSize}
              getRowId={(row) => row.lotId}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                Footer: "none",
              }}
            />
          )}
        </Box>
        <div className="MainTablePagination">
          <p>Rows Per Page:</p>
          <select
            className="dropDown"
            defaultValue={10}
            onChange={handleDropChange}
          >
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <Pagination
            count={getAllLots.data?.data.data.data.totalPages}
            color="primary"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default UpdatePaymentPharmacyListing;
