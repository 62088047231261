import React, { useState } from "react";
import "./Career.css";
import Footer from "../Footer";
import Ellipse1 from "../../../../assets/Ellipse1.png";
import Ellipse2 from "../../../../assets/Ellipse2.png";
import downArrow from "../../../../assets/downArrowBlack.png";
import upArrow from "../../../../assets/upArrow.png";
function Career() {
  const [show, setShow] = useState(true);
  const [show1, setShow1] = useState(true);
  return (
    <div className="mainCareer">
      <div className="middleCareerBox">
        <div className="careerImage">
          <img className="circle" src={Ellipse2} alt="error"/>
          <img className="nurse" src={Ellipse1} alt="error"/>
        </div>
        <div className="careerBox">
          <div className="careerInfo">
            <p className="careerInfoChild1">Careers</p>
            <p className="careerInfoChild2">
              We at YUVA are a curious bunch. We believe in turning ideas into
              reality, breathing life into products that might break the
              healthcare industry (in a good way), and giving wings to wellness
              programs for small and large organizations alike with the help of
              technology to help them fly without fright. We are actively on the
              lookout for people who, like us, want to leverage the power of
              digital to create a better world for the future.
            </p>
          </div>
          <div className="serviceCard">
            {show ? (
              <div className="serviceCardChild1">
                <p>Associate Sales Executive</p>
                <img
                  onClick={() => {
                    setShow(!show);
                  }}
                  src={downArrow}
                  alt="error"
                ></img>
              </div>
            ) : (
              <div className="expand ">
                <div className="headingArea1">
                  <p>Associate Sales Executive</p>
                  <img
                    onClick={() => {
                      setShow(!show);
                    }}
                    src={upArrow}
                    alt="error"
                  ></img>
                </div>
                <div className="headingArea2">
                  <p>About YUVA</p>
                  <p>
                    YUVA is a path-breaking, next-gen health-tech organization
                    that offers an extensive consumer-driven healthcare savings
                    program for individuals, employers and their employees. By
                    using YUVA’s purpose-built, one-stop digital platform,
                    individuals and businesses can connect with a plethora of
                    healthcare service providers, from mental health experts to
                    major hospitals to professional service providers such as
                    Zumba instructors and Personal Trainers to avail their
                    services at a discounted rate.
                  </p>
                </div>
                <div className="headingArea3">
                  <p>Primary Responsibilities</p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                    </li>
                    <li>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </li>
                    <li>Ut enim ad minim veniam,</li>
                    <li>
                      Quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </li>
                    <li>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore.
                    </li>
                    <li>
                      Eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident,
                    </li>
                    <li>
                      Sunt in culpa qui officia deserunt mollit anim id est
                      laborum."
                    </li>
                  </ul>
                </div>
                <div className="headingArea4">
                  <p>Skill and Experience</p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                    </li>
                    <li>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </li>
                    <li>Ut enim ad minim veniam,</li>
                    <li>
                      Quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </li>
                    <li>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore.
                    </li>
                    <li>
                      Eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident,
                    </li>
                    <li>
                      Sunt in culpa qui officia deserunt mollit anim id est
                      laborum."
                    </li>
                  </ul>
                </div>
                <div className="headingArea5">
                  <p>
                    Interested? Please send us your resumes at
                    <a
                      href="mailto: careers@yuvahealth.in"
                      style={{ color: "#7180AD" }}
                    >
                      {" "}
                      careers@yuvahealth.in
                    </a>
                  </p>
                </div>
              </div>
            )}

            {show1 ? (
              <div className="serviceCardChild2">
                <p>Associate Sales Executive</p>
                <img
                  onClick={() => {
                    setShow1(!show1);
                  }}
                  src={downArrow}
                  alt="error"
                ></img>
              </div>
            ) : (
              <div className="expand ">
                <div className="headingArea1">
                  <p>Associate Sales Executive</p>
                  <img
                    onClick={() => {
                      setShow1(!show1);
                    }}
                    src={upArrow}
                    alt="error"
                  ></img>
                </div>
                <div className="headingArea2">
                  <p>About YUVA</p>
                  <p>
                    YUVA is a path-breaking, next-gen health-tech organization
                    that offers an extensive consumer-driven healthcare savings
                    program for individuals, employers and their employees. By
                    using YUVA’s purpose-built, one-stop digital platform,
                    individuals and businesses can connect with a plethora of
                    healthcare service providers, from mental health experts to
                    major hospitals to professional service providers such as
                    Zumba instructors and Personal Trainers to avail their
                    services at a discounted rate.
                  </p>
                </div>
                <div className="headingArea3">
                  <p>Primary Responsibilities</p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                    </li>
                    <li>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </li>
                    <li>Ut enim ad minim veniam,</li>
                    <li>
                      Quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </li>
                    <li>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore.
                    </li>
                    <li>
                      Eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident,
                    </li>
                    <li>
                      Sunt in culpa qui officia deserunt mollit anim id est
                      laborum."
                    </li>
                  </ul>
                </div>
                <div className="headingArea4">
                  <p>Skill and Experience</p>
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                    </li>
                    <li>
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </li>
                    <li>Ut enim ad minim veniam,</li>
                    <li>
                      Quis nostrud exercitation ullamco laboris nisi ut aliquip
                      ex ea commodo consequat.{" "}
                    </li>
                    <li>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore.
                    </li>
                    <li>
                      Eu fugiat nulla pariatur. Excepteur sint occaecat
                      cupidatat non proident,
                    </li>
                    <li>
                      Sunt in culpa qui officia deserunt mollit anim id est
                      laborum."
                    </li>
                  </ul>
                </div>
                <div className="headingArea5">
                  <p>
                    Interested? Please send us your resumes at
                    <a
                      href="mailto: careers@yuvahealth.in"
                      style={{ color: "#7180AD" }}
                    >
                      {" "}
                      careers@yuvahealth.in
                    </a>
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="Help">
            <p className="helpChild1">Can't find your role?</p>
            <p className="helpChild2">
              Write to us{" "}
              <a
                href="mailto: careers@yuvahealth.in"
                style={{ color: "#7180AD" }}
              >
                careers@yuvahealth.in
              </a>{" "}
              and we’ll try to find a role for you!
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Career;
