import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/downloadPrescriptionAction"
import NetworkService from "../../../network/NetworkService";

export function* downloadPrescriptionSaga(action) {
  try {
   
    let response = yield call(NetworkService.downloadPrescription, action);
    yield put(actions.downloadPrescriptionSuccess(response));
  } catch (err) {
    yield put(actions.downloadPrescriptionFailure(err));
  }
}

export function* watchDownloadPrescriptionSaga() {
  yield takeLatest(types.DOWNLOAD_PRESCRIPTION_REQUEST, downloadPrescriptionSaga);
}