import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ViewCoupon.css";
import { GetCouponDetailsRequest } from "../../../redux/Actions/Coupon/GetCouponDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { DeleteCouponRequest } from "../../../redux/Actions/Coupon/DeleteCouponAction";
import swal from "sweetalert";
import copy from "../../../assets/copy.png";
import pencil from "../../../assets/pencil.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import leftArrow from "../../../assets/leftArrow.png";
import { EditCouponRequest } from "../../../redux/Actions/Coupon/EditCouponAction";
import CircularProgress from "@mui/material/CircularProgress";

function ViewCoupon() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [endDate, setendDate] = useState("");
  const [show, setShow] = useState(false);
  const [enumArray, setEnumArray] = useState([]);

  useEffect(() => {
    dispatch(GetCouponDetailsRequest(id));
  }, [id]);
  const GetCouponDetails = useSelector((state) => state.GetCouponDetails);
  const onInputChange = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (
      GetCouponDetails?.data?.action?.status === 200 &&
      GetCouponDetails?.data?.action?.data?.data !== null
    ) {
      let a =
        GetCouponDetails?.data?.action?.data?.data.productTypeEnum?.split(",");
      setEnumArray(a);
    }
  }, [GetCouponDetails]);

  function handleEdit() {
    setShow(true);
  }

  var endDateConverted = new Date(endDate);
  endDateConverted = endDateConverted.getTime();
  function handleDelete() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteCouponRequest(id));
      }
    });
  }
  const deleteCoupon = useSelector((state) => state.DeleteCoupon);
  if (
    deleteCoupon?.data?.action?.data?.message !== "" &&
    deleteCoupon?.data?.action?.status === 200
  ) {
    swal(
      "Success",
      `${deleteCoupon?.data?.action?.data?.message}`,
      "success"
    ).then(() => {
      navigate("/coupon");
    });
  }


  const epochValue1 = GetCouponDetails?.data?.action?.data?.data.startDate; // example epoch value
  const date1 = new Date(epochValue1);
  const dateString1 = date1.toLocaleDateString(); // returns a string in the local date format (e.g. "3/31/2022" in the US)

  const epochValue2 = GetCouponDetails?.data?.action?.data?.data.endDate; // example epoch value
  const date2 = new Date(epochValue2);
  const dateString2 = date2.toLocaleDateString(); // returns a string in the local date format (e.g. "3/31/2022" in the US)

  function handleBack() {
    navigate("/coupon");
  }

  const updateRes = useSelector((state) => state.EditCoupon)

  const [flag, setFlag] = useState(true);

  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])
  function handleUpdate() {
    setFlag(false);
    let data = {
      id: id,
      endDate: endDateConverted,
    };
    dispatch(EditCouponRequest(data));
  }

  const EditCoupon = useSelector((state) => state.EditCoupon);
  if (
    EditCoupon?.data?.data?.status === 200 &&
    EditCoupon?.data?.data?.data?.message !== ""
  ) {
    swal("Success", `${EditCoupon?.data?.data?.data?.message}`, "success").then(
      () => {
        window.location.reload();
      }
    );
  }

  return (
    <div className="ViewCouponMain">
      <div className="ViewCouponMainChild1">
        <img
          src={leftArrow}
          onClick={handleBack}
          style={{ cursor: "pointer" }}
        />
        <p>{GetCouponDetails?.data?.action?.data?.data.couponName}</p>
      </div>
      <div className="ViewCouponMainChild2">
        <div className="infoDiv">
          <div className="infoDivField">Coupon Name</div>
          <div className="infoDivValue">
            {GetCouponDetails?.data?.action?.data?.data.couponName}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Coupon Code</div>
          <div className="infoDivValue infoDivValueDiv couponCode1">
            <div className="couponCode2">
              <span className="copyText">
                {GetCouponDetails?.data?.action?.data?.data.couponCode}
              </span>
              {/* <img src={copy} className="copyIcon" onClick={handleCopy}/> */}
            </div>
          </div>
        </div>
        <div className="infoDiv">
          {GetCouponDetails?.data?.action?.data?.data.discountPercentage !==
          null ? (
            <>
              <div className="infoDivField">Discount %</div>
              <div className="infoDivValue">
                {GetCouponDetails?.data?.action?.data?.data.discountPercentage}{" "}
                %
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="infoDivField">Discount Amount</div>
              <div className="infoDivValue">
                {GetCouponDetails?.data?.action?.data?.data.discountAmount}
              </div>
            </>
          )}
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Min Amount</div>
          <div className="infoDivValue">
            {GetCouponDetails?.data?.action?.data?.data.cartValue}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Max Amount</div>
          <div className="infoDivValue">
            {GetCouponDetails?.data?.action?.data?.data.maxDiscount}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Service Category</div>
          <div className="infoDivValue infoDivValueDiv service_Div">
            {GetCouponDetails?.data?.action?.data?.data.package === true &&
              "Package"}
              {GetCouponDetails?.data?.action?.data?.data.product === true &&
              "Product"}
            {GetCouponDetails?.data?.action?.data?.data.package === true &&
              (GetCouponDetails?.data?.action?.data?.data.plan === true ||
                GetCouponDetails?.data?.action?.data?.data.test === true) &&
              ","}
            {GetCouponDetails?.data?.action?.data?.data.plan === true && " Plan"}
            {GetCouponDetails?.data?.action?.data?.data.plan === true &&
              GetCouponDetails?.data?.action?.data?.data.test === true &&
              ","}
            {GetCouponDetails?.data?.action?.data?.data.test === true && " Test"}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Usage Per Coupon</div>
          <div className="infoDivValue">
            {GetCouponDetails?.data?.action?.data?.data.couponCount}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Usage Per Customer</div>
          <div className="infoDivValue">
            {GetCouponDetails?.data?.action?.data?.data.usagePerUser}
          </div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">Start Date</div>
          <div className="infoDivValue">{dateString1}</div>
        </div>
        <div className="infoDiv">
          <div className="infoDivField">End Date</div>
          <div className="infoDivValue dateField">
            <div className="couponCode2">
              {dateString2}
              {/* {show? */}
              <img className="pencil" src={pencil} onClick={handleEdit} />
              {/* :""} */}
            </div>
            {show ? (
              <div className="enddate">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
              
                    minDate={
                      new Date(
                        GetCouponDetails?.data?.action?.data?.data.startDate
                      ).getTime() +
                      24 * 60 * 60 * 1000
                    }
                    maxDate={
                      new Date(
                        GetCouponDetails?.data?.action?.data?.data.startDate
                      ).getTime() +
                      365 * 24 * 60 * 60 * 1000
                    }
                    inputFormat="dd/MM/yyyy"
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "60%" }}inputProps={{ onKeyDown: onInputChange }} value={endDate ? endDate.toLocaleDateString() : ''}  />
                    )}
                    label="end date"
                    value={endDate}
                    onChange={(newValue) => {
                      setendDate(newValue);
                    }}
                   
                  />
                
                </LocalizationProvider>
                <button className="EditButton"  disabled={!flag}onClick={handleUpdate}>
              
                  {flag ? "       Confirm Edit":
                    
                    <CircularProgress/>
                    }
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="infoDiv lastInfoDiv">
          <div className="infoDivField">Description</div>
          <div className="infoDivValue ">
            {GetCouponDetails?.data?.action?.data?.data.description}
          </div>
        </div>
      </div>
      <div className="ViewCouponMainChild3">
        <button onClick={handleEdit}>Edit Coupon </button>
        <button onClick={handleDelete}>Delete Coupon </button>
      </div>
    </div>
  );
}

export default ViewCoupon;
