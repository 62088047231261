import * as types from "../../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const viewAllServicesAttributesReducer= (state = initialState, action = {}) => {
    switch (action.type) {
    
        case types.VIEW_ALL_SERVICES_ATTRIBUTES_SUCCESS:
            //
            return {
                ...initialState,
                data: action.action,
            }
        case types.VIEW_ALL_SERVICES_ATTRIBUTES_FAILURE:
            //
            return {
                ...initialState,
                error: action
            }
        case types.VIEW_ALL_SERVICES_ATTRIBUTES_REQUEST:
            //
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}