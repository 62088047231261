import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/deleteCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* deleteCategorySaga(action) {
 

  try {
    let response = yield call(NetworkService.deleteCategory, action);

    yield put(actions.deleteCategorySuccess(response));
  } catch (err) {
    yield put(actions.deleteCategoryFailure(err));
  }
}

export function* watchDeleteCategorySaga() {
  yield takeLatest(types.DELETE_CATEGORY_REQUEST, deleteCategorySaga);
}
