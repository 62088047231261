import * as types from "../../../Config/actionType";

export const networkClinicRequest = (
  page,
  pageSize,
  search,
  city,
  status
) => {
 
  return {
    type: types.NETWORK_CLINIC_REQUEST,
    page,
    pageSize,
    search,
    city,
    status,
  };
 
// 
};

export const networkClinicSuccess = (data) => {
  return {
    type: types.NETWORK_CLINIC_SUCCESS,
    data: data,
  };
};

export const networkClinicFailure = (data) => {
 

  return {
    type: types.NETWORK_CLINIC_FAILURE,
    data: data,
  };
};
