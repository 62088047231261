export const api = {
  viewAllDoctors: "/doctor/viewAll",
  viewAllHospitals: "/hospital/viewAll",
  login: "/login",
  addDoctor: "/doctor",
  addHospital: "/hospital",
  viewAllAgents: "/insurer/view-all",
  viewHospitalDetail: "/hospital",
  deleteHospital: "/hospital",
  viewAllCorporate: "/corporate/viewAll",
  viewAllUsers: "/user/view-all",
  viewAgentDetail: "/insurer",
  addInsuranceAgent: "/insurer",
  addInitiateRefund: "/order/refund",
  deleteCorporate: "/corporate",
  deleteAgent: "/insurer",
  deleteDoctor: "/doctor",
  viewCorporateDetail: "/corporate",
  addCorporate: "/corporate",
  updateCorporate: "/corporate",
  viewAllAppointments: "/appointment/viewAll",
  viewAccount: "/order/history",
  viewAllBooking: "/booking/viewAll",
  addAppointment: "/appointment",
  CreateTalkToDoctor:"/talkToDr",
  confirmAppointment: "/appointment/confirm",
  rescheduleAppointment: "/appointment/reschedule",
  cancelAppointment: "/appointment/cancel",
  updateInsuranceAgent: "/insurer",
  updateHospital: "/hospital",
  updateDoctor: "/doctor",
  loginInsuranceAgent: "/login/insurer",
  viewAllService: "/services/viewAll",
  viewAllServiceForCorporate: "/services/viewAllForCorporate",
  viewService: "/services",
  viewInPackages: "/package",
  viewAllHospitalNames: "/hospital/dropdown",
  viewAllProgram: "/program/viewAll",
  viewAllEmployee: "/employee/viewAll",
  viewProgram: "/program",
  getProgram: "/program",
  addService: "/services",
  getService: "/services",
  deleteProgram: "/program",
  addEmployee: "/employee",
  viewEmployeeDetail: "/employee",
  deleteEmployee: "/employee",
  addEmployeeFile: "employee/upload",
  viewAppointment: "/appointment",
  getProgramTemplate: "/program/template",
  endAppointment: "/appointment",
  addReport: "/upload",
  deleteReport: "/delete",
  deleteAppointment: "/appointment",
  validateOtp: "/appointment/completeAppointment",
  updateService: "/services/description",
  addServiceAttribute: "/services",
  viewAllPackageNames: "/package/view-all",
  addProgram: "/program/template",
  assignProgram: "/program",
  viewAllServicesAttributes: "/services",
  viewAllServicesNameAndUuid: "/services/viewAll",
  viewAllProgramTemplateNames: "/program/template/viewAll",
  viewAllTemplateData: "/program/template",
  viewAllCity: "/city/getAllCityNames",
  viewDropdownAccount: "/order/product/dropdown",
  viewAllUsersDropdown: "/user/type/dropdown",
  getUpdateHospital: "/hospital",
  getUpdateCorporate: "/corporate",
  getUpdateInsurer: "/insurer",
  checkEmailInsurer: "/check",
  checkEmailOrNumber:"/profile",
  getSearchDoctor: "/search/doctor",
  deleteService: "services/delete/service",
  assignProgramToEmployee: "/employee",
  getEmployeeProgram: "/employee/programs",
  validateOtpLogin: "/validate-otp-login",
  fileTemplate: "/employee/dummyFile",
  updateTest: "/test",
  viewAllTestsName: "/test/dropdown",
  makePackage: "/package",
  addLab: "/lab",
  addTest: "/test",
  viewAllLab: "/lab/viewAll",
  viewLab: "/lab",
  deleteLab: "/lab",
  viewPackages: "/package/view-all",
  viewAllTest: "/test/view-all",
  viewBooking: "/booking",
  updateBooking: "/booking",
  viewTest: "/test",
  uploadReport: "/upload",
  finishBooking: "/booking/finishedOrCompleted",
  completeBooking: "/booking/finishedOrCompleted",
  updatePackage: "/package",
  updatePackageFeature: "/package/featured",
  downloadAttachment: "/download",
  finishAppointment: "/appointment/finished",
  listOfConsultations: "/talkToDr/viewAll",
  consultationRequests: "/talkToDr",
  corporateDashboard: "/corporate",
  submitPrescription: "/prescription",
  AcceptOrRejectRequest: "/talkToDr",
  viewAllLabsNamesRequest: "/lab/dropdown",
  AssignLab: "/booking/assignLab",
  deleteAttachment: "/delete",
  chatStatus: "/talkToDr/chatStatus",
  viewAllPayment: "/payment/appointments",
  viewAllAppointmentWithoutLot: "/payment/lot/appointments",
  createLot: "/payment/addLot",
  getAllLots: "/payment/lots",
  getLotById: "/payment/lot/dropdown",
  updatePayment: "/payment/updatePayment",
  getRelation: "/relation",
  getAllLotsIds: "/payment/lot",
  getMyTestsAndPackage: "/employee/viewMyTestAndPackage",
  AddBooking: "/booking",
  networkHospital: "/network/hospital/viewAll",
  networkProfessionalSevices: "/network/professionalService/viewAll",
  networkViewHospital: "/network/hospital",
  networkViewProfessional: "/network/professionalService",
  approveHospital: "/hospital",
  approveProfessional: "/professional-service",
  networkDiagnostic: "/network/lab/viewAll",
  networkViewDiagnostic: "/network/diagnosticAndLabService",
  networkViewClinic: "/network/clinic",
  viewAllEmployeeProgram: "/corporate/programs",
  userDetail: "/profile",
  relationship: "/relation",
  programAndPlan: "/programAndPlan",
  approveDiagnostic: "/lab",
  viewAllProgramOfInsurer: "/insurer/program",
  assignProgramInsurer: "/insurer/program",
  addPlan: "/plan",
  getAllProgramOfCorporate: "/corporate/programs",
  getAllViewProgramOfCorporate: "/program",
  networkClinic: "/network/clinic/viewAll",
  deleteProgramTemplate: "/program/template",
  getInsurerAssignedPrograms: "/insurer/program",
  approveClinic: "/hospital/clinic",
  AssignProgramToInsurer: "/insurer/program",
  getProgramInsurer: "/insurer/program",
  addDiscountGroup: "/discount?categoryTypeEnum=",
  addDiscountIndividual: "/discount",
  editDiscount: "/discount",
  deleteDiscount: "/discount",
  getAllDiscount: "/discount/view-all",
  GetAllCoupon: "/coupon",
  DeleteCoupon: "/coupon",
  EditCoupon: "/coupon",
  GetCouponDetails: "/coupon",
  addCoupon: "/coupon",
  viewDiscount: "/discount",
  planTypeDropdown: "/plan/type/dropdown",
  viewOrder: "/order/details",
  viewOrderDropdown: "/order/dropdown",
  changeOrderStatus: "order/status",
  viewAllPlan: "/plan",
  deletePlan: "/plan",
  getPlanDetail: "/plan",
  getDosageType: "/prescription/dosageType",
  getMedType: "/prescription/medType",
  getDirection: "/prescription/direction",
  downloadPrescriptionPdf: "/prescription/download",
  getUserIndividual: "/user",
  getUserPlan: "/user",
  getUserProgram: "/user",
  AllHospitalNamesDropDown: "/hospital/filter/dropdown",
  DoctorDetailList: "/hospital/doctors",
  HospitalDashboardGet: "/hospital/dashboard",
  HospitalViewDoctorDetails: "/hospital/doctors",
  getAllPrescription:"/payment/prescription",
  addUser: "/user",
  getServiceDropdown: "/services/dropdown",
  getPackageDropdown: "/package/dropdown",
  getPlanDropdown: "/plan/dropdown",
  getCorporateDropdown: "/corporate/dropdown",
  getPlanDropdownForCard: "/plan/card/dropdown",
  createCardNumber:"/card",
  assignCardSubscription:"/card/assign",
  getAllCardsDropdown:"/card/dropdown",
  getAllCardsDownload:"/card/download",
  updatePlan:"/plan",
  addCategory:"/ecom/category",
  mapToElastic:"/search/map-to-elastic",
  mapToElasticDoctor:"/search/map-to-elastic-doctor",


};
