import React, { useContext, useEffect } from "react";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewService.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewServiceRequest } from "../../../redux/Actions/Service/viewServiceAction";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { updateServiceDescriptionRequest } from "../../../redux/Actions/Service/updateServiceDescriptionAction";
import { updateServiceTitleRequest } from "../../../redux/Actions/Service/updateServiceTitleAction";
import ModalAddNewAttributeService from "../../Organisms/Modal/ModalAddNewAttributeService";
import ModalUpdateService from "../../Organisms/Modal/ModalUpdateService";
import storage from "../../../Utils/storage";
import ModalAddTest from "../../Organisms/Modal/ModalAddTest";
import {roles} from "../../../helpers/MainConstants"
import { UserContext } from "../../../App";


function ViewServices() {
  const [add, setAdd] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [values, setValues] = React.useState();

  const handleAdd = (value) => {
    // setValues(value);
    setAdd(true);
  };

  const handleEditClick = (id) => {
    setValues(id);
    setEdit(true);
  };
  const role = useContext(UserContext);

  const columns = [
    {
      field: "attributeName",
      headerName: "Attribute name",
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "attributeDescription",
      headerName: "Attribute Description",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 630,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cost",
      headerName: "Atrribute Cost",
      type: "number",
      disableColumnMenu: true,
      sortable: false,
      miWidth: 145,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];
  const act = {
    field: "Actions",
    minWidth: 120,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderCell: (cellValues) => {
      return (
        <div className="modify">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <EditIcon
            style={{ cursor: "pointer", color: "#1D2334" }}
            onClick={() => handleEditClick(cellValues.row)}
          />
          {edit && (
            <ModalUpdateService
              value={values}
              serviceUuid={serviceUuid}
              serviceName={serviceName}
              serviceDescription={serviceDescription}
              open={setEdit}
            />
          )}
        </div>
      );
    },
  };
  if (role === roles.SUPER_ADMIN) {
    columns.push(act);
  }
 
  const dispatch = useDispatch();
  const { uuid } = useParams("uuid");
  const handleClick = () => {};

  const updateDescription = useSelector(
    (state) => state.updateServiceDescription
  );
  const updateTitle = useSelector((state) => state.updateServiceTitle);

  useEffect(() => {
    dispatch(viewServiceRequest(uuid));
  }, [uuid]);

  const details = useSelector((state) => state.viewService);

  let serviceUuid = details.data?.data.data.serviceUuid;
  let serviceName = details.data?.data.data.name;
  let serviceDescription = details.data?.data.data.description;

  const handleClickDesc = () => {
    Swal.fire({
      title: "Edit Description",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: (desc) => {
        dispatch(
          updateServiceDescriptionRequest(
            desc,
            details.data?.data.data.serviceUuid
          )
        );
      },
    }).then((result) => {
      if (updateDescription.data?.action.status === 200 && result.isConfirmed) {
        Swal.fire({
          icon: "success",
          text: "Edit Successfully",
        });
      }
      // window.location.reload();
      // else {
      //   Swal.fire({
      //     icon: "error",
      //     text: "Something went wrong!",
      //   });
      // }
    });
  };

  const handleClickTitle = () => {
    Swal.fire({
      title: "Edit Title",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: (title) => {
        dispatch(updateServiceTitleRequest(title, details.data?.data.data));
      },
    }).then((result) => {
      if (updateTitle.data?.action.status === 200 && result.isConfirmed) {
        Swal.fire({
          icon: "success",
          text: "Edit Successfully",
        });
      }
      window.location.reload();
    });
  };

  return (
    <div>
      <div className="service-main">
        <h3 className="h3">Services</h3>
        <div className="service-box">
          <div className="service-box-head">
            <div className="service-name">{details.data?.data.data.name}</div>
            {/* <div className="service-edit">
              {role === roles.SUPER_ADMIN && (
                <EditIcon
                  fontSize="medium"
                  onClick={() => handleClickTitle()}
                  sx={{ cursor: "pointer" }}
                />
              )} */}
            {/* {role === roles.SUPER_ADMIN && (
              <button
                onClick={() => handleClickTitle()}
                className="service-editBtn"
              >
                Edit
              </button>
              )}
            </div> */}
          </div>
          <hr />
          <div className="desc-head">
            <div className="service-description">Brief Description</div>
            <div className="service-edit">
              {role === roles.SUPER_ADMIN && (
                <EditIcon
                  fontSize="medium"
                  onClick={() => handleClickDesc()}
                  sx={{ cursor: "pointer" }}
                />
              )}
              {role === roles.SUPER_ADMIN && (
                <button
                  onClick={() => handleClickDesc()}
                  className="service-editBtn"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <div className="service-box-body">
            {details.data?.data.data.description}
          </div>
          <div className="service-grid">
            <div className="service-grid-head">
              <div className="atrribute">Attributes</div>
              {role === roles.SUPER_ADMIN && (
                <button className="addAtributeBtn" onClick={() => handleAdd()}>
                  + Add Attribute
                </button>
              )}
              {serviceUuid !== "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" &&
                add && (
                  <ModalAddNewAttributeService
                    open={setAdd}
                    serviceUuid={serviceUuid}
                    serviceName={serviceName}
                    serviceDescription={serviceDescription}
                  />
                )}
              {serviceUuid === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9" &&
                add && <ModalAddTest open={setAdd} />}
            </div>
            <Box sx={{ height: 400, width: "100%", marginTop: "2.18em" }}>
              {details.data?.data.data.attributeResponseDtoList && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  rows={details.data?.data.data.attributeResponseDtoList}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  getRowId={(row) => row.attributeId}
                  disableSelectionOnClick
                  components={{
                    Footer: "none",
                  }}
                />
              )}
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewServices;
