import * as types from "../../Config/actionType"

export const allCityAppointmentRequest = (data) => {
    return {
        type: types.ALL_CITY_APPOINTMENT_REQUEST,
        data:data
    }
}

export const allCityAppointmentSuccess = (data) => {
    return {
        type: types.ALL_CITY_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const allCityAppointmentFailure = (data) => {
    return {
        type: types.ALL_CITY_APPOINTMENT_FAILURE,
        data:data,
    }
}