import * as types from "../../Config/actionType"

export const deleteAgentRequest = (id,data,file) => {
    return {
        type: types.DELETE_AGENT_REQUEST,
        id:id,
        data:data,
        file:file
    }
}

export const deleteAgentSuccess = (action) => {
    return {
        type: types.DELETE_AGENT_SUCCESS,
        action
    }
}

export const deleteAgentFailure = (action) => {
    return {
        type: types.DELETE_AGENT_FAILURE,
        action
    }
}