import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getRelatedProductAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getRelatedProductSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getRelatedProduct, action);
   
    yield put(actions.getRelatedProductSuccess(response));
  } catch (err) {
   
    yield put(actions.getRelatedProductFailure(err));
  }
}

export function* watchGetRelatedProductSaga() {
 
  yield takeLatest(types.GET_RELATED_PRODUCT_REQUEST, getRelatedProductSaga);
}
