import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";
import swal from "sweetalert";
import { confirmAppointmentRequest } from "../../../redux/Actions/Appointments/confirmAppointmentAction";
import "./UpdateNumber.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { UpdateNumberStyle } from "./ModalConstants";

function UpdateNumber(props) {
  const dispatch = useDispatch();
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };

  const loginSchema = Yup.object().shape({
    patientContactNumber: Yup.string()
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .required("Required Field"),
  });
  const confirmSelector = useSelector((state) => state.confirmAppointment);

  const handleSubmit = (values, { setSubmitting }) => {
    const val = {
      id: props.id,
      patientContactNumber: values.patientContactNumber,
    };

    dispatch(confirmAppointmentRequest(val));

    const formData = new FormData();

    formData.append("approvalFile", values.UploadApproval);

    setTimeout(() => {
      setSubmitting(false);
    }, 400);
  };
  if (confirmSelector?.data || confirmSelector?.error) {
    if (confirmSelector?.data?.data?.status === 200) {
      swal({
        text: confirmSelector?.data?.data?.data?.message,
        icon: "success",
        // showCancelButton: true,
        confirmButtonColor: "#3085D6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        // className:"swalZindex"
      }).then((result) => {
        window.location.reload();
        // Swal.fire(deleteAgent?.data?.data?.message, "success");
      });
    } else {
      Swal.fire({
        text: confirmSelector?.error?.data?.response?.data?.errorMessage,
        icon: "error",
        // showCancelButton: true,
        confirmButtonColor: "#3085D6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        // className:"swalZindex"
      });
    }
  }

  return (
    <div>
      <Modal
        open={props.delOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={UpdateNumberStyle}>
          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="DisableModalHeading"
            >
              Patient Contact Number
            </Typography>
          </div>

          <Formik
            initialValues={{
              patientContactNumber: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Form>
                <div>
                  <label>
                    <Field
                      // as="Textarea"
                      type="number"
                      name="patientContactNumber"
                      placeholder="Patient Contact Number"
                      className="updateNumbertextarea"
                    />
                    <ErrorMessage
                      className="numberErrorMessageModalValidation"
                      name="patientContactNumber"
                      component="div"
                    />
                  </label>

                  <div className="disableModalSubmitButton">
                    <button type="submit" className="disableModalButton">
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default UpdateNumber;
