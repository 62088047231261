export const GET_LIFE_STYLE_REQUEST = "GET_LIFE_STYLE_REQUEST"
export const GET_LIFE_STYLE_SUCCESS = "GET_LIFE_STYLE_SUCCESS"
export const GET_LIFE_STYLE_FAILURE = "GET_LIFE_STYLE_FAILURE"


export const VIEW_LIFE_STYLE_REQUEST = "VIEW_LIFE_STYLE_REQUEST"
export const VIEW_LIFE_STYLE_SUCCESS = "VIEW_LIFE_STYLE_SUCCESS"
export const VIEW_LIFE_STYLE_FAILURE = "VIEW_LIFE_STYLE_FAILURE"

export const UPDATE_LIFESTYLE_REQUEST = "UPDATE_LIFESTYLE_REQUEST"
export const UPDATE_LIFESTYLE_SUCCESS = "UPDATE_LIFESTYLE_SUCCESS"
export const UPDATE_LIFESTYLE_FAILURE = "UPDATE_LIFESTYLE_FAILURE"

export const DELETE_LIFESTYLE_REQUEST = "DELETE_LIFESTYLE_REQUEST"
export const DELETE_LIFESTYLE_SUCCESS = "DELETE_LIFESTYLE_SUCCESS"
export const DELETE_LIFESTYLE_FAILURE = "DELETE_LIFESTYLE_FAILURE"

export const ADD_LIFESTYLE_REQUEST = "ADD_LIFESTYLE_REQUEST"
export const ADD_LIFESTYLE_SUCCESS = "ADD_LIFESTYLE_SUCCESS"
export const ADD_LIFESTYLE_FAILURE = "ADD_LIFESTYLE_FAILURE"
