import * as types from "../../Config/Banner/BannerActionType"

export const getScreenTypesRequest = (data) => {
    return {
        type: types.GET_SCREEN_TYPES_REQUEST,
        data:data
    }
}

export const getScreenTypesSuccess = (data) => {
    return {
        type: types.GET_SCREEN_TYPES_SUCCESS,
        data:data
    }
}

export const getScreenTypesFailure = (data) => {
    return {
        type: types.GET_SCREEN_TYPES_FAILURE,
        data:data
    }
}