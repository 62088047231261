import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/mapToElastic";
import NetworkService from "../../../network/NetworkService";

export function* mapToElasticSaga(action) {
  try {
   
    let response = yield call(NetworkService.mapToElastic, action);
   
    yield put(actions.mapToElasticSuccess(response));
  } catch (err) {
    yield put(actions.mapToElasticFailure(err));
  }
}

export function* watchMapToElasticSaga() {
  yield takeLatest(types.MAP_TO_ELASTIC_REQUEST, mapToElasticSaga);
}
