import * as types from "../../Config/actionType";

export const getUpdateInsurerRequest = (data) => {
  return {
    type: types.GET_UPDATE_INSURER_REQUEST,
    data
  };
};

export const getUpdateInsurerSuccess = (data) => {
  return {
    type: types.GET_UPDATE_INSURER_SUCCESS,
    data: data,
  };
};

export const getUpdateInsurerFailure = (data) => {
  return {
    type: types.GET_UPDATE_INSURER_FAILURE,
    data: data,
  };
};
