import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/ViewDiscountAction";
import NetworkService from "../../../network/NetworkService";

export function* ViewDiscountSaga(action) {
  try {
    let response = yield call(NetworkService.viewDiscount, action);
    yield put(actions.ViewDiscountSuccess(response));
  } catch (err) {
    yield put(actions.ViewDiscountFailure(err));
  }
}

export function* watchViewDiscountSaga() {
  yield takeLatest(types.VIEW_DISCOUNT_REQUEST, ViewDiscountSaga);
}
