import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/submitPrescriptionAction";
import NetworkService from "../../../network/NetworkService";

export function* submitPrescription(action) {
  try {
    let response = yield call(NetworkService.submitPrescription, action);

    yield put(actions.submitPrescriptionSuccess(response));
  } catch (err) {
    yield put(actions.submitPrescriptionFailure(err));
  }
}

export function* watchSubmitPrescription() {
  yield takeLatest(types.SUBMIT_PRESCRIPTION_REQUEST, submitPrescription);
}
