import * as types from "../../Config/Product/ProductActionType";

export const getProductRequest = (data) => {
  return {
    type: types.GET_PRODUCT_REQUEST,
    data:data
  };
};

export const getProductSuccess = (data) => {
  return {
    type: types.GET_PRODUCT_SUCCESS,
    data: data,
  };
};

export const getProductFailure = (data) => {
  return {
    type: types.GET_PRODUCT_FAILURE,
    data: data,
  };
};
