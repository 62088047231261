import * as types from "../../Config/actionType";

export const GetUserIndividualRequest = (data) => {
  return {
    type: types.GET_USER_INDIVIDUAL_REQUEST,
    data: data,
  };
};

export const GetUserIndividualSuccess = (data) => {
  return {
    type: types.GET_USER_INDIVIDUAL_SUCCESS,
    data: data,
  };
};

export const GetUserIndividualFailure = (data) => {
  return {
    type: types.GET_USER_INDIVIDUAL_FAILURE,
    data: data,
  };
};
