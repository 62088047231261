import { Box, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import { viewAllProgramRequest } from "../../../redux/Actions/Program/viewAllProgramRequest";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewAssignedProgram.css";

function ViewAssignedProgram() {
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();

  const idd = storage.getStorage("id");

  useEffect(() => {
    dispatch(viewAllProgramRequest());
  }, [page, pageSize, idd]);

  const viewAllProgram = useSelector((state) => state.viewAllProgram);

  const columns = [
    {
      field: "corporateName",
      headerClassName: "super-app-theme--header",
      headerName: "Corporate Name",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.corporateName === null && (
              <div>Assigned to Insurer</div>
            )}
            {params.row.corporateName !== null && (
              <div>{params.row.corporateName}</div>
            )}
          </>
        );
      },
    },
    {
      field: "corporateAddress",
      headerClassName: "super-app-theme--header",
      headerName: "Corporate Address",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.corporateAddress === null && <div>N/A</div>}
            {params.row.corporateAddress !== null && (
              <div>{params.row.corporateAddress}</div>
            )}
          </>
        );
      },
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Program Name",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerClassName: "super-app-theme--header",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Start Date",
      flex: 1,

      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["startDate"]).toLocaleString().split(",")[0]}
            </Typography>
          </>
        );
      },
    },

    {
      field: "endDate",
      headerClassName: "super-app-theme--header",
      headerName: "End Date",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["endDate"]).toLocaleString().split(",")[0]}
            </Typography>
          </>
        );
      },
    },

    // COMPLETED
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row["paymentStatus"] === null && (
              <Typography>
                <Chip label="Not Initiated" color="error" />
              </Typography>
            )}
            {params.row["paymentStatus"] === "Active" && (
              <Typography>
                <Chip label="ACTIVE" color="success" />
              </Typography>
            )}
            {params.row["paymentStatus"] === "Inactive" && (
              <Typography>
                <Chip label="INACTIVE" color="error" />
              </Typography>
            )}
            {params.row["paymentStatus"] === "Cancelled" && (
              <Typography>
                <Chip label="CANCELLED" color="error" />
              </Typography>
            )}
            {params.row["paymentStatus"] === "Upcoming" && (
              <Typography>
                <Chip label="UPCOMING" color="primary" />
              </Typography>
            )}
          </>
        );
      },
      // renderCell: (params) => {
      //   return (
      //     <>
      //       {params.row["paymentStatus"] === null && (
      //         <Typography>
      //           <Chip label="null" color="error" />
      //         </Typography>
      //       )}
      //     </>
      //   );
      // },
    },
  ];

  return (
    <>
      <div className="grid">
        <h4 className="h4">Assigned Programs</h4>
        <div className="mainBox">
          <div className="head">
            <div className="appleftHead">
              <h5 className="list">Active Programs</h5>
              <div className="count">
                {viewAllProgram.data?.data.data.data.length} Programs
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="appleftHead1">
              <h5 className="list">Corporate Subscribed</h5>
            </div>
          </div>

          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewAllProgram.data?.data?.data?.data && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                getRowId={(row: any) =>
                  row.name + row.description + row.endDate
                }
                rows={viewAllProgram.data?.data?.data?.data}
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          {/* <div className="MainTablePagination">
    

          {/* <div className="pagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              //   count={viewAppointments.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
            />
          </div> */}
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default ViewAssignedProgram;
