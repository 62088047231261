import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Appointments/cancelAppointmentAction"
import NetworkService from "../../../network/NetworkService";

export function* cancelAppointmentSaga(action) {
  try {
    let response = yield call(NetworkService.cancelAppointment, action);
    yield put(actions.cancelAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.cancelAppointmentFailure(err));
  }
}

export function* watchCancelAppointmentSaga() {
  yield takeLatest(types.CANCEL_APPOINTMENT_REQUEST, cancelAppointmentSaga);
}