import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/deleteDoctorAction";
import NetworkService from "../../../network/NetworkService";

export function* deleteDoctor(action) {
  
  try {
    let response = yield call(NetworkService.deleteDoctor, action);
    yield put(actions.deleteDoctorSuccess(response));
  } catch (err) {
    yield put(actions.deleteDoctorFailure(err));
  }
}

export function* watchDeleteDoctor() {
  yield takeLatest(types.DELETE_DOCTOR_REQUEST, deleteDoctor);
}

