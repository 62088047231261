import * as types from "../../Config/actionType"

export const viewAllEmployeeProgramRequest = () => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_PROGRAM_REQUEST,
    }
}

export const viewAllEmployeeProgramSuccess = (action) => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_PROGRAM_SUCCESS,
        action
    }
}

export const viewAllEmployeeProgramFailure = (action) => {
    return {
        type: types.VIEW_ALL_EMPLOYEE_PROGRAM_FAILURE,
        action
    }
}