import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getCategoryDropdownAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getCategoryDropdownSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getCategoryDropdown, action);
   
    yield put(actions.getCategoryDropdownSuccess(response));
  } catch (err) {
   
    yield put(actions.getCategoryDropdownFailure(err));
  }
}

export function* watchGetCategoryDropdownSaga() {
 
  yield takeLatest(types.GET_CATEGORY_DROPDOWN_REQUEST, getCategoryDropdownSaga);
}
