import * as types from "../../Config/actionType"

export const getPlanDropdownRequest = () => {
    return {
        type: types.GET_PLAN_DROPDOWN_REQUEST,
    }
}

export const getPlanDropdownSuccess = (data) => {
    return {
        type: types.GET_PLAN_DROPDOWN_SUCCESS,
        data
    }
}

export const getPlanDropdownFailure = (data) => {
    return {
        type: types.GET_PLAN_DROPDOWN_FAILURE,
        data
    }
}