import * as types from "../../Config/actionType"

export const completeBookingRequest = (data) => {
    return {
        type: types.COMPLETE_BOOKING_REQUEST,
        data:data
    }
}

export const completeBookingSuccess = (data) => {
    return {
        type: types.COMPLETE_BOOKING_SUCCESS,
        data:data,
    }
}

export const completeBookingFailure = (data) => {
    return {
        type: types.COMPLETE_BOOKING_FAILURE,
        data:data,
    }
}