import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/ChatStatusAction"
import NetworkService from "../../../network/NetworkService";

export function* chatStatusSaga(action) {
  try {
      //
    let response = yield call(NetworkService.chatStatus, action);
    yield put(actions.chatStatusSuccess(response));
  } catch (err) {
    yield put(actions.chatStatusFailure(err));
  }
}

export function* watchChatStatusSaga() {
  yield takeLatest(types.CHAT_STATUS_REQUEST, chatStatusSaga);
}