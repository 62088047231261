import RequestService from "./RequestService";
import { api } from "../helpers/constant";

class NetworkService {
  loginService(action) {
    const endPoint = `${api.login}`;
    return RequestService.postRequest(endPoint, {
      password: action.data.password,
      emailOrNumber: action.data.email,
    });
  }

  addDoctor(action) {
    const endPoint = `${api.addDoctor}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  addHospital(action) {
    const endPoint = `${api.addHospital}`;
    return RequestService.postRequest(endPoint, {
      address: action.data.address,
      contactName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactNumber,
      contactEmail: action.data.email,
      id: 0,
      name: action.data.name.trim(),
      cityId: parseInt(action.data.city),
      password: action.data.password,
      serviceUuidList: action.data.serviceUuidArray,
      pinCode: action.data.pinCode,
    });
  }

  viewAllHospitalNames() {
    const endPoint = `${api.viewAllHospitalNames}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllDoctors(action) {
    const id = action.id;
    const search = action.name;
    const endPoint = `${api.viewAllDoctors}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      hospitalId: id === "" ? [] : [id],
      searchKey: search,
      cityIdList:
        action.city === "" || action.city === null || action.city === undefined
          ? []
          : [action.city],
      active: action.status === "All" ? null : action.status,
    });
  }

  viewAllHospitals(action) {
    const endPoint = `${api.viewAllHospitals}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList: action.city === null ? null : [`${action.city}`],
      search: action.search,
      active: action.status === "All" ? null : action.status,
    });
  }

  viewHospitalDetail(action) {
    const endPoint = `${api.viewHospitalDetail}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  deleteHospital(action) {
    const endPoint = `${api.deleteHospital}/${action.id}?disableReason=${action.data}`;
    return RequestService.putRequest(endPoint, action.file);
  }

  viewAllAgents(action) {
    const endPoint = `${api.viewAllAgents}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList: action.city === null ? null : [`${action.city}`],
      search: action.search,
      active: action.status === "All" ? null : action.status,
    });
  }

  deleteAgent(action) {
    const endPoint = `${api.deleteAgent}/${action.id}?disableReason=${action.data}`;
    return RequestService.putRequest(endPoint, action.file);
  }

  viewAgentDetail(action) {
    const endPoint = `${api.viewAgentDetail}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllCorporate(action) {
    const endPoint = `${api.viewAllCorporate}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList: action.city === null ? null : [`${action.city}`],
      search: action.search,
      active: action.status === "All" ? null : action.status,
    });
  }
  viewAllUsers(action) {
    const endPoint = `${api.viewAllUsers}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      cityList: action.city === null ? [""] : [`${action.city}`],
      search: action.search,
      userType:
        action.selectedValue === null ? [""] : [`${action.selectedValue}`],
    });
  }

  viewAllUsersDropdown() {
    const endPoint = `${api.viewAllUsersDropdown}`;
    return RequestService.getRequest(endPoint);
  }
  viewPackages(action) {
    if (action.data === undefined) {
      const endPoint = `${api.viewPackages}`;
      return RequestService.getRequest(endPoint, {
        search: action.search,
      });
    } else {
      const endPoint = `${api.viewPackages}?search=${action.data}`;

      return RequestService.getRequest(endPoint, {
        search: action.data.search,
      });
    }
  }

  viewCorporateDetail(action) {
    const endPoint = `${api.viewCorporateDetail}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  addInsuranceAgent(action) {
    const endPoint = `${api.addInsuranceAgent}`;
    return RequestService.postRequest(endPoint, {
      // address: action.data.address,
      companyName: action.data.insuranceCompany.trim(),
      email: action.data.email,
      id: 0,
      name: action.data.agentName.trim(),
      number: action.data.contactNumber,
      // cityId: parseInt(action.data.city),
      pinCode: action.data.pinCode,
      // serviceUuids: action.data.selectServices,
      password: action.data.password,
    });
  }
  AddInitiateRefund(action) {
    const endPoint = `${api.addInitiateRefund}`;

    return RequestService.putRequest(endPoint, action.data);
  }

  addCorporate(action) {
    const endPoint = `${api.addCorporate}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  addPharmacy(action) {
    const endPoint = `${api.addPharmacy}`;
    return RequestService.postRequest(endPoint, {
      address: action.data.address,
      cityId: parseInt(action.data.city),
      contactEmail: action.data.contactEmail,
      contactName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactPersonNumber,
      id: "",
      licenseNumber: action.data.licenseNumber,
      mapUrl: action.data.mapUrl,
      name: action.data.name.trim(),
      password: action.data.password,
      pharmacyValidity: parseInt(action.data.pharmacyValidity),
      pinCode: action.data.pinCode,
    });
  }

  deleteCorporate(action) {
    const endPoint = `${api.deleteCorporate}/disable/${action.id}/?disableReason=${action.data}`;
    return RequestService.putRequest(endPoint, action.file);
  }

  deleteDoctor(action) {
    const endPoint = `${api.deleteDoctor}/${action.id}/?disableReason=${action.data}`;
    return RequestService.putRequest(endPoint, action.file);
  }

  updateHospital(action) {
    const endPoint = `${api.updateHospital}`;
    return RequestService.putRequest(endPoint, {
      address: action.data.address,
      contactName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactNumber,
      contactEmail: action.data.email,
      id: action.data.id,
      name: action.data.name.trim(),
      cityId: parseInt(action.data.cityId),
      password: action.data.password,
      pinCode: action.data.pinCode,
      mapUrl: action.data.mapUrl,
      type: action.data.selectId,
    });
  }

  updateDoctor(action) {
    const endPoint = `${api.updateDoctor}`;

    return RequestService.putRequest(endPoint, action.data);
  }

  updateAppointments(action) {
    const endPoint = `${api.updateAppointment}`;
    return RequestService.putRequest(endPoint);
  }

  addAppointment(action) {
    if (action.data.relation === 0) {
      const endPoint = `${api.addAppointment}?fromWeb=true`;
      return RequestService.postRequest(endPoint, {
        description: action.data.description,
        doctorId: parseInt(action.data.doctorId),
        // doctorId:13,
        patientId: parseInt(action.data.patientId),
        timeSlot: action.data.someDate,
        userPlanVersion: action.data.userVersion,
        version: action.data.version,
        plan: action.data.plan,
        programOrPlanUuid: action.data.uuid,
        alternateContactNumber: action.data.patientContactNumber,
      });
    } else {
      const endPoint = `${api.addAppointment}?fromWeb=true`;
      return RequestService.postRequest(endPoint, {
        description: action.data.description,
        doctorId: parseInt(action.data.doctorId),
        patientId: parseInt(action.data.patientId),
        timeSlot: action.data.someDate,
        relationId: action.data.relation,
        userPlanVersion: action.data.userVersion,
        version: action.data.version,
        plan: action.data.plan,
        programOrPlanUuid: action.data.uuid,
        alternateContactNumber: action.data.patientContactNumber,
      });
    }
  }
  CreateTalkToDoctor(action) {
    const endPoint = `${api.CreateTalkToDoctor}`;
    return RequestService.postRequest(endPoint, {
      description: action.data.description,
      healthConcern: action.data.healthConcern,
      id: null,
      relationId: action.data.relationId === "" ? null : action.data.relationId,
      userId: action.data.userId,
      requestType: action.data.requestType,
    });
  }

  deleteAppointment(action) {
    const endPoint = `${api.deleteAppointment}/${action.id}`;
    return RequestService.deleteRequest(endPoint);
  }

  updateCorporate(action) {
    const endPoint = `${api.updateCorporate}`;
    return RequestService.putRequest(endPoint, {
      address: action.data.address,
      contactPersonEmail: action.data.email,
      contactPersonName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactNumber,
      cityId: parseInt(action.data.cityId),
      name: action.data.name.trim(),
      id: action.data.id,
      password: action.data.password,
      // contactPersonAddress: action.data.contactPersonAddress,
      pinCode: action.data.pinCode,
    });
  }

  updateInsuranceAgent(action) {
    const endPoint = `${api.updateInsuranceAgent}`;
    return RequestService.putRequest(endPoint, {
      address: action.data.address,
      companyName: action.data.insuranceCompany.trim(),
      email: action.data.email,
      id: action.data.agentId,
      name: action.data.agentName.trim(),
      number: action.data.contactNumber,
      // serviceUuids: action.data.serviceUuidList,

      password: action.data.password,
      cityId: parseInt(action.data.cityId),
      pinCode: action.data.pinCode,
    });
  }
  viewAccount(action) {
    if (action.searchText === "") {
      const endPoint = `${api.viewAccount}?pageNo=${action.page}&pageSize=${action.pageSize}&productTypeEnum=${action.select}`;
      return RequestService.postRequest(endPoint);
    } else {
      const endPoint = `${api.viewAccount}?pageNo=${action.page}&pageSize=${action.pageSize}&productTypeEnum=${action.select}&search=${action.searchText}`;
      return RequestService.postRequest(endPoint);
    }
  }

  viewAllAppointments(action) {
    const endPoint = `${api.viewAllAppointments}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    return RequestService.postRequest(endPoint, {
      appointmentStatus: [],
      cityIdList:
        action.city === "" || action.city === null || action.city === undefined
          ? []
          : [parseInt(action.city)],
      hospitalId: [],
      searchKey: action.value,
      todayFilter: false,
    });
  }
  viewAllBooking(action) {
    const endPoint = `${api.viewAllBooking}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    // if(action.city === null)
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city[0] === "" ||
        action.city[0] === null ||
        action.city[0] === undefined
          ? []
          : [parseInt(action.city)],
      search: action.value,
    });
  }

  viewAllService() {
    // const endPoint = `${api.viewAllService}`;
    const endPoint = `${api.viewAllServicesNameAndUuid}`;
    return RequestService.getRequest(endPoint);
  }

  viewService(action) {
    const endPoint = `${api.viewService}/${action.name}`;
    return RequestService.getRequest(endPoint);
  }

  viewInPackages(action) {
    const endPoint = `${api.viewInPackages}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  addService(action) {
    const endPoint = `${api.addService}`;
    return RequestService.postRequest(endPoint, {
      attributeRequestDtoList: action.data.inputList,
      serviceDescription: action.data.description,
      id: "",
      name: action.data.serviceName,
      serviceProviders: ["HOSPITAL"],
    });
  }

  getService() {
    const endPoint = `${api.getService}`;
    return RequestService.getRequest(endPoint);
  }

  // addProgram(action) {
  //   const endPoint = `${api.addProgram}?corporateId=2&insuranceCompanyId=4`;
  //   return RequestService.postRequest(endPoint, {
  //     attributeCountDtoList: action.data.newArray,
  //     description: action.data.description,
  //     endDate: localStorage.getItem("endDate"),
  //     name: action.data.name,
  //     startDate: localStorage.getItem("startDate"),
  //   });
  // }

  getProgram(action) {
    const endPoint = `${api.getProgram}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllProgram() {
    const endPoint = `${api.viewAllProgram}`;
    return RequestService.getRequest(endPoint);
  }

  loginHrService(action) {
    const endPoint = `${api.login}?password=${action.data.password}&emailOrNumber=${action.data.email}`;
    return RequestService.postRequest(endPoint);
  }

  loginDoctorService(action) {
    const endPoint = `${api.login}?password=${action.data.password}&emailOrNumber=${action.data.email}`;
    return RequestService.postRequest(endPoint);
  }
  viewAllEmployee(action) {
    if (
      action.programUuidArray.length === 0 ||
      action.city.length === "" ||
      action.asSuperAdmin === undefined
    ) {
      return null;
    }
    const endPoint = `${api.viewAllEmployee}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC&asSuperAdmin=${action.asSuperAdmin}`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === "" || action.city === null || action.city === undefined
          ? []
          : [`${action.city}`],
      search: action.search,
      corporateId: action.id,
      programUUIDList:
        action.programUuidArray[0] === "" ? [] : action.programUuidArray,
    });
  }
  viewProgram(action) {
    const endPoint = `${api.viewProgram}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  deleteProgram(action) {
    const endPoint = `${api.getProgram}/${action.data.name}`;
    return RequestService.getRequest(endPoint);
  }

  addEmployee(action) {
    const endPoint = `${api.addEmployee}`;
    return RequestService.postRequest(endPoint, {
      address: action.data.address,
      email: action.data.email,
      employeeId: action.data.employeeId,
      id: 0,
      name: action.data.name.trim(),
      number: action.data.number,
      password: action.data.password,
    });
  }

  viewEmployeeDetail(action) {
    const endPoint = `${api.viewEmployeeDetail}/${action.id}/${action.asSuperAdmin}`;
    return RequestService.getRequest(endPoint);
  }

  deleteEmployee(action) {
    const endPoint = `${api.deleteEmployee}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }

  addEmployeeFile(action) {
    const endPoint = `${api.addEmployeeFile}?programUuid=${action.uuid}`;
    return RequestService.postFileRequest(endPoint, action.data);
  }

  viewAppointments(action) {
    const endPoint = `${api.viewAppointment}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  getProgramTemplate(action) {
    const endPoint = `${api.getProgramTemplate}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  endAppointment(action) {
    const endPoint = `${api.endAppointment}`;
    return RequestService.putRequest(endPoint, {
      appointmentStatus: action.data.status,
      consulting: action.data.speciality,
      customerId: action.data.userId,
      description: action.data.description,
      id: action.data.id,
      timeSlot: action.data.timeSlot,
    });
  }
  addReport(action) {
    const endPoint = `${api.addReport}?appointmentId=${action.id}`;
    return RequestService.postRequest(endPoint, action.data);
  }
  deleteReport(action) {
    const endPoint = `${api.deleteReport}?attachmentId=${action.id}`;
    return RequestService.deleteRequest(endPoint);
  }
  confirmAppointment(action) {
    const endPoint = `${api.confirmAppointment}/${action.data.id}?patientNumber=${action.data.patientContactNumber}`;
    return RequestService.putRequest(endPoint);
  }
  rescheduleAppointment(action) {
    const endPoint = `${api.rescheduleAppointment}/${action.data}?timeSlot=${action.timeSlot}`;
    return RequestService.putRequest(endPoint);
  }
  cancelAppointment(action) {
    const endPoint = `${api.cancelAppointment}/${action.data}`;
    return RequestService.putRequest(endPoint);
  }
  validateOtp(action) {
    const endPoint = `${api.validateOtp}?id=${action.id}&otp=${action.otp}`;
    return RequestService.postRequest(endPoint);
  }

  updateServiceDescription(action) {
    const endPoint = `${api.updateService}?description=${action.desc}&serviceUuid=${action.detail}`;
    return RequestService.putRequest(endPoint);
  }

  updateServiceTitle(action) {
    const endPoint = `${api.updateService}/${action.detail.serviceUuid}`;
    return RequestService.putRequest(endPoint, {
      name: action.title,
      serviceDescription: action.detail.description,
    });
  }

  addServiceAttribute(action) {
    const endPoint = `${api.addServiceAttribute}/${action.data?.values?.serviceUuid}`;
    return RequestService.postRequest(endPoint, {
      attributeRequestDtoList: action.data.list,
      serviceDescription: action.data.values.serviceDescription,
      name: action.data.values.serviceName,
      serviceProviders: ["HOSPITAL"],
    });
  }

  updateServiceAttribute(action) {
    const endPoint = `${api.addServiceAttribute}/${action.data.values.serviceName}/${action.data.values.attributeID}`;
    return RequestService.putRequest(endPoint, {
      attributeRequestDtoList: action.data.list,
      serviceDescription: action.data.values.description,
      name: action.data.values.name,
    });
  }

  viewAllPackageNames(action) {
    const endPoint = `${api.viewAllPackageNames}`;
    return RequestService.getRequest(endPoint);
  }

  addProgram(action) {
    const endPoint = `${api.addProgram}`;
    return RequestService.postRequest(endPoint, {
      services: action.data.filteredServicesAttributeData,
      additionalServices: action.data.additionalServices,
      description: action.data.description.trim(),
      name: action.data.title.trim(),
      relationsAllowed: action.data.relations,
      childrenCount: action.data.children,
    });
  }

  assignProgram(action) {
    const endPoint = `${api.assignProgram}?corporateId=${action.data.corporateId}`;
    return RequestService.postRequest(endPoint, {
      additionalServices: action.data.additionalServices,
      description: action.data.description,
      endDate: action.data.endDateConverted,
      programName: action.data.programTitle,
      startDate: action.data.startDateConverted,
      services: action.data.filteredServicesAttributeData,
      programCount: parseInt(action.data.programCount),
      relationsAllowed: action.data.relations,
      childrenCount: parseInt(action.data.children),
    });
  }

  viewAllProgramTemplateNames(action) {
    const endPoint = `${api.viewAllProgramTemplateNames}`;
    return RequestService.postRequest(endPoint);
  }
  viewAllTemplateData(action) {
    const endPoint = `${api.viewAllTemplateData}/${action.value}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllServicesAttributes(action) {
    const endPoint = `${api.viewAllServicesAttributes}/${action.serviceUuid}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllServicesNameAndUuid() {
    const endPoint = `${api.viewAllServicesNameAndUuid}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllCity() {
    const endPoint = `${api.viewAllCity}`;
    return RequestService.getRequest(endPoint);
  }
  viewDropdownAccount() {
    const endPoint = `${api.viewDropdownAccount}`;
    return RequestService.getRequest(endPoint);
  }

  getUpdateHospital(action) {
    const endPoint = `${api.getUpdateHospital}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getUpdateCorporate(action) {
    const endPoint = `${api.getUpdateCorporate}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  deleteService(action) {
    const endPoint = `${api.deleteService}?uuid=${action.id}`;
    return RequestService.deleteRequest(endPoint);
  }

  getUpdateInsurer(action) {
    const endPoint = `${api.getUpdateInsurer}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  checkEmailAgent(action) {
    const endPoint = `${api.checkEmailInsurer}/${action.data}`;
    return RequestService.getRequest(endPoint, {
      email: action.data.email,
    });
  }
  checkEmailOrNumber(action) {
    const endPoint = `${api.checkEmailOrNumber}/${action.data}`;
    return RequestService.getRequest(endPoint, {
      email: action.data.email,
    });
  }

  checkNumber(action) {
    const endPoint = `${api.checkEmailInsurer}/${action.data}`;
    return RequestService.getRequest(endPoint, {
      email: action.data.email,
    });
  }

  allCityAppointment(action) {
    const endPoint = `${api.viewAllCity}`;
    return RequestService.getRequest(endPoint);
  }

  searchDoctor(action) {
    let cityId = 0;
    let endPoint;

    if (action.data.name.length === 0 && action.data.getId === "undefined") {
      endPoint = `${api.getSearchDoctor}?fromApp=false`;
    } else if (action.data.name.length === 0 && action.data.getId === 0) {
      endPoint = `${api.getSearchDoctor}?fromApp=false`;
    } else if (action.data.name.length === 0 && action.data.getId !== 0) {
      endPoint = `${api.getSearchDoctor}?cityId=${action.data.getId}&fromApp=false`;
    }

    if (action.data.name.length !== 0 && action.data.getId === 0) {
      endPoint = `${api.getSearchDoctor}?fromApp=false&search=${action.data.name}`;
    }
    if (action.data.name.length !== 0 && action.data.getId !== 0) {
      endPoint = `${api.getSearchDoctor}?cityId=${action.data.getId}&fromApp=false&search=${action.data.name}`;
    } else if (action.data.status && cityId && action.data.name !== "null") {
      endPoint = `${api.getSearchDoctor}?cityId=${cityId}&fromApp=false&search=${action.data.name}`;
    }

    return RequestService.getRequest(endPoint);
  }

  viewAllServiceForCorporate() {
    const endPoint = `${api.viewAllServiceForCorporate}`;
    return RequestService.getRequest(endPoint);
  }

  getEmployeeProgram(action) {
    const endPoint = `${api.getEmployeeProgram}?id=${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  assignProgramToEmployee(action) {
    const endPoint = `${api.assignProgramToEmployee}?programUuid=${action.data.uuid}`;
    return RequestService.postRequest(endPoint, {
      email: action.data.email,
      employeeId: action.data.employeeId,
      id: 0,
      name: action.data.name,
      number: action.data.contactNumber.toString(),
      password: action.data.password,
    });
  }

  viewAllEmployeeProgram() {
    const endPoint = `${api.viewAllEmployeeProgram}`;
    return RequestService.getRequest(endPoint);
  }

  validateOtpLogin(action) {
    const endPoint = `${api.validateOtpLogin}`;
    return RequestService.postRequest(endPoint, {
      emailOrNumber: action.email,
      otp: action.otp,
    });
  }

  fileTemplate() {
    const endPoint = `${api.fileTemplate}`;
    return RequestService.getFileRequest(endPoint);
  }

  addLab(action) {
    const endPoint = `${api.addLab}`;
    return RequestService.postRequest(endPoint, {
      address: action.data.LabAddress,
      contactName: action.data.contactName.trim(),
      contactPersonNumber: action.data.contactNumber.toString(),
      contactEmail: action.data.email,
      id: 0,
      name: action.data.labName.trim(),
      cityId: parseInt(action.data.city),
      pinCode: action.data.pinCode.toString(),
    });
  }

  addTest(action) {
    const endPoint = `${api.addTest}`;
    return RequestService.postRequest(endPoint, {
      cost: action.data.values.TestAmount.toString(),
      description: action.data.values.Description,
      id: 0,
      instruction: action.data.instruction,
      interpretation: action.data.values.Interpretation,
      name: action.data.values.TestName.trim(),
      parameters: action.data.csvString,
      reportTime: action.data.TestTotalTime,
      radiology: action.data.radiology,
    });
  }

  updateTest(action) {
    const endPoint = `${api.updateTest}`;
    return RequestService.putRequest(endPoint, {
      cost: action.data.values.TestAmount.toString(),
      description: action.data.values.Description,
      reportTime: action.data.TestTotalTime,
      id: action.data.values.id,
      instruction: action.data.instruction,
      interpretation: action.data.values.Interpretation,
      name: action.data.values.TestName.trim(),
      parameters: action.data.csvString,
      radiology: action.data.radiology,
    });
  }

  viewAllTestsName() {
    const endPoint = `${api.viewAllTestsName}`;
    return RequestService.getRequest(endPoint);
  }

  makePackage(action) {
    const endPoint = `${api.makePackage}`;
    return RequestService.postRequest(endPoint, {
      attributeIds: action.data.serviceId,
      cost: parseInt(action.data.values.cost),
      description: action.data.values.packageDescription,
      featured: action.data.feature,
      name: action.data.values.packageName.trim(),
      prerequisites: action.data.prerequisites,
      radiology: action.data.radiology,
    });
  }

  viewAllLab(action) {
    const endPoint = `${api.viewAllLab}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === null || action.city === "" ? [] : [`${action.city}`],
      search: action.search,
      active: action.status === "All" ? null : action.status,
    });
  }
  viewLab(action) {
    const endPoint = `${api.viewLab}/${action.action}`;
    return RequestService.getRequest(endPoint);
  }
  viewInLab(action) {
    const endPoint = `${api.viewInLab}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }
  // viewPackages(action) {
  //   const endPoint = `${api.viewPackages}?search={}`;
  //   return RequestService.getRequest(endPoint);
  // }

  viewBooking(action) {
    const endPoint = `${api.viewBooking}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  deleteLab(action) {
    const endPoint = `${api.deleteLab}/${action.id}?disableReason=${action.disableReason}`;
    return RequestService.putRequest(endPoint, action.file);
  }

  updateLab(action) {
    const endPoint = `${api.addLab}`;
    return RequestService.putRequest(endPoint, {
      address: action.data.values.LabAdddress,
      contactName: action.data.values.contactName,
      contactPersonNumber: action.data.recvMobileNumber.toString(),
      contactEmail: action.data.recvEmail,
      id: action.data.values.id,
      name: action.data.values.LabName,
      cityId: parseInt(action.data.values.city),
      pinCode: action.data.values.pinCode.toString(),
      password: action.data.values.password,
      mapUrl: action.data.values.mapUrl,
    });
  }

  viewAllTest(action) {
    const endPoint = `${api.viewAllTest}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint, {
      search: action.search,
    });
  }

  confirmBooking(action) {
    const endPoint = `${api.updateBooking}/${action.data}?cancelled=false`;
    return RequestService.putRequest(endPoint);
  }

  rescheduleBooking(action) {
    const endPoint = `${api.updateBooking}/${action.data}?timeSlot=${action.timeSlot}`;
    return RequestService.putRequest(endPoint);
  }

  cancelBooking(action) {
    const endPoint = `${api.updateBooking}/${action.data}?cancelled=true`;
    return RequestService.putRequest(endPoint);
  }
  viewTest(action) {
    const endPoint = `${api.viewTest}/${action.page}`;
    return RequestService.getRequest(endPoint);
  }
  uploadReport(action) {
    const endPoint = `${api.uploadReport}?attachmentEnum=BOOKING&referenceId=${action.id}`;
    return RequestService.postRequest(endPoint, action.data);
  }
  finishBooking(action) {
    const endPoint = `${api.finishBooking}/${action.data}/true`;
    return RequestService.putRequest(endPoint);
  }
  completeBooking(action) {
    const endPoint = `${api.completeBooking}/${action.data}/false`;
    return RequestService.putRequest(endPoint);
  }
  updatePackage(action) {
    const endPoint = `${api.updatePackage}?packageUUID=${action.data.packageUuid}`;
    return RequestService.putRequest(endPoint, {
      attributeIds: action.data.serviceId,
      cost: parseInt(action.data.values.cost),
      description: action.data.values.packageDescription,
      interpretation: action.data.values.interpretation,
      featured: action.data.feature,
      name: action.data.values.packageName.trim(),
      prerequisites: action.data.prerequisites,
      radiology: action.data.radiology,
    });
  }

  updatePackageFeature(action) {
    const endPoint = `${api.updatePackageFeature}?packageUuid=${action.data}`;
    return RequestService.putRequest(endPoint);
  }

  downloadAttachment(action) {
    const endPoint = `${api.downloadAttachment}?referenceId=${action.data}`;
    return RequestService.getRequest(endPoint);
  }
  uploadReportAppointments(action) {
    const endPoint = `${api.uploadReport}?attachmentEnum=APPOINTMENT&referenceId=${action.id}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  finishAppointment(action) {
    const endPoint = `${api.finishAppointment}/${action.data}`;
    return RequestService.putRequest(endPoint);
  }
  downloadPrescription(action) {
    const endPoint = `${api.downloadAttachment}?attachmentId=${action.data}`;
    return RequestService.getFileRequest(endPoint);
  }
  listOfConsultations(action) {
    const endPoint = `${api.listOfConsultations}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    return RequestService.postRequest(endPoint, {
      search: action.search,
    });
  }

  consultationRequests(action) {
    const endPoint = `${api.consultationRequests}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  corporateDashboard(action) {
    if (!action.startAge) {
      const endPoint = `${api.corporateDashboard}`;
      return RequestService.getRequest(endPoint);
    } else {
      const endPoint = `${api.corporateDashboard}?endAge=${action.endAge}&startAge=${action.startAge}`;
      return RequestService.getRequest(endPoint);
    }
  }

  submitPrescription(action) {
    const endPoint = `${api.submitPrescription}`;
    return RequestService.postRequest(endPoint, {
      complain: action.data.complain,
      consultationId: parseInt(action.data.consultationId),
      provisionalDiagnosis: action.data.provisionalDiagnosis,
      otherAdvices: action.data.otherAdvices,
      testAdvice: action.data.testAdvice,
      treatmentAdviceRequestDto: action.data.treatmentAdvices,
      identifier: action.data.identifier.toString(),
    });
  }

  AcceptOrRejectRequest(action) {
    const endPoint = `${api.AcceptOrRejectRequest}/${action.data.id}?accept=${action.data.bool}`;
    return RequestService.putRequest(endPoint);
  }

  viewAllLabsNamesRequest(action) {
    const endPoint = `${api.viewAllLabsNamesRequest}`;
    return RequestService.getRequest(endPoint);
  }

  AssignLab(action) {
    const endPoint = `${api.AssignLab}/${action.bookingid}/${action.labid}`;
    return RequestService.postRequest(endPoint);
  }

  deleteAttachment(action) {
    const endPoint = `${api.deleteAttachment}?attachmentId=${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }
  viewAllPayment(action) {
    let dateFilter = false;
    if (action.startDate !== 0 || action.endDate !== 0) {
      dateFilter = true;
    }
    let endPoint = `${api.viewAllPayment}?dateFilter=${dateFilter}&pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    if (action.paymentStatus !== null && action.paymentStatus !== undefined) {
      endPoint += `&paymentStatus=${action.paymentStatus}`;
    }
    return RequestService.postRequest(endPoint, {
      cityIdList: [],
      endDate: action.endDate,
      searchKey: action.search,
      startDate: action.startDate,
      todayFilter: false,
      appointmentStatus: action.appointmentStatus,
      hospitalId:
        action.HospitalId === null ||
        action.HospitalId === "" ||
        action.HospitalId === undefined
          ? []
          : [action.HospitalId],
    });
  }
  viewAllAppointmentWithoutLot(action) {
    let dateFilter = false;
    if (action.startDate !== 0 || action.endDate !== 0) {
      dateFilter = true;
    }
    const endPoint = `${api.viewAllAppointmentWithoutLot}?dateFilter=${dateFilter}&pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    return RequestService.postRequest(endPoint, {
      cityIdList: [],
      endDate: action.endDate,
      searchKey: action.search,
      startDate: action.startDate,
      todayFilter: false,
    });
  }
  createLot(action) {
    const endPoint = `${api.createLot}`;
    return RequestService.postRequest(endPoint, {
      appointmentId: action.appointmentId,
      prescriptionId: action.prescriptionId,
      hospitalId: action.hospitalId,
    });
  }

  chatStatus(action) {
    if (action.data.reasonText !== "") {
      const endPoint = `${api.chatStatus}/${action.data.id}?complete=${action.data.bool}&reason=${action.data.reasonText}`;
      return RequestService.putRequest(endPoint);
    } else {
      const endPoint = `${api.chatStatus}/${action.data.id}?complete=${action.data.bool}`;
      return RequestService.putRequest(endPoint);
    }
  }
  getAllLots(action) {
    const endPoint = `${api.getAllLots}?lotType=${action.lotType}&pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=LOT_ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint);
  }

  getLotById(action) {
    const endPoint = `${api.getAllLotsIds}?lotId=${action.id}&lotType=${action.lotType}&pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=ASC`;
    return RequestService.postRequest(endPoint);
  }

  updatePayment(action) {
    const endPoint = `${api.updatePayment}`;
    return RequestService.postRequest(endPoint, {
      amount: parseInt(action.data.amount),
      discount: parseInt(action.data.discount),
      hospitalId: parseInt(action.data.hospitalId),
      pharmacyId: parseInt(action.data.pharmacyId),
      lotId: parseInt(action.data.lotId),
      referenceNumber: action.data.referenceNumber,
      tds: parseInt(action.data.tds),
    });
  }
  getRelation(action) {
    const endPoint = `${api.getRelation}?userId=${action.id}`;
    return RequestService.getRequest(endPoint);
  }
  getAllLotsIds(action) {
    const endPoint = `${api.getLotById}?lotType=${action.data}`;
    return RequestService.getRequest(endPoint);
  }
  getMyTestsAndPackage(action) {
    const endPoint = `${api.getMyTestsAndPackage}?userId=${action.data}`;
    return RequestService.getRequest(endPoint);
  }
  AddBooking(action) {
    const endPoint = `${api.AddBooking}?fromWeb=true`;
    return RequestService.postRequest(endPoint, {
      address: action.data.address,
      packageUuid: action.data.packageUuid,
      version: action.data.version,
      userPlanVersion: action.data.userPlanVersion,
      cityId: parseInt(action.data.cityId),
      patientId: action.data.patientId,
      pinCode: action.data.pinCode.toString(),
      programOrPlanUuid: action.data.programOrPlanUuid,
      relationId: parseInt(action.data.relationId),
      plan: action.data.plan,
      timeSlot: parseInt(action.data.timeSlot),
      testId: [],
      contactNumber: action.data.contactNumber,
    });
  }
  networkHospital(action) {
    const endPoint = `${api.networkHospital}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === null || action.city === undefined
          ? []
          : [`${action.city}`],
      networkStatusList: action.status === "ALL" ? [] : [action.status],
      search: action.search,
    });
  }
  networkProfessionalService(action) {
    const endPoint = `${api.networkProfessionalSevices}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === null || action.city === undefined
          ? []
          : [`${action.city}`],
      networkStatusList: action.status === "ALL" ? [] : [action.status],
      search: action.search,
    });
  }
  networkClinic(action) {
    const endPoint = `${api.networkClinic}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === null || action.city === undefined
          ? []
          : [`${action.city}`],
      networkStatusList: action.status === "ALL" ? [] : [action.status],
      search: action.search,
    });
  }

  networkViewHospital(action) {
    const endPoint = `${api.networkViewHospital}?id=${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  networkViewProfessionalService(action) {
    const endPoint = `${api.networkViewProfessional}?id=${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  approveHospital(action) {
    const endPoint = `${api.approveHospital}?id=${action.id}&mapUrl=${action.location}&networkStatus=${action.status}&typeEnum=${action.selectType}&createPrescription=${action.createPrescription}`;
    return RequestService.postRequest(endPoint);
  }
  approveProfessionalService(action) {
    const endPoint = `${api.approveProfessional}?id=${action.id}&mapUrl=${action.location}&networkStatus=${action.status}`;
    return RequestService.postRequest(endPoint);
  }
  networkDiagnostic(action) {
    const endPoint = `${api.networkDiagnostic}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      cityList:
        action.city === null || action.city === undefined
          ? []
          : [`${action.city}`],
      networkStatusList: action.status === "ALL" ? [] : [action.status],
      search: action.search,
    });
  }
  networkViewClinic(action) {
    const endPoint = `${api.networkViewClinic}?id=${action.id}`;
    return RequestService.getRequest(endPoint);
  }
  networkViewDiagnostic(action) {
    const endPoint = `${api.networkViewDiagnostic}?id=${action.id}`;
    return RequestService.getRequest(endPoint);
  }
  approveDiagnostic(action) {
    const endPoint = `${api.approveDiagnostic}?id=${action.id}&mapUrl=${action.location}&networkStatus=${action.status}`;
    return RequestService.postRequest(endPoint);
  }
  approveClinic(action) {
    const endPoint = `${api.approveClinic}?id=${action.id}&mapUrl=${action.location}&networkStatus=${action.status}&typeEnum=${action.selectType}&createPrescription=${action.createPrescription}`;
    return RequestService.postRequest(endPoint);
  }
  userDetail(action) {
    const endPoint = `${api.userDetail}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }
  relationship(action) {
    if (action.data.version === null) {
      const endPoint = `${api.relationship}?userId=${action?.data?.id}&uuid=${action.data.uuid}`;
      return RequestService.getRequest(endPoint);
    }
    const endPoint = `${api.relationship}?userId=${action?.data?.id}&uuid=${action.data.uuid}&version=${action.data.version}&userVersion=${action.data.userVersion}`;
    return RequestService.getRequest(endPoint);
  }

  programAndPlan(action) {
    const endPoint = `${api.programAndPlan}?id=${action.data.id}&serviceUuid=${action.data.uuid}`;
    return RequestService.getRequest(endPoint);
  }

  viewAllProgramOfInsurer(action) {
    const endPoint = `${api.viewAllProgramOfInsurer}`;
    return RequestService.getRequest(endPoint);
  }
  assignProgramInsurer(action) {
    const endPoint = `${api.assignProgramInsurer}?insurerId=${action.data.insurerId}`;
    return RequestService.postRequest(endPoint, {
      additionalServices: action.data.additionalServices,
      description: action.data.description,
      programName: action.data.programTitle,
      services: action.data.filteredServicesAttributeData,
      programCount: action.data.programCount,
      relationsAllowed: action.data.relations,
      childrenCount: action.data.children,
    });
  }

  addPlan(action) {
    const endPoint = `${api.addPlan}`;
    return RequestService.postRequest(endPoint, {
      additionalServices: action.data.additionalServices,
      anyOtherServices: action.data.finalotherService,
      autoCardNumberGeneration: action.data.autoGenerateCardNumber,
      childrenCount:
        action.data.children === "" ? 0 : parseInt(action.data.children),
      cityIds: action.data.cityIds,
      description: action.data.planDescription,
      featured: action.data.FeaturedPackage,
      halfYearlyPrice: action.data.sixMonthsFP,
      halfYearlyPriceGst: action.data.sixMonthsGSTFinal,
      name: action.data.planName.trim(),
      quarterlyPrice: action.data.threeMonthsFP,
      quarterlyPriceGst: action.data.threeMonthsGSTFinal,
      relationsAllowed: action.data.relations,
      services: action.data.filteredServicesAttributeData,
      yearlyPrice: action.data.twelveMonthsFP,
      yearlyPriceGst: action.data.twelveMonthsGSTFinal,
      typeList: action.data.typeList,
      showRelative: action.data.showRelations
    });
  }

  planTypeDropdown(action) {
    const endPoint = `${api.planTypeDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  corporateViewAssignProgramNetwork(action) {
    const endPoint = `${api.getAllProgramOfCorporate}?id=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  corporateGetAssignProgramNetwork(action) {
    const endPoint = `${api.getAllViewProgramOfCorporate}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  deleteProgramTemplate(action) {
    const endPoint = `${api.deleteProgramTemplate}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }
  getInsurerAssignedPrograms(action) {
    const endPoint = `${api.getInsurerAssignedPrograms}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  AssignProgramToInsurer(action) {
    let id = parseInt(action.data.corporateid);
    const endPoint = `${api.AssignProgramToInsurer}/${id}`;
    return RequestService.postRequest(endPoint, {
      allocatedCount: parseInt(action.data.count),
      endDate: action.data.endDateConverted,
      programUuid: action.data.programUuid,
      startDate: action.data.startDateConverted,
    });
  }

  getProgramInsurer(action) {
    const endPoint = `${api.getProgramInsurer}?id=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  GetAllCoupon(action) {
    const endPoint = `${api.GetAllCoupon}/getAllCoupons?pageNo=${action.data.page}&pageSize=${action.data.pageSize}&sortBy=ID&sortOrder=DESC`;
    return RequestService.postRequest(endPoint, {
      productType:
        action.data.productType === "" ? [] : [action.data.productType],
      searchKey: action.data.search,
    });
  }

  addDiscountIndividual(action) {
    const endPoint = `${api.addDiscountIndividual}?productTypeEnum=${action.data.enumType}`;
    return RequestService.postRequest(endPoint, {
      selectAll: action.data.allSelected,
      uuids: action.data.allSelected === false ? action.data.selectedUUID : [],
      discountRequestDto: {
        description: action.data.values.Description,
        discountPercentage: action.data.values.DiscountPercentage,
        endDate: action.data.edate,
        startDate: action.data.sdate,
      },
      discountFilterDto: {
        maxDiscount: action.data.maxDiscount,
        minDiscount: action.data.minDiscount,
        search: action.data.search,
      },
    });
  }

  editDiscount(action) {
    const endPoint = `${api.editDiscount}?productTypeEnum=${action.data.enumType}&uuid=${action.data.details.uuid}`;
    return RequestService.putRequest(endPoint, {
      description: action.data.values.Description,
      discountPercentage: action.data.DiscountPercentageValue,
      endDate: action.data.edate,
      startDate: action.data.sdate,
    });
  }

  editDiscountProduct(action) {
    const endPoint = `${api.editDiscount}?productTypeEnum=${action.data.enumType}&uuid=${action.data.priceId}`;
    return RequestService.putRequest(endPoint, {
      description: action.data.discountDescription,
      discountPercentage: parseInt(action.data.discountPercentageValue),
      endDate: action.data.endDateConverted,
      startDate: action.data.startDateConverted,
    });
  }

  getAllDiscount(action) {
    const endPoint = `${api.getAllDiscount}?productTypeEnum=${action.enumType}&pageNo=${action.page}&pageSize=${action.pageSize}`;
    return RequestService.postRequest(endPoint, {
      maxDiscount: action.maxDiscount,
      minDiscount: action.minDiscount,
      search: action.search,
    });
  }

  viewDiscount(action) {
    const endPoint = `${api.viewDiscount}?productTypeEnum=${action.data.enumType}&uuid=${action.data.id}`;
    return RequestService.getRequest(endPoint);
  }

  deleteDiscount(action) {
    const endPoint = `${api.deleteDiscount}?productTypeEnum=${action.data.enumType}&uuid=${action.data.uuid}`;
    return RequestService.deleteRequest(endPoint, {
      uuids: action.data.selectedUUID,
    });
  }
  DeleteCoupon(action) {
    const endPoint = `${api.DeleteCoupon}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }
  EditCoupon(action) {
    const endPoint = `${api.EditCoupon}`;
    return RequestService.putRequest(endPoint, {
      endDate: action.data.endDate,
      id: parseInt(action.data.id),
    });
  }

  GetCouponDetails(action) {
    const endPoint = `${api.GetCouponDetails}/${action.id}`;
    return RequestService.getRequest(endPoint);
  }

  addDiscountGroup(action) {
    const endPoint = `${api.addDiscountGroup}`;
    return RequestService.postRequest(endPoint, {});
  }

  AddCoupon(action) {
    const endPoint = `${api.addCoupon}`;
    return RequestService.postRequest(endPoint, {
      allowForLogin: action.data.AFLC,
      cartValue: parseInt(action.data.MinimumAmount),
      couponCode: action.data.CouponCode.toUpperCase().trim(),
      couponCount: parseInt(action.data.UsagePerCoupon),
      couponName: action.data.CouponName,
      description: action.data.description,
      discountAmount: action.data.DiscountAmount,
      discountPercentage: action.data.DiscountPercentage,
      displayAtCheckout: action.data.DAC,
      endDate: parseInt(action.data.endDate),
      maxDiscount: parseInt(action.data.MaximumAmount),
      // productTypeEnum: action.data.strValue,
      startDate: parseInt(action.data.startDate),
      usagePerUser: parseInt(action.data.UsagePerCustomers),
      test: action?.data?.strValue.Test,
      product: action?.data?.strValue.Product,
      package: action?.data?.strValue.Package,
      plan: action?.data?.strValue.Plan,
    });
  }

  viewOrder(action) {
    const endPoint = `${api.viewOrder}?id=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  viewOrderDropdown(action) {
    const endPoint = `${api.viewOrderDropdown}?id=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  changeOrderStatus(action) {
    const endPoint = `${api.changeOrderStatus}`;
    return RequestService.putRequest(endPoint, {
      orderId: action.data.id,
      orderItemIdList: action.data.orderSelected,
      orderPaymentStatus: action.data.selectedOption,
      remark: action.data.description,
    });
  }
  viewAllPlan(action) {
    const endPoint = `${api.viewAllPlan}/all?pageNo=${action.data.page}&pageSize=${action.data.pageSize}`;
    return RequestService.postRequest(endPoint, {
      search: action.data.search,
    });
  }

  getPlanDetail(action) {
    const endPoint = `${api.getPlanDetail}/${action.data}?with80D=${action.bool}`;
    return RequestService.getRequest(endPoint);
  }

  deletePlan(action) {
    const endPoint = `${api.deletePlan}/${action.data}`;
    return RequestService.deleteRequest(endPoint);
  }

  getDosageType() {
    const endPoint = `${api.getDosageType}`;
    return RequestService.getRequest(endPoint);
  }

  getMedType() {
    const endPoint = `${api.getMedType}`;
    return RequestService.getRequest(endPoint);
  }

  getDirection() {
    const endPoint = `${api.getDirection}`;
    return RequestService.getRequest(endPoint);
  }

  downloadPrescriptionPdf(action) {
    const endPoint = `${api.downloadPrescriptionPdf}/${action.data.id}/${action.data.identifier}`;
    return RequestService.getFileRequest(endPoint);
  }
  getUserIndividual(action) {
    const endPoint = `${api.getUserIndividual}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  getUserPlan(action) {
    const endPoint = `${api.getUserPlan}/${action.data}/plans?pageNo=1&pageSize=10`;
    return RequestService.getRequest(endPoint);
  }

  getUserProgram(action) {
    const endPoint = `${api.getUserProgram}/${action.data}/programs?pageNo=1&pageSize=10`;
    return RequestService.getRequest(endPoint);
  }
  AllHospitalNameDropdown(action) {
    const endPoint = `${api.AllHospitalNamesDropDown}`;
    return RequestService.getRequest(endPoint);
  }

  AllDoctorListDetail(action) {
    let endPoint = `${api.DoctorDetailList}?pageNo=${action.page}&pageSize=${action.pageSize}`;
    if (action.startDate !== undefined) {
      endPoint += `&startDate=${action.startDate}&endDate=${action.endDate}`;
    }
    return RequestService.postRequest(endPoint, {
      active: action.status === "All" ? null : action.status,
      hospitalId:
        action.hospitalDropdown === null || action.hospitalDropdown === ""
          ? []
          : [action.hospitalDropdown],
      searchKey: action.searchText,
    });
  }

  HospitalDashboardGet(action) {
    const endPoint = `${api.HospitalDashboardGet}`;
    return RequestService.postRequest(endPoint, {
      endDate: action.data.endDate,
      id: action.data.id === "All" ? null : action.data.id,
      startDate: action.data.startDate,
    });
  }

  HospitalViewDoctorDetails(action) {
    const endPoint = `${api.HospitalViewDoctorDetails}/${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  AccountviewAllPharmacyListing(action) {
    const endPoint = `${api.getAllPrescription}?pageNo=${action.page}&pageSize=${action.pageSize}&sortBy=ID&sortOrder=DESC`;
    return RequestService.postRequest(endPoint, {
      endDate: action.endDate,
      paymentStatus: action.paymentStatus,
      searchKey: action.search,
      startDate: action.startDate,
    });
  }

  addUser(action) {
    const endPoint = `${api.addUser}`;
    return RequestService.postRequest(endPoint, {
      email: action.data.email === "" ? null : action.data.email,
      name: action.data.name,
      number: action.data.number.toString(),
      password: action.data.password,
    });
  }

  getServiceDropdown() {
    const endPoint = `${api.getServiceDropdown}`;
    return RequestService.getRequest(endPoint);
  }
  getPackageDropdown() {
    const endPoint = `${api.getPackageDropdown}`;
    return RequestService.getRequest(endPoint);
  }
  getPlanDropdown() {
    const endPoint = `${api.getPlanDropdown}`;
    return RequestService.getRequest(endPoint);
  }

  corporateDropdown() {
    const endPoint = `${api.getCorporateDropdown}`;
    return RequestService.getRequest(endPoint);
  }
  getPlanDropdownForCard() {
    const endPoint = `${api.getPlanDropdownForCard}`;
    return RequestService.getRequest(endPoint);
  }

  createCardNumberService(action) {
    const endPoint = `${api.createCardNumber}`;
    return RequestService.postRequest(endPoint, {
      corporateId: action.data.corporateId,
      count: action.data.count,
      planUuid: action.data.planUuid,
      year: action.data.year,
    });
  }
  getAllCardsDropdownService(action) {
    const endPoint = `${api.getAllCardsDropdown}?subscriptionId=${action.data}`;
    return RequestService.getRequest(endPoint);
  }

  AssignCardSubscription(action) {
    const endPoint = `${api.assignCardSubscription}`;
    return RequestService.postRequest(endPoint, {
      cardNumberId: action.data.cardNumberId,
      subscriptionId: action.data.subscriptionId,
      userId: action.data.userId,
    });
  }
  getDownloadCardDetailService() {
    const endPoint = `${api.getAllCardsDownload}`;
    return RequestService.getFileRequest(endPoint);
  }

  updatePlan(action) {
    const endPoint = `${api.updatePlan}/${action.data.uuid}`;
    return RequestService.putRequest(endPoint, {
      additionalServices: action.data.additionalServices,
      anyOtherServices: action.data.finalotherService,
      autoCardNumberGeneration: action.data.autoGenerateCardNumber,
      childrenCount:
        action.data.children === "" ? 0 : parseInt(action.data.children),
      cityIds: action.data.cityIds,
      description: action.data.planDescription,
      featured: action.data.FeaturedPackage,
      halfYearlyPrice: action.data.sixMonthsFP,
      halfYearlyPriceGst: action.data.sixMonthsGSTFinal,
      name: action.data.planName.trim(),
      quarterlyPrice: action.data.threeMonthsFP,
      quarterlyPriceGst: action.data.threeMonthsGSTFinal,
      relationsAllowed: action.data.relations,
      services: action.data.filteredServicesAttributeData,
      yearlyPrice: action.data.twelveMonthsFP,
      yearlyPriceGst: action.data.twelveMonthsGSTFinal,
      typeList: action.data.typeList,
      showRelative: action.data.showRelations

    });
  }

  createCategory(action) {
    const endPoint = `${api.addCategory}`;
    return RequestService.postRequest(endPoint, action.data);
  }

  mapToElastic(action) {
    const endPoint = `${api.mapToElastic}`;
    return RequestService.getRequest(endPoint);
  }

  mapToElasticDoctor(action) {
    const endPoint = `${api.mapToElasticDoctor}`;
    return RequestService.getRequest(endPoint);
  }
}
export default new NetworkService();
