import * as types from "../../Config/actionType"

export const viewLabRequest = (action) => {
    return {
        type: types.VIEW_LAB_REQUEST,
        action
    }
}

export const viewLabSuccess = (action) => {
    return {
        type: types.VIEW_LAB_SUCCESS,
        action
    }
}

export const viewLabFailure = (action) => {
    return {
        type: types.VIEW_LAB_FAILURE,
        action
    }
} 