import * as types from "../../Config/actionType"

export const addDoctorRequest = (data) => {
   
    return {
        type: types.ADD_DOCTORS_REQUEST,
        data:data
    }
}

export const addDoctorSuccess = (data) => {
   
    return {
        type: types.ADD_DOCTORS_SUCCESS,
        data:data,
    }
}

export const addDoctorFailure = (data) => {
   
    return {
        type: types.ADD_DOCTORS_FAILURE,
        data:data,
    }
}