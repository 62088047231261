import * as types from "../../Config/actionType"

export const deleteCorporateRequest = (id,data,file) => {
    return {
        type: types.DELETE_CORPORATE_REQUEST,
        id:id,
        data:data,
        file:file
    }
}

export const deleteCorporateSuccess = (action) => {
    return {
        type: types.DELETE_CORPORATE_SUCCESS,
        action
    }
}

export const deleteCorporateFailure = (action) => {
    return {
        type: types.DELETE_CORPORATE_FAILURE,
        action
    }
}