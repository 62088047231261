import * as types from "../../Config/actionType";

export const GetUserProgramRequest = (data) => {
  return {
    type: types.GET_USER_PROGRAM_REQUEST,
    data: data,
  };
};

export const GetUserProgramSuccess = (data) => {
  return {
    type: types.GET_USER_PROGRAM_SUCCESS,
    data: data,
  };
};

export const GetUserProgramFailure = (data) => {
  return {
    type: types.GET_USER_PROGRAM_FAILURE,
    data: data,
  };
};
