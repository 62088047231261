import * as types from "../../Config/actionType";

export const DeleteCouponRequest = (data) => {
  return {
    type: types.DELETE_COUPON_REQUEST,
    data:data
  };
};

export const DeleteCouponSuccess = (action) => {
  return {
    type: types.DELETE_COUPON_SUCCESS,
    action,
  };
};

export const DeleteCouponFailure = (action) => {
  return {
    type: types.DELETE_COUPON_FAILURE,
    action,
  };
};
