import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Dashboard/HospitalDashboard/DoctorListDetail"
import NetworkService from "../../../../network/NetworkService";

export function* AllDoctorDetailListSaga(action) {
  try {
    let response = yield call(NetworkService.AllDoctorListDetail, action);
    yield put(actions.DoctorListDetailSuccess(response));
  } catch (err) {
    yield put(actions.DoctorListDetailFailure(err));
  }
}

export function* watchAllDoctorDetailListSaga() {
  yield takeLatest(types.GET_DOCTOR_LIST_REQUEST, AllDoctorDetailListSaga);
}