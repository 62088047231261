import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/addDoctorAction";
import NetworkService from "../../../network/NetworkService";

export function* addDoctorSaga(action) {
  try {
    let response = yield call(NetworkService.addDoctor, action);
    
    yield put(actions.addDoctorSuccess(response));
  } catch (err) {
    yield put(actions.addDoctorFailure(err));
  }
}

export function* watchAddDoctorSaga() {
  yield takeLatest(types.ADD_DOCTORS_REQUEST, addDoctorSaga);
}