import * as types from "../../Config/actionType"

export const viewAgentDetailRequest = (id) => {
    return {
        type: types.VIEW_AGENT_DETAIL_REQUEST,
        id
    }
}

export const viewAgentDetailSuccess = (action) => {
    return {
        type: types.VIEW_AGENT_DETAIL_SUCCESS,
        action
    }
}

export const viewAgentDetailFailure = (action) => {
    return {
        type: types.VIEW_AGENT_DETAIL_FAILURE,
        action
    }
}