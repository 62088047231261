import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Clinic/NetworkClinicAction";
import NetworkService from "../../../../network/NetworkService";

export function* networkClinicSaga(action) {
   
  try {
    let response = yield call(NetworkService.networkClinic, action);
    yield put(actions.networkClinicSuccess(response));
  } catch (err) {
    yield put(actions.networkClinicFailure(err));
  }
}

export function* watchnetworkClinicSaga() {
  yield takeLatest(types.NETWORK_CLINIC_REQUEST, networkClinicSaga);
}
