import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { modalHeader } from "./ModalConstants";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { addBrandRequest } from "../../../redux/Actions/Product/AddBrandAction";
import { updateBrandRequest } from "../../../redux/Actions/Product/updateBrandAction";

function ModalUpdateBrand(props) {
  const dispatch = useDispatch();
  const handleClose = () => props.open(false);

  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Please enter name"),
  });
  const [nutritionalSwitchState, setNutritionalSwitchState] = useState(false);
  const [displayOnLandingPageSwitchState, setDisplayOnLandingPageSwitchState] =
    useState(false);

  const res = useSelector((state) => state.updateBrand);

  const [selectedImage, setSelectedImage] = useState(
    props?.value.data.data.data.data.filePath
  );
  const [sendImage, setSendImage] = useState(
    props?.value.data.data.data.data.filePath
  );
  const [error, setError] = useState("");

  const fileInputRef = useRef(null);

  const handleClearImage = () => {
    setSelectedImage(null);
  };
  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    if (file && allowedTypes.includes(file.type)) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      setSendImage(file);
      setError("");

      reader.readAsDataURL(file);
    } else {
      setError("Please upload a valid image file (jpg, jpeg, png, gif).");
    }
  };

  const [flag, setFlag] = useState(true);
  const [imageFlag, setImageFlag] = useState(false);

  useEffect(() => {
    if (res?.data || res?.error) {
      setFlag(true);
    }
  }, [res]);

  useEffect(() => {
    if (selectedImage) {
      setImageFlag(false);
    }
  }, [selectedImage]);

  const handleSubmit = (values, { setSubmitting }) => {
    const categoryData = {
      name: values.name,
    };

    if (selectedImage) {
      setFlag(false);

      const newFormData = new FormData();
      newFormData.append("brandRequest", JSON.stringify(categoryData));
      newFormData.append("brandImage", sendImage);
      dispatch(
        updateBrandRequest(newFormData, props?.value.data.data.data.data.id)
      );
    } else {
      setImageFlag(true);
    }
  };

  if (res.data?.data.status === 200) {
    Swal.fire({
      title: "Brand updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (res?.error) {
    Swal.fire({
      title: res?.error.data.response.data.errorMessage,
      icon: "error",
    }).then(() => {});
  }
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <div className="editProductMainBody2">
          <div className="editCategoryBody">
            <div className="modalHeader1">
              <div
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modalHeaderText"
                sx={modalHeader}
              >
                Update Brand
              </div>

              <button className="closeButtonModal" onClick={handleClose}>
                <Close />
              </button>
            </div>
            <Formik
              initialValues={{ name: props?.value.data.data.data.data.name }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {
                <Form className="editCategoryFormikBody">
                  <div className="editCategoryName">Brand Name</div>

                  <label style={{ width: "90%" }}>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Brand  Name"
                      className="editCategoryField"
                    />
                    <ErrorMessage
                      className="errorMessage"
                      name="name"
                      component="div"
                    />
                  </label>

                  <div className="editCategoryDescription">Image </div>
                  {imageFlag && (
                    <div className="errorMessage">Please select a image</div>
                  )}

                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    {selectedImage && (
                      <div>
                        <img
                          src={selectedImage}
                          alt="Selected"
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    {!selectedImage && (
                      <button
                        className="editButtonProduct"
                        onClick={handleEditClick}
                      >
                        Add Image
                      </button>
                    )}

                    <button
                      onClick={handleClearImage}
                      className="clearButtonProduct"
                    >
                      Clear{" "}
                    </button>
                  </div>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <div style={{ marginTop: "2em" }} className="buttonCreate">
                    <button
                      disabled={!flag}
                      className="editSubmitButtons"
                      type="submit"
                    >
                      {flag ? " Update" : <CircularProgress />}
                    </button>
                  </div>
                </Form>
              }
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalUpdateBrand;
