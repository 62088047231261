import * as types from "../../Config/actionType"

export const viewCorporateDetailRequest = (id) => {
    return {
        type: types.VIEW_CORPORATE_DETAIL_REQUEST,
        id
    }
}

export const viewCorporateDetailSuccess = (action) => {
    return {
        type: types.VIEW_CORPORATE_DETAIL_SUCCESS,
        action
    }
}

export const viewCorporateDetailFailure = (action) => {
    return {
        type: types.VIEW_CORPORATE_DETAIL_FAILURE,
        action
    }
}