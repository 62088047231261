import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlanDetailRequest } from '../../../redux/Actions/Plan/getPlanDetailAction';
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewPlanDetail.css";

function ViewPlanDetail() {
    const { uuid } = useParams();
    const dispatch = useDispatch();
    const [totalLimit, setTotalLimit] = useState();
    const [subLimit, setSubLimit] = useState();
    const navigate=useNavigate();
    useEffect(() => {

        let z={
            uuid:uuid,
            bool:true
        }
        dispatch(getPlanDetailRequest(z));
    }, [])

 
    const handleBackButton = () => {
        navigate("/ListOfPlan");
        window.location.reload();
      };

      window.addEventListener('popstate', handleBackButton);

    const getPlanDetail = useSelector((state) => state.getPlanDetail);

    useEffect(() => {
        getPlanDetail?.data?.data.data.data.services.map((item) => {
            if (item.serviceUuid === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
                item.attributeCountDtoList.map((ele) => {
                    setTotalLimit(`₹ ${ele.topLimit} /-`);
                    setSubLimit(`₹ ${ele.subLimit} /-`);
                })
            }
        })
    }, [getPlanDetail])

    return (
        <>
        
            <div className='ViewPlanDetailMain'>
                <div className='ViewPlanDetailHeader'>{getPlanDetail?.data?.data.data.data.name}</div>
                <div className='ViewPlanDetailInfo'>
                    <p className='nthChild1'>Brief Description</p>
                    <p className='nthChild2'>
                        {getPlanDetail?.data?.data.data.data.description}
                    </p>
                    <p className='nthChild3'>Pricing</p>
                    <div className='ViewPlanDetailPricing'>
                        <div className='ViewPlanDetailPricingLeft'>
                            <p>12 months</p>
                            <p>6 months</p>
                            <p>3 months</p>
                        </div>
                        <div className='ViewPlanDetailPricingRight'>
                            <p>₹ {getPlanDetail?.data?.data.data.data.yearlyPrice}/-</p>
                            <p>₹ {getPlanDetail?.data?.data.data.data.halfYearlyPrice}/-</p>
                            <p>₹ {getPlanDetail?.data?.data.data.data.quarterlyPrice}/-</p>
                        </div>
                    </div>
                    <p className='nthChild4'>Relationship</p>
                    <div className='ViewPlanDetailRelation'>
                        {getPlanDetail?.data?.data.data.data.relationsAllowed.map((item) => (
                            <div className='ViewPlanDetailRelationChild'>
                                {item}
                            </div>
                        ))}


                    </div>
                    {getPlanDetail?.data?.data.data.data.services.map((item) => {
                        if (item.serviceUuid !== "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
                            return (
                                <div className='ViewPlanDetailServices'>
                                    <div className='ViewPlanDetailServicesHeader'>
                                        <div className='ViewPlanDetailServicesHeaderHeading'>{item.serviceName}</div>
                                        <div className='ViewPlanDetailServicesHeaderInfo'>
                                            <div></div>
                                            <div>Assigned</div>
                                        </div>
                                    </div>
                                    <div className='ViewPlanDetailServicesBottom'>

                                        {item.attributeCountDtoList.map((ele) => (
                                            <div className='ViewPlanDetailServicesHeaderInfo'>
                                                <div>{ele.attributeName}</div>
                                                <div>{ele.attributeCount}</div>
                                            </div>

                                        ))}

                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="pharmacyServicePlanDetail">
                                    <div className="pharmacyService1Plan">
                                        Pharmacy
                                    </div>
                                    <div className="pharmacyService2">
                                        <div className="pharmacyService2Child1">
                                            <p>Total Limit</p>
                                            <input type="text" value={totalLimit} disabled={true} />
                                        </div>
                                        <div className="pharmacyService2Child1">
                                            <p>Sub Limit</p>
                                            <input type="text" value={subLimit} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="pharmacyService3">
                                    </div>
                                </div>
                            )
                        }
                    }

                    )}


                </div>
            </div>
            <Footer />

        </>
    )
}

export default ViewPlanDetail