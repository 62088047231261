import * as types from "../../Config/actionType"

export const rescheduleBookingRequest = (data,timeSlot) => {
    return {
        type: types.RESCHEDULE_BOOKING_REQUEST,
        data:data,
        timeSlot
    }
}

export const rescheduleBookingSuccess = (data) => {
    return {
        type: types.RESCHEDULE_BOOKING_SUCCESS,
        data:data,
    }
}

export const rescheduleBookingFailure = (data) => {
    return {
        type: types.RESCHEDULE_BOOKING_FAILURE,
        data:data,
    }
}