import React, { useEffect, useState } from "react";
import "./AddProgramNew.css";
import DiagnosticService from "./DiagnosticService.js";
import Services from "./ServicesNew.js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";

import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { viewAllServicesNameAndUuidRequest } from "../../../redux/Actions/Program Template/Services/viewAllServicesNameAndUuidAction";
import { viewAllPackageNamesRequest } from "../../../redux/Actions/Program Template/Package/viewAllPackageNamesAction";
import { addProgramTemplateRequest } from "../../../redux/Actions/Program Template/Program/addProgramTemplateAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import HRAService from "./HRAService.js";
import PharmacyService from "./PharmacyService";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
const loginSchema = Yup.object().shape({
  NumberChildren: Yup.string()
    .matches(phoneRegExp, "Should be in digits")
    .required("Required Field"),
  ProgramDescription: Yup.string().required("Required Field"),
  ProgramTitle: Yup.string().required("Required Field"),
});

const AddProgramTemplate = () => {
  const dispatch = useDispatch();
  const [servicesComponent, setServicesComponent] = useState([]);
  const [updatedServicesAttributeData, setUpdatedServicesAttributeData] =
    useState([]);
  const [servicesAttributeData, setServicesAttributeData] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState([]);
  const [children, setChildren] = useState(0);
  const ServiceApiDataArray = [];
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [zeroError, setZeroError] = useState();
  const [empty, setEmpty] = useState();
  const [emptyPharmacy, setEmptyPharmacy] = useState();

  function TopSubLimiterror(bool) {
    setError(bool);
  }
  function ZeroTopSubLimiterror(bool) {
    setZeroError(bool);
  }
  function EmptyTopSubLimiterror(bool) {
    setEmpty(bool);
  }
  function EmptyPharmacyTopSubLimiterror(bool) {
    setEmptyPharmacy(bool);
  }

  useEffect(() => {
    dispatch(viewAllServicesNameAndUuidRequest());
    dispatch(viewAllPackageNamesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewServiceNames = useSelector(
    (state) => state.viewAllServicesNameAndUuidReducer
  );
  viewServiceNames?.data?.data?.data?.map((item) =>
    ServiceApiDataArray.push(item)
  );
  const handleClose = (componentsServiceUid) => {
    setEmptyPharmacy(false);
    setEmpty(false);
    setZeroError(false);
    setError(false);
    let newServicesComponent = servicesComponent.filter(
      (item) => item.serviceUUID !== componentsServiceUid
    );
    
    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );

    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
    setServicesComponent(newServicesComponent);

  };

  const handleCloseAttribute = (componentsServiceUid) => {
    let newUpdatedServicesAttributeData = updatedServicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    let newServicesAttributeData = servicesAttributeData.filter(
      (item) => item.serviceUuid !== componentsServiceUid
    );
    setServicesAttributeData(newServicesAttributeData);
    setUpdatedServicesAttributeData(newUpdatedServicesAttributeData);
  };

  const getData_Title_Description = (titleInput, descriptionInput) => {
    setTitle(titleInput);
    setDescription(descriptionInput);
  };
  const handleServicesAttributeData = (servicesAttributeDataInput) => {
    if (servicesAttributeData.length === 0) {
      servicesAttributeData.push(servicesAttributeDataInput);
    } else {
      let boolT = servicesAttributeData.some((item) => {
        if (item.serviceUuid === servicesAttributeDataInput.serviceUuid) {
          return true;
        }
      });
      if (boolT === true) {
        servicesAttributeData.map((item, index) => {
          if (servicesAttributeDataInput.serviceUuid === item.serviceUuid) {
            servicesAttributeData.splice(index, 1);
            servicesAttributeData.push(servicesAttributeDataInput);
          }
        });
      } else {
        servicesAttributeData.push(servicesAttributeDataInput);
      }
    }

    servicesAttributeData.map((item) => {
      if (updatedServicesAttributeData.length === 0) {
        updatedServicesAttributeData.push(item);
      } else {
        let boolT = updatedServicesAttributeData.some((element) => {
          if (element.serviceUuid === item.serviceUuid) {
            return true;
          } else {
            return false;
          }
        });
        if (boolT === true) {
          updatedServicesAttributeData.map((individual, index) => {
            if (individual.serviceName === item.serviceName) {
              updatedServicesAttributeData.splice(index, 1);
              updatedServicesAttributeData.push(item);
              setUpdatedServicesAttributeData([
                ...updatedServicesAttributeData,
              ]);
            }
          });
        } else {
          updatedServicesAttributeData.push(item);
          setUpdatedServicesAttributeData([...updatedServicesAttributeData]);
        }
      }
    });
  };
  const updateRes = useSelector((state) => state.addProgramTemplateReducer)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = () => {
    const additionalServices = servicesComponent
      .filter((item) => item.attributeResponseDtoList.length === 0)
      .map((item) => item.serviceUUID);

    const filteredServicesAttributeData = [];

    updatedServicesAttributeData.forEach((item) => {
      if (item.serviceUuid !== "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter(
          (ele) => {
            return !isNaN(ele.attributeCount);
          }
        );

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      } else {
        const validAttributeCountDtoList = item.attributeCountDtoList.filter(
          (ele) => {
            return !isNaN(ele.subLimit) && !isNaN(ele.topLimit);
          }
        );

        if (validAttributeCountDtoList.length > 0) {
          filteredServicesAttributeData.push({
            ...item,
            attributeCountDtoList: validAttributeCountDtoList,
          });
        }
      }
    });
    let submitdata = {};
    submitdata = {
      title,
      description,
      filteredServicesAttributeData,
      children,
      relations,
      additionalServices,
    };

    if (title === "") {
      Swal.fire({
        title: "Please Add Program Title",
        icon: "error",
      });
    } else if (description.length === 0) {
      Swal.fire({
        title: "Please Add Program Description",
        icon: "error",
      });
    } else if (error === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    } else if (zeroError === true) {
      Swal.fire({
        title: "Please fill pharmacy details ",
        icon: "error",
      });
    }
    // else if(empty===true){
    //   Swal.fire({
    //     title: "Please fill pharmacy details ",
    //     icon: "error",
    //   });
    // }
    // else if(emptyPharmacy===true){
    //   Swal.fire({
    //     title: "Please fill pharmacy details ",
    //     icon: "error",
    //   });
    // }
    else if (
      (relations.includes("SON") || relations.includes("DAUGHTER")) &&
      submitdata.children === 0
    ) {
      Swal.fire({
        title: "Please fill Number of Children",
        icon: "error",
      });
    } else {
    setFlag(false)
      dispatch(addProgramTemplateRequest(submitdata));
    }
  };
  const addProgramTemplate = useSelector(
    (state) => state.addProgramTemplateReducer
  );

  const FormValidationfun = (initialValues) => {
    const errors = {};

    if (!initialValues.programTitle) {
      errors.programTitle = " *programTitle is Required!";
    }
    if (!initialValues.programDescription) {
      errors.programDescription = " programDescription is Required!";
    }

    return errors;
  };

  const [relations, setRelation] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setRelation(typeof value === "string" ? value.split(",") : value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const dropdown = [
    "FATHER",
    "MOTHER",
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "MOTHER_IN_LAW",
    "FATHER_IN_LAW",
  ];

  useEffect(() => {
    if (
      addProgramTemplate?.data?.action.status === 201 &&
      addProgramTemplate?.data?.action.data.message !== ""
    ) {
      Swal.fire({
        title: `${addProgramTemplate?.data?.action.data.message}`,
        icon: "success",
      }).then(() => {
        navigate("/program");
      });
    }
  }, [addProgramTemplate]);

  useEffect(() => {
    if (
      addProgramTemplate?.error?.action?.response?.status === 409 &&
      addProgramTemplate?.error?.action?.response?.data.errorMessage !== ""
    ) {
      Swal.fire({
        title: `${addProgramTemplate?.error?.action?.response?.data.errorMessage}`,
        icon: "error",
      });
    } else if (
      addProgramTemplate.error?.action.response.status === 400 &&
      addProgramTemplate.error?.action.response.data.errorMessage !== ""
    ) {
      Swal.fire({
        title: `${addProgramTemplate.error?.action.response.data.errorMessage}`,
        icon: "error",
      });
    }
  }, [addProgramTemplate]);
  return (
    <div className="template">
      <div className="templateTop">
        <span>Add Program Template</span>
      </div>
      <div className="templateBottom">
        <div className="templateBottomLeft">
          <Formik
            initialValues={{
              ProgramTitle: "",
              ProgramDescription: "",
              NumberChildren: "",
              selectRelation: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="inputFieldsContainer1">
                  <label>
                    <Field
                      as="Textarea"
                      rows="1"
                      cols="60"
                      name="ProgramTitle"
                      placeholder="Program Title"
                      className="textarea1"
                      onBlur={(e) => setTitle(e.target.value)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation1"
                      name="ProgramTitle"
                      component="div"
                    />
                  </label>
                  <label className="LabelDescription">
                    <Field
                      as="Textarea"
                      rows="2"
                      cols="60"
                      name="ProgramDescription"
                      placeholder="Program Description"
                      className="textarea2"
                      onBlur={(e) => setDescription(e.target.value)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation1"
                      name="ProgramDescription"
                      component="div"
                    />
                  </label>
                  <div className="formControlProgram">
                    <FormControl
                      sx={{ mL: 1, marginTop: "1.5em", width: "80%" }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label ">
                        Select Relation
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={relations}
                        onChange={handleChange}
                        input={<OutlinedInput label=" Select relations" />}
                        onWheel={(event) => event.currentTarget.blur()}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {dropdown.map((dropdown) => (
                          <MenuItem key={dropdown} value={dropdown}>
                            <Checkbox
                              checked={relations.indexOf(dropdown) > -1}
                            />
                            <ListItemText primary={dropdown} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  &nbsp;
                  {(relations.includes("SON") ||
                    relations.includes("DAUGHTER")) && (
                    <div>
                      <Field
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                        name="NumberChildren"
                        placeholder="Total number of children"
                        type="number"
                        className="textarea1"
                        onBlur={(e) => setChildren(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>

          {servicesComponent.map((item) => {
            if (item.serviceUUID === "ee5413dd-eb09-4a99-92d0-a4fc6d92a5e9") {
              return (
                <DiagnosticService
                  servicesInfo={item}
                  handleClose={handleClose}
                  key={item.serviceUUID}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                />
              );
            }
            if (item.serviceUUID === "1dbcc55e-3dec-4e07-8c2a-e222631afebb") {
              return (
                <HRAService
                  servicesInfo={item}
                  handleClose={handleClose}
                  key={item.serviceUUID}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                />
              );
            }
            if (item.serviceUUID === "b5aaaf86-e1f3-4acb-97a2-d5198ee4e7bb") {
              return (
                <>
                  <PharmacyService
                    servicesInfo={item}
                    handleClose={handleClose}
                  key={item.serviceUUID}
                    handleServicesAttributeData={handleServicesAttributeData}
                    handleCloseAttribute={handleCloseAttribute}
                    TopSubLimiterror={TopSubLimiterror}
                    ZeroTopSubLimiterror={ZeroTopSubLimiterror}
                    EmptyTopSubLimiterror={EmptyTopSubLimiterror}
                    EmptyPharmacyTopSubLimiterror={
                      EmptyPharmacyTopSubLimiterror
                    }
                  />

                  {zeroError ? (
                    <p
                      style={{
                        color: "red",
                        marginLeft: "3.81em",
                        marginTop: "-2em",
                      }}
                    >
                      Total Limit/Sub Limit cannot be negative/zero
                    </p>
                  ) : (
                    ""
                  )}
                  {/* {empty ? <p style={{ color: "red", marginLeft: "3.81em", marginTop: "-2em", paddingTop: "1em" }}>Total Limit/Sub Limit cannot be null/Empty</p> : ""} */}
                  {error ? (
                    <p
                      style={{
                        color: "red",
                        marginLeft: "3.81em",
                        marginTop: "-2em",
                        paddingTop: "1em",
                      }}
                    >
                      Total Limit has to greater than Sub Limit
                    </p>
                  ) : (
                    ""
                  )}
                </>
              );
            } else {
              return (
                <Services
                  servicesInfo={item}
                  key={item.serviceUUID}
                  handleClose={handleClose}
                  handleServicesAttributeData={handleServicesAttributeData}
                  handleCloseAttribute={handleCloseAttribute}
                  servicesAttributeData={servicesAttributeData}
                />
              );
            }
          })}
        </div>

        <div className="templateBottomRight">
          <div className="addServicesHeading">
            <span>Add Services</span>
          </div>
          <hr className="addServicesSeperator" />
          <div className="allServices">
            {ServiceApiDataArray.map((item) => {
              return (
                <div className="servicesList">
                  <button
                    className="serviceListButton"
                    onClick={() => {
                      if (
                        servicesComponent.length === 0 ||
                        servicesComponent.length >= 0
                      ) {
                        let boo = servicesComponent.some((element) => {
                          if (element.serviceUUID === item.serviceUuid) {
                            return true;
                          } else {
                            return false;
                          }
                        });
                        if (!boo) {
                          setServicesComponent([
                            ...servicesComponent,
                            {
                              name: item.name,
                              serviceUUID: item.serviceUuid,
                              attributeResponseDtoList:
                                item.attributeResponseDtoList,
                            },
                          ]);
                        }
                      }
                    }}
                  >
                    +
                  </button>
                  <span className="mainTemplate-bottom-right-showServices-servicesFont">
                    {item.name}
                  </span>
                </div>
              );
            })}
          </div>

          <button
            type="submit"
            className="addTemplateSubmit"
            onClick={handleSubmit}
            disabled={!flag}
          >
            {flag ? "      Save Program" : <CircularProgress />}
          </button>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};
export default AddProgramTemplate;
