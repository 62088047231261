import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetUserIndividualRequest } from "../../../redux/Actions/Users/GetUserIndividualAction";
import { GetUserPlanRequest } from "../../../redux/Actions/Users/UserPlanDetailsAction";
import { GetUserProgramRequest } from "../../../redux/Actions/Users/UserProgramDetailsAction";
import Footer from "../../Organisms/Footer/Footer";
import FamilyDetailsList from "./FamilyDetailsList";
import PlanDetailsList from "./PlanDetailsList";
import ProgramDetailsList from "./ProgramDetailsList";
import "./ViewUser.css";

function ViewUser() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserIndividualRequest(id));
    dispatch(GetUserPlanRequest(id));
    dispatch(GetUserProgramRequest(id));
  }, []);

  const GetUserIndividual = useSelector((state) => state.GetUserIndividual);
  const GetUserPlan = useSelector((state) => state.GetUserPlan);
  const GetUserProgram = useSelector((state) => state.GetUserProgram);

  return (
    <>
      <div className="ViewUserMain">
        <h1>User Detail</h1>

        <div className="ViewUserWrapper">
          <div className="ViewUserPart1">
            <div className="ViewUserPart1Section1">
              <div className="ViewUserPart1Section1Header">
                {GetUserIndividual?.data?.data?.data?.data?.name}
              </div>
              <div className="ViewUserPart1Section1Select">
                {GetUserIndividual?.data?.data?.data?.data?.userRoles.length > 1
                  ? GetUserIndividual?.data?.data?.data?.data?.userRoles.map(
                      (itm, index) =>
                        itm +
                        (index + 1 <
                        GetUserIndividual?.data?.data?.data?.data?.userRoles
                          .length
                          ? ", "
                          : " ")
                    )
                  : GetUserIndividual?.data?.data?.data?.data?.userRoles.map(
                      (itm, ind) => itm
                    )}
              </div>
            </div>
            <hr className="ViewUserSepertaor" />
            <div className="ViewUserPart1Section2">
              <div className="ViewUserPart1Section2Details">
                <h3>Contact Number</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.number === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.number}
                </p>
              </div>

              <div className="ViewUserPart1Section2Details">
                <h3>Email</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.email === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.email}
                </p>
              </div>

              <div className="ViewUserPart1Section2Details">
                <h3>City</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.city === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.city}
                </p>
              </div>

              <div className="ViewUserPart1Section2Details">
                <h3> Pincode</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.pinCode === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.pinCode}
                </p>
              </div>

              <div className="ViewUserPart1Section2Details">
                <h3>Corporate Name</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.corporateName ===
                  null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.corporateName}
                </p>
              </div>

              <div className="ViewUserPart1Section2Details">
                <h3> Address</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.address === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.address}
                </p>
              </div>
              <div className="ViewUserPart1Section2Details">
                <h3> Age</h3>
                <p>
                  {GetUserIndividual?.data?.data?.data?.data?.age === null
                    ? "---"
                    : GetUserIndividual?.data?.data?.data?.data?.age}
                </p>
              </div>
            </div>
          </div>
          <hr />

          <div className="ViewUserPart2">
            <div className="ViewUserPart1Section1Header2">Program Details</div>
            {GetUserProgram?.data?.data?.data?.data.totalRecords === 0 ? (
              <div className="ViewUserPart3NoMessage">No Program</div>
            ) : (
              <ProgramDetailsList
                data={
                  GetUserProgram?.data?.data?.data?.data
                    .userProgramDetailsResponseDto
                }
              />
            )}
          </div>
          <hr />

          <div className="ViewUserPart3">
            <div className="ViewUserPart1Section1Header2">Plan Details</div>
            {GetUserPlan?.data?.data?.data?.data.totalRecords === 0 ? (
              <div className="ViewUserPart3NoMessage">No Plan</div>
            ) : (
              <PlanDetailsList
                data={
                  GetUserPlan?.data?.data?.data?.data.userPlanDetailsResponseDto
                }
                userId={id}
              />
            )}
          </div>
          <hr />

          <div className="ViewUserPart4">
            <div className="ViewUserPart1Section1Header2">Family Details</div>
            {GetUserIndividual?.data?.data?.data?.data?.userRelatives.length ===
            0 ? (
              <div className="ViewUserPart3NoMessage">No Family Members</div>
            ) : (
              <FamilyDetailsList
                data={GetUserIndividual?.data?.data?.data?.data?.userRelatives}
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewUser;
