import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import "./Agents.css";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { viewAllAgentsRequest } from "../../../redux/Actions/Agents/viewAllAgentAction";
import { useNavigate } from "react-router-dom";
import ModalAddInsuranceAgent from "../../Organisms/Modal/ModalAddInsuranceAgent";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import ModalUpdateInsuranceAgent from "../../Organisms/Modal/ModalUpdateInsuranceAgent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { getUpdateInsurerRequest } from "../../../redux/Actions/Agents/getUpdateInsurerAction";
import storage from "../../../Utils/storage";
import DisableModal from "../../Organisms/Modal/DisableModal";
import ActiveDropdown from "../../Organisms/ActiveDropdown/ActiveDropdown";

function Agents() {
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [delId, setDelId] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const [status, setStatus] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (city === null) {
      dispatch(viewAllAgentsRequest(page, pageSize, searchText, "", status));
    } else {
      dispatch(viewAllAgentsRequest(page, pageSize, searchText, city, status));
    }
    dispatch(viewAllCityRequest());
  }, [city]);

  const viewAgent = useSelector((state) => state.viewAllAgents);
  const viewCity = useSelector((state) => state.viewAllCity);
  const deleteAgent = useSelector((state) => state.deleteAgent);
  const getUpdateInsurerData = useSelector(
    (state) => state.getUpdateInsurer.data
  );

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleDelete = (id) => {
    setDelId(id);
    setOpen(true);
  };

  const handleClick = (id) => {
    navigate(`/viewAgentDetails/${id}`);
  };
  const handleAdd = () => {
    setAdd(true);
  };

  const [editId, setEditId] = useState();
  const handleEdit = (value) => {
    // //
    setEditId(value.id);
    dispatch(getUpdateInsurerRequest(value?.id));
    setValues(value);
    setEdit(1);
  };

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(viewAllAgentsRequest(page, pageSize, newSearchText, city, status));
      setPage(1)
    } else {
      dispatch(
        viewAllAgentsRequest(searchPage, pageSize, newSearchText, city, status)
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(viewAllAgentsRequest(value, pageSize, searchText, city, status));
      setPage(value);
    } else {
      dispatch(
        viewAllAgentsRequest(value, pageSize, searchText, city, status)
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(viewAllAgentsRequest(page, e.target.value, searchText, city, status));
    } else {
      dispatch(
        viewAllAgentsRequest(searchPage, e.target.value, searchText, city, status)
      );
    }
    setPageSize(e.target.value);
  };
  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };
  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(viewAllAgentsRequest(page, pageSize, searchText, city, e.target.value));
    } else {
      dispatch(
        viewAllAgentsRequest(searchPage, pageSize, searchText, city, e.target.value)
      );
    }
    setStatus(e.target.value);
  };

  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 40,
      flex: 0.5,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "number",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      minWidth: 210,
      disableColumnMenu: true,
      sortable: false,
      flex: 1.2,
    },
    {
      field: "companyName",
      headerClassName: "super-app-theme--header",
      headerName: "Insurer",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 180,
      flex: 1,
    },
    {
      field: "Modify",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
            {viewAgent.data?.data?.data?.insurerResponseDtoList[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <IconButton sx={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EditIcon
                  sx={{ color: "gray" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
           
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {viewAgent.data?.data?.data?.insurerResponseDtoList[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <div>
                <Switch
                  disabled
                  sx={{ color: "#52608E" }}
                />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];
  
  return (
    <div>
      {/* <DisableModal/> */}
      <div className="grid">
        <h4 className="h4">Insurers</h4>
        <div className="mainBox">
          {open && <DisableModal open={setOpen} delId={delId} />}
          {edit && getUpdateInsurerData && (
              <ModalUpdateInsuranceAgent
                value={getUpdateInsurerData}
                id={editId}
                open={setEdit}
              />
            )}
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Insurers</h5>
              <div className="agentcount">
                {viewAgent.data?.data.data.totalInsuranceAgents} Insurers
              </div>
            </div>
            <div className="rightheadbtn">
              <div type="button" className="btn" onClick={() => handleAdd()}>
                + Add Insurer
              </div>
            </div>
            {add && <ModalAddInsuranceAgent open={setAdd} />}
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities" 
                cityList={cityNames}
              />
              <ActiveDropdown onChange={(e) => handleStatus(e)} />
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #52608E",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
                <button
                  type="submit"
                  value="Submit"
                  className="agentsearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                  }}
                >
                  <FilterAltIcon fontSize="small" />
                  Filter
                </button>
              </div> */}
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewAgent.data?.data.data.insurerResponseDtoList && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rowCount={viewAgent.data?.data.data.totalInsuranceAgents}
                rows={viewAgent.data?.data.data.insurerResponseDtoList}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                // onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>
          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={viewAgent.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Agents;
