import * as types from "../../Config/actionType"

export const getMyTestsAndPackageRequest = (data) => {
   
    return {
        type: types.GET_MY_TESTS_AND_PACKAGE_REQUEST,
        data:data,
            
    }
}

export const getMyTestsAndPackageSuccess = (action) => {
   
    return {
        type: types.GET_MY_TESTS_AND_PACKAGE_SUCCESS,
        action
    }
}

export const getMyTestsAndPackageFailure = (action) => {
   
    return {
        type: types.GET_MY_TESTS_AND_PACKAGE_FAILURE,
        action
    }
}