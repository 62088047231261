import * as types from "../../Config/actionType"

export const getInsurerAssignedProgramsRequest = (data) => {
    return {
        type: types.GET_INSURER_ASSIGNED_PROGRAMS_REQUEST,
        data:data
    }
}

export const getInsurerAssignedProgramsSuccess = (action) => {
    return {
        type: types.GET_INSURER_ASSIGNED_PROGRAMS_SUCCESS,
        action
    }
}

export const getInsurerAssignedProgramsFailure = (action) => {
    return {
        type: types.GET_INSURER_ASSIGNED_PROGRAMS_FAILURE,
        action
    }
}