import { combineReducers } from "redux";
import { networkDiagnosticReducer } from "./Diagnostic/NetworkDiagnosticReducer";
import { networkViewDiagnosticReducer } from "./Diagnostic/NetworkViewDiagnosticReducer";
import { networkHospitalReducer } from "./Hospital/NetworkHospitalReducer";
import { networkViewHospitalReducer } from "./Hospital/NetworkViewHospitalReducer";
import { networkViewClinicReducer } from "./Clinic/NetworkClinicViewReducer";
import { networkClinicReducer } from "./Clinic/NetworkClinicReducer";
export default combineReducers({
    networkHospital: networkHospitalReducer,
    networkViewHospital : networkViewHospitalReducer,
    networkDiagnostic: networkDiagnosticReducer,
    networkViewDiagnostic: networkViewDiagnosticReducer,
    networkViewClinic:networkViewClinicReducer,
    networkClinic:networkClinicReducer,
})