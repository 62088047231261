import * as types from "../../Config/Banner/BannerActionType"

export const getItemTypeRequest = () => {
    return {
        type: types.GET_ITEM_TYPE_REQUEST,
    }
}

export const getItemTypeSuccess = (data) => {
    return {
        type: types.GET_ITEM_TYPE_SUCCESS,
        data:data
    }
}

export const getItemTypeFailure = (data) => {
    return {
        type: types.GET_ITEM_TYPE_FAILURE,
        data:data
    }
}