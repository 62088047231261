import * as types from "../../Config/actionType"

export const deleteDoctorRequest = (id,data,file) => {
    return {
        type: types.DELETE_DOCTOR_REQUEST,
        id:id,
        data:data,
        file:file
    }
}

export const deleteDoctorSuccess = (action) => {
    return {
        type: types.DELETE_DOCTOR_SUCCESS,
        action
    }
}

export const deleteDoctorFailure = (action) => {
    return {
        type: types.DELETE_DOCTOR_FAILURE,
        action
    }
}