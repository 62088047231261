import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { networkViewHospitalRequest } from "../../../../redux/Actions/Network/Hospital/NetworkViewHospitalAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./hospitalRequest.css";
import { approveHospitalRequest } from "../../../../redux/Actions/Hospitals/approveHospitalAction";
import swal from "sweetalert";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { planTypeDropdownRequest } from "../../../../redux/Actions/Plan/PlanTypeDropDown";
import Loader from "../../../../assets/loader.gif";


function ViewHospitalRequest() {
  const { id } = useParams();
  const [selectType, setSelectType] = React.useState("");
  const [selectPrescriptionType, setSelectPrescriptionType] = React.useState(true);
  const [location, setLocation] = React.useState("");
  const [error, setError] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networkViewHospitalRequest(id));
  }, []);

  useEffect(() => {
    dispatch(planTypeDropdownRequest());
  }, []);
  const networkViewHospital = useSelector(
    (state) => state.network.networkViewHospital
  );
  const approveHospital = useSelector((state) => state.approveHospital);
  const planTypeDropdown = useSelector((state) => state.planTypeDropdown);

  const handleChange = (event) => {
    setSelectType(event.target.value);
  };

  const handlePrescriptionChange = (event) => {
    setSelectPrescriptionType(event.target.value);
  }

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleApprove = (status) => {
    if (
      (selectType.length === 0 || location.length === 0) &&
      status === "APPROVED"
    ) {
      setError(true);
    } else {
      setTimeout(() => {
        dispatch(approveHospitalRequest(id, status, selectType, location, selectPrescriptionType));
      });
    }
  };

  if (approveHospital?.data?.status === 201) {
    swal({
      title: approveHospital?.data?.data?.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (approveHospital.error !== null) {
    swal({
      title: approveHospital?.error?.action.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      {approveHospital.isLoading ?
        <>
          <div className="corporateDashboardLoading">
            <img alt="Loader" src={Loader} className="imgLoader" />
          </div>
        </> :
        <>
          <div className="requestPage">
            <h3 className="requestHead">
              View Requests{" "}
              {networkViewHospital.data?.data.data.data.networkStatus ===
                "APPROVED" && <div className="approvedTile">APPROVED</div>}
              {networkViewHospital.data?.data.data.data.networkStatus ===
                "DECLINED" && <div className="approvedTileC">DECLINED</div>}
              {networkViewHospital.data?.data.data.data.networkStatus ===
                "PENDING" && <div className="approvedTileP">PENDING</div>}
            </h3>
            <div className="requestBox">
              <div className="requestTextHospital2">
                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Email</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.email}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.name}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Entity</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.entityType}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name of the Owner:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.ownerName}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.address}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Location:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.location}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">City:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.city}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">PIN:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.pinCode}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Contact Number:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.contactNumber}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Speciality:</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.speciality}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">List of Doctor</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.doctorsListName}{" "}
                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewHospital.data?.data.data.data.doctorsList
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Rate List</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.uploadRateListName}

                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewHospital.data?.data.data.data.uploadRateList
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Hospital Photographs</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.photoName}

                    <VisibilityIcon
                      onClick={() =>
                        handleView(networkViewHospital.data?.data.data.data.photo)
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">List of Outsource Service</h1>
                  <h1 className="requestPageData">
                    {networkViewHospital.data?.data.data.data.outSourceServices === "" ? "-" : networkViewHospital.data?.data.data.data.outSourceServices}
                  </h1>
                </div>

                {networkViewHospital.data?.data.data.data.networkStatus ===
                  "PENDING" && (
                    <>
                      <div className="requestPageInfowrapper">
                        <h1 className="requestPageInfo">Select Type</h1>
                        <select
                          as="Select"
                          value={selectType}
                          label="Select Type"
                          onChange={handleChange}
                          className="SelectTypeHospital"
                        >
                          <option value="" disabled selected>
                            Select Type
                          </option>
                          {planTypeDropdown?.data?.data?.data?.data.map(
                            (item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>

                        {error && selectType.length <= 0 ? (
                          <label className="SelectTypeUrlValidation">
                            Please Enter The Type*
                          </label>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="requestPageInfowrapper">
                        <h1 className="requestPageInfo">Location URL</h1>
                        <div className="LocationIconMui">
                          {" "}
                          <LocationOnIcon />{" "}
                        </div>
                        <input
                          value={location}
                          onChange={handleChangeLocation}
                          placeholder="Enter Location Url"
                          className="LocationInputField"
                        ></input>

                        {error && location.length <= 0 ? (
                          <label className="SelectTypeUrlValidation">
                            Please Enter The Location URL*
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="requestPageInfowrapper">
                        <h1 className="requestPageInfo">Create Prescription</h1>

                        <select className="SelectTypeHospital" onChange={handlePrescriptionChange}>
                          <option value={true} selected>
                            Yes
                          </option>
                          <option value={false}>
                            No
                          </option>
                        </select>
                      </div>
                    </>
                  )}
              </div>

              {networkViewHospital.data?.data.data.data.networkStatus ===
                "PENDING" && (
                  <div className="requestButton">
                    <button
                      className="requestApprove"
                      onClick={() => {
                        handleApprove("APPROVED");
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="requestDecline"
                      onClick={() => {
                        handleApprove("DECLINED");
                      }}
                    >
                      Decline
                    </button>
                  </div>
                )}
            </div>
          </div>
        </>}

    </>

  );
}

export default ViewHospitalRequest;
