import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Coupon/AddCouponAction"
import NetworkService from "../../../network/NetworkService";

export function* AddCouponSaga(action) {
  try {
    let response = yield call(NetworkService.AddCoupon, action);
    yield put(actions.AddCouponSuccess(response));
  } catch (err) {
    yield put(actions.AddCouponFailure(err));
  }
}

export function* watchAddCouponSaga() {
  yield takeLatest(types.ADD_COUPON_REQUEST, AddCouponSaga);
}