import { Pagination } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAllLotsIdsRequest } from "../../../redux/Actions/Payment/getAllLotsIdsAction";
import { getLotByIdActionRequest } from "../../../redux/Actions/Payment/getLotByIdAction";
import ModalUpdatePharmacyPayment from "../../Organisms/Modal/ModalUpdatePharmacyPayment";

function UpdatingPaymentStatus() {
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [add, setAdd] = React.useState(null);
  const [data, setData] = React.useState({ id: 0, lotid: 0 });
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const lotType = "PRESCRIPTION";
  useEffect(() => {
    dispatch(getLotByIdActionRequest(id, page, pageSize, lotType));
  }, [id, page, pageSize]);

  const getLotById = useSelector((state) => state.getLotById);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleDropChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleUpdateLot = (id, lotId) => {
    setData({ pharmacyId: id, lotid: lotId });
    setAdd(true);
  };

  function handleChange(event) {
    navigate(`/UpdatingPaymentStatus/${event.target.value}`);
  }

  useEffect(() => {
    dispatch(getAllLotsIdsRequest(lotType));
  }, []);
  const getAllLotsIds = useSelector((state) => state.getAllLotsIds);
  
  const columns = [
    {
      field: "pharmacyName",
      headerClassName: "super-app-theme--header",
      headerName: "Pharmacy Name",
      minWidth: 180,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pharmacyAddress",
      headerClassName: "super-app-theme--header",
      headerName: "Location",
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lotId",
      headerClassName: "super-app-theme--header",
      headerName: "Lot",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalPrescription",
      headerClassName: "super-app-theme--header",
      headerName: "Total Prescription",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unPaidAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      minWidth: 140,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
    },

    {
      field: "Status",
      headerClassName: "super-app-theme--header",
      headerName: "Update",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      fontFamily: "Nunito",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <button
              className="LotStatusUpdateButton"
              onClick={() => {
                handleUpdateLot(cellValues.row["id"], cellValues.row["lotId"]);
              }}
            >
              Update
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="LotStatusPageWrapper">
      <div className="LotStatusHeader">
        <h1 className="LotStatusHeaderText">Lot</h1>
      </div>
      <div className="LotStatusTable">
        <div className="LotStatusTableRow1">
          <div className="LotStatusTableRow1Part1">
            <div className="LotStatusTableRow1Part1Header">
              List of Payments 
            </div>
            <div className="LotStatusTableRow1Part1NumberCard">
              {getLotById.data?.data.data.data.totalLot} Pharmacy
            </div>
          </div>
          <div className="LotStatusTableRow1Part2">
            <div style={{ minWidth: "10vw" }}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  className="select_label"
                >
                  <span>Select Lot</span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={id}
                  label="Age"
                  // defaultValue={id}
                  onChange={handleChange}
                >
                  {getAllLotsIds?.data?.data.data.data.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {add && (
              <ModalUpdatePharmacyPayment
                open={setAdd}
                data={data}
                page={page}
                pageSize={pageSize}
              />
            )}
          </div>
        </div>

        <hr className="LineSeperator" />

        <Box sx={{ height: 600, width: "100%" }}>
          {getLotById.data?.data.data.data.lotResponseDtoList && (
            <DataGrid
              sx={{
                color: "#1D2334",
                "& .super-app-theme--header": {
                  backgroundColor: "#52608E",
                  color: "#F2EFEA",
                  fontFamily: "Nunito",
                  fontWeight: 600,
                  "& > .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                },
                fontFamily: "Nunito",
                "&:hover:not(.Mui-disabled)": {
                  cursor: "pointer",
                },
                ".MuiTablePagination-toolbar": {
                  alignItems: "baseline",
                  marginTop: "5%",
                },
              }}
              justify="center"
              rowCount={getLotById.data?.data.data.data.totalLot}
              pagination
              disableColumnMenu
              rows={getLotById.data?.data.data.data.lotResponseDtoList}
              columns={columns}
              getRowId={(row) => row.id}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 50, 100]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              components={{
                Footer: "none",
              }}
            />
          )}
        </Box>
        <div className="MainTablePagination">
          <p>Rows Per Page:</p>
          <select
            className="dropDown"
            defaultValue={10}
            onChange={handleDropChange}
          >
            <option value={10}>10</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <Pagination
            count={getLotById.data?.data.data.data.totalPages}
            color="primary"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default UpdatingPaymentStatus;
