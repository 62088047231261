import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getSubCategoryDropdownAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getSubCategoryDropdownSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getSubCategoryDropdown, action);
   
    yield put(actions.getSubCategoryDropdownSuccess(response));
  } catch (err) {
   
    yield put(actions.getSubCategoryDropdownFailure(err));
  }
}

export function* watchGetSubCategoryDropdownSaga() {
 
  yield takeLatest(types.GET_SUB_CATEGORY_DROPDOWN_REQUEST, getSubCategoryDropdownSaga);
}
