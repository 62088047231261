import { all } from "redux-saga/effects";
import { watchViewAllAgents } from "../Saga/Agents/viewAllAgentsSaga";
import { watchAddDoctorSaga } from "../Saga/Doctors/addDoctorSaga";
import { watchViewAllDoctors } from "../Saga/Doctors/viewAllDoctorsSaga";
import { watchAddHospitalSaga } from "../Saga/Hospitals/addHospitalSaga";
import { watchViewAllHospitals } from "../Saga/Hospitals/viewAllHospitalsSaga";
import { watchLoginSaga } from "../Saga/Login/loginSaga";
import { watchAddInsuranceAgentSaga } from "./Agents/addInsuranceAgentSaga";
import { watchCheckEmailAgentSaga } from "./Agents/checkEmailAgentSaga";
import { watchDeleteAgent } from "./Agents/deleteAgentSaga";
import { watchGetInsurerAssignedProgramsSaga } from "./Agents/getInsurerAssignedProgramsSaga";
import { watchGetUpdateInsurerSaga } from "./Agents/getUpdateInsurerSaga";
import { watchUpdateInsuranceAgentSaga } from "./Agents/updateInsuranceAgentSaga";
import { watchViewAgentDetail } from "./Agents/viewAgentsDetailSaga";
import { watchViewAllProgramOfInsurerSaga } from "./Agents/viewAllProgramOfInsurer";
import { watchAddAppointmentSaga } from "./Appointments/addAppointmentSaga";
import { watchallCityAppointmentSaga } from "./Appointments/allCityAppointmentSaga";
import { watchCancelAppointmentSaga } from "./Appointments/cancelAppointmentSaga";
import { watchConfirmAppointmentSaga } from "./Appointments/confirmAppointmentSaga";
import { watchDeleteAppointmentSaga } from "./Appointments/deleteAppointmentSaga";
import { watchDownloadPrescriptionSaga } from "./Appointments/downloadPrescriptionSaga";
import { watchFinishAppointmentSaga } from "./Appointments/finishAppointmentSaga";
import { watchProgramAndPlanSaga } from "./Appointments/programAndPlan";
import { watchrelationshipSaga } from "./Appointments/relationship";
import { watchRescheduleAppointmentSaga } from "./Appointments/rescheduleAppointmentSaga";
import { watchUpdateAppointmentSaga } from "./Appointments/updateAppointmentSaga";
import { watchUploadReportAppointments } from "./Appointments/uploadReportAppointmentsSaga";
import { watchUserDetailSaga } from "./Appointments/userDetail";
import { watchValidateOtpSaga } from "./Appointments/validateOtpSaga";
import { watchViewAllAppointments } from "./Appointments/viewAllAppointmentsSaga";
import { watchViewAppointments } from "./Appointments/viewAppointmentSaga";
import { watchAddBookingSaga } from "./Booking/AddBookingSaga";
import { watchAssignLabSaga } from "./Booking/AssignLabSaga";
import { watchCancelBookingSaga } from "./Booking/cancelBookingSaga";
import { watchCompleteBookingSaga } from "./Booking/completeBookingSaga";
import { watchConfirmBookingSaga } from "./Booking/confirmBookingSaga";
import { watchDeleteAttachmentSaga } from "./Booking/deleteAttachmentSaga";
import { watchDownloadAttachmentSaga } from "./Booking/downloadAttachmentSaga";
import { watchFinishBookingSaga } from "./Booking/finishBookingSaga";
import { watchMakePackage } from "./Booking/makePackageSaga";
import { watchRescheduleBookingSaga } from "./Booking/rescheduleBookingSaga";
import { watchUploadReport } from "./Booking/uploadReportSaga";
import { watchViewAllBookingSaga } from "./Booking/viewAllBookingSaga";
import { watchviewAllLabsNamesSaga } from "./Booking/viewAllLabsNamesSaga";
import { watchViewAllTestsName } from "./Booking/viewAllTestsNameSaga";
import { watchViewBooking } from "./Booking/viewBookingSaga";
import { watchViewAllCity } from "./City/viewAllCitySaga";
import { watchAddCorporateSaga } from "./Corporate/addCorporateSaga";
import { watchAssignProgramToEmployeeSaga } from "./Corporate/assignProgramToEmployeeSaga";
import { watchDeleteCorporate } from "./Corporate/deleteCorporateSaga";
import { watchcorporateGetAssignProgramSaga } from "./Corporate/getAssignProgramSaga";
import { watchGetUpdateCorporateSaga } from "./Corporate/getUpdateCorporateSaga";
import { watchUpdateCorporateSaga } from "./Corporate/updateCorporateSaga";
import { watchViewAllCorporate } from "./Corporate/viewAllCorporateSaga";
import { watchcorporateViewAssignProgramSaga } from "./Corporate/viewAssignProgramSaga";
import { watchViewCorporateDetail } from "./Corporate/viewCorporateDetailSaga";
import { watchCorporateDashboardSaga } from "./CorporateDashboard/CorporateDashboardSaga";
import { watchAddCouponSaga } from "./Coupon/AddCouponSaga";
import { watchDeleteCouponSaga } from "./Coupon/DeleteCouponSaga";
import { watchEditCouponSaga } from "./Coupon/EditCouponSaga";
import { watchGetAllCouponSaga } from "./Coupon/GetAllCouponSaga";
import { watchGetCouponDetailsSaga } from "./Coupon/GetCouponDetailsSaga";
import { watchAddDiscountGroupSaga } from "./Discount/AddDiscountGroupSaga";
import { watchAddDiscountIndividualSaga } from "./Discount/AddDiscountIndividualSaga";
import { watchEditDiscountSaga } from "./Discount/EditDiscountSaga";
import { watchGetAllDiscountSaga } from "./Discount/GetAllDiscountSaga";
import { watchViewDiscountSaga } from "./Discount/ViewDiscountSaga";
import { watchDeleteDiscountSaga } from "./Discount/deleteDiscountSaga";
import { watchAddReportSaga } from "./Doctors/addReportSaga";
import { watchDeleteDoctor } from "./Doctors/deleteDoctorSaga";
import { watchDeleteReportSaga } from "./Doctors/deleteReportSaga";
import { watchEndAppointment } from "./Doctors/endAppointmentSaga";
import { watchSearchDoctorSaga } from "./Doctors/searchDoctorSaga";
import { watchUpdateDoctorSaga } from "./Doctors/updateDoctorSaga";
import { watchAddEmployeeFileSaga } from "./Employee/addEmployeeFileSaga";
import { watchAddEmployeeSaga } from "./Employee/addEmployeeSaga";
import { watchDeleteEmployeeSaga } from "./Employee/deleteEmployeeSaga";
import { watchFileTemplateFileSaga } from "./Employee/fileTemplateSaga";
import { watchGetEmployeeProgramSaga } from "./Employee/getEmployeeProgramsSaga";
import { watchGetMyTestsAndPackageSaga } from "./Employee/getMyTestsAndPackageSaga";
import { watchGetRelationSaga } from "./Employee/getRelationSaga";
import { watchViewAllEmployeeProgramSaga } from "./Employee/viewAllEmployeeProgramSaga";
import { watchViewAllEmployee } from "./Employee/viewAllEmployeeSaga";
import { watchViewEmployeeDetailSaga } from "./Employee/viewEmployeeDetailSaga";
import { watchApproveHospitals } from "./Hospitals/approveHospitalSaga";
import { watchDeleteHospitals } from "./Hospitals/deleteHospitalSaga";
import { watchGetUpdateHospitalSaga } from "./Hospitals/getUpdateHospitalSaga";
import { watchUpdateHospitalSaga } from "./Hospitals/updateHospitalSaga";
import { watchViewAllHospitalNames } from "./Hospitals/viewAllHospitalNamesSaga";
import { watchViewHospitalDetail } from "./Hospitals/viewHospitalDetailSaga";
import { watchAcceptOrRejectRequestSaga } from "./InHouseDoctor/AcceptOrRejectRequestSaga";
import { watchChatStatusSaga } from "./InHouseDoctor/ChatStatusSaga";
import { watchConsultationRequests } from "./InHouseDoctor/ConsultationRequestsSaga";
import { watchDownloadPrescriptionPdfSaga } from "./InHouseDoctor/downloadPrescriptionPdfSaga";
import { watchGetDirectionSaga } from "./InHouseDoctor/getDirectionSaga";
import { watchGetDosageTypeSaga } from "./InHouseDoctor/getDosageTypeSaga";
import { watchGetMedTypeSaga } from "./InHouseDoctor/getMedTypeSaga";
import { watchListOfConsultations } from "./InHouseDoctor/listOfConsultationsSaga";
import { watchSubmitPrescription } from "./InHouseDoctor/submitPrescriptionSaga";
import { watchAssignProgramToInsurerSaga } from "./Insurer/AssignProgramToInsurerSaga";
import { watchGetProgramInsurerSaga } from "./Insurer/getProgramInsurerSaga";
import { watchViewAllLabSaga } from "./Lab/ViewAllLabSaga";
import { watchAddLabSaga } from "./Lab/addLabSaga";
import { watchApproveDiagnostic } from "./Lab/approveDiagnosticSaga";
import { watchDeleteLabSaga } from "./Lab/deleteLabSaga";
import { watchUpdateLabSaga } from "./Lab/updateLabSaga";
import { watchViewLabSaga } from "./Lab/viewLabSaga";
import { watchLoginDoctorSaga } from "./Login/LoginDoctorSaga";
import { watchLoginHrSaga } from "./Login/loginHrSaga";
import { watchLoginLabAgentSaga } from "./Login/loginLabAgentSaga";
import { watchValidateOtpLoginSaga } from "./Login/validateOtpLoginSaga";
import { watchApproveClinic } from "./Network/Clinic/ApproveClinicSaga";
import { watchProfessionalService } from "./Network/ProfessionalService/ApproveProfessionalServiceSaga";
import { watchProfessionalServiceSaga } from "./Network/ProfessionalService/NetworkProfessionalServiceSaga";
import { watchNetworkViewProfessionalServiceSaga } from "./Network/ProfessionalService/NetworkViewProfessionalServiceSaga";
import networkSaga from "./Network/networkSaga";
import { watchUpdatePackageFeatureSaga } from "./Packages/updatePackageFeatureSaga";
import { watchUpdatePackageSaga } from "./Packages/updatePackageSaga";
import { watchViewInPackagesSaga } from "./Packages/viewInPackagesSaga";
import { watchViewPackagesSaga } from "./Packages/viewPackagesSaga";
import { watchCreateLotSaga } from "./Payment/createLotSaga";
import { watchGetAllLotsIdsSaga } from "./Payment/getAllLotsIdsSaga";
import { watchGetAllLotsSaga } from "./Payment/getAllLotsSaga";
import { watchGetLotByIdSaga } from "./Payment/getLotByIdSaga";
import { watchUpdatePaymentSaga } from "./Payment/updatePaymentSaga";
import { watchViewAllAppointmentWithoutLotSaga } from "./Payment/viewAllAppointmentWithoutLotSaga";
import { watchViewAllPaymentSaga } from "./Payment/viewAllPaymentSaga";
import { watchplanTypeDropdownSaga } from "./Plan/PlanTypeDropdownSaga";
import { watchAddPlanSaga } from "./Plan/addPlanSaga";
import { watchDeletePlanSaga } from "./Plan/deletePlanSaga";
import { watchGetPlanDetailSaga } from "./Plan/getPlanDetailSaga";
import { watchViewAllPlanSaga } from "./Plan/viewAllPlanSaga";
import { watchViewAllPackageNamesSaga } from "./Program Template/Package/viewAllPackageNamesSaga";
import { watchAddProgramTemplateSaga } from "./Program Template/Program/addProgramTemplateSaga";
import { watchAssignProgramInsurerSaga } from "./Program Template/Program/assignProgramInsurerSaga";
import { watchAssignProgramSaga } from "./Program Template/Program/assignProgramSaga";
import { watchProgramTemplateSaga } from "./Program Template/Program/deleteProgramTemplateSaga";
import { watchGetProgramTemplateSaga } from "./Program Template/Program/getProgramTemplateSaga";
import { watchviewAllProgramTemplateNamesSaga } from "./Program Template/Program/viewAllProgramTemplateNamesSaga";
import { watchviewAllTemplateDataSaga } from "./Program Template/Program/viewAllTemplateDataSaga";
import { watchviewAllServicesAttributesSaga } from "./Program Template/Services/viewAllServicesAttributesSaga";
import { watchViewAllServicesNameAndUuidSaga } from "./Program Template/Services/viewAllServicesNameAndUuidSaga";
import { watchAddProgramSaga } from "./Program/addProgramSaga";
import { watchDeleteProgramSaga } from "./Program/deleteProgramSaga";
import { watchGetProgramSaga } from "./Program/getProgramSaga";
import { watchViewAllProgramSaga } from "./Program/viewAllProgramSaga";
import { watchViewProgramSaga } from "./Program/viewProgramSaga";
import { watchAddServiceAttributeSaga } from "./Service/addServiceAttributeSaga";
import { watchAddServiceSaga } from "./Service/addServiceSaga";
import { watchDeleteService } from "./Service/deleteServiceSaga";
import { watchGetServiceSaga } from "./Service/getAllServiceSaga";
import { watchUpdateServiceAttributeSaga } from "./Service/updateServiceAttribiteSaga";
import { watchUpdateServiceDescriptionSaga } from "./Service/updateServiceDescriptionSaga";
import { watchUpdateServiceTitleSaga } from "./Service/updateServiceTitleSaga";
import { watchViewAllServiceForCorporateSaga } from "./Service/viewAllServiceForCorporateSaga";
import { watchViewAllServiceSaga } from "./Service/viewAllServiceSaga";
import { watchViewServiceSaga } from "./Service/viewServiceSaga";
import { watchViewServiceSaga1 } from "./Service/viewServiceSaga1";
import { watchViewServiceSaga2 } from "./Service/viewServiceSaga2";
import { watchAddTestSaga } from "./Tests/addTestSaga";
import { watchUpdateTestSaga } from "./Tests/updateTestSaga";
import { watchViewAllTestSaga } from "./Tests/viewAllTestSaga";
import { watchViewTestSaga } from "./Tests/viewTestSaga";
import { watchGetUserIndividualSaga } from "./Users/GetUserIndividualSaga";
import { watchGetUserPlanSaga } from "./Users/UserPlanDetailsSaga";
import { watchGetUserProgramSaga } from "./Users/UserProgramDetailsSaga";
import { watchViewAllUsersDropdown } from "./Users/usersDropdownSaga";
import { watchViewAllUsersSaga } from "./Users/viewAllUserSaga";
import { watchAddInitiateRefundSaga } from "./accounts/AddInitiateRefundSaga";
import { watchChangeOrderStatusSaga } from "./accounts/ChangeOrderStatusSaga";
import { watchViewOrderDropdownSaga } from "./accounts/ViewOrderDropdownSaga";
import { watchViewOrderSaga } from "./accounts/ViewOrderSaga";
import { watchViewAccount } from "./accounts/viewAccountSaga";
// import { watchViewAllPharmacy } from "./Pharmacy/viewAllPharmacySaga";
// import { watchAddPharmacySaga } from "./Pharmacy/AddPharmacySaga";
// import { watchDeletePharmacy } from "./Pharmacy/deletePharmacySaga";
import rootPharmacySaga from "./Pharmacy/rootPharmacySaga";
import { watchAccountViewAllPharmacyLising } from "./accounts/ViewAllPharmacyListingSaga";
import { watchAllHospitalNameDropdownSaga } from "./Dashboard/HospitalDashboard/AllHospitalNameDropdown"
import { watchAllDoctorDetailListSaga } from "./Dashboard/HospitalDashboard/DoctorListDetailSaga"
import { watchHospitalDashboardSaga } from "./Dashboard/HospitalDashboard/HospitalDashboardSaga"
import { watchHospitalViewDoctorSaga } from "./Dashboard/HospitalDashboard/HospitalViewDoctorSaga"
import { watchViewDropdownAccount } from "./accounts/viewDropdownSaga";
import { watchCheckNumberSaga } from "./Login/checkNumberSaga";
import { watchAddUserSaga } from "./Users/AddUserSaga";
import { watchCheckEmailOrNumberSaga } from "./CreateTalkToDoctor/CheckEmailOrNumberSaga";
import { watchCreateTalkToDoctorSaga } from "./CreateTalkToDoctor/CreateTalkToDoctorSaga";
import { watchGetScreenTypesSaga } from "./Banner/getScreenTypesSaga";
import { watchGetBannerByScreenTypesSaga } from "./Banner/getBannerByScreenTypesSaga";
import { watchAddBannerSaga } from "./Banner/addBannerSaga";
import { watchDeleteBannerSaga } from "./Banner/deleteBannerSaga";
import {watchGetBannerTypesSaga} from "./Banner/getBannerTypesSaga"
import {watchDeletePositionBannerSaga} from "./Banner/deletePositionBannerSaga"
import rootBannerSaga from "./Banner/rootBannerSaga";
import { watchGetServiceDropdownSaga } from "./Service/getServiceDropdownSaga";
import { watchGetPackageDropdownSaga } from "./Packages/getPackageDropdownSaga";
import { watchGetPlanDropdownSaga } from "./Plan/getPlanDropdownSaga";
import rootLifeStyle from "./LifeStyle/rootLifeStyleSaga";
import { watchcorporateDropdownSaga } from "./Corporate/CorporateDropdownSaga";
import {watchGetPlanDropdownForCardSaga} from "./Plan/getPlanDropdownForCardSaga"
import {watchCreateCardNumberSaga} from "./CreateCard/CreateCardNumberSaga";
import {watchAssignCardSubcriptionSaga} from "./CreateCard/AssignCardsSubscriptionSaga";
import {watchGetAllCardsDropdownSaga} from "./CreateCard/GetAllCardsDropdownSaga"
import {watchGetDownloadCardDetailSaga} from "./CreateCard/DownloadCardDetailSaga"
import { watchUpdatePlanSaga } from "./Plan/updatePlanSaga";
import { watchGetBrandDropdownSaga } from "./Product/getBrandDropdownSaga";
import { watchGetRelatedProductSaga } from "./Product/getRelatedProductSaga";
import { watchGetUnitsSaga } from "./Product/getUnitsSaga";
import { watchGetCategoryDropdownSaga } from "./Product/getCategoryDropdownSaga";
import { watchGetSubCategoryDropdownSaga } from "./Product/getSubCategoryDropdownSaga";
import { watchAddProductSaga } from "./Product/addProductSaga";
import { watchCreateCategorySaga } from "./Products/createCategorySaga";
import { watchGetAllProductSaga } from "./Product/getAllProductSaga";
import { watchGetProductSaga } from "./Product/getProductSaga";
import { watchEditProductSaga } from "./Product/editProductSaga";
import { watchDisableProductSaga } from "./Product/deleteProductSaga";
import { watchGetAllCategorySaga } from "./Product/getAllCategorySaga";
import { watchGetCategoyDetailsSaga } from "./Product/getCategoryDetailsSaga";
import { watchGetAllSubCategorySaga } from "./Product/getAllSubCategorySaga";
import { watchAddSubCategorySaga } from "./Product/addSubCategorySaga";
import { watchAddBrandSaga } from "./Product/addBrandSaga";
import { watchGetAllBrandSaga } from "./Product/getAllBrandSaga";
import { watchGetBrandDetailsSaga } from "./Product/getBrandDetailsSaga";
import { watchUpdateCategorySaga } from "./Product/updateCategorySaga";
import { watchUpdateSubCategorySaga } from "./Product/UpdateSubCategorySaga";
import { watchGetSubCategoryDetailsSaga } from "./Product/getSubCategoryDetailsSaga";
import { watchUpdateBrandSaga } from "./Product/updateBrandSaga";
import { watchDeleteCategorySaga } from "./Product/deleteCategotySaga";
import { watchDeleteSubCategorySaga } from "./Product/deleteSubCategorySaga";
import { watchDeleteBrandSaga } from "./Product/deleteBrandSaga";
import { watchEditDiscountProductSaga } from "./Discount/EditDiscountProductSaga";
import { watchMapToElasticSaga } from "./Doctors/mapToElastic";
import { watchMapToElasticDoctorSaga } from "./Doctors/mapToElasticDoctor";





function* rootSaga() {
  yield all([
    watchLoginSaga(),
    watchAddDoctorSaga(),
    watchAddHospitalSaga(),
    watchViewAllDoctors(),
    watchViewAllHospitals(),
    watchDeleteHospitals(),
    watchViewHospitalDetail(),
    watchViewAllAgents(),
    watchViewAllCorporate(),
    watchViewAgentDetail(),
    watchAddInsuranceAgentSaga(),
    watchDeleteCorporate(),
    watchDeleteAgent(),
    watchViewCorporateDetail(),
    watchDeleteDoctor(),
    watchViewAllAppointments(),
    watchAddCorporateSaga(),
    watchUpdateDoctorSaga(),
    watchViewAllHospitalNames(),
    watchUpdateAppointmentSaga(),
    watchUpdateCorporateSaga(),
    watchUpdateInsuranceAgentSaga(),
    watchUpdateHospitalSaga(),
    watchViewAllServiceSaga(),
    watchViewServiceSaga(),
    watchAddProgramSaga(),
    watchLoginLabAgentSaga(),
    watchGetProgramSaga(),
    watchViewAllProgramSaga(),
    watchLoginHrSaga(),
    watchLoginDoctorSaga(),
    watchViewAllEmployee(),
    watchViewProgramSaga(),
    watchDeleteProgramSaga(),
    watchAddEmployeeSaga(),
    watchViewEmployeeDetailSaga(),
    watchDeleteEmployeeSaga(),
    watchAddEmployeeFileSaga(),
    watchViewAppointments(),
    watchViewServiceSaga1(),
    watchViewServiceSaga2(),
    watchEndAppointment(),
    watchAddReportSaga(),
    watchDeleteReportSaga(),
    watchGetServiceSaga(),
    watchAddServiceSaga(),
    watchAddAppointmentSaga(),
    watchConfirmAppointmentSaga(),
    watchRescheduleAppointmentSaga(),
    watchCancelAppointmentSaga(),
    watchValidateOtpSaga(),
    watchDeleteAppointmentSaga(),
    watchUpdateServiceDescriptionSaga(),
    watchUpdateServiceTitleSaga(),
    watchAddServiceAttributeSaga(),
    watchUpdateServiceAttributeSaga(),
    watchViewAllPackageNamesSaga(),
    watchviewAllServicesAttributesSaga(),
    watchViewAllServicesNameAndUuidSaga(),
    watchAddProgramTemplateSaga(),
    watchviewAllProgramTemplateNamesSaga(),
    watchviewAllTemplateDataSaga(),
    watchAssignProgramSaga(),
    watchViewAllCity(),
    watchGetUpdateHospitalSaga(),
    watchGetUpdateCorporateSaga(),
    watchDeleteService(),
    watchGetUpdateInsurerSaga(),
    watchCheckEmailAgentSaga(),
    watchGetProgramTemplateSaga(),
    watchallCityAppointmentSaga(),
    watchSearchDoctorSaga(),
    watchViewAllServiceForCorporateSaga(),
    watchViewAllEmployeeProgramSaga(),
    watchAssignProgramToEmployeeSaga(),
    watchGetEmployeeProgramSaga(),
    watchValidateOtpLoginSaga(),
    watchFileTemplateFileSaga(),
    watchAddLabSaga(),
    watchAddTestSaga(),
    watchViewAllTestsName(),
    watchMakePackage(),
    watchViewPackagesSaga(),
    watchViewInPackagesSaga(),
    watchViewAllLabSaga(),
    watchViewLabSaga(),
    watchDeleteLabSaga(),
    watchUpdateLabSaga(),
    watchUploadReport(),
    watchViewAllTestSaga(),
    watchViewAllBookingSaga(),
    watchConfirmBookingSaga(),
    watchRescheduleBookingSaga(),
    watchCancelBookingSaga(),
    watchViewBooking(),
    watchViewTestSaga(),
    watchUpdateTestSaga(),
    watchFinishBookingSaga(),
    watchUpdatePackageSaga(),
    watchDownloadAttachmentSaga(),
    watchCompleteBookingSaga(),
    watchUploadReportAppointments(),
    watchFinishAppointmentSaga(),
    watchDownloadPrescriptionSaga(),
    watchListOfConsultations(),
    watchConsultationRequests(),
    watchSubmitPrescription(),
    watchCorporateDashboardSaga(),
    watchAcceptOrRejectRequestSaga(),
    watchAssignLabSaga(),
    watchviewAllLabsNamesSaga(),
    watchDeleteAttachmentSaga(),
    watchViewAllPaymentSaga(),
    watchChatStatusSaga(),
    watchViewAllAppointmentWithoutLotSaga(),
    watchCreateLotSaga(),
    watchGetAllLotsSaga(),
    watchGetLotByIdSaga(),
    watchUpdatePaymentSaga(),
    watchGetRelationSaga(),
    watchGetAllLotsIdsSaga(),
    watchAddBookingSaga(),
    watchGetMyTestsAndPackageSaga(),
    networkSaga(),
    watchApproveHospitals(),
    watchApproveDiagnostic(),
    watchUserDetailSaga(),
    watchrelationshipSaga(),
    watchProgramAndPlanSaga(),
    watchViewAllProgramOfInsurerSaga(),
    watchAssignProgramInsurerSaga(),
    watchAddPlanSaga(),
    watchcorporateViewAssignProgramSaga(),
    watchcorporateGetAssignProgramSaga(),
    watchUpdatePackageFeatureSaga(),
    watchProgramTemplateSaga(),
    watchGetInsurerAssignedProgramsSaga(),
    watchApproveClinic(),
    watchAssignProgramToInsurerSaga(),
    watchGetProgramInsurerSaga(),
    watchGetAllCouponSaga(),
    watchEditCouponSaga(),
    watchDeleteCouponSaga(),
    watchAddCouponSaga(),
    watchGetCouponDetailsSaga(),
    watchAddDiscountGroupSaga(),
    watchAddDiscountIndividualSaga(),
    watchEditDiscountSaga(),
    watchGetAllDiscountSaga(),
    watchViewDiscountSaga(),
    watchDeleteDiscountSaga(),
    watchplanTypeDropdownSaga(),
    watchProfessionalServiceSaga(),
    watchNetworkViewProfessionalServiceSaga(),
    watchProfessionalService(),
    watchViewAccount(),
    watchAddInitiateRefundSaga(),
    watchViewOrderSaga(),
    watchViewOrderDropdownSaga(),
    watchChangeOrderStatusSaga(),
    watchViewAllUsersSaga(),
    watchViewAllUsersDropdown(),
    watchViewAllPlanSaga(),
    watchDeletePlanSaga(),
    watchGetPlanDetailSaga(),
    watchGetMedTypeSaga(),
    watchGetDosageTypeSaga(),
    watchGetDirectionSaga(),
    watchDownloadPrescriptionPdfSaga(),
    watchGetUserIndividualSaga(),
    watchGetUserPlanSaga(),
    watchGetUserProgramSaga(),
    rootPharmacySaga(),
    watchAllHospitalNameDropdownSaga(),
    watchAllDoctorDetailListSaga(),
    watchHospitalDashboardSaga(),
    watchHospitalViewDoctorSaga(),
    watchViewDropdownAccount(),
    watchAccountViewAllPharmacyLising(),
    watchCheckNumberSaga(),
    watchAddUserSaga(),
    watchCheckEmailOrNumberSaga(),
    watchCreateTalkToDoctorSaga(),
    watchGetScreenTypesSaga(),
    watchGetBannerByScreenTypesSaga(),
    watchAddBannerSaga(),
    watchDeleteBannerSaga(),
    watchGetBannerTypesSaga(),
    watchDeletePositionBannerSaga(),
    rootBannerSaga(),
    watchGetServiceDropdownSaga(),
    watchGetPackageDropdownSaga(),
    watchGetPlanDropdownSaga(),
    rootLifeStyle(),
    watchcorporateDropdownSaga(),
    watchGetPlanDropdownForCardSaga(),
    watchCreateCardNumberSaga(),
    watchAssignCardSubcriptionSaga(),
    watchGetAllCardsDropdownSaga(),
    watchGetDownloadCardDetailSaga(),
    watchUpdatePlanSaga(),
    watchGetBrandDropdownSaga(),
    watchGetRelatedProductSaga(),
    watchGetUnitsSaga(),
    watchGetCategoryDropdownSaga(),
    watchGetSubCategoryDropdownSaga(),
    watchAddProductSaga(),
    watchCreateCategorySaga(),
    watchGetAllProductSaga(),
    watchGetProductSaga(),
    watchEditProductSaga(),
    watchDisableProductSaga(),
    watchGetAllCategorySaga(),
    watchGetCategoyDetailsSaga(),
    watchGetAllSubCategorySaga(),
    watchAddSubCategorySaga(),
    watchAddBrandSaga(),
    watchGetAllBrandSaga(),
    watchGetBrandDetailsSaga(),
    watchUpdateCategorySaga(),
    watchUpdateSubCategorySaga(),
    watchGetSubCategoryDetailsSaga(),
    watchUpdateBrandSaga(),
    watchDeleteCategorySaga(),
    watchDeleteSubCategorySaga(),
    watchDeleteBrandSaga(),
    watchEditDiscountProductSaga(),
    watchMapToElasticSaga(),
    watchMapToElasticDoctorSaga(),
  ]);
}

export default rootSaga;
