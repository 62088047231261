import React from 'react'
import pnf from "../../../assets/pnf.png"
import "./PageNotFound.css"
import Footer from "../../Organisms/Footer/FooterUpper"

function PageNotFound() {
  return (
    <>
    <div className='pageNotfoundMain'>
        <p className='pageNotfoundMainPara1'>Page Not Found</p>
        <img src={pnf}></img>
        <p className='pageNotfoundMainPara2' />
    </div>
    <Footer />
    </>
  )
}

export default PageNotFound