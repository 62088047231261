import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/approveHospitalAction";
import NetworkService from "../../../network/NetworkService";

export function* approveHospitals(action) {
  try {
    let response = yield call(NetworkService.approveHospital, action);
    yield put(actions.approveHospitalSuccess(response));
  } catch (err) {
    yield put(actions.approveHospitalFailure(err));
  }
}

export function* watchApproveHospitals() {
  yield takeLatest(types.APPROVE_HOSPITAL_REQUEST, approveHospitals);
}

