import * as types from "../../Config/actionType"

export const viewEmployeeDetailRequest = (id,asSuperAdmin) => {
    return {
        type: types.VIEW_EMPLOYEE_DETAIL_REQUEST,
        id,
        asSuperAdmin
    }
}

export const viewEmployeeDetailSuccess = (action) => {
    return {
        type: types.VIEW_EMPLOYEE_DETAIL_SUCCESS,
        action
    }
}

export const viewEmployeeDetailFailure = (action) => {
    return {
        type: types.VIEW_EMPLOYEE_DETAIL_FAILURE,
        action
    }
}