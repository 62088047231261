import * as types from "../../Config/actionType"

export const downloadPrescriptionPdfRequest = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_PDF_REQUEST,
        data:data
    }
}

export const downloadPrescriptionPdfSuccess = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_PDF_SUCCESS,
        data:data,
    }
}

export const downloadPrescriptionPdfFailure = (data) => {
    return {
        type: types.DOWNLOAD_PRESCRIPTION_PDF_FAILURE,
        data:data,
    }
}