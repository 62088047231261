import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { updateTestRequest } from "../../../redux/Actions/Tests/updateTestAction";
import "./Modal.css";
import CircularProgress from "@mui/material/CircularProgress";

import { modalHeader, modalHeaderSub } from "./ModalConstants";
const addSchema = Yup.object().shape({
  TestName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.,&-])[A-Za-z0-9 (),&.-]+$/, "Insert only normal character"),

  TestTime: Yup.string().required("Required Field"),
  TestAmount: Yup.string().required("Required Field"),
  // Interpretation: Yup.string().required("Required Field"),
  Description: Yup.string().required("Required Field"),
});

function ModalUpdateTest(props) {
  const dispatch = useDispatch();
  const INITIAL_STATE = {};
  const handleClose = () => props.open(false);
  const [FormValidation, setFormValidation] = useState([INITIAL_STATE]);
  const [inputList, setInputList] = useState([]);
  const [instruction, setInstruction] = useState("");
  const [radiology, setRadiology] = useState();

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  function handleInstruction(e) {
    setInstruction(e.target.value);
  }

  const handleAddClick = () => {
    setInputList([...inputList, { parameterName: "" }]);
    FormValidation.push(INITIAL_STATE);
  };
  const updateRes = useSelector((state) => state.updateTest)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    //
    //
    //
    const TestTotalTime = values.TestTime * 24 + values.TestTimeHours;
    //

    let csvString = [];
    inputList.map((index) => {
      csvString.push({ parameterName: index.parameterName.trim() });
    });
    if (csvString[0].parameterName === "") {
      csvString = [];
    }

    let submitValues = {
      values,
      csvString,
      TestTotalTime,
      instruction,
      radiology,
    };
    dispatch(updateTestRequest(submitValues));
  };
  const updateTest = useSelector((state) => state.updateTest);

  if (updateTest.data?.data?.status === 200) {
    props.open(false);
    Swal.fire({
      title: updateTest.data?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  useEffect(() => {
    if (updateTest.error?.data?.response?.status === 409) {
      swal({
        title: "" + Object.values(updateTest.error?.data?.response?.data)[0],
        icon: "error",
      }).then(() => {
        props.open(true);
      });
    }
  }, [updateTest.error?.data?.response?.status]);

  function handleRadiology(e) {
    if (e.target.value === "true") {
      setRadiology(true);
    } else {
      setRadiology(false);
    }
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }
  useEffect(() => {
    if (props?.value?.data?.data?.data?.parameters.length !== 0) {
      setInputList(props?.value?.data?.data?.data?.parameters);
      setInstruction(props?.value?.data?.data?.data?.instruction);
      setRadiology(props?.value?.data?.data?.data?.radiology);
    } else {
      setInputList([{ parameterName: "" }]);
    }
  }, [props?.value?.data?.data?.data?.parameters]);

  // if(inputList.length===0){
  //   inputList.push({parameterName:""})
  // }
  return (
    <div>
      <div>
        <Modal
          open={props.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="mainModal"
        >
          <Box className="style1">
            <div className="modalHeader">
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className="modalHeaderText"
                sx={modalHeader}
              >
                <div>Edit Test</div>
              </Typography>

              <button className="closeButtonModal" onClick={handleClose}>
                <Close />
              </button>
            </div>
            <Formik
              initialValues={{
                TestName: props?.value?.data?.data?.data?.name,
                TestAmount: props?.value?.data?.data?.data.cost,
                Description: props?.value?.data?.data?.data.description,
                TestTime: Math.floor(
                  props?.value?.data?.data?.data.reportTime / 24
                ),
                id: props?.value?.data?.data?.data.id,
                Interpretation: props?.value?.data?.data?.data.interpretation,
                TestTimeHours: Math.floor(
                  Math.floor(props?.value?.data?.data?.data.reportTime % 24)
                ),
                // TestTotalTime:props?.value?.data?.data?.data.TestTotalTime
              }}
              validationSchema={addSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {
                <Form>
                  <div className="inputFieldsContainer">
                    <label>
                      <Field
                        type="text"
                        name="TestName"
                        // value={TestName}
                        placeholder="Test Name"
                        className="inputField"
                        // onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="TestName"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        as="textarea"
                        type="text"
                        placeholder="Test Description"
                        className="inputFieldDescription"
                        name="Description"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="Description"
                        component="div"
                      />
                    </label>

                    <label>
                      <Field
                        type="number"
                        name="TestAmount"
                        placeholder="Test Amount"
                        className="inputField"
                        onWheel={(event) => event.currentTarget.blur()}
                        onKeyDown={(e) =>
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="TestAmount"
                        component="div"
                      />
                    </label>
                    <div className="updatingtheModal">
                      <div>
                        <label>
                          <Field
                            type="number"
                            name="TestTime"
                            // value={TestTime}
                            placeholder="Test Time (in days)"
                            className="textingField"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="TestTime"
                            component="div"
                          />
                        </label>
                      </div>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div>
                        <label>
                          <Field
                            type="number"
                            name="TestTimeHours"
                            placeholder="Test Time (in hours)"
                            className="textingField"
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                          <ErrorMessage
                            className="errorMessageModalValidation"
                            name="TestTimeHours"
                            component="div"
                          />
                        </label>
                      </div>
                    </div>
                    {/* <label> */}
                    {/* <Field
                      type="number"
                      name="TestTime"
                      placeholder="Test Time (in hours)"
                      className="inputField"
                      onWheel={ event => event.currentTarget.blur() }

                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="TestTime"
                      component="div"
                    />
                  </label> */}
                    <label>
                      <select
                        className="inputField"
                        onChange={handleInstruction}
                        value={instruction}
                      >
                        <option value="" disabled selected>
                          Select Instructions
                        </option>
                        <option value="Fasting Required">
                          Fasting Required
                        </option>
                        <option value="Fasting Not Required">
                          Fasting Not Required
                        </option>
                      </select>
                    </label>
                    <label>
                      <Field
                        type="text"
                        name="Interpretation"
                        placeholder="Interpretation (optional)"
                        className="inputField"
                        onKeyDown={(event) => preventnumberinput(event)}
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="Interpretation"
                        component="div"
                      />
                    </label>
                    <label>
                      <select
                        className="inputField"
                        onChange={handleRadiology}
                        value={radiology}
                      >
                        <option value="" disabled selected>
                          Radiology
                        </option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </select>
                    </label>
                    <Typography
                      id="modal-modal-title"
                      variant="h3"
                      component="h2"
                      sx={modalHeaderSub}
                    >
                      Parameters
                    </Typography>
                    <div className="attributeCardBox">
                      {/* {props?.value?.data?.data?.data?.parameters.length===0?:} */}
                      {inputList?.map((x, i) => {
                        return (
                          <>
                            <div className="addParameterWrapper">
                              <div>
                                <input
                                  name="parameterName"
                                  placeholder="Parameter name"
                                  id="parameterName"
                                  className="inputFieldAddParameterFirst"
                                  value={x.parameterName}
                                  onChange={(e) => handleInputChange(e, i)}
                                ></input>

                                <p className="errorMessageModalValidatio">
                                  {FormValidation[i]?.parameterName}
                                </p>
                              </div>
                              <div className="addParameterCross">
                                {inputList.length !== 1 && (
                                  <div
                                    className="addParameterCrossButton"
                                    onClick={() => handleRemoveClick(i)}
                                  >
                                    X
                                  </div>
                                )}
                              </div>
                            </div>

                            <div>
                              {inputList.length - 1 === i && (
                                <button
                                  onClick={() => {
                                    handleAddClick();
                                    // setIteration(iteration+1);
                                  }}
                                  className="addParameterButton"
                                >
                                  + Add Parameter
                                </button>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <button type="submit" disabled={!flag}  className="modalButton">
                      {flag ? "     Edit Test" : <CircularProgress />}
                    </button>
                  </div>
                </Form>
              }
            </Formik>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default ModalUpdateTest;
