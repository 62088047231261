import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/LifeStyle/lifeStyleActionType"
import * as actions from "../../Actions/LifeStyle/viewAllLifeStyleAction";
// import NetworkService from "../../../network/NetworkService";
import NetworkService from "../../../network/LifeStyle/viewAllLifeStyleService"

export function* viewAllLifeStyle(action) {
  try {
    let response = yield call(NetworkService.viewAllLifeStyle,action);

    yield put(actions.viewAllLifeStyleSuccess(response))
  } catch (err) {
    yield put(actions.viewAllLifeStyleFailure(err));
  }
}

export function* watchViewAllLifeStyle() {
  yield takeLatest(types.GET_LIFE_STYLE_REQUEST, viewAllLifeStyle);
}