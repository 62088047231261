import React, { useEffect, useState } from "react";
import "./DisableModal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import uploadSign from "../../../assets/uploadSign.png";
import { deleteCorporateRequest } from "../../../redux/Actions/Corporate/deleteCorporateAction";
import { Formik, Form, Field, ErrorMessage } from "formik";
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const loginSchema = Yup.object().shape({
  disableReason: Yup.string().required("Required Field"),
});
function DisableCorporateModal(props) {
  const [file, setFile] = useState();

  const dispatch = useDispatch();
  const handleClose = () => {
    window.location.reload();
    props.delOpen(false);
  };
  const deleteCorporate = useSelector((state) => state.deleteCorporate);
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    if (deleteCorporate?.data || deleteCorporate?.error) {
      setFlag(true);
    }
  }, [deleteCorporate]);
  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();

    formData.append("approvalFile", file);

    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    setFlag(false);

    dispatch(
      deleteCorporateRequest(props.delId, values.disableReason, formData)
    );
  };
  if (deleteCorporate?.data?.data?.status === true) {
    swal({
      title: deleteCorporate?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (deleteCorporate?.error?.action?.response.status === 403) {
    swal({
      title: deleteCorporate?.error?.action?.response.data.errorMessage,
      icon: "error",
    }).then(() => {});
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCheckFile = (e) => {
    const fileType = e.target.files[0];

    if (fileType.type.includes("pdf") === false) {
      document.getElementById("fileUploadInput").value = "";
      return alert("Please upload a pdf file");
    }
  };

  return (
    <div>
      <Modal
        open={props.delOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="DisableModalHeading"
            >
              Disable Corporate
            </Typography>
            <div className="DisableModalSubHeading">Disable Reason</div>
          </div>

          <Formik
            initialValues={{
              disableReason: "",
              UploadApproval: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {(formProps) => (
              <Form>
                <div className="Responsiveyu">
                  <label>
                    <Field
                      as="Textarea"
                      type="text"
                      id="disableReason"
                      name="disableReason"
                      placeholder="Reason To Disable"
                      className="disableReasontextarea"
                    />
                  </label>
                  <ErrorMessage
                    className="errorDisableModalValidation"
                    name="disableReason"
                    component="div"
                  />
                  <div className="DisableModalSubHeading">Upload Approval</div>
                  <div>
                    <div className="DisableModalUpload">
                      <img
                        src={uploadSign}
                        alt="uploadSign"
                        className="uploadSignMain"
                      ></img>
                      <div>
                        <div className="fileUploadContainer">
                          <input
                            type="file"
                            name="UploadApproval"
                            id="fileUploadInput"
                            ondrop={handleDragOver}
                            ondragover={handleDrop}
                            accept="application/pdf,application/vnd.ms-excel"
                            onBlur={(event) => handleFileChange(event)}
                            onChange={(event) => handleCheckFile(event)}
                          />
                        </div>
                      </div>
                      <ErrorMessage
                        className="errorDisableModalValidation1"
                        name="UploadApproval"
                        component="div"
                      />
                      {file?.size / 1024 > 10240 && (
                        <div className="errorDisableModalValidation1">
                          {" "}
                          File Size should be less than 10mb
                        </div>
                      )}
                      {/* {click == true && (
                        <> */}
                      {file === null && (
                        <div className="selectFileMessage">
                          {" "}
                          Select the file
                        </div>
                      )}
                      {/* </>
                       
                      )} */}

                      <span></span>
                    </div>
                  </div>
                  <div className="disableModalSubmitButton">
                    <button type="submit" className="disableModalButton">
                      {flag ? "  Submit" : <CircularProgress />}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default DisableCorporateModal;
