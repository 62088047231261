import * as types from "../../Config/actionType"

export const addAppointmentsRequest = (data) => {
    return {
        type: types.ADD_APPOINTMENT_REQUEST,
        data:data
    }
}

export const addAppointmentsSuccess = (data) => {
    return {
        type: types.ADD_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const addAppointmentsFailure = (data) => {
    return {
        type: types.ADD_APPOINTMENT_FAILURE,
        data:data,
    }
}