import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Accounts/ChangeOrderStatusAction";
import NetworkService from "../../../network/NetworkService";

export function* ChangeOrderStatusSaga(action) {
  try {
    let response = yield call(NetworkService.changeOrderStatus, action);
    yield put(actions.ChangeOrderStatusSuccess(response));
  } catch (err) {
    yield put(actions.ChangeOrderStatusFailure(err));
  }
}

export function* watchChangeOrderStatusSaga() {
  yield takeLatest(types.CHANGE_ORDER_STATUS_REQUEST, ChangeOrderStatusSaga);
}