import LocationOnIcon from "@mui/icons-material/LocationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { approveProfessionalServiceRequest } from "../../../../redux/Actions/Network/ProfessionalService/ApproveProfessionalService";
import { networkViewProfessionalServiceRequest } from "../../../../redux/Actions/Network/ProfessionalService/ProfessionalSevicesViewRequest";
import Loader from "../../../../assets/loader.gif";
import { planTypeDropdownRequest } from "../../../../redux/Actions/Plan/PlanTypeDropDown";

function ViewProfessionalRequest() {
  const { id } = useParams();
  const [location, setLocation] = React.useState("");
  const [error, setError] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networkViewProfessionalServiceRequest(id));
  }, []);

  useEffect(() => {
  dispatch(planTypeDropdownRequest());
  }, []);

  // const approveHospital = useSelector((state) => state.approveHospital);
  const approveProfessionalService = useSelector(
    (state) => state.approveProfessionalService
  );

  const networkViewProfessionalService = useSelector(
    (state) => state.networkViewProfessionalService
  );

  // const planTypeDropdown = useSelector((state) => state.planTypeDropdown);

  // const handleChange = (event) => {
  //   setSelectType(event.target.value);
  // };

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };

  const handleApprove = (status) => {
    if (location.length === 0 && status === "APPROVED") {
      setError(true);
    } else {
      setTimeout(() => {
        dispatch(approveProfessionalServiceRequest(id, status, location));
      });
    }
  };

  if (approveProfessionalService?.data?.status === 201) {
    swal({
      title: approveProfessionalService?.data?.data?.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (approveProfessionalService.error !== null) {
    swal({
      title:
        approveProfessionalService?.error?.action.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      {approveProfessionalService.isLoading ? (
        <>
          <div className="corporateDashboardLoading">
            <img alt="Loader" src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
        <>
          <div className="requestPage">
            <h3 className="requestHead">
              View Requests{" "}
              {networkViewProfessionalService.data?.data.data.data
                .networkStatus === "APPROVED" && (
                <div className="approvedTile">APPROVED</div>
              )}
              {networkViewProfessionalService.data?.data.data.data
                .networkStatus === "DECLINED" && (
                <div className="approvedTileC">DECLINED</div>
              )}
              {networkViewProfessionalService.data?.data.data.data
                .networkStatus === "PENDING" && (
                <div className="approvedTileP">PENDING</div>
              )}
            </h3>
            <div className="requestBox">
              <div className="requestTextHospital2">
                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Email:</h1>
                  <h1 className="requestPageData">
                    {networkViewProfessionalService.data?.data.data.data.email}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name:</h1>
                  <h1 className="requestPageData">
                    {networkViewProfessionalService.data?.data.data.data.name}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .address
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">City:</h1>
                  <h1 className="requestPageData">
                    {networkViewProfessionalService.data?.data.data.data.city}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Contact Number:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .contactNumber
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Location:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .location
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Mode of Session:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .modeOfSession
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">PIN:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .pinCode
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Services :</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .services
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Clinic Photo:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .clinicPhotoName
                    }{" "}
                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewProfessionalService.data?.data.data.data
                            .clinicPhoto
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Fee Per Session:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .feeName
                    }

                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewProfessionalService.data?.data.data.data
                            .fee
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo"> Photographs:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewProfessionalService.data?.data.data.data
                        .photoName
                    }

                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewProfessionalService.data?.data.data.data
                            .photo
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                {networkViewProfessionalService.data?.data.data.data
                  .networkStatus === "PENDING" && (
                  <>
                    <div className="requestPageInfowrapper">
                      <h1 className="requestPageInfo">Location URL:</h1>
                      <div className="LocationIconMui">
                        {" "}
                        <LocationOnIcon />{" "}
                      </div>
                      <input
                        value={location}
                        onChange={handleChangeLocation}
                        placeholder="Enter Location Url"
                        className="LocationInputField"
                      ></input>

                      {error && location.length <= 0 ? (
                        <label className="SelectTypeUrlValidation">
                          Please Enter The Location URL*
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>

              {networkViewProfessionalService.data?.data.data.data
                .networkStatus === "PENDING" && (
                <div className="requestButton">
                  <button
                    className="requestApprove"
                    onClick={() => {
                      handleApprove("APPROVED");
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="requestDecline"
                    onClick={() => {
                      handleApprove("DECLINED");
                    }}
                  >
                    Decline
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewProfessionalRequest;
