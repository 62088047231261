import * as types from "../../Config/actionType"

export const viewServiceRequest2 = (name) => {
    return {
        type: types.VIEW_SERVICE_REQUEST2,
        name
    }
}

export const viewServiceSuccess2 = (action) => {
    return {
        type: types.VIEW_SERVICE_SUCCESS2,
        action
    }
}

export const viewServiceFailure2 = (action) => {
    return {
        type: types.VIEW_SERVICE_FAILURE2,
        action
    }
}