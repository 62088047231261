import * as types from "../../Config/actionType";

export const deleteLabRequest = (id, disableReason, file) => {
  return {
    type: types.DELETE_LAB_REQUEST,
    id,
    disableReason,
    file,
  };
};

export const deleteLabSuccess = (action) => {
  return {
    type: types.DELETE_LAB_SUCCESS,
    action,
  };
};

export const deleteLabFailure = (action) => {
  return {
    type: types.DELETE_LAB_FAILURE,
    action,
  };
};
