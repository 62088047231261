import { createContext, useEffect, useState } from "react";
import "./App.css";
import logo from "./assets/logo_primary.png";
import CorporateNavbar from "./component/Organisms/Navbar/CorporateNavbar";
import DoctorNavbar from "./component/Organisms/Navbar/DoctorNavbar";
import HospitalNavbar from "./component/Organisms/Navbar/HospitalNavbar";
import InsuranceAgentNavbar from "./component/Organisms/Navbar/InsuranceAgentNavbar";
import SuperAdminNavbar from "./component/Organisms/Navbar/navbar";
import NavbarLabAgent from "./component/Organisms/Navbar/NavbarLabAgent";
import { roles } from "./helpers/MainConstants";
import AllRoutes from "./routes/AllRoutes";
import storage from "./Utils/storage";
import AccountsNavbar from "./component/Organisms/Navbar/AccountsNavbar";
import PharmacyNavbar from "./component/Organisms/Navbar/PharmacyNavbar";
import CustomerServiceAgentNavbar from "./component/Organisms/Navbar/CustomerServiceAgentNavbar";
import CryptoJS from 'crypto-js';

export const UserContext = createContext()

function App({ someProp }) {

  const [isMobile, setIsMobile] = useState(false);
  const [role, setRole] = useState(storage.getStorage("authorization"));

  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (role !== null) {
      const secretKey = process.env.REACT_APP_SECURITY_KEY;
      const decryptedBytes = CryptoJS.AES.decrypt(role, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      setRole(decryptedData);
    }
  }, [])

  return (
    <UserContext.Provider value={role}>
      <>
        {isMobile ? (
          <div>
            <img src={logo} alt="error" className="logoAppMain"></img>
            <div className="loginPageHeader">Please open in tablet or laptop</div>
          </div>
        ) : (
          <div className="App">
            {role === roles.SUPER_ADMIN ? <SuperAdminNavbar /> : ""}
            {role === roles.INSURER ? <InsuranceAgentNavbar /> : ""}
            {role === roles.CORPORATE_HR ? <CorporateNavbar /> : ""}
            {role === roles.IN_HOUSE_DOCTOR ? <DoctorNavbar /> : ""}
            {role === roles.SUPER_LAB_ADMIN ? <NavbarLabAgent /> : ""}
            {role === roles.HOSPITAL_ADMIN ? <HospitalNavbar /> : ""}
            {role === roles.ACCOUNTS ? <AccountsNavbar /> : ""}
            {role === roles.SUPER_PHARMA_ADMIN ? <PharmacyNavbar /> : ""}
            {role === roles.CUSTOMER_SERVICE_AGENT ? (
              <CustomerServiceAgentNavbar />
            ) : (
              ""
            )}
            <AllRoutes />
          </div>
        )}
      </>
    </UserContext.Provider>
  );
}

export default App;
