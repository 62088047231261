import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/deleteBrandAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* deleteBrandSaga(action) {
 

  try {
    let response = yield call(NetworkService.deleteBrand, action);

    yield put(actions.deleteBrandSuccess(response));
  } catch (err) {
    yield put(actions.deleteBrandFailure(err));
  }
}

export function* watchDeleteBrandSaga() {
  yield takeLatest(types.DELETE_BRAND_REQUEST, deleteBrandSaga);
}
