import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/viewAllTemplateDataAction";
import NetworkService from "../../../../network/NetworkService";

export function* viewAllTemplateDataSaga(action) {
  try {
    // 
    let response = yield call(NetworkService.viewAllTemplateData, action);
    //
    yield put(actions.viewAllTemplateDataSuccess(response));
  } catch (err) {
    yield put(actions.viewAllTemplateDataFailure(err));
  }
}

export function* watchviewAllTemplateDataSaga() {
  yield takeLatest(types.VIEW_ALL_TEMPLATE_DATA_REQUEST, viewAllTemplateDataSaga);
}
