import React, { useState } from "react";
import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addServiceRequest } from "../../../redux/Actions/Service/addServiceAction";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { modalHeader } from "./ModalConstants";

const modalHeader2 = {
  fontSize: "20px",
  fontFamily: "Nunito",
  marginBottom: "82px",
};

function ModalAddService(props) {
  const res = useSelector((state) => state.addService);
  const INITIAL_STATE = {};
  const [FormValidation, setFormValidation] = useState([INITIAL_STATE]);
  // const [iteration, setIteration] = useState(0);
  if (res.data?.action.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Service added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }
  if (
    (res.error?.action?.response?.status !== 400 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.action?.response?.data),
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }
  const dispatch = useDispatch();
  const handleClose = () => {
    props.open(false);
    window.location.reload();
  };
  const handleSubmit = (values, { setSubmitting }) => {
    let val = {};
    val = { ...values, description, inputList };
    const VALID = FormValidationfun(val);
    setFormValidation(VALID);
    dispatch(addServiceRequest(val));
    // }
  };
  const FormValidationfun = (inputValues) => {
    const errors = {};
    const myError = [];
    for (let i = 0; i < inputValues.inputList.length; i++) {
      const newError = {};
      if (!inputValues?.inputList[i]?.attributeName) {
        newError.attributeName = " *Attribute Name is Required!";
      }
      if (!inputValues.inputList[i]?.attributeDescription) {
        newError.attributeDescription = " *Attribute Description is Required!";
      }
      if (!inputValues.inputList[i]?.cost) {
        newError.cost = " *Cost is Required!";
      }
      if (!inputValues.description) {
        newError.description = " *Description is Required!";
      }
      if (!inputValues.serviceName) {
        newError.serviceName = " *ServiceName is Required!";
      }
      myError.push(newError);
    }

    return myError;
  };

  // const handleAdd = () => {};
  // const handleChange = (newService) => {
  //   // setCurrentService(newService);
  //   // dispatch(viewServiceRequest(newService));
  //   // setCurrentServiceDisable(newService);
  // };
  const [inputList, setInputList] = useState([
    { attributeName: "", cost: 0, attributeDescription: "" },
  ]);
  const [description, setDescription] = useState("");
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { attributeName: "", cost: "", attributeDescription: "" },
    ]);
    FormValidation.push(INITIAL_STATE);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New Service
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>
          <Formik
            initialValues={{
              serviceName: "",
              provider: ["HOSPITAL"],
            }}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type=""
                      name="serviceName"
                      placeholder="Service Name"
                      className="inputFieldAddService"
                    />
                    <p className="errorMessageModalService">
                      {FormValidation[0].serviceName}
                    </p>
                  </label>
                  <label>
                    <textarea
                      type="textarea"
                      onBlur={(e) => {
                        handleDescription(e);
                      }}
                      placeholder="Service Description"
                      className="inputFieldDescription"
                      id="description"
                      name="description"
                      rows="14"
                      cols="10"
                      wrap="soft"
                      // value={description}
                    />
                    <p className="errorMessageModalService">
                      {FormValidation[0].description}
                    </p>
                  </label>
                  <Typography
                    id="modal-modal-title"
                    variant="h3"
                    component="h2"
                    sx={modalHeader2}
                  >
                    Attributes
                  </Typography>
                  {inputList.map((x, i) => {
                    return (
                      <>
                        <div className="attributeCardBox">
                          <div className="attributeCardCloseButton">
                            {inputList.length !== 1 && (
                              <button
                                className="deleteAttribute"
                                onClick={() => handleRemoveClick(i)}
                              >
                                X
                              </button>
                            )}
                          </div>
                          <input
                            name="attributeName"
                            placeholder="Attribute name"
                            id="attributeName"
                            className="inputFieldAddAttributeFirst"
                            value={x.attributeName}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                          <p className="errorMessageModalValidatio">
                            {FormValidation[i]?.attributeName}
                          </p>
                          <div>
                            <input
                              className="inputFieldAddAttribute"
                              name="cost"
                              id="cost"
                              placeholder="Cost"
                              value={x.cost}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                            <p className="errorMessageModalValidatio">
                              {FormValidation[i]?.cost}
                            </p>
                          </div>
                          <textarea
                            type="textarea"
                            name="attributeDescription"
                            id="attributeDescription"
                            placeholder="Attribute Description"
                            className="inputFieldAttributeDescription"
                            value={x.attributeDescription}
                            onChange={(e) => handleInputChange(e, i)}
                            rows="14"
                            cols="10"
                            wrap="soft"
                          />
                          <p className="errorMessageModalValidatio">
                            {FormValidation[i]?.attributeDescription}
                          </p>
                        </div>
                        <div>
                          {inputList.length - 1 === i && (
                            <button
                              onClick={() => {
                                handleAddClick();
                                // setIteration(iteration+1);
                              }}
                              className="addAttributeButton"
                            >
                              + Add Attribute
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })}
                  <button type="submit" className="modalButton">
                    Create Service
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}
export default ModalAddService;
