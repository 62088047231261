import * as types from "../../Config/Banner/BannerActionType"

export const getAllBannerByTypeRequest = (bannerType, position, screenType) => {
    return {
        type: types.GET_ALL_BANNER_BY_TYPE_REQUEST,
        bannerType,
        position,
        screenType
    }
}

export const getAllBannerByTypeSuccess = (data) => {
    return {
        type: types.GET_ALL_BANNER_BY_TYPE_SUCCESS,
        data:data.data
    }
}

export const getAllBannerByTypeFailure = (data) => {
    return {
        type: types.GET_ALL_BANNER_BY_TYPE_FAILURE,
        data:data
    }
}