import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { addPharmacyRequest } from "../../../redux/Actions/Pharmacy/AddPharmacyRequestAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z . & , ]+$/, "Insert only normal character"),
  contactPersonNumber: Yup.string()
    .required("Required Field")
    .matches(phoneRegExp, "Phone number is not valid"),
  contactEmail: Yup.string().required("Required Field"),
  contactName: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  address: Yup.string().required("Required Field"),
  city: Yup.string().required("Required Field"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  pinCode: Yup.string()
    .min(6, "Must be exactly 6 digits")
    .max(6, "Must be exactly 6 digits")
    .required("Required Field"),
});

function ModalAddPharmacy(props) {
  const handleClose = () => {
    window.location.reload();
    props.open(false);
  };

  const [matchApiCall, setMatchApiCall] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [matchApiRes, setMatchApiRes] = useState(null);

  const dispatch = useDispatch();
  const res = useSelector((state) => state.addCorporate);
  
  const addPharmacy = useSelector((state) => state.pharmacy.addPharmacy);
  var startDateConverted = new Date(startDate);
  startDateConverted = startDateConverted.getTime();

  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(addPharmacy?.data || addPharmacy?.error)
    {

      setFlag(true);
    }

  },[addPharmacy])

  const handleSubmit = (values, { setSubmitting }) => {
    if(!startDate){

      Swal.fire({
        title: "select the validity",
        icon: "error",
      }).then(() => {
        
      });
    }
    else{

      const data = {
        ...values,
        pharmacyValidity: startDateConverted,
      };
      setMatchApiRes(null);
      setMatchApiCall(true);
      setTimeout(() => {
        setFlag(false)
        dispatch(addPharmacyRequest(data));
        setSubmitting(false);
      }, 1);
    }
    
  };

  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res]);

  // eslint-disable-next-line
  const [fieldDisable, setFieldDisable] = useState(false);

  useEffect(() => {
    dispatch(viewAllCityRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewCity = useSelector((state) => state.viewAllCity);

  if (addPharmacy?.data?.data?.status === 201) {
    props.open(false);
    Swal.fire({
      title: "Pharmacy Added Successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
      setMatchApiCall(false);
      setMatchApiRes(null);
    });
  } else if (addPharmacy?.error?.data?.response.status === 409)
    Swal.fire({
      title: addPharmacy?.error?.data?.response.data.errorMessage,
      icon: "error",
    }).then(() => {});

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add Pharmacy
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              name: "",
              contactNumber: "",
              licenseNumber: "",

              mapUrl: "",
              address: "",
              city: "",
              password: "",
              confirmPassword: "",
              contactName: "",
              pinCode: "",
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="Pharmacy Name"
                      name="name"
                      placeholder=" Pharmacy Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="contactName"
                      placeholder="Contact Person Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactName"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="contactEmail"
                      placeholder="Contact Person Email"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactEmail"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="contactPersonNumber"
                      placeholder="Contact Person number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactPersonNumber"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="licenseNumber"
                      placeholder="Licence Number"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="licenseNumber"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="pinCode"
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-"].includes(e.key) &&
                        e.preventDefault()
                      }
                      placeholder="Pincode"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select City
                      </option>
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="city"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="mapUrl"
                      placeholder=" Map URL"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="mapUrl"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Generate Password"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="password"
                      component="div"
                    />
                  </label>

                  <label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Retype Password"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="confirmPassword"
                      component="div"
                    />
                  </label>
                  <label className="DatePickerPharmacy">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        renderInput={(props) => (
                          <TextField {...props} style={{ width: "40%" }} />
                        )}
                        label="Pharmacy Validity"
                        value={startDate}
                        onChange={(newValue) => {
                          setstartDate(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </label>

                  <button type="submit"   disabled={!flag} className="modalButton">
                    {flag ? "        Add Pharmacy" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddPharmacy;
