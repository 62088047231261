import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Program/AssignProgramAction";
import NetworkService from "../../../../network/NetworkService";

export function* assignProgramSaga(action) {
  try {
    //
    let response = yield call(NetworkService.assignProgram, action);
    //
    yield put(actions.assignProgramSuccess(response));
  } catch (err) {
    yield put(actions.assignProgramFailure(err));
  }
}

export function* watchAssignProgramSaga() {
  yield takeLatest(types.ASSIGN_PROGRAM_REQUEST, assignProgramSaga);
}
