import * as types from "../../Config/Product/ProductActionType";

export const deleteSubCategoryRequest = (data) => {
  return {
    type: types.DELETE_SUB_CATEGORY_REQUEST,
    data,
  
  };
};

export const deleteSubCategorySuccess = (data) => {
  return {
    type: types.DELETE_SUB_CATEGORY_SUCCESS,
    data: data,
  };
};

export const deleteSubCategoryFailure = (data) => {
  return {
    type: types.DELETE_SUB_CATEGORY_FAILURE,
    data: data,
  };
};
