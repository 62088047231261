import React, { useEffect, useState } from "react";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewInPackagesRequest } from "../../../redux/Actions/Packages/viewInPackagesAction";
import "./ViewPackages.css";
import { Box } from "@mui/system";
import { CardContent, Grid } from "@mui/material";
import downArrow from "../../../assets/DownArrow.png"
import Collapsible from 'react-collapsible';
// import ExpandIcon from 'react-collapsible';
import upArrow from "../../../assets/upArrowNew.png"
import {updatePackageFeatureRequest} from "../../../redux/Actions/Packages/updatePackageFeatureAction"
import swal from "sweetalert";


function ViewPackages() {
  const { uuid } = useParams("uuid");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCollapse, setSelectedCollapse] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [feature, setFeature] = useState();

  useEffect(() => {
    dispatch(viewInPackagesRequest(uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const handleCard = (id) => {
    navigate(`/viewTest/${id}`);
  };
  let separatedArray = [];
  const agent = useSelector((state) => state.viewInPackages);
 
  const onOpen = () => {

  }

  useEffect(() => {

      setFeature(agent?.data?.data?.data.featured);
    
  }, [agent])
  const handleClick = (index) => {
    setSelectedCollapse(index === selectedCollapse ? null : index);
    setIsOpen(!isOpen);
  };

  function handleChange(){
    dispatch(updatePackageFeatureRequest(uuid));
  }
  
  // useEffect(()=>{
  //   let pilotFeature=agent?.data?.data?.data.featured;
  //   if(feature!==feature){
  //    
  //     pilotFeature=feature;
  //   }
  // },[feature])
  const updatePackageFeature=useSelector((state)=>state.updatePackageFeature);
 
  if(updatePackageFeature?.data?.data.status===201 && updatePackageFeature?.data?.data?.data.message!==""){
    swal({
      title: updatePackageFeature?.data?.data?.data.message,
      icon: "success",
    }).then(()=>{
      window.location.reload();
    });
  }
 var sum=0;
 agent.data?.data.data.attributeResponseDtoList.map((item)=>{
  if(item.parameterCount===0){
    sum=sum+1;
  }
  else{
    sum=sum+item.parameterCount;
  }
 })
  return (
    <div>
      <div className="insurance-main">
        <div className="insurance-id">
          {agent.data?.data.data.packageName}
        </div>
        <div className="box">
          <div className="desc-head">About This Package </div>
          <div className="insurance-box-head">
            <div className="packageDescription">
              {agent.data?.data.data.description}
            </div>
          </div>
          <hr />
          <div className="box-bodyy">
            <div className="detailss" >
              <div className="detailssChild">
                <div className="keyss">
                  Package Amount
                  <br />
                  <br />
                </div>
                <div className="insurance-value">
                  {agent.data?.data.data.packageCost}
                  <br />
                  <br />
                </div>
              </div>
              <div className="detailssChild">
                <div className="keyss">
                  Prerequisites
                  <br />
                  <br />
                </div>
                <div className="insurance-value">
                  {agent.data?.data.data.prerequisites}
                  <br />
                  <br />
                </div>
              </div>
              <div  className="featureDIV">
              <input  type="checkbox" name="checked" onChange={()=>{setFeature(!feature);handleChange()}} checked={feature} />
              <span style={{marginLeft:"1em"}}>Featured</span>
            </div>
            </div>
            
            <div className="PackagingTests"><span style={{ fontWeight: "600" }}>{sum} Lab Tests</span> In This Package</div>
            <div className="cardbox">
              <Box>
                <CardContent>
                  {agent.data?.data.data.attributeResponseDtoList.map(
                    (itm, index) => (

                      <>
                        <div className={selectedCollapse === index?"package-card-headnew":"package-card-head"} onClick={() => handleClick(index)}>
                          <Collapsible
                          className={selectedCollapse === index?"Collapsiblen2":"Collapsiblen1"}
                          trigger={`${itm.attributeName} - ${itm.parameterCount==0?1:itm.parameterCount} Tests`}

                            open={selectedCollapse === index}
                          >
                            
                            {itm.parameters.map((param, i) => (
                              <p className="collapseP" key={i}>
                                {param.parameterName}
                              </p>
                            ))}
                           {itm.parameterCount!==0?<img className="dropdownImage" src={selectedCollapse === index ? upArrow : downArrow}  onClick={() => handleClick(index)} />:""} 
                          </Collapsible>
                        </div>
                      </>
                    )
                  )}
                </CardContent>
              </Box>
            </div>

          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
export default ViewPackages;
