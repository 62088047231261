import * as types from "../../Config/actionType";

export const GetAllDiscountRequest = (
  page,
  pageSize,
  maxDiscount,
  minDiscount,
  search,
  enumType
) => {
  return {
    type: types.GET_ALL_DISCOUNT_REQUEST,
    page,
    pageSize,
    maxDiscount,
    minDiscount,
    search,
    enumType,
  };
};

export const GetAllDiscountSuccess = (data) => {
  return {
    type: types.GET_ALL_DISCOUNT_SUCCESS,
    data: data,
  };
};

export const GetAllDiscountFailure = (data) => {
  return {
    type: types.GET_ALL_DISCOUNT_FAILURE,
    data: data,
  };
};
