import React from "react";
import Chart from "react-apexcharts";
// import { BarChart } from '@mui/icons-material';

function SmokingTobacco_DonutChart({ smokingTobaccoResponseDto }) {
  const options = {
    labels: ["Non Smoker", "Smoker", "Tobacco Consumer", "Non TobaccoConsumer"],


    chart: {
      // padding:2,

      // height: 200,
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        barHeight: "85%",
        // borderRadius: 7,
        columnWidth: "20%",
      },
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      position: "bottom",
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      floating: false,
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 40,
      },
    },
    colors: ["#9C3D54", "#E2703A", "#03506F", "#EEB76B"],
  };

  const series = [
    {
      name: ["Smoking & Tobacco"],

      data: [
        smokingTobaccoResponseDto?.nonSmoker,
        smokingTobaccoResponseDto?.smoker,
        smokingTobaccoResponseDto?.tobaccoConsumer,
        smokingTobaccoResponseDto?.nonTobaccoConsumer,
      ],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="auto"
      className="apex-charts"
      dir="ltr"
    />
  );
}

export default SmokingTobacco_DonutChart;
