import * as types from "../../Config/Product/ProductActionType";

export const addProductRequest = (data) => {
  return {
    type: types.ADD_PRODUCT_REQUEST,
    data:data
  };
};

export const addProductSuccess = (data) => {
  return {
    type: types.ADD_PRODUCT_SUCCESS,
    data: data,
  };
};

export const addProductFailure = (data) => {
  return {
    type: types.ADD_PRODUCT_FAILURE,
    data: data,
  };
};
