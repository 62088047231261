import * as types from "../../Config/actionType";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

export const addAppointmentsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case types.ADD_APPOINTMENT_SUCCESS:
            return {
                ...initialState,
                data: action,
            }
        case types.ADD_APPOINTMENT_FAILURE:
            return {
                ...initialState,
                error: action
            }
        case types.ADD_APPOINTMENT_REQUEST:
            return {
                ...initialState,
                isLoading: true
            }
        default:
            return state
    }
}