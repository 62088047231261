import * as types from "../../Config/actionType";

export const GetUserPlanRequest = (data) => {
  return {
    type: types.GET_USER_PLAN_REQUEST,
    data: data,
  };
};

export const GetUserPlanSuccess = (data) => {
  return {
    type: types.GET_USER_PLAN_SUCCESS,
    data: data,
  };
};

export const GetUserPlanFailure = (data) => {
  return {
    type: types.GET_USER_PLAN_FAILURE,
    data: data,
  };
};
