import * as types from "../../Config/actionType"

export const viewAllLabRequest = (page,pageSize,search,city,status) => {
    return {
        type: types.VIEW_ALL_LAB_REQUEST,
        page,
        pageSize,
        search,
        city,
        status
    }
}

export const viewAllLabSuccess = (action) => {
    return {
        type: types.VIEW_ALL_LAB_SUCCESS,
        action
    }
}

export const viewAllLabFailure = (action) => {
    return {
        type: types.VIEW_ALL_LAB_FAILURE,
        action
    }
} 