import * as types from "../../Config/Pharmacy/PharmacyActionType";

export const AccountviewAllPaymentListingRequest = (
  page,
  pageSize,
  startDate,
  endDate,
  search,
  paymentStatus
) => {
  return {
    type: types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_REQUEST,
    page,
    pageSize,
    startDate,
    endDate,
    search,
    paymentStatus,
  };
};

export const AccountviewAllPaymentListingSuccess = (data) => {
  return {
    type: types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_SUCCESS,
    data: data,
  };
};

export const AccountviewAllPaymentListingFailure = (data) => {
  return {
    type: types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_FAILURE,
    data: data,
  };
};
