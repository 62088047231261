import * as types from "../../Config/actionType"

export const AddBookingRequest = (data) => {
    return {
        type: types.ADD_BOOKING_REQUEST,
        data:data,
    }
}

export const AddBookingSuccess = (data) => {
    return {
        type: types.ADD_BOOKING_SUCCESS,
        data:data,
    }
}

export const AddBookingFailure = (data) => {
    return {
        type: types.ADD_BOOKING_FAILURE,
        data:data,
    }
}