import * as types from "../../../Config/actionType";

export const networkViewProfessionalServiceRequest = (id) => {
  return {
    type: types.NETWORK_VIEW_PROFESSIONAL_SERVICES_REQUEST,
    id
  };
};

export const networkViewProfessionalServiceSuccess = (data) => {
  return {
    type: types.NETWORK_VIEW_PROFESSIONAL_SERVICES_SUCCESS,
    data: data,
  };
};

export const networkViewProfessionalServiceFailure = (data) => {
  return {
    type: types.NETWORK_VIEW_PROFESSIONAL_SERVICES_FAILURE,
    data: data,
  };
};