import * as types from "../../Config/Product/ProductActionType";

export const getUnitsRequest = (data) => {
  return {
    type: types.GET_UNITS_REQUEST,
    data
  };
};

export const getUnitsSuccess = (data) => {
  return {
    type: types.GET_UNITS_SUCCESS,
    data: data,
  };
};

export const getUnitsFailure = (data) => {
  return {
    type: types.GET_UNITS_FAILURE,
    data: data,
  };
};
