import * as types from "../../Config/Product/ProductActionType";

export const getAllSubCategoryRequest = (pageNo, pageSize, id, search) => {
  return {
    type: types.GET_ALL_SUB_CATEGORY_REQUEST,
    pageNo,
    pageSize,
    id,
    search,
  };
};

export const getAllSubCategorySuccess = (data) => {
  return {
    type: types.GET_ALL_SUB_CATEGORY_SUCCESS,
    data: data,
  };
};

export const getAllSubCategoryFailure = (data) => {
  return {
    type: types.GET_ALL_SUB_CATEGORY_FAILURE,
    data: data,
  };
};
