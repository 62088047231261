import * as types from "../../Config/actionType"

export const AssignProgramToInsurerRequest = (data) => {
    //
    return {
        type: types.ASSIGN_PROGRAM_TO_INSURER_REQUEST,
        data
    }
}

export const AssignProgramToInsurerSuccess = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_TO_INSURER_SUCCESS,
        action
    }
}

export const AssignProgramToInsurerFailure = (action) => {
    return {
        type: types.ASSIGN_PROGRAM_TO_INSURER_FAILURE,
        action
    }
}