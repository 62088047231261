import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Tests/viewAllTestAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllTestSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllTest, action);
    yield put(actions.viewAllTestSuccess(response));
  } catch (err) {
    yield put(actions.viewAllTestFailure(err));
  }
}

export function* watchViewAllTestSaga() {
  yield takeLatest(types.VIEW_ALL_TEST_REQUEST, viewAllTestSaga);
}