import * as types from "../../Config/actionType"

export const CorporateDashboardRequest = (startAge,endAge) => {
    return {
        type: types.DASHBOARD_DATA_REQUEST,
        startAge,
        endAge,
    }
}

export const CorporateDashboardSuccess = (data) => {
    return {
        type: types.DASHBOARD_DATA_SUCCESS,
        data:data,
    }
}

export const CorporateDashboardFailure = (data) => {
    return {
        type: types.DASHBOARD_DATA_FAILURE,
        data:data,
    }
}