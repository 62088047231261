import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Diagnostic/NetworkViewDiagnosticAction";
import NetworkService from "../../../../network/NetworkService";

export function* networkViewDiagnosticSaga(action) {
 
  try {
    let response = yield call(NetworkService.networkViewDiagnostic, action);
    yield put(actions.networkViewDiagnosticSuccess(response));
  } catch (err) {
    yield put(actions.networkViewDiagnosticFailure(err));
  }
}

export function* watchNetworkViewDiagnosticSaga() {
  yield takeLatest(
    types.NETWORK_VIEW_DIAGNOSTIC_REQUEST,
    networkViewDiagnosticSaga
  );
}
