import React, { useState, useEffect } from "react";
import "./Title_Description.css";
function Title_Description(prop) {
  const {
    getData_Title_Description,
    templateName,
    templateDescription,
    onClear,
    programTitle,
    programDescription,
    setProgramTitle,
    setProgramDescription,
  } = prop;
 

  if (programTitle?.length === 0 || programDescription?.length === 0) {
    getData_Title_Description(templateName, templateDescription);
  } else {
    getData_Title_Description(programTitle, programDescription);
  }
  useEffect(() => {
    if (onClear === null) {
     
      setProgramTitle("");
      setProgramDescription("");
    }
  }, [onClear]);

  useEffect(() => {
    if (templateName?.length > 0) {
      setProgramTitle(templateName);
    }
    if (templateDescription?.length > 0) {
      setProgramDescription(templateDescription);
    }
  }, [templateName, templateDescription]);
  return (
    <div className="textbox">
      <div className="corporateNameBox1">
      <div className="text">
        {" "}
        <textarea
          rows="1"
          cols="60"
          className="textarea1"
          type="text"
          placeholder="Program Title"
          defaultValue={templateName}
          onBlur={(e) => setProgramTitle(e?.target?.value)}
        />
      </div>

        </div>
      

      <div className="text">
        <textarea
          rows="2"
          cols="60"
          className="textarea2"
          placeholder="Program Description"
          defaultValue={templateDescription}
          onBlur={(e) => setProgramDescription(e?.target?.value)}
        />
        
        

      </div>
    </div>
  );
}

export default Title_Description;
