import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Dashboard/HospitalDashboard/AllHospitalNameDropdown"
import NetworkService from "../../../../network/NetworkService";

export function* AllHospitalNameDropdownSaga(action) {
  try {
    let response = yield call(NetworkService.AllHospitalNameDropdown, action);
    yield put(actions.AllHospitalNameDropDownSuccess(response));
  } catch (err) {
    yield put(actions.AllHospitalNameDropDownFailure(err));
  }
}

export function* watchAllHospitalNameDropdownSaga() {
  yield takeLatest(types.ALL_HOSPITAL_NAME_DROPDOWN_REQUEST, AllHospitalNameDropdownSaga);
}