import * as types from "../../Config/actionType"

export const programAndPlanRequest = (data) => {
   
    return {
        type: types.PROGRAM_AND_PLAN_REQUEST,
        data,
       
    }
}

export const programAndPlanSuccess = (data) => {
    return {
        type: types.PROGRAM_AND_PLAN_SUCCESS,
        data:data,
    }
}

export const programAndPlanFailure = (data) => {
    return {
        type: types.PROGRAM_AND_PLAN_FAILURE,
        data:data,
    }
}