import * as types from "../../Config/actionType"

export const addHospitalRequest = (data) => {
    return {
        type: types.ADD_HOSPITAL_REQUEST,
        data:data
    }
}

export const addHospitalSuccess = (data) => {
    return {
        type: types.ADD_HOSPITAL_SUCCESS,
        data:data,
    }
}

export const addHospitalFailure = (data) => {
    return {
        type: types.ADD_HOSPITAL_FAILURE,
        data:data,
    }
}