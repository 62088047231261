import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/InHouseDoctor/getDosageTypeAction"
import NetworkService from "../../../network/NetworkService";

export function* getDosageTypeSaga(action) {
  try {
      //
    let response = yield call(NetworkService.getDosageType, action);
    yield put(actions.getDosageTypeSuccess(response));
  } catch (err) {
    yield put(actions.getDosageTypeFailure(err));
  }
}

export function* watchGetDosageTypeSaga() {
  yield takeLatest(types.GET_DOSAGE_TYPE_REQUEST, getDosageTypeSaga);
}