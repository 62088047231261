import { CardContent, Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Box } from "@mui/system";
import "./Services.css";
import Footer from "../../Organisms/Footer/FooterUpper";
import { viewAllServiceRequest } from "../../../redux/Actions/Service/viewAllServiceAction";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../Utils/storage";
import { viewAllServiceForCorporateRequest } from "../../../redux/Actions/Service/viewAllServiceForCorporateAction";
import { roles } from "../../../helpers/MainConstants";
import { UserContext } from "../../../App";

function Services() {
  const dispatch = useDispatch();
  const role = useContext(UserContext);

  const fetchServiceData = () => {
    if (role === roles.SUPER_ADMIN || role === "INSURER") {
      dispatch(viewAllServiceRequest());
    } else {
      dispatch(viewAllServiceForCorporateRequest());
    }
  };

  useEffect(() => {
    fetchServiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const service = useSelector((state) => state.viewAllService);

  const numService = service.data?.action?.data.data.length;
  const ser = useSelector((state) => state.viewAllServiceForCorporate);

  // const handleClick = (uuid) => {
  //   navigate(`/viewService/${uuid}`);
  // };

  return (
    <div>
      <div className="servicename">Services</div>
      <div className="servicemainBox">
        <div className="servicehead">
          <div className="leftHead">
            <h5 className="servicelist">List of Services</h5>
            <div className="servicecount">{numService} Services</div>
          </div>
        </div>
        <hr />
        <div className="card-box">
          <Box>
            <CardContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 3, lg: 5 }}
                direction="row"
              >
                {(role === roles.SUPER_ADMIN || role === "INSURER") &&
                  service.data?.action?.data?.data?.map((data) => (
                    <div
                      className="cards"
                      // onClick={() => handleClick(data.serviceUuid)}
                    >
                      <div className="card-head">{data.name}</div>
                      <div className="card-body"></div>
                    </div>
                  ))}
                {role === "CORPORATE_HR" &&
                  ser.data?.action?.data?.data?.yuvaServices.map((data) => (
                    <div
                      className="cards"
                      // onClick={() => handleClick(data.serviceUuid)}
                    >
                      <div className="card-head">{data.name}</div>

                      <div className="card-body"></div>
                    </div>
                  ))}
              </Grid>
            </CardContent>
          </Box>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Services;
