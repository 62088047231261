import "./Modal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateCorporateRequest } from "../../../redux/Actions/Corporate/updateCorporateAction";
import { Close } from "@mui/icons-material";
import Swal from "sweetalert2";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import swal from "sweetalert";
import storage from "../../../Utils/storage";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
const loginSchema = Yup.object().shape({
  // name: Yup.string()
  //   .required("Required Field")
  //   .matches(/^(?![.])[A-Za-z . & , ]+$/, "Insert only normal character"),
  contactNumber: Yup.string()
    .required("Required Field")
    .matches(phoneRegExp, "Phone number is not valid"),

  email: Yup.string().required("Required Field").email("Not Valid"),
  // city: Yup.string().required("Required Field"),
  // contactName: Yup.string()
  //   .required("Required Field")
  //   .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  // address: Yup.string().required("Required Field"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
  // contactPersonAddress: Yup.string().required("Required Field"),
  // pinCode: Yup.string()
  //   .matches(/^[0-9\b]+$/, "Enter valid pincode")
  //   .min(6, "Must be exactly 6 digits")
  //   .max(6, "Must be exactly 6 digits")
  //   .required("Required Field"),
});

function ModalUpdateCorporate(props) {
  const [recvEmail, setRecvEmail] = useState();

  useEffect(() => {
    dispatch(viewAllCityRequest());
  }, []);

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const emailDetails = emailDetail?.data?.data.data;

  const handleClose = () => props.open(false);
  const [matchApiCall, setMatchApiCall] = useState(false);

  const [matchApiRes, setMatchApiRes] = useState(null);
  const res = useSelector((state) => state.updateCorporate);
  const dispatch = useDispatch();

  const citiesId = storage.getStorage("cityId");

  const [selectCity, setSelectCity] = useState(citiesId);
  const updateRes = useSelector((state) => state.updateCorporate)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    setMatchApiRes(null);
    setMatchApiCall(true);

    if (isPasswordFirstClick) {
      const value = {
        ...values,
        password: values.password,
        cityId: selectCity,
      };
      setTimeout(() => {
        dispatch(updateCorporateRequest(value));
        setSubmitting(false);
      }, 400);
    } else {
      const value = {
        ...values,
        password: props?.value?.password,
        cityId: selectCity,
      };
      setTimeout(() => {
        dispatch(updateCorporateRequest(value));
        setSubmitting(false);
      }, 400);
    }
  };

  if (res.data?.data.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Corporate details updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if (matchApiCall) {
      if (matchApiRes !== null && matchApiRes) {
        handleEmailMobileValidation();
      }
    }
  }, [matchApiCall, matchApiRes]);
  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
  }, [res]);

  const handleEmailMobileValidation = () => {
    if (matchApiRes?.data?.data?.status === 201) {
      props.open(false);
      Swal.fire({
        title: "Corporate details updated successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
        // setSwal(false);
        setMatchApiCall(false);
        setMatchApiRes(null);
      });
    } else if (
      matchApiRes?.error !== null &&
      matchApiRes?.error?.data?.response !== 201 &&
      matchApiRes?.error?.data?.response?.data !== null
    ) {
      swal({
        title: "" + Object.values(matchApiRes?.error?.data?.response?.data),
        icon: "error",
      }).then(() => {
        setMatchApiCall(false);
        setMatchApiRes(null);

        props.open(true);
      });
    }
  };

  const viewCity = useSelector((state) => state.viewAllCity);

  const [isPasswordFirstClick, setIsPasswordFirstClick] = useState(false);

  var id;
  viewCity.data?.data?.data?.data.map((item, index) => {
    if (item.name === props.value.city) {
      id = item.id;
    }
  });

  const handleChange = (e) => {
    setSelectCity(e.target.value);
  };

  const handleCheckEmail = (event) => {
    if (event.target.value !== props.value?.contactEmail) {
      setRecvEmail(event.target.value);
    }
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Update Corporate
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              name: props.value.name,
              contactNumber: props.value.contactPersonNumber,
              // city: props.value.city,
              email: props.value.contactPersonEmail,
              contactName: props.value.contactPersonName,
              address: props.value.address,
              id: props.value.id,
              password: props.value.password.slice(0, 6),
              confirmPassword: props.value.password.slice(0, 6),
              // contactPersonAddress: props.value.contactPersonAddress,
              pinCode: props.value.pinCode,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <div className="modalInfo">
                    <div className="modalInfoLabels">
                      <label>Name</label>
                      <label>Address</label>
                      <label>PinCode</label>
                      <label>Contact Name</label>
                      
                    </div>
                    <div className="modalInfoValues">
                      <label>: {props.value.name}</label>
                      <label>
                        : {props.value.address},{props.value.city}
                      </label>
                      <label>: {props.value.pinCode}</label>
                      <label>: {props.value.contactPersonName}</label>
                    </div>
                  </div>

                  {/* <label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Corporate Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label> */}
                  {/* <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Corporate Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    /> 
                  </label> 
                  <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                      onChange={handleChange}
                    >
                      
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option
                          key={index}
                          value={item.id}
                          selected={item?.name === props?.value?.city}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </label> 
                  <label>
                    <Field
                      type="number"
                      name="pinCode"
                      placeholder="Pin Code"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label> 
                  <label>
                    <Field
                      type="text"
                      name="contactName"
                      placeholder="Contact Person"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactName"
                      component="div"
                    /> 
                  </label>*/}
                  <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Contact Person Email"
                      className="inputField"
                      onBlur={(e) => {
                        if (e.target.value !== props.value?.contactEmail) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        } else {
                          handleCheckEmail(e);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Contact Person Mobile"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>
                  {/* <label>
                    <Field
                      type="text"
                      name="contactPersonAddress"
                      placeholder="Contact Person Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactPersonAddress"
                      component="div"
                    />
                  </label> */}
                  <label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Generate Password"
                      className="inputField"
                      onClick={() => {
                        if (!isPasswordFirstClick) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="password"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Retype Password"
                      className="inputField"
                      disabled={!isPasswordFirstClick}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="confirmPassword"
                      component="div"
                    />
                  </label>

                  <button type="submit"   disabled={!flag} className="modalButton">
                  

                    {flag ? "    Update Corporate":
                    
                    <CircularProgress/>
                    }
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdateCorporate;
