import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Employee/viewEmployeeDetailsAction";
import NetworkService from "../../../network/NetworkService";

export function* viewEmployeeDetail(action) {
  try {
    let response = yield call(NetworkService.viewEmployeeDetail, action);
    yield put(actions.viewEmployeeDetailSuccess(response));
  } catch (err) {
    yield put(actions.viewEmployeeDetailFailure(err));
  }
}

export function* watchViewEmployeeDetailSaga() {
  yield takeLatest(types.VIEW_EMPLOYEE_DETAIL_REQUEST, viewEmployeeDetail);
}

