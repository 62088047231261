import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/NetworkService";
import * as actions from "../../Actions/Accounts/ViewAllPharmacyListingAction";
import * as types from "../../Config/Pharmacy/PharmacyActionType";

export function* AccountviewAllPharmacyListing(action) {
  try {
    let response = yield call(
      NetworkService.AccountviewAllPharmacyListing,
      action
    );
    yield put(actions.AccountviewAllPaymentListingSuccess(response));
  } catch (err) {
    yield put(actions.AccountviewAllPaymentListingFailure(err));
  }
}

export function* watchAccountViewAllPharmacyLising() {
  yield takeEvery(
    types.ACCOUNT_VIEW_ALL_PHARMACY_LISTING_REQUEST,
    AccountviewAllPharmacyListing
  );
}
