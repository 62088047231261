import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/getPlansDropdownForCard";
import NetworkService from "../../../network/NetworkService";

export function* getPlanDropdownForCardSaga(action) {
  try {
    let response = yield call(NetworkService.getPlanDropdownForCard, action);
    yield put(actions.getPlanDropdownForCardSuccess(response));
  } catch (err) {
    yield put(actions.getPlanDropdownForCardFailure(err));
  }
}

export function* watchGetPlanDropdownForCardSaga() {
  yield takeLatest(types.PLANS_DROPDOWN_FOR_CARD_REQUEST, getPlanDropdownForCardSaga);
}