import React from "react";
import Chart from "react-apexcharts";
import "./AllCharts.css";
// import { BarChart } from '@mui/icons-material';

function SafetyHabit_BarChart({ safetyResponseDto }) {
  const options = {
    labels: ["Both Yes", "helmet yes and seat belt no","helmet no and seat belt yes","Both No"],

    chart: {
     
      type: "bar",
      offsetY: 16,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        barHeight: "85%",
        borderRadius: 7,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      // floating: true,
      horizontalAlign: "left",
      align: "center",
verticalAlign: 'left',
padding:122
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      position: "top",
    },
    yaxis: {
      show: false,
    },
    grid: {
      padding: {
        top: 0,
        right: 40,
        bottom: 0,
        left: 0,
      },
    },
    colors: ["#319B4B", "#95990C", "#38B9AE", "#9D3D3D"],
  };

  const series = [
    {
name:"Safety Habits",

      data: [
        safetyResponseDto?.bothYes,
        safetyResponseDto?.helmetYes,
        safetyResponseDto?.seatBeltYes,
        safetyResponseDto?.bothNo
        ,
      ],
    },
  ];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="auto"
      className="apex-charts"
      dir="ltr"
    />
  );
}
export default SafetyHabit_BarChart;
