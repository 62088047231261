import * as types from "../../Config/LifeStyle/lifeStyleActionType"

export const UpdateLifeStyleRequest = (data) => {
    return {
        type: types.UPDATE_LIFESTYLE_REQUEST,
        data:data
    }
}

export const UpdateLifeStyleSuccess = (data) => {
    return {
        type: types.UPDATE_LIFESTYLE_SUCCESS,
        data:data,
    }
}

export const UpdateLifeStyleFailure = (data) => {
    return {
        type: types.UPDATE_LIFESTYLE_FAILURE,
        data:data,
    }
}