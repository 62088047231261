import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { networkViewDiagnosticRequest } from "../../../../redux/Actions/Network/Diagnostic/NetworkViewDiagnosticAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./diagnosticRequest.css";
import swal from "sweetalert";
import { approveDiagnosticRequest } from "../../../../redux/Actions/Lab/approveDiagnosticAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Loader from "../../../../assets/loader.gif";

function ViewDiagnosticRequest() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [location, setLocation] = React.useState("");
  const [error, setError] = React.useState(false);

  useEffect(() => {
    dispatch(networkViewDiagnosticRequest(id));
  }, []);

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };
  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };
  const handleApprove = (status) => {
    if (location.length === 0 && status === "APPROVED") {
      setError(true);
    } else {
      dispatch(approveDiagnosticRequest(id, status, location));
    }
  };

  const approveDiagnostic = useSelector((state) => state.approveDiagnostic);
  const networkViewDiagnostic = useSelector(
    (state) => state.network.networkViewDiagnostic
  );

  if (approveDiagnostic.data?.status === 201) {
    swal({
      title: approveDiagnostic?.data?.data.message,
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (approveDiagnostic?.error?.action?.response?.status === 409) {
    swal({
      title: approveDiagnostic?.error?.action?.response.data.errorMessage,
      icon: "error",
    }).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      {approveDiagnostic.isLoading ? (
        <>
          <div className="corporateDashboardLoading">
            <img alt="Loader" src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
        <>
          <div className="requestPage">
            <h3 className="requestHead">
              Diagnostic Requests{" "}
              {networkViewDiagnostic.data?.data.data.data.networkStatus ===
                "APPROVED" && <div className="approvedTile">APPROVED</div>}
              {networkViewDiagnostic.data?.data.data.data.networkStatus ===
                "DECLINED" && <div className="approvedTileC">DECLINED</div>}
              {networkViewDiagnostic.data?.data.data.data.networkStatus ===
                "PENDING" && <div className="approvedTileP">PENDING</div>}
            </h3>

            <div className="requestBox">
              <div className="requestTextHospital2">
                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Email:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.email}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name:</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewDiagnostic.data?.data.data.data
                        .diagnosticCenterName
                    }
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Entity:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.entityType}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Name of the Owner:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.ownerName}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.address}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Location:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.location}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Address:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.address}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">City:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.city}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">PIN:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.pinCode}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Contact Number:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.number}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Services:</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.services}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">
                    {" "}
                    List of Outsource Service:
                  </h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data
                      .outsourceService === ""
                      ? "-"
                      : networkViewDiagnostic.data?.data.data.data
                          .outsourceService}
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Rate List</h1>
                  <h1 className="requestPageData">
                    {networkViewDiagnostic.data?.data.data.data.rateListName}

                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewDiagnostic.data?.data.data.data.rateList
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                <div className="requestPageInfowrapper">
                  <h1 className="requestPageInfo">Center Photo</h1>
                  <h1 className="requestPageData">
                    {
                      networkViewDiagnostic.data?.data.data.data
                        .diagnosticCenterPhotoName
                    }{" "}
                    <VisibilityIcon
                      onClick={() =>
                        handleView(
                          networkViewDiagnostic.data?.data.data.data
                            .diagnosticCenterPhoto
                        )
                      }
                      style={{
                        height: "30px",
                        width: "55px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        color: "#52608E",
                      }}
                    />
                  </h1>
                </div>

                {networkViewDiagnostic.data?.data.data.data.networkStatus ===
                  "PENDING" && (
                  <>
                    <div className="requestPageInfowrapper">
                      <h1 className="requestPageInfo">Location URL</h1>
                      <div className="LocationIconMui">
                        {" "}
                        <LocationOnIcon />{" "}
                      </div>
                      <input
                        value={location}
                        onChange={handleChangeLocation}
                        placeholder="Enter Location Url"
                        className="LocationInputField"
                      ></input>

                      {error && location.length <= 0 ? (
                        <label className="SelectTypeUrlValidation">
                          Please Enter The Location URL*
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </div>

              {networkViewDiagnostic.data?.data.data.data.networkStatus ===
                "PENDING" && (
                <div className="requestButton">
                  <button
                    className="requestApprove"
                    onClick={() => {
                      handleApprove("APPROVED");
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="requestDecline"
                    onClick={() => {
                      handleApprove("DECLINED");
                    }}
                  >
                    Decline
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ViewDiagnosticRequest;
