import React, { useEffect } from "react";
import "./ViewAgent.css";
// import Footer from "../../Organisms/Footer/Footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewAgentDetailRequest } from "../../../redux/Actions/Agents/viewAgentsDetailAction";
import { viewAllProgramOfInsurerRequest } from "../../../redux/Actions/Agents/viewAllProgramOfInsurer";
import { getProgramInsurerRequest } from "../../../redux/Actions/Insurer/getProgramInsurerAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import InsurerProgramAssignView from "./InsurerProgramAssignView.js";

function ViewAgent() {
  const [edit, setEdit] = React.useState(false);
  const [values, setValues] = React.useState();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(viewAgentDetailRequest(id));
    dispatch(viewAllProgramOfInsurerRequest(id));
    dispatch(getProgramInsurerRequest(id));
  }, [id]);

  const navigate = useNavigate();
  const service = useSelector((state) => state.viewAllProgramOfInsurer);
  const getAllProgram = useSelector((state) => state.getProgramInsurer);
  // const agent = useSelector((state) => state.viewAgentDetail);
  // const insurerId = agent?.data?.data?.data?.id;
  const getAllProgramFinal = getAllProgram?.data?.data?.data;
  const handleClick = (value) => {
    setValues(value);
    setEdit(1);
  };

  const handleProgramClick = (name) => {
    navigate(`/viewProgram/${name}`);
  };

  const handleAssign = () => {
    navigate("/programAssignInsurer", {
      state: {
        id: id,
        name: agent.data?.data.data.name,
      },
    });
  };

  const agent = useSelector((state) => state.viewAgentDetail);

  const handleView = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div>
      <div className="insurance-main">
        <div className="insurance-id">
          Insurer Id - {agent.data?.data.data.id}
        </div>
        <div className="box">
          <div className="insurance-box-head1">
            <div className="agentHeaderName">{agent.data?.data.data.name}</div>
            {agent.data?.data.data.active === false && (
              <div className="disableChip">DISABLED</div>
            )}
            {/* <div className="edit">
              <EditIcon fontSize="medium" />
              <button
                onClick={() => handleClick(agent.data?.data.data)}
                className="editBtn"
              >
                Edit
              </button>
              {edit && values && (
              <ModalUpdateInsuranceAgent value={values} open={setEdit} />
            )}
            </div> */}
          </div>
          <hr />
          <div className="box-bodyy">
            <div className="insurer-details">
              <div className="keyss">
                Contact Number
                <br />
                <br />
                Official Email
                <br />
                <br />
                Insurance Company
                <br />
                <br />
                {agent.data?.data.data.pinCode !== null && <>Pincode</>}
                <br />
                <br />
                {agent.data?.data.data.city !== null && <>City</>}
                <br />
                <br />
                {agent.data?.data.data.address !== null && <>Address</>}
              </div>
              <div className="insurance-value">
                {agent.data?.data.data.number}
                <br />
                <br />
                {agent.data?.data.data.email}
                <br />
                <br />
                {agent.data?.data.data.companyName}
                <br />
                <br />
                {agent.data?.data.data.pinCode !== null &&
                  agent.data?.data.data.pinCode}
                <br />
                <br />
                {agent.data?.data.data.city !== null &&
                  agent.data?.data.data.city}
                <br />
                <br />
                {agent.data?.data.data.address !== null &&
                  agent.data?.data.data.address}
              </div>
            </div>
            <hr />
            {agent.data?.data.data.active === false ? (
              <div style={{ marginTop: "5em" }}>
                <div className="grid-head">
                  <div className="hospitalDoctor">Disable Reason</div>
                </div>
                <div className="disableReason">
                  {agent.data?.data.data.disableReason}
                </div>
                <div className="disableReasonFile">
                  {agent.data?.data.data.fileName}
                  <VisibilityIcon
                    onClick={() => handleView(agent.data?.data.data.filePath)}
                    style={{
                      marginLeft: 10,
                      cursor: "pointer",
                      color: "#52608E",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="buttons">
                <button className="btnAssign" onClick={handleAssign}>
                  <span style={{ color: "#ffffff" }}>Assign New Program</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {service?.data?.action?.data.data.length !== 0 &&
        agent.data?.data.data.active ? (
          <InsurerProgramAssignView
            insurerId={id}
            insurerViewAssignProgramData={getAllProgramFinal}
          />
        ) : (
          <></>
        )}
      </div>
      <br />
      <br />
      <br />

      <Footer />
    </div>
  );
}
export default ViewAgent;
