import * as types from "../../Config/actionType"

export const deleteProgramRequest = (data) => {
   
    return {
        type: types.DELETE_PROGRAM_REQUEST,
        data:data
    }
}

export const deleteProgramSuccess = (data) => {
   
    return {
        type: types.DELETE_PROGRAM_REQUEST,
        data:data,
    }
}

export const deleteProgramFailure = (data) => {
   
    return {
        type: types.DELETE_PROGRAM_FAILURE,
        data:data,
    }
}