import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/viewAllProgramOfInsurer";
import NetworkService from "../../../network/NetworkService";

export function* viewAllProgramOfInsurerSaga(action) {
  try {
    let response = yield call(NetworkService.viewAllProgramOfInsurer, action);
    yield put(actions.viewAllProgramOfInsurerSuccess(response));
  } catch (err) {
    yield put(actions.viewAllProgramOfInsurerFailure(err));
  }
}

export function* watchViewAllProgramOfInsurerSaga() {
  yield takeLatest(types.VIEW_ALL_PROGRAM_OF_INSURER_REQUEST, viewAllProgramOfInsurerSaga);
}