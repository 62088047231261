import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Corporate/getAssignProgramAction";
import NetworkService from "../../../network/NetworkService";

export function* corporateGetAssignProgramSaga(action) {

  try {
     
    let response = yield call(NetworkService.corporateGetAssignProgramNetwork, action);
    yield put(actions.corporateGetAssignProgramSuccess(response));
  } catch (err) {
    yield put(actions.corporateGetAssignProgramFailure(err));
  }
}

export function* watchcorporateGetAssignProgramSaga() {
  yield takeLatest(types.CORPORATE_GET_ASSIGN_PROGRAM_REQUEST, corporateGetAssignProgramSaga);
}