import * as types from "../../../Config/actionType"

export const getProgramTemplateRequest = (data) => {
    return {
        type: types.GET_PROGRAM_TEMPLATE_REQUEST,
        data
    }
}

export const getProgramTemplateSuccess = (action) => {
   
    return {
        type: types.GET_PROGRAM_TEMPLATE_SUCCESS,
        action
    }
}

export const getProgramTemplateFailure = (action) => {
   
    return {
        type: types.GET_PROGRAM_TEMPLATE_FAILURE,
        action
    }
}