import { call, put, takeLatest } from "redux-saga/effects";
import NetworkService from "../../../network/NetworkService";
import * as actions from "../../Actions/Discount/GetAllDiscountAction";
import * as types from "../../Config/actionType";

export function* getAllDiscountSaga(action) {

  try {
    let response = yield call(NetworkService.getAllDiscount, action);
    yield put(actions.GetAllDiscountSuccess(response));
  } catch (err) {
    yield put(actions.GetAllDiscountFailure(err));
  }
}

export function* watchGetAllDiscountSaga() {
  yield takeLatest(types.GET_ALL_DISCOUNT_REQUEST, getAllDiscountSaga);
}
