import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getSubCategoryDetailsAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getSubCategoryDetailsSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.getSubCategoryDetails, action);
   
    yield put(actions.getSubCategoryDetailsSuccess(response));
  } catch (err) {
   
    yield put(actions.getSubCategoryDetailsFailure(err));
  }
}

export function* watchGetSubCategoryDetailsSaga() {
 
    yield takeLatest(types.GET_SUB_CATEGORY_DETAILS_REQUEST, getSubCategoryDetailsSaga);

}
