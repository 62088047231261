import * as types from "../../Config/actionType"

export const viewInLabRequest = (data) => {
    return {
        type: types.VIEW_IN_LAB_REQUEST,
        data:data
    }
}

export const viewInLabSuccess = (data) => {
    return {
        type: types.VIEW_IN_LAB_SUCCESS,
        data:data,
    }
}

export const viewInLabFailure = (data) => {
    return {
        type: types.VIEW_IN_LAB_FAILURE,
        data:data,
    }
}