import * as types from "../../Config/actionType"

export const AssignLabRequest = (bookingid,labid) => {
    return {
        type: types.ASSIGN_LAB_REQUEST,
        bookingid,
        labid
    }
}

export const AssignLabSuccess = (data) => {
    return {
        type: types.ASSIGN_LAB_SUCCESS,
        data:data,
    }
}

export const AssignLabFailure = (data) => {
    return {
        type: types.ASSIGN_LAB_FAILURE,
        data:data,
    }
}