import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewBookingRequest } from "../../../redux/Actions/Booking/viewBookingAction";
import ConfirmedBooking from "../../Organisms/Booking/ConfirmedBooking";
import FinalBookingPage from "../../Organisms/Booking/FinalBookingPage";
import InitiatedBooking from "../../Organisms/Booking/InitiatedBooking";
import UnpaidView from "../../Organisms/Booking/UnpaidView";
import Footer from "../../Organisms/Footer/FooterUpper";
import "./ViewBooking.css";

function ViewBooking() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(viewBookingRequest(id));
  }, [id]);

  const res = useSelector((state) => state.viewBooking);

  return (
    <div>
      {(res.data?.data.data.bookingStatus === "INITIATED" ||
        res.data?.data.data.bookingStatus === "CANCELLED") && (
        <InitiatedBooking res={res} />
      )}
      {res.data?.data.data.bookingStatus === "CONFIRMED" && (
        <ConfirmedBooking res={res} />
      )}
      {res.data?.data.data.bookingStatus === "RESCHEDULED" && (
        <ConfirmedBooking res={res} />
      )}
      {res.data?.data.data.bookingStatus === "COMPLETED" && (
        <FinalBookingPage res={res} upload={true} />
      )}
      {res.data?.data.data.bookingStatus === "FINISHED" && (
        <FinalBookingPage res={res} upload={false} />
      )}
        {res.data?.data.data.bookingStatus === "UNPAID" && (
        <UnpaidView res={res} upload={true} />
      )}
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewBooking;
