import * as types from "../../Config/Product/ProductActionType";

export const editProductRequest = (data) => {
  return {
    type: types.EDIT_PRODUCT_REQUEST,
    data:data
  };
};

export const editProductSuccess = (data) => {
  return {
    type: types.EDIT_PRODUCT_SUCCESS,
    data: data,
  };
};

export const editProductFailure = (data) => {
  return {
    type: types.EDIT_PRODUCT_FAILURE,
    data: data,
  };
};
