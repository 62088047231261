import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, InputAdornment, Pagination, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { viewAllCityRequest } from "../../../../redux/Actions/City/viewAllCityAction";
import { networkClinicRequest } from "../../../../redux/Actions/Network/Clinic/NetworkClinicAction";
import storage from "../../../../Utils/storage";
import AutoComplete from "../../../Molecules/Autocomplete/AutoComplete";
import Footer from "../../../Organisms/Footer/FooterUpper";

function ClinicRequest() {
  const columns = [
    {
      field: "clinicName",
      headerClassName: "super-app-theme--header",
      headerName: "Clinic Name",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "clinicType",
      headerClassName: "super-app-theme--header",
      headerName: "Clinic Type",
      minWidth: 150,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "number",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "ownerName",
      headerClassName: "super-app-theme--header",
      headerName: "Owner Name",
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },

    {
      field: "Modify",
      minWidth: 120,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const city = storage.getStorage("cityId");
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [status, setStatus] = React.useState("");

  const [city, setCity] = useState(storage.getStorage("cityId"));

  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const viewCity = useSelector((state) => state.viewAllCity);
 
  const networkClinic = useSelector((state) => state.network.networkClinic);
 

  if (city === null) {
    setCity("");
  }
  useEffect(() => {
    if (city === null) {
      dispatch(networkClinicRequest(page, pageSize, searchText, "", status));
    } else {
      dispatch(networkClinicRequest(page, pageSize, searchText, city, status));
    }
    dispatch(viewAllCityRequest());
  }, [city]);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(networkClinicRequest(page, pageSize, newSearchText, city, status));
      setPage(1);
    } else {
      dispatch(
        networkClinicRequest(searchPage, pageSize, newSearchText, city, status)
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = (id) => {
    navigate(`/viewClinicRequest/${id}`);
  };

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(networkClinicRequest(value, pageSize, searchText, city, status));
      setPage(value);
    } else {
      dispatch(networkClinicRequest(value, pageSize, searchText, city, status));
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(networkClinicRequest(page, e.target.value, searchText, city, status));
    } else {
      dispatch(
        networkClinicRequest(searchPage, e.target.value, searchText, city, status)
      );
    }
    setPageSize(e.target.value);
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        networkClinicRequest(page, pageSize, searchText, city, e.target.value)
      );
    } else {
      dispatch(
        networkClinicRequest(
          searchPage,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    }
    setStatus(e.target.value);
  };

  return (
    <div>
      <div className="grid">
        <h4 className="h4">Clinic Request</h4>
        <div className="mainBox">
          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Request</h5>
              <div className="corporatecount">
                {networkClinic.data?.data.data.data.totalClinicRequest} Request
              </div>
            </div>
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities" 

                cityList={cityNames}
              />
              <select
                className="requestDropdown"
                onChange={(e) => handleStatus(e)}
              >
                <option value="">All</option>
                <option value="APPROVED">Approve</option>
                <option value="DECLINED">Decline</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
            <div className="searchSection">
              <div className="corporatesearchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
                <button
                  type="submit"
                  value="Submit"
                  className="corporatesearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                  }}
                >
                  <FilterAltIcon fontSize="small" />
                </button>
              </div> */}
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {networkClinic.data?.data.data.data
              .clinicPartnerResponseDtoList && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                pagination
                disableColumnMenu
                rowCount={
                  networkClinic.data?.data.data.data.totalHospitalRequest
                }
                rows={
                  networkClinic.data?.data.data.data
                    .clinicPartnerResponseDtoList
                }
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={networkClinic.data?.data.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ClinicRequest;
