import * as types from "../../Config/actionType"

export const viewAllAgentsRequest = (page,pageSize,search,city,status) => {
    return {
        type: types.VIEW_ALL_AGENTS_REQUEST,
        page,
        pageSize,
        search,
        city,
        status
    }
}

export const viewAllAgentsSuccess = (action) => {
    return {
        type: types.VIEW_ALL_AGENTS_SUCCESS,
        action
    }
}

export const viewAllAgentsFailure = (action) => {
    return {
        type: types.VIEW_ALL_AGENTS_FAILURE,
        action
    }
}