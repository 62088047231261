import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/updateBrandAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* updateBrandSaga(action) {
 
  try {
   
    let response = yield call(NetworkService.updateBrand, action);
   
    yield put(actions.updateBrandSuccess(response));
  } catch (err) {
   
    yield put(actions.updateBrandFailure(err));
  }
}

export function* watchUpdateBrandSaga() {
 
  yield takeLatest(types.UPDATE_BRAND_REQUEST, updateBrandSaga);
}
