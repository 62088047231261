import * as types from "../../Config/actionType";

export const getAllLotsIdsRequest = (data) => {
  return {
    type: types.GET_ALL_LOTS_IDS_REQUEST,
    data:data,
  };
};

export const getAllLotsIdsSuccess = (data) => {
  return {
    type: types.GET_ALL_LOTS_IDS_SUCCESS,
    data: data,
  };
};

export const getAllLotsIdsFailure = (data) => {
  return {
    type: types.GET_ALL_LOTS_IDS_FAILURE,
    data: data,
  };
};
