import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/NetworkService";
import * as actions from "../../Actions/Doctors/viewAllDoctorsActions";
import * as types from "../../Config/actionType";

export function* viewAllDoctors(action) {
  try {
    let response = yield call(NetworkService.viewAllDoctors, action);
    yield put(actions.viewAllDoctorsSuccess(response));
  } catch (err) {
    yield put(actions.viewAllDoctorsFailure(err));
  }
}

export function* watchViewAllDoctors() {
  yield takeEvery(types.VIEW_ALL_DOCTORS_REQUEST, viewAllDoctors);
}