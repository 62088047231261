import * as types from "../../Config/actionType";

export const ChangeOrderStatusRequest = (data) => {
  return {
    type: types.CHANGE_ORDER_STATUS_REQUEST,
    data: data,
  };
};

export const ChangeOrderStatusSuccess = (data) => {
  return {
    type: types.CHANGE_ORDER_STATUS_SUCCESS,
    data: data,
  };
};

export const ChangeOrderStatusFailure = (data) => {
  return {
    type: types.CHANGE_ORDER_STATUS_FAILURE,
    data,
  };
};
