import * as types from "../../Config/LifeStyle/lifeStyleActionType";

export const DeleteLifeStyleRequest = (data) => {
  return {
    type: types.DELETE_LIFESTYLE_REQUEST,
    data:data
  };
};

export const DeleteLifeStyleSuccess = (action) => {
  return {
    type: types.DELETE_LIFESTYLE_SUCCESS,
    action,
  };
};

export const DeleteLifeStyleFailure = (action) => {
  return {
    type: types.DELETE_LIFESTYLE_FAILURE,
    action,
  };
};
