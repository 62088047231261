import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import storage from "../../../Utils/storage";
import { updateInsuranceAgentRequest } from "../..//../redux/Actions/Agents/updateInsuranceAgentAction";
import { getAllServiceRequest } from "../..//../redux/Actions/Service/getAllServiceAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
const loginSchema = Yup.object().shape({
  // agentName: Yup.string()
  //   .required("Required Field")
  //   .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  contactNumber: Yup.string()
    .required("Required Field")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string().required("Required Field").email("Invalid Email"),
  // insuranceCompany: Yup.string()
  //   .required("Required Field")
  //   .matches(/^(?![.,&-])[A-Za-z0-9 ,&.-]+$/, "Insert only normal character"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function ModalAddInsuranceAgent(props) {
  //
  useEffect(() => {
    dispatch(getAllServiceRequest());
  }, []);

  const handleClose = () => props.open(false);

  const dispatch = useDispatch();
  const [service, setService] = React.useState(props?.value.services);
  const [matchApiCall, setMatchApiCall] = useState(false);
  // //
  const res = useSelector((state) => state.updateInsuranceAgent);
  const [matchApiRes, setMatchApiRes] = useState(null);
  const citiesId = storage.getStorage("cityId");

  const [selectCity, setSelectCity] = useState(citiesId);

  // const handleSubmit = (values, { setSubmitting }) => {
  //   setTimeout(() => {
  //     dispatch(updateInsuranceAgentRequest(values));
  //     setSubmitting(false);
  //   }, 400);
  // };
  const updateRes = useSelector((state) => state.updateInsuranceAgent)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    //
    //

    setMatchApiRes(null);
    setMatchApiCall(true);
    let serviceUuidArray = [];
    {
      // service.map((x) => {
      //   {
      //     viewServices?.data?.action?.data?.data?.map((item) => {
      //       if (item.name === x) {
      //         serviceUuidArray.push(item.serviceUuid);
      //       }
      //     });
      //   }
      // });
    }
    if (isPasswordFirstClick) {
      const value = {
        ...values,
        password: values.password,
        // serviceUuidList: serviceUuidArray,
        cityId: selectCity,
      };
      setTimeout(() => {
        dispatch(updateInsuranceAgentRequest(value));
        setSubmitting(false);
      }, 400);
    } else {
      const value = {
        ...values,
        password: props?.value?.password,
        // serviceUuidList: serviceUuidArray,
        cityId: selectCity,
      };
      setTimeout(() => {
        dispatch(updateInsuranceAgentRequest(value));
        setSubmitting(false);
      }, 400);
    }
  };

  if (res.data?.data.status === 200) {
    props.open(false);
    Swal.fire({
      title: "Insurance agent details updated successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    //
    if (matchApiCall) {
      if (matchApiRes !== null && matchApiRes) {
        handleEmailMobileValidation();
      }
    }
  }, [matchApiCall, matchApiRes]);
  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
  }, [res]);

  const handleEmailMobileValidation = () => {
    //
    if (matchApiRes?.data?.data?.status === 201) {
      //

      props.open(false);
      Swal.fire({
        title: "Insurance agent details updated successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
        // setSwal(false);
        setMatchApiCall(false);
        setMatchApiRes(null);
      });
    } else if (
      matchApiRes?.error !== null &&
      matchApiRes?.error?.data?.response !== 201 &&
      matchApiRes?.error?.data?.response?.data !== null
    ) {
      //

      swal({
        title: "" + Object.values(matchApiRes?.error?.data?.response?.data),
        icon: "error",
      }).then(() => {
        setMatchApiCall(false);
        setMatchApiRes(null);

        props.open(true);
      });
    }
  };

  const handleChangeService = (event) => {
    const {
      target: { value },
    } = event;

    setService(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const viewServices = useSelector((state) => state.getService);
  const viewCity = useSelector((state) => state.viewAllCity);

  const [isPasswordFirstClick, setIsPasswordFirstClick] = useState(false);

  var id;

  viewCity.data?.data?.data?.data.map((item, index) => {
    if (item.name === props.value.city) {
      id = item.id;
    }
  });

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  const handleChange = (e) => {
    setSelectCity(e.target.value);
  };

  const handleCheckEmail = (event) => {
    if (event.target.value !== props.value?.contactEmail) {
      setRecvEmail(event.target.value);
    }
  };

  const [recvEmail, setRecvEmail] = useState();

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  //
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Update Insurance Agent
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              agentName: props.value.name,
              contactNumber: props.value.number,
              email: props.value.email,
              // address: props.value.address,
              agentId: props.id,
              insuranceCompany: props.value.companyName,
              // pinCode: props.value.pinCode,
              // services: props.value.services,
              password: props.value.password.slice(0, 6),
              confirmPassword: props.value.password.slice(0, 6),
              id: props.value.id,
              // selectServices: "",
            }}
            // validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <div className="modalInfo">
                    <div className="modalInfoLabels">
                      <label>Name</label>
                      <label>Insurance Company</label>
                    </div>
                    <div className="modalInfoValues">
                      <label>: {props.value.name}</label>

                      <label>: {props.value.companyName}</label>
                    </div>
                  </div>
                  {/* <label>
                    <Field
                      type="text"
                      name="agentName"
                      placeholder="Insurer Name"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="agentName"
                      component="div"
                    />
                  </label> */}
                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Contact Number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="insurer Email"
                      className="inputField"
                      onBlur={(e) => {
                        if (e.target.value !== props.value?.contactEmail) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        } else {
                          handleCheckEmail(e);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>
                  {/* <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    />
                  </label> */}
                  {/* <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                      onChange={handleChange}
                    >
                    
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option
                          key={index}
                          value={item.id}
                          selected={item?.name === props?.value?.city}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </label> */}
                  {/* <label>
                    <Field
                      type="number"
                      name="pinCode"
                      placeholder="Pin Code"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label> */}
                  {/* <label>
                    <Field
                      type="text"
                      name="insuranceCompany"
                      placeholder="Insurance Company"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="insuranceCompany"
                      component="div"
                    />
                  </label> */}
                  <label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Generate Password"
                      className="inputField"
                      onClick={() => {
                        if (!isPasswordFirstClick) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="password"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Retype Password"
                      className="inputField"
                      disabled={!isPasswordFirstClick}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="confirmPassword"
                      component="div"
                    />
                  </label>
                  {/* 
                  <label>
                    <Field
                      as="select"
                      name="selectServices"
                      placeholder="Select Services"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select Service
                      </option>
                      {viewServices?.data?.action?.data?.data?.map(
                        (item, index) => (
                          <option key={index} value={item.serviceUuid}>
                            {item.name}
                          </option>
                        )
                      )}
                    </Field>
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="selectServices"
                      component="div"
                    />
                  </label> */}

                  <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        {/* Select Services */}
                      </InputLabel>
                      {/* <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        
                        defaultValue={props.value.services}
                        value={service}
                        onChange={handleChangeService}
                        input={<OutlinedInput label=" Select Services" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {viewServices?.data?.action?.data?.data?.map(
                          (item, index) => (
                            <MenuItem key={item.serviceUuid} value={item.name}>
                              <Checkbox
                                checked={service.indexOf(item.name) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          )
                        )}
                      </Select> */}
                    </FormControl>
                  </div>

                  <button type="submit"  disabled={!flag}className="modalButton">
                    {flag ? "       Update Insurer" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddInsuranceAgent;
