import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { viewAllServicesAttributesRequest } from "../../../redux/Actions/Program Template/Services/viewAllServicesAttributesAction";
import { updateServiceDescriptionRequest } from "../../../redux/Actions/Service/updateServiceDescriptionAction";
import { viewServiceRequest } from "../../../redux/Actions/Service/viewServiceAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import ModalAddNewAttributeService from "../../Organisms/Modal/ModalAddNewAttributeService";
import "./ViewServiceCorporate.css";

function ViewServiceCorporate() {
  const [add, setAdd] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [values, setValues] = React.useState();

  // const handleAdd = (value) => {
  //   // setValues(value);
  //   setAdd(true);
  // };

  // const handleEditClick = (id) => {
  //   setValues(id);
  //   setEdit(true);
  // };

  const columns = [
    {
      field: "attributeName",
      headerName: "Hospital Name",
      headerClassName: "super-app-theme--header",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "attributeDescription",
      headerName: "Hospital Address",
      minWidth: 665,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "cost",
      headerName: "Atrribute Cost",
      type: "number",
      miWidth: 110,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  // const columns = [
  //     {
  //         field: "attributeName",
  //         headerName: "Hospital Name",
  //         headerClassName: "super-app-theme--header",
  //         minWidth: 200,
  //         //   flex: 1,
  //     },
  //     {
  //         field: "hospitalAddress",
  //         headerName: "Hospital Address",
  //         minWidth: 200,
  //         //   flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "hospitalContactNumber",
  //         headerName: "Hospital Contact Number",
  //         minWidth: 150,
  //         // flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "",
  //         headerName: "Hospital Contact Person",
  //         minWidth: 150,
  //         // flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "",
  //         headerName: "No. of Doctors",
  //         minWidth: 150,
  //         // flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "",
  //         headerName: "Services Offered",
  //         minWidth: 150,
  //         // flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     {
  //         field: "",
  //         headerName: "Hospital mail",
  //         //   type: "number",
  //         miWidth: 600,
  //         //   flex: 1,
  //         headerClassName: "super-app-theme--header",
  //     },
  //     // {
  //     //   field: "Actions",
  //     //   minWidth: 120,
  //     //   flex: 1,
  //     //   headerClassName: "super-app-theme--header",
  //     //   renderCell: (cellValues) => {
  //     //     return (
  //     //       <div className="modify">
  //     //         &nbsp;&nbsp;&nbsp;&nbsp;
  //     //         <EditIcon
  //     //           style={{cursor: "pointer", color: "#1D2334", }}
  //     //           onClick={() => handleEditClick(cellValues.row)}
  //     //         />
  //     //         {edit && (
  //     //           <ModalUpdateService
  //     //             value={values}
  //     //             serviceUuid={serviceUuid}
  //     //             serviceName={serviceName}
  //     //             serviceDescription={serviceDescription}
  //     //             open={setEdit}
  //     //           />
  //     //         )}
  //     //         {/* {edit && values && (
  //     //           <ModalUpdateCorporate value={values} open={setEdit} />
  //     //         )} */}
  //     //         {/* <Box>
  //     //           <DeleteIcon
  //     //             sx={{}}
  //     //             style={{cursor: "pointer", color: "#52608E" }}
  //     //             // onClick={() => handleDelete(cellValues.row.id)}
  //     //           />
  //     //         </Box> */}
  //     //       </div>
  //     //     );
  //     //   },
  //     // },
  // ];

  const dispatch = useDispatch();
  const { uuid } = useParams("uuid");
  const handleClick = () => {};

  const updateDescription = useSelector(
    (state) => state.updateServiceDescription
  );
  const updateTitle = useSelector((state) => state.updateServiceTitle);

  useEffect(() => {
    dispatch(viewServiceRequest(uuid));
  }, [uuid]);

  useEffect(() => {
    dispatch(viewAllServicesAttributesRequest(uuid));
  }, [uuid]);

  const getservice = useSelector(
    (state) => state.viewAllServicesAttributesReducer
  );

  const details = useSelector((state) => state.viewService);

  let serviceUuid = details.data?.data.data.serviceUuid;
  let serviceName = details.data?.data.data.name;
  let serviceDescription = details.data?.data.data.description;

  const handleClickDesc = () => {
    Swal.fire({
      title: "Edit Description",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: (desc) => {
        dispatch(
          updateServiceDescriptionRequest(desc, details.data?.data.data)
        );
      },
    }).then((result) => {
      if (updateDescription.data?.action.status === 200 && result.isConfirmed) {
        Swal.fire({
          icon: "success",
          text: "Edit Successfully",
        });
      }
      window.location.reload();
      // else {
      //   Swal.fire({
      //     icon: "error",
      //     text: "Something went wrong!",
      //   });
      // }
    });
  };

  //   const handleClickTitle = () => {
  //     Swal.fire({
  //       title: "Edit Title",
  //       input: "text",
  //       inputAttributes: {
  //         autocapitalize: "off",
  //       },
  //       showCancelButton: true,
  //       confirmButtonText: "Edit",
  //       showLoaderOnConfirm: true,
  //       preConfirm: (title) => {
  //         dispatch(updateServiceTitleRequest(title, details.data?.data.data));
  //       },
  //     }).then((result) => {
  //       if (updateTitle.data?.action.status === 200 && result.isConfirmed) {
  //         Swal.fire({
  //           icon: "success",
  //           text: "Edit Successfully",
  //         });
  //       }
  //       window.location.reload();
  //     });
  //   };

  return (
    <div>
      <div className="service-main">
        <h3 className="h3">Services</h3>
        <div className="service-box">
          <div className="service-box-head">
            <div className="service-name">{details.data?.data.data.name}</div>
            <div className="service-edit">
              {/* <EditIcon
                fontSize="medium"
                onClick={() => handleClickTitle()}
                sx={{ cursor: "pointer" }}
              />
              <button
                onClick={() => handleClickTitle()}
                className="service-editBtn"
              >
                Edit
              </button> */}
            </div>
          </div>
          <hr />
          <div className="desc-head">
            <div className="service-description">Brief Description</div>
            <div className="checkup">
              Check Up Lists
              <br></br>
              <div className="servicesss">
                <ul>
                  {getservice.data?.data.data.attributeResponseDtoList.map(
                    (itm) => (
                      <div>{itm.attributeName}</div>
                    )
                  )}
                </ul>
              </div>
            </div>

            {/* <Card className="card"></Card> */}
            <div className="service-edit">
              {/* <EditIcon
                fontSize="medium"
                onClick={() => handleClickDesc()}
                sx={{ cursor: "pointer" }}
              /> */}
              {/* <button
                onClick={() => handleClickDesc()}
                className="service-editBtn"
              >
                Edit
              </button> */}
            </div>
          </div>
          <div className="service-box-body">
            {details.data?.data.data.description}
          </div>
          <div className="service-grid">
            <div className="service-grid-head">
              <div className="atrribute">
                Hospital List Providing Health Check-up
              </div>
              {/* <button className="addAtributeBtn" onClick={() => handleAdd()}>
                + Add Attribute
              </button> */}
              {add && (
                <ModalAddNewAttributeService
                  open={setAdd}
                  serviceUuid={serviceUuid}
                  serviceName={serviceName}
                  serviceDescription={serviceDescription}
                />
              )}
            </div>
            {/* <Box sx={{ height: 400, width: "100%", marginTop: "2.18em" }}>
                            {details.data?.data.data.attributeResponseDtoList && (
                                <DataGrid
                                    sx={{
                                        color: "#1D2334",
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#52608E",
                                            color: "#F2EFEA",
                                            "& > .MuiDataGrid-columnSeparator": {
                                                visibility: "hidden",
                                            },
                                        },
                                        ".MuiTablePagination-toolbar": {
                                            alignItems: "baseline",
                                            marginTop: "5%",
                                        },
                                    }}
                                    rows={details.data?.data.data.attributeResponseDtoList}
                                    // columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.attributeId}
                                    disableSelectionOnClick
                                />
                            )}
                        </Box> */}
            <Box sx={{ height: 400, width: "100%", marginTop: "2.18em" }}>
              {details.data?.data.data.attributeResponseDtoList && (
                <DataGrid
                  sx={{
                    color: "#1D2334",
                    "& .super-app-theme--header": {
                      backgroundColor: "#52608E",
                      color: "#F2EFEA",
                      "& > .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                      },
                    },
                    ".MuiTablePagination-toolbar": {
                      alignItems: "baseline",
                      marginTop: "5%",
                    },
                  }}
                  rows={details.data?.data.data.attributeResponseDtoList}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  getRowId={(row) => row.attributeId}
                  disableSelectionOnClick
                />
              )}
            </Box>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ViewServiceCorporate;
