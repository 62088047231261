import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Plan/deletePlanAction";
import NetworkService from "../../../network/NetworkService";

export function* deletePlanSaga(action) {
  try {
    let response = yield call(NetworkService.deletePlan, action);
    yield put(actions.deletePlanSuccess(response));
  } catch (err) {
    yield put(actions.deletePlanFailure(err));
  }
}

export function* watchDeletePlanSaga() {
  yield takeLatest(types.DELETE_PLAN_REQUEST, deletePlanSaga);
}
