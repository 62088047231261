import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getAllSubCategoryAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getAllSubCategorySaga(action) {

  try {
    let response = yield call(NetworkService.getAllSubCategory, action);

    yield put(actions.getAllSubCategorySuccess(response));
  } catch (err) {
    yield put(actions.getAllSubCategoryFailure(err));
  }
}

export function* watchGetAllSubCategorySaga() {
  yield takeLatest(types.GET_ALL_SUB_CATEGORY_REQUEST, getAllSubCategorySaga);
}
