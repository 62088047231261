import * as types from "../../Config/Pharmacy/PharmacyActionType";

export const getPharmacyDetailRequest = (data) => {
  return {
    type: types.GET_PHARMACY_DETAIL_REQUEST,
    data: data,
  };
};

export const getPharmacyDetailSuccess = (data) => {
  return {
    type: types.GET_PHARMACY_DETAIL_SUCCESS,
    data: data,
  };
};

export const getPharmacyDetailFailure = (data) => {
  return {
    type: types.GET_PHARMACY_DETAIL_FAILURE,
    data: data,
  };
};
