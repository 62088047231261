import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { checkNumberRequest } from "../../../redux/Actions/Login/checkNumberAction";
import { addUserRequest } from "../../../redux/Actions/Users/AddUserAction";
import "./Modal.css";
import CircularProgress from "@mui/material/CircularProgress";

import { modalHeader } from "./ModalConstants";

const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;

const loginSchema = Yup.object().shape({
  name: Yup.string()
    .required("Required Field")
    .matches(/^(?![.])[A-Za-z .]+$/, "Insert only normal character"),
  number: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function ModalAddUser(props) {
  const dispatch = useDispatch();
  const [lengthError, setLengthError] = useState(false);

  const handleClose = () => props.open(false);
  const updateRes = useSelector((state) => state.addUser)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])

  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)

    setTimeout(() => {
      dispatch(addUserRequest(values));
      setSubmitting(false);
    }, 400);
  };

  const res = useSelector((state) => state.addUser);

  const handleCheckEmail = (event) => {
    dispatch(checkEmailAgentRequest(event.target.value));
  };

  const handleCheckNumber = (event) => {
    if (event.target.value.length === 10) {
      dispatch(checkNumberRequest(event.target.value));
    }
  };

  const emailDetail = useSelector((state) => state.checkEmailAgent);
  const checkNumber = useSelector((state) => state.checkNumber);
  const emailDetails = emailDetail?.data?.data.data;

  if (res.data?.data.status === 201) {
    props.open(false);
    Swal.fire({
      title: "User added successfully",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  }

  if (
    (res.data?.data.status !== 201 && res.data === undefined) ||
    res.error !== null
  ) {
    swal({
      title: "" + Object.values(res.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      props.open(true);
    });
  }

  function preventnumberinput(e) {
    var keycode = e.keycode ? e.keycode : e.which;
    if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
      e.preventDefault();
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Add New User
            </Typography>
            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              email: "",
              name: "",
              number: "",
              password: "",
              confirmPassword: "",
            }}
            enableReinitialize={true}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {
              <Form>
                <div className="inputFieldsContainer">
                  <label>
                    <Field
                      type="number"
                      name="number"
                      placeholder="Contact Number"
                      className="inputField"
                      onBlur={(e) => {
                        handleCheckNumber(e);
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="number"
                      component="div"
                    />
                    {checkNumber.error?.data.response?.status === 302 && (
                      <div className="emailExist">Number exist</div>
                    )}
                  </label>
                  <label>
                    <Field
                      type="email"
                      name="email"
                      onBlur={(e) => {
                        handleCheckEmail(e);
                      }}
                      placeholder="Contact Email (optional)"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />
                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>

                  <label>
                    <Field
                      type=""
                      name="name"
                      placeholder="Name"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>

                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="password"
                        placeholder="Generate Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="password"
                        component="div"
                      />
                    </label>
                  )}
                  {!emailDetails?.data && (
                    <label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        placeholder="Retype Password"
                        className="inputField"
                      />
                      <ErrorMessage
                        className="errorMessageModalValidation"
                        name="confirmPassword"
                        component="div"
                      />
                    </label>
                  )}
                  <button type="submit"  disabled={!flag} className="modalButton">
                    {flag ? "     Add User" : <CircularProgress />}
                  </button>
                </div>
              </Form>
            }
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAddUser;
