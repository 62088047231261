import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/updateHospital";
import NetworkService from "../../../network/NetworkService";

export function* updateHospitalSaga(action) {
  try {
    let response = yield call(NetworkService.updateHospital, action);
    yield put(actions.updateHospitalSuccess(response));
  } catch (err) {
    yield put(actions.updateHospitalFailure(err));
  }
}

export function* watchUpdateHospitalSaga() {
  yield takeLatest(types.UPDATE_HOSPITAL_REQUEST, updateHospitalSaga);
}