import { call, put, takeEvery } from "redux-saga/effects";
import NetworkService from "../../../network/Pharmacy/PharmacyNetworkServices"
import * as actions from "../../Actions/Pharmacy/viewAllPharmacyAction";
import * as types from "../../Config/Pharmacy/PharmacyActionType";

export function* viewAllPharmacy(action) {
  try {
    let response = yield call(NetworkService.viewAllPharmacy, action);
    yield put(actions.viewAllPharmacySuccess(response));
  } catch (err) {
    yield put(actions.viewAllPharmacyFailure(err));
  }
}

export function* watchViewAllPharmacy() {
  yield takeEvery(types.VIEW_ALL_PHARMACY_REQUEST, viewAllPharmacy);
}