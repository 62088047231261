import * as types from "../../Config/Banner/BannerActionType";

export const addBannerRequest = (data) => {
  return {
    type: types.ADD_BANNER_REQUEST,
    data: data,
  };
};

export const addBannerSuccess = (data) => {
  return {
    type: types.ADD_BANNER_SUCCESS,
    data: data,
  };
};

export const addBannerFailure = (data) => {
  return {
    type: types.ADD_BANNER_FAILURE,
    data: data,
  };
};
