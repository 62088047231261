import * as types from "../../Config/actionType";

export const getPlanDetailRequest = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_REQUEST,
    data: data.uuid,
    bool:data.bool
  };
};

export const getPlanDetailSuccess = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_SUCCESS,
    data: data,
  };
};

export const getPlanDetailFailure = (data) => {
  return {
    type: types.GET_PLAN_DETAIL_FAILURE,
    data: data,
  };
};
