import * as types from "../../../Config/actionType";

export const networkViewClinicRequest = (id) => {
 
  return {
    type: types.NETWORK_VIEW_CLINIC_REQUEST,
    id
  };
};

export const networkViewClinicSuccess = (data) => {
  return {
    type: types.NETWORK_VIEW_CLINIC_SUCCESS,
    data: data,
  };
};

export const networkViewClinicFailure = (data) => {
  return {
    type: types.NETWORK_VIEW_CLINIC_FAILURE,
    data: data,
  };
};