import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Agents/addInsuranceAgentAction";
import NetworkService from "../../../network/NetworkService";

export function* addInsuranceAgentSaga(action) {
  try {
    let response = yield call(NetworkService.addInsuranceAgent, action);
    yield put(actions.addInsuranceAgentSuccess(response));
  } catch (err) {
    yield put(actions.addInsuranceAgentFailure(err));
  }
}

export function* watchAddInsuranceAgentSaga() {
  yield takeLatest(types.ADD_INSURANCE_AGENTS_REQUEST, addInsuranceAgentSaga);
}