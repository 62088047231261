import * as types from "../../Config/actionType"

export const finishAppointmentRequest = (data) => {
    return {
        type: types.FINISH_APPOINTMENT_REQUEST,
        data:data
    }
}

export const finishAppointmentSuccess = (data) => {
    return {
        type: types.FINISH_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const finishAppointmentFailure = (data) => {
    return {
        type: types.FINISH_APPOINTMENT_FAILURE,
        data:data,
    }
}