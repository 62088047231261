import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Users/UsersDropdownAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllUsersDropdown() {
  try {
    let response = yield call(NetworkService.viewAllUsersDropdown);

    yield put(actions.viewAllUsersDropdownSuccess(response))
  } catch (err) {
    yield put(actions.viewAllUsersDropdownFailure(err));
  }
}

export function* watchViewAllUsersDropdown() {
  yield takeLatest(types.VIEW_ALL_USERS_DROPDOWN_REQUEST, viewAllUsersDropdown);
}