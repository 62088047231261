import * as types from "../../Config/actionType";

export const EditDiscountProductRequest = (data) => {
  return {
    type: types.EDIT_DISCOUNT_PRODUCT_REQUEST,
    data: data,
  };
};

export const EditDiscountProductSuccess = (data) => {
  return {
    type: types.EDIT_DISCOUNT_PRODUCT_SUCCESS,
    data: data,
  };
};

export const EditDiscountProductFailure = (data) => {
  return {
    type: types.EDIT_DISCOUNT_PRODUCT_FAILURE,
    data: data,
  };
};
