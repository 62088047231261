import * as types from "../../Config/actionType"

export const viewAllHospitalRequest = (page,pageSize,search,city,status) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_REQUEST,
        page,
        pageSize,
        search,
        city,
        status
    }
}

export const viewAllHospitalSuccess = (action) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_SUCCESS,
        action
    }
}

export const viewAllHospitalFailure = (action) => {
    return {
        type: types.VIEW_ALL_HOSPITAL_FAILURE,
        action
    }
} 