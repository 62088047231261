import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Clinic/NetworkViewClinicAction";
import NetworkService from "../../../../network/NetworkService";

export function* networkViewClinicSaga(action) {
   
  try {
    let response = yield call(NetworkService.networkViewClinic, action);
    yield put(actions.networkViewClinicSuccess(response));
  } catch (err) {
    yield put(actions.networkViewClinicFailure(err));
  }
}

export function* watchnetworkViewClinicSaga() {
  yield takeLatest(types.NETWORK_VIEW_CLINIC_REQUEST, networkViewClinicSaga);
}
