import * as types from "../../Config/actionType"

export const loginHrRequest = (data) => {
   
    return {
        type: types.LOGIN_HR_REQUEST,
        data:data
    }
}

export const loginHrSuccess = (data) => {
   
    return {
        type: types.LOGIN_HR_SUCCESS,
        data:data,
    }
}

export const loginHrFailure = (data) => {
   
    return {
        type: types.LOGIN_HR_FAILURE,
        data:data,
    }
}