import * as types from "../../Config/actionType"

export const viewAllProgramRequest = (data) => {
    return {
        type: types.VIEW_ALL_PROGRAM_REQUEST,
        data:data
    }
}

export const viewAllProgramSuccess = (data) => {
    return {
        type: types.VIEW_ALL_PROGRAM_SUCCESS,
        data:data,
    }
}

export const viewAllProgramFailure = (data) => {
    return {
        type: types.VIEW_ALL_PROGRAM_FAILURE,
        data:data,
    }
}