import * as types from "../../Config/actionType"

export const deleteAppointmentRequest = (id) => {
    return {
        type: types.DELETE_APPOINTMENTS_REQUEST,
        id
    }
}

export const deleteAppointmentSuccess = (action) => {
    return {
        type: types.DELETE_APPOINTMENTS_SUCCESS,
        action
    }
}

export const deleteAppointmentFailure = (action) => {
    return {
        type: types.DELETE_APPOINTMENTS_FAILURE,
        action
    }
}