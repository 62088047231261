import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { deleteDiscountRequest } from "../../../redux/Actions/Discount/DeleteDiscountAction";
import { ViewDiscountRequest } from "../../../redux/Actions/Discount/ViewDiscountAction";
import Footer from "../../Organisms/Footer/FooterUpper";
import { viewDiscount } from "./DiscountConstants";
import "./ViewDiscount.css";
import Swal from "sweetalert2";

function ViewDiscount() {
  const { id } = useParams();
  const { enumType } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let data = { id, enumType };
    dispatch(ViewDiscountRequest(data));
  }, [id]);
  const handleEdit = (id) => {
    if(enumType==="PRODUCT"){
      navigate(`/EditDiscountProduct`, {
        state: {
  
          enumType: enumType,
          details: viewDiscountData?.data?.data?.data?.data,
        },
      });
    }
    else{
    navigate(`/EditDiscount`, {
      state: {

        enumType: enumType,
        details: viewDiscountData?.data?.data?.data?.data,
      },
    });
  }
  };

  const handleDeleteDiscount = () => {
    const uuid = viewDiscountData?.data?.data?.data?.data.uuid;
    let submitdata = { uuid, enumType };
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDiscountRequest(submitdata));
      }
    })

  };

  const deleteDiscount = useSelector((state) => state.deleteDiscount)
  const viewDiscountData = useSelector((state) => state.viewDiscount);

  if (
    deleteDiscount?.data?.action?.status === 200 &&
    deleteDiscount?.data?.action?.data.message !== ""
  ) {
    Swal.fire({
      title: `${deleteDiscount?.data?.action?.data.message}`,
      icon: "success",
    })
      .then(() => {
        navigate(`/Discount`);
        window.location.reload();
      });
  }
  return (
    <>
      <div className="ViewDiscountWrapper">
        <h1 className="ViewDiscountHeader">
          {viewDiscount.ViewDiscountHeader}
        </h1>

        <div className="ViewDiscountInfoSection">
          <div className="ViewDiscountInfoIndividual">
            <h1 className="ViewDiscountInfo">
              {enumType==="PLAN"?viewDiscount.ViewDiscountInfo1Plan:(enumType==="TEST"?viewDiscount.ViewDiscountInfo1Test:viewDiscount.ViewDiscountInfo1)}
            </h1>
            <h1 className="ViewDiscountData">
              {viewDiscountData?.data?.data?.data?.data.name}
            </h1>
          </div>
          
              <div className="ViewDiscountInfoIndividual">
                <h1 className="ViewDiscountInfo">
                  {viewDiscount.ViewDiscountInfo7}
                </h1>
                <h1 className="ViewDiscountData" style={{display:"flex"}}>
                 <p style={{color:"red",textDecoration:"line-through"}}>
                  
                   ₹ {viewDiscountData?.data?.data?.data?.data.quarterly}</p>
                   <p style={{color:"green",marginLeft:"1em"}}>
                    
                    ₹ {viewDiscountData?.data?.data?.data?.data.quarterlyDiscount}</p>
                </h1>
              </div>

              <div className="ViewDiscountInfoIndividual">
                <h1 className="ViewDiscountInfo">
                  {viewDiscount.ViewDiscountInfo8}
                </h1>
                <h1 className="ViewDiscountData" style={{display:"flex"}}>
                  <p style={{color:"red",textDecoration:"line-through"}}>₹ {viewDiscountData?.data?.data?.data?.data.halfYearly}</p><p style={{color:"green",marginLeft:"1em"}}>₹ {viewDiscountData?.data?.data?.data?.data.halfYearlyDiscount}</p>
                </h1>
              </div>

              <div className="ViewDiscountInfoIndividual">
                <h1 className="ViewDiscountInfo">
                  {viewDiscount.ViewDiscountInfo9}
                </h1>
                <h1 className="ViewDiscountData" style={{display:"flex"}}>
                <p style={{color:"red",textDecoration:"line-through"}}>₹ {viewDiscountData?.data?.data?.data?.data.yearly}</p><p style={{color:"green",marginLeft:"1em"}}>₹ {viewDiscountData?.data?.data?.data?.data.yearlyDiscount}</p>
                </h1>
              </div>
          <div className="ViewDiscountInfoIndividual">
            <h1 className="ViewDiscountInfo">
              {viewDiscount.ViewDiscountInfo3}
            </h1>
            <h1 className="ViewDiscountData">
              {viewDiscountData?.data?.data?.data?.data.discountPercentage!==null?`${viewDiscountData?.data?.data?.data?.data.discountPercentage} %`:"---"} 
            </h1>
          </div>
          <div className="ViewDiscountInfoIndividual">
            <h1 className="ViewDiscountInfo">
              {viewDiscount.ViewDiscountInfo4} 
            </h1>
            <h1 className="ViewDiscountData">
              {viewDiscountData?.data?.data?.data?.data.startDate !==null?new Date(
                viewDiscountData?.data?.data?.data?.data.startDate
              ).toLocaleString():"---"}
            </h1>
          </div>
          <div className="ViewDiscountInfoIndividual">
            <h1 className="ViewDiscountInfo">
              {viewDiscount.ViewDiscountInfo5}
            </h1>
            <h1 className="ViewDiscountData">
              {viewDiscountData?.data?.data?.data?.data.endDate!==null? new Date(
                viewDiscountData?.data?.data?.data?.data.endDate
              ).toLocaleString():"---"}
            </h1>
          </div>
          <div className="ViewDiscountInfoIndividual">
            <h1 className="ViewDiscountInfo">
              {viewDiscount.ViewDiscountInfo6}
            </h1>
            <h1 className="ViewDiscountData">
              {viewDiscountData?.data?.data?.data?.data.description!==null?viewDiscountData?.data?.data?.data?.data.description:"---"}
            </h1>
          </div>
          {viewDiscountData?.data?.data?.data?.data.discountPercentage!==null?
          <div className="ViewDiscountButton">
            <button className="ViewDiscountButtonEdit" onClick={handleEdit}>
              Edit
            </button>
            <button
              className="ViewDiscountButtonDelete"
              onClick={handleDeleteDiscount}
            >
              Delete
            </button>
          </div>:<div></div>}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViewDiscount;
