import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/Diagnostic/NetworkDiagnosticAction";
import NetworkService from "../../../../network/NetworkService";

export function* networkDiagnosticSaga(action) {
  try {
    let response = yield call(NetworkService.networkDiagnostic, action);
    yield put(actions.networkDiagnosticSuccess(response));
  } catch (err) {
    yield put(actions.networkDiagnosticFailure(err));
  }
}

export function* watchNetworkDiagnosticSaga() {
  yield takeLatest(types.NETWORK_DIAGNOSTIC_REQUEST, networkDiagnosticSaga);
}
