import * as types from "../../Config/actionType"

export const downloadAttachmentRequest = (data) => {
   
    return {
        type: types.DOWNLOAD_ATTACHMENT_REQUEST,
        data:data
    }
}

export const downloadAttachmentSuccess = (data) => {
    return {
        type: types.DOWNLOAD_ATTACHMENT_SUCCESS,
        data:data,
    }
}

export const downloadAttachmentFailure = (data) => {
    return {
        type: types.DOWNLOAD_ATTACHMENT_FAILURE,
        data:data,
    }
}