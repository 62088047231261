import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/AssignLabAction"
import NetworkService from "../../../network/NetworkService";

export function* AssignLabSaga(action) {
  try {
    let response = yield call(NetworkService.AssignLab, action);
    yield put(actions.AssignLabSuccess(response));
  } catch (err) {
    yield put(actions.AssignLabFailure(err));
  }
}

export function* watchAssignLabSaga() {
  yield takeLatest(types.ASSIGN_LAB_REQUEST, AssignLabSaga);
}