import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/addReportAction";
import NetworkService from "../../../network/NetworkService";

export function* addReportSaga(action) {
  try {
    let response = yield call(NetworkService.addReport, action);
    yield put(actions.addReportSuccess(response));
  } catch (err) {
    yield put(actions.addReportFailure(err));
  }
}

export function* watchAddReportSaga() {
  yield takeLatest(types.ADD_REPORT_REQUEST, addReportSaga);
}
