import * as types from "../../Config/actionType"

export const viewAppointmentsRequest = (id) => {
    return {
        type: types.VIEW_APPOINTMENTS_REQUEST,
        id
    }
}

export const viewAppointmentsSuccess = (action) => {
    return {
        type: types.VIEW_APPOINTMENTS_SUCCESS,
        action
    }
}

export const viewAppointmentsFailure = (action) => {
    return {
        type: types.VIEW_APPOINTMENTS_FAILURE,
        action
    }
}