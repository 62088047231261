import * as types from "../../Config/actionType";

export const planTypeDropdownRequest = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_REQUEST,
    data: data,
  };
};

export const planTypeDropdownSuccess = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_SUCCESS,
    data: data,
  };
};

export const planTypeDropdownFailure = (data) => {
  return {
    type: types.PLAN_TYPE_DROPDOWN_FAILURE,
    data: data,
  };
};
