import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Payment/getAllLotsAction"
import NetworkService from "../../../network/NetworkService";

export function* getAllLotsSaga(action) {
  try {
    let response = yield call(NetworkService.getAllLots, action);
    yield put(actions.getAllLotsActionSuccess(response));
  } catch (err) {
    yield put(actions.getAllLotsActionFailure(err));
  }
}

export function* watchGetAllLotsSaga() {
  yield takeLatest(types.GET_ALL_LOTS_REQUEST, getAllLotsSaga);
}