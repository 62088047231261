import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Login/validateOtpLoginAction"
import NetworkService from "../../../network/NetworkService";

export function* validateOtpLoginSaga(action) {
  try {
    let response = yield call(NetworkService.validateOtpLogin, action);
    yield put(actions.validateOtpLoginSuccess(response));
  } catch (err) {
    yield put(actions.validateOtpLoginFailure(err));
  }
}

export function* watchValidateOtpLoginSaga() {
  yield takeLatest(types.VALIDATE_OTP_LOGIN_REQUEST, validateOtpLoginSaga);
}