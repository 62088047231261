import * as types from "../../Config/actionType"

export const relationshipRequest = (data) => {
   
    return {
        type: types.RELATIONSHIP_REQUEST,
        data:data
    }
}

export const relationshipSuccess = (data) => {
    return {
        type: types.RELATIONSHIP_SUCCESS,
        data:data,
    }
}

export const relationshipFailure = (data) => {
    return {
        type: types.RELATIONSHIP_FAILURE,
        data:data,
    }
}