import React, { useEffect, useState } from "react";
import "./Otp.css";
import OtpInput from "react-otp-input";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  validateOtpLoginClearRequest,
  validateOtpLoginRequest,
} from "../../../redux/Actions/Login/validateOtpLoginAction";
import { loginRequest } from "../../../redux/Actions/Login/loginAction";
import swal from "sweetalert";

function OTP(props) {
  const [code, setCode] = useState("");
  const [isExpire, setExpire] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  const handleChange = (code) => setCode(code);
  const dispatch = useDispatch();
  useEffect(() => {
    startCountdown();
  }, []);
  function startCountdown() {
    const startingMinutes = 1;
    let time = startingMinutes * 60;
    setInterval(() => {
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setTimerMinutes(minutes);
      setTimerSeconds(seconds);
      time--;
      if (time < 0) {
        setExpire(true);
        setResendOTP(true);
      } else {
        setResendOTP(false);
      }
    }, 1000);
  }
  function resendOtp() {
    dispatch(loginRequest(props.val));
    dispatch(validateOtpLoginClearRequest());
  }
  function submit() {
    dispatch(validateOtpLoginRequest(code, props.val.email));
  }
  const validateOtp = useSelector((state) => state.validateOtpLogin);
  useEffect(() => {
    if (validateOtp.error?.data?.response.request.status === 400) {
      swal({
        title: validateOtp?.error?.data?.response?.data?.errorMessage,
        icon: "error",
      });
    }
    if (validateOtp?.data?.data?.request?.status === 200) {
      swal({
        title: "OTP Validated",
        text: "Please Login Again",
        icon: "success",
      }).then(() => {
        props.open(false);
      });
    }
  }, [validateOtp]);

  return (
    <Modal
      open={props.open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="mainModal"
    >
      <div className="mainOtpBox">
        <div className="upperOtp">
          <p>Please Verify the OTP</p>
        </div>
        <div className="middleOtp">
          <div className="otpHeading">
            <p>OTP</p>
          </div>
          <div className="otpBoxes">
            <OtpInput
              value={code}
              onChange={handleChange}
              containerStyle={{ width: "100%", height: "100%" }}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                background: "#F3F0F0",
                border: "1px solid #E7E5E5",
                borderRadius: "6px",
                border: "1px solid transparent",
                borderRadius: "8px",
                width: "100%",
                height: "54px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div></div>
        <div className="otpTimer">
          <div className="otpTimer1"></div>
          <div className="otpTimer2">
            {resendOTP ? (
              <p id="resendOtp" className="resendOtp" onClick={resendOtp}>
                Resend OTP
              </p>
            ) : (
              <p id="resendOtp" className="resendOtp" onClick={resendOtp}></p>
            )}
            <div className="expire">
              {isExpire ? (
                ""
              ) : (
                <p id="timeShow">
                  {timerMinutes}:{timerSeconds}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="bottomOtp">
          <button onClick={submit} disabled={code.length === 4 ? false : true} className={code.length !== 4 ? "not-allowed-button" : "allowed-button"}>
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
}
export default OTP;
