import * as types from "../../Config/actionType";

export const addPlanRequest = (data) => {
  return {
    type: types.ADD_PLAN_REQUEST,
    data: data,
  };
};

export const addPlanSuccess = (data) => {
  return {
    type: types.ADD_PLAN_SUCCESS,
    data: data,
  };
};

export const addPlanFailure = (data) => {
  return {
    type: types.ADD_PLAN_FAILURE,
    data: data,
  };
};
