import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import storage from "../../../Utils/storage";

import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { createLotRequest } from "../../../redux/Actions/Payment/createLotAction";
import { AccountviewAllPaymentListingRequest } from "../../../redux/Actions/Accounts/ViewAllPharmacyListingAction";

function CreatingLotPharmacy() {
  const columns = [
    {
      field: "id",
      headerClassName: "super-app-theme--header",
      headerName: "Id",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "patientName",
      headerClassName: "super-app-theme--header",
      headerName: "Patient Name",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 130,
      flex: 2,
    },
    {
      field: "pharmacyName",
      headerClassName: "super-app-theme--header",
      headerName: "Pharmacy Name",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 170,
      flex: 2,
    },

    {
      field: "address",
      headerClassName: "super-app-theme--header",
      headerName: "Address",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 190,
      flex: 2,
    },

    {
      field: "totalAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Total Amount",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 120,
      flex: 2,
    },
    {
      field: "billAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Bill Amount",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 120,
      flex: 2,
    },
    {
      field: "purchaseDate",
      headerClassName: "super-app-theme--header",
      headerName: "Date of Purchase ",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 180,
      flex: 3,
      renderCell: (params) => {
        return (
          <>
            <Typography>
              {new Date(params.row["purchaseDate"]).toLocaleString()}
            </Typography>
          </>
        );
      },
    },
    {
      field: "collectedAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Collected Amount",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "discountedAmount",
      headerClassName: "super-app-theme--header",
      headerName: "Discounted Amount",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 140,
      flex: 1,
    },
    {
      field: "paymentStatus",
      headerClassName: "super-app-theme--header",
      headerName: "Payment Status",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 120,
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            {params.row["paymentStatus"] === "SEND_TO_ACCOUNT" && (
              <p1 className="paymentStatus_SEND_TO_ACCOUNT">Send To Account</p1>
            )}
            {params.row["paymentStatus"] === "PAID" && (
              <p1 className="paymentStatus_Paid">Paid</p1>
            )}
            {params.row["paymentStatus"] === "PENDING" && (
              <p1 className="paymentStatus_PENDING">Pending</p1>
            )}
          </>
        );
      },
    },
    {
      field: "lotId",
      headerClassName: "super-app-theme--header",
      headerName: "Lot ID",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        if (params.row["lotId"] === null) {
          return <p>-</p>;
        } else {
          <p>{params.row["lotId"]}</p>;
        }
      },
    },
  ];

  const city = storage.getStorage("cityId");

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const [amount, setAmount] = useState(0);
  const [count, setCount] = useState(0);
  const [prescriptionId, setPrescriptionId] = useState([]);

  const appointmentId = [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paymentStatus = true;

  useEffect(() => {
    if (startDate != null && endDate === null) {
      Swal.fire({
        title: "Please Add EndDate",
        icon: "error",
      });
    } else if (startDate > endDate && endDate !== null) {
      Swal.fire({
        title: "EndDate should be bigger than StartDate",
        icon: "error",
      });
    } else {
      dispatch(
        AccountviewAllPaymentListingRequest(
          1,
          pageSize,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
      setPage(1);
    }
  }, [startDate, endDate]);

  const AccountviewAllPaymentListing = useSelector(
    (state) => state.AccountviewAllPaymentListing
  );

  const createLot = useSelector((state) => state.createLot);
  const handleStartDate = (e) => {
    var date = new Date(e.target.value);
    date = date.getTime();
    setStartDate(date);
  };

  const handleEndDate = (e) => {
    var date = new Date(e.target.value);
    date = date.getTime();
    setEndDate(date);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        AccountviewAllPaymentListingRequest(
          value,
          pageSize,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
      setPage(value);
    } else {
      dispatch(
        AccountviewAllPaymentListingRequest(
          value,
          pageSize,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        AccountviewAllPaymentListingRequest(
          page,
          e.target.value,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
      setPage(1);
    } else {
      dispatch(
        AccountviewAllPaymentListingRequest(
          searchPage,
          e.target.value,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
    }
    setPageSize(e.target.value);
  };

  const handleSelect = (itm) => {
    setPrescriptionId(itm);
    const selectedRowsData = itm.map((id) =>
      AccountviewAllPaymentListing.data?.data.data.data.prescriptionLotResponseDtoList.find(
        (row) => row.id === id
      )
    );

    let fa = 0;
    for (let i = 0; i < selectedRowsData.length; i++) {
      setPrescriptionId([...prescriptionId, selectedRowsData[i].id]);

      fa = fa + selectedRowsData[i].billAmount;
    }
    setAmount(fa);
    setCount(selectedRowsData.length);
  };

  const handleCreateLot = () => {
    if (prescriptionId.length === 0) {
      swal({
        title: `Select the Lot Creation`,
        icon: "error",
      });
    } else {
      swal({
        title: `Confirm Lot Creation`,
        icon: "info",
      }).then(() => {
        dispatch(createLotRequest(appointmentId, prescriptionId));
      });
    }
  };

  if (
    createLot?.data?.data?.status === 201 &&
    createLot?.data?.data?.data.message !== ""
  ) {
    swal({
      title: `Lot Created Successfully`,
      icon: "success",
    }).then(() => {
      createLot.data.data.status = null;
      navigate("/PhamacyListing");
    });
  }

  const handleSearch = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        AccountviewAllPaymentListingRequest(
          page,
          pageSize,
          startDate,
          endDate,
          searchText,
          paymentStatus
        )
      );
      setPage(1);
    } else {
      dispatch(
        AccountviewAllPaymentListingRequest(
          searchPage,
          pageSize,
          startDate,
          endDate,
          e.target.value,
          paymentStatus
        )
      );
    }
    setSearchText(e.target.value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <div className="grid">
        <h4 className="h4">Payments</h4>
        <div className="mainBox">
          <div className="paymentHead">
            <div className="appleftHead">
              <h5 className="paymentLotlist">Create Lot </h5>
              <div className="count">
                {
                  AccountviewAllPaymentListing.data?.data.data.data
                    .totalPrescription
                }{" "}
                Prescription
              </div>
            </div>
          </div>
          <hr />
          <div className="paymentRow2">
            <div className="paymentDate">
              <div className="paymentStartDate">
                Start Date
                <input
                  type="date"
                  onChange={(e) => handleStartDate(e)}
                  style={{
                    border:
                      endDate !== 0 && startDate === 0
                        ? "1px solid red"
                        : "1px solid black",
                  }}
                ></input>
              </div>
              <div className="paymentEndDate">
                End Date
                <input
                  type="date"
                  onChange={(e) => handleEndDate(e)}
                  style={{
                    border:
                      startDate !== 0 && endDate === 0
                        ? "1px solid red"
                        : "1px solid black",
                  }}
                ></input>
              </div>
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    borderRadius: "6px",
                    border: "1px solid #52608E",
                  }}
                  placeholder="Search"
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="searchButton"></div>
            </div>
          </div>

          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {AccountviewAllPaymentListing.data?.data.data?.data
              .prescriptionLotResponseDtoList && (
              <DataGrid
                checkboxSelection
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    width: "min-content",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                onSelectionModelChange={(itm) => handleSelect(itm)}
                pagination
                disableColumnMenu
                rowCount={
                  AccountviewAllPaymentListing.data?.data.data.data
                    .totalPrescription
                }
                rows={
                  AccountviewAllPaymentListing.data?.data.data.data
                    .prescriptionLotResponseDtoList
                }
                columns={columns}
                rowsPerPageOptions={[10, 50, 100]}
                components={{
                  Footer: "none",
                }}
              />
            )}
          </Box>

          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              count={
                AccountviewAllPaymentListing.data?.data.data.data.totalPages
              }
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
          <div className="createLotAmount">
            <div>
              <div className="amount">
                <h5 className="h5">Amount :</h5>
                <h5 className="h5">Rs {amount}</h5>
              </div>
              <div className="consultation">
                <h5 className="h5">Consultations :</h5>
                <h5 className="h5">{count}</h5>
              </div>
            </div>
            <button className="lotCreateBtn" onClick={handleCreateLot}>
              Create Lot
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatingLotPharmacy;
