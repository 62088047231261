import * as types from "../../Config/actionType"

export const approveDiagnosticRequest = (id, status,location) => {
    return {
        type: types.APPROVE_DIAGNOSTIC_REQUEST,
        id,
        status,
        location,
    }
}

export const approveDiagnosticSuccess = (action) => {
    return {
        type: types.APPROVE_DIAGNOSTIC_SUCCESS,
        action
    }
}

export const approveDiagnosticFailure = (action) => {
    return {
        type: types.APPROVE_DIAGNOSTIC_FAILURE,
        action
    }
}