import * as types from "../../Config/actionType"

export const chatStatusRequest = (data) => {
    return {
        type: types.CHAT_STATUS_REQUEST,
        data:data
    }
}

export const chatStatusSuccess = (data) => {
    return {
        type: types.CHAT_STATUS_SUCCESS,
        data:data,
    }
}

export const chatStatusFailure = (data) => {
    return {
        type: types.CHAT_STATUS_FAILURE,
        data:data,
    }
}