import * as types from "../../Config/actionType"

export const viewAllBookingRequest = (page,pageSize,value,city) => {
    return {
        type: types.VIEW_ALL_BOOKING_REQUEST,
        page,
        pageSize,
        value,
        city
    }
}

export const viewAllBookingSuccess = (action) => {
    return {
        type: types.VIEW_ALL_BOOKING_SUCCESS,
        action
    }
}

export const viewAllBookingFailure = (action) => {
    return {
        type: types.VIEW_ALL_BOOKING_FAILURE,
        action
    }
}