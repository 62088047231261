import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Doctors/endAppointmentAction";
import NetworkService from "../../../network/NetworkService";

export function* endAppointment(action) {
 
  try {
    let response = yield call(NetworkService.endAppointment, action);
    yield put(actions.endAppointmentSuccess(response));
  } catch (err) {
    yield put(actions.endAppointmentFailure(err));
  }
}

export function* watchEndAppointment() {
  yield takeLatest(types.END_APPOINTMENT_REQUEST, endAppointment);
}

