import * as types from "../../Config/actionType";

export const viewAllAppointmentsWithoutLotRequest = (
  page,
  pageSize,
  startDate,
  endDate,
  search
) => {
  return {
    type: types.VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_REQUEST,
    page,
    pageSize,
    startDate,
    endDate,
    search,
  };
};

export const viewAllAppointmentsWithoutLotSuccess = (data) => {
  return {
    type: types.VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_SUCCESS,
    data: data,
  };
};

export const viewAllAppointmentsWithoutLotFailure = (data) => {
  return {
    type: types.VIEW_ALL_APPOINTMENTS_WITHOUT_LOT_FAILURE,
    data: data,
  };
};
