import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/Product/ProductActionType";
import * as actions from "../../Actions/Product/getCategoryDetailsAction";
import NetworkService from "../../../network/Product/ProductNetworkServices";

export function* getCategoyDetailsSaga(action) {
 
  try {

   
    let response = yield call(NetworkService.getCategoryDetails, action);

   
    yield put(actions.getCategoryDetailsSuccess(response));
  } catch (err) {
   
    yield put(actions.getCategoryDetailsFailure(err));
  }
}

export function* watchGetCategoyDetailsSaga() {
 
  yield takeLatest(types.GET_CATEGORY_DETAILS_REQUEST, getCategoyDetailsSaga);
}
