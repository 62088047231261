import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Program Template/Services/viewAllServicesAttributesAction";
import NetworkService from "../../../../network/NetworkService";

export function* viewAllServicesAttributesSaga(action) {
  try {
    //
    let response = yield call(NetworkService.viewAllServicesAttributes,action);
    //
    yield put(actions.viewAllServicesAttributesSuccess(response));
  } catch (err) {
    yield put(actions.viewAllServicesAttributesFailure(err));
  }
}



export function* watchviewAllServicesAttributesSaga() {
  yield takeLatest(types.VIEW_ALL_SERVICES_ATTRIBUTES_REQUEST , viewAllServicesAttributesSaga);
}

