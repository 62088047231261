export const VIEW_ALL_PHARMACY_REQUEST = "VIEW_ALL_PHARMACY_REQUEST";
export const VIEW_ALL_PHARMACY_SUCCESS = "VIEW_ALL_PHARMACY_SUCCESS";
export const VIEW_ALL_PHARMACY_FAILURE = "VIEW_ALL_PHARMACY_FAILURE";

export const ADD_PHARMACY_REQUEST = "ADD_PHARMACY_REQUEST";
export const ADD_PHARMACY_SUCCESS = "ADD_PHARMACY_SUCCESS";
export const ADD_PHARMACY_FAILURE = "ADD_PHARMACY_FAILURE";

export const DELETE_PHARMACY_REQUEST = "DELETE_PHARMACY_REQUEST";
export const DELETE_PHARMACY_SUCCESS = "DELETE_PHARMACY_SUCCESS";
export const DELETE_PHARMACY_FAILURE = "DELETE_PHARMACY_FAILURE";

export const ACCOUNT_VIEW_ALL_PHARMACY_LISTING_REQUEST =
  "ACCOUNT_VIEW_ALL_PHARMACY_LISTING_REQUEST";
export const ACCOUNT_VIEW_ALL_PHARMACY_LISTING_SUCCESS =
  "ACCOUNT_VIEW_ALL_PHARMACY_LISTING_SUCCESS";
export const ACCOUNT_VIEW_ALL_PHARMACY_LISTING_FAILURE =
  "ACCOUNT_VIEW_ALL_PHARMACY_LISTING_FAILURE";

export const GET_PHARMACY_DETAIL_REQUEST = "GET_PHARMACY_DETAIL_REQUEST";
export const GET_PHARMACY_DETAIL_SUCCESS = "GET_PHARMACY_DETAIL_SUCCESS";
export const GET_PHARMACY_DETAIL_FAILURE = "GET_PHARMACY_DETAIL_FAILURE";
