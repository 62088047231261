import { all } from "redux-saga/effects";
import { watchNetworkDiagnosticSaga } from "./Diagnostic/NetworkDiagnosticSaga";
import { watchNetworkViewDiagnosticSaga } from "./Diagnostic/NetworkViewDiagnosticSaga";
import { watchNetworkHospitalSaga } from "./Hospital/NetworkHospitalSaga";
import { watchNetworkViewHospitalSaga } from "./Hospital/NetworkViewHospitalSaga";
import { watchnetworkClinicSaga } from "./Clinic/NetworkClinicSaga";
import { watchnetworkViewClinicSaga } from "./Clinic/NetworkViewClinicSaga";
function* networkSaga() {
  yield all([
    watchNetworkHospitalSaga(),
    watchNetworkViewHospitalSaga(),
    watchNetworkDiagnosticSaga(),
    watchNetworkViewDiagnosticSaga(),
    watchnetworkClinicSaga(),
    watchnetworkViewClinicSaga(),
  ]);
}

export default networkSaga;
