export const api = {
    getBrandDropdown: "/ecom/brand/dropdown",
    getRelatedProduct:"/ecom/related/product/dropdown",
    getUnits:"/ecom/product/unit/dropdown",
    getCategoryDropdown:"/ecom/category/dropdown",
    getSubCategoryDropdown:"/ecom/sub-category/dropdown",
    addProduct:"/ecom/product",
    getAllProduct:"/ecom/product/view-all",
    getProduct:"/ecom/product",
    editProduct:"/ecom/product",
    disableProduct:"/ecom/product",
    getAllCategory:"/ecom/category/view-all",
    getAllSubCategory:"/ecom/sub-category/view-all",
    getCategoryDetails: "/ecom/category",
    addSubCategory:"/ecom/sub-category",
    addBrand:"/ecom/brand",
    getAllBrand:"/ecom/brand/view-all",
    getBrandDetails: "/ecom/brand",
    updateCategory:"/ecom/category",
    updateSubCategory:"/ecom/sub-category",
    getSubCategoryDetails:"/ecom/sub-category",
    updateBrand:"/ecom/brand",
    deleteCategory: "/ecom/category",
    deleteSubCategory: "/ecom/sub-category",
    deleteBrand:"/ecom/brand",







    
  };
  