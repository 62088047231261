import React, { useState, useEffect } from "react";
import "./SwitchUserModal.css";
import swal from "sweetalert";
import storage from "../../../Utils/storage";
import CryptoJS from "crypto-js";
import { getSecretKey } from "../../../Utils/secretKey";
import { roles } from "../../../helpers/MainConstants";

const SwitchUserModal = ({ closeModal }) => {
  const [selectRole, setSelectRole] = useState();

  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  const encryptedDataRoles = storage.getStorage("authorizationRoles");

  const decryptedBytes = CryptoJS.AES.decrypt(encryptedDataRoles, secretKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  let originalRolesArray;

  if (!decryptedBytes || decryptedBytes.sigBytes === 0) {
  } else {
    const decryptedDataString = decryptedBytes.toString(CryptoJS.enc.Utf8);

    try {
      originalRolesArray = JSON.parse(decryptedDataString);
    } catch (error) {}
  }

  const baseURL = window.location.protocol + "//" + window.location.host;

  const handleSelectRole = (e) => {
    setSelectRole(e.target.value);
  };
 
  const handleLogin = () => {
    if (
      selectRole === "RETAIL_USER" ||
      selectRole === "EMPLOYEE" ||
      selectRole === "LAB_ADMIN" ||
      selectRole === "PHARMA_ADMIN"
    ) {
      swal({
        title: "Not authorized",
        icon: "error",
        text: "The user does not have access to this application",
      }).then(() => {
        // window.location.href = "/";
      });
    } else if (selectRole === undefined) {
      swal({
        title: "Select the Role",
        icon: "info",
      });
    } else {
      const encryptedData = CryptoJS.AES.encrypt(
        selectRole,
        secretKey
      ).toString();
      let newTab;

      storage.setStorage("authorization", encryptedData);
      switch (selectRole) {
        case roles.SUPER_ADMIN:
          newTab = window.open(baseURL + "/super-Admin-Dashboard", "_blank");
          break;

        case roles.SUPER_PHARMA_ADMIN:
          newTab = window.open(baseURL + "/pharmacy-Dashboard", "_blank");
          break;

        case roles.ACCOUNTS:
          newTab = window.open(baseURL + "/accounts-Dashboard", "_blank");
          break;

        case roles.CUSTOMER_SERVICE_AGENT:
          newTab = window.open(
            baseURL + "/customer-Service-Dashboard",
            "_blank"
          );
          break;

        case roles.INSURER:
          newTab = window.open(
            baseURL + "/insurance-Agent-Dashboard",
            "_blank"
          );
          break;

        case roles.CORPORATE_HR:
          newTab = window.open(baseURL + "/corporate-Hr-Dashboard", "_blank");
          break;

        case roles.DOCTOR:
          newTab = window.open(baseURL + "/doctor-Dashboard", "_blank");
          break;

        case roles.SUPER_LAB_ADMIN:
          newTab = window.open(baseURL + "/lab-Dashboard", "_blank");
          break;

        case roles.HOSPITAL_ADMIN:
          newTab = window.open(baseURL + "/hospital-Dashboard", "_blank");
          break;

        case roles.IN_HOUSE_DOCTOR:
          newTab = window.open(baseURL + "/inHouse-Doctor-Dashboard", "_blank");
          break;

        default:
          newTab = window.location.href = "/";
          break;
      }
      const flagData = { flag: true };
      newTab.onload = function () {
        newTab.postMessage(
          { type: "SET_AUTHORIZATION", data: encryptedData, flagData },
          window.location.origin
        );
        const actualClose = window.close;
  
        // Override wind.close and setup a promise that is resolved on wind.close
        const closePromise = new Promise(r=>{window.close = ()=>{r(undefined);}});
      
        // Setup an async function
        // that closes the window after resolution of the above promise
        (async ()=>{
          await closePromise; // wait for promise resolution
          actualClose(); // closing the window here is legal
        })();
        // alert("Please close this tab manually to complete the process.");
        try {
          window.close();
        } catch (error) {
        }
        swal({
          title: "Please log out to complete the process",
          icon: "info",
          closeOnClickOutside: false,
          // text: "Please close this tab manually to complete the process.",
        }).then(() => {
        window.location.href="/"
        });
      };
    }
    closeModal();
  };

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="loginScreenMain1">
            <div className="closeModalUserSwitch">
              <div className="closeSwithchModal" onClick={closeModal}>
                X
              </div>
            </div>
            <div className="switchUserHeading">
              <div className="loginScreenMainHeader1">Switch user</div>
            </div>

            <div className="formStarts1New1">
              <div className="SelectRoleMainDiv1">
                <div className="SelectRole1">Select Role</div>
                <select
                  onChange={(e) => handleSelectRole(e)}
                  className="SelectRoleDropdown1"
                >
                  <option selected disabled value={null}>
                    Select Role
                  </option>
                  {originalRolesArray?.map((itm) => (
                    <option value={itm}>{itm}</option>
                  ))}
                </select>
                <div className="UserLoginButtonNew">
                  <button
                    type="submit"
                    className="switchbuttonNew"
                    onClick={handleLogin}
                  >
                    Switch
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwitchUserModal;
