import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import Footer from "../../Organisms/Footer/FooterUpper";

import * as Yup from "yup";
import "./EditAgent.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateInsuranceAgentRequest } from "../../../redux/Actions/Agents/updateInsuranceAgentAction";
import Swal from "sweetalert2";
import { deleteAgentRequest } from "../../../redux/Actions/Agents/deleteAgentAction";

const validationSchema = Yup.object().shape({
  contactNumber: Yup.string().required("Required Field"),
  email: Yup.string().required("Required Field"),
  address: Yup.string().required("Required Field"),
  insuranceCompany: Yup.string().required("Required Field"),
  password: Yup.string().required("Required Field"),
  agentId:Yup.string().required("Required Field"),
  agentName: Yup.string().required("Required Field"),
  services: Yup.string().required("Required Field"),
});

function EditAgent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = (values, { setSubmitting }) => {
    //
    setTimeout(() => {
      dispatch(updateInsuranceAgentRequest(values));
      setSubmitting(false);
      // navigate('/agents');
    }, 400);
  };
  
  const location = useLocation();

  const id = location.state.id
// //
  const res = useSelector((state) => state.updateInsuranceAgent)

  if(res.data?.data.data.message === "Insurance agent details updated successfully"){
    navigate(`/viewAgentDetails/${id}`)
  }

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteAgentRequest(id));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        navigate("/agents");
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <div className="main">
        <h3>Agent Id - {location.state.id}</h3>
        <div className="box">
          <div className="box-head">
            <div className="agentHeaderName">{location.state.agentName}</div>
          </div>
          <hr />
          <div className="editAgent-box-body">
            <div className="editAgent-detail">
              <Formik
                initialValues={{
                  contactNumber: location.state.agentContact,
                  email: location.state.agentEmail,
                  address: location.state.agentAddress,
                  insuranceCompany: location.state.companyName,
                  password: location.state.password,
                  agentId: location.state.id,
                  agentName: location.state.agentName,
                  services: location.state.services
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {
                  <Form>
                    <div className="editAgent-vals">
                      <label className="editAgent-key">Contact Number</label>
                      <div className="editAgent-val">
                        <Field name="contactNumber" type="number" className="agentEditField" />
                        <ErrorMessage
                          className="errorMessage"
                          name="number"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="editAgent-vals">
                      <div className="editAgent-key">Official email</div>
                      <div className="editAgent-val">
                        <Field name="email" type="text" className="agentEditField" />
                        <ErrorMessage
                          className="errorMessage"
                          name="email"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="editAgent-vals">
                      <div className="editAgent-key">Address</div>
                      <div className="editAgent-val">
                        <Field name="address" type="text" className="agentEditField" />
                        <ErrorMessage
                          className="errorMessage"
                          name="city"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="editAgent-vals">
                      <div className="editAgent-key">Insurance Company</div>
                      <div className="editAgent-val">
                        <Field name="insuranceCompany" type="text" className="agentEditField"/>
                        <ErrorMessage
                          className="errorMessage"
                          name="company"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="editAgent-vals">
                      <div className="editAgent-key">
                        Insurer's Login Password
                      </div>
                      <div className="editAgent-val">
                        <Field name="password" type="password" className="agentEditField" />
                        <ErrorMessage
                          className="errorMessage"
                          name="password"
                          component="div"
                        />
                      </div>
                     </div>
                     <div className="editAgent-vals">
                      <div className="editAgent-key">
                        Services
                      </div>
                      <div className="editAgent-val">
                      <Field
                      as="select"
                      name="services"
                      placeholder="Select Services"
                      className="inputField"
                    >
                      <option value="" disabled selected>
                        Select Services
                      </option>
                      <option value="Cashless OPD">Cashless OPD</option>
                      <option value="Health Risk Assessment">
                        Health Risk Assessment
                      </option>
                    </Field>
                      </div>
                     </div>
                    <div className="buttons">
                      <button type="submit" className="btnAssign">
                        Edit
                      </button>
                      <button type="button" className="btnCancel"  onClick={handleDelete}>
                        Delete
                      </button>
                    </div>
                  </Form>
                }
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
export default EditAgent;