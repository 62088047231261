import * as types from "../../Config/actionType"

export const viewProgramRequest = (data) => {
    return {
        type: types.VIEW_PROGRAM_REQUEST,
        data
    }
}

export const viewProgramSuccess = (data) => {
    return {
        type: types.VIEW_PROGRAM_SUCCESS,
        data,
    }
}

export const viewProgramFailure = (data) => {
    return {
        type: types.VIEW_PROGRAM_FAILURE,
        data,
    }
}