import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "../../component/Organisms/Footer/PrivacyPolicy";

import PageNotFound from "../../component/Pages/PageNotFound/PageNotFound";

import Payment from "../../component/Pages/Payment/Payment";

import DashBoard from "../../component/Pages/Dashboard/DashBoard";
import Pharmacy from "../../component/Pages/Pharmacy/Pharmacy";
import ViewPharmacyDetail from "../../component/Pages/Pharmacy/ViewPharmacyDetail"
import SingleLogin from "../../component/Pages/Login/SingleLogin";

export default function PharmacyPrivateRoute() {
  return (
    <Routes>
      <Route path="/" element={<SingleLogin />} />

       <Route path="/pharmacy-Dashboard" element={<DashBoard />} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="*" element={<PageNotFound />}></Route>
      <Route path="/payments" element={<Payment />} />
      <Route path="/Pharmacy" element={<Pharmacy />} />
      <Route path="/ViewPharmacyDetail/:id" element={<ViewPharmacyDetail />} />
    </Routes>
  );
}