import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import storage from "../../../Utils/storage";
import smallBallImage from "../../../assets/Login/Ellipse 40.png";
import bigBallImage from "../../../assets/Login/Ellipse 45.png";
import Email from "../../../assets/Login/emailLogoLogin.png";
import eye from "../../../assets/Login/eye.png";
import Loader from "../../../assets/loader.gif";
import OTP from "../../../component/Organisms/OTP/Otp1";
import { roles } from "../../../helpers/MainConstants";
import { loginRequest } from "../../../redux/Actions/Login/loginAction";
import CryptoJS from "crypto-js";
import "./SingleLogin.css";
import { getSecretKey } from "../../../Utils/secretKey";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function SingleLogin() {
  const loginSchema = Yup.object().shape({
    password: Yup.string().required("Please enter password"),
    email: Yup.string()
      .matches(
        /^(?:[\w.-]+@[\w.-]+\.[a-zA-Z]{2,}|(?:\+?\d{1,3})?[ -]?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4})$/,
        "Enter a Valid E-mail Address or Phone Number"
      )
      .required("Email or Phone number is required!"),
  });

  useEffect(() => {
    const token = storage.getStorage("token");
    if (token) {
      // If the user is already logged in, perform logout logic
      // For example, clearing the token and redirecting to the home page
      storage.removeStorage("token");
      storage.removeStorage("authorization");
      storage.removeStorage("authorizationRoles");
      storage.removeStorage("name");
      storage.removeStorage("cityId");
      storage.removeStorage("cityName");
      window.location.href = "/";
    }
  }, []);

  const cred = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectRole, setSelectRole] = useState();

  const [val, setVal] = useState("");
  const [open, setOpen] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [showPass, setShowPass] = useState(false);

  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  const text =
    "Welcome to Yuva Health, your personalized healthcare portal! By logging in to your account, you can access various healthcare services and plan and receive customized health recommendations based on your unique needs. Book appointment, track your fitness goals, or stay on top of your medication schedule, Yuva Health has got you covered. With our user-friendly interface, secure login, and advanced features, you can take control of your health and wellness from anywhere, at any time.";

  const text2 =
    "Join the Yuva Health community today and start your journey towards a healthier, happier you !";

  function handlePasswordEye() {
    setShowPass(!showPass);
  }

  const handleSubmit = (values, { setSubmitting }) => {

    setVal(values);
    dispatch(loginRequest(values));
    setSubmitting(false);
  };

  const handleSelectRole = (e) => {
    setSelectRole(e.target.value);
  };

  const handleLogin = () => {
    if (
      selectRole === "RETAIL_USER" ||
      selectRole === "EMPLOYEE" ||
      selectRole === "LAB_ADMIN" ||
      selectRole === "PHARMA_ADMIN"
    ) {
      swal({
        title: "Not authorized",
        icon: "error",
        text: "The user does not have access to this application",
      }).then(() => {
        window.location.href = "/";
      });
    }
    else if (selectRole === undefined) {
      swal({
        title: "Select the Role",
        icon: "info",
      });
    }
    else {
      const encryptedData = CryptoJS.AES.encrypt(
        selectRole,
        secretKey
      ).toString();
      const encryptedToken = CryptoJS.AES.encrypt(
        cred.data?.data?.data?.data.refreshToken,
        secretKey
      ).toString();
      const rolesArray = cred.data?.data.data.data?.roles;
      const encryptedDataRoles = CryptoJS.AES.encrypt(
        JSON.stringify(rolesArray),
        secretKey,
        {
          mode: CryptoJS.mode.ECB, // You might want to use a different mode based on your requirements
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      storage.setStorage("sessionToken", encryptedToken);
      storage.setStorage("authorization", encryptedData);
      storage.setStorage("token", cred.data?.data.data.data?.jwt);
      storage.setStorage("authorizationRoles", encryptedDataRoles);
      storage.setStorage("name", cred.data?.data.data.data?.name);
      storage.setStorage("cityId", "");
      storage.setStorage("cityName", "All cities");
      switch (selectRole) {
        case roles.SUPER_ADMIN:
          return  window.location.href = "/super-Admin-Dashboard";
        case roles.SUPER_PHARMA_ADMIN:
          return  window.location.href = "/pharmacy-Dashboard";
        case roles.ACCOUNTS:
          return  window.location.href = "/accounts-Dashboard";
        case roles.CUSTOMER_SERVICE_AGENT:
          return  window.location.href = "/customer-Service-Dashboard";
        case roles.INSURER:
          return  window.location.href = "/insurance-Agent-Dashboard";
        case roles.CORPORATE_HR:
          return  window.location.href = "/corporate-Hr-Dashboard";
        case roles.DOCTOR:
          return  window.location.href = "/doctor-Dashboard";
        case roles.SUPER_LAB_ADMIN:
          return  window.location.href = "/lab-Dashboard";
        case roles.HOSPITAL_ADMIN:
          return  window.location.href = "/hospital-Dashboard";
        case roles.IN_HOUSE_DOCTOR:
          return  window.location.href = "/inHouse-Doctor-Dashboard";
    
      default:
        return  window.location.href = "/";
      }
    }
  };

  if (cred.error !== null) {
    swal({
      title: "" + Object.values(cred.error?.data?.response.data)[0],
      icon: "error",
    }).then(() => {
      window.location.href = "/";
    });
  }

  useEffect(() => {
    if (
      cred.data?.data.status === 200 &&
      cred.data?.data.data.data?.jwt !== null &&
      cred.data?.data.data?.data?.roles?.length === 1 &&
      cred.data?.data.data?.data?.roles?.includes(roles?.RETAIL_USER) ===
      false &&
      cred.data?.data.data?.data?.roles?.includes(roles?.EMPLOYEE) === false &&
      cred.data?.data.data?.data?.roles?.includes(roles?.LAB_ADMIN) === false &&
      cred.data?.data.data?.data?.roles?.includes(roles?.PHARMA_ADMIN) === false
    ) {
      const encryptedData = CryptoJS.AES.encrypt(
        selectRole,
        secretKey
      ).toString();
      const encryptedToken = CryptoJS.AES.encrypt(
        cred.data?.data?.data?.data.refreshToken,
        secretKey
      ).toString();
      const rolesArray = cred.data?.data.data.data?.roles;
      const encryptedDataRoles = CryptoJS.AES.encrypt(
        JSON.stringify(rolesArray),
        secretKey,
        {
          mode: CryptoJS.mode.ECB, // You might want to use a different mode based on your requirements
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString();
      storage.setStorage("sessionToken", encryptedToken);
      storage.setStorage("authorizationRoles", encryptedDataRoles);
      storage.setStorage("authorization", encryptedData);
      storage.setStorage("token", cred.data?.data.data.data?.jwt);
      storage.setStorage("name", cred.data?.data.data.data?.name);
      storage.setStorage("cityId", "");
      storage.setStorage("cityName", "All cities");
      window.location.href = "/dashboard";
    } else if (
      cred.data?.data.status === 200 &&
      cred.data?.data.data.data?.jwt !== null &&
      cred.data?.data.data?.data?.roles?.length === 1
    ) {
      swal({
        title: "Not authorized",
        icon: "error",
        text: "The user does not have access to this application",
      }).then(() => {
        window.location.href = "/";
      });
    }
  }, [cred]);

  // useEffect(() => {
  //   if (storage.getStorage("token") !== null) {
  //     window.location.href = "/";
  //   }
  // }, []);

  return (
    <>
      {cred.isLoading ? (
        <>
          <div className="corporateDashboardLoading">
            <img src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
        <>
          <div>
            {open && val !== "" && cred.data?.data.data.data === null && (
              <OTP open={setOpen} val={val} />
            )}
            <>
              <div className="NewUserLoginMainWrapper">
                <div className="NewUserLoginPart1">
                  <div className="NewUserLoginPart1TextWrapper">
                    <p className="NewUserLoginPart1Text">{text}</p>
                    <p className="NewUserLoginPart1Text">{text2}</p>
                  </div>

                  <div className="NewUserLoginPart1Image">
                    <img
                      src={bigBallImage}
                      className="NewUserLoginPart1Image_1"
                    />
                    <img
                      src={smallBallImage}
                      className="NewUserLoginPart1Image_2"
                    />
                  </div>
                </div>
                <div
                  className="NewUserLoginPart2"
                  style={{
                    backgroundSize: "100% 100%",
                  }}
                >
                  {/* <img src={loginImage} /> */}
                </div>

                <div className="loginScreenMain">
                  <div className="loginScreenMainHeader">
                    <p>Sign in</p>
                  </div>

                  <div className="formStarts1New">
                    {cred.data?.data.status === 200 &&
                      cred.data?.data.data.data?.jwt !== null &&
                      cred.data?.data.data?.data?.roles?.length > 1 ? (
                      <div className="SelectRoleMainDiv">
                        <div className="SelectRole">Select Role</div>
                        <select
                          onChange={(e) => handleSelectRole(e)}
                          className="SelectRoleDropdown"
                        >
                          <option selected disabled value={null}>
                            Select Role
                          </option>
                          {cred.data?.data.data?.data?.roles.map((itm) => (
                            <option value={itm}>{itm}</option>
                          ))}
                        </select>
                        <div className="UserLoginButtonNew">
                          <button
                            type="submit"
                            className="submitbuttonNew"
                            onClick={handleLogin}
                          >
                            <img alt="Email" src={Email} />
                            Login
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={loginSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ isSubmitting }) => {
                          return (
                            <Form className="newUserLoginForm">
                              <div className="boxNew">
                                <div className="labelpara">
                                  Mobile Number or Email Address
                                </div>
                                <label>
                                  <Field
                                    type="text"
                                    name="email"
                                    placeholder="Email or Number"
                                    className="login_textbox_new"
                                  />
                                  <ErrorMessage
                                    className="errorMessage"
                                    name="email"
                                    component="div"
                                  />
                                </label>
                              </div>

                              <div
                                className="boxNew2"
                                style={{
                                  marginTop:
                                    windowDimensions.width >= 1176
                                      ? "3vw"
                                      : "2em",
                                }}
                              >
                                <div className="labelpara">Password</div>
                                <label style={{ position: "relative" }}>
                                  {showPass ? (
                                    <Field
                                      type="text"
                                      name="password"
                                      placeholder="Password"
                                      className="login_textbox_new"
                                    />
                                  ) : (
                                    <Field
                                      type="password"
                                      name="password"
                                      placeholder="Password"
                                      className="login_textbox_new"
                                    />
                                  )}
                                  <img
                                    src={eye}
                                    onClick={handlePasswordEye}
                                    className="useregEye"
                                    style={{
                                      position: "absolute",
                                      width: "1em",
                                      height: ".7em",
                                      left: "80%",
                                      top: "35%",
                                      cursor: "pointer",
                                    }}
                                  />
                                  <ErrorMessage
                                    className="errorMessage"
                                    name="password"
                                    component="div"
                                  />
                                </label>
                              </div>

                              <div className="UserLoginButtonNew">
                                <button
                                  type="submit"
                                  className="submitbuttonNew"
                                  disabled={isSubmitting}
                                >
                                  <img alt="Email" src={Email} />
                                  Login
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
}

export default SingleLogin;