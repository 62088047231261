import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Login/loginLabAgentAction";
import NetworkService from "../../../network/NetworkService";

export function* LoginLabAgentSaga(action) {
  try {
     
    let response = yield call(NetworkService.loginLabAgentService, action);
    yield put(actions.loginLabAgentSuccess(response));
  } catch (err) {
    yield put(actions.loginLabAgentFailure(err));
  }
}

export function* watchLoginLabAgentSaga() {
  yield takeLatest(types.LOGIN_LAB_AGENT_REQUEST, LoginLabAgentSaga);
}