import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Tests/viewTestAction";
import NetworkService from "../../../network/NetworkService";

export function* viewTestSaga(action) {
  try {
    let response = yield call(NetworkService.viewTest, action);
    yield put(actions.viewTestSuccess(response));
  } catch (err) {
    yield put(actions.viewTestFailure(err));
  }
}

export function* watchViewTestSaga() {
  yield takeLatest(types.VIEW_TEST_REQUEST, viewTestSaga);
}