import * as types from "../../Config/actionType"

export const viewServiceRequest = (name) => {
    return {
        type: types.VIEW_SERVICE_REQUEST,
        name
    }
}

export const viewServiceSuccess = (action) => {
    return {
        type: types.VIEW_SERVICE_SUCCESS,
        action
    }
}

export const viewServiceFailure = (action) => {
    return {
        type: types.VIEW_SERVICE_FAILURE,
        action
    }
}