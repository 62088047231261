import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import "./Doctors.css";
import { viewAllDoctorsRequest } from "../../../redux/Actions/Doctors/viewAllDoctorsActions";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import ModalAddDoctor from "../../Organisms/Modal/ModalAddDoctor";
import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import ModalUpdateDoctor from "../../Organisms/Modal/ModalUpdateDoctor";
import Pagination from "@mui/material/Pagination";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import storage from "../../../Utils/storage";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DisableDoctorModal from "../../Organisms/Modal/DisableDoctorModal";
import ActiveDropdown from "../../Organisms/ActiveDropdown/ActiveDropdown";
import {mapToElasticDoctorRequest} from "../../../redux/Actions/Doctors/mapToElasticDoctor"
import Loader from "../../../assets/Loader/loader.gif";


function Doctors() {
  const dispatch = useDispatch();

  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [edit, setEdit] = React.useState(null);
  const [add, setAdd] = React.useState(null);
  const [values, setValues] = React.useState();
  const [pageSize, setPageSize] = React.useState(10);
  const [delOpen, setdelOpen] = React.useState(false);
  const [delId, setDelId] = useState();
  const [searchPage, setSearchPage] = useState(1);
  const [page, setPage] = React.useState(1);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState();

  const viewCity = useSelector((state) => state.viewAllCity);
  const viewDoctor = useSelector((state) => state.doctor.viewAllDoctors);


  useEffect(() => {
    if (city === null) {
      dispatch(viewAllDoctorsRequest("", 1, pageSize, searchText, "", status));
    } else {
      dispatch(
        viewAllDoctorsRequest("", 1, pageSize, searchText, city, status)
      );
    }
    dispatch(viewAllCityRequest());
    setPage(1);
  }, [city]);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });


  const handleSearch = (e) => {
    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        viewAllDoctorsRequest("", page, pageSize, newSearchText, city, status)
      );
      setPage(1);
    } else {
      dispatch(
        viewAllDoctorsRequest(
          "",
          searchPage,
          pageSize,
          newSearchText,
          city,
          status
        )
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAdd = () => {
    setAdd(true);
  };

  const handleEdit = (value) => {
    setValues(value);
    setEdit(1);
  };

  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllDoctorsRequest("", value, pageSize, searchText, city, status)
      );
      setPage(value);
    } else {
      dispatch(
        viewAllDoctorsRequest("", value, pageSize, searchText, city, status)
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllDoctorsRequest(
          "",
          page,
          event.target.value,
          searchText,
          city,
          status
        )
      );
    } else {
      dispatch(
        viewAllDoctorsRequest(
          "",
          searchPage,
          event.target.value,
          searchText,
          city,
          status
        )
      );
    }
    setPageSize(event.target.value);
  };

  const handleDelete = (id) => {
    setDelId(id);

    setdelOpen(true);
  };

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
    // window.location.reload();
    //
  };

  const handleStatus = (e) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllDoctorsRequest(
          "",
          page,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    } else {
      dispatch(
        viewAllDoctorsRequest(
          "",
          searchPage,
          pageSize,
          searchText,
          city,
          e.target.value
        )
      );
    }
    setStatus(e.target.value);
  };

  const columns = [
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: " Name",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "speciality",
      headerClassName: "super-app-theme--header",
      headerName: " Speciality",
      minWidth: 150,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "qualifications",
      headerClassName: "super-app-theme--header",
      headerName: "Qualifications",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "experience",
      headerClassName: "super-app-theme--header",
      headerName: "Years of Experience",
      minWidth: 160,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "hospitalName",
      headerClassName: "super-app-theme--header",
      headerName: "Hospital",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "cost",
      headerClassName: "super-app-theme--header",
      headerName: "Consult Fees",
      minWidth: 200,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "rating",
      headerClassName: "super-app-theme--header",
      headerName: "Rating",
      minWidth: 100,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      field: "Modify",
      minWidth: 120,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            {viewDoctor.data?.data?.data?.doctorResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <IconButton sx={{ pointerEvents: "none", cursor: "not-allowed" }}>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            ) : (
              <IconButton>
                <EditIcon
                  sx={{ color: "#1D2334" }}
                  onClick={() => handleEdit(cellValues.row)}
                />
              </IconButton>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {viewDoctor.data?.data?.data?.doctorResponseDtos[
              cellValues.api.getRowIndex(cellValues.row.id)
            ]?.active === false ? (
              <div>
                <Switch disabled sx={{ color: "#52608E" }} />
              </div>
            ) : (
              <div>
                {" "}
                <Switch
                  defaultChecked
                  sx={{ color: "#52608E" }}
                  onClick={() => handleDelete(cellValues.row.id)}
                />{" "}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  function handleSync() {
    dispatch(mapToElasticDoctorRequest());
  }

  const mapToElasticDoctor = useSelector((state) => state.mapToElasticDoctor);

  return (
    <div>
      <div className="grid">
        {edit && values && <ModalUpdateDoctor value={values} open={setEdit} />}
        <h4 className="h4">Doctors</h4>
        <div className="mainBox">
          {delOpen && <DisableDoctorModal delOpen={setdelOpen} delId={delId} />}

          <div className="head">
            <div className="leftHead">
              <h5 className="list">List of Doctors</h5>
              <div className="doctorcount">
                {viewDoctor.data?.data.data.totalDoctors} Doctors
              </div>
            </div>
            <div className="rightheadbtn">
              {mapToElasticDoctor.isLoading===true ?  <button
                type="button"
                className="btn"
              style={{ width: "10%", height: "2.3em", paddingRight: "1em", paddingLeft: "1em", marginRight: "1em", display: "flex", alignItems: "center", justifyContent: "center", background: "#3C9C4B" }}

              >
                             <img src={Loader} style={{ width: "50%", height: "70%" }} />

              </button>: <button
                type="button"
                className="btn"
                onClick={handleSync}
                style={{width:"10%",height:"2.3em",paddingRight:"1em",paddingLeft:"1em",marginRight:"1em",display:"flex",alignItems:"center",justifyContent:"center"}}

              >
                Sync
              </button>}
              <div
                type="button"
                className="btn addModalbtn"
                onClick={() => handleAdd()}
              >
                + Add Doctors
              </div>
            </div>

            {add && <ModalAddDoctor open={setAdd} />}
          </div>
          <hr />
          <div className="row2">
            <div className="requestCity">
              <AutoComplete
                onChange={(event, newValue) => {
                  handleCity(event, newValue);
                }}
                heading="All Cities"
                cityList={cityNames}
              />
              <ActiveDropdown onChange={(e) => handleStatus(e)} />
            </div>
            <div className="searchSection">
              <div className="searchBar">
                <TextField
                  type="text"
                  name="search"
                  // onChange={(e) => setName(e.target.value)}
                  onChange={handleSearch}
                  variant="outlined"
                  sx={{
                    border: "1px solid #52608E",
                    borderRadius: "6px",
                    width: "350px",
                  }}
                  placeholder="Search "
                  size="small"
                  hiddenLabel="true"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#52608E" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <div className="searchButton">
                <button
                  type="submit"
                  value="Submit"
                  className="corporatesearchBtn"
                  style={{
                    backgroundColor: "#52608E",
                    width: "150px",
                    align: "end",
                    color: "white",
                  }}
                >
                  <FilterAltIcon fontSize="small" />
                  Filter
                </button>
              </div> */}
              {/* <div className="searchButton">
                <form
                  onSubmit={(e) => {
                    handleSearch(e);
                  }}
                >
                  <button
                    type="submit"
                    value="Submit"
                    className="doctorsearchBtn"
                    style={{
                      backgroundColor: "#52608E",
                      width: "150px",
                      align: "end",
                      color: "white",
                      border: "none",
                    }}
                  >
                    Search
                  </button>
                </form>
              </div> */}
            </div>
          </div>
          <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
            {viewDoctor.data?.data.data.doctorResponseDtos && (
              <DataGrid
                sx={{
                  color: "#1D2334",
                  "& .super-app-theme--header": {
                    backgroundColor: "#52608E",
                    color: "#F2EFEA",
                    "& > .MuiDataGrid-columnSeparator": {
                      visibility: "hidden",
                    },
                  },
                  "&:hover:not(.Mui-disabled)": {
                    cursor: "pointer",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                  ".MuiTablePagination-toolbar": {
                    alignItems: "baseline",
                    marginTop: "5%",
                  },
                }}
                rows={viewDoctor.data?.data.data.doctorResponseDtos}
                columns={columns}
                rowCount={viewDoctor?.data?.data.data.totalDoctors}
                pageSize={pageSize}
                disableColumnMenu
                rowsPerPageOptions={[10, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                paginationMode="server"
                components={{
                  Footer: "none",

                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      No results found
                    </Stack>
                  ),
                }}
              />
            )}
          </Box>
          <div className="MainTablePagination">
            <p>Rows Per Page:</p>
            <select
              className="dropDown"
              defaultValue={10}
              onChange={handleDropChange}
            >
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <Pagination
              shape="rounded"
              count={viewDoctor?.data?.data.data.totalPages}
              color="primary"
              onChange={handlePageChange}
              page={
                searchText === "" || searchText.length <= 1 ? page : searchPage
              }
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Doctors;
