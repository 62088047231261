import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { viewAllServiceRequest } from "../../../redux/Actions/Service/viewAllServiceAction";
import { viewServiceRequest } from "../../../redux/Actions/Service/viewServiceAction";
import "./AddProgram.css";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Required Field"),
  selectServices: Yup.string().required("Please select"),
});

const modalHeader = {
  fontSize: "35px",
  fontFamily: "Nunito",
  marginBottom: "30px",
  marginTop: "15px",
};

function ModalNewProgram(props) {
  const [selected, setSelected] = useState("");
  const [array, setArray] = useState([{ name: "", list: [] }]);


  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      //   dispatch(addHospitalRequest(values));
      setSubmitting(false);
    }, 400);
  };

  const handleService = (e) => {
    setSelected(e.target.value);
    allService?.data?.action?.data?.data?.map((item, index) => {
      if (e.target.value === item.name) {
        var arr = [];
        arr.push({ name: e.target.value, list: item.attributeDtoList });
        setArray([...array, arr]);
      }
    });
  };

  //   const res = useSelector((state) => state.addHospital);

  //   if (res.data?.data.data.message === "Hospital added successfully") {
  //     props.open(false);
  //     Swal.fire({
  //       title: "Hospital Added Successfully",
  //       icon: "success",
  //     }).then(() => {
  //       window.location.reload();
  //     });
  //   }
  useEffect(() => {
    dispatch(viewAllServiceRequest());
  }, []);

  useEffect(() => {
    dispatch(viewServiceRequest(selected));
  }, [selected]);

  const allService = useSelector((state) => state.viewAllService);
  const serviceAttr = useSelector((state) => state.viewService);

  

  const [service, setService] = useState([
    {
      serviceName: "",
      attributeName: "",
      attributeId: "",
      atrributeCount: "",
      packageName: "",
      packageCount: "",
    },
  ]);

  const handleAdd = () => {
    setService([
      ...service,
      {
        serviceName: "",
        attributeName: "",
        attributeId: "",
        atrributeCount: "",
        packageName: "",
        packageCount: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    const list = [...service];
    list.splice(index, 1);
    setService(list);
  };

  return (
    <div>
      <div className="modalHeader">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={modalHeader}
        >
          Add New Program
        </Typography>

        
      </div>

      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {
          <Form>
            <div className="inputFieldsContainer">
              <label>
                <Field
                  type="text"
                  name="name"
                  placeholder="Program Name"
                  className="inputField"
                />
                <ErrorMessage
                  className="errorMessage"
                  name="name"
                  component="div"
                />
              </label>
              <label>
                <Field
                  as="textarea"
                  type="text"
                  name="description"
                  placeholder="Description"
                  className="inputField"
                />
                <ErrorMessage
                  className="errorMessage"
                  name="contactNumber"
                  component="div"
                />
              </label>
              Services
              {service.map((x, i) => {
                return (
                  <div>
                    <select onChange={handleService}>
                      <option value={x.serviceName} disabled selected>
                        Select Service
                      </option>
                      {allService?.data?.action?.data?.data?.map(
                        (item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        )
                      )}
                    </select>
                    <input
                      placeholder="count"
                      type="number"
                      value={x.atrributeCount}
                    />
                      <div>
                        {array.map((item) => {
                          
                          
                          {
                            item[i]?.list.map((aName) => {
                            
                              <div>{aName.attributeName}</div>
                            });
                          }
                        })}
                      </div>
                    {service.length - 1 === i && (
                      <button onClick={handleAdd}>Add</button>
                    )}
                    {service.length !== 1 && (
                      <button onClick={() => handleRemove(i)}>Remove</button>
                    )}
                  </div>
                );
              })}
              <button type="submit" className="modalButton">
                Save Program Template
              </button>
            </div>
          </Form>
        }
      </Formik>
    </div>
  );
}

export default ModalNewProgram;
