import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../../Config/actionType";
import * as actions from "../../../Actions/Network/ProfessionalService/ApproveProfessionalService";
import NetworkService from "../../../../network/NetworkService";

export function* approveProfessionalServiceSaga(action) {
  try {
    let response = yield call(NetworkService.approveProfessionalService, action);
    yield put(actions.approveProfessionalServiceSuccess(response));
  } catch (err) {
    yield put(actions.approveProfessionalServiceFailure(err));
  }
}

export function* watchProfessionalService() {
  yield takeLatest(types.APPROVE_PROFESSIONAL_SERVICES_REQUEST, approveProfessionalServiceSaga);
}

