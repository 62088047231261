import * as types from "../../Config/actionType"

export const deleteHospitalRequest = (id,data,file) => {
    return {
        type: types.DELETE_HOSPITAL_REQUEST,
        id:id,
        data:data,
        file:file
    }
}

export const deleteHospitalSuccess = (action) => {
    return {
        type: types.DELETE_HOSPITAL_SUCCESS,
        action
    }
}

export const deleteHospitalFailure = (action) => {
    return {
        type: types.DELETE_HOSPITAL_FAILURE,
        action
    }
}