import * as types from "../../Config/actionType"

export const AssignCardSubscriptionRequest = (data) => {
   
    return {
        type: types.ASSIGN_CARD_SUBSCRIPTION_REQUEST,
        data
            
    }
}

export const AssignCardSubscriptionSuccess = (action) => {
   
    return {
        type: types.ASSIGN_CARD_SUBSCRIPTION_SUCCESS,
        action
    }
}

export const AssignCardSubscriptionFailure = (action) => {
   
    return {
        type: types.ASSIGN_CARD_SUBSCRIPTION_FAILURE,
        action
    }
}