import React, { useEffect, useState } from "react";
import Footer from "../../../Organisms/Footer/FooterUpper";
import { HospitalDashboardRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/HospitalDashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AllHospitalNameDropDownRequest } from "../../../../redux/Actions/Dashboard/HospitalDashboard/AllHospitalNameDropdown";
import "./HospitalDashboard.css";
import Loader from "../../../../assets/loader.gif";

function HospitalDashboard() {
  const [startDate, setStartDate] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [selectHospital, setSelectHospital] = useState(null);
  const HospitalDasboard = useSelector((state) => state.HospitalDasboard);

  const AllHospitalNameDropdown = useSelector(
    (state) => state.AllHospitalNameDropdown
  );

  const HospitalDasboardData = HospitalDasboard?.data?.data?.data?.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEndDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setEndDate(date);
    setFormattedEndDate(e.target.value);
  };

  const handleSelectHospital = (e) => {
    setSelectHospital(e.target.value);
  };

  const handleStartDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setStartDate(date);
    setFormattedStartDate(e.target.value);
  };

  const handleActiveDoctor = () => {
    navigate("/HospitalDoctorList");
  };
  const totalNumberOfAppointment = () => {
    navigate("/DashboardAppointmentstatus", {
      state: {
        appointmentStatus: ["COMPLETED", "FINISHED", "CONFIRMED"],
        paymentStatus: null,
        value: "Total Number Of Appointment ",
      },
    });
  };
  const CompletedNumberOfAppointment = () => {
    navigate("/DashboardAppointmentstatus", {
      state: {
        appointmentStatus: ["COMPLETED", "FINISHED"],
        paymentStatus: null,
        value: "Complete Number of Appointment",
      },
    });
  };

  const TotalAmountPaid = () => {
    navigate("/DashboardAppointmentstatus", {
      state: {
        appointmentStatus: ["COMPLETED", "FINISHED"],
        paymentStatus: true,
        value: "Total Amount Paid",
      },
    });
  };
  const UpcomingAppointment = () => {
    navigate("/DashboardAppointmentstatus", {
      state: {
        appointmentStatus: ["CONFIRMED"],
        paymentStatus: null,
        value: "Upcoming Appointment",
      },
    });
  };

  const PendingPayment = () => {
    navigate("/DashboardAppointmentstatus", {
      state: {
        appointmentStatus: ["COMPLETED", "FINISHED"],
        paymentStatus: false,
        value: "Pending Payment",
      },
    });
  };
  useEffect(() => {
    if (startDate != null && endDate === null) {
      Swal.fire({
        title: "Please Add EndDate",
        icon: "error",
      });
    } else if (startDate > endDate && endDate !== null) {
      Swal.fire({
        title: "EndDate should be bigger than StartDate",
        icon: "error",
      });
    } else {
      const data = {
        startDate: startDate,
        endDate: endDate,
        id: selectHospital,
      };

      dispatch(HospitalDashboardRequest(data));
    }
  }, [startDate, endDate, selectHospital]);

  useEffect(() => {
    dispatch(AllHospitalNameDropDownRequest());
  }, []);

  return (
    <>
      {HospitalDasboard.isLoading ? (
        <>
          {" "}
          {/* <h2 className="headingLoading">Your Payment is Being Processed</h2> */}
          <h3 className="headingLoading">Please Wait...</h3>
          <div className="corporateDashboardLoading">
            <img src={Loader} className="imgLoader" />
          </div>
        </>
      ) : (
        <>
          <div className="HospitalDashbaordOuterContainer">
            <div className="HospitalDashbaordDateContainer">
              <div>
                <select
                  as="Select"
                  label="Select Type"
                  className="SelectHospitalboxContainer"
                  onChange={(e) => handleSelectHospital(e)}
                >
                  <option value="" disabled selected>
                    Select Hospital
                  </option>
                  <option value="All">All</option>

                  {AllHospitalNameDropdown?.data?.data?.data?.data.map(
                    (item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="HospitalDashboardFlexdisplay">
                <div className="paymentStartDate">
                  <input
                    type="date"
                    className="HospitalDashboardStartDateInput"
                    onChange={(e) => handleStartDate(e)}
                    value={formattedStartDate}
                    style={{
                      border:
                        endDate !== 0 && startDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>

                <div className="paymentEndDate">
                  <input
                    type="date"
                    className="HospitalDashboardStartDateInput"
                    onChange={(e) => handleEndDate(e)}
                    value={formattedEndDate}
                    style={{
                      border:
                        startDate !== 0 && endDate === 0
                          ? "1px solid red"
                          : "1px solid black",
                    }}
                  ></input>
                </div>
              </div>
            </div>

            <div className="HospitalDashbaordContainerMain">
              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Active Doctors
                  <div className="HospitalDashboardNameList2">
                    {HospitalDasboardData?.activeDoctors}
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={handleActiveDoctor}
                >
                  View Details
                </button>
              </div>

              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Total Number of Appointments
                  <div className="HospitalDashboardNameList2">
                    {HospitalDasboardData?.totalNoOfConsultation}
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={totalNumberOfAppointment}
                >
                  View Details
                </button>
              </div>

              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Completed Number of Appointment
                  <div className="HospitalDashboardNameList2">
                    {HospitalDasboardData?.completedConsultations}
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={CompletedNumberOfAppointment}
                >
                  View Details
                </button>
              </div>

              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Total Amount Paid
                  <div className="HospitalDashboardNameList2">
                    ₹{HospitalDasboardData?.totalAmountPaid}/-
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={TotalAmountPaid}
                >
                  View Details
                </button>
              </div>

              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Upcoming Appointment
                  <div className="HospitalDashboardNameList2">
                    {HospitalDasboardData?.upcomingConsultation}
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={UpcomingAppointment}
                >
                  View Details
                </button>
              </div>

              <div className="HospitalDashbaordblockContainer">
                <div className="HospitalDashboardNameList">
                  Pending For Payment
                  <div className="HospitalDashboardNameList2">
                    ₹{HospitalDasboardData?.totalPendingAmount}/-
                  </div>
                </div>
                <button
                  className="HospitalDashboardBtn"
                  onClick={PendingPayment}
                >
                  View Details
                </button>
              </div>
            </div>
            <Footer />
          </div>
        </>
      )}

      <Footer />
    </>
  );
}

export default HospitalDashboard;