import * as types from "../../Config/Pharmacy/PharmacyActionType"

export const addPharmacyRequest = (data) => {
    return {
        type: types.ADD_PHARMACY_REQUEST,
        data:data
    }
}

export const addPharmacySuccess = (data) => {
    return {
        type: types.ADD_PHARMACY_SUCCESS,
        data:data,
    }
}

export const addPharmacyFailure = (data) => {
    return {
        type: types.ADD_PHARMACY_FAILURE,
        data:data,
    }
}