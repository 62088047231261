import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { checkEmailAgentRequest } from "../../../redux/Actions/Agents/checkEmailAgentAction";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { planTypeDropdownRequest } from "../../../redux/Actions/Plan/PlanTypeDropDown";
import storage from "../../../Utils/storage";
import { updateHospitalRequest } from "../..//../redux/Actions/Hospitals/updateHospital";
import { getAllServiceRequest } from "../..//../redux/Actions/Service/getAllServiceAction";
import "./Modal.css";
import { modalHeader } from "./ModalConstants";
import CircularProgress from "@mui/material/CircularProgress";
const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
const loginSchema = Yup.object().shape({
  contactNumber: Yup.string()
    .required("Required Field")
    .matches(phoneRegExp, "Phone number is not valid"),
  email: Yup.string().required("Required Field").email("Invalid Email"),
  password: Yup.string()
    .min(6, "Password should be more than 5 characters")
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function preventnumberinput(e) {
  var keycode = e.keycode ? e.keycode : e.which;
  if ((keycode > 47 && keycode < 58) || (keycode > 95 && keycode < 107)) {
    e.preventDefault();
  }
}

function ModalUpdateHospital(props) {
  useEffect(() => {
    dispatch(viewAllCityRequest());
    dispatch(getAllServiceRequest());
    dispatch(planTypeDropdownRequest());
  }, []);

  const [matchApiCall, setMatchApiCall] = useState(false);
  // if(updateRes.isLoading && !flag)
  // {
    //   setFlag(true)
    // }
    
    const [matchApiRes, setMatchApiRes] = useState(null);
    const [service, setService] = React.useState(props.value.services);
    const viewServices = useSelector((state) => state.getService);
    const viewCity = useSelector((state) => state.viewAllCity);
    
    const handleClose = () => {
      props.open(false);
    };
    
    const dispatch = useDispatch();
    
  const citiesId = storage.getStorage("cityId");
  // const SelectTypeId = storage.getStorage("selectId");
  
  const res = useSelector((state) => state.updateHospital);
  const [isPasswordFirstClick, setIsPasswordFirstClick] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [initialUuid, setInitialUuid] = useState();
  const planTypeDropdown = useSelector((state) => state.planTypeDropdown);

  var id;
  
  viewCity.data?.data?.data?.data.map((item, index) => {
    if (item.name === props.value.city) {
      id = item.id;
    }
  });
  planTypeDropdown.data?.data?.data?.data.map((item, index) => {
    if (item.name === props.value.typeData) {
      id = item.id;
    }
  });
  const [selectCity, setSelectCity] = useState(citiesId);
  const [selectType, setSelectType] = useState(props.value?.type);
  const updateRes = useSelector((state) => state.updateHospital)
  const [flag, setFlag] = useState(true);
  useEffect(()=>{
    if(updateRes?.data || updateRes?.error)
    {

      setFlag(true);
    }

  },[updateRes])


  const handleSubmit = (values, { setSubmitting }) => {
    setFlag(false)
    setMatchApiRes(null);
    setMatchApiCall(true);

    if (isPasswordFirstClick) {
      const value = {
        ...values,
        password: values.password,
        // serviceUuidList: serviceUuidArray,
        selectId: selectType,
      };
      setTimeout(() => {
        dispatch(updateHospitalRequest(value));
        setSubmitting(false);
      }, 400);

    } else {
      const value = {
        ...values,
        password: props?.value?.password,
        // serviceUuidList: serviceUuidArray,
        selectId: selectType,
      };
      setTimeout(() => {
        dispatch(updateHospitalRequest(value));
        setSubmitting(false);
      }, 400);
    }
    
  };

  useEffect(() => {

    if (matchApiCall) {
      if (matchApiRes !== null && matchApiRes) {
        handleEmailMobileValidation();
      }
    }
  }, [matchApiCall, matchApiRes]);
  useEffect(() => {
    if (res && res !== null && res !== undefined) {
      if (res?.data || res?.error) {
        setMatchApiRes(res);
      }
    }
  }, [res]);

  const handleEmailMobileValidation = () => {
    //
    if (matchApiRes?.data?.data?.status === 200) {
      //

      props.open(false);
      Swal.fire({
        title: "Hospital Details Updated Successfully",
        icon: "success",
      }).then(() => {
        window.location.reload();
        // setSwal(false);
        setMatchApiCall(false);
        setMatchApiRes(null);
      });
    } else if (
      matchApiRes?.error !== null &&
      matchApiRes?.error?.data?.response !== 201 &&
      matchApiRes?.error?.data?.response?.data !== null
    ) {
      //

      swal({
        title: "" + Object.values(matchApiRes?.error?.data?.response?.data),
        icon: "error",
      }).then(() => {
        setMatchApiCall(false);
        setMatchApiRes(null);
        props.open(true);
      });
    }
  };

  const handleChangeService = (event) => {
    const {
      target: { value },
    } = event;

    setService(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  //

  // useEffect(() => {
  //   viewServices?.data?.action?.data?.data?.map((item, index) =>
  //     item?.name === props?.value?.services[0]
  //       ? setInitialUuid(item?.serviceUuid)
  //       : null
  //   );
  // }, [viewServices]);

  const handleChange = (e) => {
    setSelectCity(e.target.value);
  };
  const handleChangeDropdown = (e) => {
    setSelectType(e.target.value);
  };
  const handleCheckEmail = (event) => {
    if (event.target.value !== props.value?.contactEmail) {
      setRecvEmail(event.target.value);
    }
  };

  const [recvEmail, setRecvEmail] = useState();

  useEffect(() => {
    dispatch(checkEmailAgentRequest(recvEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recvEmail]);

  const emailDetail = useSelector((state) => state.checkEmailAgent);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mainModal"
      >
        <Box className="style1">
          <div className="modalHeader">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={modalHeader}
            >
              Update Hospital
            </Typography>

            <button className="closeButtonModal" onClick={handleClose}>
              <Close />
            </button>
          </div>

          <Formik
            initialValues={{
              name: props.value?.name,
              contactName: props.value?.contactName,
              address: props.value?.address,
              pinCode: props.value.pinCode,
              mapUrl: props.value.mapUrl,
              password: props?.value?.password?.slice(0, 6),
              confirmPassword: props?.value?.password?.slice(0, 6),
              contactNumber: props.value?.contactPersonNumber,
              email: props.value?.contactEmail,
              // city: props.value.city,
              // selectServices: initialUuid,
              id: props.value?.id,
              cityId: props.value?.cityId
              // typeData: props.value?.type,
              // type: props.value?.type,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="inputFieldsContainer">
                  <div className="modalInfo">
                    <div className="modalInfoLabels">
                      <label>Name</label>
                      <label>Address</label>
                      <label>PinCode</label>
                      <label>Contact Name</label>
                      <a href={props.value.mapUrl} className="modalInfoLabelsA">
                        View on Map
                      </a>
                    </div>
                    <div className="modalInfoValues">
                      <label>: {props.value.name}</label>
                      <label>
                        : {props.value.address},{props.value.city}
                      </label>
                      <label>: {props.value.pinCode}</label>
                      <label>: {props.value.contactName}</label>
                    </div>
                  </div>
                  {/* <label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Hospital Name"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="name"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Hospital Address"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="address"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      as="select"
                      name="city"
                      placeholder="Select City"
                      className="inputField"
                      onChange={handleChange}
                    >
                      {viewCity.data?.data?.data?.data.map((item, index) => (
                        <option
                          key={index}
                          value={item.id}
                          selected={item?.name === props?.value?.city}
                        >
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </label>
                  <label>
                    <Field
                      type="number"
                      name="pinCode"
                      placeholder="Pin Code"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="pinCode"
                      component="div"
                    />
                  </label> */}
                  <label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Hospital Email"
                      className="inputField"
                      // onBlur={handleEmailChange}
                      onBlur={(e) => {
                        if (e.target.value !== props.value?.contactEmail) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        } else {
                          handleCheckEmail(e);
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="email"
                      component="div"
                    />

                    {emailDetail.error?.data.response?.status === 302 && (
                      <div className="emailExist">Email exist</div>
                    )}
                  </label>
                  {/* {emailChange &&
                    Swal.fire({
                      title: "Do you want to change email?",
                      icon: "warning",
                      showDenyButton: true,
                      confirmButtonText: "Yes",
                      denyButtonText: `No`,
                    }).then((result) => {
                     
                      if (result.isConfirmed) {
                        setIsPasswordFirstClick(true);
                      } else if (result.isDenied) {
                        setIsPasswordFirstClick(false);
                      }
                    })} */}
                  <label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder="Hospital Contact Number"
                      className="inputField"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactNumber"
                      component="div"
                    />
                  </label>

                  {/* <label>
                    <Field
                      type="text"
                      name="contactName"
                      placeholder="Hospital Contact Name"
                      className="inputField"
                      onKeyDown={(event) => preventnumberinput(event)}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="contactName"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="text"
                      name="mapUrl"
                      placeholder="Enter Map URL"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="mapUrl"
                      component="div"
                    />
                  </label> */}
                  {/* <label>
                    <Field
                      type="text"
                      name="typeData"
                      placeholder="Enter Type"
                      className="inputField"
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="typeData"
                      component="div"
                    />
                  </label> */}
                  <label>
                    <Field
                      as="select"
                      name="type"
                      placeholder="Select City"
                      className="inputField"
                      onChange={handleChangeDropdown}
                    >
                      {planTypeDropdown.data?.data?.data?.data.map(
                        (item, index) => (
                          <option
                            key={index}
                            value={item.id}
                            selected={item?.name === props?.value?.typeData}
                          >
                            {item.name}
                          </option>
                        )
                      )}
                    </Field>
                  </label>
                  {/* {emailChange && {
                   setFieldValue("password", "");
                   setFieldValue("confirmPassword", "");
                 }} */}
                  <label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Generate Password"
                      className="inputField"
                      onClick={() => {
                        if (!isPasswordFirstClick) {
                          setIsPasswordFirstClick(true);
                          setFieldValue("password", "");
                          setFieldValue("confirmPassword", "");
                        }
                      }}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="password"
                      component="div"
                    />
                  </label>
                  <label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Retype Password"
                      defaultValue="DefaultPassword"
                      className="inputField"
                      disabled={!isPasswordFirstClick}
                    />
                    <ErrorMessage
                      className="errorMessageModalValidation"
                      name="confirmPassword"
                      component="div"
                    />
                  </label>

                  {/* <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Services
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        defaultValue={props.value.services}
                        value={service}
                        onChange={handleChangeService}
                        input={<OutlinedInput label=" Select Services" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {viewServices?.data?.action?.data?.data?.map(
                          (item, index) => (
                            <MenuItem key={item.serviceUuid} value={item.name}>
                              <Checkbox
                                checked={service.indexOf(item.name) > -1}
                              />
                              <ListItemText primary={item.name} />
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div> */}

                  <button type="submit"     disabled={!flag} className="modalButton">
                
                    {flag ? "    Update Hospital":
                    
                    <CircularProgress/>
                    }
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalUpdateHospital;
