import * as types from "../../Config/Banner/BannerActionType"

export const getBannerTypesRequest = (data) => {
    return {
        type: types.GET_BANNER_TYPES_REQUEST,
        data:data
    }
}

export const getBannerTypesSuccess = (data) => {
    return {
        type: types.GET_BANNER_TYPES_SUCCESS,
        data:data
    }
}

export const getBannerTypesFailure = (data) => {
    return {
        type: types.GET_BANNER_TYPES_FAILURE,
        data:data
    }
}