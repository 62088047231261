import {

  InputAdornment,
 
  Pagination,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AutoComplete from "../../Molecules/Autocomplete/AutoComplete";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../Organisms/Footer/FooterUpper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { viewAllEmployeeRequest } from "../../../redux/Actions/Employee/viewAllEmployeeAction";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Employee.css";
import storage from "../../../Utils/storage";
import { viewAllCityRequest } from "../../../redux/Actions/City/viewAllCityAction";
import { viewAllEmployeeProgramRequest } from "../../../redux/Actions/Employee/viewAllEmployeeProgram";
import { UserContext } from "../../../App";



function Employee(props) {
  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: "Employee name",
      minWidth: 150,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
    },
    {
      field: "employeeId",
      headerClassName: "super-app-theme--header",
      headerName: "Employee ID",
      minWidth: 100,
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "number",
      headerClassName: "super-app-theme--header",
      headerName: "Contact Number",
      // type: "number",
      disableColumnMenu: true,
      sortable: false,
      minWidth: 150,
      flex: 1,
    },
    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Employee Email",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 350,
      flex: 1,
    },
    {
      field: "Modify",
      minWidth: 150,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <div className="modify">
            <VisibilityIcon
              sx={{ color: "#52608E", marginRight: 3 }}
              onClick={() => handleClick(cellValues.row.id)}
            />
          
          </div>
        );
      },
    },
  ];
  const id = props.id;


  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [asSuperAdmin, setAsSuperAdmin] = React.useState(true);
  const [city, setCity] = useState(storage.getStorage("cityId"));
  const [searchText, setSearchText] = useState("");
  const [searchPage, setSearchPage] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (city === null) {
    setCity("");
  }

  const viewEmployee = useSelector((state) => state.viewAllEmployee);
  const viewCity = useSelector((state) => state.viewAllCity);

  const Admin = useContext(UserContext);

  useEffect(() => {
    Admin !== "SUPER_ADMIN" ? setAsSuperAdmin(false) : setAsSuperAdmin(true);
  }, []);



  useEffect(() => {
    dispatch(
      viewAllEmployeeRequest(page, pageSize, "", city, [""], asSuperAdmin, id)
    );
    dispatch(viewAllEmployeeProgramRequest());
    dispatch(viewAllCityRequest());
  }, [city]);

  let cityNames = [{ label: "All cities", id: "" }];

  viewCity.data?.data?.data?.data.map((itm, ind) => {
    cityNames.push({
      label: itm.name,
      id: itm.id,
    });
  });

  const handleSearch = (e) => {

    const newSearchText = e.target.value;

    if (newSearchText === "" || newSearchText.length < 1) {
      dispatch(
        viewAllEmployeeRequest(
          page,
          pageSize,
          newSearchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
      setPage(1);
    } else {
      dispatch(
        viewAllEmployeeRequest(
          searchPage,
          pageSize,
          newSearchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
    }
    setSearchText(newSearchText);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = (id) => {
    navigate(`/viewEmployeeDetails/${id}`);
  };



  const handlePageChange = (event, value) => {
    if (searchText === "" || searchText.length <= 1) {
      dispatch(
        viewAllEmployeeRequest(
          value,
          pageSize,
          searchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
      setPage(value);
    } else {
      dispatch(
        viewAllEmployeeRequest(
          value,
          pageSize,
          searchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
      setSearchPage(value);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDropChange = (event) => {
    if (searchText === "" || searchText.length < 1) {
      dispatch(
        viewAllEmployeeRequest(
          page,
          pageSize,
          searchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
    } else {
      dispatch(
        viewAllEmployeeRequest(
          searchPage,
          pageSize,
          searchText,
          city,
          [""],
          asSuperAdmin,
          id
        )
      );
    }
    setPageSize(event.target.value);
  };

  const handleCity = (e, val) => {
    storage.setStorage("cityId", val.id);
    storage.setStorage("cityName", val.label);
    setCity(val.id);
  };





  return (
    <>
      {Admin === "SUPER_ADMIN" ? (
        <div>
          {" "}
          <div style={{ marginTop: Admin === "SUPER_ADMIN" ? "-12vw" : "0vw" }}>
            <div className="grid">
              <div className="mainBoxEmp">
                <div className="employee-head">
                  <div className="ProgramAssignedMainHeading">
                    Employee List
                  </div>
                </div>

                <Box sx={{ height: 400, width: "100%", marginTop: "2%" }}>
                  {viewEmployee.data?.data.data.employeeResponseDtoList && (
                    <DataGrid
                      sx={{
                        color: "#1D2334",
                        "& .super-app-theme--header": {
                          backgroundColor: "#52608E",
                          color: "#F2EFEA",
                          "& > .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                          },
                        },
                        "&:hover:not(.Mui-disabled)": {
                          cursor: "pointer",
                        },
                        ".MuiTablePagination-toolbar": {
                          alignItems: "baseline",
                          marginTop: "5%",
                        },
                      }}
                      pagination
                      disableColumnMenu
                      rows={
                        viewEmployee.data?.data.data.employeeResponseDtoList
                      }
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[10, 50, 100]}
                      // onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      components={{
                        Footer: "none",
                      }}
                    />
                  )}
                  <div className="MainTablePagination">
                    <p>Rows Per Page:</p>
                    <select
                      className="dropDown"
                      defaultValue={10}
                      onChange={handleDropChange}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <Pagination
                      count={viewEmployee.data?.data.data.totalPages}
                      color="primary"
                      onChange={handlePageChange}
                    />
                  </div>
                </Box>
              </div>
            </div>
            <br />
            <br />
            <br />
            {Admin !== "SUPER_ADMIN" && <Footer />}
          </div>
        </div>
      ) : (
        <div>
          {" "}
          <div style={{ marginTop: Admin === "SUPER_ADMIN" ? "-12vw" : "0vw" }}>
            <div className="grid">
              <h4
                style={{ marginLeft: Admin === "SUPER_ADMIN" ? "7vw" : "7vw" }}
                className="h4"
              >
                Employees
              </h4>
              <div className="mainBox">
                <div className="employee-head">
                  <div className="employee-leftHead">
                    <h5 className="list">List of Employees</h5>
                    <div className="employeecount">
                      {viewEmployee.data?.data.data.totalEmployees} Employees
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row2">
                  <AutoComplete
                    style={{ height: "100%", border: "1px solid #52608E" }}
                    onChange={(event, newValue) => {
                      handleCity(event, newValue);
                    }}
                    heading="All Cities"
                    cityList={cityNames}
                  />
                  <div className="searchBar">
                    <TextField
                      type="text"
                      name="search"
                      onChange={handleSearch}
                      variant="outlined"
                      sx={{
                        border: "1px solid #52608E",
                        borderRadius: "6px",
                      }}
                      placeholder="Search"
                      size="small"
                      hiddenLabel="true"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ color: "#52608E" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                <Box sx={{ height: 600, width: "100%", marginTop: "2%" }}>
                  {viewEmployee.data?.data.data.employeeResponseDtoList && (
                    <DataGrid
                      sx={{
                        color: "#1D2334",
                        "& .super-app-theme--header": {
                          backgroundColor: "#52608E",
                          color: "#F2EFEA",
                          "& > .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                          },
                        },
                        "&:hover:not(.Mui-disabled)": {
                          cursor: "pointer",
                        },
                        ".MuiTablePagination-toolbar": {
                          alignItems: "baseline",
                          marginTop: "5%",
                        },
                      }}
                      pagination
                      disableColumnMenu
                      rows={
                        viewEmployee.data?.data.data.employeeResponseDtoList
                      }
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[10, 50, 100]}
                      // onPageChange={(newPage) => setPage(newPage)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      components={{
                        Footer: "none",
                      }}
                    />
                  )}
                  <div className="MainTablePagination">
                    <p>Rows Per Page:</p>
                    <select
                      className="dropDown"
                      defaultValue={10}
                      onChange={handleDropChange}
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <Pagination
                      count={viewEmployee.data?.data.data.totalPages}
                      color="primary"
                      onChange={handlePageChange}
                      page={
                        searchText === "" || searchText.length <= 1
                          ? page
                          : searchPage
                      }
                    />
                  </div>
                </Box>
              </div>
            </div>
            <br />
            <br />
            <br />
            {Admin !== "SUPER_ADMIN" && <Footer />}
          </div>
        </div>
      )}
    </>
  );
}

export default Employee;
