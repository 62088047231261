import React, { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import "./ServicesNew.css";

function DiscountService(prop) {
  const [servicesAttribute, setServicesAttribute] = useState([]);
  const servicesNames = [];
  const servicesInfoObject = [];
  const [discount, setDiscount] = useState();

  function handleDiscount(e) {
    setDiscount(e.target.value)
  }
  

 

  const {
    handleClose,
    servicesInfo,
    handleServicesAttributeData,
  } = prop;

  useEffect(() => {
    servicesAttribute.push(servicesInfoObject)
    setServicesAttribute([...servicesInfoObject])
  }, []);

  servicesInfo.attributeResponseDtoList.map((item) => {
    servicesInfoObject.push(item);
    servicesNames.push(item.name);
  });

  useEffect(() => {
    let newFormValues = [
      {
        attributeUuid: servicesInfoObject[0].attributeId.toString(),
        attributeType: "INDIVIDUAL_TEST",
        attributeCount: discount,
        exclusive: true,
        attributeName: servicesInfoObject[0].attributeName,
      }
    ]

    let ffval = {
      serviceName: servicesInfo.name,
      serviceUuid: servicesInfo.serviceUUID,
      attributeCountDtoList: newFormValues,
    }
    handleServicesAttributeData(ffval);
  }, [discount])

  return (
    <div className="pharmacyService">
      <div className="pharmacyService1">
        Discount
      </div>
      <div className="pharmacyService2">
        <div className="pharmacyService2Child1">
          {/* <p>Total Limit</p> */}
          <input type="number" onChange={handleDiscount} value={discount} />
        </div>
      </div>
      <div className="pharmacyService3">
        <button
          className="templateClose"
          onClick={() => {
            handleClose(servicesInfo.serviceUUID);
          }}
        >
          <Close />
        </button>
      </div>
    </div>
  );
}
export default DiscountService;