import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Hospitals/viewAllHospitalAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllHospitals(action) {
  try {
    let response = yield call(NetworkService.viewAllHospitals, action);
    yield put(actions.viewAllHospitalSuccess(response));
  } catch (err) {
    yield put(actions.viewAllHospitalFailure(err));
  }
}

export function* watchViewAllHospitals() {
  yield takeLatest(types.VIEW_ALL_HOSPITAL_REQUEST, viewAllHospitals);
}

