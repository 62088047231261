import * as types from "../../Config/actionType"

export const addServiceRequest = (data) => {
   
    return {
        type: types.ADD_SERVICE_REQUEST,
        data
    }
}

export const addServiceSuccess = (action) => {
    return {
        type: types.ADD_SERVICE_SUCCESS,
        action
    }
}

export const addServiceFailure = (action) => {
    return {
        type: types.ADD_SERVICE_FAILURE,
        action
    }
}