import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/deleteAttachmentAction"
import NetworkService from "../../../network/NetworkService";

export function* deleteAttachmentSaga(action) {
  try {
    let response = yield call(NetworkService.deleteAttachment, action);
    yield put(actions.deleteAttachmentSuccess(response));
  } catch (err) {
    yield put(actions.deleteAttachmentFailure(err));
  }
}

export function* watchDeleteAttachmentSaga() {
  yield takeLatest(types.DELETE_ATTACHMENT_REQUEST, deleteAttachmentSaga);
}