import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/EditDiscountProductAction";
import NetworkService from "../../../network/NetworkService";

export function* EditDiscountProductSaga(action) {
  try {
    let response = yield call(NetworkService.editDiscountProduct, action);
    yield put(actions.EditDiscountProductSuccess(response));
  } catch (err) {
    yield put(actions.EditDiscountProductFailure(err));
  }
}

export function* watchEditDiscountProductSaga() {
  yield takeLatest(types.EDIT_DISCOUNT_PRODUCT_REQUEST, EditDiscountProductSaga);
}
