import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Booking/viewAllTestsNameAction";
import NetworkService from "../../../network/NetworkService";

export function* viewAllTestsName() {
  try {
    let response = yield call(NetworkService.viewAllTestsName);

    yield put(actions.viewAllTestsNameSuccess(response))
  } catch (err) {
    yield put(actions.viewAllTestsNameFailure(err));
  }
}

export function* watchViewAllTestsName() {
  yield takeLatest(types.VIEW_ALL_TESTS_NAME_REQUEST, viewAllTestsName);
}