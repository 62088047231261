import * as types from "../../Config/actionType"

export const updateServiceAttributeRequest = (data) => {
   
    return {
        type: types.UPDATE_SERVICE_ATTRIBUTE_REQUEST,
        data
    }
}

export const updateServiceAttributeSuccess = (action) => {
    return {
        type: types.UPDATE_SERVICE_ATTRIBUTE_SUCCESS,
        action
    }
}

export const updateServiceAttributeFailure = (action) => {
    return {
        type: types.UPDATE_SERVICE_ATTRIBUTE_FAILURE,
        action
    }
}
