import { all } from "redux-saga/effects";
import { watchAddPharmacySaga } from "./AddPharmacySaga";
import { watchDeletePharmacy } from "./deletePharmacySaga";
import { watchViewAllPharmacy } from "./viewAllPharmacySaga";
import { watchGetPharmacyDetailSaga } from "./viewPharmacyDetailSaga";

function* rootPharmacySaga() {
  yield all([
    watchViewAllPharmacy(),
    watchAddPharmacySaga(),
    watchDeletePharmacy(),
    watchGetPharmacyDetailSaga(),
  ]);
}

export default rootPharmacySaga;
