import RequestService from "../RequestService";
import { api } from "../../helpers/Banner/BannerConstant"

class BannerNetworkServices {


    addBanner(action) {
        const endPoint = `${api.addBanner}`;
        return RequestService.postRequest(endPoint, {
            bannerType: action.data.bannerType,
            name: action.data.bannerName,
            position: action.data.bannerPosition,
            screenType: action.data.screenType,
        });
    }

    deleteBanner(action) {
        const endPoint = `${api.deleteBanner}/screen-type?delete=${action.data.bool}&screenType=${action.data.screenType}`;
        return RequestService.deleteRequest(endPoint);
    }



    deletePositionBanner(action) {
        const endPoint = `${api.deletePositionBanner}/screen-type/position?delete=${action.data.bool}&position=${action.data.position}&screenType=${action.data.screenType}`
        return RequestService.deleteRequest(endPoint);
    }

    getScreenTypes(action) {
        const endPoint = `${api.getScreenTypes}/screen-types?forWeb=${action.data}`
        return RequestService.getRequest(endPoint);
    }

    getBannerByScreenTypes(action) {
        const endPoint = `${api.getBannerByScreenTypes}?screenType=${action.data}`
        return RequestService.getRequest(endPoint);
    }

    getBannerTypes(action) {
        const endPoint = `${api.getBannerTypes}/banner-type`
        return RequestService.getRequest(endPoint);
    }

    getAllBannerByType(action) {
        const endPoint = `${api.getAllBannerByType}?bannerType=${action.bannerType}&position=${action.position}&screenType=${action.screenType}`
        return RequestService.getRequest(endPoint);
    }
    getItemType() {
        const endPoint = `${api.getItemType}`
        return RequestService.getRequest(endPoint);
    }
    addBannerDetail(action) {
        const endPoint = `${api.addBannerDetail}`
        return RequestService.postRequest(endPoint, action.formData);
    }
    deleteById(action) {
        const endPoint = `${api.deleteById}?delete=${action.bool}&id=${action.id}`
        return RequestService.deleteRequest(endPoint);
    }

}
export default new BannerNetworkServices();