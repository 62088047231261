import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Coupon/GetAllCouponAction";
import NetworkService from "../../../network/NetworkService";

export function* GetAllCoupon(action) {
  try {
    let response = yield call(NetworkService.GetAllCoupon, action);
    yield put(actions.GetAllCouponSuccess(response));
  } catch (err) {
    yield put(actions.GetAllCouponFailure(err));
  }
}

export function* watchGetAllCouponSaga() {
  yield takeLatest(types.GET_ALL_COUPON_REQUEST, GetAllCoupon);
}

