import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../Config/actionType";
import * as actions from "../../Actions/Discount/AddDiscountIndividualAction";
import NetworkService from "../../../network/NetworkService";

export function* AddDiscountIndividualSaga(action) {
  try {
    let response = yield call(NetworkService.addDiscountIndividual, action);
    yield put(actions.addDiscountIndividualSuccess(response));
  } catch (err) {
    yield put(actions.addDiscountIndividualFailure(err));
  }
}

export function* watchAddDiscountIndividualSaga() {
  yield takeLatest(types.ADD_DISCOUNT_INDIVIDUAL_REQUEST, AddDiscountIndividualSaga);
}
