import * as types from "../../Config/actionType"

export const addCorporateRequest = (data) => {
    return {
        type: types.ADD_CORPORATE_REQUEST,
        data:data
    }
}

export const addCorporateSuccess = (data) => {
    return {
        type: types.ADD_CORPORATE_SUCCESS,
        data:data,
    }
}

export const addCorporateFailure = (data) => {
    return {
        type: types.ADD_CORPORATE_FAILURE,
        data:data,
    }
}