import * as types from "../../Config/actionType"

export const confirmAppointmentRequest = (data) => {
    return {
        type: types.CONFIRM_APPOINTMENT_REQUEST,
        data:data
    }
}

export const confirmAppointmentSuccess = (data) => {
    return {
        type: types.CONFIRM_APPOINTMENT_SUCCESS,
        data:data,
    }
}

export const confirmAppointmentFailure = (data) => {
    return {
        type: types.CONFIRM_APPOINTMENT_FAILURE,
        data:data,
    }
}