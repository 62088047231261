import * as types from "../../../Config/actionType";

export const networkDiagnosticRequest = (
  page,
  pageSize,
  search,
  city,
  status
) => {
  return {
    type: types.NETWORK_DIAGNOSTIC_REQUEST,
    page,
    pageSize,
    search,
    city,
    status,
  };
};

export const networkDiagnosticSuccess = (data) => {
  return {
    type: types.NETWORK_DIAGNOSTIC_SUCCESS,
    data: data,
  };
};

export const networkDiagnosticFailure = (data) => {
  return {
    type: types.NETWORK_DIAGNOSTIC_FAILURE,
    data: data,
  };
};
