import React from "react";
import "./FooterUpper.css";
import Email from "../../../assets/email.png";
import Phone from "../../../assets/phone.png";
import Instagram from "../../../assets/instagram.png";
import Facebook from "../../../assets/facebook.png";
import Twitter from "../../../assets/twitter.png";
import Linkedin from "../../../assets/linkedin.png";
import Logo from "../../../assets/logoPrimaryFooter.png";
import Copyright from "../../../assets/copyright.png";


function Footer() {
  return (
    <div className="footer">
      <div className="upperFooter">
        <div className="upperChild1">
          <div className="logoImage">
            <img alt="Logo" src={Logo} />
          </div>

          <div className="address">
            <p>
              1025-1030, 80ft Main Road
              <br /> 1st Block, Koramangala
              <br />
              Bengaluru - 560034
            </p>
            <p style={{ lineHeight: "1.4vw" }} >
              B-1/632 Janak puri, 3rd Floor,
              <br /> Opposite Metro Pillar No.566 Janakpuri,
              <br />Above Galway Store,  New Delhi-110058

            </p>
          </div>
          <div className="emailContact">
            <img alt="Email" src={Email} />
            <a href="mailto: support@yuvahealth.in" style={{ color: "#ffffff" }}>support@yuvahealth.in</a>
          </div>
          <div className="phoneContact" >
            <img alt="Phone" src={Phone} />
            <p>+91 8929945228</p>
          </div>

          <div className="socialmediaContact">
            <a href="https://www.instagram.com/yuva_health/" target="_blank">
              <img alt="Instagram" src={Instagram} />
            </a>
            <a
              href="https://www.facebook.com/yuvahealthwellness"
              target="_blank"
            >
              <img alt="Facebook" src={Facebook} />
            </a>
            <a href="https://twitter.com/" target={"_blank"}>
              <img alt="Twitter" src={Twitter} />
            </a>
            <a
              href="https://www.linkedin.com/company/yuvahealth/"
              target={"_blank"}
            >
              <img alt="Linkedin" src={Linkedin} />
            </a>
          </div>
        </div>
        <div className="upperChild2Footer">
          <p>Company</p>
          <ol>
            <a href="https://yuvahealth.in/AboutUs"><li>About Us</li></a>
            <a href="https://yuvahealth.in/TermsAndConditions"><li>Terms and Conditions</li></a>
            <a href="https://yuvahealth.in/PrivacyPolicy"><li>Privacy Policy</li></a>
            <li>FAQ's</li>
            <a href = "https://yuvahealth.in/ContactUs" style={{ color: "#ffffff" }}> <li>Contact Us</li></a>
          </ol>
        </div>
        <div className="upperChild3Footer">
          <p>Services</p>
          <ol>
            <a href="https://yuvahealth.in/HealthRiskAssessment"><li>Health Risk Assessment</li></a>
            <a href="https://yuvahealth.in/HealthCheckups"><li>Health Checkups</li></a>
            <a href="https://yuvahealth.in/HealthCheckUps"><li>Diagnostic Services</li></a>
            <a href="https://yuvahealth.in/Subscribe"><li>Our Plans</li></a>
            <a href="https://yuvahealth.in/"><li>Corporate Services</li></a>
          </ol>
        </div>
        <div className="upperChild4">
          <div className="upperChild4Heading">
            <p>Newsletter</p>
          </div>

          <div className="upperChild4Description">
            <span>
              Signup our newsletter to get update on information,
              news, insights and promotions.
            </span>
          </div>
          <div className="emailInput">
            <input
              type="text"
              placeholder="Email"
              style={{
                color: "#9A9A9A",
                fontStyle: "normal",
                fontWeight: "400",
                fontFamily: "Rubik",
                fontSize: "0.75em",
                lineHeight: "150%",
              }}
            />
          </div>
          <div className="signup">
            <img alt="Email" src={Email} />
            Sign-up
          </div>
        </div>
      </div>
      <div className="downFooter">
        <div className="copyrightSign">
          <img alt="Copyright" src={Copyright} />
        </div>
        <div className="copyrightText">
          <p>Yuva Health - 2022. All right reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
