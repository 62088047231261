import * as types from "../../Config/actionType"

export const fileTemplateRequest = (data) => {
    return {
        type: types.FILE_TEMPLATE_REQUEST,
        data
    }
}

export const fileTemplateSuccess = (action) => {
    return {
        type: types.FILE_TEMPLATE_SUCCESS,
        action
    }
}

export const fileTemplateFailure = (action) => {
    return {
        type: types.FILE_TEMPLATE_FAILURE,
        action
    }
}