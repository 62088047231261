import * as types from "../../Config/actionType"

export const addInsuranceAgentRequest = (data) => {
    return {
        type: types.ADD_INSURANCE_AGENTS_REQUEST,
        data:data
    }
}

export const addInsuranceAgentSuccess = (data) => {
    return {
        type: types.ADD_INSURANCE_AGENTS_SUCCESS,
        data:data,
    }
}

export const addInsuranceAgentFailure = (data) => {
    return {
        type: types.ADD_INSURANCE_AGENTS_FAILURE,
        data:data,
    }
}